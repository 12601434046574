import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AuthService } from './auth.service';
import firebase from 'firebase/compat/app';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { DATA_CARTEGGI_ENTRO_12_BASEE, DATA_CARTEGGI_OLTRE_12_BASEE, DATA_QUIZ_BASE, DATA_QUIZ_VELA_BASE } from 'src/app/QUIZ_DATA';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  COUNTER
  COUNTER_ALL: any = {}
  COUNTER_TEMPLATE = 1;

  QUIZ_BASE = DATA_QUIZ_BASE//.filter(e=> e.capitolo == 'TEORIA DELLO SCAFO').splice(0,30);
  QUIZ_VELA_BASE = DATA_QUIZ_VELA_BASE;
  CARTEGGI_ENTRO_12_BASE = DATA_CARTEGGI_ENTRO_12_BASEE;
  CARTEGGI_OLTRE_12_BASE = DATA_CARTEGGI_OLTRE_12_BASEE;

  isWebApp = !this.platform.is('capacitor')
  /*
    fixUsers() {

      console.clear()

      this.firestore.collection('users').valueChanges().subscribe((res: any[]) => {

        for (const user of res) {

          try {

            if (user.email.toLowerCase() != user.email) {

              this.firestore.doc('users/' + user.id).set({email: user.email.toLowerCase()},{merge:true})

              console.log(user.email)
            }

          } catch (error) {

            console.log(user)

          }



        }

      })

    }
  */

  constructor(
    public firestore: AngularFirestore,
    private auth: AuthService,
    public storage: AngularFireStorage,
    public platform: Platform
  ) {


    // this.fixUsers()

    //console.log(this.isWebApp)


    this.auth.afAuth.onAuthStateChanged(currentUser => {
      if (currentUser && currentUser.uid) {

        this.firestore.doc(`${COUNTER_COLLECTION}/${currentUser.uid}`).valueChanges().subscribe(async res => {

          //console.log('COUNTER READ')

          if (!res) {
            await this.updateCounter()
            return
          }

          //console.log('counter', res)

          this.COUNTER = res
        })

      } else {
        this.COUNTER = undefined
      }


    });


  }

  getUserByMail(email) {
    return this.firestore.collection('users', ref => ref.where('email', '==', email))
  }


  async getCounterByUser(email) {

    if (this.COUNTER_ALL[email]) return


    console.clear()
    console.log('getCounterByUser')

    this.COUNTER_ALL[email] = []

    console.clear()

    this.firestore.collection('users', ref => ref.where('email', '==', email.toLocaleLowerCase())).valueChanges().subscribe((res: any[]) => {

      console.log(res)
      let uid = res[0].id

      //console.log(uid)

      this.firestore.doc(`${COUNTER_COLLECTION}/${uid}`).valueChanges().subscribe(async res => {

        //console.log('COUNTER READ')
        //console.log('counter', res)

        this.COUNTER_ALL[email] = res
      })

    })
  }



  async getCounterCustom(type) {

    console.log('getCounterCustom')

    this.firestore.collection('quiz_template', ref => ref.where('type', '==', type).where('user', '==', this.auth.getEmail())).valueChanges().subscribe((res: any[]) => {
      //console.log(res.length);
      this.COUNTER_TEMPLATE = res.length;
    })
  }


  getQuizIdFromCap(cap) {

    console.log('getQuizIdFromCap '+cap)

    //return this.firestore.collection(QUIZ_COLLECTION, ref => ref.where('cap', '==', cap).where('user', '==', this.auth.getEmail()));

    return this.firestore.collection(QUIZ_COLLECTION, 
      el => el
      .where('user', '==', this.auth.getEmail())
      .where('cap', '==', cap) );
  }


  getUser(id) {
    return this.firestore.doc(`${QUIZ_BASE_COLLECTION}/${id}`);
  }

  deleteUser(id) {
    this.firestore.collection(UTENTI_COLLECTION).doc(id).delete()
  }

  deleteQuizTemplate(id) {
    this.firestore.collection(QUIZ_TEMPLATE_COLLECTION).doc(id).delete()
  }


  searchQuiz(string) {
    //console.log(this.QUIZ_BASE)

    let base = this.QUIZ_BASE.filter(el => (
      (' ' + el.argomento.toLowerCase()).includes(' ' + string.toLowerCase()) ||
      (' ' + ('' + el.id).toLowerCase()).includes(' ' + string.toLowerCase()) ||
      (' ' + el.progressivo.toLowerCase()).includes(' ' + string.toLowerCase()) ||
      (' ' + el.domanda.toLowerCase()).includes(' ' + string.toLowerCase())
    ));

    let vela = this.QUIZ_VELA_BASE.filter(el => (
      (' ' + el.categoria.toLowerCase()).includes(' ' + string.toLowerCase()) ||
      (' ' + ('' + el.id).toLowerCase()).includes(' ' + string.toLowerCase()) ||
      (' ' + el.progressivo.toLowerCase()).includes(' ' + string.toLowerCase()) ||
      (' ' + el.domanda.toLowerCase()).includes(' ' + string.toLowerCase())
    ));

    return [base, vela]
  }

  getTimestamp() {
    return firebase.firestore.Timestamp.fromDate(new Date());
  }

  getRandomId() {
    return this.firestore.createId();
  }

  saveQuiz(quiz): Promise<void> {

    if (!quiz.user) quiz.user = this.auth.getUserId();


    if (!quiz.geoData) { delete quiz.geoData; }


    this.updateCounter(quiz)


    if (!quiz.id) quiz.id = this.firestore.createId();
    if (!quiz.dta) quiz.dta = new Date();

    console.log(quiz);
    this.firestore.doc('quiz/' + quiz.id).set(quiz);

    return this.firestore.doc(`${QUIZ_BASE_COLLECTION}/${quiz.id}`).set(quiz, { merge: true });

  }

  saveTemplate(template): Promise<void> {

    if (!template.id) template.id = this.firestore.createId();
    if (!template.dta) template.dta = new Date();

    console.log(template);
    this.firestore.doc('template/' + template.id).set(template);

    return this.firestore.doc(`${QUIZ_TEMPLATE_COLLECTION}/${template.id}`).set(template, { merge: true });

  }

  getTemplate(id,type) {
      return this.firestore.collection(
        QUIZ_TEMPLATE_COLLECTION, 
        el => el
        .where('id', '==', id)
        .where('type', '==', type.toUpperCase())
      );
  }

  listTemplateUtente(user) {
    return this.firestore.collection(QUIZ_TEMPLATE_COLLECTION, el => el.where('user', '==', user));
  }

  async updateCounter(quiz = undefined) {

    console.log('updateCounter')

    if (
      !this.QUIZ_BASE ||
      !this.QUIZ_VELA_BASE ||
      !this.CARTEGGI_ENTRO_12_BASE ||
      !this.CARTEGGI_OLTRE_12_BASE
    ) {

      setTimeout(() => {
        this.updateCounter(quiz)
      }, 1000);

      return
    }

    if (!this.COUNTER)
      this.COUNTER = {} as any

    /// set
    if (!this.COUNTER['quiz_base']) this.COUNTER['quiz_base'] = []
    if (!this.COUNTER['quiz_vela']) this.COUNTER['quiz_vela'] = []
    if (!this.COUNTER['carteggio_entro_12']) this.COUNTER['carteggio_entro_12'] = []
    if (!this.COUNTER['carteggio_oltre_12']) this.COUNTER['carteggio_oltre_12'] = []

    for (const e of this.QUIZ_BASE)
      if (!this.COUNTER['quiz_base'].find(e1 => e1.id == e.id))
        this.COUNTER['quiz_base'].push({ id: e.id, n: 0, capitolo: e.capitolo })

    for (const e of this.QUIZ_VELA_BASE)
      if (!this.COUNTER['quiz_vela'].find(e1 => e1.id == e.id))
        this.COUNTER['quiz_vela'].push({ id: e.id, n: 0, capitolo: e.categoria })

    for (const e of this.CARTEGGI_ENTRO_12_BASE)
      if (!this.COUNTER['carteggio_entro_12'].find(e1 => e1.id == e.id) && e.settore)
        this.COUNTER['carteggio_entro_12'].push({ id: e.id, n: 0, capitolo: e.settore })

    for (const e of this.CARTEGGI_OLTRE_12_BASE)
      if (!this.COUNTER['carteggio_oltre_12'].find(e1 => e1.id == e.id) && e.argomento)
        this.COUNTER['carteggio_oltre_12'].push({ id: e.id, n: 0, capitolo: e.argomento })


    if (quiz)
      switch (quiz.type) {

        case 'quiz_base':
        case 'quiz_vela':

          for (const e of quiz.questions) {

            let i = this.COUNTER[quiz.type].findIndex(e1 => e1.id == e.id)
            this.COUNTER[quiz.type][i].n++
            this.COUNTER[quiz.type][i].lastError = !e.correct

          }

          break;

        case 'carteggio_entro_12':
        case 'carteggio_oltre_12':

          let i = this.COUNTER[quiz.type].findIndex(e1 => e1.id == quiz.carteggio.id)
          this.COUNTER[quiz.type][i].n++
          this.COUNTER[quiz.type][i].lastError = quiz.perc < 100

          break;

      }

    this.COUNTER.id = this.auth.profileUid

    for (const type in this.COUNTER) {
      for (const row of this.COUNTER[type]) {
        for (const key in row) {
          if (row[key] === undefined)
            console.log(row)
        }
      }
    }



    //console.log(this.COUNTER)

    //console.log('counter', JSON.stringify(this.COUNTER))

    await this.firestore.doc(`${COUNTER_COLLECTION}/${this.COUNTER.id}`).set(this.COUNTER)
      .then(res => console.log('counter updated'))
      .catch(err => console.error(err))

  }

  resetCounterVela(){
    this.COUNTER.quiz_vela = []
    return this.firestore.doc(`${COUNTER_COLLECTION}/${this.COUNTER.id}`).set(this.COUNTER)
    .then(res => console.log('counter updated'))
    .catch(err => console.error(err))
  }

  resetCounterBase(){
    this.COUNTER.quiz_base = []
    return this.firestore.doc(`${COUNTER_COLLECTION}/${this.COUNTER.id}`).set(this.COUNTER)
    .then(res => console.log('counter updated'))
    .catch(err => console.error(err))
  }

  getQuizBase(id: string): AngularFirestoreDocument {
    return this.firestore.doc(`${QUIZ_BASE_COLLECTION}/${id}`);
  }

  listQuizUtente(user = this.auth.getEmail()) {
    return this.firestore.collection(QUIZ_COLLECTION, el => el.where('user', '==', user));
  }

  listQuiz(): AngularFirestoreCollection {
    return this.firestore.collection(QUIZ_COLLECTION);
  }


  listSharedQuiz(user = this.auth.getEmail()): AngularFirestoreCollection {
    return this.firestore.collection(QUIZ_COLLECTION,
      el => el
        .where('user', '==', user)
        .where('shared', '==', true)
        .where('finished', '==', false));
  }

  deleteQuiz(id: string): Promise<void> {
    return this.firestore.doc(`${QUIZ_COLLECTION}/${id}`).delete();
  }

  getResult(id: string) {
    return this.firestore.doc(`${QUIZ_COLLECTION}/${id}`);
  }

  getQuizById(id: string) {
    return this.firestore.doc(`${QUIZ_COLLECTION}/${id}`);
  }

  listClassi(overrideUser = undefined) {
    return this.firestore.collection(CLASSI_COLLECTION, el => el.where('nautica', '==', overrideUser ? overrideUser : this.auth.profileUid));
  }

  listUserByClasse(id, overrideUser = undefined) {
    return this.firestore.collection(CLASSI_COLLECTION, 
      el => el
      .where('nautica', '==', overrideUser ? overrideUser : this.auth.profileUid)
      .where('id', '==', id)
      );
  }


  upsertClasse(classe) {
    if (!classe.id) {
      const i = this.firestore.createId();
      classe.id = i;
    }
    this.firestore.collection(CLASSI_COLLECTION).doc(classe.id).set(classe, { merge: true });
  }
  upsertUser(user) {
    return this.firestore.collection(UTENTI_COLLECTION).doc(user.id).set(user, { merge: true });
  }
  listUsersClassi(idClasse) {
    return this.firestore.collection(UTENTI_COLLECTION, el => el.where('classe', '==', idClasse));
  }



  getProfile() {
    return this.firestore.collection(UTENTI_COLLECTION, el => el.where('email', '==', this.auth.getUserId()));
  }


  imageExists(url) {

    return new Promise((resolve) => {

      const img = new Image();

      img.onload = () => { resolve(true); };
      img.onerror = () => { resolve(false); };
      img.src = url;

    });

  }



  uploadProgress = 0;
  async uploadFile(content: string, path: string, filename: string, contentType = 'image/jpg') {

    const uploadTask = firebase.storage().ref(path).child(filename).putString(content, 'base64', { contentType: contentType });

    return uploadTask;

    /*
    await uploadTask.then(async res => {

      localStorage.removeItem(itemId)

    }).catch(err => {
      //alert(JSON.stringify(err))
    })

    return true

    */
  }


  deleteFile(file) {
    console.log('deleting ' + file);

    return this.storage.ref(file).delete()

  }

  getRandomFilename() {
    return `${new Date().getTime()}_${Math.random().toString(36).substring(2, 8)}`;
  }

  getFileURL(file) {
    return this.storage.ref(file).getDownloadURL().toPromise();
  }


  setCondizioni(b) {
    this.auth.okCondizioni = b
    return this.firestore.doc(`${UTENTI_COLLECTION}/${this.auth.profileUid}`).set({ okCondizioni: b, dtaCondizioni: new Date() }, { merge: true })
  }
  setPrivacy(b) {
    this.auth.okPrivacy = b
    return this.firestore.doc(`${UTENTI_COLLECTION}/${this.auth.profileUid}`).set({ okPrivacy: b, dtaPrivacy: new Date() }, { merge: true })
  }

}


export const QUIZ_BASE_COLLECTION = 'quiz_base';
export const QUIZ_TEMPLATE_COLLECTION = 'quiz_template';
export const QUIZ_VELA_BASE_COLLECTION = 'quiz_vela_base';
export const QUIZ_COLLECTION = 'quiz';
export const UTENTI_COLLECTION = 'users';
export const CLASSI_COLLECTION = 'classi';
export const CARTEGGI_ENTRO_12_BASE_COLLECTION = 'carteggi_entro_12_base';
export const CARTEGGI_OLTRE_12_BASE_COLLECTION = 'carteggi_oltre_12_base';
export const COUNTER_COLLECTION = 'counter'
export const COUNTER_TEMPLATE = 'counter_template'