import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AdminGuard, LoginGuard, PaymentGuard, PrivacyGuard, SchoolGuard } from './guards';
import { PaymentManagerPage } from './payment-manager/payment-manager.page';

const routes: Routes = [


  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },



  {
    path: 'sign-in',
    loadChildren: () => import('./sign-in/sign-in.module').then(m => m.SingInPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./sign-up/sign-up.module').then(m => m.SignUpPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'question/:cap',
    loadChildren: () => import('./question/question.module').then(m => m.QuestionPageModule),
    canActivate: [LoginGuard, PrivacyGuard]
  },
  {
    path: 'question/id/:id',
    loadChildren: () => import('./question/question.module').then(m => m.QuestionPageModule),
    canActivate: [LoginGuard, PrivacyGuard]
  },
  {
    path: 'test-result',
    loadChildren: () => import('./test-result/test-result.module').then(m => m.TestResultPageModule),
    canActivate: [LoginGuard, PrivacyGuard]
  },
  {
    path: 'leaderboard',
    loadChildren: () => import('./leaderboard/leaderboard.module').then(m => m.LeaderboardPageModule),
    canActivate: [LoginGuard, PrivacyGuard, PaymentGuard]
  },
  {
    path: 'leaderboard/:email',
    loadChildren: () => import('./leaderboard/leaderboard.module').then(m => m.LeaderboardPageModule),
    canActivate: [LoginGuard, PrivacyGuard, PaymentGuard]
  },
  {
    path: 'stats',
    loadChildren: () => import('./stats/stats.module').then(m => m.StatsPageModule),
    canActivate: [LoginGuard, PrivacyGuard, PaymentGuard]
  },
  {
    path: 'bookmarks',
    loadChildren: () => import('./bookmarks/bookmarks.module').then(m => m.BookmarksPageModule),
    canActivate: [LoginGuard, PrivacyGuard, PaymentGuard]
  },
  {
    path: 'carteggi-entro-12/:cap',
    loadChildren: () => import('./carteggi-entro-12/carteggi-entro-12.module').then(m => m.CarteggiEntro12Module),
    canActivate: [LoginGuard, PrivacyGuard, PaymentGuard]
  },
  {
    path: 'carteggi-oltre-12/:cap',
    loadChildren: () => import('./carteggi-oltre-12/carteggi-oltre-12.module').then(m => m.CarteggiOltre12Module),
    canActivate: [LoginGuard, PrivacyGuard, PaymentGuard]
  },
  {
    path: 'vela/:cap',
    loadChildren: () => import('./vela/vela.module').then(m => m.VelaPageModule),
    canActivate: [LoginGuard, PrivacyGuard, PaymentGuard]
  },
  {
    path: 'vela/id/:id',
    loadChildren: () => import('./vela/vela.module').then(m => m.VelaPageModule),
    canActivate: [LoginGuard, PrivacyGuard, PaymentGuard]
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfilePageModule),
    canActivate: [LoginGuard, PrivacyGuard]
  },
  {
    path: 'my-profile/:msg',
    loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfilePageModule),
    canActivate: [LoginGuard, PrivacyGuard]
  },
  {
    path: 'stats-class',
    loadChildren: () => import('./stats-class/stats-class.module').then(m => m.StatsClassPageModule),
    canActivate: [LoginGuard, PrivacyGuard, PaymentGuard]
  },
  {
    path: 'home-admin',
    loadChildren: () => import('./home-admin/home-admin.module').then(m => m.HomeAdminPageModule),
    canActivate: [AdminGuard, PrivacyGuard]
  },
  {
    path: 'attachments',
    loadChildren: () => import('./attachments/attachments.module').then(m => m.AttachmentsPageModule),
    canActivate: [PrivacyGuard, SchoolGuard]
  },
  {
    path: 'payment-manager/:id', component: PaymentManagerPage


  },
  ////////////////////////////////////////

  /*
    {
      path: 'notification',
      loadChildren: () => import('./notification/notification.module').then(m => m.NotificationPageModule)
    },
  
  
    {
      path: 'chat',
      loadChildren: () => import('./chat/chat.module').then(m => m.ChatPageModule)
    },
  
    {
      path: 'chat-list',
      loadChildren: () => import('./chat-list/chat-list.module').then(m => m.ChatListPageModule)
    },
  
    {
      path: 'calender',
      loadChildren: () => import('./calender/calender.module').then(m => m.CalenderPageModule)
    },
  
  */


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
