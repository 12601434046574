import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Platform } from '@ionic/angular';

import { SignInWithApple, SignInWithAppleOptions } from "@capacitor-community/apple-sign-in"
import firebase from 'firebase/compat/app';
import { GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";

import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { timeStamp } from 'console';
//import { FacebookLogin, FacebookLoginResponse } from '@capacitor-community/facebook-login';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  tipo
  ready = false

  public currentUser: firebase.User;
  public isAdmin = false;
  public okPrivacy
  public okCondizioni
  public stripeId
  public isPaid = false
  public isPrivato = false
  public fiscalcode
  public profileUid
  public classe
  public schoolId

  nameDisplayed
  constructor(
    public afAuth: AngularFireAuth,
    public platform: Platform,
    public firestore: AngularFirestore,

  ) {

    setTimeout(() => {
      this.ready = true
    }, 3000);

    this.afAuth.onAuthStateChanged(async currentUser => {
      //alert(JSON.stringify(currentUser))
      //if(currentUser && currentUser.photoURL) currentUser.photoURL = currentUser.photoURL.replace('https://','http://')


      this.currentUser = currentUser;
      this.loadProfile()


    });

  }

  async loadProfile() {

    console.log('load profile')


    //this.profileUid = undefined

    if (!this.currentUser) return

    console.log(this.currentUser)
    console.log(`users/${this.profileUid}`);


    await this.firestore.doc(`users/${this.currentUser.uid}`).valueChanges().subscribe((res: any) => {

      console.log(res);

      if (res) {

        this.stripeId = res.stripeId
        this.nameDisplayed = res.nome
        this.tipo = res.tipo
        this.isAdmin = res.tipo == 'ADMIN';
        this.isPrivato = res.tipo == 'PRIVATO'

        if (this.tipo == 'UTENTE') {
          res.isPaid = true
          this.isPaid = true
        }


        this.isPaid = res.isPaid;

        this.okPrivacy = res.okPrivacy === true;
        this.okCondizioni = res.okCondizioni === true;
        this.fiscalcode = res.fiscalcode;
        this.classe = res.classe;

        this.firestore.doc(`classi/${this.classe}`).valueChanges().subscribe((res: any) => {
          console.log('classe', res)
          this.schoolId = res?.nautica
        })



        this.profileUid = res.id

      }
      //u.unsubscribe();
    });


    //////////////////// FIX PER ACCESSO SOCIAL ////////////////
    console.log('profileUid1: ' + this.profileUid)
    if (!this.profileUid) {

      console.log(`email: ${this.currentUser?.email}`)
      setTimeout(async () => {
        let email = this.currentUser?.email ? this.currentUser?.email : this.currentUser?.providerData[0]?.email

        await this.firestore.collection(`users`, ref => ref.where('email', '==', email)).valueChanges().subscribe((resx: any[]) => {

          // alert(JSON.stringify(resx))


          let res = resx[0]

          console.log(res);

          if (res) {


            this.profileUid = res.id
            this.isPaid = res.isPaid;

            this.stripeId = res.stripeId
            this.nameDisplayed = res.nome
            this.tipo = res.tipo
            this.isAdmin = res.tipo == 'ADMIN';
            this.isPrivato = res.tipo == 'PRIVATO'
            this.okPrivacy = res.okPrivacy === true;
            this.okCondizioni = res.okCondizioni === true;
            this.fiscalcode = res.fiscalcode;
            this.classe = res.classe;

            this.firestore.doc(`classi/${this.classe}`).valueChanges().subscribe((res: any) => {
              console.log('classe', res)
              this.schoolId = res?.nautica
            })


          }
          //u.unsubscribe();
        });
      }, 500);


    }



    console.log('profileUid2: ' + this.profileUid)
    if (!this.profileUid) {
      setTimeout(() => {
        this.loadProfile()
      }, 1000);

      return
    }


    setTimeout(() => {
      this.ready = true
    }, 500);





  }


  getAdmin() {
    return this.isAdmin;
  }
  getAvatar() {



    try {

      if (this.currentUser?.photoURL) {
        // alert(this.currentUser.photoURL)
        console.log('img1: ' + this.currentUser.photoURL)
        return this.currentUser.photoURL;
      }
      if (this.currentUser?.providerData[0]?.photoURL) {
        // alert(this.currentUser?.providerData[0]?.photoURL)
        console.log('img2: ' + this.currentUser?.providerData[0]?.photoURL)
        return this.currentUser.providerData[0].photoURL;
      }

    } catch (error) {

      console.error(error)

    }

    return '/assets/images/noprofile.png';
  }

  getEmail(): string {
    //console.log(this.currentUser)

    //return 'info@nautiscuola.it'

    try {
      if (this.currentUser.email) { return this.currentUser.email; }
      if (this.currentUser.providerData[0].email) { return this.currentUser.providerData[0].email; }
    } catch (error) {

    }


    return null;
  }

  getUserId() {
    return this.getEmail();
  }



  getUserName(): string {

    try {
      if (this.currentUser.displayName && this.currentUser.displayName != '') { return this.currentUser.displayName; }
      if (this.currentUser.providerData[0].displayName && this.currentUser.providerData[0].displayName != '') { return this.currentUser.providerData[0].displayName; }
      if (this.nameDisplayed && this.nameDisplayed != '') return this.nameDisplayed
      return this.getUserId();
    } catch (error) {

    }

    return undefined;

  }

  signOut() {


    this.isPaid = false
    this.isAdmin = false
    this.profileUid = undefined
    this.isAdmin = false;
    this.okPrivacy = undefined;
    this.okCondizioni = undefined;

    if (this.platform.is('capacitor')) {
      try {
        GoogleAuth.signOut()
      } catch (error) {

      }
    }

    this.currentUser = undefined;
    return this.afAuth.signOut();
  }

  signInWithEmail(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  createUserWithEmail(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  deleteAccount() {
    this.afAuth.currentUser.then(res => res?.delete())
    this.signOut()
  }

  resetPassword(email) {
    return this.afAuth.sendPasswordResetEmail(email)
  }



  /*
  async signInWithFacebook() {

    const FACEBOOK_PERMISSIONS = ['email', 'user_birthday'];

    let fbUser: FacebookLoginResponse = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS })
    const credential = FacebookAuthProvider.credential(fbUser.accessToken.token);
    return this.afAuth.signInAndRetrieveDataWithCredential(credential)

  }

*/


  /*
    signInWithTwitter() {
      const provider = new firebase.auth.TwitterAuthProvider();
      return this.socialSignIn(provider.providerId);
    }
  */

  async signInWithGoogle() {


    if (this.platform.is('capacitor')) {
      const googleUser = await GoogleAuth.signIn();
      console.log('my user: ', googleUser);
      const credential = GoogleAuthProvider.credential(googleUser.authentication.idToken);
      return this.afAuth.signInWithCredential(credential)
    } else {
      const provider = new firebase.auth.GoogleAuthProvider();
      const scopes = ['profile', 'email'];
      return this.socialSignIn(provider.providerId, scopes);
    }
  }

  async logInWithApple() {
    let options: SignInWithAppleOptions = {
      clientId: '1098716540546-mtj3u5bl8ep702hba1ko4dd8tfo7qt9n.apps.googleusercontent.com',
      redirectURI: 'https://www.yourfrontend.com/login',
      scopes: 'email name',
      //state: '12345',
      //nonce: 'nonce',
    };
    const { response } = await SignInWithApple.authorize(options);
    let provider = new firebase.auth.OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");
    const credential = provider.credential({
      idToken: response.identityToken,
    });
    return this.afAuth.signInWithCredential(credential)

  }


  socialSignIn(providerName: string, scopes?: Array<string>): Promise<any> {
    const provider = new firebase.auth.OAuthProvider(providerName);

    // add any permission scope you need
    if (scopes) {
      scopes.forEach(scope => {
        provider.addScope(scope);
      });
    }

    provider.setCustomParameters({
      prompt: 'select_account'
    });

    if (this.platform.is('desktop')) {
      return this.afAuth.signInWithPopup(provider);
    } else {
      // web but not desktop, for example mobil

      return this.afAuth.signInWithRedirect(provider);
    }
  }



}
