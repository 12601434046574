import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'capitalize' })
export class CapitalizePipe implements PipeTransform {
    transform(s: string) {
        try {
            const r = s[0].toLocaleUpperCase() + s.substring(1).toLocaleLowerCase();
            return r;
        } catch (error) {
            return s;
        }

    }
}
