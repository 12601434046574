import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-latlon',
  templateUrl: './latlon.component.html',
  styleUrls: ['./latlon.component.scss'],
})
export class LatlonComponent implements OnInit {

  r = ['', '', '', '', '', '', '']

  @Input() label = 'xxx'
  @Output() onResult = new EventEmitter<string>();

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() { }


  async openModal(i) {

    let type = ''
    switch (i) {
      case 0:
      case 2:
      case 4:
        type = 'number'
        break


      case 5:
        type = "nsoe"
        break

      default:
        type = 'symbols'



    }

    const modal = await this.modalCtrl.create({
      component: LatLonModalComponent,
      componentProps: { data: { text: this.r.join(' '), type: type } }
    });


    modal.present();

    const { data, role } = await modal.onWillDismiss();

    this.r[i] = data

    this.onResult.emit(`${this.r[0]}${this.r[1]} ${this.r[2]}${this.r[3]},${this.r[4]} ${this.r[5]}`)

  }

}


@Component({
  selector: 'app-latlon-modal',
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>{{data?.text}}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

      <br><br>
      <div  *ngIf="data.type=='number'">
            <ion-item>
              <ion-label position="stacked">Digita il valore</ion-label>
              <ion-input type="string"  [(ngModel)]="value"></ion-input>
            </ion-item>

            <ion-grid>
            <ion-row>
              <ion-col size="6">
                <ion-button color="danger" expand="block" (click)="cancel()">Annulla</ion-button>
              </ion-col>

              <ion-col size="6">
                <ion-button color="success" expand="block" (click)="confirm()" [strong]="true">Ok</ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
      </div>


      <ion-grid *ngIf="data.type=='nsoe'"> 
        <ion-row>
          <ion-col size="4" class="big"></ion-col>
          <ion-col size="4" class="big"> <ion-button expand="block" (click)="confirm('N')">N</ion-button></ion-col>
          <ion-col size="4" class="big"></ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="4" class="big"> <ion-button expand="block" (click)="confirm('O')">O</ion-button></ion-col>
          <ion-col size="4" class="big"></ion-col>
          <ion-col size="4" class="big"> <ion-button expand="block" (click)="confirm('E')">E</ion-button></ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="4" class="big"></ion-col>
          <ion-col size="4" class="big"> <ion-button expand="block" (click)="confirm('S')">S</ion-button></ion-col>
          <ion-col size="4" class="big"></ion-col>
        </ion-row>
      </ion-grid>


      <ion-grid *ngIf="data.type=='symbols'">
        <ion-row>
        <ion-col size="4" *ngFor="let s of symbols"> 
          <ion-button expand="block" class="big" (click)="confirm(s)">{{s}}</ion-button>
        </ion-col>
        </ion-row>
      </ion-grid>



    </ion-content>
  `,
  styles: [`
    ion-button.big {
        font-size: 300%;
    }
  `]
})
export class LatLonModalComponent {

  symbols = ['*', '°', '-', '.', ',', "'"]
  value = '';
  data

  constructor(private modalCtrl: ModalController) { }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm(value = this.value) {
    return this.modalCtrl.dismiss(value, 'confirm');
  }

}
