import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { timingSafeEqual } from 'crypto';
import { AuthService } from 'src/services/auth.service';

@Injectable()
export class LoginGuard implements CanActivate {
    constructor(
        private auth: AuthService,
        private toastController: ToastController,
        private router: Router,
    ) { }
    canActivate() {

        const b = this.auth.getUserId() != undefined;

        if (!b) {
            /*
                        console.error('Forbidden')

                        this.toastController.create({
                            message: 'Forbidden',
                            duration: 3000,
                            position: 'middle',
                            color:'danger',
                        }).then(t => t.present())
            */
            setTimeout(() => {
                this.router.navigate(['sign-in']);

            }, 100);
        }

        return b;
    }

}

@Injectable()
export class AdminGuard implements CanActivate {
    constructor(
        private auth: AuthService,
        private toastController: ToastController,
        private router: Router
    ) { }
    canActivate() {

        const b = this.auth.isAdmin;


        if (!b) {

            this.router.navigate(['home']);


        }

        return b;
    }
}


@Injectable()
export class SchoolGuard implements CanActivate {
    constructor(
        private auth: AuthService,
        private toastController: ToastController,
        private router: Router
    ) { }
    canActivate() {

        const b = !this.auth.isPrivato;
        if (!b) {

            this.router.navigate(['home']);


        }

        console.log('SchoolGuard ' + b)

        return b;
    }
}



@Injectable()
export class PrivacyGuard implements CanActivate {
    constructor(
        private auth: AuthService,
        private toastController: ToastController,
        private router: Router,
    ) { }
    canActivate() {

        const b = this.auth.okPrivacy;
        const c = this.auth.okCondizioni
        console.log('PrivacyGuard ' + b)

        let ok = c === true && b === true

        if (!ok) {
            /*
                        console.error('Forbidden')

                        this.toastController.create({
                            message: 'Forbidden',
                            duration: 3000,
                            position: 'middle',
                            color:'danger',
                        }).then(t => t.present())
            */
            this.router.navigate(['']);
        }

        return ok;
    }

}


@Injectable()
export class PaymentGuard implements CanActivate {

    noRedirect = false

    constructor(
        private auth: AuthService,
        private router: Router,
    ) { }

    canActivate() {

        // console.log(this.auth.isPaid, this.auth.isAdmin)

        const b = this.auth.isPaid || this.auth.isAdmin;

        // console.log('PaymentGuard ' + b)

        if (!b) {
            if (!this.noRedirect) this.router.navigate(['']);
        }

        return b;
    }

}
