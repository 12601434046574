import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentGuard } from 'src/app/guards';
import { AuthService } from 'src/services/auth.service';

@Component({
  selector: 'app-locked',
  templateUrl: './locked.component.html',
  styleUrls: ['./locked.component.scss'],
})
export class LockedComponent implements OnInit {

  paymentGuard

  constructor(private router: Router, private auth: AuthService) {




  }

  isLocked() {

    if (!this.paymentGuard) {
      this.paymentGuard = new PaymentGuard(this.auth, this.router)
      this.paymentGuard.noRedirect = true
    }

    return !this.paymentGuard.canActivate()
  }

  ngOnInit() { }

}
