import { Component, Inject } from '@angular/core';
import { Platform, NavController, ModalController, MenuController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG, AppConfig } from './app.config';

import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';


//import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { Constants } from '../models/contants.models';
import { MyEvent } from 'src/services/myevent.services';
import { AuthService } from 'src/services/auth.service';


import { Capacitor } from '@capacitor/core';
import { initializeApp } from 'firebase/app';
import { indexedDBLocalPersistence, initializeAuth } from 'firebase/auth';
import { firebaseConfig } from './app.module';
//import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx'; 
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  rtlSide = 'left';
  selectedIndex: any;
  appPages: any;
  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    public platform: Platform,
    private navCtrl: NavController,
    //private statusBar: StatusBar,
    private modalController: ModalController,
    private translate: TranslateService,
    private myEvent: MyEvent,
    private menu: MenuController,
    public auth: AuthService,
    //TEST UPDATE private store: InAppPurchase2,

  ) {
    this.initializeApp();
    this.myEvent.getLanguageObservable().subscribe(value => {

      // this.navCtrl.navigateRoot(['./']);
      this.globalize(value);
    });





  }



  initializeApp() {

    //this.navCtrl.navigateRoot(['./']);

    this.platform.ready().then(() => {

    

      this.showSplash()


      //SplashScreen.show();
      /*
      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('black');
      */
      const defaultLang = window.localStorage.getItem(Constants.KEY_DEFAULT_LANGUAGE);
      this.globalize(defaultLang);

      this.hideSplash()

    });
  }

   
  async showSplash() {

    if (this.platform.is('capacitor'))
      await StatusBar.setBackgroundColor({ color: '#6A36C5' })

    // Hide the splash (you should do this on app launch)
    await SplashScreen.hide();


    // Show the splash for two seconds and then automatically hide it:
    /*
    await SplashScreen.show({
      showDuration: 2000,
      autoHide: true,
    });
    */
  }

  hideSplash() {

    if (!this.auth.ready) {
      setTimeout(() => {
        this.hideSplash()
      }, 100);
    } else {
      setTimeout(() => SplashScreen.hide(), 200);
    }


  }

  globalize(languagePriority) {
    this.translate.setDefaultLang('it');
    const defaultLangCode = this.config.availableLanguages[7].code;
    this.translate.use(languagePriority && languagePriority.length ? languagePriority : defaultLangCode);
    this.setDirectionAccordingly(languagePriority && languagePriority.length ? languagePriority : defaultLangCode);
  }

  setDirectionAccordingly(lang: string) {
    switch (lang) {
      case 'ar': {
        this.rtlSide = 'rtl';
        break;
      }
      default: {
        this.rtlSide = 'ltr';
        break;
      }
    }
  }


  language(): void {
    this.navCtrl.navigateRoot(['./language']);
  }

  navigate(string) {

    sessionStorage.removeItem('classe')

    this.navCtrl.navigateRoot(['./' + string]);
    this.menu.close();
  }

  async logout() {
    await this.auth.signOut();
    this.navCtrl.navigateRoot(['sign-in']);
  }
}


