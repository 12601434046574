export const DATA_QUIZ_BASE: any[] = [
    {
        "id": 1,
        "ris_2": "F",
        "domanda": "Com'è denominata la massima lunghezza dell'unità navale, cioè quella misurata tra le estremità prodiera e poppiera?",
        "immagine": "",
        "progressivo": "1.1.1-1",
        "risposta_2": "lunghezza al galleggiamento.",
        "risposta_1": "lunghezza tra le perpendicolari.",
        "ris_1": "F",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_3": "V",
        "risposta_3": "lunghezza fuori tutto."
    },
    {
        "id": 10,
        "ris_3": "F",
        "risposta_1": "specchio di poppa.",
        "domanda": "Con riferimento alla figura, quale porzione dello scafo è indicata dalle linee trasversali?",
        "risposta_3": "opera morta.",
        "capitolo": "TEORIA DELLO SCAFO",
        "progressivo": "1.1.1-10",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "immagine": 1,
        "ris_1": "F",
        "ris_2": "V",
        "risposta_2": "opera viva."
    },
    {
        "ris_3": "F",
        "domanda": "Con elica sinistrorsa, se manovro in marcia indietro:",
        "immagine": "",
        "progressivo": "1.1.2-25",
        "id": 100,
        "risposta_2": "con il timone al centro, la poppa accosta a dritta.",
        "ris_2": "V",
        "risposta_3": "l'unità procede dritta.",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "risposta_1": "con il timone al centro, la poppa accosta a sinistra.",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_1": "F"
    },
    {
        "ris_2": "V",
        "ris_3": "F",
        "domanda": "Navigando con Rv 090 gradi, rimane invariata:",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "F",
        "risposta_3": "la longitudine.",
        "risposta_2": "la latitudine.",
        "id": 1000,
        "argomento": "Coordinate geografiche",
        "risposta_1": "la declinazione magnetica.",
        "immagine": "",
        "progressivo": "1.7.1-34"
    },
    {
        "argomento": "Coordinate geografiche",
        "risposta_3": "di equatore compreso tra il meridiano di Greenwich ed il meridiano passante per il punto considerato.",
        "risposta_2": "compreso tra l’equatore ed il polo.",
        "id": 1001,
        "domanda": "La latitudine di un punto è l'arco:",
        "ris_3": "F",
        "progressivo": "1.7.1-35",
        "ris_2": "F",
        "immagine": "",
        "risposta_1": "di meridiano compreso tra l’equatore ed il punto (o il parallelo passante per il punto considerato).",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "V"
    },
    {
        "ris_3": "F",
        "ris_1": "F",
        "argomento": "Coordinate geografiche",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "di equatore compreso tra il meridiano di Greenwich ed il meridiano passante per il punto considerato.",
        "immagine": "",
        "risposta_3": "di meridiano compreso tra l’equatore ed il punto (o parallelo passante per il punto considerato).",
        "progressivo": "1.7.1-36",
        "id": 1002,
        "ris_2": "V",
        "domanda": "La longitudine di un punto è l'arco:",
        "risposta_1": "compreso tra il meridiano di Greenwich ed il suo antimeridiano."
    },
    {
        "risposta_3": "180, di cui 90 contati di grado in grado dall'equatore (zero gradi) al polo Nord e altrettanti 90 contati di grado in grado dall'equatore (zero gradi) al polo Sud, però possiamo tracciarne infiniti.",
        "id": 1003,
        "ris_2": "F",
        "ris_1": "F",
        "progressivo": "1.7.1-37",
        "ris_3": "V",
        "domanda": "Per convenzione si dice che i paralleli sono:",
        "immagine": "",
        "argomento": "Coordinate geografiche",
        "risposta_1": 181,
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "tanti quanti sono i meridiani."
    },
    {
        "risposta_2": "tanti quanti sono i paralleli.",
        "ris_1": "F",
        "progressivo": "1.7.1-38",
        "ris_2": "F",
        "ris_3": "V",
        "argomento": "Coordinate geografiche",
        "immagine": "",
        "risposta_3": "360, di cui 180 contati di grado in grado a partire dal meridiano di Greenwich verso Est ed altrettanti 180 contati di grado in grado a partire dal meridiano di Greenwich verso Ovest, però possiamo tracciarne infiniti.",
        "risposta_1": 361,
        "domanda": "Per convenzione si dice che i meridiani sono:",
        "id": 1004,
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA"
    },
    {
        "risposta_2": "a quello che si trova nel punto ove si incrociano il meridiano di Greenwich e l’equatore.",
        "id": 1005,
        "domanda": "Il meridiano \"zero\" corrisponde:",
        "immagine": "",
        "progressivo": "1.7.1-39",
        "ris_2": "F",
        "argomento": "Coordinate geografiche",
        "risposta_1": "a quello comunemente chiamato meridiano di Greenwich.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "F",
        "risposta_3": "a quello comunemente chiamato equatore.",
        "ris_1": "V"
    },
    {
        "id": 1006,
        "domanda": "Il novantesimo parallelo si trova:",
        "immagine": "",
        "risposta_3": "all’equatore.",
        "ris_2": "F",
        "risposta_2": "a metà tra il polo e l'equatore.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "V",
        "risposta_1": "al polo.",
        "progressivo": "1.7.1-40",
        "argomento": "Coordinate geografiche",
        "ris_3": "F"
    },
    {
        "ris_1": "F",
        "argomento": "Coordinate geografiche",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "Il novantesimo meridiano:",
        "ris_3": "V",
        "risposta_1": "è l’antimeridiano ovvero il meridiano opposto al meridiano di Greenwich.",
        "immagine": "",
        "risposta_2": "passa per Greenwich.",
        "risposta_3": "ricade esattamente a metà tra il meridiano di Greenwich ed il suo antimeridiano, cioè nel cardine Est ovvero nel cardine Ovest.",
        "id": 1007,
        "progressivo": "1.7.1-41",
        "ris_2": "F"
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "progressivo": "1.7.1-42",
        "risposta_3": "è sud se si trova su un parallelo.",
        "risposta_2": "è sud se vediamo i valori di longitudine diminuire verso il Nord.",
        "ris_1": "V",
        "ris_3": "F",
        "id": 1008,
        "argomento": "Coordinate geografiche",
        "immagine": "",
        "ris_2": "F",
        "risposta_1": "è nord se vediamo i valori di latitudine aumentare verso il Nord.",
        "domanda": "Leggo sulla carta nautica in proiezione di mercatore che un’isola si trova a 45 gradi di latitudine: è Nord o Sud?"
    },
    {
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Coordinate geografiche",
        "ris_1": "V",
        "risposta_1": "australe.",
        "id": 1009,
        "progressivo": "1.7.1-43",
        "immagine": "",
        "risposta_3": "settentrionale.",
        "risposta_2": "boreale.",
        "ris_3": "F",
        "domanda": "L'emisfero sud è quello:"
    },
    {
        "risposta_3": "è destrorsa anch'essa.",
        "id": 101,
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "ris_1": "V",
        "ris_2": "F",
        "risposta_2": "può essere indifferentemente destrorsa o sinistrorsa.",
        "domanda": "Se un'unità da diporto a motore bielica ha a sinistra un'elica sinistrorsa, si può ragionevolmente ritenere che quella di dritta:",
        "immagine": "",
        "progressivo": "1.1.2-26",
        "ris_3": "F",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_1": "è destrorsa."
    },
    {
        "ris_1": "V",
        "ris_3": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "il punto si trova esattamente sull'equatore.",
        "ris_2": "F",
        "domanda": "Se la longitudine di un punto misura 0° significa che:",
        "id": 1010,
        "risposta_1": "il punto si trova esattamente sul meridiano di Greenwich.",
        "immagine": "",
        "risposta_3": "il punto si trova sul parallelo del tropico del cancro.",
        "argomento": "Coordinate geografiche",
        "progressivo": "1.7.1-44"
    },
    {
        "risposta_1": "il punto si trova sull'equatore.",
        "progressivo": "1.7.1-45",
        "risposta_3": "il punto si trova al Polo Nord.",
        "ris_3": "F",
        "id": 1011,
        "domanda": "Se la latitudine di un punto misura 0° significa che:",
        "ris_1": "V",
        "argomento": "Coordinate geografiche",
        "risposta_2": "il punto si trova sul meridiano di Greenwich.",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": ""
    },
    {
        "domanda": "A quali aree marittime si riferiscono le carte e pubblicazioni nautiche edite dall'I.I.M.M.?",
        "risposta_3": "ai mari ed alle coste nazionali italiane nonché a quelle del Mar Mediterraneo.",
        "ris_2": "F",
        "id": 1012,
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "risposta_1": "ai mari ed alle coste nazionali italiane.",
        "risposta_2": "a tutti i mari del mondo.",
        "progressivo": "1.7.2-1",
        "ris_3": "V",
        "ris_1": "F"
    },
    {
        "id": 1013,
        "domanda": "In una carta di Mercatore, la scala delle latitudini rimane la stessa per tutta la carta?",
        "ris_2": "F",
        "immagine": "",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "risposta_3": "no, non è costante ed aumenta con la latitudine.",
        "ris_1": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "no, non è costante e diminuisce con la latitudine.",
        "risposta_1": "sì.",
        "ris_3": "V",
        "progressivo": "1.7.2-2"
    },
    {
        "ris_1": "F",
        "ris_2": "F",
        "immagine": "",
        "ris_3": "V",
        "risposta_1": "sulle \"Tavole Nautiche\".",
        "risposta_3": "su un lato a margine della stessa carta nautica.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "progressivo": "1.7.2-3",
        "domanda": "Gli aggiornamenti alla carta nautica si riportano:",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "risposta_2": "nella Legenda del titolo.",
        "id": 1014
    },
    {
        "ris_1": "F",
        "domanda": "La carta di Mercatore:",
        "risposta_3": "rappresenta le zone polari.",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "ris_3": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "V",
        "risposta_1": "è una modifica della carta gnomonica operata dallo spagnolo Mercatore.",
        "progressivo": "1.7.2-4",
        "immagine": "",
        "id": 1015,
        "risposta_2": "in essa i paralleli hanno distanza variabile in funzione delle latitudini crescenti."
    },
    {
        "id": 1016,
        "risposta_3": "carte stereografiche, carte topografiche, carte astronomiche, carte gnomoniche e carte iperboliche.",
        "domanda": "A seconda della scala, in quali categorie si possono suddividere le carte nautiche?",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "ris_3": "F",
        "immagine": "",
        "progressivo": "1.7.2-5",
        "risposta_1": "carte planetarie, carte astronomiche, carte satellitari, carte radiogoniometriche e carte sinottiche.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "F",
        "ris_2": "V",
        "risposta_2": "carte generali, carte di atterraggio, carte costiere, carte dei litorali e piani nautici."
    },
    {
        "domanda": "Qual è la scala delle carte nautiche generali?",
        "progressivo": "1.7.2-6",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "risposta_3": "1:300.000 e inferiore.",
        "ris_3": "F",
        "risposta_2": "1:3.000.000 e inferiore.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1017,
        "immagine": "",
        "ris_2": "V",
        "risposta_1": "1:100.000 e inferiore.",
        "ris_1": "F"
    },
    {
        "ris_3": "F",
        "ris_2": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_3": "carte utilizzate dalle Stazioni Radio Costiere.",
        "risposta_1": "carte utilizzate dal navigante durante la navigazione svolta nei bacini portuali.",
        "immagine": "",
        "progressivo": "1.7.2-7",
        "domanda": "Come possono definirsi le carte nautiche costiere?",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "risposta_2": "carte utilizzate dal navigante principalmente durante la fase prossima all'atterraggio e che rappresentano in modo particolarmente dettagliato elementi afferenti le batimetriche ed il segnalamento marittimo.",
        "id": 1018,
        "ris_1": "F"
    },
    {
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "id": 1019,
        "ris_1": "F",
        "risposta_2": "consentono di individuare la presenza di relitti.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "delimitano le aree in cui è vietato l'ancoraggio.",
        "ris_2": "F",
        "progressivo": "1.7.2-8",
        "risposta_3": "sono linee di ugual fondale.",
        "domanda": "Le linee batimetriche:",
        "ris_3": "V",
        "immagine": ""
    },
    {
        "id": 102,
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_1": "F",
        "ris_2": "V",
        "progressivo": "1.1.2-27",
        "domanda": "Il timone è compensato quando:",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "risposta_1": "è dotato di sistema di comando idraulico.",
        "risposta_3": "è fissato allo specchio di poppa a mezzo di cerniere.",
        "ris_3": "F",
        "risposta_2": "una parte della pala è a proravia dell'asse.",
        "immagine": ""
    },
    {
        "risposta_3": "con rette convergenti verso il polo.",
        "immagine": "",
        "ris_1": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1020,
        "domanda": "Come sono rappresentati i meridiani sulla carta di Mercatore?",
        "risposta_1": "con rette parallele tra loro, ma non equidistanti.",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "ris_3": "F",
        "risposta_2": "con rette perpendicolari all'equatore ed equidistanti fra loro.",
        "progressivo": "1.7.2-9",
        "ris_2": "V"
    },
    {
        "risposta_3": "la carta dei litorali.",
        "progressivo": "1.7.2-10",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "la carta a grande scala.",
        "ris_3": "F",
        "immagine": "",
        "ris_2": "F",
        "id": 1021,
        "risposta_1": "la carta a piccola scala.",
        "domanda": "Quale carta non è usata per condurre la navigazione costiera?",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "ris_1": "V"
    },
    {
        "domanda": "Quale caratteristica possiede la carta di Mercatore?",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "ris_2": "F",
        "ris_1": "V",
        "id": 1022,
        "risposta_2": "la proiezione gnomonica, che mostra i paralleli radiali.",
        "risposta_3": "la proiezione gnomonica, che mostra i meridiani radiali.",
        "immagine": "",
        "ris_3": "F",
        "progressivo": "1.7.2-11",
        "risposta_1": "l'isogonia, perché conserva inalterati gli angoli formati da meridiani e paralleli."
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "I \"pianetti\" sono utilizzati per:",
        "id": 1023,
        "immagine": "",
        "ris_2": "V",
        "progressivo": "1.7.2-12",
        "risposta_2": "conoscere l'entrata dei porti ed altre informazioni quali la dislocazione delle banchine, i punti di ormeggio, i fondali presenti, ecc.",
        "risposta_1": "conoscere le correnti marine e svolgere i relativi problemi.",
        "ris_3": "F",
        "ris_1": "F",
        "risposta_3": "la condotta della navigazione in avvicinamento della costa.",
        "argomento": "Carte nautiche e proiezione di Mercatore"
    },
    {
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "ris_2": "F",
        "ris_1": "V",
        "domanda": "Che tipo di carta è il piano nautico in relazione alla scala della carta?",
        "risposta_3": "carta a grande scala, quale quella con scala 1:1.000.000.",
        "risposta_2": "carta a grande scala riproducente aree di elevate estensioni come mari e continenti.",
        "ris_3": "F",
        "progressivo": "1.7.2-13",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "id": 1024,
        "risposta_1": "carta a grande scala riproducente aree di limitate estensioni come porti, rade, isolotti."
    },
    {
        "risposta_2": "no, è utilizzabile per pianificare una traversata oceanica.",
        "progressivo": "1.7.2-14",
        "id": 1025,
        "ris_3": "F",
        "immagine": "",
        "risposta_1": "sempre.",
        "risposta_3": "sì, perché è prescritta per una navigazione a corto raggio.",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "Si usa la carta gnomonica per la navigazione costiera stimata?",
        "ris_1": "F",
        "ris_2": "V"
    },
    {
        "id": 1026,
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "Quali, tra quelli sotto elencati, sono riportati sulla carta nautica edita dall'I.I.M.M.?",
        "ris_2": "F",
        "ris_1": "F",
        "progressivo": "1.7.2-15",
        "ris_3": "V",
        "risposta_2": "le caratteristiche dei venti nella zona rappresentata.",
        "risposta_3": "simboli indicanti la natura del fondo marino.",
        "risposta_1": "simboli indicanti la natura del suolo terrestre.",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "immagine": ""
    },
    {
        "risposta_3": "la carta mantiene il rapporto tra le aree.",
        "risposta_2": "la carta mantiene gli angoli della realtà.",
        "immagine": "",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "domanda": "In cosa consiste la proprietà della isogonia di una carta nautica?",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "progressivo": "1.7.2-16",
        "ris_1": "F",
        "risposta_1": "la carta mantiene il rapporto tra gli angoli, ma solo in ristrette fasce di latitudine e a date condizioni.",
        "ris_3": "F",
        "id": 1027,
        "ris_2": "V"
    },
    {
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "ris_2": "V",
        "risposta_3": "del porto principale cui si riferiscono.",
        "ris_3": "F",
        "id": 1028,
        "risposta_2": "della scala.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "progressivo": "1.7.2-17",
        "ris_1": "F",
        "domanda": "Le carte nautiche sono classificate secondo il criterio:",
        "risposta_1": "del formato."
    },
    {
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "id": 1029,
        "ris_2": "F",
        "progressivo": "1.7.2-18",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "F",
        "risposta_3": "la pianificazione di rotte su grandi distanze.",
        "ris_3": "V",
        "risposta_2": "lo studio di eventi meteorologici su grande scala.",
        "risposta_1": "la disposizione delle varie carte particolari.",
        "immagine": "",
        "domanda": "Le carte generali possono essere utilizzate per:"
    },
    {
        "risposta_1": "tenendo il timone al centro, la poppa accosta a dritta.",
        "progressivo": "1.1.2-28",
        "ris_2": "V",
        "ris_3": "F",
        "immagine": "",
        "risposta_3": "con il timone a sinistra, accentuo l’accostata della poppa a dritta.",
        "risposta_2": "con il timone a dritta, limito l’accostata della poppa a sinistra.",
        "capitolo": "TEORIA DELLO SCAFO",
        "id": 103,
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "ris_1": "F",
        "domanda": "Con elica destrorsa, manovro in marcia indietro:"
    },
    {
        "immagine": "",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "ris_3": "F",
        "risposta_3": "parallele tra loro ed equidistanti.",
        "ris_2": "V",
        "risposta_2": "parallele tra loro, ma non equidistanti al crescere della latitudine.",
        "id": 1030,
        "ris_1": "F",
        "risposta_1": "non parallele tra loro, ma equidistanti.",
        "domanda": "Sulla carta di Mercatore i paralleli sono rappresentati da linee rette:",
        "progressivo": "1.7.2-19"
    },
    {
        "risposta_3": "aumentano la loro lunghezza con il crescere della latitudine.",
        "progressivo": "1.7.2-20",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "ris_3": "F",
        "immagine": "",
        "id": 1031,
        "ris_2": "F",
        "risposta_2": "diminuiscono la loro lunghezza con il crescere della latitudine.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "sono uguali tra loro.",
        "ris_1": "V",
        "domanda": "Sulla proiezione di Mercatore, i primi di longitudine:"
    },
    {
        "progressivo": "1.7.2-21",
        "ris_1": "F",
        "risposta_3": "scala inferiore a 1:3.000.000.",
        "risposta_1": "scala compresa tra 1:60.000 e 1:200.000.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "F",
        "domanda": "Generalmente, la “carta generale” è espressa con:",
        "ris_3": "V",
        "immagine": "",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "risposta_2": "scala superiore a 1:1.000.000.",
        "id": 1032
    },
    {
        "ris_1": "F",
        "progressivo": "1.7.2-22",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "V",
        "immagine": "",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "ris_3": "F",
        "id": 1033,
        "risposta_3": "1:1.100.000.",
        "risposta_2": "1:100.000.",
        "domanda": "Qual è la scala utilizzata nella “carta costiera” tra quelle sotto indicate?",
        "risposta_1": "1:10.000."
    },
    {
        "risposta_2": "1:55.000.",
        "immagine": "",
        "progressivo": "1.7.2-23",
        "ris_2": "F",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "ris_1": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_3": "1:5.000.",
        "id": 1034,
        "ris_3": "V",
        "domanda": "Qual è la scala utilizzata nel “piano nautico” tra quelle sotto indicate, per rappresentare un porto e la sua rada?",
        "risposta_1": "1:550.000."
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "Oltre al profilo della costa, cosa è riportato sulle carte nautiche?",
        "ris_2": "F",
        "risposta_3": "la profondità, le elevazioni, i segnali convenzionali ecc.",
        "ris_3": "V",
        "id": 1035,
        "risposta_1": "la natura della terraferma, e alcune notizie oceanografiche.",
        "ris_1": "F",
        "progressivo": "1.7.2-24",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "immagine": "",
        "risposta_2": "la dislocazione delle stazioni radio costiere."
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "risposta_1": "che dipende dalla latitudine.",
        "ris_1": "F",
        "ris_2": "F",
        "ris_3": "V",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "risposta_3": "col denominatore minore.",
        "domanda": "Tra due scale della carta, la maggiore è quella:",
        "progressivo": "1.7.2-25",
        "risposta_2": "col denominatore maggiore.",
        "id": 1036
    },
    {
        "immagine": "",
        "domanda": "In navigazione, si può carteggiare sulle carte didattiche?",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1037,
        "risposta_2": "si.",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "ris_2": "F",
        "ris_1": "F",
        "ris_3": "V",
        "progressivo": "1.7.2-26",
        "risposta_1": "no, perché sono stampate in bianco e nero e non a quattro colori.",
        "risposta_3": "no, perché oltre a non essere aggiornate non sono documenti ufficiali."
    },
    {
        "risposta_1": "in quanto la lunghezza del primo di latitudine diviene infinita in prossimità dei Poli.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "domanda": "I poli non sono rappresentabili con la Carta di Mercatore:",
        "id": 1038,
        "ris_3": "F",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "progressivo": "1.7.2-27",
        "risposta_3": "le precedenti risposte sono errate.",
        "risposta_2": "in quanto la navigazione ai poli è talmente scarsa da rendere non conveniente la produzione di tali carte di mercatore polari.",
        "ris_2": "F",
        "ris_1": "V"
    },
    {
        "ris_2": "F",
        "risposta_3": "che la distanza tra i paralleli diminuisce verso i poli.",
        "ris_3": "F",
        "risposta_1": "che il punto di proiezione è situato al centro della Terra.",
        "risposta_2": "che lungo i meridiani i rapporti tra le distanze risultano inalterati.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "Riguardo alle caratteristiche della proiezione di Mercatore, si può dire che:",
        "immagine": "",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "id": 1039,
        "progressivo": "1.7.2-28",
        "ris_1": "V"
    },
    {
        "ris_1": "V",
        "id": 104,
        "domanda": "Manovrando una unità da diporto dotata di due linee d'asse (destrorsa a dritta e sinistrorsa a sinistra); si ha che:",
        "capitolo": "TEORIA DELLO SCAFO",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "ris_2": "F",
        "risposta_3": "con il solo motore di sinistra in marcia indietro (fermo il motore di dritta), la poppa accosti a sinistra.",
        "risposta_2": "con il motore di dritta in marcia avanti e con quello di sinistra in marcia indietro, si ruota sul posto in senso orario.",
        "ris_3": "F",
        "progressivo": "1.1.2-29",
        "immagine": "",
        "risposta_1": "con il solo motore di dritta in marcia indietro (fermo il motore di sinistra), la prora accosta a dritta."
    },
    {
        "risposta_1": "si chiedono per radio.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "F",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "risposta_2": "si leggono sulla carta nautica.",
        "risposta_3": "si acquisiscono su internet.",
        "ris_1": "F",
        "domanda": "Le informazioni sul tipo di fondale:",
        "ris_2": "V",
        "immagine": "",
        "progressivo": "1.7.2-29",
        "id": 1040
    },
    {
        "ris_2": "F",
        "id": 1041,
        "immagine": "",
        "progressivo": "1.7.2-30",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "la biro verde serve solo a tracciare le rotte e a segnare i punti stimati.",
        "ris_3": "V",
        "ris_1": "F",
        "risposta_3": "il compasso, possibilmente a punte secche, serve per misurare o riportare distanze.",
        "risposta_2": "la biro nera a punta sottile serve a segnalare i punti nave documentabili.",
        "domanda": "Al fine di preservare la riutilizzabilità della carta nautica:",
        "argomento": "Carte nautiche e proiezione di Mercatore"
    },
    {
        "ris_1": "F",
        "risposta_3": "rende rettilinee le rotte lossodromiche.",
        "risposta_2": "i paralleli risultano equidistanti tra loro.",
        "progressivo": "1.7.2-31",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "ris_3": "V",
        "risposta_1": "non conserva la corrispondenza dei valori angolari.",
        "id": 1042,
        "ris_2": "F",
        "immagine": "",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "Tra le caratteristiche della proiezione di Mercatore, risulta che:"
    },
    {
        "risposta_1": "è una costa frastagliata.",
        "ris_1": "F",
        "progressivo": "1.7.2-32",
        "id": 1043,
        "ris_2": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "ris_3": "F",
        "domanda": "Sulla carta nautica in proiezione di Mercatore è indicato un segnalamento preceduto da una “ F ”; significa che:",
        "risposta_2": "è una luce fissa.",
        "risposta_3": "è un faro.",
        "argomento": "Carte nautiche e proiezione di Mercatore"
    },
    {
        "risposta_3": "180 gradi.",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "immagine": "",
        "progressivo": "1.7.2-33",
        "domanda": "Nella carta nautica di Mercatore i meridiani ed i paralleli formano angoli di:",
        "ris_3": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "V",
        "id": 1044,
        "ris_1": "F",
        "risposta_1": "45 gradi.",
        "risposta_2": "90 gradi."
    },
    {
        "id": 1045,
        "risposta_2": "non è utilizzabile oltre i 70 gradi di Latitudine.",
        "ris_3": "F",
        "risposta_3": "i paralleli risultano equidistanti tra loro.",
        "ris_2": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "Tra le caratteristiche della proiezione di Mercatore, risulta che:",
        "risposta_1": "lungo gli archi di meridiani i rapporti tra le distanze risultano inalterati.",
        "progressivo": "1.7.2-34",
        "ris_1": "F",
        "immagine": "",
        "argomento": "Carte nautiche e proiezione di Mercatore"
    },
    {
        "ris_3": "F",
        "ris_1": "V",
        "risposta_3": "a proiezione “gnomonica”.",
        "risposta_1": "“costiera a grande scala”, utilizzabile per condurre la navigazione costiera.",
        "immagine": "",
        "domanda": "La scala 1:50.000 identifica una carta nautica:",
        "ris_2": "F",
        "id": 1046,
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "progressivo": "1.7.2-35",
        "risposta_2": "dei porti e delle rade (“piano nautico”)."
    },
    {
        "risposta_2": "è l'edizione di una rappresentazione già esistente che contiene modifiche essenziali per la sicurezza della navigazione ovvero qualunque altra modifica non apportabile mediante aggiornamento tramite Avvisi ai Naviganti (AA.NN.).",
        "id": 1047,
        "progressivo": "1.7.2-36",
        "risposta_1": "non annulla la precedente edizione.",
        "ris_2": "V",
        "domanda": "La carta nautica di \"nuova edizione\", edita dall'I.I.M.M.:",
        "ris_3": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_3": "è una carta a copertura di una zona mai rappresentata.",
        "immagine": "",
        "ris_1": "F",
        "argomento": "Carte nautiche e proiezione di Mercatore"
    },
    {
        "risposta_3": "si esegue con il compasso aperto pari alla distanza da misurare, ci si muove in orizzontale (lungo un parallelo preso a riferimento) indifferentemente verso la scala di destra o di sinistra delle latitudini.",
        "ris_3": "V",
        "risposta_1": "si esegue con il compasso aperto pari alla distanza da misurare, ci si muove in orizzontale (lungo un parallelo preso a riferimento) soltanto verso la scala di destra delle latitudini.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1048,
        "risposta_2": "si esegue con il compasso aperto pari alla distanza da misurare, ci si muove in orizzontale (lungo un parallelo preso a riferimento) soltanto verso la scala di sinistra delle latitudini.",
        "immagine": "",
        "progressivo": "1.7.2-37",
        "ris_1": "F",
        "domanda": "Come si esegue sulla scala della carta nautica, la misura della distanza?",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "ris_2": "F"
    },
    {
        "ris_1": "F",
        "risposta_1": "scoglio emergente.",
        "risposta_2": "fondale roccioso.",
        "immagine": "",
        "ris_2": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "ris_3": "F",
        "risposta_3": "fondale ripido (la profondità aumenta rapidamente).",
        "domanda": "Cosa indica la lettera “ r ” sulla carta nautica nazionale, nella zona rappresentante il mare?",
        "id": 1049,
        "progressivo": "1.7.2-38"
    },
    {
        "ris_1": "F",
        "risposta_3": "in marcia indietro, l'elica tende a far accostare la poppa a sinistra.",
        "ris_3": "V",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "risposta_1": "in marcia avanti tende a far accostare la poppa a sinistra.",
        "progressivo": "1.1.2-30",
        "domanda": "Un’elica destrorsa:",
        "risposta_2": "se vista da poppa, in marcia avanti l'elica gira in senso antiorario.",
        "ris_2": "F",
        "id": 105,
        "capitolo": "TEORIA DELLO SCAFO",
        "immagine": ""
    },
    {
        "risposta_1": "il fondo fangoso.",
        "risposta_2": "una zona di ancoraggio alla fonda.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "V",
        "progressivo": "1.7.2-39",
        "risposta_3": "una nave alla fonda.",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "domanda": "La lettera “ f ” sulla carta nautica, nella zona rappresentante il mare, indica:",
        "ris_3": "F",
        "ris_2": "F",
        "id": 1050,
        "immagine": ""
    },
    {
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "progressivo": "1.7.2-40",
        "risposta_1": "i limiti di una zona regolamentata.",
        "ris_3": "F",
        "immagine": 92,
        "ris_1": "V",
        "risposta_2": "la presenza di una tonnara.",
        "id": 1051,
        "domanda": "Il simbolo in figura a fianco, riportato sulla carta nautica, indica:",
        "ris_2": "F",
        "risposta_3": "fondo sporco.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA"
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1052,
        "risposta_1": "la presenza di un punto cospicuo.",
        "domanda": "Il simbolo in figura, riportato sulla carta nautica, indica:",
        "risposta_3": "la presenza di un campanile.",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "ris_3": "F",
        "ris_1": "F",
        "immagine": 93,
        "ris_2": "V",
        "progressivo": "1.7.2-41",
        "risposta_2": "la presenza di uno scoglio affiorante."
    },
    {
        "risposta_2": "la presenza di scogli sommersi non pericolosi per la navigazione.",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "la presenza di scogli sommersi pericolosi per la navigazione.",
        "risposta_3": "reti da pesca.",
        "ris_1": "V",
        "ris_3": "F",
        "id": 1053,
        "domanda": "Il simbolo in figura, riportato sulla carta nautica, indica:",
        "progressivo": "1.7.2-42",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "immagine": 94
    },
    {
        "ris_3": "F",
        "risposta_1": "elettrico non perfettamente isolato, è pericoloso immergersi in acqua.",
        "immagine": 95,
        "domanda": "Il simbolo in figura a fianco, riportato sulla carta nautica, è prescritto per indicare la presenza di un cavo:",
        "progressivo": "1.7.2-43",
        "risposta_2": "sottomarino non più in funzione o abbandonato.",
        "ris_1": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1054,
        "ris_2": "V",
        "risposta_3": "per reti da pesca.",
        "argomento": "Carte nautiche e proiezione di Mercatore"
    },
    {
        "ris_1": "V",
        "id": 1055,
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "domanda": "La scritta “ P.A. ”, posizionata vicino ad un simbolo sulla carta nautica, è utilizzata per indicare:",
        "risposta_3": "parzialmente affiorante\".",
        "risposta_1": "posizione approssimativa\".",
        "ris_3": "F",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "risposta_2": "posizione di ancoraggio\".",
        "progressivo": "1.7.2-44"
    },
    {
        "risposta_1": "è possibile l'ancoraggio di piccole navi.",
        "immagine": 96,
        "domanda": "Il simbolo in figura a fianco indica la presenza di una zona dove:",
        "ris_3": "F",
        "id": 1056,
        "risposta_3": "è probabile che l'ancora s'incastri sul fondo.",
        "ris_1": "V",
        "ris_2": "F",
        "risposta_2": "è possibile l'ancoraggio con ancora dotata di una sola marra.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "progressivo": "1.7.2-45"
    },
    {
        "risposta_2": "magnetica.",
        "ris_2": "F",
        "ris_3": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "immagine": "",
        "progressivo": "1.7.2-46",
        "risposta_3": "ortodromica.",
        "domanda": "La proiezione di Mercatore consente di tracciare una rotta:",
        "id": 1057,
        "ris_1": "V",
        "risposta_1": "lossodromica."
    },
    {
        "immagine": "",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "ad angolo costante.",
        "progressivo": "1.7.2-47",
        "ris_3": "F",
        "ris_1": "F",
        "risposta_3": "più breve mantenendo costante l’angolo.",
        "risposta_1": "più breve.",
        "domanda": "La proiezione di Mercatore consente di tracciare una rotta:",
        "ris_2": "V",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "id": 1058
    },
    {
        "immagine": "",
        "ris_1": "F",
        "ris_3": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "pressione atmosferica crescente in modo uniforme.",
        "domanda": "Una isobata è una linea che unisce punti di:",
        "id": 1059,
        "ris_2": "V",
        "risposta_2": "eguale profondità marina.",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "progressivo": "1.7.2-48",
        "risposta_3": "eguale pressione atmosferica."
    },
    {
        "risposta_1": "con barra a sinistra, la prua va a sinistra.",
        "ris_2": "V",
        "immagine": "",
        "capitolo": "TEORIA DELLO SCAFO",
        "domanda": "Sulla manovra e il funzionamento del timone a barra, senza considerare l’effetto dell’elica, in marcia avanti abbiamo che:",
        "ris_3": "F",
        "ris_1": "F",
        "risposta_2": "con barra a sinistra, la prua va a dritta.",
        "progressivo": "1.1.2-31",
        "risposta_3": "con barra a sinistra, in moto indietro la poppa va a sinistra.",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "id": 106
    },
    {
        "ris_3": "V",
        "progressivo": "1.7.2-49",
        "risposta_3": "carte aventi una scala superiore rispetto a quella delle carte costiere, realizzate per rappresentare in modo più dettagliato particolari zone di interesse come l'accesso ai porti e zone relative a stretti e passaggi.",
        "immagine": "",
        "ris_2": "F",
        "domanda": "Come possono definirsi le carte nautiche dei litorali?",
        "risposta_1": "carte utilizzate nella navigazione fra isole distanti tra loro per non più di 12 miglia nautiche.",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "ris_1": "F",
        "risposta_2": "carte utilizzate per la navigazione tra stati costieri confinanti.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1060
    },
    {
        "ris_1": "F",
        "risposta_3": "la presenza di una zona di traffico costiero senza limiti definiti.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "F",
        "ris_2": "V",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "domanda": "Il simbolo rappresentato in figura indica:",
        "immagine": 97,
        "risposta_2": "la presenza di uno schema di separazione del traffico, diviso da una zona di separazione.",
        "id": 1061,
        "progressivo": "1.7.2-50",
        "risposta_1": "la presenza e la direzione di correnti marine particolarmente intense, tra schemi di separazione del traffico."
    },
    {
        "risposta_3": "l'ancoraggio vietato.",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "domanda": "Il simbolo rappresentato in figura indica:",
        "risposta_2": "il divieto di pesca a strascico.",
        "progressivo": "1.7.2-51",
        "ris_2": "F",
        "risposta_1": "un porto turistico non più in uso.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "F",
        "ris_3": "V",
        "id": 1062,
        "immagine": 98
    },
    {
        "progressivo": "1.7.2-52",
        "risposta_1": "la presenza di un relitto pericolosamente appoppato.",
        "immagine": 99,
        "ris_1": "F",
        "ris_2": "F",
        "id": 1063,
        "risposta_2": "la presenza di una boa di ormeggio.",
        "domanda": "Il simbolo rappresentato in figura indica:",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "V",
        "risposta_3": "la presenza di un relitto in parte emergente."
    },
    {
        "id": 1064,
        "immagine": 100,
        "ris_3": "F",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "risposta_1": "la presenza di un cavo telefonico sottomarino.",
        "risposta_2": "la presenza di una condotta non specificata.",
        "ris_1": "F",
        "risposta_3": "la presenza di un cavo elettrico sottomarino.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "Il simbolo rappresentato in figura indica:",
        "ris_2": "V",
        "progressivo": "1.7.2-53"
    },
    {
        "domanda": "Il simbolo rappresentato in figura indica:",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "id": 1065,
        "risposta_3": "la presenza di corpi morti di categoria A.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "F",
        "ris_2": "V",
        "risposta_1": "che il fondale situato in un raggio pari a un miglio nautico dallo stesso è di natura argillosa.",
        "progressivo": "1.7.2-54",
        "immagine": 101,
        "ris_3": "F",
        "risposta_2": "un punto di fonda."
    },
    {
        "domanda": "Che cosa si intende per ortodromia?",
        "risposta_2": "il percorso seguito da un'unità che interseca in successione  i meridiani alternativamente con angoli diversi e uguali.",
        "immagine": "",
        "progressivo": "1.7.2-55",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "V",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "risposta_3": "l'arco di circolo massimo seguito da un'unità, che interseca in successione i meridiani con angoli diversi e descrive il percorso più breve fra due punti.",
        "risposta_1": "la rotta mantenuta da un'unità che delinea un percorso che interseca tutti i meridiani secondo un angolo costante.",
        "id": 1066,
        "ris_1": "F",
        "ris_2": "F"
    },
    {
        "id": 1067,
        "immagine": "",
        "argomento": "Carte nautiche e proiezione di Mercatore",
        "ris_1": "V",
        "domanda": "Che cosa si intende per lossodromia?",
        "risposta_3": "l'arco di circolo massimo seguito da un'unità, che interseca in successione i meridiani con angoli diversi e descrive il percorso più breve fra due punti.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "il percorso seguito da un'unità che interseca in successione  i meridiani alternativamente con angoli diversi e uguali.",
        "ris_3": "F",
        "ris_2": "F",
        "progressivo": "1.7.2-56",
        "risposta_1": "la rotta mantenuta da un'unità che delinea un percorso che interseca tutti i meridiani secondo un angolo costante."
    },
    {
        "immagine": "",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "V",
        "risposta_1": "conoscere in ogni istante la distanza rispetto al punto in cui è caduto un uomo in mare e determinare la relativa rotta necessaria per tentarne il recupero.",
        "ris_3": "F",
        "domanda": "Per quale finalità è stata creata la funzione MOB (Man Over Board ) sui dispositivi GPS?",
        "progressivo": "1.7.3-1",
        "ris_2": "F",
        "risposta_3": "attivare automaticamente un sistema di localizzazione radiogoniometrico per determinare il rilevamento rispetto alla posizione dell'uomo caduto in mare.",
        "risposta_2": "la funzione MOB, attivata manualmente quando l'uomo cade in mare, emette onde magnetiche a bassa frequenza.",
        "id": 1068,
        "argomento": "Navigazione elettronica"
    },
    {
        "ris_2": "V",
        "ris_1": "F",
        "argomento": "Navigazione elettronica",
        "progressivo": "1.7.3-2",
        "immagine": "",
        "ris_3": "F",
        "domanda": "In cosa consiste il sistema GPS?",
        "risposta_2": "è un ricevitore in grado di determinare la propria distanza rispetto a ciascuno dei satelliti che costituiscono la relativa rete orbitante, e quindi il punto nave, con un esiguo margine di errore.",
        "risposta_1": "è un trasmettitore in grado di determinare il proprio rilevamento vero rispetto a un complesso di stazioni riceventi, poste in prossimità dei poli.",
        "id": 1069,
        "risposta_3": "è un trasmettitore in grado di determinare il proprio rilevamento geosatellitare inerziale rispetto a un complesso di stazioni gravitazionali.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA"
    },
    {
        "ris_1": "V",
        "ris_3": "F",
        "progressivo": "1.1.2-32",
        "domanda": "Ruotando la ruota a sinistra nel moto in avanti; accade che la:",
        "risposta_3": "poppa vada a sinistra.",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "immagine": "",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_2": "F",
        "id": 107,
        "risposta_1": "prora vada a sinistra.",
        "risposta_2": "prora vada a dritta."
    },
    {
        "progressivo": "1.7.3-3",
        "risposta_2": "latitudine e longitudine, direzione ed intensità del vento e della corrente e altezza media delle onde del mare.",
        "id": 1070,
        "argomento": "Navigazione elettronica",
        "ris_1": "F",
        "immagine": "",
        "ris_2": "F",
        "risposta_3": "Latitudine e longitudine, distanza e rotta necessarie per raggiungere un waypoint, velocità e rotta rispetto al fondo marino (Ve e Rv) e ora stimata di arrivo al punto di destinazione.",
        "ris_3": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "altitudine e parallasse, distanza e rotta necessarie per raggiungere un waypoint, prora vera e velocità propulsiva e ora stimata del punto di partenza.",
        "domanda": "Quali sono le principali informazioni fornite dal sistema GPS?"
    },
    {
        "id": 1071,
        "argomento": "Navigazione elettronica",
        "ris_1": "F",
        "ris_3": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "le unità da diporto con lunghezza superiore a 15 metri devono essere dotate sia di apparati GPS fissi, alimentati dall'impianto elettrico di bordo, nonché di apparati GPS  portatili, alimentati da batterie alcaline.",
        "progressivo": "1.7.3-4",
        "immagine": "",
        "risposta_2": "le unità da diporto possono essere dotate sia di apparati GPS fissi, alimentati dall'impianto elettrico di bordo, nonché di apparati GPS portatili, alimentati da batterie alcaline.",
        "ris_2": "V",
        "domanda": "Quale tra queste affermazioni è corretta?",
        "risposta_3": "le unità  da diporto in navigazione, effettuata ad una distanza dalla costa superiore a 12 miglia nautiche, devono essere dotate sia di apparati GPS fissi, alimentati dall'impianto elettrico di bordo, nonché di apparati GPS portatili, alimentati da batterie nichel- cadmio."
    },
    {
        "argomento": "Navigazione elettronica",
        "risposta_2": "500 e più metri.",
        "id": 1072,
        "progressivo": "1.7.3-5",
        "domanda": "Qual è il margine di errore del G.P.S.?",
        "immagine": "",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "F",
        "ris_3": "F",
        "ris_1": "V",
        "risposta_3": "100 e più metri.",
        "risposta_1": "pochi metri."
    },
    {
        "argomento": "Navigazione elettronica",
        "domanda": "Qual è l'utilità del G.P.S.?",
        "risposta_3": "seguire la Prora.",
        "ris_2": "V",
        "risposta_1": "rilevare un punto cospicuo a terra.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1073,
        "ris_3": "F",
        "ris_1": "F",
        "risposta_2": "fornire in ogni istante il punto nave.",
        "progressivo": "1.7.3-6",
        "immagine": ""
    },
    {
        "ris_1": "F",
        "risposta_1": "nella navigazione oltre le 50 miglia.",
        "argomento": "Navigazione elettronica",
        "risposta_3": "nella navigazione oltre le 12 miglia.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "progressivo": "1.7.3-7",
        "ris_2": "F",
        "ris_3": "V",
        "immagine": "",
        "id": 1074,
        "risposta_2": "no, è una dotazione consigliata e facoltativa.",
        "domanda": "Il GPS è obbligatorio?"
    },
    {
        "risposta_2": "all'interno del porto.",
        "domanda": "Utilizzando il GPS per la navigazione è opportuno posizionare il WAY-POINT:",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "F",
        "immagine": "",
        "risposta_1": "almeno 500 metri fuori dai fanali del porto, avendo cura che la rotta non passi su ostacoli o secche.",
        "id": 1075,
        "ris_1": "V",
        "risposta_3": "in corrispondenza del fanale rosso.",
        "argomento": "Navigazione elettronica",
        "progressivo": "1.7.3-8",
        "ris_3": "F"
    },
    {
        "argomento": "Navigazione elettronica",
        "risposta_3": "la connessione alla bussola di bordo.",
        "risposta_2": "la connessione con il canale 100 del VHF.",
        "id": 1076,
        "ris_3": "F",
        "progressivo": "1.7.3-9",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "ris_1": "V",
        "ris_2": "F",
        "risposta_1": "l’esistenza e il corretto utilizzo del tasto MOB.",
        "domanda": "Cosa è opportuno verificare sul proprio apparato GPS:"
    },
    {
        "argomento": "Navigazione elettronica",
        "ris_2": "F",
        "ris_1": "V",
        "ris_3": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "progressivo": "1.7.3-10",
        "risposta_1": "la funzione del GPS che traccia il punto di caduta dell’uomo a mare e il rilevamento per tornarci.",
        "risposta_3": "la funzione del GPS per allertare la Capitaneria di porto.",
        "domanda": "Cosa è il MOB?",
        "id": 1077,
        "risposta_2": "la funzione del GPS  che avvisa della navigazione in acque poco profonde.",
        "immagine": ""
    },
    {
        "argomento": "Navigazione elettronica",
        "risposta_1": "effettua sempre automaticamente il calcolo della rotta tenendo conto di ostacoli, pericoli e morfologia della costa.",
        "ris_3": "V",
        "id": 1078,
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "legge solamente gli ostacoli.",
        "risposta_3": "se non di ultimissima generazione, non tiene conto di ostacoli, pericoli e morfologia della costa, è quindi necessario impostare delle rotte spezzate; comunque, anche in caso di rotta automatica, l’esito va comunque sempre verificato.",
        "ris_1": "F",
        "immagine": "",
        "ris_2": "F",
        "domanda": "Il GPS, nel calcolare la rotta per il WAY-POINT impostato:",
        "progressivo": "1.7.3-11"
    },
    {
        "ris_3": "V",
        "risposta_2": "navigazione costiera.",
        "progressivo": "1.7.3-12",
        "immagine": "",
        "risposta_3": "navigazione per WAY- POINT.",
        "domanda": "La navigazione effettuata con il G.P.S. è denominata:",
        "ris_1": "F",
        "id": 1079,
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "F",
        "risposta_1": "navigazione stimata.",
        "argomento": "Navigazione elettronica"
    },
    {
        "ris_1": "V",
        "domanda": "Un'elica sinistrorsa:",
        "immagine": "",
        "progressivo": "1.1.2-33",
        "risposta_1": "guardando la poppa dall'esterno, in marcia avanti gira in senso antiorario.",
        "risposta_3": "in marcia indietro, tende a fare accostare la prora a dritta.",
        "risposta_2": "in marcia avanti, tende a fare accostare la poppa a dritta.",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "ris_2": "F",
        "capitolo": "TEORIA DELLO SCAFO",
        "id": 108,
        "ris_3": "F"
    },
    {
        "progressivo": "1.7.3-13",
        "immagine": "",
        "risposta_2": "solo il punto nave.",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "V",
        "risposta_1": "la propria rotta, il fuori rotta, il tempo stimato di arrivo, il tempo di percorrenza, la distanza, lo scarto in gradi rispetto al luogo impostato come arrivo, la data e l’ora, la velocità, ed infine la freccia che mostra in ogni momento la direzione che dovremmo seguire per arrivare a destinazione.",
        "risposta_3": "la presenza di altre unità in navigazione.",
        "argomento": "Navigazione elettronica",
        "ris_3": "F",
        "domanda": "Il GPS cartografico indica:",
        "id": 1080
    },
    {
        "ris_1": "F",
        "ris_3": "F",
        "immagine": "",
        "domanda": "I 360 gradi dell'orizzonte sono divisi in quattro quadranti:  1 NE; 2 SE; 3 SW; 4 NW. La direzione (Rv o Rlv) 157° in quale quadrante si dirige?",
        "risposta_1": "quarto quadrante.",
        "ris_2": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "progressivo": "1.7.4-1",
        "id": 1081,
        "risposta_2": "secondo quadrante.",
        "argomento": "Orientamento e rosa dei venti",
        "risposta_3": "primo quadrante."
    },
    {
        "domanda": "In una carta nautica dell'emisfero nord (boreale), com'è noto, la direzione del Nord è verso il bordo in alto della carta stessa.\nPartendo da un punto qualsiasi della carta, le direzioni 048 gradi e 167 gradi (siano esse rotte o rilevamenti) in quale senso dirigono rispettivamente?",
        "id": 1082,
        "progressivo": "1.7.4-2",
        "argomento": "Orientamento e rosa dei venti",
        "ris_2": "F",
        "risposta_1": "la direzione 048 gradi verso l'alto e a destra; la direzione 167 gradi verso il basso e a destra.",
        "ris_1": "V",
        "risposta_2": "la direzione 048 gradi verso l'alto e a sinistra; la direzione 167 gradi verso il basso e a destra.",
        "immagine": "",
        "risposta_3": "la direzione 048 gradi verso il basso e a sinistra; la direzione 167 gradi verso l'alto e a destra.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "F"
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "la direzione 301 gradi verso l'alto e a destra; la direzione 249 gradi verso il basso e a destra.",
        "ris_3": "F",
        "id": 1083,
        "domanda": "In una carta nautica dell'emisfero nord (boreale), com'è noto, la direzione del Nord è verso il bordo in alto della carta stessa.\nPartendo da un punto qualsiasi della carta le direzioni 301 gradi e 249 gradi (siano esse rotte o rilevamenti) in quale senso dirigono rispettivamente?",
        "ris_2": "V",
        "progressivo": "1.7.4-3",
        "risposta_3": "la direzione 301 gradi verso il basso e a sinistra; la direzione 249 gradi verso l'alto e a destra.",
        "argomento": "Orientamento e rosa dei venti",
        "ris_1": "F",
        "risposta_2": "la direzione 301 gradi verso l'alto e a sinistra; la direzione 249 gradi verso il basso e a sinistra.",
        "immagine": ""
    },
    {
        "risposta_3": "terzo quadrante.",
        "ris_2": "F",
        "risposta_1": "secondo quadrante.",
        "ris_1": "F",
        "immagine": "",
        "domanda": "I 360 gradi dell'orizzonte sono divisi in quattro quadranti: il primo, NE; il secondo, SE; il terzo, SW; il quarto, NW. La direzione (Rv o Rlv) 224 gradi verso quale quadrante si dirige?",
        "argomento": "Orientamento e rosa dei venti",
        "ris_3": "V",
        "risposta_2": "primo quadrante.",
        "progressivo": "1.7.4-4",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1084
    },
    {
        "ris_1": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "secondo quadrante.",
        "progressivo": "1.7.4-5",
        "argomento": "Orientamento e rosa dei venti",
        "ris_2": "F",
        "risposta_2": "primo quadrante.",
        "ris_3": "V",
        "id": 1085,
        "domanda": "I 360 gradi dell'orizzonte sono divisi in quattro quadranti: il primo, NE; il secondo, SE; il terzo, SW; il quarto, NW. La direzione (Rv o Rlv) 320 gradi in quale quadrante si dirige?",
        "risposta_3": "quarto quadrante.",
        "immagine": ""
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1086,
        "progressivo": "1.7.4-6",
        "risposta_3": "secondo quadrante.",
        "ris_3": "F",
        "risposta_2": "quarto quadrante.",
        "ris_2": "F",
        "domanda": "I 360 gradi dell'orizzonte sono divisi in quattro quadranti: il primo, NE; il secondo, SE; il terzo, SW; il quarto, NW. La direzione (Rv o Rlv) 038 gradi in quale quadrante si dirige?",
        "immagine": "",
        "ris_1": "V",
        "argomento": "Orientamento e rosa dei venti",
        "risposta_1": "primo quadrante."
    },
    {
        "ris_1": "F",
        "risposta_3": "primo quadrante.",
        "ris_3": "F",
        "progressivo": "1.7.4-7",
        "id": 1087,
        "risposta_1": "quarto quadrante.",
        "risposta_2": "secondo quadrante.",
        "ris_2": "V",
        "argomento": "Orientamento e rosa dei venti",
        "domanda": "I 360 gradi dell'orizzonte sono divisi in quattro quadranti: il primo, NE; il secondo, SE; il terzo, SW; il quarto, NW. La direzione (Rv o Rlv) 099 gradi in quale quadrante si dirige?",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": ""
    },
    {
        "immagine": "",
        "ris_2": "V",
        "progressivo": "1.7.4-8",
        "argomento": "Orientamento e rosa dei venti",
        "ris_3": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "antiorario.",
        "domanda": "L'angolo di rotta e l'angolo di prora, si misurano con valori che vanno da 0° a 360° in senso:",
        "risposta_3": "da est verso ovest.",
        "id": 1088,
        "risposta_2": "orario.",
        "ris_1": "F"
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "F",
        "id": 1089,
        "risposta_3": "45°, 135°, 225° e 315°.",
        "risposta_2": "NE (Nord-Est), SE (Sud-Est), SW (Sud-Ovest) e NW (Nord- Ovest).",
        "argomento": "Orientamento e rosa dei venti",
        "ris_2": "F",
        "risposta_1": "Nord (N), Sud (S), Est (E) e Ovest (W).",
        "domanda": "Quali sono le direzioni cardinali?",
        "ris_1": "V",
        "immagine": "",
        "progressivo": "1.7.4-9"
    },
    {
        "risposta_2": "l’effetto evolutivo dell’elica è più importante rispetto a quello generato con un entrobordo monoelica.",
        "ris_2": "F",
        "risposta_1": "in marcia avanti, ruotando il piede a dritta, la poppa accosta a dritta.",
        "risposta_3": "in marcia avanti, ruotando il piede a dritta, la poppa accosta a sinistra.",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_1": "F",
        "ris_3": "V",
        "id": 109,
        "progressivo": "1.1.2-34",
        "domanda": "Con un motore fuoribordo:",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "immagine": ""
    },
    {
        "immagine": "",
        "risposta_1": "le direzioni cardinali rappresentano quelle verso cui sono dirette le correnti di marea.",
        "progressivo": "1.7.4-10",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "le direzioni cardinali rappresentano le direzioni di provenienza degli alisei.",
        "domanda": "Quale tra queste affermazioni è corretta?",
        "id": 1090,
        "argomento": "Orientamento e rosa dei venti",
        "risposta_3": "le direzioni cardinali costituiscono le principali direzioni di riferimento rispetto alle quali si individuano tutte le altre direzioni.",
        "ris_1": "F",
        "ris_3": "V",
        "ris_2": "F"
    },
    {
        "ris_3": "F",
        "domanda": "Quali sono le direzioni intercardinali?",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "risposta_3": "Nord (N), Sud (S), Est (E) e Ovest (W).",
        "ris_2": "V",
        "id": 1091,
        "ris_1": "F",
        "risposta_1": "90°, 180°, 270° e 360°.",
        "risposta_2": "NE (Nord-Est), SE (Sud-Est), SW (Sud-Ovest) e NW (Nord- Ovest).",
        "argomento": "Orientamento e rosa dei venti",
        "progressivo": "1.7.4-11"
    },
    {
        "ris_1": "V",
        "risposta_3": "Nord magnetico.",
        "immagine": "",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "F",
        "argomento": "Bussole magnetiche",
        "risposta_2": "Nord vero.",
        "risposta_1": "Nord bussola.",
        "domanda": "Verso quale direzione si orientano gli aghi di una bussola magnetica a bordo dell'unità?",
        "ris_3": "F",
        "id": 1092,
        "progressivo": "1.7.4-12"
    },
    {
        "ris_2": "F",
        "risposta_3": "indica l'angolo che il baglio forma con la direzione del parallelo indicata dalla bussola.",
        "progressivo": "1.7.4-13",
        "domanda": "A quale funzione adempie la linea di fede di una bussola magnetica?",
        "id": 1093,
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "risposta_1": "indica l'angolo che l'unità forma con la direzione del meridiano indicata dalla bussola.",
        "argomento": "Bussole magnetiche",
        "ris_1": "V",
        "risposta_2": "indica l'angolo che l'unità forma con la direzione del parallelo indicata dalla bussola.",
        "ris_3": "F"
    },
    {
        "id": 1094,
        "ris_1": "F",
        "risposta_3": "da un galleggiante sotto al quale sono collocati gli aghi magnetici e il quadrante composto da un disco su cui è rappresentata la graduazione da 0° a 360° in senso orario.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "progressivo": "1.7.4-14",
        "ris_2": "F",
        "argomento": "Bussole magnetiche",
        "immagine": "",
        "ris_3": "V",
        "domanda": "Da cos'è costituita la rosa graduata da una bussola magnetica?",
        "risposta_1": "da un disco rappresentante i venti predominanti.",
        "risposta_2": "sfere di condensazione magnetica."
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_3": "lo strumento di bordo utilizzato per la navigazione stimata, basato sulle proprietà del campo magnetico terrestre, in grado di orientarsi verso il Nord magnetico.",
        "ris_1": "V",
        "risposta_1": "lo strumento di bordo utilizzato per la navigazione stimata, basato sulle proprietà del campo magnetico terrestre, in grado di  orientarsi verso il Nord bussola.",
        "progressivo": "1.7.4-15",
        "id": 1095,
        "ris_3": "F",
        "ris_2": "F",
        "domanda": "Cos'è una bussola magnetica di bordo?",
        "immagine": "",
        "risposta_2": "lo strumento di bordo utilizzato per la navigazione stimata, basato sulle proprietà del campo magnetico terrestre, in grado di orientarsi verso il Nord vero.",
        "argomento": "Bussole magnetiche"
    },
    {
        "progressivo": "1.7.4-16",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "V",
        "risposta_3": "il perito nautico.",
        "ris_1": "F",
        "risposta_1": "mediatore marittimo.",
        "immagine": "",
        "argomento": "Bussole magnetiche",
        "risposta_2": "il perito compensatore autorizzato dall'Autorità marittima.",
        "id": 1096,
        "ris_3": "F",
        "domanda": "Chi può eseguire l'operazione dei \"giribussola\"?"
    },
    {
        "ris_1": "F",
        "ris_3": "V",
        "progressivo": "1.7.4-17",
        "risposta_1": "la variazione magnetica.",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1097,
        "risposta_3": "la declinazione magnetica.",
        "domanda": "Qual è l'angolo che rappresenta la differenza tra Nv e Nm?",
        "argomento": "Bussole magnetiche",
        "immagine": "",
        "risposta_2": "la deviazione magnetica."
    },
    {
        "risposta_3": "dell'orientamento delle linee di forza del campo magnetico terrestre.",
        "id": 1098,
        "ris_2": "F",
        "domanda": "La declinazione magnetica è in funzione:",
        "progressivo": "1.7.4-18",
        "argomento": "Bussole magnetiche",
        "ris_1": "F",
        "risposta_2": "della presenza a bordo di materiale magnetico.",
        "ris_3": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "risposta_1": "dell'orientamento della prora dell'imbarcazione."
    },
    {
        "immagine": "",
        "progressivo": "1.7.4-19",
        "argomento": "Bussole magnetiche",
        "domanda": "Qual è l'angolo che rappresenta la differenza tra Nm e Nb?",
        "risposta_3": "la variazione magnetica.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "la declinazione magnetica.",
        "risposta_1": "la deviazione magnetica.",
        "id": 1099,
        "ris_2": "F",
        "ris_1": "V",
        "ris_3": "F"
    },
    {
        "risposta_1": "opera morta.",
        "ris_2": "F",
        "domanda": "Con riferimento al disegno rappresentato in figura, quale porzione dello scafo è indicata dalle linee trasversali?",
        "ris_1": "V",
        "immagine": 2,
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "risposta_3": "opera viva.",
        "progressivo": "1.1.1-11",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_3": "F",
        "id": 11,
        "risposta_2": "specchio di poppa."
    },
    {
        "domanda": "Un’elica destrorsa:",
        "ris_1": "V",
        "immagine": "",
        "capitolo": "TEORIA DELLO SCAFO",
        "id": 110,
        "risposta_2": "in marcia indietro, con timone al centro, la poppa si sposta verso dritta.",
        "risposta_1": "in marcia indietro, l’elica ruota in senso antiorario.",
        "ris_2": "F",
        "risposta_3": "in marcia avanti, con timone al centro, la poppa si sposta verso sinistra.",
        "ris_3": "F",
        "progressivo": "1.1.2-35",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità."
    },
    {
        "ris_2": "F",
        "argomento": "Bussole magnetiche",
        "risposta_1": "con i giri di bussola a bussola compensata.",
        "risposta_3": "con la compensazione della bussola amagnetica.",
        "ris_1": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "F",
        "immagine": "",
        "id": 1100,
        "domanda": "La tabella delle deviazioni magnetiche residue si ricava:",
        "risposta_2": "con la rotazione della rosa della bussola rispetto alla linea di fede.",
        "progressivo": "1.7.4-20"
    },
    {
        "progressivo": "1.7.4-21",
        "risposta_3": "angolare tra Nord vero e Nord bussola.",
        "argomento": "Bussole magnetiche",
        "immagine": "",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "F",
        "ris_3": "F",
        "id": 1101,
        "domanda": "La declinazione magnetica è la differenza:",
        "ris_1": "V",
        "risposta_2": "angolare tra Nord magnetico e Nord bussola.",
        "risposta_1": "tra la direzione indicata dal meridiano geografico e quella indicata dal meridiano magnetico."
    },
    {
        "ris_1": "F",
        "immagine": "",
        "ris_2": "V",
        "ris_3": "F",
        "risposta_3": "dal portolano del luogo.",
        "risposta_2": "dalla carta nautica.",
        "domanda": "Il navigante ricava il valore della declinazione magnetica:",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Bussole magnetiche",
        "id": 1102,
        "progressivo": "1.7.4-22",
        "risposta_1": "da apposito documento edito dalla Capitaneria di porto."
    },
    {
        "risposta_3": "tra 0 e 45 gradi Est e tra 0 e 45 gradi Ovest.",
        "risposta_1": "tra 0 e 180 gradi Est e tra 0 e 180 gradi Ovest.",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "F",
        "ris_1": "V",
        "progressivo": "1.7.4-23",
        "domanda": "I limiti di variabilità teorici della declinazione magnetica sono compresi:",
        "id": 1103,
        "immagine": "",
        "risposta_2": "tra 0 e 90 gradi Est e tra 0 e 90 gradi Ovest.",
        "argomento": "Bussole magnetiche"
    },
    {
        "risposta_1": "orientare con precisione il mortaio della bussola rispetto la linea di fede.",
        "argomento": "Bussole magnetiche",
        "immagine": "",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "V",
        "id": 1104,
        "risposta_3": "a redigere, a bussola compensata, la tabella delle deviazioni residue.",
        "domanda": "I giri di bussola servono a:",
        "progressivo": "1.7.4-24",
        "ris_2": "F",
        "ris_1": "F",
        "risposta_2": "compensare la bussola magnetica."
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_3": "dal tempo e dal luogo in cui si trova la nave in quel momento.",
        "ris_2": "F",
        "argomento": "Bussole magnetiche",
        "id": 1105,
        "risposta_1": "dalla prora dell'imbarcazione e dalla sua velocità.",
        "progressivo": "1.7.4-25",
        "ris_1": "F",
        "domanda": "La variazione della declinazione magnetica dipende:",
        "immagine": "",
        "risposta_2": "dal tempo e dai materiali ferrosi presenti a bordo.",
        "ris_3": "V"
    },
    {
        "progressivo": "1.7.4-26",
        "risposta_2": "dalla posizione della nave sul globo terrestre.",
        "risposta_1": "dalla velocità effettiva dell'imbarcazione.",
        "argomento": "Bussole magnetiche",
        "id": 1106,
        "immagine": "",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "F",
        "ris_3": "V",
        "domanda": "Da cosa dipende la deviazione magnetica?",
        "risposta_3": "dai ferri duri e dai ferri dolci che si trovano a bordo."
    },
    {
        "ris_1": "V",
        "risposta_1": "su tabelle in dotazione alle imbarcazioni.",
        "risposta_2": "sulle carte nautiche.",
        "progressivo": "1.7.4-27",
        "risposta_3": "sul portolano e sull'elenco fari e fanali.",
        "domanda": "I valori della deviazione magnetica sono reperibili:",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "id": 1107,
        "argomento": "Bussole magnetiche",
        "ris_2": "F",
        "ris_3": "F"
    },
    {
        "domanda": "Qual è la funzione del liquido presente all'interno del mortaio di una bussola magnetica di bordo?",
        "risposta_1": "diminuire gli effetti della deviazione magnetica.",
        "immagine": "",
        "ris_3": "V",
        "risposta_3": "assorbire colpi di mare e vibrazioni, nonché conferire massima stabilità ai piccoli magneti interni.",
        "progressivo": "1.7.4-28",
        "ris_1": "F",
        "risposta_2": "mantenere la rosa graduata sempre in orizzontale.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Bussole magnetiche",
        "ris_2": "F",
        "id": 1108
    },
    {
        "progressivo": "1.7.4-29",
        "ris_2": "F",
        "domanda": "L'elemento sensibile della bussola è dato da:",
        "immagine": "",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_3": "equipaggio magnetico (piccoli magneti interni).",
        "ris_1": "F",
        "id": 1109,
        "ris_3": "V",
        "argomento": "Bussole magnetiche",
        "risposta_2": "rosa graduata.",
        "risposta_1": "sospensione cardanica."
    },
    {
        "capitolo": "TEORIA DELLO SCAFO",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "id": 111,
        "risposta_2": "ordinario.",
        "domanda": "Il timone avente tutta la pala a poppavia dell’anima è denominato:",
        "risposta_3": "comune.",
        "ris_1": "F",
        "risposta_1": "compensato.",
        "progressivo": "1.1.2-36",
        "immagine": "",
        "ris_3": "F",
        "ris_2": "V"
    },
    {
        "risposta_3": "nord vero.",
        "risposta_2": "nord magnetico.",
        "progressivo": "1.7.4-30",
        "immagine": "",
        "ris_1": "V",
        "domanda": "Gli aghi magnetici della bussola magnetica, installata su di un'imbarcazione, si orientano verso il:",
        "argomento": "Bussole magnetiche",
        "ris_2": "F",
        "id": 1110,
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "nord bussola.",
        "ris_3": "F"
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "progressivo": "1.7.4-31",
        "risposta_1": "in funzione della direzione del nord magnetico.",
        "ris_2": "V",
        "ris_1": "F",
        "ris_3": "F",
        "id": 1111,
        "immagine": "",
        "argomento": "Bussole magnetiche",
        "domanda": "L'orientamento della linea di fede di una bussola è:",
        "risposta_3": "parallelo all'asse trasversale dell'unità.",
        "risposta_2": "parallelo all'asse longitudinale dell'unità."
    },
    {
        "ris_1": "V",
        "risposta_2": "non deve mai variare.",
        "ris_2": "F",
        "argomento": "Bussole magnetiche",
        "domanda": "La declinazione varia:",
        "progressivo": "1.7.4-32",
        "risposta_1": "al variare della posizione geografica dell'unità.",
        "risposta_3": "al variare della prora dell'unità.",
        "ris_3": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1112,
        "immagine": ""
    },
    {
        "ris_2": "F",
        "ris_1": "F",
        "risposta_3": "da 0 a 360 gradi in senso orario misurati cominciando dalla direzione del Nord bussola (Nb).",
        "domanda": "La rosa di una bussola è graduata:",
        "risposta_2": "da 0 a 360 gradi in senso orario misurati cominciando da prora.",
        "immagine": "",
        "risposta_1": "da 0 a 180 gradi a dritta e a sinistra.",
        "ris_3": "V",
        "argomento": "Bussole magnetiche",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "progressivo": "1.7.4-33",
        "id": 1113
    },
    {
        "argomento": "Bussole magnetiche",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "La declinazione magnetica è indicata con il segno:",
        "immagine": "",
        "progressivo": "1.7.4-34",
        "id": 1114,
        "risposta_1": "Est-Ovest (rispettivamente negativa e positiva).",
        "ris_2": "V",
        "ris_1": "F",
        "risposta_3": "Nord-Sud (rispettivamente positiva e negativa).",
        "risposta_2": "Est-Ovest (rispettivamente positiva e negativa).",
        "ris_3": "F"
    },
    {
        "risposta_3": "in nessun caso, in quanto tutti i materiali, prima o poi, risentono del campo magnetico terrestre.",
        "immagine": "",
        "risposta_2": "nel caso a bordo non ci sia alcuna influenza magnetica, come su una barca in legno in cui non sono presenti elementi ferrosi e apparecchiature elettriche di qualsiasi genere.",
        "ris_3": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Bussole magnetiche",
        "ris_1": "F",
        "id": 1115,
        "risposta_1": "in nessun caso.",
        "ris_2": "V",
        "domanda": "Da un punto di vista teorico, in quale particolare caso  la bussola magnetica di bordo si orienta esattamente verso il nord magnetico?",
        "progressivo": "1.7.4-35"
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "compensa la deviazione causata dai materiali ferrosi presenti a bordo.",
        "progressivo": "1.7.4-36",
        "domanda": "La linea di fede della bussola:",
        "ris_1": "F",
        "argomento": "Bussole magnetiche",
        "risposta_2": "mantiene la prora prestabilita.",
        "ris_2": "V",
        "risposta_3": "indica il nord.",
        "ris_3": "F",
        "immagine": "",
        "id": 1116
    },
    {
        "ris_1": "V",
        "ris_2": "F",
        "risposta_2": "metodo del cerchio capace.",
        "ris_3": "F",
        "progressivo": "1.7.4-37",
        "risposta_1": "metodo dell'allineamento; metodo dell'osservazione della stella polare.",
        "immagine": "",
        "argomento": "Bussole magnetiche",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_3": "metodo del rilevamento di un punto cospicuo e della relativa distanza.",
        "id": 1117,
        "domanda": "Quale metodo posso utilizzare per controllare la deviazione della mia bussola?"
    },
    {
        "risposta_3": "positivo se il nord bussola si trova a Est del nord magnetico e segno negativo se il nord bussola si trova a Ovest del nord magnetico.",
        "ris_3": "V",
        "progressivo": "1.7.4-38",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "La deviazione magnetica ha segno:",
        "argomento": "Bussole magnetiche",
        "immagine": "",
        "risposta_1": "positivo se il nord magnetico e nord bussola coincidono.",
        "ris_2": "F",
        "ris_1": "F",
        "id": 1118,
        "risposta_2": "negativo se il nord bussola si trova a Est del nord magnetico e segno positivo se il nord bussola si trova a Ovest del nord magnetico."
    },
    {
        "risposta_1": "terrestre.",
        "ris_1": "V",
        "progressivo": "1.7.4-39",
        "ris_3": "F",
        "ris_2": "F",
        "immagine": "",
        "risposta_3": "di bordo.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "di bordo in funzione del magnetismo terrestre.",
        "argomento": "Bussole magnetiche",
        "id": 1119,
        "domanda": "La declinazione magnetica è causata dal magnetismo:"
    },
    {
        "ris_2": "V",
        "risposta_3": "l’unità ruota decisamente di più.",
        "ris_3": "F",
        "domanda": "Installando un timone compensato si ottiene che:",
        "risposta_1": "si riesce a far ruotare il timone fino a circa 90 gradi.",
        "capitolo": "TEORIA DELLO SCAFO",
        "id": 112,
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "risposta_2": "è richiesto uno sforzo minore per girare la ruota.",
        "immagine": "",
        "progressivo": "1.1.2-37",
        "ris_1": "F"
    },
    {
        "risposta_2": "di bordo.",
        "immagine": "",
        "ris_2": "V",
        "ris_1": "F",
        "risposta_3": "terrestre.",
        "id": 1120,
        "domanda": "La deviazione magnetica è causata dal magnetismo:",
        "ris_3": "F",
        "progressivo": "1.7.4-40",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Bussole magnetiche",
        "risposta_1": "di bordo in funzione del magnetismo terrestre."
    },
    {
        "ris_3": "F",
        "argomento": "Bussole magnetiche",
        "immagine": "",
        "ris_2": "F",
        "domanda": "La linea di fede:",
        "id": 1121,
        "risposta_2": "si orienta verso il nord bussola.",
        "ris_1": "V",
        "risposta_1": "indica la direzione prodiera dell'asse longitudinale dell'unità.",
        "risposta_3": "è trasversale alla direzione dell'asse longitudinale dell'unità.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "progressivo": "1.7.4-41"
    },
    {
        "risposta_1": "degli anni trascorsi dalla pubblicazione della carta nautica.",
        "ris_3": "F",
        "risposta_2": "della prora che si intende impostare.",
        "risposta_3": "del luogo in cui ci si trova.",
        "progressivo": "1.7.4-42",
        "domanda": "La deviazione magnetica varia in funzione:",
        "ris_1": "F",
        "argomento": "Bussole magnetiche",
        "immagine": "",
        "ris_2": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1122
    },
    {
        "risposta_2": "sulla certificazione rilasciata dal produttore che deve sempre essere allegata alla bussola stessa.",
        "argomento": "Bussole magnetiche",
        "risposta_3": "al centro della rosa dei venti delle carte nautiche: bisogna ricordarsi di aggiornare il valore iniziale.",
        "ris_1": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "F",
        "domanda": "Per la conversione/correzione dell’angolo di prora disponibile, il valore della deviazione magnetica si legge:",
        "id": 1123,
        "progressivo": "1.7.4-43",
        "ris_3": "F",
        "risposta_1": "sulla tabella delle deviazioni residue dopo aver fatto eseguire la compensazione dal perito compensatore.",
        "immagine": ""
    },
    {
        "immagine": "",
        "argomento": "Bussole magnetiche",
        "ris_2": "F",
        "risposta_2": "il puntale.",
        "progressivo": "1.7.4-44",
        "id": 1124,
        "ris_1": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "V",
        "risposta_1": "la chiesuola.",
        "domanda": "Il riferimento di una bussola, sotto il quale si legge l’angolo di prora, è:",
        "risposta_3": "la linea di fede."
    },
    {
        "risposta_1": "sia parallela all’asse longitudinale (chiglia) dell’imbarcazione.",
        "ris_2": "F",
        "progressivo": "1.7.4-45",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "V",
        "risposta_3": "sia orientata verso il nord (magnetico o bussola).",
        "ris_3": "F",
        "risposta_2": "sia sempre in ogni caso puntata esattamente sulla prora.",
        "argomento": "Bussole magnetiche",
        "id": 1125,
        "immagine": "",
        "domanda": "Quando si installa la bussola magnetica sull'imbarcazione da diporto ci si deve assicurare che la linea di fede:"
    },
    {
        "risposta_3": "nord bussola: che è quello dato da quella specifica bussola.",
        "domanda": "Il nord indicato dalla bussola a bordo di un'imbarcazione in navigazione è denominato:",
        "ris_2": "F",
        "risposta_1": "nord magnetico: perché i magneti vengono attratti dal campo magnetico terrestre.",
        "argomento": "Bussole magnetiche",
        "ris_3": "V",
        "risposta_2": "nord vero: quello che stiamo veramente seguendo.",
        "immagine": "",
        "ris_1": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1126,
        "progressivo": "1.7.4-46"
    },
    {
        "ris_2": "F",
        "risposta_2": "la declinazione magnetica \"assorbe\" la deviazione magnetica.",
        "progressivo": "1.7.4-47",
        "risposta_3": "il nord vero si orienta verso il nord magnetico.",
        "ris_1": "V",
        "immagine": "",
        "risposta_1": "la bussola predetta è a bordo di un'unità in legno o vetroresina, in assenza di masse ferrose ed apparecchiature elettriche nelle vicinanze della stessa.",
        "domanda": "La \"variazione magnetica\" della bussola magnetica è uguale alla declinazione magnetica se:",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1127,
        "argomento": "Bussole magnetiche",
        "ris_3": "F"
    },
    {
        "risposta_2": "Nm.",
        "id": 1128,
        "ris_1": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "progressivo": "1.7.4-48",
        "ris_3": "F",
        "risposta_1": "Nb.",
        "immagine": "",
        "domanda": "In assenza di campi magnetici esterni, una bussola magnetica a terra indica la direzione del:",
        "risposta_3": "Nv.",
        "argomento": "Bussole magnetiche",
        "ris_2": "V"
    },
    {
        "domanda": "La sospensione cardanica della bussola magnetica:",
        "risposta_1": "consente di mantenere la linea di fede parallela all'asse longitudinale dell'unità.",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "è il  collegamento tra il perno di sospensione e la rosa graduata.",
        "ris_3": "V",
        "immagine": "",
        "risposta_3": "consente di mantenere detta bussola parallela al piano orizzontale.",
        "ris_1": "F",
        "argomento": "Bussole magnetiche",
        "id": 1129,
        "progressivo": "1.7.4-49"
    },
    {
        "capitolo": "TEORIA DELLO SCAFO",
        "domanda": "Come ci si deve comportare per manovrare in caso di avaria al timone su una barca di piccole dimensioni?",
        "ris_1": "F",
        "risposta_3": "immergendo un remo sul lato sinistro per virare a dritta.",
        "risposta_2": "immergendo un remo sul lato sinistro per virare a sinistra.",
        "id": 113,
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "ris_3": "F",
        "progressivo": "1.1.2-38",
        "immagine": "",
        "risposta_1": "non si può manovrare per cui conviene chiedere aiuto.",
        "ris_2": "V"
    },
    {
        "ris_2": "F",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "id": 1130,
        "domanda": "Quali tra questi strumenti e dotazioni non forniscono una posizione stimata?",
        "ris_3": "F",
        "risposta_1": "il GPS e il radar.",
        "risposta_3": "l'orologio di bordo e le squadrette nautiche.",
        "ris_1": "V",
        "risposta_2": "il solcometro (log) e bussola magnetica.",
        "immagine": "",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "progressivo": "1.7.5-1"
    },
    {
        "risposta_1": "solitamente i problemi di navigazione stimata sono risolti attraverso il metodo grafico, utilizzando le carte nautiche, le squadrette e il compasso.",
        "id": 1131,
        "progressivo": "1.7.5-2",
        "ris_3": "F",
        "risposta_3": "solitamente i problemi di navigazione stimata sono risolti attraverso il punto nave astronomico.",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "domanda": "Relativamente alla navigazione stimata, si può affermare che:",
        "immagine": "",
        "risposta_2": "i problemi di navigazione stimata sono risolti solo attraverso l'ausilio del GPS.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "F",
        "ris_1": "V"
    },
    {
        "domanda": "Relativamente alla navigazione stimata, si può affermare che:",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1132,
        "ris_3": "F",
        "progressivo": "1.7.5-3",
        "ris_1": "F",
        "immagine": "",
        "risposta_3": "in presenza di foschia non può essere effettuata per un intervallo di tempo superiore a tre ore.",
        "risposta_1": "può essere effettuata per intervalli di tempo non superiori alle sei ore.",
        "risposta_2": "se effettuata per un lungo intervallo temporale può determinare lo spostamento della posizione stimata dell'unità di svariate miglia nautiche rispetto alla sua posizione reale.",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "ris_2": "V"
    },
    {
        "risposta_2": "mantenere l'unità lontana da potenziali pericoli sia di natura idrografica che di natura meteorologica.",
        "progressivo": "1.7.5-4",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "ris_1": "F",
        "domanda": "In cosa consiste l'adozione del criterio di sicurezza nell'ambito della pianificazione di una navigazione stimata?",
        "id": 1133,
        "risposta_1": "mantenere l'unità navale lontana da potenziali pericoli sia di natura antropica che di natura astronomica.",
        "ris_3": "F",
        "ris_2": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "risposta_3": "mantenere l'unità lontana da potenziali pericoli sia di natura radio-elettrica che di natura termo-dinamica."
    },
    {
        "domanda": "Qual è uno dei possibili criteri di sicurezza di natura idrografica che deve essere tenuto in considerazione nell'ambito della pianificazione di un percorso da effettuare mediante una navigazione stimata?",
        "ris_3": "V",
        "id": 1134,
        "ris_1": "F",
        "immagine": "",
        "progressivo": "1.7.5-5",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_3": "garantire che la distanza tra il fondo e la superficie del mare non risulti inferiore al pescaggio dell'unità navale.",
        "risposta_2": "garantire che il fondo del mare disti dalla superficie meno del pescaggio dell'unità navale.",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "risposta_1": "garantire che il fondo del mare non disti dalla superficie più del pescaggio dell'unità navale."
    },
    {
        "id": 1135,
        "risposta_3": "evitare la possibile presenza di anticiclone, cumulonembi e pressione livellata.",
        "domanda": "Qual è uno dei possibili criteri di sicurezza di natura meteorologica che deve essere tenuto in considerazione nell'ambito della pianificazione di un percorso da effettuare mediante una navigazione stimata?",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "immagine": "",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "evitare la possibile presenza di ghiacci, nebbia e tempesta.",
        "ris_1": "V",
        "ris_3": "F",
        "progressivo": "1.7.5-6",
        "risposta_2": "evitare la possibile presenza di cirri, alta pressione e fronte caldo."
    },
    {
        "risposta_1": "la corretta conoscenza e valutazione della distribuzione dei pesi a bordo consentono al navigante di acquisire una velocità superiore a quella prevista dalla casa costruttrice.",
        "immagine": "",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "Relativamente alla navigazione stimata, si può affermare che:",
        "risposta_2": "la corretta conoscenza e valutazione della distribuzione dei pesi a bordo consentono al navigante di acquisire una velocità inferiore a quella di crociera.",
        "risposta_3": "la corretta conoscenza e valutazione degli effetti causati dal vento e dalla corrente consentono, di contrastarne le conseguenze, modificando adeguatamente la direzione della prua e della velocità propulsiva della propria unità.",
        "ris_2": "F",
        "ris_3": "V",
        "progressivo": "1.7.5-7",
        "id": 1136,
        "ris_1": "F"
    },
    {
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "immagine": "",
        "domanda": "Avuto riguardo al concetto di navigazione stimata, quale tra queste affermazioni è corretta?",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "F",
        "ris_3": "F",
        "risposta_1": "la navigazione stimata consente al navigante esperto di effettuare delle comunicazioni radio più  nitide.",
        "ris_2": "V",
        "risposta_2": "l'esperienza marinaresca maturata nella condotta della navigazione contribuisce a far sì che il navigante possa conoscere, in ogni istante, la più probabile posizione raggiunta con la propria unità navale.",
        "risposta_3": "la navigazione stimata consente al navigante esperto di effettuare dei punti nave costieri più accurati.",
        "id": 1137,
        "progressivo": "1.7.5-8"
    },
    {
        "ris_2": "F",
        "risposta_1": "un punto che rappresenta la posizione dell'unità navale ottenuta mediante due rilevamenti simultanei.",
        "immagine": "",
        "ris_3": "V",
        "progressivo": "1.7.5-9",
        "risposta_3": "un punto che rappresenta in maniera approssimata la posizione dell'unità.",
        "ris_1": "F",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "id": 1138,
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "un punto che rappresenta l'esatta posizione dell'unità navale.",
        "domanda": "Avuto riguardo al concetto di navigazione stimata, cosa si intende per punto stimato?"
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "Come si risolvono di massima i problemi della navigazione stimata?",
        "ris_1": "F",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "id": 1139,
        "risposta_2": "graficamente mediante l'utilizzo della carta nautica del Mercatore la quale rettifica le lossodromie.",
        "ris_3": "F",
        "risposta_1": "geometricamente mediante l'intersezione di due o più rilevamenti.",
        "progressivo": "1.7.5-10",
        "immagine": "",
        "ris_2": "V",
        "risposta_3": "analiticamente mediante l'osservazione astronomica."
    },
    {
        "risposta_3": "la prora tende ad evoluire verso sinistra.",
        "risposta_1": "la poppa tende ad evoluire verso sinistra.",
        "risposta_2": "l’unità avanza con moto diritto.",
        "immagine": "",
        "progressivo": "1.1.2-39",
        "ris_2": "F",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_3": "F",
        "ris_1": "V",
        "id": 114,
        "domanda": "Con motore entrobordo ed elica sinistrorsa, in marcia avanti e con il timone al centro:"
    },
    {
        "risposta_1": "il margine di errore dei rilevamenti costieri effettuati durante la navigazione.",
        "ris_1": "F",
        "domanda": "Quali possono essere i fattori che concorrono a rendere il punto nave stimato non preciso?",
        "ris_3": "F",
        "risposta_3": "la differenza di rilevamento tra i rilevamenti costieri effettuati durante la navigazione.",
        "id": 1140,
        "ris_2": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "risposta_2": "scarroccio, deriva, declinazione magnetica e deviazione magnetica.",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "progressivo": "1.7.5-11"
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "F",
        "risposta_3": "la posizione stimata non può differire di oltre un quarto di miglio nautico rispetto alla posizione effettiva.",
        "risposta_1": "la posizione stimata non può differire di oltre mezzo miglio nautico rispetto alla posizione effettiva.",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "domanda": "Dovendo effettuare un atterraggio, a conclusione di una navigazione stimata, è opportuno considerare che:",
        "immagine": "",
        "ris_2": "V",
        "progressivo": "1.7.5-12",
        "id": 1141,
        "ris_3": "F",
        "risposta_2": "la posizione stimata rappresenta di fatto il centro di una zona di incertezza, la cui estensione può risultare anche molto vasta."
    },
    {
        "progressivo": "1.7.5-13",
        "domanda": "Qual è l'unità di misura per le velocità in mare?",
        "ris_1": "F",
        "ris_3": "F",
        "ris_2": "V",
        "risposta_1": "il metro al secondo.",
        "immagine": "",
        "risposta_3": "il chilometro orario.",
        "risposta_2": "il nodo.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1142,
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità"
    },
    {
        "domanda": "Considerato il calcolo delle velocità in mare, a cosa corrisponde il nodo?",
        "risposta_1": "a un miglio marino percorso in un'ora.",
        "ris_3": "F",
        "immagine": "",
        "id": 1143,
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "risposta_3": "a un metro percorso in un secondo.",
        "ris_2": "F",
        "risposta_2": "a un chilometro percorso in un'ora.",
        "ris_1": "V",
        "progressivo": "1.7.5-14",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA"
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "id": 1144,
        "risposta_2": "V = S / T, dove S si esprime in miglia nautiche e T in ore e decimi di ora.",
        "domanda": "Avuto riguardo alla navigazione stimata, volendo calcolare il valore della velocità riferita ad un percorso stimato, effettuato in un determinato intervallo temporale, quale tra le seguenti formule dovrà essere applicata?",
        "progressivo": "1.7.5-15",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "ris_3": "F",
        "ris_1": "F",
        "risposta_3": "T = S / V, dove S si esprime in miglia nautiche e V in nodi (miglia nautiche orarie).",
        "ris_2": "V",
        "risposta_1": "S = V * T, dove V si esprime in nodi (miglia nautiche orarie) e T in ore e decimi di ora."
    },
    {
        "risposta_3": "S = V * T, dove V si esprime in nodi (miglia nautiche orarie) e T in ore e decimi di ora.",
        "ris_1": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "ris_2": "F",
        "domanda": "Avuto riguardo alla navigazione stimata, volendo calcolare il valore del percorso stimato compreso tra due punti, considerando la velocità propulsiva della propria unità navale e l'intervallo temporale impiegato per percorrerlo, quale tra le seguenti formule dovrà essere applicata?",
        "risposta_1": "V = S / T, dove S si esprime in miglia nautiche e T in ore e decimi di ora.",
        "progressivo": "1.7.5-16",
        "risposta_2": "T = S / V, dove S si esprime in miglia nautiche e V in nodi (miglia nautiche orarie).",
        "ris_3": "V",
        "id": 1145
    },
    {
        "progressivo": "1.7.5-17",
        "domanda": "Avuto riguardo alla navigazione stimata, volendo calcolare l'intervallo temporale necessario per percorrere la distanza tra due punti ad una determinata velocità propulsiva, quale tra le seguenti formule dovrà essere applicata?",
        "risposta_2": "S = V * T, dove V si esprime in nodi (miglia nautiche orarie) e T in ore e decimi di ora.",
        "id": 1146,
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "ris_1": "V",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "ris_3": "F",
        "risposta_3": "V = S / T, dove S si esprime in miglia nautiche e T in ore e decimi di ora.",
        "risposta_1": "T = S / V, dove S si esprime in miglia nautiche e V in nodi (miglia nautiche orarie)."
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "risposta_2": "pantografi.",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "ris_1": "V",
        "ris_3": "F",
        "risposta_3": "aerografi e grafometri.",
        "progressivo": "1.7.5-18",
        "domanda": "Quali possono essere gli strumenti nautici utilizzabili per la misurazione del valore di una rotta stimata tracciata sulla carta nautica del Mercatore?",
        "ris_2": "F",
        "risposta_1": "squadrette nautiche e parallele.",
        "id": 1147
    },
    {
        "risposta_3": "alla misurazione delle velocità dei bersagli individuati dal radar.",
        "risposta_1": "al calcolo delle distanze e all'individuazione delle coordinate geografiche.",
        "ris_2": "F",
        "ris_3": "F",
        "id": 1148,
        "risposta_2": "al tracciamento degli angoli di rotta e dei rilevamenti.",
        "ris_1": "V",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "immagine": "",
        "progressivo": "1.7.5-19",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "Avuto riguardo alla navigazione costiera, a cosa serve principalmente il compasso nautico nelle operazioni di carteggio?"
    },
    {
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "risposta_2": "1.852 metri.",
        "immagine": "",
        "ris_1": "F",
        "risposta_1": "1.825 metri.",
        "domanda": "Un miglio marino equivale a:",
        "ris_3": "F",
        "id": 1149,
        "risposta_3": "1.609 metri.",
        "ris_2": "V",
        "progressivo": "1.7.5-20",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA"
    },
    {
        "immagine": "",
        "ris_3": "F",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "risposta_3": "la poppa tende ad evoluire verso sinistra.",
        "capitolo": "TEORIA DELLO SCAFO",
        "progressivo": "1.1.2-40",
        "ris_2": "V",
        "ris_1": "F",
        "domanda": "Con motore entrobordo ed elica sinistrorsa, in retromarcia e con il timone al centro, accade che:",
        "risposta_1": "l’unità indietreggia con moto diritto.",
        "id": 115,
        "risposta_2": "la poppa tende ad evoluire verso dritta."
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "è la lunghezza dell'arco di meridiano ampio un sessantesimo di grado (1', un primo).",
        "ris_2": "F",
        "risposta_2": "è la lunghezza dell'arco di equatore ampio un grado.",
        "domanda": "Definizione di miglio nautico:",
        "risposta_3": "è la lunghezza dell'arco di cerchio massimo ampio un grado.",
        "immagine": "",
        "id": 1150,
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "ris_3": "F",
        "ris_1": "V",
        "progressivo": "1.7.5-21"
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "errori nella prora vera (Pv).",
        "progressivo": "1.7.5-22",
        "ris_3": "V",
        "risposta_1": "errori nella misura della velocità con il solcometro.",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "ris_1": "F",
        "id": 1151,
        "ris_2": "F",
        "immagine": "",
        "risposta_3": "errori soggettivi nella conoscenza e/o nell'apprezzamento dello scarroccio e della deriva.",
        "domanda": "Qual è la causa più importante dell'imprecisione del punto stimato?"
    },
    {
        "progressivo": "1.7.5-23",
        "id": 1152,
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "F",
        "risposta_2": "bussola, solcometro (per misura della velocità propria) e orologio.",
        "ris_3": "F",
        "risposta_1": "bussola e solcometro (per misura della velocità propria).",
        "domanda": "Gli strumenti della navigazione stimata sono:",
        "risposta_3": "esclusivamente l'orologio.",
        "ris_2": "V",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "immagine": ""
    },
    {
        "ris_3": "F",
        "immagine": "",
        "domanda": "Una nave che percorre 12 miglia in due ore, a che velocità sta navigando?",
        "id": 1153,
        "ris_2": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "risposta_2": "6 nodi.",
        "progressivo": "1.7.5-24",
        "risposta_3": "6 k/h.",
        "ris_1": "F",
        "risposta_1": "12 miglia all'ora."
    },
    {
        "domanda": "Il nodo è:",
        "risposta_2": "la grandezza che equivale a 1.850 metri, pari alla lunghezza di 1' (un primo) di Longitudine.",
        "ris_3": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "F",
        "risposta_1": "la velocità di 1850 metri all'ora.",
        "immagine": "",
        "risposta_3": "l'unità di misura della velocità della nave.",
        "progressivo": "1.7.5-25",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "ris_1": "F",
        "id": 1154
    },
    {
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "progressivo": "1.7.5-26",
        "risposta_1": "delle distanze in mare.",
        "ris_3": "F",
        "risposta_3": "degli angoli in mare.",
        "ris_1": "V",
        "domanda": "Il miglio marino è l'unità di misura:",
        "id": 1155,
        "ris_2": "F",
        "risposta_2": "dell'angolo che si forma tra le rotte di due imbarcazioni.",
        "immagine": "",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA"
    },
    {
        "ris_1": "F",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "V",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "domanda": "Una nave in navigazione a 16 nodi effettivi, in 15 minuti percorrerà:",
        "risposta_3": "4 miglia.",
        "risposta_2": "4,50 miglia.",
        "risposta_1": "5 miglia.",
        "immagine": "",
        "id": 1156,
        "progressivo": "1.7.5-27"
    },
    {
        "ris_2": "F",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "immagine": "",
        "ris_3": "F",
        "risposta_3": "del punto nave è in funzione degli elementi in vista della costa.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "della rotta vera coincide con la rotta bussola.",
        "id": 1157,
        "progressivo": "1.7.5-28",
        "ris_1": "V",
        "domanda": "La navigazione è stimata se la determinazione:",
        "risposta_1": "del punto nave stimato è in funzione della prora impostata e delle miglia percorse in un dato intervallo di tempo."
    },
    {
        "ris_3": "F",
        "progressivo": "1.7.5-29",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1158,
        "domanda": "Il punto stimato per il navigante:",
        "immagine": "",
        "ris_1": "F",
        "ris_2": "V",
        "risposta_1": "può, in lunghe navigazioni con cielo coperto, validamente sostituire un punto astronomico di difficile o impossibile esecuzione.",
        "risposta_2": "è insostituibile, ma insufficiente per condurre la navigazione in sicurezza.",
        "risposta_3": "è preciso ed affidabile in ogni circostanza."
    },
    {
        "immagine": "",
        "risposta_1": "rotta vera Rv, velocità effettiva, posizione iniziale.",
        "id": 1159,
        "domanda": "Per determinare il punto stimato bisogna conoscere i seguenti elementi:",
        "progressivo": "1.7.5-30",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "F",
        "ris_1": "F",
        "risposta_2": "prora vera Pv, velocità propria, posizione iniziale, tempo trascorso.",
        "risposta_3": "moto proprio, deriva, scarroccio.",
        "ris_2": "V",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità"
    },
    {
        "risposta_2": "inclinando leggermente l’asse portaelica.",
        "risposta_3": "con il timone.",
        "ris_1": "F",
        "ris_2": "F",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "progressivo": "1.1.2-41",
        "capitolo": "TEORIA DELLO SCAFO",
        "immagine": "",
        "id": 116,
        "ris_3": "V",
        "risposta_1": "usando un'elica particolare.",
        "domanda": "L’effetto evolutivo dell’elica su un motore entrobordo si compensa:"
    },
    {
        "domanda": "Una nave in navigazione a 15 nodi effettivi, in 45 minuti percorrerà:",
        "progressivo": "1.7.5-31",
        "risposta_3": "12 miglia.",
        "id": 1160,
        "risposta_2": "11,25 miglia.",
        "immagine": "",
        "ris_1": "F",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "risposta_1": "10,75 miglia.",
        "ris_2": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "F"
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "F",
        "id": 1161,
        "risposta_2": "120 miglia.",
        "risposta_3": "120 km.",
        "risposta_1": "60 miglia.",
        "ris_2": "F",
        "ris_1": "V",
        "immagine": "",
        "progressivo": "1.7.5-32",
        "domanda": "In un grado di latitudine sono compresi:",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità"
    },
    {
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "risposta_1": "si tracciano i paralleli passanti per i due punti sino alla scala delle latitudini.",
        "ris_2": "F",
        "risposta_2": "si tracciano le linee dai punti estremi della carta alla propria posizione.",
        "progressivo": "1.7.5-33",
        "risposta_3": "con il compasso si misura la distanza tra i due punti e la si riporta sulla scala delle latitudini.",
        "ris_1": "F",
        "id": 1162,
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "V",
        "immagine": "",
        "domanda": "Per misurare la distanza tra due punti sulla carta nautica:"
    },
    {
        "risposta_1": "9,25 miglia.",
        "immagine": "",
        "ris_3": "F",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "domanda": "Una nave in navigazione a 15 nodi effettivi, in 35 minuti percorrerà:",
        "progressivo": "1.7.5-34",
        "id": 1163,
        "risposta_3": "7,75 miglia.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "8,75 miglia.",
        "ris_2": "V",
        "ris_1": "F"
    },
    {
        "risposta_2": "la posizione con la relazione \"S = V x T\" a ogni variazione di velocità.",
        "ris_2": "V",
        "immagine": "",
        "domanda": "Una corretta navigazione stimata consiste, tra l'altro, anche nel calcolare:",
        "risposta_1": "esattamente con precisione la propria velocità istantanea.",
        "ris_1": "F",
        "id": 1164,
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "ris_3": "F",
        "progressivo": "1.7.5-35",
        "risposta_3": "la posizione approssimativa del punto di partenza."
    },
    {
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "risposta_2": "con un luogo di posizione.",
        "immagine": "",
        "ris_1": "F",
        "ris_3": "V",
        "risposta_3": "con almeno due luoghi di posizione.",
        "domanda": "Il punto nave è ricavato:",
        "id": 1165,
        "ris_2": "F",
        "risposta_1": "con almeno tre o più luoghi di posizione.",
        "progressivo": "1.7.5-36",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA"
    },
    {
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "domanda": "180 miglia corrispondono a:",
        "ris_2": "F",
        "immagine": "",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_3": "1800 Km.",
        "progressivo": "1.7.5-37",
        "risposta_1": "3 gradi di latitudine.",
        "id": 1166,
        "ris_3": "F",
        "risposta_2": "180 Km.",
        "ris_1": "V"
    },
    {
        "ris_3": "V",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "domanda": "In un grado di latitudine sono compresi:",
        "ris_1": "F",
        "id": 1167,
        "progressivo": "1.7.5-38",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_3": "60 primi di arco.",
        "risposta_2": "60 secondi di arco.",
        "risposta_1": "30 secondi di arco.",
        "immagine": ""
    },
    {
        "ris_3": "F",
        "id": 1168,
        "domanda": "Una nave in navigazione a 9 nodi effettivi, in 45 minuti percorrerà:",
        "immagine": "",
        "risposta_1": "6,75 miglia.",
        "progressivo": "1.7.5-39",
        "risposta_3": "5,75 miglia.",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "6,25 miglia.",
        "ris_1": "V",
        "ris_2": "F"
    },
    {
        "ris_3": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "è quel luogo formato da una circonferenza i cui punti vedono due punti cospicui sulla costa sempre con lo stesso angolo e quindi con la stessa differenza di azimuth.",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "risposta_2": "è quel luogo formato da una circonferenza i cui punti vedono un punto cospicuo sulla costa sempre con lo stesso angolo.",
        "immagine": "",
        "progressivo": "1.7.5-40",
        "ris_2": "F",
        "domanda": "Il luogo di posizione di egual differenza d'azimuth:",
        "id": 1169,
        "risposta_3": "è quel luogo che, per essere attendibile deve essere utilizzato necessariamente con altro luogo di posizione.",
        "ris_1": "V"
    },
    {
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "immagine": "",
        "domanda": "Cosa si intende per timoni accoppiati di un'unità?",
        "id": 117,
        "risposta_3": "la condizione in cui i due timoni montati a bordo sono collegati al pilota automatico.",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_1": "la condizione in cui il timone principale e quello di emergenza si muovono in sincronia.",
        "ris_1": "F",
        "risposta_2": "due timoni uguali e simmetrici utilizzati su alcune unità navali bielica che agiscono in sincronia.",
        "ris_2": "V",
        "progressivo": "1.1.2-42",
        "ris_3": "F"
    },
    {
        "ris_1": "F",
        "domanda": "Una nave in navigazione a 19 nodi effettivi, in 15 minuti percorrerà:",
        "progressivo": "1.7.5-41",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "4,25 miglia.",
        "risposta_3": "4,50 miglia.",
        "ris_2": "V",
        "id": 1170,
        "immagine": "",
        "risposta_2": "4,75 miglia.",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "ris_3": "F"
    },
    {
        "risposta_3": "10' (dieci primi) di latitudine.",
        "immagine": "",
        "id": 1171,
        "ris_2": "V",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "domanda": "Quanti primi sono contenuti in un miglio?",
        "ris_3": "F",
        "ris_1": "F",
        "risposta_1": "60' (sessanta primi) di latitudine.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "progressivo": "1.7.5-42",
        "risposta_2": "1' (un primo) di latitudine."
    },
    {
        "ris_3": "V",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "risposta_1": "7,25 miglia.",
        "risposta_3": "7,50 miglia",
        "risposta_2": "6,50 miglia",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "Una nave in navigazione a 18 nodi effettivi, in 25 minuti percorrerà:",
        "progressivo": "1.7.5-43",
        "id": 1172,
        "ris_2": "F",
        "ris_1": "F",
        "immagine": ""
    },
    {
        "ris_1": "V",
        "risposta_1": "2,85 miglia.",
        "immagine": "",
        "domanda": "Una nave in navigazione a 19 nodi effettivi, in 9 minuti percorrerà:",
        "id": 1173,
        "risposta_3": "2,25 miglia",
        "ris_3": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "3,15 miglia",
        "ris_2": "F",
        "progressivo": "1.7.5-44",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità"
    },
    {
        "risposta_3": "14,50 miglia.",
        "ris_3": "F",
        "progressivo": "1.7.5-45",
        "id": 1174,
        "ris_1": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "14 miglia.",
        "risposta_1": "13,75 miglia.",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "ris_2": "V",
        "domanda": "Una nave in navigazione a 24 nodi effettivi, in 35 minuti percorrerà:",
        "immagine": ""
    },
    {
        "risposta_3": "5,50 miglia.",
        "ris_1": "F",
        "ris_3": "V",
        "ris_2": "F",
        "id": 1175,
        "risposta_1": "5,25 miglia.",
        "immagine": "",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "progressivo": "1.7.5-46",
        "domanda": "Una nave in navigazione a 22 nodi effettivi, in 15 minuti percorrerà:",
        "risposta_2": "6,50 miglia."
    },
    {
        "progressivo": "1.7.5-47",
        "ris_3": "F",
        "risposta_1": "15,5 miglia.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1176,
        "domanda": "Una nave in navigazione a 22 nodi effettivi, in 45 minuti percorrerà:",
        "immagine": "",
        "risposta_3": "16 miglia.",
        "ris_2": "V",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "risposta_2": "16,5 miglia.",
        "ris_1": "F"
    },
    {
        "domanda": "Una nave in navigazione a 21 nodi effettivi, in 45 minuti percorrerà:",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "V",
        "risposta_1": "15,75 miglia.",
        "ris_3": "F",
        "ris_2": "F",
        "immagine": "",
        "progressivo": "1.7.5-48",
        "id": 1177,
        "risposta_2": "16,25 miglia.",
        "risposta_3": "15,50 miglia.",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità"
    },
    {
        "domanda": "Una nave in navigazione a 16 nodi effettivi, in 45 minuti percorrerà:",
        "risposta_1": "12,25 miglia.",
        "ris_2": "V",
        "ris_1": "F",
        "ris_3": "F",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_3": "12,50 miglia.",
        "id": 1178,
        "progressivo": "1.7.5-49",
        "risposta_2": "12 miglia.",
        "immagine": ""
    },
    {
        "ris_3": "F",
        "id": 1179,
        "immagine": "",
        "ris_1": "V",
        "risposta_2": "trecentosessantesima parte di un arco di equatore di 1 grado.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "1 miglio marino corrisponde alla:",
        "progressivo": "1.7.5-50",
        "ris_2": "F",
        "risposta_3": "sessantesima parte di un arco di longitudine di 1 grado.",
        "risposta_1": "sessantesima parte di un arco di latitudine di 1 grado.",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità"
    },
    {
        "domanda": "In un'unità bielica (con due assi portaelica), per quale motivo l'elica di dritta è destrorsa e l'elica di sinistra è sinistrorsa?",
        "risposta_1": "per garantire una costante velocità di crociera.",
        "capitolo": "TEORIA DELLO SCAFO",
        "immagine": "",
        "progressivo": "1.1.2-43",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "id": 118,
        "ris_3": "V",
        "risposta_2": "per eliminare il fenomeno di cavitazione dell'elica.",
        "risposta_3": "Per compensare l'effetto laterale delle pale.",
        "ris_2": "F",
        "ris_1": "F"
    },
    {
        "id": 1180,
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "Cosa significa l'affermazione \"velocità 5 nodi\"?",
        "ris_3": "V",
        "risposta_3": "che in un'ora si percorrono 5 miglia.",
        "risposta_2": "una distanza di 5 miglia.",
        "ris_2": "F",
        "progressivo": "1.7.5-51",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "risposta_1": "che la velocità di navigazione è di 5 km ogni ora.",
        "ris_1": "F",
        "immagine": ""
    },
    {
        "domanda": "Per misurare la distanza tra due punti sulla carta nautica in proiezione di mercatore si utilizza la scala:",
        "progressivo": "1.7.5-52",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "ris_1": "F",
        "id": 1181,
        "risposta_1": "delle longitudini, alla stessa longitudine della zona di mare dove è stata misurata la distanza tra due punti.",
        "risposta_2": "delle latitudini, alla stessa longitudine della zona dove è stata misurata la distanza tra due punti.",
        "risposta_3": "delle latitudini, alla stessa latitudine della zona dove è stata misurata la distanza tra due punti.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "V",
        "ris_2": "F",
        "immagine": ""
    },
    {
        "risposta_3": "44 miglia esatte.",
        "risposta_1": "4 miglia e 4 decimi di miglio.",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "domanda": "Un tratto di 4’.4 di latitudine corrispondono a:",
        "immagine": "",
        "ris_2": "F",
        "ris_1": "V",
        "id": 1182,
        "risposta_2": "4 miglia e 24 centesimi di miglio.",
        "progressivo": "1.7.5-53",
        "ris_3": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA"
    },
    {
        "risposta_2": "4 ore e 24 minuti.",
        "ris_3": "F",
        "risposta_3": "4 ore e 14 minuti.",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "progressivo": "1.7.5-54",
        "ris_1": "F",
        "ris_2": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "Applicata  la  formula  T = S : V (tempo = spazio diviso la velocità), si ricava 4,4. Ciò significa che la navigazione durerà:",
        "immagine": "",
        "id": 1183,
        "risposta_1": "4 ore e 40 minuti."
    },
    {
        "immagine": "",
        "ris_2": "V",
        "risposta_1": "il GPS.",
        "id": 1184,
        "progressivo": "1.7.5-55",
        "domanda": "Il punto nave stimato si determina con:",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "la formula S = V x T.",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "ris_1": "F",
        "ris_3": "F",
        "risposta_3": "almeno due luoghi di posizione."
    },
    {
        "immagine": "",
        "progressivo": "1.7.5-56",
        "risposta_3": "1 ora e 24 minuti.",
        "ris_3": "V",
        "id": 1185,
        "ris_2": "F",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "domanda": "S = 14 miglia; V = 10 nodi. Il tempo di navigazione sarà di:",
        "ris_1": "F",
        "risposta_2": "1 ora e 34 minuti.",
        "risposta_1": "1 ora e 14 minuti.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA"
    },
    {
        "risposta_1": "1 ora e 56 minuti.",
        "ris_1": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "2 ore e 06 minuti.",
        "immagine": "",
        "ris_2": "F",
        "domanda": "S = 11,6 miglia; V = 6 nodi. Il tempo di navigazione sarà di:",
        "ris_3": "F",
        "id": 1186,
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "progressivo": "1.7.5-57",
        "risposta_3": "1 ora e 46 minuti."
    },
    {
        "id": 1187,
        "risposta_3": "1 ora e 54 minuti.",
        "progressivo": "1.7.5-58",
        "risposta_2": "2 ore e 4 minuti.",
        "ris_2": "V",
        "ris_1": "F",
        "immagine": "",
        "ris_3": "F",
        "risposta_1": "2 ore e 14 minuti.",
        "domanda": "S = 12,4 miglia; V = 6 nodi. Il tempo di navigazione sarà di:",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità"
    },
    {
        "risposta_3": "9,5 miglia.",
        "ris_2": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1188,
        "domanda": "V = 8 nodi; T = 1 ora e 15 minuti. Lo spazio percorso sarà di:",
        "ris_3": "F",
        "risposta_2": "10 miglia.",
        "risposta_1": "10,5 miglia.",
        "progressivo": "1.7.5-59",
        "ris_1": "F",
        "immagine": "",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità"
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "F",
        "id": 1189,
        "progressivo": "1.7.5-60",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "ris_1": "V",
        "ris_3": "F",
        "risposta_3": "17,5 miglia.",
        "domanda": "V = 6 nodi; T = 2 ore e 45 minuti. Lo spazio percorso sarà di:",
        "risposta_1": "16,5 miglia.",
        "risposta_2": "15,5 miglia.",
        "immagine": ""
    },
    {
        "risposta_1": "la prua si sposterà verso sinistra e la poppa verso dritta.",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "ris_3": "F",
        "id": 119,
        "immagine": "",
        "risposta_2": "la prua si sposterà verso dritta e la poppa verso sinistra.",
        "progressivo": "1.1.2-44",
        "ris_1": "V",
        "capitolo": "TEORIA DELLO SCAFO",
        "domanda": "In generale, quale effetto produce un'elica destrorsa in marcia avanti su un'unità navale monoelica con il timone al centro?",
        "ris_2": "F",
        "risposta_3": "poppa tenderà ad abbassarsi e la prua ad innalzarsi."
    },
    {
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "ris_3": "V",
        "domanda": "V = 9 nodi; T = 20 minuti. Lo spazio percorso sarà di:",
        "progressivo": "1.7.5-61",
        "ris_1": "F",
        "risposta_2": "3,5 miglia.",
        "immagine": "",
        "risposta_3": "3 miglia.",
        "id": 1190,
        "risposta_1": "2,5 miglia.",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA"
    },
    {
        "ris_3": "V",
        "ris_2": "F",
        "risposta_2": "13 nodi.",
        "domanda": "T = 1 ora e 40 minuti; S = 20 miglia. La velocità sarà di:",
        "risposta_1": "10 nodi.",
        "risposta_3": "12 nodi.",
        "id": 1191,
        "progressivo": "1.7.5-62",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "immagine": "",
        "ris_1": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA"
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "id": 1192,
        "risposta_2": "10,6 nodi.",
        "ris_1": "V",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "domanda": "T = 1 ora e 15 minuti; S = 12 miglia. La velocità sarà di:",
        "ris_2": "F",
        "risposta_1": "9,6 nodi.",
        "ris_3": "F",
        "progressivo": "1.7.5-63",
        "risposta_3": "8,6 nodi."
    },
    {
        "progressivo": "1.7.5-63",
        "risposta_2": "7 nodi.",
        "ris_3": "F",
        "id": 1193,
        "ris_1": "F",
        "ris_2": "V",
        "risposta_1": "8 nodi.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "risposta_3": "6 nodi.",
        "immagine": "",
        "domanda": "T = 3 ore e 30 minuti; S = 24,5 miglia. La velocità sarà di:"
    },
    {
        "risposta_3": "28 miglia.",
        "risposta_1": "27 miglia.",
        "progressivo": "1.7.5-64",
        "ris_3": "V",
        "id": 1194,
        "risposta_2": "29 miglia.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "ris_2": "F",
        "domanda": "T = 2 ore e 20 minuti; V = 12 nodi. Lo spazio percorso sarà di:",
        "immagine": "",
        "ris_1": "F"
    },
    {
        "immagine": "",
        "progressivo": "1.7.5-65",
        "risposta_1": "2 ore e 34 minuti.",
        "risposta_3": "2 ore e 44 minuti.",
        "ris_1": "V",
        "ris_3": "F",
        "id": 1195,
        "ris_2": "F",
        "risposta_2": "2 ore e 24 minuti.",
        "domanda": "S = 18 miglia; V = 7 nodi. Il tempo di navigazione sarà di:",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità"
    },
    {
        "immagine": "",
        "risposta_3": "4 miglia.",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "ris_1": "V",
        "risposta_1": "3 miglia.",
        "risposta_2": "2 miglia.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "Una nave in navigazione a 10 nodi effettivi, in 18 minuti percorrerà:",
        "id": 1196,
        "ris_3": "F",
        "ris_2": "F",
        "progressivo": "1.7.5-66"
    },
    {
        "risposta_2": "4,50 miglia.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "ris_1": "F",
        "ris_2": "V",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "id": 1197,
        "domanda": "Una nave in navigazione a 15 nodi effettivi, in 18 minuti percorrerà:",
        "risposta_3": "4,75 miglia.",
        "ris_3": "F",
        "risposta_1": "4,25 miglia.",
        "progressivo": "1.7.5-67"
    },
    {
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "risposta_3": "3,50 miglia.",
        "ris_3": "V",
        "domanda": "Una nave in navigazione a 6 nodi effettivi, in 35 minuti percorrerà:",
        "ris_2": "F",
        "ris_1": "F",
        "risposta_1": "3,25 miglia.",
        "risposta_2": "4,50 miglia.",
        "immagine": "",
        "id": 1198,
        "progressivo": "1.7.5-68",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA"
    },
    {
        "ris_3": "F",
        "risposta_1": "2,50 miglia.",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1199,
        "domanda": "Una nave in navigazione a 7,5 nodi effettivi, in 20 minuti percorrerà:",
        "ris_1": "V",
        "risposta_2": "2,25 miglia.",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "progressivo": "1.7.5-69",
        "risposta_3": "2,75 miglia.",
        "immagine": ""
    },
    {
        "risposta_3": "dalla lunghezza tra le perpendicolari.",
        "ris_3": "F",
        "risposta_1": "dal peso.",
        "risposta_2": "dalla forma della carena.",
        "id": 12,
        "domanda": "Da cosa dipende la coppia di stabilità di forma di un'unità navale?",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "ris_1": "F",
        "immagine": "",
        "progressivo": "1.1.1-12",
        "ris_2": "V",
        "capitolo": "TEORIA DELLO SCAFO"
    },
    {
        "progressivo": "1.1.2-45",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_2": "F",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "immagine": "",
        "risposta_1": "la poppa tenderà ad abbassarsi e la prua ad innalzarsi.",
        "domanda": "In generale, quale effetto produce un'elica sinistrorsa in marcia avanti su un'unità navale monoelica con il timone al centro?",
        "ris_1": "F",
        "risposta_3": "la prua si sposterà verso dritta e la poppa verso sinistra.",
        "risposta_2": "la prua si sposterà verso sinistra e la poppa verso dritta.",
        "ris_3": "V",
        "id": 120
    },
    {
        "ris_1": "F",
        "risposta_2": "6,75 miglia.",
        "immagine": "",
        "risposta_1": "6,50 miglia.",
        "ris_3": "V",
        "domanda": "Una nave in navigazione a 12,5 nodi effettivi, in 30 minuti percorrerà:",
        "id": 1200,
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "ris_2": "F",
        "risposta_3": "6,25 miglia.",
        "progressivo": "1.7.5-70"
    },
    {
        "id": 1201,
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Elementi di navigazione stimata: tempo, spazio e velocità",
        "progressivo": "1.7.5-71",
        "risposta_3": "per una navigazione oltre le 12 miglia.",
        "risposta_1": "non lo sono per una navigazione oltre le 12 miglia.",
        "domanda": "Quando sono obbligatori gli strumenti da carteggio nautico a bordo?",
        "ris_2": "F",
        "ris_1": "F",
        "immagine": "",
        "ris_3": "V",
        "risposta_2": "per una navigazione entro le 12 miglia."
    },
    {
        "risposta_3": "dall'intersezione di due o più luoghi di posizione.",
        "ris_2": "F",
        "risposta_2": "dall'intersezione di due o più rotte stimate convergenti.",
        "progressivo": "1.7.6-1",
        "risposta_1": "mediante le formule che legano lo spazio, la velocità e il cammino.",
        "immagine": "",
        "argomento": "Elementi di navigazione costiera",
        "id": 1202,
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "Un punto nave costiero può essere determinato:",
        "ris_1": "F",
        "ris_3": "V"
    },
    {
        "risposta_1": "le rette di rilevamento, i cerchi capaci, i cerchi di uguale distanza e le linee batimetriche.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "F",
        "ris_1": "V",
        "id": 1203,
        "argomento": "Elementi di navigazione costiera",
        "risposta_3": "il settore di un segnalamento marittimo luminoso, la bisettrice di altezza, i cerchi di uguale portata luminosa e i cerchi di maggiore portata geografica.",
        "risposta_2": "l'altezza di un astro, la distanza radiogoniometrica, la distanza polare e la distanza altazimutale.",
        "progressivo": "1.7.6-2",
        "domanda": "I luoghi di posizione sono:",
        "ris_3": "F",
        "immagine": ""
    },
    {
        "ris_1": "F",
        "ris_2": "V",
        "risposta_1": "la conoscenza esatta della velocità propulsiva dell'unità navale.",
        "risposta_2": "la disponibilità di carte di navigazione con scala adeguata, al fine di identificare possibili punti cospicui utili alla determinazione del punto nave.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "risposta_3": "la conoscenza esatta della prora vera dell'unità navale.",
        "argomento": "Elementi di navigazione costiera",
        "ris_3": "F",
        "progressivo": "1.7.6-3",
        "id": 1204,
        "domanda": "Uno degli elementi indispensabili per effettuare un'appropriata navigazione costiera è:"
    },
    {
        "risposta_3": "adeguate pubblicazioni nautiche che consentano il riconoscimento della costa.",
        "risposta_2": "un buon cronometro.",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "un buon solcometro.",
        "id": 1205,
        "ris_1": "F",
        "progressivo": "1.7.6-4",
        "domanda": "Quale tra queste dotazioni a bordo di un'unità navale risulta indispensabile per effettuare un'appropriata navigazione costiera?",
        "argomento": "Elementi di navigazione costiera",
        "immagine": "",
        "ris_3": "V"
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Elementi di navigazione costiera",
        "risposta_2": "la navigazione costiera costituisce un tipo di navigazione molto impegnativa, in quanto effettuata in prossimità della costa, ove sono presenti molto spesso punti cospicui sconosciti, che non risultano facilmente identificabili provenendo dal largo.",
        "progressivo": "1.7.6-5",
        "ris_1": "F",
        "id": 1206,
        "risposta_1": "la navigazione costiera costituisce un tipo di navigazione piuttosto facile considerato il suo sviluppo lungo la fascia costiera.",
        "ris_3": "F",
        "risposta_3": "la navigazione costiera costituisce un tipo di navigazione molto facile in quanto agevolata dalla presenza di numerosissimi punti cospicui presenti lungo la fascia costiera.",
        "ris_2": "V",
        "domanda": "Avuto riguardo alla navigazione costiera, quale tra queste affermazioni è corretta?",
        "immagine": ""
    },
    {
        "ris_2": "F",
        "immagine": "",
        "ris_3": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Elementi di navigazione costiera",
        "domanda": "Avuto riguardo alla navigazione costiera, quale tra queste affermazioni è corretta?",
        "ris_1": "V",
        "risposta_1": "il punto nave costiero risulta tanto più preciso quanto più affidabili sono gli strumenti utilizzati per la sua determinazione, nonché il metodo impiegato e l'esperienza marinaresca del navigante.",
        "risposta_3": "il punto nave ottenuto mediante la navigazione costiera è tanto più preciso quanto più veloce è l'unità navale.",
        "progressivo": "1.7.6-6",
        "id": 1207,
        "risposta_2": "navigazione costiera è subordinata alla conoscenza degli elementi del moto dell'unità."
    },
    {
        "immagine": "",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "F",
        "argomento": "Elementi di navigazione costiera",
        "risposta_2": "ben visibili e compresi entro un raggio visivo tra le otto e le dieci miglia nautiche dalla costa.",
        "ris_3": "F",
        "risposta_1": "ben visibili e distanti dalla batimentica dei 50 metri di oltre sei miglia nautiche dalla costa.",
        "progressivo": "1.7.6-7",
        "risposta_3": "ben visibili e compresi entro un raggio visivo tra i ventiquattro e i trentasei chilometri dalla costa.",
        "domanda": "I punti cospicui osservati dal navigante per determinare il punto nave costiero devono risultare:",
        "ris_2": "V",
        "id": 1208
    },
    {
        "immagine": "",
        "progressivo": "1.7.6-8",
        "risposta_3": "l'angolo compreso tra l'asse longitudinale dell'unità navale (prora) e il piano contenente la direzione del Nord bussola.",
        "risposta_1": "l'angolo compreso tra l'asse longitudinale dell'unità navale (prora) e il piano contenente la direzione del Nord vero.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "Avuto riguardo alla navigazione costiera, cosa si intende per rilevamento polare?",
        "ris_1": "F",
        "argomento": "Elementi di navigazione costiera",
        "risposta_2": "l'angolo compreso tra l'asse longitudinale dell'unità navale (prora) e il piano contenente la congiungente unità-oggetto osservato.",
        "ris_3": "F",
        "ris_2": "V",
        "id": 1209
    },
    {
        "progressivo": "1.1.2-46",
        "ris_3": "F",
        "id": 121,
        "risposta_3": "la posizione di equilibrio assunta nel piano verticale.",
        "risposta_1": "la posizione di equilibrio assunta nel piano longitudinale (prua - poppa).",
        "domanda": "Cosa si intende per assetto di un'unità navale?",
        "ris_1": "V",
        "capitolo": "TEORIA DELLO SCAFO",
        "immagine": "",
        "risposta_2": "la posizione di equilibrio assunta nel piano trasversale (dritta - sinistra).",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "ris_2": "F"
    },
    {
        "ris_3": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "progressivo": "1.7.6-9",
        "ris_1": "V",
        "domanda": "Avuto riguardo alla navigazione costiera, come varia angolarmente il rilevamento polare?",
        "immagine": "",
        "risposta_2": "da 090° a 270° gradi in senso orario a partire dall'asse longitudinale dell'unità a dritta.",
        "ris_2": "F",
        "risposta_3": "da 090° a 270° gradi in senso orario a partire dall'asse longitudinale dell'unità a sinistra.",
        "risposta_1": "da 000° a 360° gradi in senso orario a partire dall'asse longitudinale dell'unità.",
        "argomento": "Elementi di navigazione costiera",
        "id": 1210
    },
    {
        "ris_1": "F",
        "immagine": "",
        "risposta_3": "l'asse longitudinale dell'unità e la congiungente unità- oggetto osservato, contato da 000° a 180° verso dritta o sinistra dell'unità stessa.",
        "id": 1211,
        "progressivo": "1.7.6-10",
        "argomento": "Elementi di navigazione costiera",
        "ris_2": "F",
        "risposta_2": "il Nord vero e la congiungente unità-oggetto osservato, contato da 000° a 180° verso dritta o sinistra dell'unità stessa.",
        "ris_3": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "il Nord bussola e la congiungente unità-oggetto osservato, contato da 000° a 180° verso dritta o sinistra dell'unità stessa.",
        "domanda": "Il rilevamento polare semicircolare è rappresentato dall'angolo compreso tra:"
    },
    {
        "ris_1": "V",
        "risposta_3": "45°",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "progressivo": "1.7.6-11",
        "immagine": "",
        "id": 1212,
        "risposta_1": "0° oppure 180°.",
        "domanda": "L'allineamento rappresenta un particolare luogo di posizione che deriva dall'osservazione di due punti cospicui sulla costa la cui differenza di rilevamento risulta pari a:",
        "argomento": "Elementi di navigazione costiera",
        "risposta_2": "90°",
        "ris_2": "F",
        "ris_3": "F"
    },
    {
        "ris_2": "V",
        "domanda": "Avuto riguardo alla navigazione costiera, a cosa servono le squadrette nautiche nelle operazioni di carteggio?",
        "risposta_1": "alla misura dell'altezza dei punti cospicui della costa rispetto al livello medio del mare.",
        "ris_3": "F",
        "risposta_3": "alla misurazione del calcolo della minima distanza a cui un bersaglio passerà di poppa rispetto alla propria unità navale.",
        "immagine": "",
        "risposta_2": "al tracciamento degli angoli di rotta e dei rilevamenti.",
        "ris_1": "F",
        "id": 1213,
        "argomento": "Elementi di navigazione costiera",
        "progressivo": "1.7.6-12",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA"
    },
    {
        "ris_2": "F",
        "id": 1214,
        "risposta_3": "alla misurazione delle velocità dei bersagli individuati dal radar.",
        "progressivo": "1.7.6-13",
        "domanda": "Avuto riguardo alla navigazione costiera, a cosa serve principalmente il compasso nautico nelle operazioni di carteggio?",
        "risposta_2": "al tracciamento degli angoli di rotta e dei rilevamenti.",
        "risposta_1": "al calcolo delle distanze e all'individuazione delle coordinate geografiche.",
        "ris_1": "V",
        "ris_3": "F",
        "immagine": "",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Elementi di navigazione costiera"
    },
    {
        "id": 1215,
        "ris_2": "V",
        "risposta_3": "è il luogo di posizione rappresentato da una circonferenza lungo la quale tutti gli osservatori, ad intervalli temporali di 20 minuti l'uno dall'altro, misurano la stessa somma di rilevamento di due distinti punti cospicui sulla costa.",
        "risposta_2": "è il luogo di posizione rappresentato da una circonferenza lungo la quale tutti gli osservatori, nel medesimo istante, misurano la stessa differenza di rilevamento di due distinti punti cospicui sulla costa.",
        "immagine": "",
        "ris_3": "F",
        "risposta_1": "è il luogo di posizione rappresentato da una circonferenza lungo la quale tutti gli osservatori, ad intervalli temporali di 10 minuti l'uno dall'altro, misurano la stessa differenza di distanza di due distinti punti cospicui sulla costa.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "progressivo": "1.7.6-14",
        "ris_1": "F",
        "argomento": "Elementi di navigazione costiera",
        "domanda": "Avuto riguardo ai luoghi di posizione, cosa si intende per \"cerchio capace\"?"
    },
    {
        "id": 1216,
        "domanda": "Sono a Sud-Ovest del Faro della Meloria; significa che lo rilevo per:",
        "risposta_1": "225 gradi.",
        "ris_2": "V",
        "risposta_3": "135 gradi.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Elementi di navigazione costiera",
        "progressivo": "1.7.6-15",
        "ris_3": "F",
        "immagine": "",
        "ris_1": "F",
        "risposta_2": "045 gradi."
    },
    {
        "argomento": "Elementi di navigazione costiera",
        "risposta_1": "a Nord del faro, distanza 5 miglia.",
        "domanda": "Se con la mia unità sono a 5 miglia sul Rlv 180 gradi del Faro di Pianosa; significa che mi trovo:",
        "risposta_3": "non vi sono elementi sufficienti per dirlo.",
        "risposta_2": "a Sud del faro, distanza 5 miglia.",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "V",
        "immagine": "",
        "ris_3": "F",
        "progressivo": "1.7.6-16",
        "id": 1217
    },
    {
        "risposta_2": "coincidono solo se Pv e Rv coincidono.",
        "ris_1": "V",
        "immagine": "",
        "risposta_1": "coincidono sempre.",
        "argomento": "Elementi di navigazione costiera",
        "ris_3": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "progressivo": "1.7.6-17",
        "ris_2": "F",
        "risposta_3": "coincidono solo se Pv e Rv, Vp e Ve coincidono.",
        "domanda": "Rilevamento polare a 90 gradi e traverso:",
        "id": 1218
    },
    {
        "immagine": "",
        "argomento": "Elementi di navigazione costiera",
        "risposta_3": "lo si rileva polarmente per 180°.",
        "risposta_2": "il punto cospicuo è perpendicolare all'asse longitudinale dell'unità.",
        "ris_1": "F",
        "risposta_1": "il punto cospicuo è perpendicolare alla Rv che l'unità sta seguendo.",
        "domanda": "In presenza di scarroccio o deriva, accostare quando si è al traverso di un punto cospicuo significa accostare quando:",
        "id": 1219,
        "ris_2": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "progressivo": "1.7.6-18",
        "ris_3": "F"
    },
    {
        "id": 122,
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "risposta_3": "la rotazione che si manifesta lungo l'asse verticale, che determina la contestuale deviazione della prua da un lato e della poppa dal lato opposto.",
        "ris_1": "V",
        "ris_2": "F",
        "domanda": "Cosa si intende per rollio di un'unità navale?",
        "ris_3": "F",
        "immagine": "",
        "risposta_1": "la rotazione lungo l'asse longitudinale dell'unità, che determina l'inclinazione della stessa a dritta e a sinistra.",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_2": "la rotazione che si verifica lungo l'asse trasversale, che determina l'immersione della prua e il contestuale sollevamento della poppa e viceversa.",
        "progressivo": "1.1.2-47"
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "di due distanze del punto cospicuo.",
        "ris_2": "V",
        "immagine": "",
        "progressivo": "1.7.6-19",
        "ris_1": "F",
        "domanda": "Di cosa ho bisogno per determinare, in corso di navigazione, la posizione dell'unità rispetto ad un punto cospicuo?",
        "risposta_3": "di due rilevamenti contemporanei del punto cospicuo.",
        "ris_3": "F",
        "risposta_2": "di un rilevamento e di una distanza del punto cospicuo.",
        "id": 1220,
        "argomento": "Elementi di navigazione costiera"
    },
    {
        "id": 1221,
        "risposta_1": "315 gradi.",
        "ris_1": "V",
        "ris_2": "F",
        "domanda": "Sono a Sud-Est di un faro; significa che lo rilevo per:",
        "progressivo": "1.7.6-20",
        "ris_3": "F",
        "immagine": "",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "135 gradi.",
        "argomento": "Elementi di navigazione costiera",
        "risposta_3": "235 gradi."
    },
    {
        "ris_1": "F",
        "ris_2": "V",
        "domanda": "Se con la mia unità sono a 6 miglia sul Rlv Sud-Ovest del Faro della Meloria; significa che mi trovo:",
        "immagine": "",
        "risposta_3": "a Sud-Est del faro, distanza 6 miglia.",
        "id": 1222,
        "argomento": "Elementi di navigazione costiera",
        "ris_3": "F",
        "progressivo": "1.7.6-21",
        "risposta_1": "a Nord-Ovest del faro, distanza 6 miglia.",
        "risposta_2": "a Nord-Est del faro, distanza 6 miglia.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA"
    },
    {
        "risposta_3": "non vi sono elementi sufficienti per dirlo.",
        "ris_3": "F",
        "argomento": "Elementi di navigazione costiera",
        "id": 1223,
        "progressivo": "1.7.6-22",
        "domanda": "Se sono a Sud di un faro; significa che lo rilevo per:",
        "risposta_1": "180 gradi.",
        "risposta_2": "360 gradi.",
        "ris_2": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "ris_1": "F"
    },
    {
        "risposta_2": "è in funzione di elementi cospicui riconoscibili dal mare.",
        "ris_1": "F",
        "immagine": "",
        "argomento": "Elementi di navigazione costiera",
        "ris_3": "F",
        "ris_2": "V",
        "domanda": "La navigazione è \"costiera\" quando la determinazione del punto nave:",
        "id": 1224,
        "risposta_1": "è possibile se ci si allontana oltre 1 miglio dalla costa.",
        "progressivo": "1.7.6-23",
        "risposta_3": "se stimato, è in funzione della rotta seguita e delle miglia percorse in un dato intervallo di tempo.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA"
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_3": "l'angolo tra il piano verticale passante per il Nord e il piano verticale passante per l'oggetto rilevato, entrambi passanti per l'osservatore.",
        "ris_2": "F",
        "ris_3": "V",
        "ris_1": "F",
        "risposta_2": "l'angolo che un astro forma con il meridiano fondamentale.",
        "risposta_1": "l'avvistamento di un punto cospicuo della costa.",
        "immagine": "",
        "domanda": "Per angolo di rilevamento di un oggetto s'intende:",
        "progressivo": "1.7.6-24",
        "id": 1225,
        "argomento": "Elementi di navigazione costiera"
    },
    {
        "argomento": "Elementi di navigazione costiera",
        "progressivo": "1.7.6-25",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "F",
        "ris_1": "V",
        "risposta_1": "180 gradi.",
        "risposta_3": "non vi sono elementi sufficienti per dirlo.",
        "domanda": "Sono a Nord del faro; significa che lo rilevo per:",
        "ris_3": "F",
        "id": 1226,
        "risposta_2": "360 gradi.",
        "immagine": ""
    },
    {
        "argomento": "Elementi di navigazione costiera",
        "risposta_2": "a Nord-Est del faro.",
        "ris_1": "V",
        "ris_3": "F",
        "ris_2": "F",
        "progressivo": "1.7.6-26",
        "id": 1227,
        "risposta_1": "a Sud-Ovest del faro.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_3": "a Sud-Est del faro.",
        "domanda": "Sono sul Rlv 045 gradi del faro; significa che mi trovo:",
        "immagine": ""
    },
    {
        "immagine": "",
        "id": 1228,
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "F",
        "risposta_2": "a Nord-Est del faro.",
        "ris_1": "V",
        "progressivo": "1.7.6-27",
        "risposta_3": "a Sud-Est del faro.",
        "argomento": "Elementi di navigazione costiera",
        "risposta_1": "a Nord-Ovest del faro.",
        "ris_2": "F",
        "domanda": "Sono sul Rlv 135 gradi del faro; significa che mi trovo:"
    },
    {
        "id": 1229,
        "ris_3": "F",
        "risposta_3": "Sud-Est.",
        "progressivo": "1.7.6-28",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "Nord-Est.",
        "immagine": "",
        "domanda": "Sono sul Rlv 225 gradi del faro; significa che lo rilevo per:",
        "ris_1": "F",
        "ris_2": "V",
        "argomento": "Elementi di navigazione costiera",
        "risposta_2": "Sud-Ovest."
    },
    {
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "immagine": "",
        "ris_1": "F",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_3": "la rotazione che si manifesta lungo l'asse verticale, che determina la contestuale deviazione della prua da un lato e della poppa dal lato opposto.",
        "risposta_1": "la rotazione lungo l'asse longitudinale dell'unità navale, che determina l'inclinazione della stessa a dritta e a sinistra.",
        "ris_2": "V",
        "ris_3": "F",
        "progressivo": "1.1.2-48",
        "risposta_2": "la rotazione che si verifica lungo l'asse trasversale, che determina l'immersione della prua e il contestuale sollevamento della poppa e viceversa.",
        "domanda": "Cosa si intende per beccheggio di un'unità?",
        "id": 123
    },
    {
        "ris_2": "F",
        "progressivo": "1.7.6-29",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "l'oggetto rilevato si trova rispettivamente a dritta o a sinistra dell'osservatore rivolto verso prora.",
        "domanda": "Il rilevamento polare semicircolare si considera positivo o negativo se:",
        "id": 1230,
        "argomento": "Elementi di navigazione costiera",
        "ris_3": "F",
        "risposta_2": "l'oggetto è rispettivamente a sinistra o a dritta della prora della nave.",
        "immagine": "",
        "ris_1": "V",
        "risposta_3": "è rispettivamente maggiore o minore della rotta."
    },
    {
        "risposta_1": "non vi sono elementi sufficienti per dirlo.",
        "ris_3": "V",
        "domanda": "Se sono a Nord-Ovest di un faro; significa che lo rilevo per:",
        "ris_2": "F",
        "immagine": "",
        "id": 1231,
        "progressivo": "1.7.6-30",
        "ris_1": "F",
        "risposta_3": "135 gradi.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "315 gradi.",
        "argomento": "Elementi di navigazione costiera"
    },
    {
        "id": 1232,
        "risposta_2": "315 gradi.",
        "progressivo": "1.7.6-31",
        "ris_1": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "F",
        "risposta_3": "non vi sono elementi sufficienti per dirlo.",
        "risposta_1": "135 gradi.",
        "domanda": "Se sono a Sud-Est dello Scoglio Africa; significa che lo rilevo per:",
        "argomento": "Elementi di navigazione costiera",
        "ris_2": "V",
        "immagine": ""
    },
    {
        "risposta_1": "un allineamento.",
        "ris_2": "F",
        "ris_1": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "progressivo": "1.7.6-32",
        "ris_3": "F",
        "id": 1233,
        "risposta_3": "un cerchio di uguale distanza.",
        "argomento": "Elementi di navigazione costiera",
        "immagine": "",
        "domanda": "Notiamo due oggetti cospicui con uguale rilevamento o con rilevamenti distanziati tra loro di 180 gradi; si tratta di:",
        "risposta_2": "un incrocio."
    },
    {
        "argomento": "Elementi di navigazione costiera",
        "domanda": "Un rilevamento al traverso corrisponde ad un rilevamento polare di:",
        "progressivo": "1.7.6-33",
        "ris_2": "F",
        "risposta_2": "45 gradi.",
        "risposta_3": "120 gradi.",
        "ris_1": "V",
        "immagine": "",
        "risposta_1": "90 gradi.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "F",
        "id": 1234
    },
    {
        "risposta_3": "non vi sono elementi sufficienti per dirlo.",
        "argomento": "Elementi di navigazione costiera",
        "id": 1235,
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "F",
        "ris_1": "V",
        "progressivo": "1.7.6-34",
        "risposta_1": "a Est del faro.",
        "ris_2": "F",
        "risposta_2": "a Ovest del faro.",
        "immagine": "",
        "domanda": "Sono sul Rlv 270 gradi del faro; significa che sono:"
    },
    {
        "risposta_2": "lo staziografo.",
        "argomento": "Elementi di navigazione costiera",
        "id": 1236,
        "risposta_1": "il grafometro.",
        "progressivo": "1.7.6-35",
        "domanda": "Il rilevamento polare si misura con:",
        "immagine": "",
        "risposta_3": "la bussola di rotta.",
        "ris_1": "V",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "F"
    },
    {
        "id": 1237,
        "risposta_3": "270 gradi.",
        "ris_1": "F",
        "risposta_2": "90 gradi.",
        "ris_2": "F",
        "ris_3": "V",
        "argomento": "Elementi di navigazione costiera",
        "risposta_1": "180 gradi.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "Sono a Est del faro; significa che lo rilevo per:",
        "immagine": "",
        "progressivo": "1.7.6-36"
    },
    {
        "progressivo": "1.7.6-37",
        "risposta_1": "a Nord-Nord Ovest del faro.",
        "risposta_3": "a Sud-Sud Est del faro.",
        "immagine": "",
        "ris_1": "V",
        "risposta_2": "a Ovest-Nord Ovest del faro.",
        "id": 1238,
        "ris_3": "F",
        "ris_2": "F",
        "argomento": "Elementi di navigazione costiera",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "Sono sul Rlv 157,5 gradi del faro; significa che sono:"
    },
    {
        "ris_1": "F",
        "risposta_2": "egual profondità.",
        "immagine": "",
        "domanda": "Quale tra le risposte sotto elencate non è un luogo di posizione?",
        "id": 1239,
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_3": "rosa dei venti.",
        "argomento": "Elementi di navigazione costiera",
        "ris_2": "F",
        "risposta_1": "cerchio di egual differenza d'azimuth.",
        "ris_3": "V",
        "progressivo": "1.7.6-38"
    },
    {
        "ris_1": "F",
        "risposta_2": "la rotazione dell'unità lungo l'asse trasversale, che determina l'immersione della parte prodiera e il contestuale sollevamento della parte poppiera.",
        "risposta_3": "la rotazione dell'unità  lungo l'asse verticale, che determina la contestuale deviazione della prua da un lato e della poppa dal lato opposto.",
        "risposta_1": "la rotazione dell'unità lungo l'asse longitudinale dell'unità, che determina l'inclinazione della stessa in maniera alternata a dritta e a sinistra.",
        "ris_2": "F",
        "progressivo": "1.1.2-49",
        "id": 124,
        "ris_3": "V",
        "immagine": "",
        "capitolo": "TEORIA DELLO SCAFO",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "domanda": "Cosa si intende per accostata di un'unità?"
    },
    {
        "ris_3": "F",
        "domanda": "Un luogo di posizione:",
        "progressivo": "1.7.6-39",
        "risposta_3": "è un insieme di tutti i riferimenti facilmente individuabili sia sulla carta sia in mare durante la navigazione.",
        "risposta_2": "è la posizione in cui si trova l'unità in un dato preciso istante.",
        "ris_1": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "F",
        "argomento": "Elementi di navigazione costiera",
        "risposta_1": "è un insieme di punti che godono tutti di una determinata proprietà nello stesso istante e che tale proprietà deve essere misurabile.",
        "id": 1240,
        "immagine": ""
    },
    {
        "risposta_2": "a Sud-Sud Est del faro.",
        "progressivo": "1.7.6-40",
        "argomento": "Elementi di navigazione costiera",
        "risposta_3": "a Sud-Est del faro.",
        "immagine": "",
        "ris_1": "F",
        "ris_2": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "a Sud del faro.",
        "domanda": "Sono sul Rlv 337,5 gradi del faro; significa che sono:",
        "id": 1241,
        "ris_3": "F"
    },
    {
        "ris_2": "V",
        "risposta_3": "il mare calmo.",
        "risposta_2": "essere in vista della costa.",
        "ris_1": "F",
        "immagine": "",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "avvalersi esclusivamente del Portolano.",
        "argomento": "Elementi di navigazione costiera",
        "domanda": "Per la navigazione costiera è indispensabile:",
        "progressivo": "1.7.6-41",
        "id": 1242,
        "ris_3": "F"
    },
    {
        "ris_3": "F",
        "risposta_2": "a Sud-Sud Ovest del faro.",
        "argomento": "Elementi di navigazione costiera",
        "progressivo": "1.7.6-42",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "V",
        "immagine": "",
        "risposta_1": "a Sud-Ovest del faro.",
        "risposta_3": "a Sud del faro.",
        "ris_1": "F",
        "id": 1243,
        "domanda": "Sono sul Rlv 022,5 gradi del faro; significa che sono:"
    },
    {
        "domanda": "Sono sul Rlv 067,5 gradi del faro; significa che sono:",
        "immagine": "",
        "risposta_3": "a Ovest-Sud Ovest del faro.",
        "ris_1": "F",
        "id": 1244,
        "risposta_1": "a Ovest del faro.",
        "ris_2": "F",
        "progressivo": "1.7.6-43",
        "ris_3": "V",
        "argomento": "Elementi di navigazione costiera",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "a Sud-Ovest del faro."
    },
    {
        "risposta_3": "per 270 gradi.",
        "id": 1245,
        "risposta_1": "per 090 gradi.",
        "immagine": "",
        "domanda": "Navigo con Rv direzione Ovest, in assenza di vento e corrente, e vedo sulla prora un faro; significa che lo rilevo:",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Elementi di navigazione costiera",
        "ris_2": "F",
        "ris_3": "V",
        "risposta_2": "per 180 gradi.",
        "progressivo": "1.7.6-44",
        "ris_1": "F"
    },
    {
        "id": 1246,
        "risposta_2": "il cerchio capace.",
        "domanda": "E' un punto cospicuo:",
        "ris_1": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Elementi di navigazione costiera",
        "ris_3": "V",
        "risposta_1": "l'allineamento.",
        "risposta_3": "il campanile.",
        "progressivo": "1.7.6-45",
        "immagine": "",
        "ris_2": "F"
    },
    {
        "ris_2": "V",
        "argomento": "Elementi di navigazione costiera",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "no.",
        "ris_3": "F",
        "immagine": "",
        "risposta_1": "sì, solo se si tratta di un rilevamento.",
        "id": 1247,
        "domanda": "Si può determinare il punto nave con un solo luogo di posizione?",
        "ris_1": "F",
        "risposta_3": "sì.",
        "progressivo": "1.7.6-46"
    },
    {
        "risposta_3": "si.",
        "domanda": "Durante la navigazione si può determinare il punto nave con un solo punto cospicuo, se conosciuta la distanza da esso?",
        "risposta_1": "non è possibile.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1248,
        "argomento": "Elementi di navigazione costiera",
        "ris_1": "F",
        "ris_2": "F",
        "immagine": "",
        "progressivo": "1.7.6-47",
        "risposta_2": "si, purchè sia misurato il rilevamento due volte simultaneamente.",
        "ris_3": "V"
    },
    {
        "risposta_3": "sì, ho la certezza del punto nave quando rilevo l'allineamento.",
        "argomento": "Elementi di navigazione costiera",
        "ris_1": "V",
        "domanda": "Si può determinare il punto nave effettivo rilevando 2 torri allineate?",
        "risposta_1": "sì, ma ho bisogno di almeno un altro luogo di posizione.",
        "id": 1249,
        "risposta_2": "no, perchè non abbiamo il GPS.",
        "progressivo": "1.7.6-48",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "F",
        "ris_3": "F",
        "immagine": ""
    },
    {
        "id": 125,
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "progressivo": "1.1.2-50",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_2": "la stazza.",
        "immagine": "",
        "ris_1": "F",
        "risposta_1": "la portata.",
        "ris_3": "V",
        "risposta_3": "il dislocamento.",
        "ris_2": "F",
        "domanda": "Il peso della nave corrisponde a:"
    },
    {
        "risposta_2": "stima la posizione della nave ad un dato istante in quanto occorre un secondo luogo di posizione.",
        "argomento": "Elementi di navigazione costiera",
        "ris_2": "F",
        "id": 1250,
        "risposta_3": "determina la posizione con sufficiente precisione; è quindi affidabile per il prosieguo della navigazione.",
        "domanda": "Il punto nave ricavato con il metodo della navigazione \"costiera\":",
        "progressivo": "1.7.6-49",
        "immagine": "",
        "risposta_1": "considera lo scarroccio ma non la deriva.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "V",
        "ris_1": "F"
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "risposta_3": "quell'arco di cerchio massimo congiungente il punto di partenza \"A\" con il punto di arrivo \"B\".",
        "ris_3": "F",
        "ris_2": "V",
        "risposta_1": "la rotta della nave è l'angolo di prora che assume il timoniere rispetto al nord magnetico.",
        "progressivo": "1.7.7-1",
        "immagine": "",
        "domanda": "La rotta Rv è:",
        "ris_1": "F",
        "risposta_2": "il percorso reale che la nave ha effettuato o dovrà effettuare rispetto al fondo del mare.",
        "id": 1251
    },
    {
        "progressivo": "1.7.7-2",
        "risposta_2": "la chiglia dell'imbarcazione da diporto forma con la direzione Nord del meridiano geografico.",
        "id": 1252,
        "ris_1": "V",
        "ris_2": "F",
        "ris_3": "F",
        "risposta_1": "il percorso dell'imbarcazione da diporto in ciascun punto forma con la direzione Nord del meridiano geografico.",
        "domanda": "L'angolo di rotta Rv è l'angolo che:",
        "risposta_3": "il percorso dell'imbarcazione da diporto in ciascun punto forma con la direzione Sud del meridiano geografico.",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": ""
    },
    {
        "ris_1": "F",
        "id": 1253,
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "risposta_3": "tra 0 e 360 gradi in senso orario a partire da Nord.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "L'angolo di rotta può variare:",
        "progressivo": "1.7.7-3",
        "immagine": "",
        "risposta_2": "tra 0 e 180 gradi verso Est o verso Ovest a partire da Nord.",
        "ris_2": "F",
        "risposta_1": "tra 0 e 360 gradi in senso anti- orario a partire da Nord.",
        "ris_3": "V"
    },
    {
        "immagine": "",
        "risposta_3": "coincidente con la rotta Rv in presenza di vento o corrente.",
        "domanda": "La prora della nave è:",
        "ris_2": "V",
        "risposta_1": "la direzione che sta seguendo la nave rispetto al fondale.",
        "id": 1254,
        "risposta_2": "la direzione verso la quale la linea di chiglia della nave è orientata rispetto al nord.",
        "ris_3": "F",
        "progressivo": "1.7.7-4",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "F",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente"
    },
    {
        "progressivo": "1.7.7-5",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "risposta_3": "180 gradi.",
        "id": 1255,
        "risposta_1": "360 gradi.",
        "ris_2": "F",
        "risposta_2": "90 gradi.",
        "immagine": "",
        "ris_1": "F",
        "domanda": "Due rotte opposte hanno una differenza angolare di:",
        "ris_3": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA"
    },
    {
        "ris_2": "F",
        "risposta_3": "sì, solo la latitudine.",
        "id": 1256,
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "F",
        "ris_1": "V",
        "risposta_2": "sì, solo la deviazione geografica.",
        "domanda": "Navigando con Rv 090 gradi cambia qualche coordinata geografica?",
        "risposta_1": "sì, solo la longitudine.",
        "immagine": "",
        "progressivo": "1.7.7-6"
    },
    {
        "risposta_1": "sì, solo la deviazione geografica.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "progressivo": "1.7.7-7",
        "immagine": "",
        "risposta_3": "sì, solo la longitudine.",
        "ris_3": "F",
        "ris_1": "F",
        "ris_2": "V",
        "risposta_2": "sì, solo la latitudine.",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "domanda": "Navigando con Rv 180 gradi cambia qualche coordinata geografica?",
        "id": 1257
    },
    {
        "id": 1258,
        "ris_1": "V",
        "risposta_1": "prora vera dell'unità e si misura in senso orario.",
        "risposta_2": "prora magnetica dell'unità e si misura in senso antiorario.",
        "progressivo": "1.7.7-8",
        "risposta_3": "prora bussola dell'unità e si misura in senso antiorario.",
        "domanda": "L’angolo di prora vera è compreso tra il nord vero e la:",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "F",
        "ris_3": "F",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "immagine": ""
    },
    {
        "risposta_1": "sulla rosa dei venti delle carte nautiche.",
        "risposta_3": "in corrispondenza della linea di fede della bussola.",
        "ris_3": "F",
        "progressivo": "1.7.7-9",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "non si può leggere, non conoscendo l’angolo di deriva e/o di scarroccio.",
        "immagine": "",
        "id": 1259,
        "ris_1": "V",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "domanda": "L’angolo di prora vera si legge:"
    },
    {
        "id": 126,
        "ris_2": "F",
        "ris_3": "V",
        "domanda": "Di norma il motore diesel viene spento:",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "progressivo": "1.2.1-1",
        "ris_1": "F",
        "capitolo": "MOTORI",
        "immagine": "",
        "risposta_2": "mettendo a massa la bobina.",
        "risposta_3": "impedendo al carburante di affluire alla pompa di iniezione.",
        "risposta_1": "lasciando esaurire la benzina nel serbatoio."
    },
    {
        "risposta_2": "dovuta alle azioni di propulsori e corrente sulla nave.",
        "id": 1260,
        "ris_3": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "F",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "immagine": "",
        "risposta_1": "misurata dal solcometro.",
        "progressivo": "1.7.7-10",
        "domanda": "La velocità effettiva (Ve) è quella velocità:",
        "ris_2": "F",
        "risposta_3": "dovuta alle azioni sulla nave di propulsori, vento e corrente."
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_3": "dalle azioni combinate dei propulsori-eliche e del vento.",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "immagine": "",
        "ris_2": "V",
        "progressivo": "1.7.7-11",
        "risposta_2": "dalla sola azione dei propulsori-eliche.",
        "domanda": "Il moto proprio o propulsivo di una nave a motore è generato:",
        "id": 1261,
        "ris_3": "F",
        "risposta_1": "dalle azioni combinate dei propulsori-eliche, del vento e della corrente.",
        "ris_1": "F"
    },
    {
        "ris_1": "F",
        "risposta_1": "deviato il percorso dell'unità a causa dell'azione della corrente.",
        "domanda": "L'angolo di scarroccio è quell'angolo sotto il quale è:",
        "ris_3": "V",
        "progressivo": "1.7.7-12",
        "id": 1262,
        "risposta_2": "deviato il percorso dell'unità per il vento e la corrente.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "F",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "immagine": "",
        "risposta_3": "deviato il percorso dell'unità a causa dell'azione del vento."
    },
    {
        "immagine": "",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "domanda": "Il moto effettivo è definito dai seguenti termini:",
        "risposta_1": "Rv (angolo di rotta vera) e Ve (velocità effettiva).",
        "ris_3": "F",
        "risposta_2": "Rsup (angolo di rotta di superficie) e Vsup (velocità di superficie).",
        "ris_2": "F",
        "progressivo": "1.7.7-13",
        "risposta_3": "Pv (angolo di prora vera) e Vp (velocità propria o propulsiva).",
        "ris_1": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1263
    },
    {
        "risposta_2": "deviato il percorso dell'unità a causa dell'azione della corrente.",
        "ris_3": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "F",
        "id": 1264,
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "ris_2": "V",
        "progressivo": "1.7.7-14",
        "risposta_1": "deviato il percorso dell'unità a causa dell'azione del vento.",
        "risposta_3": "deviato il percorso dell'unità per effetto del vento e della corrente.",
        "domanda": "L'angolo di deriva è quell'angolo sotto il quale è:",
        "immagine": ""
    },
    {
        "risposta_1": "unicamente dal suo propulsore-elica.",
        "ris_2": "F",
        "risposta_2": "dal suo propulsore-elica e dalla corrente.",
        "domanda": "La velocità propria o propulsiva (Vp) è quella velocità impressa all'unità a motore:",
        "immagine": "",
        "progressivo": "1.7.7-15",
        "ris_1": "V",
        "risposta_3": "dal suo propulsore-elica e dal vento.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "F",
        "id": 1265,
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente"
    },
    {
        "risposta_3": "è indifferente e non dipende dal tipo di scafo o esposizione dell'opera morta.",
        "ris_3": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1266,
        "risposta_2": "maggiore quando lo scafo naviga molto immerso",
        "ris_2": "F",
        "ris_1": "F",
        "domanda": "A parità di corrente, la deriva come influisce sui vari tipi di scafi?",
        "progressivo": "1.7.7-16",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "immagine": "",
        "risposta_1": "maggiore quando lo scafo ha un'alta opera morta."
    },
    {
        "immagine": "",
        "risposta_1": "reale rispetto al fondo marino.",
        "ris_1": "V",
        "domanda": "La velocità effettiva (Ve) altro non è che la velocità:",
        "ris_2": "F",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "risposta_2": "di scarroccio e deriva.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "progressivo": "1.7.7-17",
        "id": 1267,
        "risposta_3": "della superficie dell'acqua.",
        "ris_3": "F"
    },
    {
        "risposta_3": "lo spostamento laterale che avviene rispettivamente a dritta o a sinistra rispetto alla prora della nave.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "V",
        "risposta_1": "lo scarroccio avente lo stesso segno della deviazione ovvero avente segno opposto.",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "progressivo": "1.7.7-18",
        "domanda": "Eccetto i casi teorici di scarroccio di prora ovvero poppa, lo scarroccio positivo o negativo è:",
        "immagine": "",
        "id": 1268,
        "ris_1": "F",
        "risposta_2": "lo scarroccio E o W.",
        "ris_2": "F"
    },
    {
        "ris_1": "F",
        "progressivo": "1.7.7-19",
        "risposta_3": "tanto è maggiore quanto è maggiore l'opera viva e quanto è maggiore la superficie esposta al vento.",
        "risposta_2": "tanto è maggiore quanto è minore l'opera viva e quanto è maggiore la superficie esposta al vento.",
        "domanda": "A parità di azione del vento, la velocità di scarroccio:",
        "ris_2": "V",
        "risposta_1": "con lo stesso vento è eguale per tutte le navi anche se diverse tra loro.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "ris_3": "F",
        "id": 1269
    },
    {
        "immagine": "",
        "ris_3": "F",
        "domanda": "Prima di avviare un motore entrobordo a benzina, qual è la prima operazione da compiere?",
        "ris_1": "F",
        "ris_2": "V",
        "risposta_3": "verificare che le candele siano ben inserite.",
        "risposta_2": "far aerare il vano motore.",
        "capitolo": "MOTORI",
        "progressivo": "1.2.1-2",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "id": 127,
        "risposta_1": "aprire i rubinetti del circuito di raffreddamento a ciclo chiuso."
    },
    {
        "risposta_3": "perché il bordo libero della nave e le tughe deviano il moto del vento.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1270,
        "risposta_2": "perché a quello reale si somma, vettorialmente, il vento dovuto al moto della nave.",
        "immagine": "",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "risposta_1": "è solo apparenza, in realtà i due venti hanno uguale provenienza.",
        "progressivo": "1.7.7-20",
        "domanda": "Come mai in navigazione il vento appare diverso da quello reale?",
        "ris_2": "V",
        "ris_3": "F",
        "ris_1": "F"
    },
    {
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "progressivo": "1.7.7-21",
        "domanda": "Lo scarroccio influisce:",
        "risposta_1": "solo sulle unità a motore.",
        "ris_2": "F",
        "id": 1271,
        "risposta_2": "solo sulle unità a vela.",
        "immagine": "",
        "ris_3": "V",
        "risposta_3": "su tutte le unità.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "F"
    },
    {
        "risposta_2": "il vento soffia verso nord (Ostro), la corrente, al contrario, va verso sud.",
        "risposta_3": "che entrambi provengono da nord e vanno verso sud.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "F",
        "immagine": "",
        "ris_3": "F",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "id": 1272,
        "ris_2": "V",
        "risposta_1": "il vento soffia verso sud (Tramontana), la corrente, al contrario, va verso nord.",
        "progressivo": "1.7.7-22",
        "domanda": "Vento 180 gradi e corrente 180 gradi; cosa significa?"
    },
    {
        "ris_3": "V",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "ris_1": "F",
        "immagine": "",
        "progressivo": "1.7.7-23",
        "domanda": "Lo scarroccio:",
        "risposta_2": "quanto minore è l'opera viva tanto minore sarà la superficie esposta al vento.",
        "risposta_1": "con lo stesso vento, la velocità di scarroccio è uguale per tutte le navi anche se diverse tra loro.",
        "id": 1273,
        "risposta_3": "dipende dall'intensità del vento, dalla velocità dell'unità, dalla superficie esposta al vento e dal tipo di carena.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "F"
    },
    {
        "progressivo": "1.7.7-24",
        "risposta_3": "lo scarroccio è il movimento dovuto al sistema di governo.",
        "id": 1274,
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "domanda": "Tra i possibili moti sull’acqua che la nave può compiere o subire, si può affermare che:",
        "ris_3": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "V",
        "risposta_2": "la velocità di deriva dipende dalla forma della carena.",
        "ris_2": "F",
        "immagine": "",
        "risposta_1": "la deriva è il movimento dovuto alla presenza di correnti."
    },
    {
        "risposta_2": "la somma degli effetti prodotti dal vento sul moto dell'unità.",
        "risposta_1": "l’effetto perturbatore prodotto dalla corrente marina sul moto dell’unità.",
        "ris_1": "V",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1275,
        "ris_3": "F",
        "immagine": "",
        "risposta_3": "l’effetto perturbatore prodotto dal vento sull'opera morta dell'unità.",
        "progressivo": "1.7.7-25",
        "domanda": "La deriva è:"
    },
    {
        "risposta_1": "all'azione del vento.",
        "domanda": "Lo scarroccio è dovuto:",
        "risposta_3": "all'effetto della corrente.",
        "risposta_2": "all'azione combinata di vento e corrente.",
        "id": 1276,
        "immagine": "",
        "progressivo": "1.7.7-26",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "ris_1": "V",
        "ris_3": "F",
        "ris_2": "F"
    },
    {
        "ris_1": "F",
        "risposta_1": "all'azione combinata di vento e corrente.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_3": "all'azione del vento.",
        "ris_3": "F",
        "id": 1277,
        "risposta_2": "all'effetto della corrente.",
        "progressivo": "1.7.7-27",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "ris_2": "V",
        "immagine": "",
        "domanda": "La deriva è dovuta:"
    },
    {
        "risposta_1": "si ha un effetto sulla velocità della nave ma non sulla direzione del suo percorso.",
        "domanda": "Con vento, di poppa, la cui direzione coincide con la direzione del moto della nave:",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "V",
        "id": 1278,
        "progressivo": "1.7.7-28",
        "risposta_2": "si ha un effetto sulla velocità della nave ed anche sulla direzione del suo percorso.",
        "immagine": "",
        "ris_3": "F",
        "risposta_3": "si ha un effetto non sulla velocità della nave ma sulla direzione del suo percorso.",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente"
    },
    {
        "risposta_2": "il moto della nave è agevolato dallo scarroccio mentre è contrastato dalla deriva.",
        "ris_3": "F",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "progressivo": "1.7.7-29",
        "id": 1279,
        "immagine": "",
        "risposta_1": "il moto della nave è agevolato dalla deriva mentre è contrastato dallo scarroccio.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "V",
        "domanda": "Siamo in navigazione con rotta Nord in presenza di un vento e corrente entrambi 180; succede che:",
        "ris_1": "F",
        "risposta_3": "il moto della nave è agevolato dalla deriva e dallo scarroccio."
    },
    {
        "immagine": "",
        "risposta_3": "le esalazioni di vapori di benzina dal tubo di scarico.",
        "progressivo": "1.2.1-3",
        "capitolo": "MOTORI",
        "risposta_2": "la minore volatilità della benzina rispetto al gasolio.",
        "ris_1": "V",
        "ris_3": "F",
        "id": 128,
        "ris_2": "F",
        "risposta_1": "l'accumulo di vapori di benzina nel vano motore.",
        "domanda": "Il principale problema, in termini di sicurezza, di un motore a benzina è:",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore"
    },
    {
        "risposta_1": "si, sempre.",
        "id": 1280,
        "risposta_3": "si, ma solo se provengono esattamente da prora o da poppa.",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "F",
        "argomento": "Prora e rotta, scarroccio e deriva per effetto del vento e della corrente",
        "progressivo": "1.7.7-30",
        "immagine": "",
        "domanda": "L’angolo di rotta vera e l’angolo di prora vera possono coincidere in presenza di deriva e/o scarroccio?",
        "ris_3": "V",
        "risposta_2": "no, mai."
    },
    {
        "ris_2": "F",
        "progressivo": "1.7.8-1",
        "id": 1281,
        "argomento": "Pubblicazioni",
        "immagine": "",
        "ris_3": "F",
        "risposta_2": "nel listino dei servizi di bordo (I.I. 2721).",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "Dov'è riportato l'elenco di tutte le pubblicazioni nautiche edite dall'Istituto Idrografico della Marina Militare?",
        "risposta_3": "nell'elenco dei Navarea del Mediterraneo (I.I. 2127).",
        "ris_1": "V",
        "risposta_1": "nel catalogo delle carte e delle pubblicazioni nautiche (I.I. 3001)."
    },
    {
        "risposta_2": "un adeguamento delle pubblicazioni alle modifiche che intervengono.",
        "domanda": "Per \"aggiornamento\" delle pubblicazioni nautiche s'intende:",
        "ris_1": "F",
        "ris_2": "V",
        "argomento": "Pubblicazioni",
        "id": 1282,
        "progressivo": "1.7.8-2",
        "immagine": "",
        "risposta_1": "la modifica di pagine e cartine nell'elenco dei fari e fanali.",
        "ris_3": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_3": "solamente la segnalazione di nuove edizioni."
    },
    {
        "ris_3": "V",
        "ris_2": "F",
        "ris_1": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 1283,
        "risposta_1": "è riprodotta a seguito di esaurimento scorte.",
        "risposta_3": "è una nuova tiratura dell'edizione in vigore di una carta sulla quale non è stata incorporata alcuna modifica importante fatta eccezione di quelle derivanti da eventuali Avvisi ai Naviganti (AA.NN.) emessi nel tempo.",
        "argomento": "Pubblicazioni",
        "domanda": "La \"ristampa\" di una carta nautica edita dell'I.I.M.M.:",
        "progressivo": "1.7.8-3",
        "immagine": "",
        "risposta_2": "annulla l'edizione in vigore."
    },
    {
        "ris_1": "F",
        "progressivo": "1.7.8-4",
        "domanda": "Gli Avvisi ai Naviganti (AA.NN.):",
        "risposta_3": "forniscono dati sulla ricettività portuale.",
        "ris_2": "V",
        "risposta_2": "hanno lo scopo di aggiornare le carte nautiche.",
        "argomento": "Pubblicazioni",
        "risposta_1": "possono essere richiesti via telefono.",
        "id": 1284,
        "ris_3": "F",
        "immagine": "",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA"
    },
    {
        "immagine": "",
        "ris_1": "F",
        "risposta_1": "in caso di vento di Ponente- Maestro è sconsigliato entrare in quel porto.",
        "id": 1285,
        "ris_3": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Pubblicazioni",
        "risposta_2": "non ci sono particolari problemi per entrare in porto con venti provenienti dal secondo quadrante.",
        "ris_2": "F",
        "domanda": "Se il Portolano avverte che per un determinato porto i venti di traversìa sono quelli del secondo quadrante, quali considerazioni faremo?",
        "risposta_3": "il porto è poco protetto in caso di Levante, Scirocco e Ostro.",
        "progressivo": "1.7.8-5"
    },
    {
        "ris_1": "V",
        "ris_2": "F",
        "id": 1286,
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "una pubblicazione che riporta ubicazione, descrizione e caratteristiche dei\nsegnali luminosi e sonori delle coste del Mediterraneo",
        "immagine": "",
        "argomento": "Pubblicazioni",
        "risposta_3": "un fascicolo periodico contenente dati, inserti e pagine sostitutive per l'aggiornamento delle carte e pubblicazioni nautiche.",
        "domanda": "L'Elenco dei Fari e Segnali da Nebbia è:",
        "ris_3": "F",
        "risposta_2": "un documento che fornisce notizie al navigante come descrizione della costa, pericoli, aspetto dei fari, fanali, servizi portuali, boe.",
        "progressivo": "1.7.8-6"
    },
    {
        "progressivo": "1.7.8-7",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "F",
        "domanda": "Il Portolano:",
        "risposta_1": "è il fascicolo periodico contenente dati, inserti e pagine sostitutive per l'aggiornamento delle carte e pubblicazioni nautiche.",
        "argomento": "Pubblicazioni",
        "ris_2": "F",
        "risposta_3": "fornisce notizie necessarie alla navigazione costiera come descrizione della costa, pericoli, aspetto dei fari, fanali, servizi portuali, boe.",
        "ris_3": "V",
        "risposta_2": "riporta ubicazione, descrizione e caratteristiche dei segnali luminosi e sonori delle coste del Mediterraneo.",
        "id": 1287,
        "immagine": ""
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "I documenti nautici sono:",
        "argomento": "Pubblicazioni",
        "ris_2": "V",
        "risposta_2": "l'insieme delle carte e delle pubblicazioni nautiche necessarie per la condotta della navigazione.",
        "ris_1": "F",
        "ris_3": "F",
        "risposta_3": "costituiti esclusivamente dalle carte nautiche.",
        "id": 1288,
        "risposta_1": "i documenti personali e tecnici dell'unità che lo Skipper deve avere con sé come la patente nautica e il certificato di sicurezza.",
        "progressivo": "1.7.8-8",
        "immagine": ""
    },
    {
        "ris_1": "F",
        "risposta_2": "entro tre giorni dall'arrivo in porto.",
        "immagine": "",
        "progressivo": "1.8.1-1",
        "ris_3": "F",
        "ris_2": "V",
        "risposta_3": "entro 24 ore dall'arrivo in porto.",
        "risposta_1": "entro e non oltre il giorno successivo all'arrivo in porto.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "domanda": "Quando  il comandante di un'unità navale è tenuto a presentare la denuncia di evento straordinario all'Autorità Marittima?",
        "id": 1289,
        "argomento": "Leggi e regolamenti"
    },
    {
        "ris_2": "V",
        "risposta_3": "la percentuale di aria nella miscela bassa.",
        "domanda": "Cosa può determinare il danneggiamento della girante del circuito di raffreddamento di un motore fuoribordo?",
        "capitolo": "MOTORI",
        "ris_3": "F",
        "risposta_1": "la miscela troppo ricca.",
        "immagine": "",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "progressivo": "1.2.1-4",
        "id": 129,
        "risposta_2": "il funzionamento del fuoribordo quando la sua presa d'acqua si trova al di fuori del livello dell'acqua.",
        "ris_1": "F"
    },
    {
        "risposta_3": "l'Autorità Consolare.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "immagine": "",
        "argomento": "Leggi e regolamenti",
        "risposta_2": "l'Autorità di Polizia più vicina al porto di approdo.",
        "progressivo": "1.8.1-2",
        "domanda": "Ai sensi del  Codice della Nautica da diporto, all'estero qual è l'Autorità a cui va presentata la denuncia di evento straordinario?",
        "id": 1290,
        "risposta_1": "non sussiste obbligo di denuncia.",
        "ris_1": "F",
        "ris_2": "F",
        "ris_3": "V"
    },
    {
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "argomento": "Leggi e regolamenti",
        "id": 1291,
        "risposta_1": "all'armatore, se presente a bordo.",
        "domanda": "A chi spetta in modo esclusivo la direzione della manovra e della navigazione di un'unità?",
        "ris_2": "V",
        "ris_1": "F",
        "immagine": "",
        "progressivo": "1.8.1-3",
        "ris_3": "F",
        "risposta_3": "a chi possiede la maggior esperienza marinaresca.",
        "risposta_2": "al comandante dell'unità."
    },
    {
        "id": 1292,
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_2": "è punito con la sanzione amministrativa del pagamento di 206,00 Euro.",
        "domanda": "Il comandante dell'unità navale che in caso di urto non dia nei limiti del possibile alle altre unità le notizie necessarie per l'identificazione della propria imbarcazione:",
        "risposta_1": "è punito con la revoca della patente nautica.",
        "risposta_3": "è punito con la sanzione amministrativa del pagamento di una somma da 1.032,00 Euro a 6.197,00\nEuro.",
        "argomento": "Leggi e regolamenti",
        "ris_1": "F",
        "ris_2": "F",
        "progressivo": "1.8.1-4",
        "immagine": "",
        "ris_3": "V"
    },
    {
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_3": "F",
        "risposta_2": "la dichiarazione che il comandante dell'unità è tenuto a presentare al Comandante del porto o all'Autorità consolare del porto di scalo quando, durante il viaggio si siano avvistati cetacei di particolari dimensioni.",
        "risposta_1": "la dichiarazione che il comandante dell'unità è tenuto a presentare al Comandante del porto o all'Autorità consolare del porto di scalo quando, durante il viaggio, si siano verificati eventi straordinari relativi all'unità. navale o alle persone  presenti a bordo.",
        "ris_1": "V",
        "progressivo": "1.8.1-5",
        "argomento": "Leggi e regolamenti",
        "risposta_3": "la dichiarazione che il comandante dell'unità  è tenuto a presentare al Comandante del porto o all'Autorità consolare del porto di scalo quando durante il viaggio siano state incontrate perturbazioni meteorologiche di fortissima entità.",
        "immagine": "",
        "domanda": "Ai sensi del Codice della Navigazione, cosa si intende per denuncia di avvenimenti straordinari?",
        "ris_2": "F",
        "id": 1293
    },
    {
        "progressivo": "1.8.1-6",
        "risposta_2": "l'assunzione del comando o della condotta ovvero la direzione nautica di un'unità da diporto senza la prescritta abilitazione, perché non conseguita o revocata o non convalidata per mancanza dei requisiti, ovvero sospesa o ritirata.",
        "id": 1294,
        "ris_2": "V",
        "risposta_3": "omettere di cooperare con i mezzi di cui si dispone, al soccorso di un'altra unità navale.",
        "immagine": "",
        "ris_3": "F",
        "ris_1": "F",
        "risposta_1": "la direzione dell'armamento delle vele prodiere senza la prevista abilitazione.",
        "domanda": "Ai sensi del Codice della nautica da diporto, quale tra questi comportamenti costituisce un illecito amministrativo?",
        "argomento": "Leggi e regolamenti",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE"
    },
    {
        "risposta_3": "è tenuto a prestarle assistenza solo se trovasi a non più di 12 miglia nautiche dall'unità in pericolo.",
        "ris_2": "V",
        "risposta_1": "è tenuto a prestarle assistenza solo se trovasi a non più di 10 miglia nautiche dall'unità in pericolo.",
        "ris_1": "F",
        "domanda": "Il comandante di una unità, che durante la navigazione venga a conoscenza del pericolo in cui versa un'altra unità.",
        "id": 1295,
        "progressivo": "1.8.1-7",
        "risposta_2": "è tenuto ad accorrere per prestare assistenza, quando possa prevedere un utile risultato.",
        "argomento": "Leggi e regolamenti",
        "ris_3": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "immagine": ""
    },
    {
        "ris_1": "F",
        "argomento": "Leggi e regolamenti",
        "ris_3": "V",
        "risposta_3": "il comandante di ciascuna è tenuto a prestare soccorso alle altre, al loro equipaggio ed ai loro passeggeri, qualora ciò non comporti grave pericolo per la sua unità navale e per le persone che sono a bordo.",
        "id": 1296,
        "progressivo": "1.8.1-8",
        "domanda": "Avvenuto un urto tra unità navali:",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_2": "F",
        "risposta_2": "il comandante dell'unità navale più grande, è tenuto a prestare soccorso a tutte le altre, al loro equipaggio.",
        "immagine": "",
        "risposta_1": "il comandante di ciascuna, è tenuto a prestare soccorso esclusivamente a quella più vicina, al suo equipaggio."
    },
    {
        "domanda": "Il comandante dell'unità soccorritrice è tenuto a tentare il salvataggio di persone che siano in mare o in acque interne in pericolo:",
        "progressivo": "1.8.1-9",
        "ris_1": "F",
        "ris_2": "V",
        "risposta_1": "qualora l'altezza del bordo libero non ecceda i 2 metri.",
        "id": 1297,
        "immagine": "",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "argomento": "Leggi e regolamenti",
        "ris_3": "F",
        "risposta_2": "qualora ciò non comporti grave pericolo per la sua unità navale e per le persone che sono a bordo.",
        "risposta_3": "qualora sia in possesso di ulteriori mezzi collettivi di salvataggio."
    },
    {
        "ris_2": "F",
        "risposta_1": "è punito con la reclusione fino a 2 anni.",
        "domanda": "Il comandante di un'unità navale che omette di prestare assistenza ovvero tentare il salvataggio nei casi in cui ne ha l'obbligo a norma del Codice della Navigazione:",
        "progressivo": "1.8.1-10",
        "ris_1": "V",
        "risposta_3": "è punito con il ritiro della patente nautica.",
        "id": 1298,
        "ris_3": "F",
        "argomento": "Leggi e regolamenti",
        "immagine": "",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_2": "è punito con la sanzione pecuniaria di euro 10.000."
    },
    {
        "argomento": "Leggi e regolamenti",
        "risposta_1": "non ottemperare agli ordini impartiti dalla competente Autorità marittima, ai sensi dell'art. 70 Codice della Navigazione, omettendo di cooperare con i mezzi di cui si dispone, al soccorso di un'altra unità navale.",
        "ris_2": "V",
        "progressivo": "1.8.1-11",
        "domanda": "Quale tra queste condotte costituisce un illecito amministrativo?",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_2": "salvo che il fatto costituisca violazione della normativa sulle aree marine protette, l'utilizzo di un'unità da diporto non osservando una disposizione di legge o di regolamento in materia di sicurezza della navigazione.",
        "risposta_3": "tentare il salvataggio nei confronti di un'altra unità navale in pericolo di perdersi qualora non comporti grave rischio per l'unità soccorritrice.",
        "immagine": "",
        "ris_1": "F",
        "ris_3": "F",
        "id": 1299
    },
    {
        "ris_3": "F",
        "ris_2": "F",
        "capitolo": "TEORIA DELLO SCAFO",
        "progressivo": "1.1.1-13",
        "domanda": "Gli elementi che suddividono lo scafo in senso trasversale, sono:",
        "risposta_3": "i boccaporti.",
        "risposta_1": "le paratie.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "immagine": "",
        "risposta_2": "gli osteriggi.",
        "ris_1": "V",
        "id": 13
    },
    {
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "domanda": "Un impianto di aerazione forzata nel vano che alloggia il motore entrobordo diesel di un'unità da diporto:",
        "ris_2": "F",
        "risposta_3": "non è obbligatorio, ma è consigliato.",
        "ris_3": "V",
        "id": 130,
        "risposta_2": "è obbligatorio.",
        "progressivo": "1.2.1-5",
        "risposta_1": "è sconsigliato.",
        "ris_1": "F",
        "immagine": "",
        "capitolo": "MOTORI"
    },
    {
        "ris_1": "F",
        "immagine": "",
        "ris_2": "V",
        "risposta_2": "dall'altezza significativa delle onde e dalla forza del vento.",
        "progressivo": "1.8.1-12",
        "argomento": "Leggi e regolamenti",
        "domanda": "I limiti di navigazione delle unità munite di marcatura \"CE\" sono definiti:",
        "ris_3": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_3": "dalla vetustà dello scafo.",
        "risposta_1": "dal Registro Italiano Navale",
        "id": 1300
    },
    {
        "risposta_3": "gli uffici locali marittimi.",
        "ris_1": "V",
        "argomento": "Leggi e regolamenti",
        "domanda": "Un'imbarcazione da diporto può essere immatricolata presso:",
        "immagine": "",
        "risposta_2": "gli uffici della Provincia.",
        "progressivo": "1.8.1-13",
        "risposta_1": "l'Archivio telematico delle unità da diporto (ATCN).",
        "id": 1301,
        "ris_2": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_3": "F"
    },
    {
        "id": 1302,
        "ris_1": "V",
        "risposta_1": "segnare il limite interno da cui si misura la fascia di mare territoriale.",
        "risposta_3": "delimitare tutte quelle aree del mare assoggettate alle specifiche  regolamentazioni marittime.",
        "ris_3": "F",
        "ris_2": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "immagine": "",
        "risposta_2": "delimitare aree di mare in cui è vietata la navigazione o un'attività.",
        "progressivo": "1.8.1-14",
        "domanda": "Le linee di base hanno la funzione di:",
        "argomento": "Leggi e regolamenti"
    },
    {
        "domanda": "La bandiera nazionale per le unità da diporto iscritte nei registri:",
        "risposta_1": "è esposta nella posizione più visibile, più opportuna.",
        "risposta_2": "in porto si deve esporre sempre, dall'alba al tramonto.",
        "progressivo": "1.8.1-15",
        "argomento": "Leggi e regolamenti",
        "ris_3": "F",
        "risposta_3": "in navigazione fuori dai porti, è sempre esposta dall'alba al tramonto.",
        "ris_2": "F",
        "immagine": "",
        "ris_1": "V",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "id": 1303
    },
    {
        "immagine": "",
        "ris_1": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "progressivo": "1.8.1-16",
        "ris_2": "F",
        "ris_3": "V",
        "argomento": "Leggi e regolamenti",
        "risposta_1": "si segnala il ritrovamento telefonando al numero 115.",
        "domanda": "In caso di ritrovamento in spiaggia di natanti, motori marini, ecc.:",
        "risposta_3": "si presentata apposita denuncia all'Autorità marittima locale.",
        "risposta_2": "si presenta denuncia alla stazione dei Carabinieri.",
        "id": 1304
    },
    {
        "domanda": "Per le imbarcazioni da diporto, il nome:",
        "risposta_2": "non è obbligatorio.",
        "id": 1305,
        "immagine": "",
        "ris_1": "F",
        "risposta_1": "è imposto, qualora non vi provveda il proprietario entro un certo termine fissato dall'Autorità marittima.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "progressivo": "1.8.1-17",
        "argomento": "Leggi e regolamenti",
        "ris_3": "F",
        "ris_2": "V",
        "risposta_3": "è obbligatorio."
    },
    {
        "domanda": "Nella navigazione in prossimità delle rade ci dobbiamo attenere:",
        "risposta_1": "al codice internazionale di navigazione.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "argomento": "Leggi e regolamenti",
        "id": 1306,
        "ris_2": "F",
        "ris_1": "F",
        "ris_3": "V",
        "risposta_2": "alle norme delle effemeridi.",
        "progressivo": "1.8.1-18",
        "immagine": "",
        "risposta_3": "alle ordinanze dell'Autorità Marittima."
    },
    {
        "id": 1307,
        "ris_2": "F",
        "argomento": "Leggi e regolamenti",
        "immagine": "",
        "risposta_3": "prescrizioni che regolamentano la navigazione marittima in ambito locale.",
        "ris_3": "V",
        "progressivo": "1.8.1-19",
        "domanda": "Le ordinanze sono delle:",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_1": "prescrizioni indicate sulla licenza di navigazione o sul certificato d’uso motore.",
        "ris_1": "F",
        "risposta_2": "strutture portanti trasversali della nave in legno."
    },
    {
        "id": 1308,
        "domanda": "Cosa si intende per navigazione interna?",
        "argomento": "Leggi e regolamenti",
        "ris_1": "F",
        "risposta_1": "quella effettuata tra la costa e le linee di base.",
        "risposta_2": "quella effettuata su laghi di confine.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "immagine": "",
        "ris_2": "F",
        "risposta_3": "quella effettuata sui laghi, fiumi, canali e altre acque interne.",
        "progressivo": "1.8.1-20",
        "ris_3": "V"
    },
    {
        "immagine": "",
        "risposta_2": "no, solo quelle superiori a 10 metri di lunghezza fuori tutto.",
        "progressivo": "1.8.1-21",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "domanda": "Si può immatricolare un’unità avente lunghezza fuori tutto di 9,90 metri?",
        "argomento": "Leggi e regolamenti",
        "risposta_1": "sì, ma subisce il regime giuridico delle imbarcazioni da diporto iscritte.",
        "ris_2": "F",
        "risposta_3": "no, non può essere immatricolato.",
        "ris_1": "V",
        "id": 1309,
        "ris_3": "F"
    },
    {
        "ris_2": "F",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "capitolo": "MOTORI",
        "risposta_1": "depressione, alimentazione, iniezione, espansione.",
        "progressivo": "1.2.1-6",
        "risposta_3": "aspirazione, compressione, scoppio, scarico.",
        "ris_1": "F",
        "risposta_2": "attiva e passiva.",
        "immagine": "",
        "id": 131,
        "domanda": "Quali sono le fasi di un motore a combustione interna a quattro tempi?",
        "ris_3": "V"
    },
    {
        "risposta_3": "Ministero dello Sviluppo Economico per il tramite dello STED (Sportello Telematico del Diportista).",
        "risposta_1": "Ministero delle Attività Produttive.",
        "ris_3": "V",
        "ris_2": "F",
        "progressivo": "1.8.1-22",
        "immagine": "",
        "risposta_2": "Ministero dello Sviluppo Economico.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_1": "F",
        "id": 1310,
        "domanda": "Dove va richiesta la licenza RTF per il VHF installato a bordo delle unità da diporto?",
        "argomento": "Leggi e regolamenti"
    },
    {
        "risposta_1": "sì, sempre.",
        "domanda": "Alle imbarcazioni spetta l'obbligo di esporre la bandiera nazionale?",
        "risposta_2": "si, solo in acque territoriali.",
        "ris_2": "F",
        "immagine": "",
        "ris_3": "F",
        "id": 1311,
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "argomento": "Leggi e regolamenti",
        "progressivo": "1.8.1-23",
        "risposta_3": "no.",
        "ris_1": "V"
    },
    {
        "ris_1": "V",
        "immagine": "",
        "risposta_2": "presso il registro ACI della propria città di residenza.",
        "id": 1312,
        "ris_2": "F",
        "domanda": "Un soggetto residente a Genova, dove può immatricolare la propria imbarcazione da diporto?",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_3": "F",
        "risposta_3": "presso il consolato con sede a Genova.",
        "argomento": "Leggi e regolamenti",
        "risposta_1": "presso un qualsiasi STED (Sportello Telematico del Diportista).",
        "progressivo": "1.8.1-24"
    },
    {
        "risposta_3": "qualsiasi costruzione di qualunque tipo e con qualunque mezzo di propulsione, che sia destinata alla navigazione da diporto, che può essere di natura \"lusoria\" (privata) oppure \"commerciale\".",
        "ris_1": "F",
        "risposta_1": "soltanto le unità destinate alla navigazione da diporto \"lusoria\" (uso privato).",
        "immagine": "",
        "ris_2": "F",
        "progressivo": "1.8.1-25",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_2": "soltanto le unità a motore destinate alla navigazione da diporto.",
        "ris_3": "V",
        "id": 1313,
        "domanda": "Per \"unità da diporto\" s'intende:",
        "argomento": "Leggi e regolamenti"
    },
    {
        "progressivo": "1.8.1-26",
        "argomento": "Leggi e regolamenti",
        "risposta_2": "codice alfanumerico composto da 4 lettere e 4 numeri seguiti dalla lettera D.",
        "immagine": "",
        "ris_2": "V",
        "ris_3": "F",
        "ris_1": "F",
        "risposta_1": "il nome della barca seguito dalla lettera D.",
        "id": 1314,
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "domanda": "Un'imbarcazione da diporto registrata nell'ATCN (Archivio Telematico Centrale della Nautica da diporto), ha la sigla così composta:",
        "risposta_3": "sigla provincia + N + numero iscrizione."
    },
    {
        "risposta_2": "quella utilizzata per il servizio di pilotaggio e rimorchio.",
        "ris_1": "F",
        "id": 1315,
        "ris_2": "F",
        "risposta_1": "quella utilizzata per la pesca.",
        "risposta_3": "quella utilizzata per le attività previste dal codice della nautica, tra cui locazione e noleggio.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "immagine": "",
        "domanda": "Per unità da diporto adibita a uso commerciale si intende:",
        "progressivo": "1.8.1-27",
        "argomento": "Leggi e regolamenti",
        "ris_3": "V"
    },
    {
        "domanda": "Per unità da diporto adibita a uso commerciale si intende:",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "argomento": "Leggi e regolamenti",
        "risposta_3": "quella utilizzata per la pesca.",
        "ris_1": "F",
        "immagine": "",
        "id": 1316,
        "risposta_2": "quella utilizzata per le attività previste dal codice della nautica, tra cui l'assistenza alle attività subacquee e l'insegnamento professionale.",
        "ris_3": "F",
        "ris_2": "V",
        "progressivo": "1.8.1-28",
        "risposta_1": "quella utilizzata per l'attività di collegamento di  linea a orari prestabiliti  tra due o più località predefinite."
    },
    {
        "domanda": "Le disposizioni del Codice della nautica:",
        "argomento": "Leggi e regolamenti",
        "ris_3": "F",
        "id": 1317,
        "risposta_3": "si applicano alle unità da diporto e della piccola pesca.",
        "progressivo": "1.8.1-29",
        "ris_2": "V",
        "ris_1": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_2": "si applicano sia alla navigazione da diporto esercitata per fini lusori (ricreativi), sia per fini commerciali, come definiti dal codice stesso.",
        "risposta_1": "si applicano alla navigazione da diporto esercitata per fini esclusivamente lusori (ricreativi).",
        "immagine": ""
    },
    {
        "id": 1318,
        "risposta_3": "le disposizioni della Marina Militare.",
        "immagine": "",
        "argomento": "Leggi e regolamenti",
        "progressivo": "1.8.1-30",
        "domanda": "In mancanza di specifiche disposizioni, alla navigazione delle unità da diporto si applicano:",
        "risposta_1": "le disposizioni del Codice della navigazione.",
        "ris_3": "F",
        "risposta_2": "dalle disposizioni del Codice civile.",
        "ris_2": "F",
        "ris_1": "V",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE"
    },
    {
        "ris_3": "V",
        "risposta_1": "a qualsiasi unità galleggiante",
        "ris_2": "F",
        "ris_1": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "id": 1319,
        "risposta_3": "a tutte le unità da diporto come definite dal codice della nautica,con esclusione delle unità a remi e a vela non dotate di motore ausiliario.",
        "risposta_2": "a tutte le unità da diporto come definite dal codice della nautica.",
        "domanda": "Le disposizioni sull'obbligo di assicurazione per responsabilità civile (decreto legislativo 7 settembre 2005, n. 209, e successive modificazioni) si applicano:",
        "argomento": "Leggi e regolamenti",
        "progressivo": "1.8.1-31",
        "immagine": ""
    },
    {
        "capitolo": "MOTORI",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "ris_3": "F",
        "domanda": "Ho necessità di invertire la rotazione dell'elica: è necessario invertire la rotazione del motore?",
        "id": 132,
        "immagine": "",
        "progressivo": "1.2.1-7",
        "ris_1": "V",
        "risposta_2": "sì; azionando l'apposita leva avviene automaticamente l'inversione del senso di rotazione del motore.",
        "risposta_1": "no; l'inversione della rotazione dell'elica si ottiene azionando l'apposita leva del sistema riduttore/invertitore.",
        "risposta_3": "solo nei motori non muniti del sistema riduttore/invertitore occorre invertire il senso di rotazione del motore.",
        "ris_2": "F"
    },
    {
        "risposta_2": "sempre.",
        "ris_2": "V",
        "immagine": "",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "progressivo": "1.8.1-32",
        "domanda": "Un motore fuoribordo è soggetto all'obbligo di assicurazione (decreto legislativo 7 settembre 2005, n. 209, e successive modificazioni):",
        "ris_3": "F",
        "risposta_1": "solo per le potenze superiori a 2,5 cavalli.",
        "argomento": "Leggi e regolamenti",
        "id": 1320,
        "risposta_3": "solo per le potenze che richiedono la patente nautica.",
        "ris_1": "F"
    },
    {
        "ris_3": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_3": "si applicano ai motori muniti di certificato di uso straniero o di altro documento equivalente emesso all’estero, solo se impiegati nelle acque territoriali nazionali oltre 3 miglia dalla costa.",
        "progressivo": "1.8.1-33",
        "risposta_2": "non si applicano ai motori muniti di certificato di uso straniero o di altro documento equivalente emesso all’estero.",
        "domanda": "Le disposizioni sull'obbligo di assicurazione per responsabilità civile (decreto legislativo 7 settembre 2005, n. 209, e successive modificazioni):",
        "id": 1321,
        "argomento": "Leggi e regolamenti",
        "ris_2": "F",
        "immagine": "",
        "risposta_1": "si applicano anche ai motori muniti di certificato di uso straniero o di altro documento equivalente emesso all’estero, se impiegati nelle acque territoriali nazionali.",
        "ris_1": "V"
    },
    {
        "risposta_1": "dal Regolamento di attuazione al codice della nautica.",
        "immagine": "",
        "domanda": "Le disposizioni della disciplina della navigazione delle unità da diporto contenute nel codice della nautica sono completate:",
        "risposta_2": "dal Codice civile.",
        "id": 1322,
        "progressivo": "1.8.1-34",
        "ris_1": "V",
        "ris_3": "F",
        "argomento": "Leggi e regolamenti",
        "risposta_3": "dalle disposizioni speciali.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_2": "F"
    },
    {
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_3": "F",
        "ris_1": "F",
        "argomento": "Leggi e regolamenti",
        "risposta_2": "le disposizioni del Codice della navigazione.",
        "progressivo": "1.8.1-35",
        "id": 1323,
        "risposta_1": "le disposizioni speciali.",
        "immagine": "",
        "risposta_3": "le diposizioni del'Autorità prefettizia.",
        "ris_2": "V",
        "domanda": "Per quanto non previsto dalle disposizioni del Codice della nautica, si applicano:"
    },
    {
        "risposta_2": "è consentita con riferimento ai soli natanti, previa comunicazione alla Capitaneria di porto competente.",
        "progressivo": "1.8.1-36",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "id": 1324,
        "ris_1": "V",
        "ris_2": "F",
        "risposta_1": "è consentita con riferimento a imbarcazioni e natanti, previa sottoscrizione di una polizza assicurativa e comunicazione alla Capitaneria di porto competente.",
        "argomento": "Leggi e regolamenti",
        "ris_3": "F",
        "risposta_3": "non è consentita.",
        "immagine": "",
        "domanda": "L'attività di assistenza e traino:"
    },
    {
        "immagine": "",
        "argomento": "Leggi e regolamenti",
        "risposta_3": "è soggetto a diffida.",
        "progressivo": "1.8.1-37",
        "ris_3": "F",
        "risposta_1": "è soggetto alla sanzione da 2.775 a 11.017 euro.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "id": 1325,
        "domanda": "L'esercizio abusivo delle attività commerciali con unità da diporto:",
        "ris_2": "F",
        "risposta_2": "è soggetto alla sanzione da 276 a 1.377 euro.",
        "ris_1": "V"
    },
    {
        "immagine": "",
        "argomento": "Leggi e regolamenti",
        "ris_3": "F",
        "id": 1326,
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_2": "F",
        "domanda": "Con contratto di leasing nautico si intende:",
        "ris_1": "V",
        "progressivo": "1.8.1.38",
        "risposta_1": "il finanziamento posto in essere da una banca o intermediario finanziario consistente nella concessione in utilizzo, per un determinato periodo di tempo e dietro il pagamento di un corrispettivo periodico (canone), di una unità da diporto.",
        "risposta_3": "il leasing nautico non è applicato alla nautica.",
        "risposta_2": "la locazione di una unità da diporto, senza diritto di riscatto."
    },
    {
        "ris_3": "F",
        "id": 1327,
        "ris_1": "V",
        "risposta_1": "ancorché non proprietario dell'imbarcazione, assume tutti i rischi relativi al perimento del bene.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_3": "non è mai responsabile del perimento del bene.",
        "progressivo": "1.8.1-39",
        "argomento": "Leggi e regolamenti",
        "domanda": "L’utilizzatore di un contratto di leasing nautico:",
        "ris_2": "F",
        "immagine": "",
        "risposta_2": "poiché non è il proprietario dell'imbarcazione, non si assume  i rischi relativi al perimento del bene, a meno che non derivi da una sua grave imperizia."
    },
    {
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "argomento": "Leggi e regolamenti",
        "ris_3": "F",
        "id": 1328,
        "risposta_2": "il proprietario dell'unità (soggetto finanziatore del leasing) è tenuto al pagamento.",
        "risposta_3": "le sanzioni sono suddivise tra il proprietario dell'unità e l'utilizzatore in leasing.",
        "risposta_1": "l'utilizzatore a titolo di leasing dell'unità è obbligato in solido con l'autore delle violazioni, se persona diversa.",
        "domanda": "In caso di violazioni da parte di una unità in leasing di disposizioni in materia di navigazione che prevedono sanzioni pecuniarie:",
        "ris_2": "F",
        "progressivo": "1.8.1-40",
        "immagine": "",
        "ris_1": "V"
    },
    {
        "progressivo": "1.8.1-41",
        "ris_1": "F",
        "id": 1329,
        "domanda": "L'esercizio abusivo di attività commerciali con unità da diporto è punito:",
        "risposta_1": "con la sanzione amministrativa da 250 euro a\n1.100 euro.",
        "ris_2": "V",
        "risposta_3": "con la sanzione amministrativa da 557 euro a 2.507 euro.",
        "risposta_2": "con la sanzione amministrativa da 2.755 euro a 11.017 euro.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_3": "F",
        "immagine": "",
        "argomento": "Leggi e regolamenti"
    },
    {
        "risposta_2": "la pompa di iniezione si è rotta.",
        "risposta_3": "si è sporcato il filtro dell'olio.",
        "capitolo": "MOTORI",
        "domanda": "In generale, la causa più comune in base alla quale un motore entrobordo si surriscalda avviene quando:",
        "ris_3": "F",
        "ris_2": "F",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "id": 133,
        "immagine": "",
        "ris_1": "V",
        "progressivo": "1.2.1-8",
        "risposta_1": "la presa a mare della pompa dell'acqua si è occlusa."
    },
    {
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "immagine": "",
        "argomento": "Leggi e regolamenti",
        "progressivo": "1.8.1-42",
        "ris_3": "F",
        "domanda": "In caso di leasing nautico:",
        "risposta_2": "l'utilizzatore assume in toto la responsabilità del comando.",
        "risposta_3": "la responsabilità del comando è stabilita dal contratto di leasing.",
        "ris_1": "F",
        "risposta_1": "l'utilizzatore condivide la responsabilità del comando con il proprietario.",
        "ris_2": "V",
        "id": 1330
    },
    {
        "risposta_3": "è punito con la sanzione amministrativa del pagamento di una somma da 1.032,00 Euro a 6.197,00\nEuro.",
        "progressivo": "1.8.1-43",
        "ris_2": "F",
        "risposta_1": "è punito con la reclusione da uno a due mesi.",
        "risposta_2": "è punito con il pagamento di un'ammenda fino a 206,00 Euro.",
        "ris_1": "F",
        "ris_3": "V",
        "domanda": "Il comandante dell'unità navale che in caso di urto non dia nei limiti del possibile alle altre unità le notizie necessarie per l'identificazione della propria imbarcazione:",
        "argomento": "Comandante, conduttore, utilizzatore",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "id": 1331,
        "immagine": ""
    },
    {
        "ris_1": "V",
        "argomento": "Comandante, conduttore, utilizzatore",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "domanda": "Con riguardo alla locazione delle unità da diporto, quale tra queste affermazioni è corretta?",
        "immagine": "",
        "risposta_1": "il conduttore di un'unità da diporto locata, ne esercita la navigazione assumendosi le responsabilità ed i rischi.",
        "ris_2": "F",
        "progressivo": "1.8.1-44",
        "ris_3": "F",
        "risposta_2": "il conduttore di un'unità da diporto locata, ne esercita la navigazione senza assumersene le responsabilità e i rischi che restano in capo al proprietario.",
        "risposta_3": "il conduttore di un'unità da diporto locata, ne esercita la navigazione senza assumersene le responsabilità e i rischi che restano in capo al noleggiatore.",
        "id": 1332
    },
    {
        "domanda": "Quali sono gli obblighi a cui deve adempiere il conduttore di un'unità navale da diporto locata?",
        "ris_3": "V",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "immagine": "",
        "ris_1": "F",
        "ris_2": "F",
        "risposta_1": "impiegare l'unità navale da diporto secondo le caratteristiche tecniche risultanti dal certificato di stabilità e in conformità alle finalità di diporto.",
        "argomento": "Comandante, conduttore, utilizzatore",
        "progressivo": "1.8.1-45",
        "risposta_2": "impiegare l'unità da diporto secondo le caratteristiche tecniche risultanti dal certificato di classe e in conformità alle finalità di diporto.",
        "id": 1333,
        "risposta_3": "impiegare l'unità da diporto secondo le caratteristiche tecniche risultanti dalla licenza di navigazione e in conformità alle finalità di diporto."
    },
    {
        "ris_3": "F",
        "ris_2": "F",
        "id": 1334,
        "ris_1": "V",
        "argomento": "Attvità commerciale",
        "immagine": "",
        "risposta_1": "deve essere tenuto a bordo in originale o copia conforme.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "domanda": "Il contratto di locazione delle imbarcazioni e delle navi da diporto:",
        "risposta_3": "può anche essere tenuto a bordo in copia fotostatica.",
        "progressivo": "1.8.1-46",
        "risposta_2": "va rinnovato in concomitanza della scadenza delle manutenzioni periodiche dell'apparato motore."
    },
    {
        "domanda": "La licenza di navigazione e gli altri documenti prescritti sono mantenuti a bordo:",
        "progressivo": "1.8.1-47",
        "id": 1335,
        "risposta_2": "esclusivamente in originale solo se la navigazione avviene tra porti di uno stesso compartimento marittimo.",
        "risposta_3": "dopo esser stati vidimati annualmente da parte della competente Autorità marittima.",
        "ris_3": "F",
        "ris_1": "V",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "immagine": "",
        "risposta_1": "in originale, o in copia autentica se la navigazione avviene tra porti dello Stato.",
        "argomento": "Documenti",
        "ris_2": "F"
    },
    {
        "ris_2": "F",
        "domanda": "Il certificato di sicurezza:",
        "id": 1336,
        "progressivo": "1.8.1-48",
        "ris_1": "F",
        "argomento": "Documenti",
        "risposta_2": "attesta lo stato di stabilità dell'unità e fa parte dei documenti di bordo.",
        "risposta_1": "deve essere registrato presso l'Agenzia delle Dogane entro 30 giorni dal rilascio.",
        "immagine": "",
        "risposta_3": "attesta lo stato di navigabilità dell'unità e fa parte dei documenti di bordo.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_3": "V"
    },
    {
        "progressivo": "1.8.1-49",
        "id": 1337,
        "risposta_1": "è facoltativa.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "argomento": "Documenti",
        "ris_3": "V",
        "immagine": "",
        "ris_1": "F",
        "ris_2": "F",
        "domanda": "La dichiarazione di potenza del motore installato a bordo:",
        "risposta_3": "fa parte dei documenti di bordo di natanti e imbarcazioni con fuoribordo.",
        "risposta_2": "è inclusa nel Manuale del proprietario."
    },
    {
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_1": "vale come documento di bordo e abilita alla navigazione nei limiti consentiti dalle caratteristiche di costruzione dell'unità da diporto, nonché alla navigazione in acque territoriali straniere per il periodo di tempo necessario alla partecipazione a fiere, saloni e altri eventi espositivi.",
        "domanda": "L'atto di autorizzazione alla navigazione temporanea",
        "risposta_2": "vale come documento di bordo straordinario ed abilita alla navigazione senza alcun limite dalla costa, nonché in navigazione in acque internazionali.",
        "ris_3": "F",
        "ris_1": "V",
        "argomento": "Documenti",
        "progressivo": "1.8.1-50",
        "id": 1338,
        "ris_2": "F",
        "risposta_3": "non vale come documento di bordo ed abilita esclusivamente alla navigazione da e per il cantiere di costruzione e/o manutenzione.",
        "immagine": ""
    },
    {
        "ris_2": "F",
        "risposta_2": "il contratto è redatto per iscritto a pena di nullità ed è conservato in originale presso l'Ufficio di iscrizione.",
        "ris_3": "F",
        "risposta_3": "il contratto  è redatto per iscritto a pena di nullità ed è conservato in originale presso la Direzione Marittima di giurisdizione ove è iscrittà l'unità.",
        "progressivo": "1.8.1-51",
        "id": 1339,
        "domanda": "Con riguardo al noleggio delle unità da diporto, quale tra queste affermazioni è corretta?",
        "argomento": "Attività commerciale",
        "risposta_1": "il contratto è redatto per iscritto a pena di nullità e deve essere tenuto a bordo in originale o copia conforme.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_1": "V",
        "immagine": ""
    },
    {
        "immagine": "",
        "id": 134,
        "risposta_1": "esterno allo scafo con organi di trasmissione interni.",
        "ris_2": "V",
        "domanda": "Un motore \"entrofuoribordo\" è un motore:",
        "risposta_3": "fuoribordo, che ha un apposito pozzetto interno per alloggiare la testa.",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "risposta_2": "entrobordo con gli organi di trasmissione riuniti in un piede fuoribordo applicato alla poppa.",
        "ris_1": "F",
        "ris_3": "F",
        "capitolo": "MOTORI",
        "progressivo": "1.2.1-9"
    },
    {
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "progressivo": "1.8.1-52",
        "risposta_3": "dall'omologazione CE.",
        "risposta_1": "dalla licenza di navigazione.",
        "ris_1": "V",
        "risposta_2": "dall'iscrizione nel registro delle imprese.",
        "domanda": "L'utilizzo di un'imbarcazione da diporto per mezzo di contratti di locazione ovvero di noleggio risulta:",
        "ris_2": "F",
        "id": 1340,
        "ris_3": "F",
        "immagine": "",
        "argomento": "Attività commerciale"
    },
    {
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "id": 1341,
        "ris_1": "V",
        "argomento": "Leggi e regolamenti",
        "domanda": "A che distanza dalla costa possono navigare le unità a remi (pedalò, sandolini ecc.) ?",
        "immagine": "",
        "risposta_1": "entro 1 miglio dalla costa.",
        "risposta_3": "entro 6 miglia.",
        "ris_3": "F",
        "progressivo": "1.8.1-53",
        "risposta_2": "in base alle condimeteomarine individuate con ordinanza dell'Autorità marittima.",
        "ris_2": "F"
    },
    {
        "domanda": "Al compimento dei 16 anni di età:",
        "ris_3": "V",
        "risposta_3": "si può assumere il comando e la condotta di natanti a motore e natanti a vela con motore ausiliario e motovelieri, purchè non sia prescritto il possesso della patente nautica.",
        "progressivo": "1.8.1-54",
        "immagine": "",
        "id": 1342,
        "ris_1": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_2": "si può essere ammessi all'esame per il rilascio della patente nautica anche senza limiti dalla costa.",
        "argomento": "Leggi e regolamenti",
        "ris_2": "F",
        "risposta_1": "si può essere ammessi esclusivamente all'esame per il rilascio della patente nautica entro 12 miglia dalla costa."
    },
    {
        "ris_2": "F",
        "domanda": "La patente nautica può essere sospesa:",
        "progressivo": "1.8.1-55",
        "risposta_2": "a seguito di denuncia di evento ordinario.",
        "immagine": "",
        "risposta_3": "per mancanza a bordo delle dotazioni di sicurezza.",
        "argomento": "Leggi e regolamenti",
        "id": 1343,
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_1": "V",
        "risposta_1": "per assunzione del comando in stato di ebbrezza (ubriachezza) o sotto effetto di stupefacenti (droghe).",
        "ris_3": "F"
    },
    {
        "ris_3": "F",
        "risposta_3": "lunghezza, larghezza e altezza di costruzione.",
        "immagine": "",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_2": "F",
        "id": 1344,
        "risposta_1": "lunghezza fuori tutto.",
        "domanda": "Le unità da diporto sono classificate in base alla:",
        "ris_1": "V",
        "argomento": "Leggi e regolamenti",
        "risposta_2": "potenza dell'apparato motore, stazza e lunghezza fuori  tutto.",
        "progressivo": "1.8.1-56"
    },
    {
        "ris_1": "V",
        "id": 1345,
        "risposta_2": "imbarcazione da diporto.",
        "risposta_1": "natante da diporto.",
        "domanda": "Un'unità a motore lunga 9 metri è classificata:",
        "progressivo": "1.8.1-57",
        "ris_2": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "immagine": "",
        "risposta_3": "nave da diporto.",
        "ris_3": "F",
        "argomento": "Leggi e regolamenti"
    },
    {
        "ris_2": "F",
        "argomento": "Leggi e regolamenti",
        "domanda": "Il ritrovamento di un relitto va denunciato all'Autorità competente entro:",
        "progressivo": "1.8.1-58",
        "ris_3": "F",
        "risposta_1": "3 giorni dal ritrovamento o dall'approdo.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_1": "V",
        "risposta_3": "24 ore dal ritrovamento o dall'approdo.",
        "risposta_2": "7 giorni dal ritrovamento o dall'approdo.",
        "immagine": "",
        "id": 1346
    },
    {
        "risposta_2": "dai laghi e dai fiumi dello Stato.",
        "immagine": "",
        "ris_1": "F",
        "risposta_1": "dalle acque marittime riservate alla balneazione.",
        "argomento": "Leggi e regolamenti",
        "ris_2": "F",
        "ris_3": "V",
        "progressivo": "1.8.1-59",
        "domanda": "Le acque interne marittime sono costituite:",
        "risposta_3": "dalle acque marittime comprese tra la costa e la linea di base.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "id": 1347
    },
    {
        "progressivo": "1.8.1-60",
        "risposta_3": "no.",
        "risposta_2": "si, solo in acque territoriali.",
        "id": 1348,
        "ris_1": "F",
        "ris_2": "F",
        "domanda": "Ai natanti spetta l'obbligo di esporre la bandiera nazionale?",
        "ris_3": "V",
        "immagine": "",
        "risposta_1": "sì, sempre.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "argomento": "Leggi e regolamenti"
    },
    {
        "ris_2": "V",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "progressivo": "1.8.1-61",
        "domanda": "I limiti di navigazione delle unità munite di marcatura \"CE\" sono definiti:",
        "immagine": "",
        "risposta_2": "dall'altezza significativa delle onde e dalla forza del vento.",
        "argomento": "Leggi e regolamenti",
        "risposta_1": "dall'omologazione dell'organo tecnico.",
        "ris_3": "F",
        "ris_1": "F",
        "id": 1349,
        "risposta_3": "dalla distanza dalla costa."
    },
    {
        "risposta_3": "svuotare tutto il gasolio presente nel sistema prima di riempire il serbatoio.",
        "ris_2": "V",
        "risposta_2": "eliminare tutta l'aria presente nel circuito di alimentazione carburante prima di riaccendere il motore.",
        "risposta_1": "pulire i filtri del gasolio.",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "domanda": "Con il termine \"spurgare\", riferito a un sistema d'alimentazione di un motore diesel, s'intende:",
        "ris_1": "F",
        "immagine": "",
        "progressivo": "1.2.1-10",
        "capitolo": "MOTORI",
        "id": 135,
        "ris_3": "F"
    },
    {
        "ris_1": "F",
        "immagine": "",
        "ris_3": "F",
        "ris_2": "V",
        "id": 1350,
        "progressivo": "1.8.1-62",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_3": "sì.",
        "argomento": "Leggi e regolamenti",
        "risposta_1": "dipende dalle violazioni per le quali è stato dichiarato delinquente abituale.",
        "domanda": "Può conseguire la patente nautica un \"delinquente abituale\"?",
        "risposta_2": "no."
    },
    {
        "id": 1351,
        "ris_2": "F",
        "argomento": "Leggi e regolamenti",
        "immagine": "",
        "domanda": "La bandiera nazionale deve essere esposta:",
        "risposta_3": "soltanto dalle navi e dalle imbarcazioni da diporto se abilitate alla navigazione oltre le 12 miglia di distanza dalla costa.",
        "progressivo": "1.8.1-63",
        "risposta_1": "dalle navi e dalle imbarcazioni da diporto.",
        "ris_3": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_1": "V",
        "risposta_2": "tutte le unità da diporto."
    },
    {
        "risposta_1": "si, se la categoria di progettazione consente di intraprendere la navigazione necessaria per raggiungere la destinazione estera.",
        "id": 1352,
        "argomento": "Leggi e regolamenti",
        "risposta_2": "sì, sempre.",
        "immagine": "",
        "ris_3": "F",
        "domanda": "Un'imbarcazione da diporto marcata \"CE\", può recarsi all'estero?",
        "progressivo": "1.8.1-64",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_3": "sì, ma solo in presenza di mare e vento assicurati.",
        "ris_2": "F",
        "ris_1": "V"
    },
    {
        "ris_3": "V",
        "argomento": "Leggi e regolamenti",
        "ris_2": "F",
        "immagine": "",
        "risposta_2": "navigazione, si esegue una variazione di rotta non programmata.",
        "domanda": "La denuncia di evento straordinario è presentata se in corso di:",
        "id": 1353,
        "progressivo": "1.8.1-65",
        "risposta_3": "navigazione, si siano verificati eventi anomali relativi all'unità o alle persone a bordo.",
        "ris_1": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_1": "navigazione, le persone a bordo hanno corso un pericolo."
    },
    {
        "argomento": "Documenti",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_1": "F",
        "immagine": "",
        "domanda": "La licenza di navigazione è valida:",
        "risposta_2": "5 anni.",
        "id": 1354,
        "progressivo": "1.8.1-66",
        "risposta_3": "sino a che l'unità da diporto non subisca modifiche agli elementi strutturali o di identificazione della stessa ovvero importanti innovazioni.",
        "risposta_1": "3 anni.",
        "ris_2": "F",
        "ris_3": "V"
    },
    {
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "id": 1355,
        "ris_2": "V",
        "immagine": "",
        "ris_1": "F",
        "risposta_3": "quando la potenza del motore supera i 25 Cv, fermo restando la cilindrata, i tempi del motore ed il carburante impiegato.",
        "argomento": "Leggi e regolamenti",
        "domanda": "L'obbligo della patente nautica per condurre un natante da diporto sorge:",
        "progressivo": "1.8.1-67",
        "risposta_2": "quando la potenza del motore supera i 40,8 Cv, fermo restando la cilindrata, i tempi del motore ed il carburante impiegato.",
        "ris_3": "F",
        "risposta_1": "mai, perché non è necessaria la patente nautica per i natanti da diporto."
    },
    {
        "ris_2": "F",
        "progressivo": "1.8.1-68",
        "risposta_2": "sì, in questo caso sussiste l'obbligo di patente nautica.",
        "ris_3": "F",
        "id": 1356,
        "immagine": "",
        "argomento": "Leggi e regolamenti",
        "domanda": "Occorre la patente nautica per il comando o condotta di un'imbarcazione da\ndiporto entro 6 miglia dalla costa, quando a bordo sia installato un motore di\npotenza di 29 Kw e cilindrata 750 centimetri cubici?",
        "risposta_1": "no, in questo caso è richiesto solo di aver compiuto 18 anni.",
        "ris_1": "V",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_3": "sì, solo se minorenne."
    },
    {
        "id": 1357,
        "risposta_1": "natanti a motore e quelli a vela con superficie velica superiore a quattro metri quadrati.",
        "argomento": "Leggi e regolamenti",
        "ris_3": "V",
        "risposta_2": "unità di lunghezza compresa tra 10 metri e 24 metri.",
        "immagine": "",
        "ris_1": "F",
        "progressivo": "1.8.1-69",
        "risposta_3": "unità di lunghezza compresa tra 2,5 metri e 24 metri, se immesse in commercio dopo il 16/06/1998.",
        "ris_2": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "domanda": "Quali unità da diporto sono soggette alla marcatura \"CE\"?"
    },
    {
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "id": 1358,
        "domanda": "La patente nautica è obbligatoria per il comando o condotta di un'imbarcazione da diporto entro le 6 miglia dalla costa?",
        "risposta_2": "sì, se l'unità è munita di motore con potenza superiore a 40.8 cavalli.",
        "risposta_3": "no, mai.",
        "progressivo": "1.8.1-70",
        "ris_1": "F",
        "ris_2": "V",
        "argomento": "Leggi e regolamenti",
        "risposta_1": "sì, sempre a prescindere sia della cilindrata che delle altre caratteristiche del motore.",
        "ris_3": "F",
        "immagine": ""
    },
    {
        "ris_1": "F",
        "ris_2": "V",
        "argomento": "Leggi e regolamenti",
        "id": 1359,
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "domanda": "Si può sospendere la patente nel caso di assunzione del comando di un'unità da diporto con patente nautica scaduta di validità?",
        "ris_3": "F",
        "progressivo": "1.8.1-71",
        "risposta_1": "sì, se la patente nautica è scaduta da più di 12 mesi.",
        "risposta_3": "sì, sempre.",
        "risposta_2": "non è prevista la sospensione in questo caso.",
        "immagine": ""
    },
    {
        "risposta_1": "si è ostruita la presa a mare.",
        "ris_2": "F",
        "domanda": "Escludendo problemi all'elica, se un motore diesel \"perde colpi e cala di giri\" e vi è carburante in quantità sufficiente; cos'è successo?",
        "id": 136,
        "ris_1": "F",
        "immagine": "",
        "risposta_2": "si è intasato il filtro dell'olio.",
        "progressivo": "1.2.1-11",
        "capitolo": "MOTORI",
        "risposta_3": "nel serbatoio è presente carburante sporco.",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "ris_3": "V"
    },
    {
        "risposta_3": "quando è di tipo amovibile, sistemato su proprio supporto con potenza non superiore al 20% di quella del motore principale.",
        "immagine": "",
        "id": 1360,
        "ris_1": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_3": "V",
        "risposta_2": "quando è superiore a 40 Cv.",
        "risposta_1": "quando è inferiore a 40 Cv.",
        "progressivo": "1.8.1-72",
        "argomento": "Leggi e regolamenti",
        "ris_2": "F",
        "domanda": "Quando il secondo motore è considerato ausiliario?"
    },
    {
        "risposta_2": "di una nave da diporto.",
        "immagine": "",
        "id": 1361,
        "progressivo": "1.8.1-73",
        "ris_3": "F",
        "domanda": "Il \"manuale del proprietario\" è quel documento contenente i dati tecnici:",
        "risposta_1": "del natante omologato CE.",
        "ris_2": "F",
        "argomento": "Documenti",
        "risposta_3": "del natante non omologato CE.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_1": "V"
    },
    {
        "risposta_2": "all'Autorità di polizia del porto di arrivo.",
        "immagine": "",
        "risposta_1": "all'Autorità marittima o Consolare del porto di arrivo.",
        "id": 1362,
        "argomento": "",
        "progressivo": "1.8.1-74",
        "risposta_3": "all'Autorità giudiziaria del porto di arrivo.",
        "ris_3": "F",
        "domanda": "La denuncia di evento straordinario è presentata:",
        "ris_1": "V",
        "ris_2": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE"
    },
    {
        "risposta_1": "il sequestro dell'unità.",
        "immagine": "",
        "ris_2": "V",
        "argomento": "Documenti",
        "domanda": "Cosa comporta l'assunzione, con l'abilitazione scaduta, del comando o condotta di un'unità soggetta ad obbligo di patente nautica?",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "id": 1363,
        "ris_3": "F",
        "ris_1": "F",
        "progressivo": "1.8.1-75",
        "risposta_3": "l'arresto.",
        "risposta_2": "una salata sanzione amministrativa."
    },
    {
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_3": "no, è sufficiente avere a bordo delle semplici copie fotostatiche.",
        "ris_3": "F",
        "risposta_2": "si, previa autorizzazione rilasciata dall'Autorità marittima.",
        "id": 1364,
        "immagine": "",
        "ris_2": "F",
        "domanda": "La licenza e gli altri documenti per le unità da diporto devono essere sempre tenuti a bordo in originale?",
        "progressivo": "1.8.1-76",
        "ris_1": "V",
        "argomento": "Documenti",
        "risposta_1": "no, per la navigazione tra i porti nazionali è sufficiente avere a bordo le copie conformi all'originale."
    },
    {
        "domanda": "Dove vengono iscritte le imbarcazioni e navi da diporto?",
        "risposta_2": "solo presso le Capitanerie di porto.",
        "argomento": "Leggi e regolamenti",
        "ris_2": "F",
        "id": 1365,
        "immagine": "",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_1": "V",
        "ris_3": "F",
        "risposta_1": "nell'ATCN (Archivio Telematico Centrale della Nautica da diporto) per il tramite dello STED (Sportello Telematico del Diportista)",
        "progressivo": "1.8.1-77",
        "risposta_3": "non vengono più iscritte nei registri navali poiché abrogati."
    },
    {
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_2": "V",
        "ris_3": "F",
        "risposta_2": "aver compiuto diciotto anni.",
        "id": 1366,
        "progressivo": "1.8.1-78",
        "argomento": "Leggi e regolamenti",
        "ris_1": "F",
        "immagine": "",
        "risposta_1": "la patente nautica per il comando e condotta di imbarcazioni da diporto a vela e motore entro 12 miglia dalla costa.",
        "risposta_3": "la patente nautica per il comando e condotta di imbarcazioni da diporto a motore entro 12 miglia dalla costa.",
        "domanda": "Per il comando e condotta di un'imbarcazione da diporto a vela senza motore ausiliario, per la navigazione nelle acque interne e nelle acque marittime entro 6 miglia dalla costa, è sufficiente:"
    },
    {
        "ris_1": "F",
        "risposta_1": "sì, a patto che ottenga una apposita autorizzazione, in tal senso, dalla Capitaneria del porto di partenza.",
        "risposta_2": "sì, a patto che non superi il limite delle 12 miglia dalla costa.",
        "argomento": "Leggi e regolamenti",
        "risposta_3": "no, in nessun caso.",
        "immagine": "",
        "ris_2": "V",
        "progressivo": "1.8.1-79",
        "id": 1367,
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_3": "F",
        "domanda": "Con la patente nautica entro le 12 miglia dalla costa, si può comandare un'imbarcazione da diporto abilitata a navigare senza limiti di distanza dalla costa?"
    },
    {
        "argomento": "Leggi e regolamenti",
        "risposta_2": "no, solo se in possesso della patente nautica per nave da diporto.",
        "ris_3": "F",
        "immagine": "",
        "ris_1": "V",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_3": "sì, sempre.",
        "ris_2": "F",
        "progressivo": "1.8.1-80",
        "risposta_1": "sì, solo a condizione di essere in possesso di idoneo titolo professionale del diporto prescritto dalla legge.",
        "id": 1368,
        "domanda": "E' possibile comandare e condurre un'imbarcazione da diporto utilizzata con contratti di noleggio?"
    },
    {
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_3": "F",
        "risposta_3": "noleggio.",
        "domanda": "Si paga una somma per \"prendere in godimento\" un'imbarcazione per un determinato periodo di tempo e il contratto esclude la \"possibilità di riscatto\" alla sua scadenza; siamo in presenza di un contratto di:",
        "progressivo": "1.8.1-81",
        "argomento": "Attività commerciale",
        "ris_1": "V",
        "immagine": "",
        "risposta_2": "leasing finanziario.",
        "ris_2": "F",
        "risposta_1": "locazione.",
        "id": 1369
    },
    {
        "id": 137,
        "risposta_1": "dal timone e i suoi accessori.",
        "capitolo": "MOTORI",
        "ris_1": "F",
        "ris_2": "F",
        "risposta_3": "dal motore e dall'elica.",
        "risposta_2": "dalle vele.",
        "progressivo": "1.2.1-12",
        "immagine": "",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "ris_3": "V",
        "domanda": "Il sistema propulsivo di una barca a motore è dato:"
    },
    {
        "ris_3": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_2": "noleggio.",
        "argomento": "Attività commerciale",
        "id": 1370,
        "domanda": "Se si paga una somma per usufruire dei servizi di una persona, che si mette a disposizione e mette a disposizione la sua imbarcazione per un determinato periodo di tempo, si ha un contratto di:",
        "immagine": "",
        "risposta_3": "locazione.",
        "progressivo": "1.8.1-82",
        "risposta_1": "leasing finanziario.",
        "ris_1": "F",
        "ris_2": "V"
    },
    {
        "argomento": "Attività commerciale",
        "id": 1371,
        "ris_1": "V",
        "immagine": "",
        "ris_3": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "progressivo": "1.8.1-83",
        "risposta_2": "il cliente (noleggiatore) dell'unità ne esercita la navigazione, assumendosene le responsabilità e i rischi.",
        "ris_2": "F",
        "risposta_1": "l'unità noleggiata rimane nella disponibilità del proprietario/armatore (noleggiante), alle cui dipendenze resta anche l'equipaggio.",
        "domanda": "Quale tra le seguenti affermazioni sul noleggio di unità da diporto è corretta?",
        "risposta_3": "noleggio e locazione sono sinonimi."
    },
    {
        "ris_3": "F",
        "risposta_2": "la facoltà del proprietario di una imbarcazione di noleggiare la propria unità per un massimo di 42 giorni l'anno previa comunicazione di ogni singolo contratto all'Agenzia delle Entrate e all'Autorità marittima. Non costituisce un'attività professionale.",
        "progressivo": "1.8.1-84",
        "immagine": "",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "domanda": "Il noleggio occasionale è:",
        "risposta_1": "è una attività professionale svolta in maniera non continuativa.",
        "argomento": "Attività commerciale",
        "ris_1": "F",
        "risposta_3": "è un sinonimo di locazione.",
        "id": 1372,
        "ris_2": "V"
    },
    {
        "risposta_1": "può prevedere anche più clienti \"noleggiatori a cabina\"; salva  diversa  volontà  delle parti, sono stipulati più contratti di noleggio per quanti  sono i noleggiatori di ogni cabina o gruppo di cabine.",
        "risposta_3": "può essere concluso anche in forma orale se alla presenza di un ufficiale dell'Autorità marittima.",
        "ris_3": "F",
        "domanda": "Quale tra le seguenti affermazioni sul contratto di noleggio di unità da diporto è corretta?",
        "ris_2": "F",
        "progressivo": "1.8.1-85",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "id": 1373,
        "argomento": "Attività commerciale",
        "ris_1": "V",
        "immagine": "",
        "risposta_2": "può essere intestato sempre e solo a una persona fisica."
    },
    {
        "risposta_1": "sì, ma subisce il regime giuridico delle imbarcazioni da diporto.",
        "id": 1374,
        "ris_1": "V",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "immagine": "",
        "progressivo": "1.8.1-86",
        "ris_2": "F",
        "risposta_3": "sì, ma mantiene il regime giuridico del natante da diporto.",
        "ris_3": "F",
        "domanda": "Un'unità da diporto, avente lunghezza fuori tutto pari a 7 metri, può essere iscritta nel registro delle imbarcazioni da diporto?",
        "risposta_2": "sì, se con un motore di potenza superiore a 40,8 CV.",
        "argomento": "Leggi e regolamenti"
    },
    {
        "ris_2": "V",
        "ris_3": "F",
        "risposta_2": "di 10 anni.",
        "ris_1": "F",
        "risposta_1": "di 3 anni.",
        "id": 1375,
        "progressivo": "1.8.1-87",
        "argomento": "Leggi e regolamenti",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "immagine": "",
        "risposta_3": "di 5 anni.",
        "domanda": "Un cittadino di 55 anni deve rinnovare la patente; la validità del nuovo documento sarà:"
    },
    {
        "risposta_1": "sportivo o ricreativo ovvero commerciale, come previsto dal Codice della nautica da diporto.",
        "immagine": "",
        "risposta_2": "ricreativo, dai cui esuli ogni forma di agonismo.",
        "domanda": "Per navigazione da diporto s'intende la navigazione effettuata a scopo:",
        "id": 1376,
        "ris_3": "F",
        "ris_1": "V",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "progressivo": "1.8.1-88",
        "risposta_3": "solo sportivo.",
        "argomento": "Leggi e regolamenti",
        "ris_2": "F"
    },
    {
        "ris_1": "V",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_2": "F",
        "risposta_2": "10 anni per tutti.",
        "domanda": "Come è articolata la validità temporale della patenta nautica?",
        "id": 1377,
        "argomento": "Leggi e regolamenti",
        "risposta_1": "10 anni se non si è superato il 60esimo anno di età, 5 anni se si è superato tale limite d'età.",
        "progressivo": "1.8.1-89",
        "risposta_3": "10 anni se non si è superato il 50esimo anno di età, 5 anni se si è superato tale limite d'età.",
        "immagine": "",
        "ris_3": "F"
    },
    {
        "ris_1": "V",
        "risposta_1": "la sanzione amministrativa che va da 2.755 a 11.017 euro e la sospensione della licenza di navigazione per 30 giorni.",
        "risposta_2": "la sanzione amministrativa che va da 2.755 a 11.017 euro e l'arresto.",
        "ris_2": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "immagine": "",
        "progressivo": "1.8.1-90",
        "risposta_3": "la sanzione amministrativa che va da 2.755 a 11.017 euro, la sospensione della licenza di navigazione per 1 anno.",
        "domanda": "Assumere il comando di un'unità senza aver conseguito la prescritta abilitazione, comporta:",
        "ris_3": "F",
        "argomento": "Leggi e regolamenti",
        "id": 1378
    },
    {
        "immagine": "",
        "ris_1": "F",
        "risposta_1": "no, solo in caso di potenza superiore a 40.8 cavalli.",
        "argomento": "Leggi e regolamenti",
        "progressivo": "1.8.1-91",
        "risposta_2": "sì, sempre.",
        "ris_3": "F",
        "ris_2": "V",
        "domanda": "Per condurre una moto d'acqua è sempre necessaria la patente nautica?",
        "id": 1379,
        "risposta_3": "mai, perchè di lunghezza inferiore a 4,5 metri.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE"
    },
    {
        "ris_1": "F",
        "risposta_1": "permette il raffreddamento dell'asse portaelica dell'unità mediante acqua pompata dall'esterno.",
        "ris_3": "V",
        "capitolo": "MOTORI",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "risposta_3": "serve per raffreddare il fluido presente nel circuito chiuso mediante l'acqua di mare.",
        "ris_2": "F",
        "progressivo": "1.2.1-13",
        "immagine": "",
        "domanda": "Lo scambiatore di calore in un motore marino entrobordo:",
        "risposta_2": "fa parte del circuito diretto di raffreddamento del motore salpancora.",
        "id": 138
    },
    {
        "id": 1380,
        "risposta_1": "da un qualsiasi membro dell'equipaggio.",
        "risposta_3": "da chiunque sia stato delegato dal comandante dell'unità.",
        "argomento": "Leggi e regolamenti",
        "domanda": "La dichiarazione di evento straordinario deve essere rilasciata:",
        "ris_2": "V",
        "immagine": "",
        "risposta_2": "dal comandante dell'unità.",
        "ris_1": "F",
        "ris_3": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "progressivo": "1.8.1-92"
    },
    {
        "risposta_3": "entro due miglia dalla costa o dall'unità madre.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_3": "F",
        "argomento": "Leggi e regolamenti",
        "id": 1381,
        "progressivo": "1.8.1-93",
        "risposta_1": "entro 500 metri dalla costa o dall'unità madre.",
        "ris_1": "F",
        "domanda": "Il battello di servizio (tender) non ha l'obbligo delle dotazioni di sicurezza e dei mezzi di salvataggio, fatti  salvi  i  mezzi  di salvataggio individuali, se naviga:",
        "risposta_2": "entro un miglio dalla costa o dall'unità madre.",
        "immagine": "",
        "ris_2": "V"
    },
    {
        "risposta_1": "sì, solo se minorenne.",
        "id": 1382,
        "progressivo": "1.8.1-94",
        "domanda": "La patente nautica è obbligatoria per il comando o condotta di un'imbarcazione da diporto entro 6 miglia dalla costa, quando a bordo sia installato un motore di potenza di 29 Kw e cilindrata di 1.398 centimetri cubici con carburazione a 4 tempi entro bordo?",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_3": "V",
        "risposta_3": "sì, in questo caso sussiste l'obbligo di patente nautica.",
        "ris_1": "F",
        "risposta_2": "no, in questo caso è richiesto solo di aver compiuto i 18 anni di età.",
        "ris_2": "F",
        "immagine": "",
        "argomento": "Leggi e regolamenti"
    },
    {
        "risposta_1": "sui motori di potenza superiore a 10 Cv.",
        "ris_2": "F",
        "id": 1383,
        "risposta_2": "sui motori di potenza superiore a 40,8 Cv.",
        "ris_3": "V",
        "domanda": "L'obbligo dell'assicurazione sulla responsabilità civile ricade:",
        "ris_1": "F",
        "argomento": "Leggi e regolamenti",
        "immagine": "",
        "progressivo": "1.8.1-95",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_3": "su qualsiasi motore marino, amovibile e non, indipendentemente dalla potenza."
    },
    {
        "ris_1": "V",
        "risposta_3": "subito all'arrivo in porto.",
        "ris_3": "F",
        "risposta_1": "entro tre giorni dall'arrivo in porto.",
        "id": 1384,
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_2": "F",
        "progressivo": "1.8.1-96",
        "immagine": "",
        "argomento": "Leggi e regolamenti",
        "risposta_2": "non appena possibile.",
        "domanda": "Nel caso non siano avvenute lesioni a persone a bordo, la denuncia di evento straordinario va presentata:"
    },
    {
        "ris_1": "V",
        "argomento": "Leggi e regolamenti",
        "ris_3": "F",
        "progressivo": "1.8.1-97",
        "immagine": "",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_3": "quando non si è pagato il bollo annuale.",
        "id": 1385,
        "domanda": "Quando è sospesa la patente nautica?",
        "ris_2": "F",
        "risposta_2": "quando è scaduta e non è stata rinnovata.",
        "risposta_1": "per gravi atti di imperizia ed imprudenza."
    },
    {
        "immagine": "",
        "ris_3": "F",
        "ris_2": "V",
        "domanda": "Tutte le unità a motore hanno l’obbligo di tenere a bordo la dichiarazione di\npotenza del motore (o il certificato d’uso motore)?",
        "id": 1386,
        "risposta_1": "si, tutte le unità da diporto a motore hanno l'obbligo a prescindere che siano o non siano iscritte nei registri navali.",
        "ris_1": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "argomento": "Documenti",
        "risposta_3": "no, hanno l'obbligo solo le navi da diporto.",
        "risposta_2": "no, oltre ai natanti da diporto, hanno l'obbligo solo le imbarcazioni da diporto dotate di motore fuoribordo.",
        "progressivo": "1.8.1-98"
    },
    {
        "immagine": "",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "progressivo": "1.8.1-99",
        "risposta_3": "licenza di navigazione.",
        "ris_2": "F",
        "domanda": "Il documento che riporta, tra le varie caratteristiche, i dati di un’imbarcazione e i dati anagrafici del suo proprietario, è denominato:",
        "risposta_1": "manuale del proprietario.",
        "ris_1": "F",
        "argomento": "Documenti",
        "ris_3": "V",
        "risposta_2": "certificato di proprietà marittimo.",
        "id": 1387
    },
    {
        "domanda": "Nel caso di unità non adibita a noleggio, il certificato di sicurezza è rilasciato?",
        "id": 1388,
        "ris_1": "F",
        "risposta_3": "a tutte le unità da diporto.",
        "ris_2": "V",
        "progressivo": "1.8.1-100",
        "argomento": "Leggi e regolamenti",
        "risposta_2": "solo alle navi e le imbarcazioni da diporto.",
        "immagine": "",
        "risposta_1": "solo ai natanti da diporto adibiti a locazione commerciale.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_3": "F"
    },
    {
        "risposta_1": "da una tabella ministeriale.",
        "domanda": "I limiti fissati dalla legge per il conseguimento della patente nautica relativamente al motore sono determinati:",
        "ris_3": "F",
        "immagine": "",
        "ris_2": "V",
        "progressivo": "1.8.1-101",
        "argomento": "Leggi e regolamenti",
        "ris_1": "F",
        "risposta_2": "dalla potenza massima di esercizio.",
        "risposta_3": "dalla potenza fiscale del motore.",
        "id": 1389,
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE"
    },
    {
        "ris_2": "F",
        "progressivo": "1.2.1-14",
        "risposta_1": "giunto cardanico.",
        "ris_1": "F",
        "risposta_3": "invertitore.",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "domanda": "Com'è denominato quell'organo del motore marino che permette di alternare le fasi di moto \"marcia avanti - folle - marcia indietro\"?",
        "capitolo": "MOTORI",
        "immagine": "",
        "risposta_2": "astuccio.",
        "ris_3": "V",
        "id": 139
    },
    {
        "ris_2": "V",
        "risposta_2": "indossare il giubbotto di salvataggio e rispettare i limiti di velocità disposti localmente.",
        "ris_3": "F",
        "ris_1": "F",
        "risposta_1": "possedere la patente nautica solo se l'unità ha una cilindrata superiore a 785 centimetri cubici.",
        "immagine": "",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "argomento": "Leggi e regolamenti",
        "progressivo": "1.8.1-102",
        "risposta_3": "raggiungere la riva senza bisogno dei corridoi di atterraggio.",
        "domanda": "Il conduttore di una moto d’acqua deve:",
        "id": 1390
    },
    {
        "argomento": "Leggi e regolamenti",
        "risposta_1": "è in ogni caso abilitata alla navigazione solo entro 3 miglia dalla costa.",
        "risposta_3": "è considerata come unità a vela senza motore ausiliario.",
        "risposta_2": "necessita della licenza di navigazione.",
        "progressivo": "1.8.1-103",
        "ris_2": "V",
        "immagine": "",
        "id": 1391,
        "ris_3": "F",
        "domanda": "Un’unità di 13 metri di lunghezza, con superficie velica di 80 metri quadrati e un motore di 45 Cv:",
        "ris_1": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE"
    },
    {
        "id": 1392,
        "ris_3": "V",
        "domanda": "La patente nautica viene revocata in caso di:",
        "risposta_1": "condotta con tasso alcolemico pari a 1,50 grammi per litro.",
        "ris_1": "F",
        "ris_2": "F",
        "risposta_2": "per gravi atti di imperizia ed imprudenza.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "progressivo": "1.8.1-104",
        "argomento": "Leggi e regolamenti",
        "immagine": "",
        "risposta_3": "in caso di perdita dei requisiti morali e fisici."
    },
    {
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_1": "V",
        "ris_3": "F",
        "argomento": "Leggi e regolamenti",
        "risposta_1": "quelle indicate nel certificato di omologazione.",
        "progressivo": "1.8.1-105",
        "risposta_2": "dipende dall'ordinanza disciplinante l'utilizzazione dei natanti da diporto.",
        "id": 1393,
        "domanda": "Un natante marcato \"CE\", quante persone può trasportare?",
        "ris_2": "F",
        "risposta_3": "quelle indicate nella dichiarazione di potenza del motore per natanti da diporto.",
        "immagine": ""
    },
    {
        "id": 1394,
        "risposta_2": "no, in questo caso è richiesto solo di aver compiuto i 18 anni di età.",
        "risposta_3": "sì, in questo caso sussiste l'obbligo di patente nautica.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_2": "F",
        "risposta_1": "sì, solo se minorenne.",
        "ris_1": "F",
        "domanda": "La patente nautica è obbligatoria per il comando o condotta di un'imbarcazione da diporto entro 6 miglia dalla costa, quando a bordo sia installato un motore fuoribordo di potenza di 29 Kw e cilindrata di 1.299 centimetri cubici a iniezione diretta?",
        "argomento": "Leggi e regolamenti",
        "progressivo": "1.8.1-106",
        "ris_3": "V",
        "immagine": ""
    },
    {
        "risposta_3": "sempre.",
        "progressivo": "1.8.1-107",
        "risposta_1": "solo se il motore è di cilindrata superiore a 750 centimetri cubici.",
        "domanda": "La patente per condurre un acquascooter è obbligatoria:",
        "risposta_2": "solo se il motore supera i 30 Kw.",
        "ris_3": "V",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_1": "F",
        "immagine": "",
        "id": 1395,
        "argomento": "Leggi e regolamenti",
        "ris_2": "F"
    },
    {
        "ris_1": "F",
        "domanda": "Sicuramente è considerato un “evento straordinario”:",
        "immagine": "",
        "ris_2": "V",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_3": "il restare senza carburante.",
        "ris_3": "F",
        "id": 1396,
        "progressivo": "1.8.1-108",
        "risposta_1": "il malore tra i membri dell’equipaggio.",
        "risposta_2": "l'incaglio.",
        "argomento": "Leggi e regolamenti"
    },
    {
        "risposta_3": "all’autorità marittima locale che lo trasmetterà al consolato di bandiera.",
        "argomento": "Leggi e regolamenti",
        "immagine": "",
        "progressivo": "1.8.1-109",
        "id": 1397,
        "domanda": "A chi va presentata la denuncia di evento straordinario, in caso di evento straordinario all’estero?",
        "ris_1": "V",
        "ris_2": "F",
        "risposta_1": "al consolato di bandiera.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_3": "F",
        "risposta_2": "all’autorità marittima italiana al primo porto di approdo in territorio nazionale."
    },
    {
        "risposta_1": "sì, in questo caso sussiste l'obbligo di patente nautica.",
        "ris_2": "F",
        "argomento": "Leggi e regolamenti",
        "ris_3": "F",
        "progressivo": "1.8.1-110",
        "id": 1398,
        "risposta_2": "no, in questo caso è richiesto solo di aver compiuto i 18 anni di età.",
        "ris_1": "V",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_3": "sì, solo se minorenne.",
        "domanda": "E' obbligatoria la patente nautica, per la navigazione con un'imbarcazione da diporto entro 6 miglia dalla costa, quando a bordo sia installato un motore di potenza di 29 Kw e cilindrata di 1.098 centimetri cubici e carburazione a quattro tempi fuori bordo?",
        "immagine": ""
    },
    {
        "immagine": "",
        "risposta_3": "sempre.",
        "argomento": "Leggi e regolamenti",
        "ris_3": "V",
        "domanda": "E' obbligatoria la patente nautica per condurre un’unità da diporto avente un motore di 35 Kw?",
        "ris_2": "F",
        "id": 1399,
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_1": "F",
        "risposta_1": "mai.",
        "progressivo": "1.8.1-111",
        "risposta_2": "solo in determinati casi."
    },
    {
        "ris_1": "V",
        "progressivo": "1.1.1-14",
        "domanda": "Lo specchio di poppa è:",
        "risposta_3": "il fondo del pozzetto in cui è alloggiata la barra del timone.",
        "immagine": "",
        "id": 14,
        "risposta_1": "la parte della poppa al di sopra del dritto di poppa.",
        "capitolo": "TEORIA DELLO SCAFO",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "ris_2": "F",
        "risposta_2": "l'apertura della poppa negli scafi autosvuotanti.",
        "ris_3": "F"
    },
    {
        "immagine": "",
        "ris_1": "F",
        "progressivo": "1.2.1-15",
        "id": 140,
        "capitolo": "MOTORI",
        "ris_2": "F",
        "risposta_3": "la batteria è elemento essenziale per l'avviamento.",
        "risposta_1": "tutte le candele ricevono nello stesso istante l'impulso elettrico dallo spinterogeno.",
        "domanda": "Nell'impianto elettrico di un motore marino diesel:",
        "risposta_2": "una volta avviato il motore, questo non funziona staccando la batteria.",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "ris_3": "V"
    },
    {
        "progressivo": "1.8.1-112",
        "id": 1400,
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "domanda": "La validità della patente nautica è di 10 anni sino al compimento del:",
        "risposta_3": "50esimo anno e poi 5 anni.",
        "risposta_2": "60esimo anno e poi 5 anni.",
        "ris_2": "V",
        "argomento": "Leggi e regolamenti",
        "risposta_1": "65esimo anno e poi 5 anni.",
        "ris_3": "F",
        "ris_1": "F",
        "immagine": ""
    },
    {
        "ris_1": "V",
        "domanda": "Un natante è quell'unità:",
        "progressivo": "1.8.1-113",
        "id": 1401,
        "risposta_3": "di lunghezza superiore a 10 metri.",
        "immagine": "",
        "risposta_2": "iscritta.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_3": "F",
        "ris_2": "F",
        "argomento": "Leggi e regolamenti",
        "risposta_1": "non iscritta."
    },
    {
        "risposta_1": "no, purchè vi sia a bordo altra persona regolarmente abilitata per il tipo di navigazione in atto che si assuma la responsabilità del comando e della condotta.",
        "immagine": "",
        "ris_2": "F",
        "risposta_3": "si, sempre perché tenere il timone significa determinare la direzione della navigazione in atto.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_3": "F",
        "argomento": "Comandante, conduttore, utilizzatore",
        "id": 1402,
        "risposta_2": "no, purchè abbia delega scritta ed età non inferiore ad anni 14.",
        "domanda": "Chi regge il timone di un'imbarcazione da diporto deve essere necessariamente munito di patente nautica?",
        "progressivo": "1.8.1-114",
        "ris_1": "V"
    },
    {
        "immagine": "",
        "risposta_3": "dichiarazione di potenza.",
        "ris_2": "F",
        "ris_1": "F",
        "progressivo": "1.8.1-115",
        "risposta_1": "dichiarazione motoristica.",
        "argomento": "Documenti",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_3": "V",
        "risposta_2": "libretto del motore.",
        "domanda": "Il documento che riporta le caratteristiche del motore di un natante è conosciuto sotto di:",
        "id": 1403
    },
    {
        "ris_2": "V",
        "ris_3": "F",
        "risposta_3": "in presenza di forza del vento non superiore a 8 e altezza significativa dell'onda non superiore a 5 metri.",
        "domanda": "La categoria di progettazione \"B\" presuppone che l'imbarcazione da diporto sia in grado di navigare (individuare l'affermazione corretta):",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "id": 1404,
        "risposta_1": "in presenza di forza del vento superiore a 8 e altezza significativa dell'onda non superiore a 4 metri.",
        "argomento": "Leggi e regolamenti",
        "immagine": "",
        "risposta_2": "in presenza di forza del vento non superiore a 8 e altezza significativa dell'onda non superiore a 4 metri.",
        "ris_1": "F",
        "progressivo": "1.8.1-116"
    },
    {
        "immagine": "",
        "risposta_3": "in presenza di forza del vento non superiore a 6 e altezza significativa dell'onda non superiore a 2 metri.",
        "ris_2": "F",
        "progressivo": "1.8.1-117",
        "domanda": "La categoria di progettazione \"C\" presuppone che l'imbarcazione da diporto sia in grado di navigare (individuare l'affermazione corretta):",
        "argomento": "Leggi e regolamenti",
        "id": 1405,
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_3": "V",
        "ris_1": "F",
        "risposta_2": "in presenza di forza del vento non superiore a 7 e altezza significativa dell'onda non superiore a 3 metri.",
        "risposta_1": "in presenza di forza del vento superiore a 7 e altezza significativa dell'onda non superiore a 4 metri."
    },
    {
        "risposta_3": "sì, solo se minorenne.",
        "ris_2": "F",
        "immagine": "",
        "ris_3": "F",
        "argomento": "Leggi e regolamenti",
        "risposta_1": "no, in questo caso è richiesto solo di aver compiuto i 16 anni di età.",
        "risposta_2": "si, in questo caso sussiste l'obbligo di patente nautica.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_1": "V",
        "id": 1406,
        "progressivo": "1.8.1-118",
        "domanda": "E' obbligatoria la patente nautica, per la navigazione con un natante da diporto entro 6 miglia dalla costa, quando a bordo sia installato un motore di potenza di 29 Kw e cilindrata di 998 centimetri cubici e carburazione a quattro tempi fuori bordo?"
    },
    {
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "progressivo": "1.8.1-119",
        "domanda": "La categoria di progettazione \"D\" presuppone che l'imbarcazione da diporto sia in grado di navigare (individuare l'affermazione corretta):",
        "risposta_1": "in presenza di forza del vento non superiore a 3 e altezza significativa dell'onda non superiore a 0,2 metri, occasionalmente a 0,4 metri.",
        "id": 1407,
        "ris_1": "F",
        "ris_2": "F",
        "ris_3": "V",
        "immagine": "",
        "risposta_2": "in presenza di forza del vento non superiore a 5 e altezza significativa dell'onda non superiore a 0,4 metri, occasionalmente a 0,6 metri.",
        "argomento": "Leggi e regolamenti",
        "risposta_3": "in presenza di forza del vento non superiore a 4 e altezza significativa  dell'onda non superiore a 0,3 metri, occasionalmente a 0,5 metri."
    },
    {
        "risposta_3": "del comandante dell'unità.",
        "progressivo": "1.8.1-120",
        "ris_1": "F",
        "argomento": "Comandante, conduttore, utilizzatore",
        "ris_3": "V",
        "risposta_2": "dell'Autorità Marittima.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "id": 1408,
        "domanda": "Verificare prima della partenza che le dotazioni di sicurezza di un'unità da diporto siano efficienti, è un compito di chi?",
        "immagine": "",
        "risposta_1": "dell'Organismo tecnico notificato o autorizzato al momento di visita a bordo.",
        "ris_2": "F"
    },
    {
        "argomento": "Comandante, conduttore, utilizzatore",
        "immagine": "",
        "risposta_1": "dal comandante in funzione della navigazione da intraprendere in relazione alle condimeteo marine e alla distanza da porti sicuri.",
        "risposta_3": "con ordinanza dell'Autorità marittima competente, trattandosi di navigazione entro 12 miglia.",
        "ris_3": "F",
        "id": 1409,
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "progressivo": "1.8.1-121",
        "risposta_2": "nel certificato di sicurezza per imbarcazioni da diporto.",
        "ris_2": "F",
        "ris_1": "V",
        "domanda": "In generale il numero minimo dei componenti l'equipaggio di una qualsiasi unità da diporto (purchè non adibita a noleggio) è stabilito:"
    },
    {
        "capitolo": "MOTORI",
        "risposta_2": "quattro giri.",
        "ris_1": "V",
        "risposta_3": "otto giri.",
        "progressivo": "1.2.1-16",
        "id": 141,
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "immagine": "",
        "domanda": "In un motore a 4 tempi quanti giri descrive l’albero motore per effettuare un ciclo completo?",
        "risposta_1": "due giri.",
        "ris_2": "F",
        "ris_3": "F"
    },
    {
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "progressivo": "1.8.1-122",
        "argomento": "Leggi e regolamenti",
        "risposta_2": "oltre 1000 metri dalla costa, 500 metri dalle coste a picco.",
        "domanda": "La moto d'acqua può navigare oltre la velocità minima:",
        "risposta_3": "oltre 1 miglio dalla costa.",
        "id": 1410,
        "ris_2": "V",
        "immagine": "",
        "ris_3": "F",
        "ris_1": "F",
        "risposta_1": "oltre 100 metri dalla costa."
    },
    {
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_1": "F",
        "risposta_3": "solo se  la potenza del motore supera i 40,8 CV (30 Kw).",
        "domanda": "E' obbligatoria la patente per l'uso della moto d'acqua?",
        "ris_2": "V",
        "immagine": "",
        "id": 1411,
        "risposta_1": "no.",
        "argomento": "Leggi e regolamenti",
        "progressivo": "1.8.1-123",
        "risposta_2": "si, sempre.",
        "ris_3": "F"
    },
    {
        "id": 1412,
        "ris_3": "V",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_2": "sempre e solo entro 6 miglia dalla costa.",
        "risposta_1": "anche oltre 12 miglia dalla costa, se ha le adeguate dotazioni di sicurezza",
        "risposta_3": "entro 12 miglia dalla costa, se omologato per la navigazione senza alcun limite.",
        "progressivo": "1.8.1-124",
        "immagine": "",
        "argomento": "Leggi e regolamenti",
        "ris_2": "F",
        "ris_1": "F",
        "domanda": "Un natante omologato CE può navigare:"
    },
    {
        "risposta_3": "del rilascio della dichiarazione di potenza.",
        "id": 1413,
        "progressivo": "1.8.1-125",
        "risposta_1": "della sostituzione dei mezzi di salvataggio e delle dotazioni di sicurezza che presentino deterioramento o deficienze tali da comprometterne l'efficienza.",
        "immagine": "",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_3": "F",
        "ris_2": "F",
        "argomento": "Comandante, conduttore, utilizzatore",
        "domanda": "Il comandante è responsabile:",
        "risposta_2": "del rilascio del certificato di sicurezza.",
        "ris_1": "V"
    },
    {
        "domanda": "La pratica dello sci nautico prevede, altresì, l'utilizzo delle seguenti dotazioni:",
        "immagine": "",
        "risposta_2": "la cassetta di pronto soccorso ed un salvagente per ciascuno degli sciatori trainati.",
        "ris_2": "V",
        "ris_3": "F",
        "progressivo": "1.8.2-1",
        "risposta_3": "un gavitello di colore arancione munito di apposito dispositivo luminoso lampeggiante di colore blu visibile per tutto l'orizzonte.",
        "risposta_1": "una boetta di segnalazione di colore arancione munita di sagola galleggiante di lunghezza non inferiore a 180 metri.",
        "argomento": "Sci nautico",
        "ris_1": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "id": 1414
    },
    {
        "risposta_3": "un gavitello di colore arancione munito di apposito dispositivo luminoso lampeggiante di colore blu visibile per tutto l'orizzonte.",
        "argomento": "Sci nautico",
        "ris_3": "F",
        "progressivo": "1.8.2-2",
        "ris_2": "V",
        "risposta_1": "la boetta di segnalazione di colore arancione munita di sagola galleggiante di lunghezza non inferiore a 180 metri.",
        "id": 1415,
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_1": "F",
        "domanda": "La pratica dello sci nautico quale dotazione prevede ?",
        "immagine": "",
        "risposta_2": "la cassetta di pronto soccorso ed un salvagente per ciascuno degli sciatori trainati."
    },
    {
        "ris_2": "V",
        "risposta_3": "devono essere abilitati alla condotta dell'unità solo nel caso esse siano dotate di motori di potenza superiori a 50 Hp.",
        "ris_3": "F",
        "progressivo": "1.8.2-3",
        "ris_1": "F",
        "domanda": "I conduttori delle unità navali da diporto munite di motore entrobordo e fuoribordo, utilizzate per l'esercizio dello sci nautico:",
        "risposta_1": "possono anche non essere abilitati alla condotta dell'unità anzidette.",
        "immagine": "",
        "risposta_2": "devono avere la patente nautica.",
        "argomento": "Sci nautico",
        "id": 1416,
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE"
    },
    {
        "id": 1417,
        "ris_1": "F",
        "risposta_3": "essere sempre assistiti da altra persona esperta nel nuoto.",
        "argomento": "Sci nautico",
        "risposta_1": "essere assistiti da altra persona imbarcata a bordo di un tender in attività di assistenza.",
        "risposta_2": "essere in possesso della patente per navi da diporto.",
        "ris_2": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "progressivo": "1.8.2-4",
        "ris_3": "V",
        "domanda": "I conduttori di natanti muniti di motore entrobordo e fuoribordo, utilizzati per l'esercizio dello sci nautico, devono:",
        "immagine": ""
    },
    {
        "argomento": "Sci nautico",
        "risposta_3": "è vietato a qualsiasi unità navale a motore superare altre unità navali trainanti sciatori oppure attraversarne la scia a velocità superiore a 15 nodi.",
        "id": 1418,
        "ris_3": "F",
        "risposta_1": "è consentito a qualsiasi unità navale a motore seguire altre unità navali trainanti sciatori, purchè distanti oltre i 15 metri dallo sciatore stesso.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_1": "F",
        "progressivo": "1.8.2-5",
        "domanda": "Avuto riguardo alla disciplina dello sci nautico, quale tra queste affermazioni è corretta?",
        "immagine": "",
        "risposta_2": "è vietato a qualsiasi unità navale a motore seguire, nella scia o a distanza inferiore a quella di sicurezza, altre unità navali trainanti sciatori oppure attraversarne la scia in velocità a vicinanza tale da poter investire gli sciatori in caso di caduta.",
        "ris_2": "V"
    },
    {
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "domanda": "Nelle zone di mare antistanti le spiagge, in assenza dei corridoi di lancio, la partenza o il rientro delle unità navali a motore addette al traino di sciatori:",
        "risposta_3": "deve avvenire a velocità non superiore a tre nodi nell'ultimo tratto dei 200 metri dalla batimetrica di metri 1,60.",
        "ris_3": "V",
        "immagine": "",
        "ris_1": "F",
        "argomento": "Sci nautico",
        "risposta_1": "deve avvenire a velocità non superiore a cinque nodi nell'ultimo tratto dei 200 metri dalla batimetrica di metri 1,60.",
        "risposta_2": "deve avvenire a velocità non superiore a sette nodi nell'ultimo tratto dei 200 metri dalla batimetrica di metri 1,60.",
        "id": 1419,
        "progressivo": "1.8.2-6",
        "ris_2": "F"
    },
    {
        "risposta_1": "consiste in 4 giri dell’albero motore.",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "capitolo": "MOTORI",
        "risposta_2": "consiste in 1 giro dell’albero motore.",
        "ris_1": "F",
        "ris_3": "V",
        "ris_2": "F",
        "risposta_3": "consiste in 4 corse del pistone e 2 giri dell’albero motore.",
        "immagine": "",
        "progressivo": "1.2.1-17",
        "domanda": "Riguardo al ciclo di funzionamento di un motore a 4 tempi:",
        "id": 142
    },
    {
        "argomento": "Sci nautico",
        "risposta_1": "esclusivamente nelle ore diurne, con tempo favorevole e mare calmo.",
        "ris_3": "F",
        "domanda": "L'esercizio dello sci nautico è consentito per ragioni di sicurezza:",
        "progressivo": "1.8.2-7",
        "ris_2": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "immagine": "",
        "id": 1420,
        "risposta_3": "esclusivamente dalle ore 09:00 alle ore 19:00, con tempo favorevole e con stato del mare non superiore a 3.",
        "risposta_2": "non oltre 50 metri dalla costa, con tempo favorevole e mare calmo.",
        "ris_1": "V"
    },
    {
        "argomento": "Sci nautico",
        "risposta_3": "no.",
        "ris_3": "F",
        "progressivo": "1.8.2-8",
        "ris_1": "V",
        "id": 1421,
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "immagine": "",
        "risposta_2": "dipende dalla lunghezza dell'unità e dalla potenza del motore.",
        "risposta_1": "sì.",
        "domanda": "Deve essere in possesso di patente nautica il conduttore di un'unità da diporto nell'esercizio dello sci nautico?",
        "ris_2": "F"
    },
    {
        "progressivo": "1.8.2-9",
        "ris_2": "V",
        "immagine": "",
        "argomento": "Sci nautico",
        "ris_1": "F",
        "domanda": "Per l'esercizio dello sci nautico, oltre al conduttore, quante persone devono trovarsi a bordo:",
        "risposta_3": "nessun altro.",
        "id": 1422,
        "ris_3": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_2": "una, esperta nel nuoto.",
        "risposta_1": "una, esperta nello sci nautico."
    },
    {
        "risposta_1": "oltre 100 metri dalla batimetrica di 1,60 metri, salvo diverse disposizioni dell'Autorità marittima.",
        "argomento": "Sci nautico",
        "immagine": "",
        "progressivo": "1.8.2-10",
        "ris_2": "V",
        "ris_3": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "id": 1423,
        "risposta_2": "oltre 200 metri dalla spiaggia, misurati dalla batimetrica di 1,60 metri, salvo diverse disposizioni dell'Autorità marittima.",
        "risposta_3": "entro un miglio dalla costa.",
        "domanda": "In quale fascia di mare è possibile praticare lo sci nautico?",
        "ris_1": "F"
    },
    {
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "id": 1424,
        "immagine": "",
        "argomento": "Sci nautico",
        "progressivo": "1.8.2-11",
        "ris_3": "F",
        "domanda": "Lo sci nautico è praticabile:",
        "risposta_1": "in ore diurne, con tempo favorevole e mare calmo.",
        "ris_1": "V",
        "risposta_2": "sempre.",
        "risposta_3": "anche in ore notturne se si dispone di un proiettore omologato.",
        "ris_2": "F"
    },
    {
        "ris_3": "F",
        "risposta_2": "12 metri.",
        "immagine": "",
        "ris_2": "V",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "argomento": "Sci nautico",
        "risposta_3": "14 metri.",
        "risposta_1": "18 metri.",
        "domanda": "La distanza minima tra lo sciatore nautico e il mezzo trainante è di:",
        "ris_1": "F",
        "progressivo": "1.8.2-12",
        "id": 1425
    },
    {
        "risposta_1": "deve essere un'unità omologata CE.",
        "domanda": "L'unità con la quale viene praticato lo sci nautico:",
        "id": 1426,
        "risposta_2": "deve essere un'unità immatricolata.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_1": "F",
        "immagine": "",
        "risposta_3": "può essere qualsiasi tipo di unità da diporto.",
        "ris_2": "F",
        "ris_3": "V",
        "argomento": "Sci nautico",
        "progressivo": "1.8.2-13"
    },
    {
        "domanda": "In caso di sci nautico svolto con natante da diporto, il conduttore deve possedere:",
        "ris_2": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "id": 1427,
        "immagine": "",
        "risposta_2": "il brevetto di nuoto e voga.",
        "risposta_1": "il brevetto di salvamento.",
        "ris_3": "V",
        "ris_1": "F",
        "progressivo": "1.8.2-14",
        "risposta_3": "la patente nautica.",
        "argomento": "Sci nautico"
    },
    {
        "id": 1428,
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_2": "V",
        "risposta_2": "soltanto in acque libere da bagnanti e da imbarcazioni, se non vietato dalle ordinanze locali, ovvero entro gli appositi corridoi di lancio.",
        "risposta_1": "esclusivamente servendosi dei corridoi di lancio.",
        "ris_3": "F",
        "immagine": "",
        "risposta_3": "ovunque purchè con cautela al fine di prevenire situazioni di pericolo.",
        "argomento": "Sci nautico",
        "ris_1": "F",
        "domanda": "Dove devono avvenire partenza e recupero dello sciatore nautico?",
        "progressivo": "1.8.2-15"
    },
    {
        "risposta_2": "mezzi che consentano una facile risalita a bordo.",
        "ris_2": "F",
        "risposta_1": "cassetta di pronto soccorso, gancio di traino e specchietto retrovisore.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "id": 1429,
        "risposta_3": "dispositivi supplementari per il segnalamento acustico.",
        "ris_1": "V",
        "progressivo": "1.8.2-16",
        "domanda": "L'unità trainante lo sciatore nautico deve essere munita di:",
        "immagine": "",
        "ris_3": "F",
        "argomento": "Sci nautico"
    },
    {
        "risposta_2": "il sistema di accensione esiste solo nei motori a scoppio.",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "risposta_1": "il motorino di avviamento non necessita di alimentazione dalla batteria.",
        "progressivo": "1.2.1-18",
        "capitolo": "MOTORI",
        "risposta_3": "la batteria non è un accumulatore di energia elettrica.",
        "ris_1": "F",
        "id": 143,
        "domanda": "Nell’impianto elettrico di un motore marino:",
        "ris_2": "V",
        "immagine": "",
        "ris_3": "F"
    },
    {
        "ris_3": "F",
        "risposta_2": "200 metri.",
        "id": 1430,
        "argomento": "Sci nautico",
        "ris_1": "V",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "progressivo": "1.8.2-17",
        "domanda": "In linea generale, qual è la distanza minima per fare sci nautico dalle coste cadenti a picco sul mare?",
        "immagine": "",
        "risposta_1": "100 metri.",
        "ris_2": "F",
        "risposta_3": "400 metri."
    },
    {
        "risposta_2": "la Motorizzazione civile.",
        "immagine": "",
        "argomento": "Sci nautico",
        "ris_1": "F",
        "risposta_1": "Ente tecnico autorizzato.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "progressivo": "1.8.2-18",
        "domanda": "Chi riconosce l'idoneità del gancio di traino e dello specchietto retrovisore ai fini della pratica dello sci nautico?",
        "risposta_3": "la Capitaneria di porto.",
        "ris_3": "V",
        "ris_2": "F",
        "id": 1431
    },
    {
        "progressivo": "1.8.2-19",
        "ris_3": "F",
        "ris_2": "V",
        "id": 1432,
        "risposta_3": "non è possibile in alcun caso.",
        "risposta_2": "avviene con rotta normale alla linea di costa ed a velocità non superiore a 3 nodi.",
        "risposta_1": "durante la stagione balneare è possibile solo davanti a coste cadenti a picco sul mare, in assenza di balneazione.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "immagine": "",
        "argomento": "Sci nautico",
        "domanda": "Nelle zone di mare antistanti le spiagge, in assenza di corridoi di lancio e fermo restando quanto prescritto dall'ordinanza dell'Autorità marittima, la partenza ed il rientro dell'unità trainante lo sciatore nautico:",
        "ris_1": "F"
    },
    {
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_3": "F",
        "ris_2": "F",
        "argomento": "Sci nautico",
        "progressivo": "1.8.2-20",
        "immagine": "",
        "risposta_2": "1 boetta fumogena.",
        "risposta_3": "1 dispositivo sonoro.",
        "ris_1": "V",
        "risposta_1": "1 salvagente a portata di mano.",
        "id": 1433,
        "domanda": "Per ogni sciatore trainato, deve essere presente a bordo dell'unità trainante:"
    },
    {
        "ris_2": "F",
        "risposta_2": "di potenza necessaria per trainare lo sciatore.",
        "ris_1": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "id": 1434,
        "progressivo": "1.8.2-21",
        "risposta_1": "del tipo entrofuoribordo.",
        "ris_3": "V",
        "immagine": "",
        "domanda": "Per poter effettuare lo sci nautico, il motore dell’unità trainante lo sciatore nautico deve essere:",
        "risposta_3": "dotato di invertitore di marcia e di dispositivo per la messa in folle.",
        "argomento": "Sci nautico"
    },
    {
        "progressivo": "1.8.2-22",
        "ris_3": "V",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "argomento": "Sci nautico",
        "ris_2": "F",
        "id": 1435,
        "domanda": "Per effettuare lo sci nautico, quale dotazione, non prevista dall'Allegato V al DM 146/2008, per navigare entro 12 miglia dalla costa, si deve aggiungere a bordo?",
        "immagine": "",
        "risposta_2": "un binocolo.",
        "ris_1": "F",
        "risposta_3": "una cassetta di pronto soccorso.",
        "risposta_1": "un fuoco a mano a luce rossa."
    },
    {
        "id": 1436,
        "progressivo": "1.8.2-23",
        "argomento": "Sci nautico",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_2": "piatto.",
        "domanda": "Per effettuare lo sci nautico il conducente osserva lo sciatore tramite uno specchio retrovisore:",
        "risposta_1": "convesso.",
        "immagine": "",
        "ris_1": "V",
        "risposta_3": "concavo.",
        "ris_3": "F",
        "ris_2": "F"
    },
    {
        "domanda": "Quanti sciatori possono essere trainati contemporaneamente dalla medesima unità da diporto?",
        "id": 1437,
        "progressivo": "1.8.2-24",
        "ris_1": "F",
        "risposta_1": "tre.",
        "immagine": "",
        "argomento": "Sci nautico",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_3": "F",
        "ris_2": "V",
        "risposta_2": "due.",
        "risposta_3": "uno."
    },
    {
        "progressivo": "1.8.2-25",
        "ris_1": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "immagine": "",
        "risposta_2": "almeno 1,5 volte la lunghezza del cavo di traino.",
        "ris_2": "F",
        "risposta_1": "almeno pari alla lunghezza del cavo di traino + ulteriori metri 10 fissi.",
        "argomento": "Sci nautico",
        "domanda": "La distanza laterale tra un battello trainante uno sciatore e gli altri natanti deve essere:",
        "id": 1438,
        "ris_3": "V",
        "risposta_3": "superiore alla lunghezza del cavo di traino."
    },
    {
        "progressivo": "1.8.2-26",
        "ris_2": "V",
        "ris_1": "F",
        "argomento": "Sci nautico",
        "immagine": "",
        "risposta_2": "no.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_1": "solo se chi conduce sia titolare di patente nautica.",
        "ris_3": "F",
        "domanda": "In barca ci sono due persone quando una di queste decide che vuole praticare sci nautico. È possibile?",
        "risposta_3": "sì.",
        "id": 1439
    },
    {
        "progressivo": "1.2.2-1",
        "risposta_1": "il carburatore è ingolfato.",
        "domanda": "Se un motore a benzina gira ma non parte, la causa potrebbe essere:",
        "capitolo": "MOTORI",
        "ris_3": "F",
        "risposta_3": "c’è aria nel circuito di raffreddamento.",
        "ris_2": "F",
        "id": 144,
        "risposta_2": "la batteria è scarica.",
        "ris_1": "V",
        "argomento": "Irregolarità, piccole avarie e modo di rimediarvi",
        "immagine": ""
    },
    {
        "id": 1440,
        "argomento": "Pesca",
        "immagine": "",
        "risposta_1": "15 anni.",
        "risposta_3": "14 anni solo con il consenso dei genitori.",
        "domanda": "La pesca non professionale effettuata con il fucile subacqueo può essere consentita a coloro che hanno compiuto:",
        "ris_1": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_3": "F",
        "progressivo": "1.8.2-27",
        "ris_2": "V",
        "risposta_2": "16 anni."
    },
    {
        "risposta_2": "soltanto fino ad una profondità di metri tre.",
        "risposta_3": "soltanto entro 50 metri della costa.",
        "immagine": "",
        "id": 1441,
        "domanda": "L'esercizio della pesca subacquea sportiva è consentito:",
        "ris_3": "F",
        "progressivo": "1.8.2-28",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_1": "soltanto in apnea senza l'uso di apparecchi ausiliari di respirazione.",
        "argomento": "Pesca",
        "ris_2": "F",
        "ris_1": "V"
    },
    {
        "ris_1": "F",
        "ris_2": "V",
        "argomento": "Pesca",
        "risposta_1": "è consento esclusivamente l'utilizzo di fonti luminose alogene.",
        "domanda": "Durante l'esercizio della pesca sportiva subacquea:",
        "progressivo": "1.8.2-29",
        "risposta_2": "è vietato l'utilizzo delle fonti luminose a eccezione della torcia.",
        "id": 1442,
        "ris_3": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "immagine": "",
        "risposta_3": "non è consento l'utilizzo di alcuna fonte luminosa, compresa la torcia."
    },
    {
        "risposta_1": "è vietato dal sorgere del sole al tramonto.",
        "risposta_3": "è vietato dalle ore 08:00 alle ore 18:00.",
        "ris_3": "F",
        "progressivo": "1.8.2-30",
        "argomento": "Pesca",
        "immagine": "",
        "ris_2": "V",
        "risposta_2": "è vietato dal tramonto al sorgere del sole.",
        "id": 1443,
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_1": "F",
        "domanda": "L'esercizio della pesca subacquea è vietato"
    },
    {
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_3": "F",
        "ris_2": "F",
        "risposta_2": "la prescritta bandiera rossa può anche non essere issata.",
        "argomento": "Pesca",
        "ris_1": "V",
        "id": 1444,
        "progressivo": "1.8.2-31",
        "risposta_1": "deve essere issata sul mezzo stesso.",
        "risposta_3": "la prescritta bandiera rossa può essere custodita nel gavone di prora.",
        "immagine": "",
        "domanda": "Se il subacqueo è accompagnato da un mezzo nautico di appoggio, la prescritta bandiera rossa con striscia diagonale bianca:"
    },
    {
        "id": 1445,
        "domanda": "Il subacqueo in immersione ha l'obbligo di segnalarsi con un galleggiante recante:",
        "ris_3": "V",
        "ris_2": "F",
        "risposta_3": "una bandiera rossa con striscia diagonale bianca, visibile ad una distanza non inferiore a 300 metri.",
        "ris_1": "F",
        "risposta_2": "una bandiera bianca con striscia diagonale rossa, visibile ad una distanza non inferiore a 30 metri.",
        "progressivo": "1.8.2-32",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "argomento": "Pesca",
        "immagine": "",
        "risposta_1": "una bandiera a scacchi rossa e bianca, visibile ad una distanza non superiore a 300 metri."
    },
    {
        "ris_3": "F",
        "argomento": "Pesca",
        "immagine": "",
        "risposta_1": "è vietato tenere il fucile subacqueo in posizione di armamento se non in immersione.",
        "domanda": "Avuto riguardo alla disciplina della pesca subacquea, quale tra queste affermazioni è corretta?",
        "risposta_2": "il subacqueo ha la facoltà di decidere quando tenere armato il fucile subacqueo prima dell'immersione.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "id": 1446,
        "ris_1": "V",
        "risposta_3": "è consentito tenere il fucile subacqueo in posizione di armamento anche se non in immersione.",
        "ris_2": "F",
        "progressivo": "1.8.2-33"
    },
    {
        "ris_2": "F",
        "risposta_3": "consentito a distanza inferiore a 50 metri dalle navi ancorate fuori dai porti.",
        "argomento": "Pesca",
        "immagine": "",
        "domanda": "L'esercizio della pesca subacquea è:",
        "ris_3": "F",
        "ris_1": "V",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "id": 1447,
        "progressivo": "1.8.2-34",
        "risposta_1": "vietato a distanza inferiore a 100 metri dalle navi ancorate fuori dai porti.",
        "risposta_2": "consentito entro i 100 metri dalle navi ancorate fuori dai porti."
    },
    {
        "risposta_3": "consentito entro i 100 metri dagli impianti fissi da pesca e dalle reti da posta.",
        "risposta_1": "consentito a distanza inferiore a 50 metri dagli impianti fissi da pesca e dalle reti da posta.",
        "ris_3": "F",
        "argomento": "Pesca",
        "ris_1": "F",
        "immagine": "",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "id": 1448,
        "ris_2": "V",
        "domanda": "L'esercizio della pesca subacquea è:",
        "progressivo": "1.8.2-35",
        "risposta_2": "vietato a distanza inferiore a 100 metri dagli impianti fissi da pesca e dalle reti da posta."
    },
    {
        "ris_2": "V",
        "risposta_2": "viene esercitata mediante l'utilizzo di unità da diporto solo a scopo ricreativo o agonistico.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "domanda": "Avuto riguardo alla disciplina della pesca sportiva, quale tra queste affermazioni è corretta?",
        "ris_3": "F",
        "ris_1": "F",
        "argomento": "Pesca",
        "immagine": "",
        "id": 1449,
        "progressivo": "1.8.2-36",
        "risposta_1": "può essere esercitata mediante l'utilizzo di unità navali da diporto di lunghezza superiore ai 15 metri.",
        "risposta_3": "viene esercitata esclusivamente mediante l'utilizzo di unità da diporto iscritte nei registri."
    },
    {
        "argomento": "Irregolarità, piccole avarie e modo di rimediarvi",
        "risposta_2": "gli iniettori sono fuori taratura.",
        "ris_3": "F",
        "id": 145,
        "risposta_3": "presenza di incrostazioni o anomalie nel circuito di raffreddamento.",
        "domanda": "Se il motore picchia in testa, le cause dirette possono essere:",
        "progressivo": "1.2.2-2",
        "ris_1": "F",
        "capitolo": "MOTORI",
        "ris_2": "V",
        "immagine": "",
        "risposta_1": "il sistema di iniezione è otturato."
    },
    {
        "ris_2": "F",
        "ris_3": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_3": "scopo ricreativo o agonistico. Sono consentiti la vendita ed il commercio dei prodotti di tale tipo di pesca solo per quantitativi inferiori, per ciascuna specie, a 5 Kg.",
        "progressivo": "1.8.2-37",
        "risposta_1": "a scopo ricreativo o agonistico. Sono vietati, sotto qualsiasi forma, la vendita ed il commercio dei prodotti di tale tipo di pesca.",
        "id": 1450,
        "risposta_2": "a scopo ricreativo e propedeutico alla successiva vendita e commercio dei prodotti catturati.",
        "domanda": "La pesca sportiva è l'attività esercitata:",
        "argomento": "Pesca",
        "immagine": "",
        "ris_1": "V"
    },
    {
        "ris_3": "F",
        "ris_1": "F",
        "risposta_1": "solo i bolentini a tre ami.",
        "immagine": "",
        "id": 1451,
        "risposta_3": "solo le canne a non piú di 10 ami.",
        "argomento": "Pesca",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "progressivo": "1.8.2-38",
        "ris_2": "V",
        "risposta_2": "canne a non piú di tre ami, lenze morte, bolentini, correntine a non piú di sei ami e lenze per cefalopodi.",
        "domanda": "Le lenze fisse consentite nell'ambito della pesca sportiva sono :"
    },
    {
        "domanda": "Le manifestazioni e le gare di pesca sportiva sono subordinate:",
        "risposta_2": "all'approvazione del Capo del Compartimento marittimo che emana un'apposita ordinanza.",
        "ris_3": "F",
        "ris_1": "F",
        "immagine": "",
        "argomento": "Pesca",
        "id": 1452,
        "risposta_1": "all'approvazione dell'autorità comunale che emana un'apposita autorizzazione.",
        "progressivo": "1.8.2-39",
        "risposta_3": "all'approvazione dell'autorità prefettizia che emana un'apposita autorizzazione.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_2": "V"
    },
    {
        "risposta_1": "pesci, molluschi e crostacei in quantità superiore a 5 kg complessivi salvo il caso di pesce singolo di peso superiore e non può catturare giornalmente piu' di un esemplare di cernia.",
        "risposta_3": "molluschi e crostacei in quantità superiore a 10 kg.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_2": "pesci, molluschi e crostacei in quantità superiore a 8 kg complessivi salvo il caso di pesce singolo di peso superiore e non può catturare giornalmente più di due esemplari di cernia.",
        "progressivo": "1.8.2-40",
        "id": 1453,
        "ris_1": "V",
        "immagine": "",
        "domanda": "Il pescatore sportivo giornalmente non può catturare:",
        "argomento": "Pesca",
        "ris_3": "F",
        "ris_2": "F"
    },
    {
        "id": 1454,
        "risposta_2": "si, ma solo da novembre a febbraio.",
        "domanda": "Gli apparecchi d respirazione (bombole) possono essere utilizzate per la pesca subacquea?",
        "risposta_1": "assolutamente no.",
        "ris_2": "F",
        "progressivo": "1.8.2-41",
        "ris_3": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_1": "V",
        "immagine": "",
        "risposta_3": "si.",
        "argomento": "Pesca"
    },
    {
        "risposta_3": "è vietata.",
        "argomento": "Pesca",
        "risposta_1": "è consentita.",
        "id": 1455,
        "progressivo": "1.8.2-42",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "immagine": "",
        "ris_1": "F",
        "ris_2": "F",
        "ris_3": "V",
        "domanda": "La commercializzazione del tonno rosso catturato in qualità di pescatore sportivo:",
        "risposta_2": "è possibile a seguito di comunicazione alla Capitaneria."
    },
    {
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "argomento": "Pesca",
        "risposta_3": 1,
        "ris_2": "F",
        "immagine": "",
        "ris_1": "F",
        "progressivo": "1.8.2-43",
        "id": 1456,
        "domanda": "Qual è il limite di esemplari di tonno rosso che possono essere pescati e detenuti a bordo?",
        "ris_3": "V",
        "risposta_2": 2,
        "risposta_1": "non ci sono limiti per la pesca sportiva."
    },
    {
        "ris_3": "F",
        "risposta_1": "informare senza indugio le unità navali presenti nelle vicinanze.",
        "argomento": "Norme ambientali",
        "risposta_2": "informare senza indugio l'autorità marittima più vicina al luogo del sinistro.",
        "progressivo": "1.8.2-44",
        "id": 1457,
        "ris_1": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "domanda": "Nel caso di avaria o incidente occorso alla propria unità, da cui possa derivare uno sversamento di idrocarburi, il comandante deve:",
        "immagine": "",
        "risposta_3": "informare senza indugio l'autorità comunale più vicina al luogo del sinistro.",
        "ris_2": "V"
    },
    {
        "ris_3": "V",
        "risposta_1": "soggetto ad ammonizione.",
        "ris_1": "F",
        "domanda": "Chiunque navighi a motore  all'interno di un'area marina protetta non adeguatamente segnalata dalle previste boe perimetrali, non essendo a conoscenza dei vincoli relativi al tale area è.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_2": "soggetto ad una sanzione penale.",
        "id": 1458,
        "risposta_3": "soggetto a una sanzione amministrativa.",
        "argomento": "Norme ambientali",
        "immagine": "",
        "progressivo": "1.8.2-45",
        "ris_2": "F"
    },
    {
        "ris_2": "F",
        "risposta_3": "le Capitanerie di porto, nonché le polizie degli enti locali delegati nella gestione delle medesime aree protette.",
        "ris_3": "V",
        "ris_1": "F",
        "risposta_2": "le autorità doganali nonché quelle prefettizie.",
        "risposta_1": "gli ispettorati portuali e le agenzie del demanio.",
        "progressivo": "1.8.2-46",
        "id": 1459,
        "domanda": "Quali sono le autorità preposte alla sorveglianza delle aree marine protette?",
        "immagine": "",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "argomento": "Norme ambientali"
    },
    {
        "immagine": "",
        "risposta_3": "vi è presenza di aria nel circuito del carburante.",
        "id": 146,
        "risposta_1": "il motorino di avviamento è andato in cortocircuito.",
        "ris_3": "V",
        "ris_1": "F",
        "domanda": "Se girando la chiave d'avviamento il motore diesel gira ma non parte, le cause dirette possono essere:",
        "argomento": "Irregolarità, piccole avarie e modo di rimediarvi",
        "capitolo": "MOTORI",
        "ris_2": "F",
        "risposta_2": "l'anticipo dell'accensione o dell'iniezione è sfasato.",
        "progressivo": "1.2.2-3"
    },
    {
        "ris_1": "V",
        "risposta_1": "istituire, nelle zone \"B\" (di riserva generale) o \"C\" (di riserva parziale), campi boa e campi di ormeggio attrezzati, anche con l'impiego di tecnologie informatiche e telematiche.",
        "risposta_3": "istituire, nelle zone \"A\", gare di motonautica.",
        "progressivo": "1.8.2-47",
        "domanda": "Gli enti gestori delle Aree marine protette possono, nelle zone \"B\" (di riserva generale) o \"C\" (di riserva parziale) di una Area marina protetta, istituire:",
        "id": 1460,
        "risposta_2": "istituire, nelle zone \"A\", gare di pesca.",
        "argomento": "Norme ambientali",
        "ris_3": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "immagine": "",
        "ris_2": "F"
    },
    {
        "id": 1461,
        "risposta_3": "una quota pari al 15% degli ormeggi è riservata alle navi da diporto a propulsione ibrida.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_2": "una quota pari al 15% degli ormeggi è riservata alle unità da pesca.",
        "ris_1": "V",
        "immagine": "",
        "argomento": "Norme ambientali",
        "domanda": "Nell'ambito dei campi boa e dei campi di ormeggio delle Aree marine protette:",
        "progressivo": "1.8.2-48",
        "risposta_1": "una quota pari al 15% degli ormeggi è riservata alle unità a vela.",
        "ris_2": "F",
        "ris_3": "F"
    },
    {
        "ris_2": "F",
        "id": 1462,
        "ris_3": "F",
        "immagine": "",
        "risposta_2": "è consentita solo previa comunicazione all'Autorità marittima.",
        "argomento": "Norme ambientali",
        "progressivo": "1.8.2-49",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_1": "V",
        "risposta_1": "non è consentita.",
        "domanda": "Generalmente, in zona \"A\" delle Aree Marine Protette la navigazione:",
        "risposta_3": "è consentita."
    },
    {
        "domanda": "Generalmente, in zona B delle Aree Marine Protette la navigazione:",
        "risposta_3": "è disciplinata dal decreto istitutivo e dal regolamento di gestione.",
        "argomento": "Norme ambientali",
        "risposta_1": "è sempre consentita.",
        "risposta_2": "non è mai consentita.",
        "ris_3": "V",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_1": "F",
        "ris_2": "F",
        "immagine": "",
        "progressivo": "1.8.2-50",
        "id": 1463
    },
    {
        "id": 1464,
        "domanda": "La dispersione in mare di 5 chili di olio usato (quantità tipo di un motore da 115 HP fuoribordo):",
        "immagine": "",
        "argomento": "Norme ambientali",
        "ris_2": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "progressivo": "1.8.2-51",
        "risposta_2": "è possibile solo in alto mare, oltre le 12 miglia dalla costa.",
        "ris_3": "F",
        "risposta_1": "è assolutamente vietata ed è capace di inquinare una superficie grande una volta e mezzo un campo da calcio.",
        "risposta_3": "è possibile solo in alto mare, oltre le 12 miglia dalla costa, su autorizzazione dell'Autorità marittima.",
        "ris_1": "V"
    },
    {
        "argomento": "Norme ambientali",
        "risposta_2": "è consentito spararli per esercitazione.",
        "risposta_3": "è consentito utilizzarli per uso privato, ma solo in occasione del Capodanno.",
        "ris_1": "V",
        "ris_2": "F",
        "domanda": "I segnali di soccorso scaduti (razzi-fuochi a mano-boette fumogene):",
        "risposta_1": "devono essere conferiti al rivenditore nel momento della loro sostituzione.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "progressivo": "1.8.2-52",
        "immagine": "",
        "ris_3": "F",
        "id": 1465
    },
    {
        "risposta_3": "completamente interdette alla navigazione.",
        "domanda": "In generale, le aree marine protette sono:",
        "risposta_1": "disciplinate con norme che riguardano la pesca, ma non la navigazione e l'ancoraggio.",
        "ris_2": "V",
        "immagine": "",
        "argomento": "Norme ambientali",
        "ris_1": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "progressivo": "1.8.2-53",
        "id": 1466,
        "risposta_2": "suddivise in tre zone di tutela denominate A-B-C, alcune hanno una ulteriore zona D.",
        "ris_3": "F"
    },
    {
        "ris_2": "V",
        "risposta_3": "individuate dall'Ordinanza balneare della Capitaneria di porto.",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "progressivo": "1.8.2-54",
        "ris_1": "F",
        "ris_3": "F",
        "risposta_1": "disciplinate dal posizionamento di boe.",
        "risposta_2": "delimitate da coordinate geografiche e riportate nella cartografia allegata al Decreto istitutivo pubblicato in Gazzetta Ufficiale.",
        "argomento": "Norme ambientali",
        "id": 1467,
        "immagine": "",
        "domanda": "Le zone in cui è suddivisa un'Area Marina Protetta sono:"
    },
    {
        "domanda": "Generalmente, in zona A delle Aree Marine Protette l'ancoraggio:",
        "ris_2": "F",
        "immagine": "",
        "id": 1468,
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_3": "V",
        "argomento": "Norme ambientali",
        "risposta_1": "è consentito solo previa comunicazione all'Autorità marittima.",
        "risposta_2": "è consentita.",
        "risposta_3": "non è consentita.",
        "ris_1": "F",
        "progressivo": "1.8.2-55"
    },
    {
        "domanda": "L’interruzione immediata e definitiva delle catture di tonno rosso a scopo sportivo-ricrerativo:",
        "ris_1": "F",
        "ris_3": "F",
        "risposta_1": "è fissata ciascun anno per il successivo dalle Capitanerie di porto competenti",
        "immagine": "",
        "ris_2": "V",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_3": "non è prevista, poiché vi sono delle quote assegnate.",
        "id": 1469,
        "argomento": "Norme ambientali",
        "risposta_2": "è stabilita con decreto del Ministero competente al raggiungimento della quota di pesca assegnata all'Italia.",
        "progressivo": "1.8.2-56"
    },
    {
        "domanda": "Il motore dell'unità è in marcia quando si ferma all’improvviso; le cause dirette possono essere:",
        "ris_1": "F",
        "ris_3": "V",
        "progressivo": "1.2.2-4",
        "capitolo": "MOTORI",
        "risposta_1": "il motorino di avviamento è difettoso (motore a scoppio).",
        "risposta_2": "la presa a mare è chiusa o intasata.",
        "immagine": "",
        "id": 147,
        "argomento": "Irregolarità, piccole avarie e modo di rimediarvi",
        "ris_2": "F",
        "risposta_3": "l'asse portaelica si è bloccato con l’invertitore ingranato."
    },
    {
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "progressivo": "1.8.2-57",
        "ris_1": "F",
        "argomento": "Norme ambientali",
        "risposta_3": "vige il divieto di accesso per qualsiasi tipo di unità.",
        "risposta_1": "sono vietate le immersioni subacquee.",
        "risposta_2": "è consentita la navigazione a remi e a vela.",
        "ris_3": "F",
        "id": 1470,
        "domanda": "Nelle aree marine protette in zona B (riserva generale):",
        "immagine": "",
        "ris_2": "V"
    },
    {
        "domanda": "I contenitori di plastica abbandonata in mare:",
        "ris_2": "F",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "ris_1": "V",
        "ris_3": "F",
        "immagine": "",
        "id": 1471,
        "risposta_3": "perdurano anche per 5 anni.",
        "risposta_1": "perdurano anche fino a 450 anni.",
        "argomento": "Norme ambientali",
        "risposta_2": "perdurano anche per 10 anni.",
        "progressivo": "1.8.2-58"
    },
    {
        "progressivo": "1.8.2-59",
        "ris_3": "V",
        "ris_1": "F",
        "risposta_3": "nei campi boe l'ancoraggio non è mai consentito.",
        "ris_2": "F",
        "risposta_1": "nei campi boe l'ancoraggio è consentito dall'alba al tramonto.",
        "immagine": "",
        "capitolo": "NORMATIVA DIPORTISTICA E AMBIENTALE",
        "risposta_2": "nei campi boe l'ancoraggio è consentito solo se c'è sufficiente spazio di manovra.",
        "domanda": "Nelle aree marine protette dove l'ormeggio è regolamentato tramite campi boe:",
        "id": 1472,
        "argomento": "Norme ambientali"
    },
    {
        "ris_3": "V",
        "risposta_1": "l'elica si è rotta.",
        "risposta_3": "l'elica si è bloccata.",
        "argomento": "Irregolarità, piccole avarie e modo di rimediarvi",
        "ris_2": "F",
        "capitolo": "MOTORI",
        "progressivo": "1.2.2-5",
        "id": 148,
        "ris_1": "F",
        "immagine": "",
        "domanda": "Il motore, se in folle, rimane acceso e, se in marcia, si ferma. Perchè?",
        "risposta_2": "il carburatore è sporco."
    },
    {
        "immagine": "",
        "ris_3": "V",
        "capitolo": "MOTORI",
        "risposta_2": "il carburatore o gli iniettori sono sporchi.",
        "progressivo": "1.2.2-6",
        "ris_1": "F",
        "id": 149,
        "risposta_3": "le batterie sono completamente scariche.",
        "argomento": "Irregolarità, piccole avarie e modo di rimediarvi",
        "ris_2": "F",
        "domanda": "Il motore entrobordo non si mette in moto e le luci sul pannello si spengono al momento dell'avviamento: la causa potrebbe essere:",
        "risposta_1": "presenza di acqua nel circuito di alimentazione."
    },
    {
        "risposta_1": "sezione maestra.",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_2": "V",
        "progressivo": "1.1.1-15",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "domanda": "La parte emersa dell'unità è denominata:",
        "id": 15,
        "risposta_2": "opera morta.",
        "immagine": "",
        "risposta_3": "opera viva.",
        "ris_3": "F",
        "ris_1": "F"
    },
    {
        "risposta_3": "la linea che divide l’opera viva e l’opera morta.",
        "argomento": "Irregolarità, piccole avarie e modo di rimediarvi",
        "ris_1": "F",
        "domanda": "La linea d’asse è:",
        "progressivo": "1.2.2-7",
        "risposta_2": "un insieme di organi meccanici che trasmette il movimento all'elica.",
        "risposta_1": "quella linea longitudinale solitamente chiamata carena.",
        "capitolo": "MOTORI",
        "ris_3": "F",
        "id": 150,
        "immagine": "",
        "ris_2": "V"
    },
    {
        "risposta_1": "presenza di aria nella pompa di iniezione.",
        "domanda": "Il motore diesel si spegne subito dopo l'accensione; la causa può essere:",
        "risposta_2": "i cilindri sono ovalizzati.",
        "capitolo": "MOTORI",
        "ris_3": "F",
        "immagine": "",
        "risposta_3": "il combustibile è di qualità scadente.",
        "ris_1": "V",
        "id": 151,
        "ris_2": "F",
        "progressivo": "1.2.2-8",
        "argomento": "Irregolarità, piccole avarie e modo di rimediarvi"
    },
    {
        "risposta_1": "l'elica è parzialmente frenata.",
        "capitolo": "MOTORI",
        "progressivo": "1.2.2-9",
        "immagine": "",
        "domanda": "Quale può essere la causa più probabile in base alla quale un motore entrobordo emette fumo azzurro dallo scarico?",
        "ris_3": "V",
        "id": 152,
        "ris_2": "F",
        "argomento": "Irregolarità, piccole avarie e modo di rimediarvi",
        "ris_1": "F",
        "risposta_3": "l'olio lubrificante è entrato nella camera di scoppio.",
        "risposta_2": "c'è acqua nel circuito di alimentazione."
    },
    {
        "risposta_1": "i cilindri sono ovalizzati.",
        "progressivo": "1.2.2-10",
        "id": 153,
        "ris_2": "V",
        "argomento": "Irregolarità, piccole avarie e modo di rimediarvi",
        "risposta_2": "carburante sporco, filtro aria o filtro carburante sporchi, carburatore sporco o danneggiato.",
        "capitolo": "MOTORI",
        "immagine": "",
        "risposta_3": "la pressione dell'olio è troppo elevata.",
        "ris_3": "F",
        "ris_1": "F",
        "domanda": "Quale può essere la causa più probabile in base alla quale un motore entrobordo emette fumo nero dallo scarico?"
    },
    {
        "ris_2": "F",
        "domanda": "Qual è la funzione dell’iniettore in un motore diesel?",
        "immagine": "",
        "risposta_1": "nebulizzare il gasolio per farlo bruciare rapidamente.",
        "ris_3": "F",
        "risposta_3": "pescare il carburante dal serbatoio.",
        "risposta_2": "inviare corrente elettrica alle candele perchè scocchino la scintilla.",
        "ris_1": "V",
        "id": 154,
        "progressivo": "1.2.1-29",
        "capitolo": "MOTORI",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore"
    },
    {
        "ris_3": "F",
        "ris_2": "F",
        "risposta_2": "5%.",
        "risposta_1": "30%.",
        "immagine": "",
        "domanda": "Qual è l'ulteriore percentuale di carburante che è consigliabile da mantenere a bordo per garantire una navigazione in sicurezza?",
        "id": 155,
        "progressivo": "1.2.3-1",
        "argomento": "Calcolo dell'autonomia",
        "ris_1": "V",
        "risposta_3": "percentuale variabile a seconda della densità del carburante.",
        "capitolo": "MOTORI"
    },
    {
        "ris_3": "F",
        "progressivo": "1.2.2-2",
        "risposta_2": "almeno 130 litri.",
        "ris_1": "F",
        "risposta_3": "almeno 140 litri.",
        "capitolo": "MOTORI",
        "ris_2": "V",
        "argomento": "Calcolo dell'autonomia",
        "risposta_1": "almeno 120 litri.",
        "id": 156,
        "domanda": "Quanto carburante devo avere a bordo, incluso l'incremento del 30% di sicurezza, per percorrere 10 miglia alla velocità di 5 nodi, sapendo che il consumo orario è di 50 litri/ora?",
        "immagine": ""
    },
    {
        "ris_3": "F",
        "id": 157,
        "immagine": "",
        "argomento": "Calcolo dell'autonomia",
        "progressivo": "1.2.3-2",
        "risposta_1": "32 litri orari.",
        "ris_2": "F",
        "domanda": "Un motore fuoribordo 2 tempi a scoppio consuma 300 gr. per ogni cavallo per ogni ora di moto. Ipotizzando un peso specifico di 0,75 Kg ogni litro, a quanto ammonta il consumo medio di carburante del suddetto motore fuoribordo 2 tempi a scoppio di 80 Hp?",
        "capitolo": "MOTORI",
        "ris_1": "V",
        "risposta_2": "22 litri orari.",
        "risposta_3": "38 litri orari."
    },
    {
        "id": 158,
        "progressivo": "1.2.2-3",
        "immagine": "",
        "ris_2": "F",
        "argomento": "Calcolo dell'autonomia",
        "risposta_2": "il consumo specifico di un motore di un'unità da diporto va calcolato in grammi (o litri) al secondo.",
        "risposta_1": "un motore allo stesso regime eroga sempre una potenza diversa.",
        "ris_3": "V",
        "domanda": "Relativamente all'autonomia di navigazione di un'imbarcazione da diporto, è possibile affermare che:",
        "risposta_3": "se non è noto il consumo orario, si può calcolare l'autonomia oraria conoscendo  la potenza HP erogata e il peso specifico del carburante impiegato.",
        "capitolo": "MOTORI",
        "ris_1": "F"
    },
    {
        "capitolo": "MOTORI",
        "argomento": "Calcolo dell'autonomia",
        "ris_1": "F",
        "risposta_3": "per calcolare l'autonomia oraria bisogna conoscere l'autonomia in miglia.",
        "immagine": "",
        "domanda": "Circa i consumi e all'autonomia di navigazione, si può affermare che:",
        "risposta_2": "con mare mosso, a parità di velocità diminuisce l'autonomia in miglia.",
        "id": 159,
        "ris_2": "V",
        "ris_3": "F",
        "progressivo": "1.2.3-3",
        "risposta_1": "autonomia oraria = consumo orario : carburante disponibile."
    },
    {
        "risposta_3": "bordo libero.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "risposta_1": "opera viva.",
        "risposta_2": "opera morta.",
        "progressivo": "1.1.1-16",
        "ris_2": "F",
        "id": 16,
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_3": "F",
        "ris_1": "V",
        "domanda": "La parte sommersa dell'unità è denominata:",
        "immagine": ""
    },
    {
        "domanda": "Quale calcolo della quantità di carburante più riserva (S = spazio, V = velocità, C = consumo l/h, RIS = riserva, Q = quantità carburante in litri) è corretta?",
        "risposta_3": "S = 8 Miglia     C = 20 litri/h V = 15 Nodi   Q = 25 litri.",
        "risposta_2": "S = 15 Miglia   C = 15 litri/h V = 15 Nodi   Q = 19,5 litri.",
        "capitolo": "MOTORI",
        "id": 160,
        "immagine": "",
        "ris_2": "V",
        "ris_3": "F",
        "argomento": "Calcolo dell'autonomia",
        "progressivo": "1.2.2-4",
        "risposta_1": "S = 20 Miglia   C = 20 litri/h V = 10 Nodi   Q = 26 litri.",
        "ris_1": "F"
    },
    {
        "argomento": "Calcolo dell'autonomia",
        "risposta_3": "S = 10 Miglia   C = 10 litri/h V = 10 Nodi   Q = 13,0 litri.",
        "capitolo": "MOTORI",
        "ris_2": "F",
        "risposta_2": "S = 4 Miglia     C = 10 litri/h V = 7 Nodi     Q = 15 litri.",
        "immagine": "",
        "ris_1": "F",
        "risposta_1": "S = 10 Miglia   C = 15 litri/h V = 15 Nodi   Q = 19,5 litri.",
        "domanda": "Quale calcolo della quantità di carburante incluso l'incremento del 30% di sicurezza (S = spazio, V = velocità, C = consumo l/h, RIS = riserva, Q = quantità carburante in litri) è corretto?",
        "progressivo": "1.2.3-4",
        "id": 161,
        "ris_3": "V"
    },
    {
        "ris_3": "V",
        "risposta_1": "corrisponde al calcolo di miglia nautiche percorse in relazione alla quantità di carburante erogata dal motore.",
        "immagine": "",
        "argomento": "Calcolo dell'autonomia",
        "capitolo": "MOTORI",
        "domanda": "Il dato del consumo di un motore:",
        "progressivo": "1.2.2-5",
        "risposta_2": "è indicato sulla licenza di navigazione nel caso di unità da diporto che non\nsiano immatricolate.",
        "ris_1": "F",
        "risposta_3": "corrisponde al consumo litri orario a potenza massima erogata dal motore dell'unità da diporto.",
        "id": 162,
        "ris_2": "F"
    },
    {
        "ris_2": "F",
        "risposta_1": "moltiplicando il consumo orario per la durata della navigazione effettivamente svolta (consumo x tempo).",
        "ris_3": "F",
        "risposta_3": "dividendo il consumo orario per la durata della navigazione effettivamente svolta (consumo : tempo).",
        "id": 163,
        "risposta_2": "moltiplicando la distanza per il consumo orario (spazio x\n…. litri/ora).",
        "argomento": "Calcolo dell'autonomia",
        "ris_1": "V",
        "capitolo": "MOTORI",
        "domanda": "Il calcolo del consumo di carburante si effettua:",
        "immagine": "",
        "progressivo": "1.2.3-5"
    },
    {
        "ris_2": "F",
        "ris_3": "F",
        "argomento": "Calcolo dell'autonomia",
        "risposta_2": "dell’avanzamento ridotto dell’elica rispetto al passo.",
        "ris_1": "V",
        "immagine": "",
        "capitolo": "MOTORI",
        "risposta_1": "di eventuali elementi perturbatori del moto (vento e/o corrente).",
        "domanda": "Calcolato il  consumo teorico per una certa navigazione, secondo una buona regola marinara si aggiunge almeno il 30% a causa:",
        "risposta_3": "del maggior consumo del motore, di qualsiasi tipo, rispetto a quello pubblicizzato dal costruttore.",
        "progressivo": "1.2.2-6",
        "id": 164
    },
    {
        "risposta_1": "non possiamo calcolarla senza sapere la velocità dell'unità.",
        "argomento": "Calcolo dell'autonomia",
        "ris_2": "F",
        "id": 165,
        "risposta_3": "complessivamente di 90 minuti ma, considerando il 30% di incremento, diventano circa 69 minuti.",
        "risposta_2": "di 1 ora e 15 minuti.",
        "ris_3": "V",
        "immagine": "",
        "capitolo": "MOTORI",
        "domanda": "Con 30 litri di carburante e un consumo orario di 20 litri, l'autonomia di navigazione, considerando l'incremento del 30% di sicurezza, sarà di:",
        "ris_1": "F",
        "progressivo": "1.2.3-6"
    },
    {
        "progressivo": "1.2.2-7",
        "risposta_2": "120 litri.",
        "risposta_3": "312 litri.",
        "immagine": "",
        "ris_1": "F",
        "risposta_1": "240 litri.",
        "capitolo": "MOTORI",
        "ris_2": "F",
        "ris_3": "V",
        "id": 166,
        "domanda": "Determinare, con la dovuta approssimazione, la quantità di carburante (comprensiva del 30% relativa alla riserva) necessaria ad un’unità navale da diporto per compiere 150 miglia nautiche in sicurezza, conoscendone il consumo orario (40 l/h) e la velocità di crociera (25 nodi).",
        "argomento": "Calcolo dell'autonomia"
    },
    {
        "ris_1": "F",
        "argomento": "Calcolo dell'autonomia",
        "risposta_2": "242 litri.",
        "id": 167,
        "progressivo": "1.2.3-7",
        "domanda": "Determinare, con la dovuta approssimazione, la quantità di carburante (comprensiva del 30% relativa alla riserva) necessaria ad un’unità navale da diporto per compiere 180 miglia nautiche in sicurezza, conoscendone il consumo orario (31 l/h) e la velocità di crociera (30 nodi).",
        "immagine": "",
        "risposta_3": "372 litri.",
        "ris_3": "F",
        "ris_2": "V",
        "capitolo": "MOTORI",
        "risposta_1": "186 litri."
    },
    {
        "risposta_3": "il consumo orario per la velocità (nodi) e aggiungere il 30%.",
        "domanda": "Per calcolare correttamente la quantità di carburante da imbarcare sulla  mia unità devo moltiplicare:",
        "id": 168,
        "ris_3": "F",
        "ris_1": "V",
        "immagine": "",
        "progressivo": "1.2.2-8",
        "risposta_1": "il consumo orario per le ore di navigazione e aggiungere il 30%.",
        "argomento": "Calcolo dell'autonomia",
        "ris_2": "F",
        "capitolo": "MOTORI",
        "risposta_2": "il consumo orario per le miglia da percorrere e aggiungere il 30%."
    },
    {
        "id": 169,
        "risposta_2": "1,34 Cv",
        "ris_1": "F",
        "ris_2": "F",
        "risposta_3": "1,36 Cv",
        "capitolo": "MOTORI",
        "progressivo": "1.2.1-30",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "risposta_1": "1,43 Cv",
        "immagine": "",
        "ris_3": "V",
        "domanda": "1 Kw equivale a:"
    },
    {
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_3": "un appiglio per rinviare e/o dare volta al cavo di ormeggio oppure ad una cima di bordo (come drizze/scotte).",
        "immagine": "",
        "id": 17,
        "risposta_2": "un sistema per tendere le draglie.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "ris_3": "V",
        "progressivo": "1.1.1-17",
        "domanda": "La \"galloccia\" è:",
        "ris_1": "F",
        "risposta_1": "un foro per l'uscita dell'acqua dal pozzetto.",
        "ris_2": "F"
    },
    {
        "capitolo": "MOTORI",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "progressivo": "1.2.1-31",
        "immagine": "",
        "domanda": "Quali sono le parti principali di cui è composta l'elica?",
        "risposta_2": "alesaggio, corsa, fusto e diamante.",
        "ris_2": "F",
        "ris_3": "V",
        "risposta_3": "mozzo e pale.",
        "ris_1": "F",
        "id": 170,
        "risposta_1": "perno, superficie, stondatura ed inclinazione."
    },
    {
        "immagine": "",
        "risposta_3": "tipo di combustione esterna.",
        "domanda": "Quale caratteristica offre il carburante diesel rispetto alla benzina?",
        "ris_1": "V",
        "ris_2": "F",
        "id": 171,
        "risposta_1": "più elevato punto di infiammabilità.",
        "ris_3": "F",
        "progressivo": "1.2.1-32",
        "capitolo": "MOTORI",
        "risposta_2": "eliminazione del rischio di accensioni o di esplosioni accidentali.",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore"
    },
    {
        "ris_2": "F",
        "id": 172,
        "capitolo": "MOTORI",
        "ris_1": "F",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "progressivo": "1.2.1-33",
        "risposta_2": "accensione, lubrificazione, rotazione, riavviamento.",
        "ris_3": "V",
        "immagine": "",
        "risposta_1": "cablaggio, alesaggio, corsa, arresto.",
        "domanda": "Quali sono le fasi di un motore a combustione interna a quattro tempi?",
        "risposta_3": "aspirazione, compressione, scoppio, scarico."
    },
    {
        "domanda": "Di quali materiali possono essere realizzate le eliche dei motori fuoribordo?",
        "ris_1": "F",
        "risposta_3": "ghisa, teflon, zinco.",
        "immagine": "",
        "capitolo": "MOTORI",
        "ris_2": "V",
        "ris_3": "F",
        "risposta_2": "alluminio, acciaio inox, composito.",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "id": 173,
        "progressivo": "1.2.1-34",
        "risposta_1": "polipropilene, legno, aipalon."
    },
    {
        "ris_2": "V",
        "domanda": "Qual è la caratteristica principale di un buon lubrificante per un motore diesel?",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "ris_1": "F",
        "ris_3": "F",
        "risposta_3": "alto contenuto di ottani oleosi.",
        "risposta_2": "viscosità o densità.",
        "progressivo": "1.2.1-35",
        "capitolo": "MOTORI",
        "id": 174,
        "immagine": "",
        "risposta_1": "punto di infiammabilità."
    },
    {
        "progressivo": "1.2.1-36",
        "risposta_2": "verificare il livello dell'acqua nel circuito di raffreddamento.",
        "immagine": "",
        "ris_2": "F",
        "risposta_3": "verificare la funzionalità della campana dell'idrogetto.",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "ris_1": "V",
        "ris_3": "F",
        "capitolo": "MOTORI",
        "risposta_1": "fatto raffreddare il motore, verificare il livello dell'olio ed eventualmente effettuare il rabbocco.",
        "id": 175,
        "domanda": "Quale intervento è opportuno effettuare dopo una lunga navigazione di un motore entrobordo?"
    },
    {
        "risposta_3": "Il trim tab.",
        "ris_3": "F",
        "capitolo": "MOTORI",
        "ris_2": "V",
        "immagine": 10,
        "domanda": "Quale parte del motore fuoribordo in figura indicano le frecce?",
        "risposta_2": "le prese dell'acqua di raffreddamento.",
        "ris_1": "F",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "id": 176,
        "progressivo": "1.2.1-37",
        "risposta_1": "lo scarico dell'acqua di raffreddamento."
    },
    {
        "risposta_2": "il connettore del tubo carburante.",
        "risposta_3": "la «spia», fuoriuscita di acqua che testimonia il corretto funzionamento del circuito di raffreddamento.",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "id": 177,
        "ris_3": "V",
        "immagine": 11,
        "progressivo": "1.2.1-38",
        "risposta_1": "la presa acqua di raffreddamento.",
        "capitolo": "MOTORI",
        "domanda": "Quale parte del motore fuoribordo in figura indica le freccia?",
        "ris_1": "F",
        "ris_2": "F"
    },
    {
        "risposta_2": "leva cambio (avanti/folle/indietro)",
        "id": 178,
        "ris_2": "F",
        "risposta_3": "elica.",
        "immagine": 12,
        "domanda": "Quale parte del motore fuoribordo in figura indica le freccia?",
        "progressivo": "1.2.1-39",
        "capitolo": "MOTORI",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "risposta_1": "piastra anticavitazione.",
        "ris_1": "F",
        "ris_3": "V"
    },
    {
        "ris_2": "F",
        "ris_3": "V",
        "risposta_3": "gruppo poppiero.",
        "domanda": "Quale parte della trasmissione entrofuoribordo è indicata dalla freccia?",
        "ris_1": "F",
        "risposta_1": "giunto cardanico.",
        "id": 179,
        "capitolo": "MOTORI",
        "risposta_2": "basamento motore.",
        "immagine": 13,
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "progressivo": "1.2.1-40"
    },
    {
        "risposta_2": "un tornichetto per tendere le draglie.",
        "risposta_1": "un foro per l'uscita dell'acqua dal pozzetto.",
        "capitolo": "TEORIA DELLO SCAFO",
        "domanda": "La bitta è:",
        "ris_1": "F",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "progressivo": "1.1.1-18",
        "ris_2": "F",
        "id": 18,
        "risposta_3": "bassa e robusta colonnetta, generalmenete con una testa a fungo, posta sulle banchine e sui ponti delle navi per legarvi le catene o i cavi di ormeggio;",
        "ris_3": "V",
        "immagine": ""
    },
    {
        "capitolo": "MOTORI",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "domanda": "Quale parte dello scafo è indicata dalla freccia?",
        "risposta_3": "paratia del vano motore.",
        "risposta_2": "gruppo poppiero.",
        "id": 180,
        "ris_2": "F",
        "ris_3": "V",
        "risposta_1": "trasmissione.",
        "progressivo": "1.2.1-41",
        "immagine": 14,
        "ris_1": "F"
    },
    {
        "domanda": "Quale parte degli organi di trasmissione di un motore entro bordo è indicata dalla freccia?",
        "ris_2": "F",
        "ris_3": "F",
        "risposta_2": "asse portaelica.",
        "immagine": 15,
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "progressivo": "1.2.1-42",
        "risposta_3": "giunto.",
        "id": 181,
        "capitolo": "MOTORI",
        "ris_1": "V",
        "risposta_1": "astuccio."
    },
    {
        "progressivo": "1.2.1-43",
        "domanda": "Quale parte degli organi di trasmissione di un motore entro bordo è indicata dalla freccia?",
        "capitolo": "MOTORI",
        "ris_2": "V",
        "ris_1": "F",
        "risposta_3": "giunto.",
        "risposta_2": "asse portaelica.",
        "ris_3": "F",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "immagine": 16,
        "risposta_1": "astuccio.",
        "id": 182
    },
    {
        "ris_1": "F",
        "risposta_3": "asse.",
        "risposta_1": "astuccio.",
        "ris_3": "F",
        "capitolo": "MOTORI",
        "ris_2": "V",
        "immagine": 17,
        "risposta_2": "invertitore/riduttore.",
        "progressivo": "1.2.1-44",
        "domanda": "Quale parte degli organi di trasmissione di un motore entro bordo è indicata dalla freccia?",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "id": 183
    },
    {
        "capitolo": "MOTORI",
        "risposta_1": "la propulsione è ottenuta mediante un getto d'acqua erogato ad alta velocità dalla prora dell'unità, attraverso un'apposita pompa.",
        "domanda": "Qual è il principio di funzionamento di un motore ad idrogetto?",
        "ris_1": "F",
        "risposta_3": "la propulsione è ottenuta mediante una miscela di acqua ed aria di raffreddamento attraverso una turbina alimentata dai gas di scarico.",
        "risposta_2": "la propulsione è ottenuta mediante un getto d'acqua erogato ad alta velocità dalla poppa dell'unità navale, attraverso un'apposita pompa azionata da un motore convenzionale.",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "immagine": "",
        "ris_2": "V",
        "ris_3": "F",
        "id": 184,
        "progressivo": "1.2.1-45"
    },
    {
        "ris_3": "F",
        "capitolo": "MOTORI",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "ris_1": "V",
        "id": 185,
        "immagine": "",
        "progressivo": "1.2.1-46",
        "risposta_1": "condotto di aspirazione, elica, condotto forzato e meccanismo di governo.",
        "risposta_2": "condotto di mandata, elica a passo variabile, condotto laterale e meccanismo di scarico.",
        "ris_2": "F",
        "risposta_3": "condotto di scarico, invertitore, marmitta di espansione e cablaggio elettrico.",
        "domanda": "Di quali parti principali si compone un sistema di propulsione ad idrogetto?"
    },
    {
        "risposta_2": "facilmente manovrabile anche al mimino dei giri e in condizione di vento.",
        "ris_1": "V",
        "ris_2": "F",
        "risposta_1": "difficilmente manovrabile al mimino dei giri e in condizione di vento.",
        "domanda": "Il sistema di propulsione ad idrogetto risulta:",
        "id": 186,
        "risposta_3": "difficilmente manovrabile alla velocità di crociera in assenza di vento.",
        "immagine": "",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "ris_3": "F",
        "progressivo": "1.2.1-47",
        "capitolo": "MOTORI"
    },
    {
        "ris_3": "F",
        "risposta_3": "necessita di un numero di iniettori inferiore rispetto a quello dei cilindri.",
        "risposta_2": "necessita di un numero di candele di scoppio doppio rispetto a quello dei cilindri.",
        "risposta_1": "necessita di un numero di iniettori pari a quello dei cilindri.",
        "capitolo": "MOTORI",
        "progressivo": "1.2.1-48",
        "domanda": "Relativamente a un motore diesel, quale affermazione è corretta?",
        "ris_1": "V",
        "id": 187,
        "ris_2": "F",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "immagine": ""
    },
    {
        "immagine": "",
        "progressivo": "1.2.1-49",
        "ris_1": "F",
        "risposta_1": "pompa di alimentazione, pompa di iniezione, carburatori.",
        "capitolo": "MOTORI",
        "ris_3": "F",
        "id": 188,
        "domanda": "Quali sono gli organi fondamentali che costituiscono l'impianto di alimentazione di un motore diesel?",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "ris_2": "V",
        "risposta_3": "pompa di alimentazione e pompa di aspirazione.",
        "risposta_2": "pompa di alimentazione, pompa di iniezione, iniettori."
    },
    {
        "capitolo": "MOTORI",
        "risposta_1": "candela di scoppio.",
        "ris_2": "V",
        "risposta_3": "filtro di aereazione.",
        "ris_3": "F",
        "domanda": "Cos'è necessario per garantire l'avviamento nei motori diesel ad iniezione indiretta?",
        "risposta_2": "candeletta a incandescenza.",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "ris_1": "F",
        "progressivo": "1.2.1-50",
        "id": 189,
        "immagine": ""
    },
    {
        "risposta_1": "il vano-ripostiglio, sia di prora sia di poppa.",
        "domanda": "Il gavone di un'imbarcazione da diporto è:",
        "id": 19,
        "risposta_2": "quella parte curva dello scafo prossima alla prora.",
        "capitolo": "TEORIA DELLO SCAFO",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "ris_2": "F",
        "risposta_3": "quella parte arrotondata dello scafo prima della poppa.",
        "immagine": "",
        "ris_1": "V",
        "ris_3": "F",
        "progressivo": "1.1.1-19"
    },
    {
        "risposta_2": "mediante l'azione di un motorino d'avviamento collegato all'impianto elettrico.",
        "id": 190,
        "ris_2": "V",
        "ris_1": "F",
        "risposta_1": "mediante l'azione di un gruppo generatore installato nella parte poppiera dell'unità.",
        "immagine": "",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "capitolo": "MOTORI",
        "ris_3": "F",
        "domanda": "Come avviene la messa in moto elettrica di un motore fuoribordo?",
        "risposta_3": "mediante l'azione di un motorino d'avviamento alimentato da energia elettrostatica.",
        "progressivo": "1.2.1-51"
    },
    {
        "argomento": "Irregolarità e piccole avarie che possono prevedere un intervento non specialistico",
        "ris_1": "F",
        "domanda": "Quali tra queste può essere una causa di surriscaldamento di un motore fuoribordo?",
        "ris_2": "F",
        "id": 191,
        "ris_3": "V",
        "immagine": "",
        "risposta_1": "malfunzionamento del circuito elettrico a causa del surriscaldamento della batteria.",
        "risposta_3": "ostruzione del flusso dell'acqua di raffreddamento dovuto, ad esempio, alla possibile presenza di alghe o frammenti di materiale plastico in corrispondenza della presa di aspirazione del circuito dell'acqua.",
        "risposta_2": "eccessiva usura del pignone del motorino di avviamento.",
        "progressivo": "1.2.2-11",
        "capitolo": "MOTORI"
    },
    {
        "id": 192,
        "ris_2": "F",
        "risposta_1": "verificare il collegamento degli anodi sacrificali.",
        "risposta_2": "verificare la temperatura dell'acqua del mare.",
        "risposta_3": "controllare che la leva delle marce sia in posizione di folle.",
        "argomento": "Irregolarità e piccole avarie che possono prevedere un intervento non specialistico",
        "ris_3": "V",
        "progressivo": "1.2.2-12",
        "immagine": "",
        "ris_1": "F",
        "domanda": "Quale tra queste verifiche è corretto eseguire nel caso in cui un motore fuoribordo presenti difficoltà di avviamento?",
        "capitolo": "MOTORI"
    },
    {
        "domanda": "Circa il fenomeno della cavitazione riferito a un motore fuoribordo, quale affermazione è corretta?",
        "ris_1": "V",
        "risposta_2": "può verificarsi a seguito della riduzione del numero di giri dell'elica.",
        "capitolo": "MOTORI",
        "argomento": "Irregolarità e piccole avarie che possono prevedere un intervento non specialistico",
        "progressivo": "1.2.2-13",
        "id": 193,
        "risposta_1": "può verificarsi quando la lunghezza del piede non risulta compatibile con l'altezza dello specchio di poppa, dell'unità navale su cui è installato.",
        "ris_2": "F",
        "ris_3": "F",
        "risposta_3": "può verificarsi a seguito dell'incremento graduale del numero di giri dell'elica.",
        "immagine": ""
    },
    {
        "risposta_1": "mancato afflusso di carburante, carburatore sporco o ingolfato, deterioramento delle candele.",
        "ris_1": "V",
        "ris_3": "F",
        "ris_2": "F",
        "progressivo": "1.2.2-14",
        "capitolo": "MOTORI",
        "risposta_2": "deterioramento delle candelette di preriscaldamento.",
        "immagine": "",
        "domanda": "Quali tra questi inconvenienti possono causare la mancata partenza di un motore a benzina?",
        "id": 194,
        "argomento": "Irregolarità e piccole avarie che possono prevedere un intervento non specialistico",
        "risposta_3": "insufficiente compressione,"
    },
    {
        "ris_1": "F",
        "ris_2": "V",
        "domanda": "Quali possono essere le cause che determinano l'emissione di fumo nero allo scarico di un motore a benzina?",
        "argomento": "Irregolarità e piccole avarie che possono prevedere un intervento non specialistico",
        "capitolo": "MOTORI",
        "risposta_2": "cattiva combustione e carburazione difettosa.",
        "risposta_1": "benzina con basso numero di ottani, contatti e candelette ossidati.",
        "ris_3": "F",
        "immagine": "",
        "id": 195,
        "risposta_3": "olio bruciato che penetra nei cilindri, candelette e pompa d'iniezione difettose.",
        "progressivo": "1.2.2-15"
    },
    {
        "capitolo": "MOTORI",
        "domanda": "Quali possono essere le cause per le quali un motore diesel gira ma non si avvia?",
        "argomento": "Irregolarità e piccole avarie che possono prevedere un intervento non specialistico",
        "progressivo": "1.2.2-16",
        "ris_1": "F",
        "immagine": "",
        "ris_2": "V",
        "risposta_3": "elica danneggiata.",
        "risposta_2": "presenza di aria nel circuito carburante, intasamento del filtro carburante,",
        "id": 196,
        "ris_3": "F",
        "risposta_1": "carburante con basso numero di ottani, ventilazione del vano motore inadeguata."
    },
    {
        "ris_1": "V",
        "ris_2": "F",
        "risposta_3": "carburante con basso numero di ottani.",
        "capitolo": "MOTORI",
        "progressivo": "1.2.2-17",
        "ris_3": "F",
        "domanda": "Quali possono essere le cause per le quali un motore diesel si avvia difficilmente?",
        "risposta_1": "presenza di acqua nel carburante, ostruzione del tubo di scarico,",
        "risposta_2": "carburatore ingolfato.",
        "argomento": "Irregolarità e piccole avarie che possono prevedere un intervento non specialistico",
        "id": 197,
        "immagine": ""
    },
    {
        "domanda": "Quali possono essere le cause per le quali un motore diesel produce fumi di scarico di colore nero o grigio?",
        "ris_1": "F",
        "risposta_2": "malfunzionamento della pompa di iniezione, intasamento del filtro dell'aria.",
        "progressivo": "1.2.2-18",
        "id": 198,
        "capitolo": "MOTORI",
        "risposta_3": "aria nel sistema carburante, comando di stop difettoso, avaria della pompa dell'olio.",
        "immagine": "",
        "argomento": "Irregolarità e piccole avarie che possono prevedere un intervento non specialistico",
        "ris_3": "F",
        "ris_2": "V",
        "risposta_1": "carburatore intasato, malfunzionamento della turbina di sovralimentazione, intasatura del filtro dell'olio."
    },
    {
        "risposta_2": "intasamento del filtro dell'olio, malfunzionamento della turbina di sovralimentazione.",
        "ris_1": "F",
        "progressivo": "1.2.2-19",
        "risposta_3": "intasamento del filtro dell'olio, carburatore intasato, malfunzionamento della turbina di sovralimentazione.",
        "domanda": "Quali possono essere le cause per le quali un motore diesel produce fumi di scarico di colore blu o bianco?",
        "id": 199,
        "capitolo": "MOTORI",
        "ris_2": "V",
        "risposta_1": "aria nel sistema carburante, comando di stop difettoso, avaria della pompa dell'olio.",
        "argomento": "Irregolarità e piccole avarie che possono prevedere un intervento non specialistico",
        "immagine": "",
        "ris_3": "F"
    },
    {
        "progressivo": "1.1.1-2",
        "ris_2": "V",
        "domanda": "Cosa si intende per asse longitudinale di un'unità navale?",
        "ris_1": "F",
        "ris_3": "F",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "id": 2,
        "risposta_3": "l'asse orizzontale compreso tra le due murate, posto perpendicolarmente a quello trasversale.",
        "risposta_1": "l'asse di rotazione di riferimento per il movimento di beccheggio.",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_2": "l'asse passante per la prua e la poppa, parallelo alla chiglia.",
        "immagine": ""
    },
    {
        "domanda": "La freccia verso l'unità rappresentata in figura a fianco indica il:",
        "ris_1": "V",
        "risposta_2": "giardinetto di sinistra.",
        "ris_2": "F",
        "ris_3": "F",
        "capitolo": "TEORIA DELLO SCAFO",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "risposta_1": "mascone di sinistra.",
        "immagine": 3,
        "risposta_3": "dritto di prora.",
        "progressivo": "1.1.1-20",
        "id": 20
    },
    {
        "risposta_1": "presenza di aria nel circuito del carburante, deformazione o rottura di uno o più tubi dell'iniettore.",
        "progressivo": "1.2.2-20",
        "id": 200,
        "risposta_3": "ridotto livello dell'acqua di raffreddamento, ridotto livello di carburante nel serbatoio, carburante con basso numero di ottani.",
        "capitolo": "MOTORI",
        "ris_1": "V",
        "argomento": "Irregolarità e piccole avarie che possono prevedere un intervento non specialistico",
        "domanda": "Quali possono essere le cause che determinano un'irregolare accensione di un motore diesel?",
        "immagine": "",
        "ris_3": "F",
        "ris_2": "F",
        "risposta_2": "malfunzionamento del sistema di carburazione, candele difettose, batteria sottodimensionata."
    },
    {
        "risposta_2": "ridotto livello dell'acqua di raffreddamento, carburante con basso numero di ottani.",
        "capitolo": "MOTORI",
        "risposta_3": "carena eccessivamente sporca.",
        "ris_2": "F",
        "id": 201,
        "immagine": "",
        "ris_3": "F",
        "progressivo": "1.2.2-21",
        "domanda": "Quali possono essere le cause per cui un motore diesel non gira in modo uniforme?",
        "argomento": "Irregolarità e piccole avarie che possono prevedere un intervento non specialistico",
        "ris_1": "V",
        "risposta_1": "intasamento del filtro del carburante, deformazione o rottura di uno o più tubi che portano il carburante agli iniettori."
    },
    {
        "progressivo": "1.2.2-22",
        "ris_1": "F",
        "immagine": "",
        "domanda": "Quali possono essere le cause che determinano un'eccessiva vibrazione di un motore diesel?",
        "risposta_2": "avaria del termostato, bloccaggio del tubo di scarico,",
        "ris_2": "F",
        "risposta_1": "carburazione difettosa, interruzione dei cavi elettrici, carburante con alto numero di ottani.",
        "argomento": "Irregolarità e piccole avarie che possono prevedere un intervento non specialistico",
        "ris_3": "V",
        "risposta_3": "rottura o allentamento dei supporti di fissaggio del motore.",
        "id": 202,
        "capitolo": "MOTORI"
    },
    {
        "capitolo": "MOTORI",
        "progressivo": "1.2.2-23",
        "risposta_3": "il deterioramento del carburante.",
        "id": 203,
        "ris_2": "V",
        "argomento": "Irregolarità e piccole avarie che possono prevedere un intervento non specialistico",
        "ris_1": "F",
        "risposta_2": "il rabbocco del serbatoio con carburante di scarsa qualità.",
        "risposta_1": "l'evaporazione del carburante.",
        "ris_3": "F",
        "domanda": "Quale potrebbe essere la causa che determina la formazione di acqua nel serbatoio del carburante?",
        "immagine": ""
    },
    {
        "risposta_3": "installare un apposito filtro separatore.",
        "immagine": "",
        "id": 204,
        "domanda": "Quale accorgimento può essere adottato per evitare la contaminazione del carburante?",
        "ris_3": "V",
        "risposta_2": "utilizzare un carburante con basso numero di ottani.",
        "progressivo": "1.2.2-24",
        "ris_1": "F",
        "risposta_1": "aumentare la percentuale di olio nel carburante.",
        "argomento": "Irregolarità e piccole avarie che possono prevedere un intervento non specialistico",
        "capitolo": "MOTORI",
        "ris_2": "F"
    },
    {
        "risposta_1": "deterioramento della batteria, logoramento delle pale dell'elica e del suo mozzo.",
        "immagine": "",
        "domanda": "Quali danni può causare un protratto surriscaldamento di un motore fuoribordo?",
        "risposta_3": "grippaggio del motore, danneggiamento della testata e delle sue guarnizioni.",
        "ris_2": "F",
        "risposta_2": "avaria dell'impianto di alimentazione, rottura dell'asse dell'elica.",
        "ris_1": "F",
        "id": 205,
        "capitolo": "MOTORI",
        "argomento": "Irregolarità e piccole avarie che possono prevedere un intervento non specialistico",
        "progressivo": "1.2.2-25",
        "ris_3": "V"
    },
    {
        "risposta_3": "danneggiamento dei perni di fissaggio del motore allo specchio di poppa.",
        "immagine": "",
        "ris_1": "V",
        "domanda": "Quale conseguenza potrebbe causare l'ostruzione della presa d'acqua di un motore fuoribordo?",
        "capitolo": "MOTORI",
        "risposta_2": "cavitazione dell'elica.",
        "progressivo": "1.2.2-26",
        "argomento": "Irregolarità e piccole avarie che possono prevedere un intervento non specialistico",
        "ris_2": "F",
        "id": 206,
        "risposta_1": "il surriscaldamento del  motore e il successivo arresto dello stesso.",
        "ris_3": "F"
    },
    {
        "ris_2": "F",
        "progressivo": "1.2.2-27",
        "risposta_2": "l'otturazione della pompa di iniezione.",
        "ris_1": "V",
        "argomento": "Irregolarità e piccole avarie che possono prevedere un intervento non specialistico",
        "risposta_3": "l'improvviso aumento della temperatura degli elettrodi della batteria.",
        "id": 207,
        "domanda": "Quale conseguenza potrebbe causare la presenza di alghe o detriti galleggianti sull'elica di un motore fuoribordo?",
        "ris_3": "F",
        "risposta_1": "l'eccessiva vibrazione del motore.",
        "immagine": "",
        "capitolo": "MOTORI"
    },
    {
        "progressivo": "1.2.2-28",
        "risposta_2": "a seconda che si intenda intraprendere una navigazione nei quadranti settentrionali o meridionali.",
        "id": 208,
        "risposta_3": "a seconda che si intenda effettuare una navigazione stimata o costiera.",
        "risposta_1": "le condizioni meteo-marine e il dislocamento complessivo dell'unità navale.",
        "immagine": "",
        "domanda": "Quali cause o fattori possono influire sull'autonomia dell'unità navale?",
        "ris_1": "V",
        "argomento": "Irregolarità e piccole avarie che possono prevedere un intervento non specialistico",
        "ris_2": "F",
        "ris_3": "F",
        "capitolo": "MOTORI"
    },
    {
        "immagine": "",
        "ris_1": "F",
        "argomento": "Irregolarità e piccole avarie che possono prevedere un intervento non specialistico",
        "domanda": "Da quali fattori può essere influenzata l'autonomia di un'unità navale?",
        "risposta_1": "affidabilità dei punti nave effettuati durante la navigazione.",
        "progressivo": "1.2.2-29",
        "risposta_2": "valori di deviazione della bussola magnetica di bordo.",
        "risposta_3": "velocità di crociera mantenuta.",
        "id": 209,
        "ris_3": "V",
        "ris_2": "F",
        "capitolo": "MOTORI"
    },
    {
        "capitolo": "TEORIA DELLO SCAFO",
        "id": 21,
        "progressivo": "1.1.1-21",
        "ris_1": "V",
        "immagine": 4,
        "ris_3": "F",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "risposta_3": "dritto di prora.",
        "risposta_2": "giardinetto di dritta.",
        "domanda": "La freccia verso l'unità rappresentata in figura a fianco indica il:",
        "ris_2": "F",
        "risposta_1": "mascone di dritta."
    },
    {
        "progressivo": "1.2.3-9",
        "domanda": "Determinare, con la dovuta approssimazione, la quantità di carburante (comprensiva del 30% relativa alla riserva) necessaria ad un’unità navale da diporto per compiere una navigazione in sicurezza in un tempo di 4 ore, conoscendo il consumo orario (24 litri/h) del motore installato a bordo.",
        "risposta_3": "106 litri.",
        "ris_1": "V",
        "ris_3": "F",
        "ris_2": "F",
        "id": 210,
        "argomento": "Calcolo dell'autonomia in relazione alla potenza del motore ed alla quantità residua di carburante",
        "risposta_1": "125 litri.",
        "risposta_2": "96  litri.",
        "capitolo": "MOTORI",
        "immagine": ""
    },
    {
        "argomento": "Calcolo dell'autonomia in relazione alla potenza del motore ed alla quantità residua di carburante",
        "ris_3": "F",
        "ris_1": "F",
        "ris_2": "V",
        "risposta_2": "208 litri.",
        "capitolo": "MOTORI",
        "id": 211,
        "risposta_3": "160 litri.",
        "domanda": "Determinare, con la dovuta approssimazione, la quantità di carburante (comprensiva del 30% relativa alla riserva) necessaria ad un’unità navale da diporto per compiere una navigazione in sicurezza in un tempo di 5 ore, conoscendo il consumo orario (32 litri/h) del motore installato a bordo.",
        "progressivo": "1.2.3-10",
        "risposta_1": "185 litri.",
        "immagine": ""
    },
    {
        "ris_2": "V",
        "ris_3": "F",
        "risposta_3": "54 litri.",
        "capitolo": "MOTORI",
        "ris_1": "F",
        "immagine": "",
        "progressivo": "1.2.3-11",
        "id": 212,
        "risposta_2": "140 litri.",
        "domanda": "Determinare, con la dovuta approssimazione, la quantità di carburante (comprensiva del 30% relativa alla riserva) necessaria ad un’unità navale da diporto per compiere una navigazione in sicurezza in un tempo di 6 ore, conoscendo il consumo orario (18 litri/h) del motore installato a bordo.",
        "risposta_1": "108 litri.",
        "argomento": "Calcolo dell'autonomia in relazione alla potenza del motore ed alla quantità residua di carburante"
    },
    {
        "progressivo": "1.2.3-12",
        "immagine": "",
        "domanda": "Determinare, con la dovuta approssimazione, la quantità di carburante (compresa del 30% relativa alla riserva) necessaria ad un’unità navale da diporto per compiere una navigazione in sicurezza in un tempo di 7 ore, conoscendo il consumo orario (27 litri/h) del motore installato a bordo.",
        "id": 213,
        "ris_1": "V",
        "capitolo": "MOTORI",
        "argomento": "Calcolo dell'autonomia in relazione alla potenza del motore ed alla quantità residua di carburante",
        "risposta_3": "189 litri.",
        "risposta_2": "350 litri",
        "risposta_1": "246 litri.",
        "ris_3": "F",
        "ris_2": "F"
    },
    {
        "risposta_3": "198 litri.",
        "risposta_2": "222 litri.",
        "id": 214,
        "immagine": "",
        "progressivo": "1.2.3-13",
        "ris_1": "F",
        "capitolo": "MOTORI",
        "argomento": "Calcolo dell'autonomia in relazione alla potenza del motore ed alla quantità residua di carburante",
        "risposta_1": "171 litri",
        "domanda": "Determinare, con la dovuta approssimazione, la quantità di carburante (compresa del 30% relativa alla riserva) necessaria ad un’unità navale da diporto per compiere una navigazione in sicurezza in un tempo di 9 ore, conoscendo il consumo orario (19 litri/h) del motore installato a bordo.",
        "ris_3": "F",
        "ris_2": "V"
    },
    {
        "ris_1": "F",
        "risposta_3": "155 litri.",
        "risposta_1": "141 litri",
        "capitolo": "MOTORI",
        "id": 215,
        "progressivo": "1.2.3-14",
        "ris_3": "F",
        "risposta_2": "183 litri.",
        "ris_2": "V",
        "immagine": "",
        "argomento": "Calcolo dell'autonomia in relazione alla potenza del motore ed alla quantità residua di carburante",
        "domanda": "Determinare, con la dovuta approssimazione, la quantità di carburante (comprensiva del 30% relativa alla riserva) necessaria ad un’unità navale da diporto per compiere una navigazione in sicurezza in un tempo di 3 ore, conoscendo il consumo orario (47 litri/h) del motore installato a bordo."
    },
    {
        "risposta_1": "25 litri.",
        "argomento": "Calcolo dell'autonomia in relazione alla potenza del motore ed alla quantità residua di carburante",
        "immagine": "",
        "id": 216,
        "capitolo": "MOTORI",
        "ris_2": "F",
        "domanda": "Essendo noti i dati relativi alla lunghezza del percorso da effettuare (90 miglia nautiche), la velocità di crociera (30 nodi) ed il corrispondente consumo orario (28 l/h), determinare la quantità di carburante relativa alla riserva che dovrà essere imbarcata a bordo di un’unità.",
        "ris_3": "F",
        "risposta_2": "75 litri.",
        "progressivo": "1.2.3-15",
        "risposta_3": "8 litri.",
        "ris_1": "V"
    },
    {
        "progressivo": "1.2.3-16",
        "domanda": "Essendo noti i dati relativi alla lunghezza del percorso da effettuare (84 miglia nautiche), la velocità di crociera (21 nodi) ed il corrispondente consumo orario (18 l/h), determinare la quantità di carburante relativa alla riserva che dovrà essere imbarcata a bordo di un’unità.",
        "ris_2": "V",
        "ris_3": "F",
        "risposta_1": "72 litri.",
        "ris_1": "F",
        "risposta_2": "22 litri.",
        "capitolo": "MOTORI",
        "id": 217,
        "risposta_3": "33 litri.",
        "immagine": "",
        "argomento": "Calcolo dell'autonomia in relazione alla potenza del motore ed alla quantità residua di carburante"
    },
    {
        "ris_2": "F",
        "risposta_2": "90 litri.",
        "capitolo": "MOTORI",
        "risposta_1": "150 litri.",
        "id": 218,
        "domanda": "Essendo noti i dati relativi alla lunghezza del percorso da effettuare (100 miglia nautiche), la velocità di crociera (40 nodi) ed il corrispondente consumo orario (60 l/h), determinare la quantità di carburante relativa alla riserva che dovrà essere imbarcata a bordo di un’unità navale.",
        "argomento": "Calcolo dell'autonomia in relazione alla potenza del motore ed alla quantità residua di carburante",
        "immagine": "",
        "progressivo": "1.2.3-17",
        "risposta_3": "45 litri.",
        "ris_3": "V",
        "ris_1": "F"
    },
    {
        "progressivo": "1.2.3-18",
        "risposta_2": "9 litri.",
        "immagine": "",
        "ris_1": "V",
        "domanda": "Essendo noti i dati relativi alla lunghezza del percorso da effettuare (54 miglia nautiche), la velocità di crociera (18 nodi) ed il corrispondente consumo orario (30 l/h), determinare la quantità di carburante relativa alla riserva che dovrà essere imbarcata a bordo di un’unità navale.",
        "ris_3": "F",
        "risposta_1": "27 litri.",
        "ris_2": "F",
        "id": 219,
        "capitolo": "MOTORI",
        "risposta_3": "65 litri.",
        "argomento": "Calcolo dell'autonomia in relazione alla potenza del motore ed alla quantità residua di carburante"
    },
    {
        "risposta_3": "la distanza tra la chiglia dell'unità e il fondo del mare.",
        "risposta_2": "il peso totale della nave.",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_1": "la distanza verticale tra la linea di galleggiamento e il punto inferiore estremo dello scafo.",
        "immagine": "",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "domanda": "Il pescaggio di un'imbarcazione è:",
        "ris_1": "V",
        "id": 22,
        "ris_3": "F",
        "ris_2": "F",
        "progressivo": "1.1.1-22"
    },
    {
        "ris_3": "F",
        "argomento": "Calcolo dell'autonomia in relazione alla potenza del motore ed alla quantità residua di carburante",
        "immagine": "",
        "risposta_2": "24 litri.",
        "progressivo": "1.2.3-19",
        "capitolo": "MOTORI",
        "ris_2": "V",
        "id": 220,
        "risposta_1": "8 litri.",
        "ris_1": "F",
        "risposta_3": "55 litri.",
        "domanda": "Essendo noti i dati relativi alla lunghezza del percorso da effettuare (150 miglia nautiche), la velocità di crociera (30 nodi) ed il corrispondente consumo orario (16 l/h), determinare la quantità di carburante relativa alla riserva che dovrà essere imbarcata a bordo di un’unità navale."
    },
    {
        "progressivo": "1.2.3-20",
        "id": 221,
        "risposta_1": "45 litri.",
        "capitolo": "MOTORI",
        "ris_2": "F",
        "ris_1": "F",
        "domanda": "Essendo noti i dati relativi alla lunghezza del percorso da effettuare (48 miglia nautiche), la velocità di crociera (12 nodi) ed il corrispondente consumo orario (26 l/h), determinare la quantità di carburante relativa alla riserva che dovrà essere imbarcata a bordo di un’unità navale.",
        "risposta_2": "10 litri.",
        "risposta_3": "31 litri.",
        "ris_3": "V",
        "immagine": "",
        "argomento": "Calcolo dell'autonomia in relazione alla potenza del motore ed alla quantità residua di carburante"
    },
    {
        "risposta_3": "61 litri",
        "capitolo": "MOTORI",
        "argomento": "Calcolo dell'autonomia in relazione alla potenza del motore ed alla quantità residua di carburante",
        "ris_1": "F",
        "domanda": "Essendo noti i dati relativi alla lunghezza del percorso da effettuare (68 miglia nautiche), la velocità di crociera (12 nodi) ed il corrispondente consumo orario (12 l/h), determinare la quantità di carburante relativa alla riserva che dovrà essere imbarcata a bordo di un’unità navale.",
        "ris_3": "F",
        "ris_2": "V",
        "immagine": "",
        "progressivo": "1.2.3-21",
        "risposta_2": "20 litri.",
        "id": 222,
        "risposta_1": "102 litri."
    },
    {
        "risposta_2": "una tipologia di turbina per sovralimentare i motori diesel.",
        "domanda": "Per IPS (Inboard Performance System) si intende:",
        "risposta_1": "una tipologia di trasmissione con piede completamente immerso, caratterizzato da eliche traenti e rivolte verso prua.",
        "risposta_3": "un sistema di arricchimento del carburante a benzina.",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "ris_3": "F",
        "ris_2": "F",
        "capitolo": "MOTORI",
        "id": 223,
        "progressivo": "1.2.1-52",
        "ris_1": "V",
        "immagine": ""
    },
    {
        "progressivo": "1.2.1-53",
        "ris_1": "F",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "risposta_1": "da una linea d'asse di sezione maggiorata.",
        "ris_2": "F",
        "domanda": "Le trasmissioni tipo \"Pod\" sono costituite:",
        "capitolo": "MOTORI",
        "risposta_2": "da una serie di ingranaggi e rinvii che consentono di posizionare il motore a poppavia dell'astuccio, quindi all'incontrario rispetto alla posizione della linea d'asse classica.",
        "id": 224,
        "risposta_3": "da un corpo trasmissione contenuto in un piede completamente immerso, che ruotando orienta la prua della barca.",
        "ris_3": "V",
        "immagine": ""
    },
    {
        "id": 225,
        "risposta_2": "una trasmissione \"V drive\".",
        "risposta_3": "un piede \"S drive\".",
        "capitolo": "MOTORI",
        "domanda": "Quella dell'immagine a fianco è:",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "immagine": 18,
        "ris_1": "V",
        "ris_3": "F",
        "progressivo": "1.2.1-54",
        "ris_2": "F",
        "risposta_1": "una trasmissione IPS."
    },
    {
        "immagine": "",
        "risposta_1": "il piedino all’interno del quale si trovano due ingranaggi conici che trasmettono il moto dal motore all’elica, utilizzato sulle barche a vela in luogo della linea d'asse.",
        "ris_2": "F",
        "risposta_2": "il tubo di scarico dell'acqua di raffreddamento.",
        "capitolo": "MOTORI",
        "id": 226,
        "progressivo": "1.2.1-55",
        "ris_1": "V",
        "domanda": "Per \"S drive\" si intende:",
        "risposta_3": "il circuito di alimentazione degli iniettori.",
        "ris_3": "V",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore"
    },
    {
        "ris_3": "F",
        "progressivo": "1.2.1-56",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "ris_1": "V",
        "immagine": "",
        "ris_2": "F",
        "risposta_3": "sostituire lo zinco ogni 10 anni.",
        "risposta_2": "sostituire la guarnizione del piedino ogni 15 anni.",
        "capitolo": "MOTORI",
        "id": 227,
        "domanda": "Ai fini della sicurezza, un elemento importante per la manutenzione della \"S drive\" è:",
        "risposta_1": "la regolare sostituzione della guarnizione del piedino secondo le indicazioni di scadenza del costruttore, stampate nella gomma."
    },
    {
        "ris_1": "V",
        "immagine": "",
        "progressivo": "1.2.1-57",
        "ris_2": "F",
        "capitolo": "MOTORI",
        "domanda": "Il carburante diesel attualmente in commercio:",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "ris_3": "F",
        "id": 228,
        "risposta_2": "ha una alto contenuto di ottani oleosi.",
        "risposta_3": "ha il medesimo punto di infiammabilità della benzina.",
        "risposta_1": "favorisce la formazione di alghe nel serbatoio, che possono ostruire l'alimentazione del motore."
    },
    {
        "risposta_3": "è opportuno provvedere spesso alla pulizia del serbatoio e alla sostituzione dei filtri del carburante per contrastare la formazione di alghe",
        "immagine": "",
        "risposta_1": "verificare regolarmente la pulizia del carburatore.",
        "ris_3": "V",
        "ris_2": "F",
        "ris_1": "F",
        "argomento": "Elementi di funzionamento dei sistemi di propulsione a motore",
        "risposta_2": "verificare la pulizia delle candele.",
        "capitolo": "MOTORI",
        "id": 229,
        "domanda": "Per garantire il perfetto funzionamento di un motore diesel:",
        "progressivo": "1.2.1-58"
    },
    {
        "ris_2": "V",
        "risposta_1": "di maggior spessore.",
        "risposta_3": "indicata con il numero 1.",
        "ris_3": "F",
        "immagine": "",
        "progressivo": "1.1.1-23",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_1": "F",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "domanda": "L'ordinata maestra è quella:",
        "id": 23,
        "risposta_2": "che corrisponde alla sezione maestra dello scafo dell'unità."
    },
    {
        "progressivo": "1.3.1-1",
        "ris_1": "V",
        "risposta_3": "fuochi da liquidi.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_2": "F",
        "immagine": "",
        "id": 230,
        "domanda": "Per quali classi di incendio (A, B, C, D) risulta idoneo l'impiego dell'estintore a polvere?",
        "risposta_2": "fuochi da solidi e fuochi da gas.",
        "risposta_1": "tutte le classi.",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "ris_3": "F"
    },
    {
        "risposta_2": "fuochi da gas.",
        "risposta_3": "fuochi da solidi e fuochi da liquidi.",
        "domanda": "Per quale tipologia di incendio risulta idoneo l'impiego dell'estintore a schiuma?",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "progressivo": "1.3.1-2",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "risposta_1": "fuochi da impianti elettrici.",
        "ris_3": "V",
        "id": 231,
        "immagine": "",
        "ris_2": "F",
        "ris_1": "F"
    },
    {
        "ris_2": "V",
        "ris_3": "F",
        "ris_1": "F",
        "risposta_3": "sia in locali aperti che chiusi perché agisce per raffreddamento.",
        "id": 232,
        "immagine": "",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "progressivo": "1.3.1-3",
        "risposta_2": "all'interno di locali chiusi in quanto estingue l'incendio per soffocamento.",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "risposta_1": "in locali aperti perché agisce per sottrazione di calore.",
        "domanda": "L'estintore ad anidride carbonica va utilizzato:"
    },
    {
        "id": 233,
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "ris_1": "V",
        "ris_3": "F",
        "risposta_2": "solo quelli a CO2.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_1": "tutti.",
        "immagine": "",
        "risposta_3": "nessuno.",
        "ris_2": "F",
        "domanda": "Quali estintori devono essere omologati a norma CE?",
        "progressivo": "1.3.1-4"
    },
    {
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "immagine": "",
        "ris_2": "F",
        "progressivo": "1.3.1-5",
        "risposta_3": "determinate sostanze, quali stracci unti di olio abbandonati nel vano motore o in gavoni scarsamente ventilati, possono riscaldarsi lentamente determinando una combustione spontanea.",
        "risposta_1": "determinate sostanze, quali stracci unti di olio abbandonati in coperta, possono raggiungere rapidamente la temperatura di infiammabilità determinando una combustione spontanea financo l'esplosione.",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "ris_1": "F",
        "id": 234,
        "risposta_2": "determinate sostanze, quali stracci unti di olio abbandonati in coperta, possono raggiungere rapidamente la temperatura di ignizione determinando una combustione spontanea.",
        "domanda": "Avuto riguardo alla prevenzione sugli incendi, quale tra queste affermazioni è corretta?",
        "ris_3": "V"
    },
    {
        "id": 235,
        "ris_2": "V",
        "progressivo": "1.3.1-6",
        "ris_1": "F",
        "ris_3": "F",
        "immagine": "",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "risposta_3": "estintore a schiuma.",
        "domanda": "Quale mezzo antincendio risulta più opportuno impiegare per estinguere incendi generati da apparecchiature o quadri elettrici?",
        "risposta_2": "estintore a polvere ad anidride carbonica.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_1": "acqua di mare."
    },
    {
        "risposta_3": "l'estintore ad anidride carbonica va utilizzato solo all'aperto, perché agisce per raffreddamento.",
        "progressivo": "1.3.1-7",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "il getto degli estintori a schiuma deve essere diretto verso la superficie delle fiamme ed impiegato principalmente per estinguere incendi di materiale elettrico sotto tensione.",
        "ris_1": "V",
        "ris_2": "F",
        "risposta_1": "il getto degli estintori a schiuma deve essere diretto alla base delle fiamme e non deve essere impiegato per estinguere incendi di materiale elettrico sotto tensione.",
        "immagine": "",
        "ris_3": "F",
        "id": 236,
        "domanda": "Quale tra queste affermazioni è corretta?"
    },
    {
        "risposta_1": "non ottengo lo spegnimento.",
        "id": 237,
        "risposta_2": "è un utilizzo efficace.",
        "progressivo": "1.3.1-8",
        "domanda": "Utilizzo dell'acqua per spegnere fuochi da metalli:",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "immagine": "",
        "ris_2": "F",
        "ris_1": "F",
        "ris_3": "V",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "risposta_3": "è un utilizzo pericoloso."
    },
    {
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "id": 238,
        "ris_2": "F",
        "risposta_3": "da liquidi infiammabili.",
        "ris_1": "F",
        "immagine": "",
        "domanda": "Da cosa è generato un incendio di classe B?",
        "risposta_1": "da apparecchiature elettriche in tensione.",
        "risposta_2": "da gas infiammabili.",
        "progressivo": "1.3.1-9",
        "ris_3": "V"
    },
    {
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "il locale si raffredda.",
        "ris_3": "V",
        "domanda": "Viene introdotta aria in un locale aggredito da incendio:",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "progressivo": "1.3.1-10",
        "risposta_3": "si alimenta l'incendio.",
        "ris_2": "F",
        "risposta_1": "non accade nulla di nuovo.",
        "id": 239,
        "immagine": "",
        "ris_1": "F"
    },
    {
        "immagine": 5,
        "risposta_3": "mascone di dritta.",
        "progressivo": "1.1.1-24",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "ris_2": "F",
        "domanda": "La freccia verso l'unità rappresentata in figura a fianco indica il:",
        "ris_3": "F",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_1": "giardinetto di dritta.",
        "id": 24,
        "ris_1": "V",
        "risposta_2": "babordo."
    },
    {
        "id": 240,
        "ris_1": "F",
        "progressivo": "1.3.1-11",
        "risposta_2": "dopo quanti mesi va revisionato.",
        "risposta_3": "classe di incendio e capacità estinguente.",
        "ris_2": "F",
        "immagine": "",
        "domanda": "La sigla 13B sugli estintori indica:",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_3": "V",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "risposta_1": "la classe di costruzione e confezionamento secondo la normativa CE."
    },
    {
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "immagine": "",
        "risposta_1": "estinguere incendi di sostanze liquide o gassose, nonché incendi di apparecchiature elettriche sotto tensione.",
        "domanda": "Gli estintori a polvere si utilizzano per:",
        "ris_3": "F",
        "progressivo": "1.3.1-12",
        "ris_2": "F",
        "ris_1": "V",
        "id": 241,
        "risposta_3": "estinguere incendi di materiale in vetroresina o in legno.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "estinguere incendi di sostanze solide."
    },
    {
        "risposta_3": "liquidi infiammabili e materiali elettrici sotto tensione.",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "ris_3": "V",
        "ris_2": "F",
        "ris_1": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "id": 242,
        "domanda": "L'estintore a CO2 è utilizzabile per incendi di:",
        "progressivo": "1.3.1-13",
        "risposta_2": "gas inerti idraulici e materiali solidi.",
        "immagine": "",
        "risposta_1": "materiali solidi o metalli combustibili."
    },
    {
        "ris_1": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "da apparecchiature elettriche in tensione.",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "risposta_1": "da liquidi infiammabili.",
        "immagine": "",
        "id": 243,
        "ris_3": "F",
        "progressivo": "1.3.1-14",
        "ris_2": "V",
        "risposta_3": "da combustibili solidi.",
        "domanda": "Da cosa è generato un incendio di classe E?"
    },
    {
        "risposta_2": "di classe E.",
        "ris_2": "F",
        "immagine": "",
        "ris_3": "F",
        "risposta_1": "delle classi A e B.",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "risposta_3": "di tutti i tipi.",
        "domanda": "Un estintore a schiuma è utilizzabile per incendi:",
        "ris_1": "V",
        "id": 244,
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "progressivo": "1.3.1-15"
    },
    {
        "ris_1": "V",
        "risposta_2": "da alcuni particolari metalli infiammabili.",
        "ris_2": "F",
        "immagine": "",
        "risposta_3": "da gas infiammabili.",
        "progressivo": "1.3.1-16",
        "risposta_1": "da combustibili solidi.",
        "ris_3": "F",
        "id": 245,
        "domanda": "Da cosa è generato un incendio di classe A?",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "argomento": "Prevenzione degli incendi e uso degli estintori"
    },
    {
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_3": "classe C.",
        "risposta_1": "classe A.",
        "progressivo": "1.3.1-17",
        "risposta_2": "classe B.",
        "ris_2": "F",
        "immagine": "",
        "ris_1": "F",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "id": 246,
        "domanda": "L'incendio di gas infiammabili è un incendio di:",
        "ris_3": "V"
    },
    {
        "risposta_2": "un estintore a CO2.",
        "ris_3": "F",
        "risposta_1": "un estintore a schiuma.",
        "domanda": "Per incendi da gas e da impianti elettrici (classi C ed E) è preferibile utilizzare:",
        "ris_1": "F",
        "id": 247,
        "progressivo": "1.3.1-18",
        "ris_2": "V",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "immagine": "",
        "risposta_3": "un estintore a polvere."
    },
    {
        "progressivo": "1.3.1-19",
        "risposta_2": "si rimuove la radio il più velocemente possibile e la si getta in acqua.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "domanda": "Principio d’incendio all'apparato radio VHF:",
        "ris_3": "V",
        "id": 248,
        "risposta_3": "si raffredda la radio utilizzando l’estintore ad anidride carbonica (CO2).",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "ris_2": "F",
        "risposta_1": "si getta una secchiata d’acqua fresca sull’apparato radio.",
        "immagine": "",
        "ris_1": "F"
    },
    {
        "risposta_1": "non ottengo lo spegnimento.",
        "progressivo": "1.3.1-20",
        "ris_1": "F",
        "id": 249,
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "immagine": "",
        "ris_2": "V",
        "ris_3": "F",
        "risposta_2": "è un utilizzo molto pericoloso.",
        "risposta_3": "è un utilizzo efficace.",
        "domanda": "Utilizzo dell'acqua per spegnere un incendio da impianti elettrici (classe E):",
        "argomento": "Prevenzione degli incendi e uso degli estintori"
    },
    {
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "ris_1": "V",
        "ris_2": "F",
        "progressivo": "1.1.1-25",
        "domanda": "La freccia verso l'unità rappresentata in figura a fianco indica il:",
        "risposta_3": "mascone di sinistra.",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_1": "giardinetto di sinistra.",
        "risposta_2": "babordo.",
        "immagine": 6,
        "ris_3": "F",
        "id": 25
    },
    {
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "risposta_3": "ogni 2 anni.",
        "ris_3": "F",
        "id": 250,
        "risposta_1": "ogni 4 anni.",
        "ris_2": "V",
        "progressivo": "1.3.1-21",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "quando la lancetta del manometro è sul rosso.",
        "ris_1": "F",
        "immagine": "",
        "domanda": "Ogni quanto tempo va revisionato un estintore?"
    },
    {
        "ris_1": "F",
        "immagine": "",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "risposta_2": "sì, ogni anno.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_3": "mai, salvo che non sia stato utilizzato o vi sia stata perdita di pressione, è sufficiente verificare periodicamente che la lancetta del manometro stia sul verde.",
        "domanda": "Va revisionato un estintore?",
        "ris_3": "V",
        "ris_2": "F",
        "risposta_1": "sì, ogni 2 anni.",
        "progressivo": "1.3.1-22",
        "id": 251
    },
    {
        "id": 252,
        "immagine": "",
        "domanda": "Ogni quanto tempo si deve sostituire un estintore?",
        "progressivo": "1.3.1-23",
        "ris_2": "F",
        "risposta_3": "quando è in cattivo stato.",
        "risposta_1": "ogni 2 anni.",
        "risposta_2": "ogni anno.",
        "ris_1": "F",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "ris_3": "V",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE"
    },
    {
        "ris_3": "V",
        "progressivo": "1.3.1-24",
        "ris_2": "F",
        "immagine": "",
        "risposta_1": "combustione, estinzione, reazione.",
        "id": 253,
        "risposta_3": "combustibile, comburente, calore.",
        "domanda": "Quali sono gli elementi del cosiddetto \"triangolo del fuoco\" che alimentano un incendio?",
        "risposta_2": "estintore, pressione, schiuma.",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "ris_1": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE"
    },
    {
        "id": 254,
        "ris_2": "F",
        "progressivo": "1.3.1-25",
        "risposta_1": "esponendo la fiamma all'aria aperta.",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "immagine": "",
        "risposta_3": "abbassando sensibilmente la temperatura.",
        "ris_1": "F",
        "ris_3": "V",
        "risposta_2": "se piove.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "domanda": "Come si può estinguere un incendio?"
    },
    {
        "domanda": "Un incendio si estingue:",
        "risposta_3": "aumentando la forza del vento.",
        "immagine": "",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "risposta_2": "aumentando la temperatura.",
        "ris_2": "F",
        "risposta_1": "mancando l'ossigeno.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "id": 255,
        "ris_1": "V",
        "progressivo": "1.3.1-26",
        "ris_3": "F"
    },
    {
        "domanda": "Come può definirsi la combustione?",
        "id": 256,
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "F",
        "risposta_2": "la reazione chimica che produce calore e che avviene tra il comburente ed il combustibile.",
        "risposta_3": "la reazione meccanica che produce una variazione di temperatura e che avviene tra un liquido più caldo e un solido più freddo.",
        "ris_3": "F",
        "ris_2": "V",
        "risposta_1": "l'improvvisa emissione di gas inerte da parte di un liquido, generata da un'istantanea variazione di temperatura.",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "immagine": "",
        "progressivo": "1.3.1-27"
    },
    {
        "immagine": "",
        "risposta_3": "dalla temperatura alla quale il liquido sublima, cioè passa dallo stato solido a quello aeriforme senza passare attraverso la fase liquida.",
        "progressivo": "1.3.1-28",
        "risposta_1": "dalla temperatura di infiammabilità del liquido.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "domanda": "Da che cosa dipende la maggiore o minore combustibilità di un liquido?",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "ris_1": "V",
        "id": 257,
        "ris_2": "F",
        "risposta_2": "dalla temperatura dei solidi con cui il liquido viene a contatto.",
        "ris_3": "F"
    },
    {
        "risposta_2": "no, è stabilito dal proprietario",
        "risposta_1": "si.",
        "ris_3": "V",
        "immagine": "",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "ris_2": "F",
        "id": 258,
        "risposta_3": "no, stabilito nel Manuale del proprietario.",
        "progressivo": "1.3.1-29",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "domanda": "Il numero e il posizionamento degli estintori di una unità marcata CE è stabilito dalle ordinanze dell'Autorità marittima?",
        "ris_1": "F"
    },
    {
        "ris_2": "V",
        "domanda": "Per le imbarcazioni da diporto NON marcata CE (immesse sul mercato prima del 17 giugno 1998):",
        "risposta_1": "il numero e il posizionamento degli estintori non è stabilito.",
        "risposta_2": "il numero e il posizionamento degli estintori è fissato dal Regolamento di attuazione al Codice della nautica secondo la potenza del motore e prevede un minimo di 1 estintore al posto di guida e 1 estintore in ciascuno degli altri locali.",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "ris_1": "F",
        "immagine": "",
        "id": 259,
        "progressivo": "1.3.1-30",
        "risposta_3": "il numero e il posizionamento degli estintori è stabilito dalle ordinanze dell'Autorità marittima, con il minimo di 1 estintore.",
        "ris_3": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE"
    },
    {
        "immagine": "",
        "ris_1": "F",
        "progressivo": "1.1.1-26",
        "ris_3": "V",
        "domanda": "L'ombrinale è:",
        "id": 26,
        "ris_2": "F",
        "risposta_2": "la parte più bassa dello scafo, immediatamente al di sotto del motore, dove si raccolgono eventuali perdite di fluidi.",
        "risposta_3": "una piccola apertura per far defluire l'acqua presente in coperta o nel pozzetto",
        "risposta_1": "il foro attraverso cui passa la catena dell'ancora quando viene dato fondo.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "capitolo": "TEORIA DELLO SCAFO"
    },
    {
        "id": 260,
        "ris_3": "F",
        "risposta_2": "il materiale infiammabile.",
        "risposta_1": "la sostanza che alimenta la combustione mediante ossidazione del combustibile, generalmente l'ossigeno.",
        "domanda": "Cos'è il comburente?",
        "ris_2": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "argomento": "Prevenzione degli incendi e uso degli estintori",
        "immagine": "",
        "ris_1": "V",
        "progressivo": "1.3.1-31",
        "risposta_3": "il nome della fiamma."
    },
    {
        "risposta_3": "è disposta la vendita coatta dell'unità da diporto.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_2": "F",
        "immagine": "",
        "risposta_2": "è sempre disposta la confisca dell'unità da diporto.",
        "ris_3": "F",
        "ris_1": "V",
        "risposta_1": "è sempre disposta la revoca della patente nautica.",
        "domanda": "Se, in conseguenza della condotta di un'unità da diporto in stato di ebbrezza, deriva un danno o un pericolo di danno ambientale:",
        "argomento": "Rischi derivanti dalla conduzione dell'unità sotto l'influenza di alcol o in stato di alterazione psico-fisica per l'uso di sostanze stupefacenti o psicotrope",
        "id": 261,
        "progressivo": "1.3.2-1"
    },
    {
        "argomento": "Rischi derivanti dalla conduzione dell'unità sotto l'influenza di alcol o in stato di alterazione psico-fisica per l'uso di sostanze stupefacenti o psicotrope",
        "ris_1": "F",
        "risposta_2": "con una sanzione amministrativa che varia da 500 euro a 1.500 euro qualunque sia il tasso alcolemico rilevato.",
        "ris_3": "V",
        "domanda": "Chiunque assume o ritiene il comando o la condotta ovvero la direzione nautica di un'unità da diporto in stato di ebbrezza è punito,",
        "ris_2": "F",
        "immagine": "",
        "risposta_1": "con una sanzione amministrativa che varia da\n1.000 euro a 5.000 euro in relazione al tasso alcolemico rilevato e la sanzione accessoria della revisione parziale della patente nautica prima della scadenza.",
        "progressivo": "1.3.2-2",
        "risposta_3": "con una sanzione amministrativa che varia da 2.755 euro a 15.000 euro in relazione al tasso alcolemico rilevato.",
        "id": 262,
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE"
    },
    {
        "domanda": "Circa la condotta di un'unità da diporto in stato di ebbrezza:",
        "ris_2": "F",
        "ris_1": "V",
        "argomento": "Rischi derivanti dalla conduzione dell'unità sotto l'influenza di alcol o in stato di alterazione psico-fisica per l'uso di sostanze stupefacenti o psicotrope",
        "risposta_3": "è disposta la sospensione della patente nautica da 3 mesi a 24 mesi solo se ne deriva danno o pericolo di danno ambientale.",
        "ris_3": "F",
        "risposta_2": "è disposta la sospensione della patente nautica da 3 mesi a 24 mesi in caso di sinistro marittimo.",
        "risposta_1": "è sempre disposta la sospensione della patente nautica da 3 mesi a 24 mesi, in relazione al tasso alcolemico rilevato.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "immagine": "",
        "id": 263,
        "progressivo": "1.3.2-3"
    },
    {
        "argomento": "Rischi derivanti dalla conduzione dell'unità sotto l'influenza di alcol o in stato di alterazione psico-fisica per l'uso di sostanze stupefacenti o psicotrope",
        "id": 264,
        "ris_2": "V",
        "ris_1": "F",
        "risposta_2": "con la sanzione amministrativa da 2.755 euro a 11.017 euro.",
        "risposta_3": "con la sanzione amministrativa da 557 euro a 2.507 euro.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_1": "con la sanzione amministrativa da 250 euro a\n1.100 euro.",
        "progressivo": "1.3.2-4",
        "ris_3": "F",
        "immagine": "",
        "domanda": "Chi assume il comando o la condotta di un'unità da diporto in stato di alterazione psico-fisica dopo aver assunto sostanze stupefacenti o psicotrope è punito:"
    },
    {
        "ris_2": "F",
        "domanda": "Se nel commettere l'infrazione amministrativa inerente la condotta di un'unità da diporto in stato di ebbrezza, in conseguenza dell'uso di bevande alcoliche, da cui ne deriva danno o pericolo di danno ambientale:",
        "risposta_3": "è sempre disposta la sospensione della patente nautica da 6 a 12 mesi.",
        "risposta_2": "è sempre disposta la sospensione della patente nautica da 1 a 6 mesi.",
        "argomento": "Rischi derivanti dalla conduzione dell'unità sotto l'influenza di alcol o in stato di alterazione psico-fisica per l'uso di sostanze stupefacenti o psicotrope",
        "id": 265,
        "ris_3": "F",
        "immagine": "",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "V",
        "progressivo": "1.3.2-5",
        "risposta_1": "è sempre disposta la revoca della patente nautica."
    },
    {
        "ris_2": "F",
        "risposta_2": "le sanzioni previste sono aumentate solo qualora sia stato accertato un valore corrispondente a un tasso alcolemico superiore a 1,5 grammi per litro.",
        "risposta_3": "è disposta la vendita coatta dell'unità da diporto.",
        "id": 266,
        "argomento": "Rischi derivanti dalla conduzione dell'unità sotto l'influenza di alcol o in stato di alterazione psico-fisica per l'uso di sostanze stupefacenti o psicotrope",
        "ris_3": "F",
        "domanda": "In caso di conduzione sotto l'influenza dell'alcool di unità da diporto adibita a noleggio:",
        "immagine": "",
        "progressivo": "1.3.2-6",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_1": "le sanzioni previste sono aumentate di un terzo, qualora sia stato accertato un valore corrispondente a un tasso alcolemico superiore a 0,5 e non superiore a 0,8 grammi per litro.",
        "ris_1": "V"
    },
    {
        "argomento": "Rischi derivanti dalla conduzione dell'unità sotto l'influenza di alcol o in stato di alterazione psico-fisica per l'uso di sostanze stupefacenti o psicotrope",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_1": "l'abbandono dell'unità in pericolo da parte del comandante, non scendendo per ultimo da bordo.",
        "ris_1": "F",
        "progressivo": "1.3.2-7",
        "immagine": "",
        "ris_3": "V",
        "id": 267,
        "ris_2": "F",
        "risposta_3": "l'assunzione del comando o della condotta di un'unità da diporto in stato di ebbrezza.",
        "risposta_2": "l'omissione di tentare il salvataggio nei confronti di un'altra unità  in pericolo di perdersi, qualora non comporti grave rischio per l'unità soccorritrice.",
        "domanda": "Quale tra questi comportamenti prevede, oltre all'elevazione di un illecito amministrativo, anche l'applicazione della sanzione accessoria della sospensione della licenza di navigazione?"
    },
    {
        "ris_1": "V",
        "risposta_1": "raddoppia in caso di sinistro.",
        "progressivo": "1.3.2-8",
        "ris_3": "F",
        "id": 268,
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "argomento": "Rischi derivanti dalla conduzione dell'unità sotto l'influenza di alcol o in stato di alterazione psico-fisica per l'uso di sostanze stupefacenti o psicotrope",
        "immagine": "",
        "domanda": "La sanzione per chi assume il comando o la condotta di un'unità da diporto in stato di alterazione psico-fisica:",
        "risposta_2": "è stabilita dalla Capitaneria di porto all'atto del fermo.",
        "risposta_3": "aumentata del 25% in caso di sinistro.",
        "ris_2": "F"
    },
    {
        "domanda": "Gli effetti dell'alcol:",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "id": 269,
        "argomento": "Rischi derivanti dalla conduzione dell'unità sotto l'influenza di alcol o in stato di alterazione psico-fisica per l'uso di sostanze stupefacenti o psicotrope",
        "immagine": "",
        "progressivo": "1.3.2-9",
        "risposta_1": "si riducono nel giro di pochi minuti.",
        "risposta_2": "perdurano anche fino a 5 ore.",
        "ris_1": "F",
        "ris_2": "V",
        "ris_3": "F",
        "risposta_3": "si annullano dopo 2 ore."
    },
    {
        "progressivo": "1.1.1-27",
        "id": 27,
        "immagine": "",
        "risposta_1": "la poppa e la prima paratia.",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_2": "F",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "risposta_3": "il fondo all'interno dello scafo e il pagliolo (o pagliolato).",
        "domanda": "La sentina di un'unità è lo spazio compreso tra:",
        "ris_1": "F",
        "ris_3": "V",
        "risposta_2": "la dritta e la sinistra dell'unità."
    },
    {
        "risposta_1": "è disposta la vendita coatta dell'unità da diporto.",
        "ris_2": "F",
        "ris_1": "F",
        "risposta_2": "le sanzioni previste sono aumentate del 25%, qualora sia stato accertato un valore corrispondente a un tasso alcolemico superiore a 0,8 grammi per litro.",
        "id": 270,
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "progressivo": "1.3.2-10",
        "domanda": "In caso di conduzione sotto l'influenza dell'alcool di unità da diporto adibita a noleggio:",
        "immagine": "",
        "ris_3": "V",
        "risposta_3": "la patente nautica è sempre revocata, qualora sia stato accertato un valore corrispondente a un tasso alcolemico superiore a 1,5 grammi per litro .",
        "argomento": "Rischi derivanti dalla conduzione dell'unità sotto l'influenza di alcol o in stato di alterazione psico-fisica per l'uso di sostanze stupefacenti o psicotrope"
    },
    {
        "id": 271,
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "F",
        "immagine": "",
        "ris_3": "F",
        "progressivo": "1.3.2-11",
        "risposta_2": "ha effetti molto pericolosi se contemporaneamente si assumono bevande alcoliche.",
        "ris_2": "V",
        "domanda": "L'assunzione di farmaci sedativi:",
        "risposta_1": "non compromette le facoltà mentali se si soffre d'ansia.",
        "risposta_3": "aiuta la concentrazione specialmente di notte.",
        "argomento": "Rischi derivanti dalla conduzione dell'unità sotto l'influenza di alcol o in stato di alterazione psico-fisica per l'uso di sostanze stupefacenti o psicotrope"
    },
    {
        "ris_3": "F",
        "risposta_2": "si ha un livello di attenzione molto basso.",
        "risposta_3": "si recupera velocemente l'idoneità fisica se si assumono cibi piuttosto salati.",
        "progressivo": "1.3.2-12",
        "immagine": "",
        "ris_1": "F",
        "argomento": "Rischi derivanti dalla conduzione dell'unità sotto l'influenza di alcol o in stato di alterazione psico-fisica per l'uso di sostanze stupefacenti o psicotrope",
        "id": 272,
        "risposta_1": "si recupera velocemente l'idoneità fisica se si assume caffè amaro.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_2": "V",
        "domanda": "In caso di assunzione di una quantità eccessiva di bevande alcoliche:"
    },
    {
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "risposta_3": "quando naviga oltre le 6 miglia nautiche dalla costa.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_1": "sempre.",
        "immagine": "",
        "progressivo": "1.3.3-1",
        "ris_3": "V",
        "id": 273,
        "risposta_2": "solo quando si naviga fuori dalle acque territoriali.",
        "domanda": "Per un'imbarcazione da diporto quando è obbligatorio l'uso dell'apparato VHF?",
        "ris_1": "F",
        "ris_2": "F"
    },
    {
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "V",
        "immagine": "",
        "progressivo": "1.3.3-2",
        "id": 274,
        "risposta_3": "non più di 4.",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "risposta_2": "non meno di 2.",
        "risposta_1": "almeno 1.",
        "ris_2": "F",
        "domanda": "A bordo del natante da diporto, durante la navigazione entro le 6 miglia dalla costa, quanti estintori devono essere presenti a bordo?",
        "ris_3": "F"
    },
    {
        "ris_1": "V",
        "progressivo": "1.3.3-3",
        "ris_3": "F",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_2": "F",
        "risposta_1": "diurno.",
        "immagine": "",
        "risposta_3": "che si può utilizzare solo in presenza di nebbia.",
        "id": 275,
        "risposta_2": "notturno.",
        "domanda": "La \"boetta fumogena arancione\" è un segnale:"
    },
    {
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "2 fuochi a mano a luce rossa, 1 boetta luminosa.",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "id": 276,
        "immagine": "",
        "ris_3": "F",
        "domanda": "Quale risposta indica correttamente tipologia e quantità di tutte le dotazioni luminose d'emergenza prescritte in caso di navigazione entro le 12 miglia dalla costa:",
        "risposta_1": "1 boetta luminosa, 2 fuochi a mano a luce rossa, 2 razzi a paracadute a luce rossa.",
        "ris_2": "F",
        "risposta_3": "2 boette luminose, 3 fuochi a mano a luce rossa, 3 razzi a paracadute a luce rossa.",
        "ris_1": "V",
        "progressivo": "1.3.3-4"
    },
    {
        "ris_1": "F",
        "id": 277,
        "progressivo": "1.3.3-5",
        "domanda": "Quali delle seguenti dotazioni devono essere obbligatoriamente a bordo di un'imbarcazione da diporto che naviga entro 12 miglia dalla costa?",
        "risposta_1": "1 orologio.",
        "immagine": "",
        "ris_2": "V",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "ris_3": "F",
        "risposta_2": "1 bussola e tabelle delle deviazioni bussola.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_3": "1 binocolo."
    },
    {
        "risposta_2": "cinture di salvataggio per ogni persona imbarcata.",
        "risposta_3": "zattere di salvataggio per tutte le persone imbarcabili a bordo.",
        "progressivo": "1.3.3-6",
        "ris_3": "F",
        "ris_1": "F",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "immagine": "",
        "risposta_1": "apparecchi galleggianti per tutte le persone imbarcabili a bordo.",
        "ris_2": "V",
        "id": 278,
        "domanda": "Secondo il Regolamento per la sicurezza della navigazione da diporto, quali sono i mezzi individuali di salvataggio?"
    },
    {
        "ris_2": "V",
        "domanda": "Secondo il Regolamento per la sicurezza della navigazione da diporto, quante boette fumogene deve avere un'imbarcazione abilitata a navigare entro le 12 miglia dalla costa?",
        "progressivo": "1.3.3-7",
        "ris_3": "F",
        "ris_1": "F",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "risposta_3": 3,
        "risposta_1": 1,
        "immagine": "",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "id": 279,
        "risposta_2": 2
    },
    {
        "id": 28,
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_1": "F",
        "progressivo": "1.1.1-28",
        "domanda": "La linea che separa l'opera viva dall'opera morta è denominata:",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "immagine": "",
        "risposta_3": "linea di chiglia.",
        "risposta_2": "linea di galleggiamento.",
        "risposta_1": "linea di bordo libero.",
        "ris_3": "F",
        "ris_2": "V"
    },
    {
        "ris_1": "F",
        "risposta_1": "in navigazione notturna entro 1 miglio dalla costa.",
        "domanda": "In base alla Tabella delle dotazioni di sicurezza minime da tenere a bordo (Allegato V al DM 146/2008), i fanali regolamentari di navigazione sono obbligatoriamente prescritti:",
        "progressivo": "1.3.3-8",
        "risposta_3": "comunque e sempre, a prescindere dal tipo di navigazione effettuata.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "ris_3": "F",
        "risposta_2": "in navigazione notturna oltre 1 miglio dalla costa.",
        "id": 280,
        "immagine": "",
        "ris_2": "V"
    },
    {
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "domanda": "Il mezzo collettivo di salvataggio minimo per le imbarcazioni da diporto in navigazione entro le 12 miglia dalla costa è:",
        "risposta_2": "la zattera di salvataggio autogonfiabile costiera per la navigazione entro 12 miglia dalla costa.",
        "ris_2": "V",
        "ris_3": "F",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "ris_1": "F",
        "risposta_1": "la lancia di salvataggio.",
        "risposta_3": "non è previsto il mezzo collettivo di salvataggio entro le 12 miglia dalla costa.",
        "immagine": "",
        "id": 281,
        "progressivo": "1.3.3-9"
    },
    {
        "risposta_3": "contiene medicinali e dotazioni previste da apposito decreto ministeriale.",
        "ris_2": "F",
        "risposta_2": "è obbligatoria oltre le 6 miglia.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "domanda": "La cassetta di pronto soccorso:",
        "ris_3": "V",
        "id": 282,
        "progressivo": "1.3.3-10",
        "risposta_1": "è dimensionata al numero di persone trasportabili dall'unità.",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "immagine": "",
        "ris_1": "F"
    },
    {
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "risposta_3": 1,
        "progressivo": "1.3.3-11",
        "id": 283,
        "risposta_1": 2,
        "risposta_2": 3,
        "immagine": "",
        "domanda": "Quante boette fumogene deve mantenere a bordo un natante da diporto che naviga entro le 3 miglia nautiche dalla costa?",
        "ris_1": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_3": "V",
        "ris_2": "F"
    },
    {
        "ris_3": "F",
        "risposta_3": "per la navigazione entro le 12 miglia dalla costa.",
        "domanda": "L’obbligo di legge di avere una cintura di salvataggio per ogni persona imbarcata è prescritto:",
        "progressivo": "1.3.3-12",
        "risposta_2": "per ogni tipo di navigazione oltre i 300 metri dalla costa.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "immagine": "",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "ris_1": "F",
        "id": 284,
        "risposta_1": "non è prescritto per i natanti da diporto a motore.",
        "ris_2": "V"
    },
    {
        "immagine": "",
        "ris_3": "F",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "1 zattera di salvataggio costiera.",
        "risposta_3": "1 zattera di salvataggio omologata.",
        "ris_1": "V",
        "domanda": "Per le imbarcazioni da diporto in navigazione entro le 3 miglia dalla costa, quale mezzo collettivo di salvataggio è prescritto?",
        "id": 285,
        "ris_2": "F",
        "risposta_1": "non è previsto il mezzo collettivo di salvataggio.",
        "progressivo": "1.3.3-13"
    },
    {
        "risposta_1": "il mezzo collettivo di salvataggio.",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "id": 286,
        "progressivo": "1.3.3-14",
        "risposta_3": "i 2 razzi a paracadute a luce rossa.",
        "domanda": "Navigando entro le 3 miglia dalla costa, si deve tenere a bordo dell'unità:",
        "immagine": "",
        "ris_3": "F",
        "ris_1": "F",
        "ris_2": "V",
        "risposta_2": "i 2 fuochi a mano a luce rossa."
    },
    {
        "progressivo": "1.3.3-15",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "F",
        "risposta_1": "si, in quanto trattasi di navigazione fluviale, essa è considerata più sicura rispetto alla navigazione in mare.",
        "id": 287,
        "domanda": "In navigazione entro 300 metri dalla costa non vi è obbligo di alcuna dotazione di sicurezza e di salvataggio. Quanto detto vale anche per la navigazione nei fiumi?",
        "immagine": "",
        "risposta_2": "no, bisogna avere a bordo almeno 1 salvagente anulare con cima e cinture di salvataggio per ogni persona presente a bordo.",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "ris_2": "V",
        "ris_3": "F",
        "risposta_3": "no, sono sufficienti almeno 2 salvagenti anulari con cima."
    },
    {
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "risposta_2": "6 miglia.",
        "immagine": "",
        "ris_1": "F",
        "risposta_3": "5 miglia.",
        "ris_3": "F",
        "domanda": "Normalmente, qual è la portata in miglia dei fuochi a mano a luce rossa?",
        "id": 288,
        "progressivo": "1.3.3-16",
        "ris_2": "V",
        "risposta_1": "7 miglia.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE"
    },
    {
        "progressivo": "1.3.3-17",
        "risposta_3": "25 miglia.",
        "domanda": "Normalmente, di quanto è la portata notturna in miglia dei razzi a paracadute a luce rossa?",
        "risposta_2": "7 miglia.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_2": "F",
        "risposta_1": "6 miglia.",
        "immagine": "",
        "ris_3": "V",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "ris_1": "F",
        "id": 289
    },
    {
        "ris_1": "F",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_2": "V",
        "ris_3": "F",
        "risposta_1": "murata.",
        "immagine": "",
        "id": 29,
        "risposta_3": "dormiente.",
        "progressivo": "1.1.1-29",
        "risposta_2": "pagliolato.",
        "domanda": "Come si chiama il pavimento interno di un'imbarcazione da diporto?",
        "argomento": "Nomenclatura delle parti principali dello scafo"
    },
    {
        "domanda": "Quanti fuochi a mano a luce rossa deve mantenere a bordo un'imbarcazione da diporto che naviga entro le 6 miglia nautiche dalla costa?",
        "risposta_1": 2,
        "ris_2": "F",
        "ris_3": "F",
        "id": 290,
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "progressivo": "1.3.3-18",
        "risposta_3": 4,
        "immagine": "",
        "risposta_2": 3,
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "ris_1": "V"
    },
    {
        "domanda": "Su un’unità con a bordo 4 persone, abilitata al trasporto di 8 persone, quante cinture di salvataggio devono essere presenti?",
        "ris_1": "F",
        "risposta_1": 12,
        "risposta_3": 4,
        "progressivo": "1.3.3-19",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "ris_3": "V",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "id": 291,
        "ris_2": "F",
        "risposta_2": 8,
        "immagine": ""
    },
    {
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_3": "arancione.",
        "ris_3": "V",
        "risposta_2": "giallo.",
        "ris_1": "F",
        "immagine": "",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "domanda": "Il fumo emesso dalla boetta fumogena, una volta attivata, è di colore:",
        "risposta_1": "rosso.",
        "ris_2": "F",
        "progressivo": "1.3.3-20",
        "id": 292
    },
    {
        "risposta_2": "ogni anno",
        "id": 293,
        "risposta_1": "ogni 10 anni",
        "ris_2": "F",
        "ris_3": "V",
        "domanda": "In genere, che scadenza hanno i segnali di emergenza e soccorso come i fuochi a mano, i razzi a paracadute e le boette fumogene?",
        "immagine": "",
        "risposta_3": "ogni 4 anni",
        "progressivo": "1.3.3-21",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "ris_1": "F"
    },
    {
        "immagine": "",
        "ris_3": "V",
        "risposta_1": "entro 12 miglia dalla costa.",
        "ris_2": "F",
        "domanda": "L'E.P.I.R.B. (Emergency Position Indicator Radio Beacon) è obbligatorio:",
        "risposta_2": "entro 50 miglia dalla costa.",
        "ris_1": "F",
        "risposta_3": "oltre 50 miglia dalla costa.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "progressivo": "1.3.3-22",
        "id": 294,
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio"
    },
    {
        "ris_1": "F",
        "progressivo": "1.3.3-23",
        "domanda": "Quali sono le dotazioni luminose d'emergenza per le imbarcazioni abilitate a navigare \"senza alcun limite\" dalla costa?",
        "id": 295,
        "ris_3": "V",
        "immagine": "",
        "risposta_3": "4 fuochi a mano a luce rossa, 4 razzi a paracadute a luce rossa, 1 boetta luminosa.",
        "risposta_1": "3 fuochi a mano a luce rossa, 3 razzi a paracadute a luce rossa.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "3 fuochi a mano a luce rossa, 3 razzi a paracadute a luce rossa, 1 boetta luminosa.",
        "ris_2": "F",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio"
    },
    {
        "risposta_1": "oltre 12 miglia dalla costa:",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "ris_3": "F",
        "risposta_2": "entro 6 miglia dalla costa.",
        "immagine": "",
        "ris_2": "F",
        "risposta_3": "entro 12 miglia dalla costa.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "domanda": "Il riflettore radar è obbligatorio quando le imbarcazioni navigano:",
        "id": 296,
        "progressivo": "1.3.3-24",
        "ris_1": "V"
    },
    {
        "domanda": "Una zattera di salvataggio deve essere revisionata:",
        "risposta_2": "ogni due anni.",
        "ris_3": "F",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "ris_2": "V",
        "id": 297,
        "risposta_3": "ogni tre anni.",
        "risposta_1": "annualmente.",
        "progressivo": "1.3.3-25",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "F",
        "immagine": ""
    },
    {
        "id": 298,
        "risposta_1": "programmato con il codice MMSI assegnato dal Ministero dello Sviluppo economico.",
        "ris_2": "F",
        "ris_1": "V",
        "progressivo": "1.3.3-26",
        "risposta_2": "programmato con il codice MMSI assegnato dalle Direzioni Marittime.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "domanda": "L'E.P.I.R.B. è un trasmettitore di emergenza:",
        "ris_3": "F",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "risposta_3": "programmato con il codice MMSI assegnato dal Ministero degli Interni.",
        "immagine": ""
    },
    {
        "ris_2": "V",
        "immagine": "",
        "ris_3": "F",
        "ris_1": "F",
        "risposta_3": "è in relazione al numero massimo di persone imbarcabili.",
        "domanda": "La quantità di cinture di salvataggio da tenere a bordo:",
        "progressivo": "1.3.3-27",
        "risposta_2": "è in relazione al numero di persone imbarcate.",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "risposta_1": "deve essere il 20% in più del numero massimo di persone imbarcabili.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "id": 299
    },
    {
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "immagine": "",
        "ris_2": "V",
        "capitolo": "TEORIA DELLO SCAFO",
        "domanda": "Quale funzione svolge la sentina di un'unità navale?",
        "progressivo": "1.1.1-3",
        "risposta_3": "contenere le acque dolci.",
        "ris_1": "F",
        "id": 3,
        "risposta_1": "contenere il carburante.",
        "risposta_2": "contenere le acque sporche e i residui liquidi.",
        "ris_3": "F"
    },
    {
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "risposta_2": "un punto specifico della murata ove appoggiare i parabordi tutte le volte che si esegue l'ormeggio di fianco.",
        "progressivo": "1.1.1-30",
        "immagine": "",
        "id": 30,
        "ris_1": "F",
        "domanda": "La battagliola è:",
        "ris_3": "V",
        "risposta_3": "una sorta di ringhiera laterale per aiutare il passaggio tra poppa e prora.",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_2": "F",
        "risposta_1": "un'apertura praticata in coperta."
    },
    {
        "risposta_1": "9 miglia.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_3": "F",
        "domanda": "Normalmente, qual è all'incirca la portata diurna dei razzi a paracadute a luce rossa?",
        "id": 300,
        "risposta_3": "5 miglia.",
        "progressivo": "1.3.3-28",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "risposta_2": "7 miglia.",
        "ris_2": "V",
        "immagine": "",
        "ris_1": "F"
    },
    {
        "risposta_2": "circa 3 minuti.",
        "risposta_3": "meno di 1 minuto.",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "id": 301,
        "immagine": "",
        "progressivo": "1.3.3-29",
        "ris_2": "F",
        "domanda": "Normalmente, qual è la durata di accensione dei razzi a paracadute a luce rossa utilizzata da un'imbarcazione da diporto?",
        "ris_3": "V",
        "ris_1": "F",
        "risposta_1": "circa 2 minuti."
    },
    {
        "risposta_1": "si, sempre.",
        "progressivo": "1.3.3-30",
        "id": 302,
        "immagine": "",
        "domanda": "E' obbligatorio il radar?",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "ris_2": "F",
        "ris_3": "V",
        "ris_1": "F",
        "risposta_2": "si solo oltre le 12 miglia dalla costa.",
        "risposta_3": "no, ma è consigliato per la navigazione notturna.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE"
    },
    {
        "domanda": "Quali sono i range di dotazioni corrette per fanali e segnali luminosi?",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "ris_1": "V",
        "id": 303,
        "risposta_1": "fino a 3 miglia, fino a 6 miglia, fino a 12 miglia, fino a 50 miglia, senza limiti dalla costa.",
        "risposta_2": "fino a 12 miglia, oltre 12 miglia dalla costa.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_3": "F",
        "ris_2": "F",
        "risposta_3": "fino a 6 miglia, fino a 50 miglia, senza limiti dalla costa.",
        "immagine": "",
        "progressivo": "1.3.3-31"
    },
    {
        "risposta_3": "la navigazione entro 3 miglia ed entro 6 miglia dalla costa prevede le medesime dotazioni luminose d'emergenza.",
        "risposta_1": "la navigazione entro 12 miglia e fino a 50 miglia dalla costa prevede le medesime dotazioni luminose d'emergenza.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_2": "V",
        "ris_3": "F",
        "risposta_2": "la navigazione entro 12 miglia e fino a 50 miglia dalla costa prevede diverse dotazioni luminose d'emergenza.",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "immagine": "",
        "id": 304,
        "domanda": "Quale affermazione è corretta?",
        "progressivo": "1.3.3-32",
        "ris_1": "F"
    },
    {
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_1": "per la navigazione oltre 6 miglia dalla costa, senza limiti.",
        "ris_2": "V",
        "risposta_3": "per la navigazione oltre 1 miglio dalla costa.",
        "progressivo": "1.3.3-33",
        "ris_3": "F",
        "ris_1": "F",
        "id": 305,
        "risposta_2": "per la navigazione oltre 6 miglia dalla costa, fino alle 12 miglia dalla costa.",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "immagine": "",
        "domanda": "E' necessaria la zattera costiera:"
    },
    {
        "progressivo": "1.3.3-34",
        "immagine": "",
        "ris_1": "F",
        "risposta_1": "quando naviga entro le 24 miglia nautiche dalla costa.",
        "domanda": "Per un'imbarcazione da diporto quando è obbligatorio l'uso dell'EPIRB?",
        "risposta_3": "quando naviga entro le 50 miglia nautiche dalla costa.",
        "risposta_2": "quando naviga oltre le 50 miglia nautiche dalla costa.",
        "id": 306,
        "ris_2": "V",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_3": "F"
    },
    {
        "risposta_3": "quando naviga entro le 6 miglia dalla costa.",
        "progressivo": "1.3.3-35",
        "ris_2": "F",
        "id": 307,
        "risposta_2": "quando naviga oltre 6 miglia dalla costa, fino alle 12 miglia dalla costa.",
        "ris_3": "V",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_1": "quando naviga oltre le 12 miglia nautiche dalla costa.",
        "ris_1": "F",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "domanda": "La zattera di salvataggio costiera non è prevista:",
        "immagine": ""
    },
    {
        "risposta_3": "Mai.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "immagine": "",
        "domanda": "La zattera di salvataggio (non costiera) per tutte le persone presenti a bordo quando deve essere mantenuta su un'imbarcazione da diporto?",
        "ris_3": "F",
        "risposta_1": "Quando naviga oltre le 12 miglia nautiche dalla costa.",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "ris_2": "F",
        "risposta_2": "Quando naviga entro le 12 miglia nautiche dalla costa.",
        "id": 308,
        "progressivo": "1.3.3-36",
        "ris_1": "V"
    },
    {
        "id": 309,
        "risposta_2": "certificazione di omologazione.",
        "ris_1": "F",
        "progressivo": "1.3.3-37",
        "risposta_1": "certificato di stazza.",
        "risposta_3": "manuale del proprietario.",
        "immagine": "",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "ris_3": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "domanda": "Su quale documento è riportato il numero delle persone trasportabili sui natanti da diporto prodotti in serie?",
        "ris_2": "V"
    },
    {
        "ris_2": "F",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_2": "la stazza esprime la larghezza totale della scafo.",
        "id": 31,
        "risposta_1": "l'insieme di draglie e candelieri costituisce la battagliola a protezione del camminamento per il passaggio tra poppa e prora.",
        "progressivo": "1.1.1-31",
        "risposta_3": "l'opera viva è la parte emersa dello scafo.",
        "domanda": "Quale affermazione, tra le seguenti, è corretta:",
        "immagine": "",
        "ris_1": "V",
        "ris_3": "F",
        "argomento": "Nomenclatura delle parti principali dello scafo"
    },
    {
        "immagine": "",
        "ris_2": "V",
        "id": 310,
        "risposta_3": "entro 300 metri dalla costa.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "indipendentemente dalla distanza dalla costa.",
        "ris_1": "F",
        "domanda": "A che distanza dalla costa i conduttori di tavole a vela hanno l'obbligo di indossare il mezzo di salvataggio individuale?",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "ris_3": "F",
        "progressivo": "1.3.3-38",
        "risposta_1": "entro 1 miglia dalla costa."
    },
    {
        "risposta_2": "sì, sempre.",
        "risposta_1": "no, fino ad un massimo di sei persone a bordo.",
        "progressivo": "1.3.3-39",
        "immagine": "",
        "ris_3": "V",
        "id": 311,
        "domanda": "Per un'imbarcazione da diporto che naviga entro le 12 miglia nautiche dalla costa è obbligatorio avere a bordo la zattera di salvataggio (non costiera)?",
        "ris_1": "F",
        "ris_2": "F",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "risposta_3": "No.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE"
    },
    {
        "ris_1": "F",
        "progressivo": "1.3.3-40",
        "risposta_3": "quando naviga oltre le 6 miglia nautiche dalla costa.",
        "ris_2": "F",
        "risposta_1": "quando naviga entro le 6 miglia nautiche dalla costa.",
        "immagine": "",
        "domanda": "Per un'imbarcazione da diporto quando è obbligatorio l'uso dell'apparato VHF?",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "risposta_2": "quando è autorizzata alla navigazione occasionale.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_3": "V",
        "id": 312
    },
    {
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "domanda": "Quanti fuochi a mano a luce rossa deve mantenere a bordo un'imbarcazione da diporto che naviga entro le 50 miglia nautiche dalla costa?",
        "risposta_2": "tre.",
        "progressivo": "1.3.3-41",
        "risposta_1": "uno.",
        "ris_1": "F",
        "ris_3": "F",
        "immagine": "",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "ris_2": "V",
        "id": 313,
        "risposta_3": "due."
    },
    {
        "risposta_3": "due.",
        "risposta_2": "tre.",
        "immagine": "",
        "id": 314,
        "domanda": "Quanti razzi a paracadute a luce rossa deve mantenere a bordo una imbarcazione da diporto che naviga entro le 50 miglia nautiche dalla costa?",
        "ris_2": "V",
        "ris_3": "F",
        "risposta_1": "quattro.",
        "progressivo": "1.3.3-42",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "F"
    },
    {
        "immagine": "",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "domanda": "Per un'imbarcazione da diporto quando è obbligatorio l'uso del binocolo?",
        "risposta_2": "quando naviga entro le 12 miglia nautiche dalla costa.",
        "risposta_1": "sempre.",
        "risposta_3": "quando naviga oltre le 12 miglia nautiche dalla costa.",
        "id": 315,
        "ris_2": "F",
        "ris_3": "V",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "progressivo": "1.3.3-43",
        "ris_1": "F"
    },
    {
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "domanda": "I conduttori di tavole a vela, acquascooter e unità similari:",
        "risposta_1": "indossano permanentemente un mezzo di salvataggio individuale, comprese le persone trasportate, indipendentemente dalla distanza dalla costa in cui la navigazione si svolge.",
        "immagine": "",
        "ris_1": "V",
        "risposta_3": "indossano permanentemente un mezzo di salvataggio individuale, esclusivamente in navigazione entro le sei miglia nautiche dalla costa, incluse le persone trasportate.",
        "risposta_2": "indossano permanentemente un mezzo di salvataggio individuale, indipendentemente dalla distanza dalla costa in cui la navigazione si svolge, a esclusione delle persone trasportate.",
        "ris_3": "F",
        "ris_2": "F",
        "id": 316,
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "progressivo": "1.3.3-44"
    },
    {
        "risposta_2": "sono fissate nel decreto 1° ottobre 2015 del Ministero della Salute.",
        "risposta_3": "sono stabilite dal comandante dell'unità.",
        "domanda": "Quali sono i medicinali e gli oggetti di medicazione di cui devono essere provviste le unità navali da diporto?",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "ris_2": "V",
        "id": 317,
        "ris_3": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "immagine": "",
        "progressivo": "1.3.3-45",
        "ris_1": "F",
        "risposta_1": "sono stabilite nel Regolamento di esecuzione al Codice della navigazione."
    },
    {
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "domanda": "Le dotazione minima indispensabile del materiale sanitario di cui devono essere dotate le imbarcazioni da diporto impiegate in attività di noleggio \"senza limiti\" dalla costa è la medesima di quella delle unità non impiegate in attività di noleggio?",
        "ris_2": "V",
        "risposta_2": "no, è fissata in un apposita tabella.",
        "risposta_1": "si.",
        "immagine": "",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "F",
        "risposta_3": "si, ma raddoppia la dotazione di cotone.",
        "ris_3": "F",
        "id": 318,
        "progressivo": "1.3.3-46"
    },
    {
        "risposta_1": "Tabella \"A\".",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "immagine": "",
        "progressivo": "1.3.3-47",
        "ris_1": "F",
        "ris_2": "F",
        "risposta_2": "Tabella \"B\".",
        "domanda": "Qual è la Tabella delle dotazioni contenente la quantità minima indispensabile del materiale sanitario di cui devono essere dotate le imbarcazioni da diporto abilitate alla navigazione senza alcun limite, senza personale imbarcato e non impiegate in attività di noleggio?",
        "risposta_3": "Tabella \"D\".",
        "ris_3": "V",
        "id": 319
    },
    {
        "risposta_1": "sezione maestra dell'opera morta.",
        "ris_3": "V",
        "risposta_3": "struttura che costituisce il guscio dell'unità.",
        "ris_1": "F",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "immagine": "",
        "progressivo": "1.1.1-32",
        "id": 32,
        "domanda": "Lo scafo di un'unità da diporto è la:",
        "risposta_2": "sezione maestra dell'opera viva.",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_2": "F"
    },
    {
        "domanda": "Quale tra queste affermazioni è corretta?",
        "argomento": "Dotazioni di sicurezza e mezzi di salvataggio",
        "ris_3": "F",
        "risposta_1": "le unità da diporto in navigazione entro le 12 miglia nautiche dalla costa devono essere dotate di una cassetta di pronto soccorso.",
        "ris_2": "V",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "le unità da diporto in navigazione oltre le 12 miglia nautiche dalla costa devono essere dotate di una cassetta di pronto soccorso.",
        "ris_1": "F",
        "progressivo": "1.3.3-48",
        "risposta_3": "le unità da diporto in navigazione entro le 3 miglia nautiche dalla costa devono essere dotate di una cassetta di pronto soccorso.",
        "immagine": "",
        "id": 320
    },
    {
        "ris_1": "F",
        "ris_2": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "progressivo": "1.3.4-1",
        "id": 321,
        "risposta_3": "presso qualsiasi STED (Sportello Telematico del Diportista) in seguito alla visita ispettiva dell'Organismo tecnico.",
        "risposta_2": "non è possibile convalidare il certificato di sicurezza.",
        "ris_3": "V",
        "risposta_1": "presso qualsiasi agenzia di sicurezza per la navigazione.",
        "argomento": "Tipi di visite, loro periodicità e certificazioni",
        "domanda": "Dove è possibile convalidare il certificato di sicurezza?",
        "immagine": ""
    },
    {
        "argomento": "Tipi di visite, loro periodicità e certificazioni",
        "risposta_2": "solo se l'unità viene fermata per un controllo della Capitaneria di porto - Guardia costiera.",
        "ris_2": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "progressivo": "1.3.4-2",
        "ris_3": "V",
        "immagine": "",
        "risposta_1": "solo se l'unità viene messa a mare dopo essere stata a secco.",
        "risposta_3": "a seguito di danni o mutamenti dello scafo o dell'apparato motore, se sono mutate le condizioni di navigabilità o di sicurezza.",
        "id": 322,
        "domanda": "Un'unità da diporto deve essere sottoposta a visita occasionale:",
        "ris_1": "F"
    },
    {
        "id": 323,
        "risposta_2": "solo su richiesta dell'Autorità marittima.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_1": "periodiche ed occasionali.",
        "immagine": "",
        "domanda": "Un'imbarcazione da diporto munita di Marcatura CE, è soggetta a visite:",
        "risposta_3": "in caso di ritiro della licenza di navigazione.",
        "ris_3": "F",
        "ris_2": "F",
        "argomento": "Tipi di visite, loro periodicità e certificazioni",
        "ris_1": "V",
        "progressivo": "1.3.4-3"
    },
    {
        "ris_2": "F",
        "ris_1": "F",
        "id": 324,
        "risposta_3": "solo le imbarcazioni e le navi da diporto.",
        "argomento": "Tipi di visite, loro periodicità e certificazioni",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_3": "V",
        "domanda": "Quali unità da diporto sono soggette alle visite periodiche di sicurezza?",
        "progressivo": "1.3.4-4",
        "risposta_1": "solo le imbarcazioni da diporto.",
        "risposta_2": "solo i natanti da diporto.",
        "immagine": ""
    },
    {
        "progressivo": "1.3.4-5",
        "ris_1": "V",
        "risposta_3": "dall'Organismo tecnico notificato o autorizzato.",
        "risposta_2": "dalla ditta costruttrice.",
        "immagine": "",
        "argomento": "Tipi di visite, loro periodicità e certificazioni",
        "ris_3": "F",
        "risposta_1": "dall'Archivio telematico delle unità da diporto attraverso lo STED, Sportello telematico del diportista",
        "ris_2": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "domanda": "Il certificato di sicurezza per imbarcazioni da diporto è rilasciato:",
        "id": 325
    },
    {
        "risposta_3": "rilascia la Licenza di Navigazione.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "argomento": "Tipi di visite, loro periodicità e certificazioni",
        "ris_1": "F",
        "risposta_1": "rilascia la licenza di esercizio RTF.",
        "immagine": "",
        "ris_2": "V",
        "risposta_2": "determina il numero massimo delle persone trasportabili.",
        "ris_3": "F",
        "progressivo": "1.3.4-6",
        "id": 326,
        "domanda": "Nel corso della visita iniziale di sicurezza, l'Organismo tecnico notificato o autorizzato:"
    },
    {
        "domanda": "L'imbarcazione da diporto iscritta è sottoposta a visita occasionale:",
        "ris_3": "V",
        "ris_1": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_1": "l'unità stessa viene iscritta in un registro straniero.",
        "risposta_2": "l'unità stessa deve affrontare una navigazione di trasferimento.",
        "argomento": "Tipi di visite, loro periodicità e certificazioni",
        "risposta_3": "se ne verifica la necessità.",
        "ris_2": "F",
        "progressivo": "1.3.4-7",
        "immagine": "",
        "id": 327
    },
    {
        "argomento": "Tipi di visite, loro periodicità e certificazioni",
        "risposta_3": "10 anni.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "id": 328,
        "risposta_2": "5 anni.",
        "ris_2": "V",
        "domanda": "Superata la prima scadenza del certificato di sicurezza, ogni quanti anni deve essere sottoposta a visita un'imbarcazione da diporto ai fini del rinnovo del certificato medesimo?",
        "ris_3": "F",
        "immagine": "",
        "ris_1": "F",
        "risposta_1": "8 anni.",
        "progressivo": "1.3.4-8"
    },
    {
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "si, ogni 5 anni.",
        "risposta_3": "no, si convalida il certificato di sicurezza.",
        "argomento": "Tipi di visite, loro periodicità e certificazioni",
        "risposta_1": "si, ogni 3 anni.",
        "domanda": "La licenza è sottoposta a convalida?",
        "ris_2": "F",
        "immagine": "",
        "ris_1": "F",
        "id": 329,
        "ris_3": "V",
        "progressivo": "1.3.4-9"
    },
    {
        "id": 33,
        "risposta_3": "la parte più bassa dello scafo, immediatamente al di sotto del motore, dove si raccolgono eventuali perdite di fluidi.",
        "immagine": "",
        "risposta_1": "un foro per il quale passa la catena dell'ancora quando viene dato fondo.",
        "ris_2": "V",
        "domanda": "La \"losca\" è:",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "ris_1": "F",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_2": "un'apertura, ricavata nella poppa, per la quale passa l'asse del timone.",
        "progressivo": "1.1.1-33",
        "ris_3": "F"
    },
    {
        "risposta_3": "no, non sono soggetti.",
        "progressivo": "1.3.4-10",
        "ris_2": "F",
        "id": 330,
        "risposta_1": "sì, ogni 3 anni.",
        "argomento": "Tipi di visite, loro periodicità e certificazioni",
        "ris_3": "V",
        "ris_1": "F",
        "domanda": "I natanti da diporto sono soggetti al rilascio del certificato di sicurezza?",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "sì, ogni 5 anni.",
        "immagine": ""
    },
    {
        "risposta_1": "ogni qualvolta viene varata.",
        "risposta_2": "ogni 20 anni.",
        "id": 331,
        "ris_1": "F",
        "ris_3": "V",
        "domanda": "La convalida del Certificato di Sicurezza di un'imbarcazione da diporto va effettuata:",
        "argomento": "Tipi di visite, loro periodicità e certificazioni",
        "ris_2": "F",
        "progressivo": "1.3.4-11",
        "risposta_3": "nel caso in cui l'unità abbia subito gravi avarie o siano state apportate innovazioni.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "immagine": ""
    },
    {
        "ris_3": "F",
        "ris_2": "V",
        "risposta_3": "rilascio di apposita attestazione di idoneità da parte dell'Ufficio Marittimo.",
        "ris_1": "F",
        "domanda": "Quali tra questi requisiti sono necessari per il rinnovo del Certificato di Sicurezza di un'unità da diporto?",
        "risposta_1": "rilascio di apposita attestazione di idoneità da parte della Motorizzazione Civile territoriale.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "progressivo": "1.3.4-12",
        "immagine": "",
        "id": 332,
        "risposta_2": "rilascio di apposita attestazione di idoneità da parte di un Organismo Tecnico affidato.",
        "argomento": "Tipi di visite, loro periodicità e certificazioni"
    },
    {
        "id": 333,
        "risposta_1": "si rinnova di diritto ogni 3 anni.",
        "ris_1": "F",
        "immagine": "",
        "ris_2": "V",
        "ris_3": "F",
        "risposta_2": "si rinnova ogni 5 anni.",
        "risposta_3": "si rinnova quando richiesto dall'Organismo Tecnico affidato.",
        "progressivo": "1.3.4-13",
        "domanda": "Il Certificato di Sicurezza:",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "argomento": "Tipi di visite, loro periodicità e certificazioni"
    },
    {
        "ris_3": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "immagine": "",
        "progressivo": "1.3.4-14",
        "id": 334,
        "risposta_2": "ha validità di cinque anni dall'immatricolazione per le unità appartenenti alle categorie di progettazione A e B.",
        "risposta_3": "ha validità di cinque anni dall'immatricolazione per le unità appartenenti alle categorie di progettazione A, B e C.",
        "risposta_1": "ha validità di otto anni dall'immatricolazione per le unità appartenenti alle categorie di progettazione A e B.",
        "ris_2": "F",
        "domanda": "Il Certificato di Sicurezza di un'unità da diporto, in caso di primo rilascio:",
        "argomento": "Tipi di visite, loro periodicità e certificazioni",
        "ris_1": "V"
    },
    {
        "ris_1": "F",
        "id": 335,
        "risposta_3": "ha validità di cinque anni dall'immatricolazione per le unità appartenenti alle categorie di progettazione B, C e D.",
        "immagine": "",
        "domanda": "Il Certificato di Sicurezza di un'unità da diporto, in caso di primo rilascio,",
        "ris_3": "F",
        "argomento": "Tipi di visite, loro periodicità e certificazioni",
        "progressivo": "1.3.4-15",
        "risposta_1": "ha validità di otto anni dall'immatricolazione per le unità appartenenti alle categorie di progettazione C e D.",
        "risposta_2": "ha validità di dieci anni dall'immatricolazione per le unità appartenenti alle categorie di progettazione C e D.",
        "ris_2": "V",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE"
    },
    {
        "domanda": "Il certificato di sicurezza di un'unità da diporto:",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "è rinnovato con cadenza annuale.",
        "id": 336,
        "immagine": "",
        "progressivo": "1.3.4-16",
        "ris_1": "F",
        "risposta_1": "riporta il certificato di stazza per le imbarcazioni non omologate.",
        "ris_3": "V",
        "argomento": "Tipi di visite, loro periodicità e certificazioni",
        "ris_2": "F",
        "risposta_3": "è rinnovato in occasione delle visite periodiche."
    },
    {
        "ris_2": "V",
        "id": 337,
        "argomento": "Tipi di visite, loro periodicità e certificazioni",
        "progressivo": "1.3.4-17",
        "immagine": "",
        "risposta_3": "10 anni dalla data di costruzione; le successive ogni 8 anni.",
        "domanda": "Un'unità da diporto munita di marcatura CE classe B effettua la prescritta visita periodica dopo:",
        "ris_3": "F",
        "ris_1": "F",
        "risposta_2": "8 anni dalla data di immatricolazione; le successive ogni 5 anni.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_1": "10 anni dalla data di immatricolazione; le successive ogni 5 anni."
    },
    {
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "domanda": "In quale documento viene annotato l'esito della visita eseguita dall'Organismo Tecnico notificato o autorizzato?",
        "ris_1": "V",
        "risposta_3": "manuale del proprietario.",
        "progressivo": "1.3.4-18",
        "risposta_2": "licenza di navigazione.",
        "risposta_1": "certificato di sicurezza.",
        "id": 338,
        "ris_3": "F",
        "ris_2": "F",
        "argomento": "Tipi di visite, loro periodicità e certificazioni",
        "immagine": ""
    },
    {
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "id": 339,
        "ris_2": "V",
        "risposta_2": "il certificato di sicurezza è soggetto a scadenza.",
        "immagine": "",
        "progressivo": "1.3.4-19",
        "ris_1": "F",
        "ris_3": "F",
        "argomento": "Tipi di visite, loro periodicità e certificazioni",
        "risposta_1": "la patente nautica è sempre obbligatoria.",
        "domanda": "Quale affermazione è vera tra le alternative di risposta sotto riportate?",
        "risposta_3": "la licenza di navigazione è soggetta a scadenza."
    },
    {
        "risposta_3": "verticale.",
        "immagine": "",
        "risposta_1": "trasversale.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "ris_2": "F",
        "ris_1": "V",
        "capitolo": "TEORIA DELLO SCAFO",
        "progressivo": "1.1.1-34",
        "ris_3": "F",
        "risposta_2": "longitudinale.",
        "id": 34,
        "domanda": "Il beccheggio è l'oscillazione dell'unità intorno al suo asse:"
    },
    {
        "domanda": "In caso di falla irreparabile a bordo, il Comandante lancia via radio il:",
        "id": 340,
        "risposta_2": "SECURITE' ed aspetta i soccorsi.",
        "ris_2": "F",
        "ris_3": "V",
        "argomento": "Comunicazioni radiotelefoniche e relative procedure: assistenza e soccorso",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_1": "PAN PAN e mette l'equipaggio alle pompe di sentina.",
        "immagine": "",
        "progressivo": "1.3.5-1",
        "risposta_3": "MAYDAY e opera per la salvezza delle persone a bordo.",
        "ris_1": "F"
    },
    {
        "progressivo": "1.3.6-1",
        "ris_2": "V",
        "risposta_2": "tamponare la falla dall’esterno in modo che la pressione dell’acqua non faccia spostare il “tappo” di fortuna.",
        "risposta_3": "sbandare l'unità sul lato della falla.",
        "ris_3": "F",
        "id": 341,
        "immagine": "",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "F",
        "domanda": "In caso di falla, quale operazione è la più corretta tra quelle riportate nelle seguenti alternative risposta:",
        "risposta_1": "imbarcare acqua per compensare la pressione.",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)"
    },
    {
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "risposta_1": "quando si arrestano gli apparati di radio comunicazione.",
        "ris_2": "F",
        "risposta_3": "quando si conduce volontariamente l'unità navale ad incagliarsi per scongiurare un potenziale naufragio derivante da una falla, un incendio o una collisione con un'altra unità.",
        "progressivo": "1.3.6-2",
        "immagine": "",
        "ris_1": "F",
        "domanda": "Quando può verificarsi l'incaglio volontario?",
        "id": 342,
        "ris_3": "V",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "quando si decide volontariamente di disattivare gli apparati di radio navigazione."
    },
    {
        "risposta_3": "tipo di timone di cui dispone l'unità navale.",
        "immagine": "",
        "ris_3": "F",
        "ris_1": "V",
        "id": 343,
        "risposta_1": "tipo e rilievo dei fondali, entità dell'avaria subita, manovra più idonea da porre in essere in relazione alle caratteristiche dell'unità e del luogo in cui si è verificato il sinistro.",
        "ris_2": "F",
        "progressivo": "1.3.6-3",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "altezza del bordo libero.",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "domanda": "Quali fattori devono essere tenuti in debita considerazione per procedere alle operazioni di disincaglio?"
    },
    {
        "risposta_3": "l'incaglio può derivare da una scarsa accuratezza nella determinazione del punto nave in prossimità della costa.",
        "ris_1": "F",
        "id": 344,
        "ris_3": "V",
        "domanda": "Avuto riguardo all'incaglio, quale tra queste affermazioni è corretta?",
        "immagine": "",
        "risposta_1": "l'incaglio può derivare a seguito della caduta di un uomo in mare.",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "l'incaglio può derivare a seguito della eccessiva cavitazione dell'elica.",
        "ris_2": "F",
        "progressivo": "1.3.6-4"
    },
    {
        "risposta_2": "se si verifica una falla a prua è opportuno aumentare la velocità dell'unità navale per diminuire il flusso dell'acqua che tende ad allagare i compartimenti prodieri.",
        "id": 345,
        "ris_2": "F",
        "progressivo": "1.3.6-5",
        "ris_1": "V",
        "immagine": "",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "risposta_3": "se si verifica una falla a prua è opportuno spostare i pesi longitudinalmente verso proravia.",
        "ris_3": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "domanda": "Avuto riguardo alla falla, quale tra queste affermazioni è corretta?",
        "risposta_1": "se si verifica una falla nella parte prodiera è opportuno arrestare il moto dell'unità navale per evitare che l'avanzamento della stessa possa incrementare l'afflusso d'acqua nel suo interno."
    },
    {
        "risposta_1": "azionare la pompa di alimentazione.",
        "immagine": "",
        "progressivo": "1.3.6-6",
        "domanda": "Quale tra queste procedure può essere effettuata nel caso in cui un'unità abbia subito una falla di lieve entità?",
        "risposta_3": "azionare la pompa di iniezione.",
        "ris_3": "F",
        "ris_2": "V",
        "risposta_2": "azionare la pompa di sentina.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "F",
        "id": 346,
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)"
    },
    {
        "id": 347,
        "risposta_2": "la falla può compromettere la galleggiabilità dell'unità navale in quanto si verifica una riduzione della riserva di spinta dell'unità stessa.",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "domanda": "Quale tra queste affermazioni è corretta?",
        "risposta_1": "a seguito della presenza di una falla può aumentare la rigidità dello scafo dovuta all'effetto osmotico.",
        "ris_1": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_2": "V",
        "immagine": "",
        "risposta_3": "a seguito della presenza di una falla può aumentare la rigidità del fasciame dovuta all'effetto capillare.",
        "ris_3": "F",
        "progressivo": "1.3.6-7"
    },
    {
        "immagine": "",
        "id": 348,
        "domanda": "Quale soluzione può essere adottata al fine di disincagliare un'unità navale?",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "ris_2": "F",
        "risposta_3": "imbarcare pesi lungo la verticale del punto d'incaglio.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_3": "F",
        "progressivo": "1.3.6-8",
        "risposta_2": "mettere in folle il motore.",
        "ris_1": "V",
        "risposta_1": "attendere il sopraggiungere dell'alta marea."
    },
    {
        "ris_1": "F",
        "risposta_2": "tamponare la via d'acqua servendosi di materiali ingombranti quali, tele cerate, materassi ecc..",
        "domanda": "Quale accorgimento può essere adottato al fine di ostruire al meglio una falla di notevoli dimensioni?",
        "ris_3": "F",
        "risposta_3": "limitare l'ingresso d'acqua dalla falla sbandando l'unità navale sul medesimo lato a quello dove si è verificata la stessa.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "progressivo": "1.3.6-9",
        "risposta_1": "travasare carburante nella zona ubicata a proravia della linea trasversale dell'unità navale.",
        "id": 349,
        "immagine": "",
        "ris_2": "V",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)"
    },
    {
        "ris_2": "F",
        "id": 35,
        "ris_3": "F",
        "risposta_3": "il ginocchio di poppa.",
        "domanda": "La parte strutturale della poppa, alla quale si incardina il timone, se esterno, è:",
        "risposta_2": "la losca del timone.",
        "progressivo": "1.1.1-35",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_1": "V",
        "immagine": "",
        "risposta_1": "il dritto di poppa."
    },
    {
        "immagine": "",
        "ris_2": "F",
        "risposta_3": "fermare il motore ed eventualmente ingranare la marcia indietro accostando contemporaneamente, per attenuare l'impatto.",
        "id": 350,
        "progressivo": "1.3.6-10",
        "risposta_2": "azionare preventivamente tutti gli apparati di radiocomunicazione.",
        "domanda": "Quale tra le seguenti manovre può essere la più efficace per limitare i danni allorquando due unità sono in procinto di collidere?",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "F",
        "risposta_1": "appennellare l'ancora e filare a mare l'ancora galleggiante.",
        "ris_3": "V"
    },
    {
        "immagine": "",
        "risposta_3": "sì, sempre.",
        "progressivo": "1.3.6-11",
        "id": 351,
        "ris_2": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_1": "no.",
        "ris_1": "V",
        "ris_3": "F",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "risposta_2": "sì, se il porto è attrezzato per l'estinzione di incendi a bordo di unità.",
        "domanda": "Si sviluppa un incendio a bordo e siamo in vicinanza di un porto: è giusto accelerare per raggiungere al più presto il porto?"
    },
    {
        "risposta_2": "aerare il più possibile il vano motore per spegnere le fiamme.",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "immagine": "",
        "risposta_3": "per prima cosa porre l'imbarcazione con le fiamme sopravento.",
        "id": 352,
        "ris_1": "V",
        "domanda": "In caso di incendio nel vano motore, è necessario:",
        "ris_2": "F",
        "ris_3": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_1": "per prima cosa tentare di chiudere la valvola del carburante.",
        "progressivo": "1.3.6-12"
    },
    {
        "progressivo": "1.3.6-13",
        "risposta_3": "scollegare le batterie.",
        "domanda": "In caso di incendio in coperta, è essenziale:",
        "ris_1": "V",
        "risposta_1": "porre l'imbarcazione con le fiamme sottovento.",
        "risposta_2": "spegnere il motore.",
        "ris_3": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "immagine": "",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "id": 353,
        "ris_2": "F"
    },
    {
        "id": 354,
        "domanda": "In caso d'incendio con fiamma alta, dove dirigiamo il getto dell'estintore?",
        "risposta_1": "sopra le fiamme in modo tale da allontanare anche il fumo.",
        "progressivo": "1.3.6-14",
        "risposta_2": "al centro della fiamma.",
        "risposta_3": "alla base della fiamma.",
        "ris_3": "V",
        "immagine": "",
        "ris_1": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "ris_2": "F"
    },
    {
        "progressivo": "1.3.6-15",
        "risposta_1": "bisogna porre subito l'imbarcazione con le fiamme sopravento.",
        "ris_3": "F",
        "risposta_2": "appronto le procedure per l'abbandono nave.",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "risposta_3": "bisogna spegnere immediatamente le strumentazioni di bordo.",
        "id": 355,
        "domanda": "In caso di grave incendio a bordo:",
        "immagine": "",
        "ris_1": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_2": "V"
    },
    {
        "progressivo": "1.3.6-16",
        "risposta_1": "utilizzando acqua di mare.",
        "ris_3": "F",
        "ris_2": "V",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "id": 356,
        "risposta_2": "utilizzando un estintore a polvere.",
        "ris_1": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "domanda": "Un quadro elettrico di bordo ha preso fuoco; estinguo l'incendio:",
        "risposta_3": "utilizzando un estintore a schiuma.",
        "immagine": ""
    },
    {
        "ris_3": "F",
        "immagine": "",
        "risposta_1": "gettate subito acqua sul fuoco.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "F",
        "risposta_3": "abbandonare l'unità.",
        "id": 357,
        "ris_2": "V",
        "risposta_2": "indossare i giubbetti di salvataggio e allontanarsi dall'incendio.",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "domanda": "Il comandante a bordo, in caso di incendio, che ordine primario deve dare?",
        "progressivo": "1.3.6-17"
    },
    {
        "immagine": "",
        "ris_2": "V",
        "progressivo": "1.3.6-18",
        "domanda": "C'è un incendio al vano poppiero motore, conduco l'unità in modo da:",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_3": "F",
        "risposta_2": "mettere l’incendio sottovento.",
        "risposta_3": "mettere l’incendio sopravento.",
        "ris_1": "F",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "risposta_1": "mettere la prora sottovento.",
        "id": 358
    },
    {
        "id": 359,
        "ris_3": "F",
        "risposta_3": "dirigersi rapidamente verso l'ormeggio più vicino per ricevere dai servizi portuali l'aiuto allo spegnimento.",
        "ris_1": "F",
        "ris_2": "V",
        "risposta_1": "spegnere il motore e richiedere soccorso emettendo 5 o più suoni brevi.",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "progressivo": "1.3.6-19",
        "immagine": "",
        "risposta_2": "tentare di allontanare l'unità dal porto operando per lo spegnimento.",
        "domanda": "In caso di incendio, mentre si manovra in porto, il primo intervento più opportuno è quello di:"
    },
    {
        "progressivo": "1.1.1-36",
        "risposta_3": "il pagliolato.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "capitolo": "TEORIA DELLO SCAFO",
        "domanda": "Le strutture verticali, che suddividono internamente lo scafo, sono:",
        "id": 36,
        "ris_1": "F",
        "risposta_1": "le murate.",
        "ris_2": "V",
        "ris_3": "F",
        "risposta_2": "le paratie.",
        "immagine": ""
    },
    {
        "risposta_2": "sovralimentare l'impianto di alimentazione del carburante.",
        "id": 360,
        "ris_1": "V",
        "risposta_1": "assicurare, prima dell'avvio del motore, il completo ricambio dell'aria.",
        "ris_3": "F",
        "progressivo": "1.3.6-20",
        "ris_2": "F",
        "domanda": "A quale funzione assolve la ventilazione forzata a bordo delle unità navali da diporto dotate di motore a benzina?",
        "immagine": "",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_3": "mantenere costante la temperatura della testata dell'apparato motore."
    },
    {
        "progressivo": "1.3.6-21",
        "id": 361,
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "immagine": "",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_3": "F",
        "ris_1": "F",
        "domanda": "Come si estingue un incendio derivante da combustione di sostanze comuni (legno, tessuti e carta)?",
        "risposta_1": "con ossigeno ad alta pressione.",
        "ris_2": "V",
        "risposta_2": "mediante azione di raffreddamento ottenuta attraverso getti d'acqua.",
        "risposta_3": "attraverso l'intensa aspirazione di vapori."
    },
    {
        "risposta_2": "prendere il mare al giardinetto.",
        "ris_2": "F",
        "immagine": "",
        "risposta_1": "orientare la poppa al vento.",
        "risposta_3": "orientare la prua al vento.",
        "id": 362,
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "F",
        "ris_3": "V",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "progressivo": "1.3.6-22",
        "domanda": "Quale accorgimento è necessario adottare se l'incendio si sviluppa nella zona poppiera dell'unità navale?"
    },
    {
        "risposta_2": "mediante la ventilazione forzata del locale interessato, riducendo il numero di giri del motore, azionando il circuito di condizionamento.",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "ris_2": "F",
        "ris_1": "V",
        "progressivo": "1.3.6-23",
        "immagine": "",
        "domanda": "Come si estingue un incendio derivante da combustione di sostanze liquide?",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_1": "mediante l'azione di soffocamento generata, polvere chimica, schiuma, anidride carbonica (CO2) o altro gas inerte.",
        "id": 363,
        "risposta_3": "intercettando il pacco batterie e disattivando il gruppo generatore.",
        "ris_3": "F"
    },
    {
        "ris_3": "F",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "progressivo": "1.3.6-24",
        "domanda": "Quali contromisure possono essere adottate in caso di incendio sviluppatosi nel locale apparato motore?",
        "immagine": "",
        "id": 364,
        "ris_2": "F",
        "ris_1": "V",
        "risposta_3": "aprire tutti i boccaporti del locale  motore per favorirne l'aerazione.",
        "risposta_1": "chiudere immediatamente le vie d'aria e l'alimentazione del combustibile.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "azionare immediatamente le turbine di sovralimentazione per favorire l'estinzione dell'incendio."
    },
    {
        "ris_2": "F",
        "domanda": "Quale manovra può essere adottata in caso di incendio verificatosi nella zona prodiera di un'unità navale da diporto?",
        "risposta_2": "incrementare repentinamente la velocità dell'unità navale.",
        "ris_3": "V",
        "risposta_3": "porre la poppa al vento e quindi la prora sottovento.",
        "ris_1": "F",
        "risposta_1": "porre la prora al vento e quindi la poppa sottovento.",
        "progressivo": "1.3.6-25",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "id": 365,
        "immagine": ""
    },
    {
        "ris_2": "F",
        "domanda": "Il numero e il posizionamento degli estintori di una unità marcata CE:",
        "id": 366,
        "immagine": "",
        "risposta_1": "è fissato dal Codice della navigazione.",
        "ris_3": "V",
        "progressivo": "1.3.6-26",
        "risposta_3": "è stabilito nel Manuale del proprietario.",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "risposta_2": "è stabilito dalle ordinanze dell'Autorità marittima.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "F"
    },
    {
        "ris_1": "F",
        "ris_3": "F",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "risposta_1": "no, non è stabilito.",
        "progressivo": "1.3.6-27",
        "immagine": "",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_3": "no, è stabilito dalle ordinanze dell'Autorità marittima, con il minimo di 1 estintore.",
        "risposta_2": "si, e prevede un minimo di 1 estintore al posto di guida e 1 estintore in ciascuno degli altri locali.",
        "ris_2": "V",
        "domanda": "Per le imbarcazioni da diporto NON marcata CE (immesse sul mercato prima del 17 giugno 1998) il numero e il posizionamento degli estintori è stabilito dal Regolamento di attuazione al Codice della nautica secondo la potenza del motore?",
        "id": 367
    },
    {
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "rapidamente, riducendo la velocità con l'elica a marcia indietro quando giunti in prossimità del naufrago, possibilmente sgasando.",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "risposta_1": "a velocità costante.",
        "ris_2": "F",
        "risposta_3": "con prudenza, dopo aver smaltito la velocità iniziale.",
        "id": 368,
        "domanda": "Durante la manovra a motore di recupero di uomo a mare, come deve essere effettuato l'avvicinamento finale verso il naufrago?",
        "ris_1": "F",
        "progressivo": "1.3.6-28",
        "immagine": "",
        "ris_3": "V"
    },
    {
        "risposta_2": "aumentare al massimo la velocità con il timone alla banda per compiere un'evoluzione di 360° per fini perlustrativi.",
        "ris_2": "F",
        "progressivo": "1.3.6-29",
        "domanda": "Quali tra queste azioni è la più opportuna da compiere in caso di uomo in mare?",
        "ris_1": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "id": 369,
        "risposta_3": "lanciare il salvagente anulare presente a bordo in direzione del naufrago.",
        "risposta_1": "arrestare immediatamente i motori dell'unità navale ingranando la marcia indietro.",
        "ris_3": "V",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "immagine": ""
    },
    {
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "domanda": "La carena è:",
        "progressivo": "1.1.1-37",
        "capitolo": "TEORIA DELLO SCAFO",
        "immagine": "",
        "risposta_2": "la parte esterna dello scafo.",
        "risposta_3": "l’opera morta.",
        "ris_3": "F",
        "ris_1": "V",
        "ris_2": "F",
        "id": 37,
        "risposta_1": "l'opera viva."
    },
    {
        "domanda": "Un uomo cade in mare di prora lato dritto, da una imbarcazione che procede a motore, quale delle tre manovre in figura a fianco è corretta?",
        "id": 370,
        "risposta_3": "la manovra in figura C.",
        "ris_3": "F",
        "ris_2": "V",
        "risposta_1": "la manovra in figura A.",
        "progressivo": "1.3.6-30",
        "ris_1": "F",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "risposta_2": "la manovra in figura B.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "immagine": 19
    },
    {
        "progressivo": "1.3.6-31",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "immagine": "",
        "risposta_3": "si accelera per recuperare rapidamente l'uomo a mare, accostando indifferentemente a dritta oppure a sinistra.",
        "risposta_1": "si accosta rapidamente dallo stesso lato dell’uomo in mare.",
        "domanda": "Al grido ”uomo in mare a ... (sinistra o a dritta)”:",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "V",
        "ris_2": "F",
        "risposta_2": "si accosta rapidamente dal lato opposto dell’uomo in mare.",
        "ris_3": "F",
        "id": 371
    },
    {
        "domanda": "Quale tra queste precauzioni è la più opportuna adottare in caso di uomo in mare?",
        "ris_3": "F",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "ris_1": "F",
        "ris_2": "V",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "immagine": "",
        "id": 372,
        "risposta_3": "collocare tutti i parabordi lungo l'opera morta.",
        "risposta_1": "dare fondo all'ancora.",
        "risposta_2": "mantenere un costante controllo visivo del naufrago.",
        "progressivo": "1.3.6-32"
    },
    {
        "risposta_1": "per allontanare subito le eliche dell'unità quanto più possibile dal naufrago.",
        "ris_1": "V",
        "domanda": "Qual è il motivo per cui in caso di uomo a mare si accosta tempestivamente dallo stesso lato dal quale è caduto il naufrago?",
        "risposta_2": "per orientare l'unità controvento.",
        "ris_2": "F",
        "ris_3": "F",
        "immagine": "",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "id": 373,
        "risposta_3": "per offrire al naufrago il ridosso dagli agenti meteomarini.",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "progressivo": "1.3.6-33"
    },
    {
        "immagine": "",
        "ris_2": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "progressivo": "1.3.6-34",
        "ris_1": "F",
        "risposta_1": "confortare il naufrago rassicurandolo circa l'intendimento dell'unità navale di trarlo in salvo.",
        "id": 374,
        "risposta_3": "agevolare le operazioni di recupero del naufrago durante la manovra evolutiva effettuata dall'unità, scongiurando il rischio che lo stesso possa disperdersi.",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "ris_3": "V",
        "risposta_2": "per determinare la lunghezza di cima necessaria per lanciare il salvagente individuale.",
        "domanda": "Qual è il motivo per cui è opportuno mantenere il controllo visivo del naufrago subito dopo la sua caduta in mare?"
    },
    {
        "risposta_2": "filare a mare l'ancora galleggiante in modo da mantenere la prua al vento durante le operazioni di recupero.",
        "risposta_3": "lanciare in mare il salvagente anulare quanto più possibile nelle vicinanze del naufrago per agevolarne il recupero.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "progressivo": "1.3.6-35",
        "risposta_1": "dare fondo all'ancora per mantenere fissa la posizione dell'unità navale durante le operazioni di recupero.",
        "id": 375,
        "ris_3": "V",
        "domanda": "Quale tra queste azioni è la più opportuna intraprendere durante le fasi di recupero di un uomo caduto in mare da un'unità navale?",
        "ris_2": "F",
        "ris_1": "F",
        "immagine": ""
    },
    {
        "risposta_2": "si deve mettere immediatamente a folle il motore e successivamente ingranarlo a marcia indietro al fine di allontanarsi il meno possibile dal naufrago.",
        "id": 376,
        "domanda": "Nel caso in cui un membro dell'equipaggio cada in mare dal lato dritto dell'unità:",
        "risposta_3": "occorre accostare immediatamente il timone a dritta mantenendo il costante controllo visivo del\nnaufrago.",
        "risposta_1": "occorre accostare immediatamente a sinistra mantenendo un adeguato servizio di vedetta sul medesimo lato al fine di prevenire eventuali collisioni con altre unità navali o ostacoli posti nelle immediate vicinanze.",
        "progressivo": "1.3.6-36",
        "argomento": "Provvedimenti da adottare in caso di sinistro marittimo (incendio, collisione, falla, incaglio, uomo a mare)",
        "ris_3": "V",
        "ris_1": "F",
        "ris_2": "F",
        "immagine": "",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE"
    },
    {
        "risposta_2": "fa indossare a ciascuna persona a bordo il giubbotto di salvataggio.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_3": "F",
        "ris_1": "F",
        "risposta_1": "spegne il motore.",
        "argomento": "Sinistro e abbandono dell'unità",
        "risposta_3": "prepara le bandiere di segnalamento.",
        "ris_2": "V",
        "id": 377,
        "domanda": "In caso di sinistro e conseguente abbandono dell'unità il comandante:",
        "immagine": "",
        "progressivo": "1.3.7-1"
    },
    {
        "risposta_2": "la sagola della zattera deve essere fissata alla barca, prima di lanciarla in acqua.",
        "risposta_3": "si gonfia il tender.",
        "ris_1": "F",
        "progressivo": "1.3.7-2",
        "domanda": "In caso di abbandono dell'imbarcazione:",
        "ris_2": "V",
        "ris_3": "F",
        "immagine": "",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_1": "per prima cosa si lancia la zattera in mare.",
        "argomento": "Sinistro e abbandono dell'unità",
        "id": 378
    },
    {
        "risposta_3": "sul pulpito di poppa.",
        "ris_3": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "immagine": "",
        "id": 379,
        "ris_2": "F",
        "argomento": "Sinistro e abbandono dell'unità",
        "risposta_2": "sulla tuga.",
        "domanda": "In quale posizione non è opportuno posizionare la zattera di salvataggio?",
        "progressivo": "1.3.7-3",
        "risposta_1": "sottocoperta.",
        "ris_1": "V"
    },
    {
        "risposta_2": "paramezzale.",
        "ris_2": "F",
        "id": 38,
        "progressivo": "1.1.1-38",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_1": "F",
        "domanda": "Una sovrastruttura è quella parte della nave che si eleva al di sopra del:",
        "ris_3": "V",
        "immagine": "",
        "risposta_3": "ponte di coperta nel caso di unità con unico ponte.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "risposta_1": "pagliolato."
    },
    {
        "argomento": "Sinistro e abbandono dell'unità",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_2": "V",
        "risposta_2": "in un gavone chiuso in posizione difficilmente raggiungibile.",
        "risposta_1": "in un vano ricavato nello specchio di poppa.",
        "ris_1": "F",
        "immagine": "",
        "domanda": "In quale posizione non è opportuno posizionare la zattera di salvataggio?",
        "risposta_3": "sulla tuga.",
        "id": 380,
        "ris_3": "F",
        "progressivo": "1.3.7-4"
    },
    {
        "domanda": "Il \"Grab Bag\" è:",
        "risposta_3": "un'attrezzatura velica.",
        "immagine": "",
        "argomento": "Sinistro e abbandono dell'unità",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_1": "è quel sacco contenente le dotazioni normalmente custodite all'interno della zattera di salvataggio.",
        "ris_3": "F",
        "risposta_2": "la sacca contente i giubbotti i salvataggio quando sono riposti.",
        "ris_2": "F",
        "id": 381,
        "ris_1": "V",
        "progressivo": "1.3.7-5"
    },
    {
        "ris_1": "F",
        "domanda": "Il \"Grab Bag\" deve:",
        "immagine": "",
        "risposta_3": "essere tenuto saldamente legato con una cinghia alla custodia rigida della zattera.",
        "ris_2": "V",
        "risposta_1": "essere custodito e protetto in un gavone sottocoperta per evitare danneggiamenti al suo contenuto.",
        "risposta_2": "essere tenuto a portata di mano in modo da poter essere lanciato nella zattera di salvataggio in caso di abbandono della barca.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "argomento": "Sinistro e abbandono dell'unità",
        "id": 382,
        "ris_3": "F",
        "progressivo": "1.3.7-6"
    },
    {
        "argomento": "Sinistro e abbandono dell'unità",
        "ris_2": "V",
        "domanda": "Cosa si intende per soccorso marittimo?",
        "risposta_3": "l'evacuazione medica a bordo di un'unità.",
        "risposta_2": "tutte le attività finalizzate alla ricerca ed al salvataggio della vita umana in mare.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "immagine": "",
        "ris_3": "F",
        "progressivo": "1.3.7-7",
        "ris_1": "F",
        "id": 383,
        "risposta_1": "le attività tese a prendere a rimorchio un'unità in avaria."
    },
    {
        "ris_3": "F",
        "domanda": "Qual è l'organismo nazionale che assicura il coordinamento generale dei servizi di soccorso marittimo?",
        "immagine": "",
        "ris_2": "F",
        "id": 384,
        "risposta_3": "la stazione radio costiera.",
        "ris_1": "V",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_1": "il Comando Generale del Corpo delle Capitanerie di Porto.",
        "argomento": "Sinistro e abbandono dell'unità",
        "progressivo": "1.3.7-8",
        "risposta_2": "il Dipartimento della Protezione Civile."
    },
    {
        "ris_3": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_3": "Croce Rossa internazionale.",
        "argomento": "Centro Internazionale Radio Medico (CIRM).",
        "id": 385,
        "risposta_1": "guardia medica più vicina.",
        "immagine": "",
        "domanda": "In caso di un grave infortunio occorso ad un membro dell'equipaggio durante la navigazione, quale Ente sarà opportuno contattare il prima possibile?",
        "progressivo": "1.3.2.113",
        "ris_1": "F",
        "ris_2": "V",
        "risposta_2": "Centro Internazionale Radio Medico (CIRM)."
    },
    {
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "immagine": "",
        "ris_1": "V",
        "risposta_3": "a prescindere dalla distanza in cui si trovano.",
        "argomento": "Sinistro e abbandono dell'unità",
        "id": 386,
        "ris_3": "F",
        "domanda": "Quando l'Autorità Marittima può ordinare alle unità da diporto di partecipare alle attività di soccorso in mare?",
        "progressivo": "1.3.7-9",
        "risposta_1": "quando si trovano in porto o nelle vicinanze.",
        "risposta_2": "solo se in navigazione.",
        "ris_2": "F"
    },
    {
        "ris_1": "F",
        "ris_2": "V",
        "domanda": "In quali condizioni è obbligatorio per un comandante di un'unità da diporto prestare assistenza ad un'altra unità in pericolo?",
        "immagine": "",
        "argomento": "Sinistro e abbandono dell'unità",
        "id": 387,
        "risposta_3": "quando la distanza tra le due unità non è superiore alle 6 miglia nautiche e quando non sussiste il rischio per l'unità soccorritrice e delle persone ivi imbarcate.",
        "progressivo": "1.3.7-10",
        "ris_3": "F",
        "risposta_2": "quando a bordo dell'unita in difficoltà vi sono persone in pericolo di vita e quando non sussiste il rischio per l'unità soccorritrice e delle persone ivi imbarcate.",
        "risposta_1": "quando la distanza tra le due unità non è superiore alle 12 miglia nautiche e quando non sussiste il rischio per l'unità soccorritrice e delle persone ivi imbarcate.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE"
    },
    {
        "ris_1": "V",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "il comandante dell'unità ordina \"l'abbandono\" della stessa solo dopo aver verificato di persona che oltre alla presenza di infiltrazioni nello scafo si sia verificata la contemporanea avaria degli organi propulsivi.",
        "progressivo": "1.3.7-11",
        "risposta_1": "il comandante dell'unità ordina \"l'abbandono\" della stessa solo dopo aver accertato di persona che tutti i mezzi suggeriti dall'arte nautica non sono in grado di salvarla.",
        "risposta_3": "il comandante ordina \"l'abbandono\" della stessa solo dopo aver verificato di persona il mancato funzionamento di tutti gli apparati di navigazione.",
        "ris_3": "F",
        "ris_2": "F",
        "id": 388,
        "argomento": "Sinistro e abbandono dell'unità",
        "domanda": "Ai sensi del Codice della Navigazione, quale tra queste affermazioni è la più corretta per quanto concerne \"l'abbandono nave\"?",
        "immagine": ""
    },
    {
        "risposta_2": "si, nei limiti del possibile.",
        "argomento": "Sinistro e abbandono dell'unità",
        "domanda": "In caso di urto tra due o più unità navali, i Comandanti delle stesse sono obbligati a fornire alle altre unità navali coinvolte le notizie per identificare la propria?",
        "ris_1": "F",
        "risposta_3": "solo previo accordo tra i Comandanti delle unità navali coinvolte.",
        "immagine": "",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_2": "V",
        "ris_3": "F",
        "progressivo": "1.3.7-12",
        "id": 389,
        "risposta_1": "no."
    },
    {
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_1": "la sovrastruttura abitabile innalzata sopra un ponte.",
        "progressivo": "1.1.1-39",
        "ris_2": "F",
        "ris_1": "V",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "risposta_3": "un vano destinato al ricovero di cime, vele e accessori.",
        "ris_3": "F",
        "id": 39,
        "risposta_2": "un elemento costruttivo ubicato sotto il ponte.",
        "domanda": "La tuga è:",
        "immagine": ""
    },
    {
        "risposta_3": "accertarsi che sia stata intercettata la linea di alimentazione elettrica.",
        "risposta_1": "accertarsi che tutte le persone imbarcate indossino le cinture di salvataggio e che l'eventuale mezzo collettivo di salvataggio (zattera) sia equipaggiato con le previste dotazioni di sicurezza.",
        "risposta_2": "accertarsi che i serbatoi di carburante siano stati svuotati.",
        "ris_3": "F",
        "immagine": "",
        "ris_2": "F",
        "domanda": "Quale accorgimento deve adottare il comandante dell'unità da diporto prima di ordinare l'abbandono della stessa?",
        "id": 390,
        "argomento": "Sinistro e abbandono dell'unità",
        "progressivo": "1.3.7-13",
        "ris_1": "V",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE"
    },
    {
        "risposta_3": "si riduce la possibilità che l'imbarcazione possa ingavonarsi.",
        "ris_1": "V",
        "risposta_1": "l'inclinazione della prua verso il basso per attutire gli impatti sulle onde con il mare formato.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_2": "F",
        "risposta_2": "si migliora il rendimento del circuito di raffreddamento.",
        "immagine": "",
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo",
        "id": 391,
        "domanda": "Quale effetto si genera in navigazione abbassando il piede (trim negativo), in un'unità spinta da un motore fuoribordo?",
        "ris_3": "F",
        "progressivo": "1.3.8-1"
    },
    {
        "ris_3": "V",
        "domanda": "Quali possono essere i principali accorgimenti che un'unità navale da diporto dovrà adottare qualora interessata da un brusco peggioramento delle condizioni meteo-marine?",
        "risposta_1": "trasferire quanto più possibile il peso a prua.",
        "immagine": "",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "progressivo": "1.3.8-2",
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo",
        "risposta_3": "rizzare (fissare) tutti gli oggetti di bordo, chiudere accuratamente oblò e osterigi, istruire le persone imbarcate in merito al corretto uso dei mezzi collettivi e individuali di salvataggio.",
        "id": 392,
        "ris_2": "F",
        "risposta_2": "chiudere immediatamente la presa a mare del raffreddamento motore ed intercettare la linea di alimentazione.",
        "ris_1": "F"
    },
    {
        "ris_3": "F",
        "progressivo": "1.3.8-3",
        "immagine": "",
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "F",
        "risposta_2": "dirigersi in sicurezza verso la costa ove il moto ondoso è più attenuato.",
        "id": 393,
        "risposta_3": "prendere il mare solo al giardinetto.",
        "domanda": "Quale accorgimento sarà opportuno adottare in caso di tempesta proveniente dalla terra ferma?",
        "risposta_1": "appennellare l'ancora.",
        "ris_2": "V"
    },
    {
        "immagine": "",
        "ris_1": "F",
        "ris_3": "F",
        "risposta_1": "si cercherà di prendere il mare in poppa.",
        "domanda": "Quale accorgimento sarà opportuno adottare in caso di tempesta proveniente dal mare?",
        "ris_2": "V",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_3": "trasmettere via radio apposito messaggio di soccorso diretto alle unità navali presenti nelle vicinanze.",
        "progressivo": "1.3.8-4",
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo",
        "id": 394,
        "risposta_2": "condurre l'unità navale alla cappa."
    },
    {
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo",
        "progressivo": "1.3.8-5",
        "ris_3": "V",
        "risposta_3": "ridurre opportunamente la velocità.",
        "id": 395,
        "ris_1": "F",
        "risposta_2": "aumentare la velocità.",
        "immagine": "",
        "domanda": "Quale accorgimento potrà essere adottato nel caso in cui un'unità navale da diporto navighi con mare particolarmente grosso in poppa causando brusche variazioni del motore?",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_1": "controllare il livello di carburante nel serbatoio.",
        "ris_2": "F"
    },
    {
        "ris_3": "F",
        "immagine": "",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "domanda": "Navigando in presenza di nebbia fitta, quali fattori possono indicare la possibile vicinanza della costa?",
        "risposta_2": "mutamento del colore dell'acqua ed il fragore dei frangenti.",
        "ris_1": "F",
        "risposta_1": "aumento della corrente di superficie e brusca riduzione della temperatura dell'acqua.",
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo",
        "progressivo": "1.3.8-6",
        "id": 396,
        "risposta_3": "forti escursioni di marea e repentino abbassamento della temperatura delle acque.",
        "ris_2": "V"
    },
    {
        "risposta_3": "trasmettendo in frequenza VHF e HF, permette di inviare automaticamente un segnale di soccorso, di urgenza o di sicurezza ad altre navi nelle vicinanze, ai Centri di Coordinamento del Soccorso Marittimo e alle Stazioni Costiere.",
        "id": 397,
        "ris_3": "V",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo",
        "risposta_2": "trasmettendo in frequenza VHF e HF, permette di  inviare automaticamente onde radio digitali, eliminando così le deviazioni che le stesse subiscono sottocosta per rifrazione elettromagnetica.",
        "ris_1": "F",
        "progressivo": "1.3.8-7",
        "domanda": "A quale funzione assolve il sistema DSC (Digital Selective Calling) installato su alcune tipologie di apparati radio?",
        "ris_2": "F",
        "risposta_1": "trasmettendo in frequenza MF, permette di inviare automaticamente un segnale di soccorso ad altre unità navali che si trovino entro un raggio non superiore alle 15 miglia nautiche.",
        "immagine": ""
    },
    {
        "risposta_1": "di poppa, sfrutto la spinta delle onde per aumentare la velocità di fuga.",
        "immagine": "",
        "ris_1": "F",
        "risposta_3": "di prora, cerco di tagliare le onde esattamente con la prua.",
        "id": 398,
        "ris_2": "V",
        "ris_3": "F",
        "domanda": "Navigando a motore con mare molto mosso:",
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo",
        "risposta_2": "faccio il possibile per non prendere le onde al traverso.",
        "progressivo": "1.3.8-8",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE"
    },
    {
        "ris_3": "V",
        "ris_2": "F",
        "immagine": "",
        "risposta_3": "è opportuno puntare leggermente verso la cresta, per poi allontanarsene quando la barca scende nel cavo dell’onda.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "id": 399,
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo",
        "risposta_2": "è necessario tagliare la cresta esattamente con la prua perpendicolare all'onda.",
        "risposta_1": "è necessario prendere l'onda al traverso.",
        "ris_1": "F",
        "progressivo": "1.3.8-9",
        "domanda": "Per attenuare l'impatto con l'onda formata:"
    },
    {
        "ris_1": "V",
        "id": 4,
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_3": "F",
        "risposta_3": "la paratia divisoria verticale che separa due locali posti all'interno dello scafo.",
        "immagine": "",
        "domanda": "Le murate sono:",
        "progressivo": "1.1.1-4",
        "ris_2": "F",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "risposta_2": "la superficie laterale verticale della tuga dell'unità navale.",
        "risposta_1": "la porzione esterna e laterale dello scafo (opera morta) che si estende tra la prora e la poppa."
    },
    {
        "progressivo": "1.1.1-40",
        "ris_1": "F",
        "ris_3": "V",
        "capitolo": "TEORIA DELLO SCAFO",
        "domanda": "La sagola è:",
        "risposta_1": "la corda più lunga.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "ris_2": "F",
        "risposta_3": "una cima di piccolo diametro",
        "immagine": "",
        "risposta_2": "una sartia volante.",
        "id": 40
    },
    {
        "risposta_3": "si tiene il trim negativo, (elica più immersa) per alzare la prua e limitare i colpi sull'onda.",
        "ris_1": "V",
        "progressivo": "1.3.8-10",
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo",
        "immagine": "",
        "risposta_1": "si tiene il trim positivo (elica più sollevata) per alzare la prua e limitare i colpi sull'onda.",
        "domanda": "In navigazione con onda di poppa:",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "id": 400,
        "risposta_2": "si tiene il trim neutro, per non alterare l'assetto della barca.",
        "ris_2": "F",
        "ris_3": "F"
    },
    {
        "id": 401,
        "ris_2": "F",
        "immagine": "",
        "ris_1": "V",
        "risposta_3": "disattivarli, poiché si tratta di dispositivi da usare solo con mare piatto.",
        "progressivo": "1.3.8-11",
        "risposta_1": "tenerli più o meno abbassati, secondo la tipologia di scafo, per contrastare la tendenza della carena ad alzare la prua a causa della massa d’acqua che scorre sotto la stessa.",
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo",
        "domanda": "In merito alla regolazione dei flaps, con moto ondoso contrario è opportuno:",
        "ris_3": "F",
        "risposta_2": "tenerli più o meno alzati, secondo la tipologia di scafo, per aiutare ad alzare la prua e favorire il passaggio sull'onda.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE"
    },
    {
        "risposta_1": "disattivarli, poiché si tratta di dispositivi da usare solo con mare piatto.",
        "risposta_3": "tenere i flaps alzati, per schiacciare la poppa verso il basso, per contrastare l'onda che  tende a sollevare la poppa e a far immergere la prua.",
        "ris_3": "V",
        "immagine": "",
        "ris_2": "F",
        "ris_1": "F",
        "domanda": "In merito alla regolazione dei flaps, con mare formato di poppa, è opportuno:",
        "progressivo": "1.3.8-12",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "id": 402,
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo",
        "risposta_2": "tenere i flaps abbassati, per consentire alla prua di alzarsi sull'onda."
    },
    {
        "risposta_3": "che è regolato per abbassare la prua sull'acqua.",
        "ris_1": "V",
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo",
        "id": 403,
        "immagine": "",
        "domanda": "Normalmente, quando l’indicatore del flap è sullo zero, ciò significa:",
        "risposta_2": "che è regolato per alzare la prua sull'acqua.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_3": "F",
        "ris_2": "F",
        "progressivo": "1.3.8-13",
        "risposta_1": "che in quel momento il flap è ”neutro”, ovvero orizzontale, quando invece vengono segnalati dei numeri negativi ciò significa che i flaps sono inclinati verso il basso."
    },
    {
        "domanda": "In merito alla regolazione dei flaps, è possibile affermare:",
        "ris_2": "V",
        "progressivo": "1.3.8-14",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "id": 404,
        "immagine": "",
        "risposta_3": "che se regolati in maniera indipendente possono compromettere l'equilibrio dell'unità.",
        "ris_1": "F",
        "risposta_2": "che generalmente possono essere regolati in maniera indipendente, così da poter variare non solo la regolazione della prua, ma anche l’equilibrio trasversale.",
        "ris_3": "F",
        "risposta_1": "che possono essere regolati solo in maniera identica.",
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo"
    },
    {
        "domanda": "Alzando il flap sinistro o abbassando il flap destro, si ottiene:",
        "risposta_1": "di inclinare lo scafo verso il lato dritto.",
        "immagine": "",
        "ris_3": "F",
        "ris_2": "F",
        "progressivo": "1.3.8-15",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "di inclinare lo scafo verso il lato sinistro.",
        "id": 405,
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo",
        "ris_1": "V",
        "risposta_3": "di abbassare la prua."
    },
    {
        "id": 406,
        "risposta_1": "l'interruttore collegato tramite un cordino a spirale rosso (o con un dispositivo elettronico) a chi governa un fuoribordo, che spegne “automaticamente” il motore in caso di caduta in acqua.",
        "ris_3": "F",
        "domanda": "Lo \"stacco di sicurezza\" è:",
        "risposta_2": "il \"cordone ombelicale\" della cintura di sicurezza con cui ci si assicura allo scafo.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "progressivo": "1.3.8-16",
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo",
        "risposta_3": "la valvola di chiusura del carburante.",
        "ris_1": "V",
        "immagine": "",
        "ris_2": "F"
    },
    {
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo",
        "risposta_2": "si rallenta, si accendono i fanali e si emettono i segnali prescritti.",
        "ris_1": "F",
        "risposta_1": "si emette un segnale acustico prolungato ogni 2 minuti.",
        "progressivo": "1.3.8-17",
        "ris_2": "V",
        "ris_3": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "id": 407,
        "immagine": "",
        "risposta_3": "ci si deve fermare.",
        "domanda": "Improvvisamente la visibilità diviene scarsa:"
    },
    {
        "id": 408,
        "progressivo": "1.3.8-18",
        "domanda": "Stando alla cappa, in quali casi può essere utile l’ancora galleggiante?",
        "immagine": "",
        "ris_2": "V",
        "risposta_2": "quando sottovento a noi c’è una costa vicina.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "F",
        "ris_3": "F",
        "risposta_3": "sempre e comunque.",
        "risposta_1": "mai.",
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo"
    },
    {
        "ris_2": "F",
        "ris_1": "F",
        "progressivo": "1.3.8-19",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_1": "di prora in modo da saltare la cresta dell'onda.",
        "immagine": "",
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo",
        "ris_3": "V",
        "id": 409,
        "domanda": "Mettersi \"alla Cappa\", significa prendere il mare:",
        "risposta_2": "di poppa con motore a un regime tale da far stare l'unità da diporto sempre sulla cresta dell'onda.",
        "risposta_3": "al mascone con motore a un regime tale da consentire al'unità di scarrocciare lasciando sopravento una zona di remora che possa smorzare i frangenti prima di arrivare sulla fiancata sopravento."
    },
    {
        "risposta_3": "la porzione terminale della parte esterna dello scafo, posto in prossimità della poppa (a dritta e a sinistra), con profilo spigoloso o tondeggiante.",
        "ris_2": "F",
        "risposta_2": "la zona più esterna e centrale della poppa.",
        "risposta_1": "la parte esterna convessa (a dritta e sinistra) in corrispondenza della prua.",
        "id": 41,
        "progressivo": "1.1.1-41",
        "ris_3": "V",
        "immagine": "",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "ris_1": "F",
        "domanda": "Cosa si intende per giardinetto?",
        "capitolo": "TEORIA DELLO SCAFO"
    },
    {
        "immagine": "",
        "ris_1": "V",
        "risposta_2": "è condizione che nasce da mare e vento incrociati.",
        "risposta_3": "è un vento locale di debole intensità.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "id": 410,
        "progressivo": "1.3.8-20",
        "ris_2": "F",
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo",
        "domanda": "Cos'è la risacca?",
        "ris_3": "F",
        "risposta_1": "onde di riflusso."
    },
    {
        "ris_3": "F",
        "ris_2": "F",
        "risposta_3": "serve a individuare un basso fondale.",
        "ris_1": "V",
        "progressivo": "1.3.8-21",
        "risposta_1": "serve a limitare l'intraversamento dell'unità.",
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo",
        "domanda": "L’ancora galleggiante:",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "id": 411,
        "risposta_2": "è una dotazione di sicurezza obbligatoria.",
        "immagine": ""
    },
    {
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo",
        "ris_3": "F",
        "ris_1": "F",
        "risposta_1": "avvisare il 1530.",
        "id": 412,
        "risposta_3": "accendere le luci di via anche di giorno.",
        "immagine": "",
        "progressivo": "1.3.8-22",
        "ris_2": "V",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "indossare la cintura di sicurezza e assicurarsi al ponte.",
        "domanda": "In caso di navigazione in solitario:"
    },
    {
        "domanda": "In caso di navigazione con cattivo tempo, qual è il provvedimento da adottare:",
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo",
        "id": 413,
        "ris_1": "F",
        "ris_3": "F",
        "progressivo": "1.3.8-23",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_1": "si chiudono gli ombrinali.",
        "immagine": "",
        "risposta_3": "si chiudono necessariamente le prese a mare del motore.",
        "ris_2": "V",
        "risposta_2": "si chiudono oblò, boccaporti e prese a mare, lasciando aperta solo quelle del motore."
    },
    {
        "risposta_1": "dopo l'installazione è necessaria un'ispezione dell'Autorità Marittima del luogo ove è stata eseguita l'installazione.",
        "id": 414,
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "domanda": "Per attirare l'attenzione di un'altra unità in caso di pericolo:",
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo",
        "risposta_2": "si effettua un movimento lento e ripetuto di entrambe le braccia allargate, dall'alto in basso.",
        "immagine": "",
        "progressivo": "1.3.8-24",
        "risposta_3": "è necessario il collaudo di un tecnico abilitato appartenente al Ministero dello\nSviluppo Economico.",
        "ris_2": "V",
        "ris_3": "F",
        "ris_1": "F"
    },
    {
        "ris_3": "V",
        "immagine": "",
        "progressivo": "1.3.8-25",
        "risposta_1": "per attirare l'attenzione di notte è possibile usare le boette fumogene.",
        "ris_2": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "F",
        "risposta_2": "per effettuare una chiamata di soccorso con il VHF è necessario un abbonamento.",
        "risposta_3": "il 1530 è il numero telefonico di emergenza della Guardia Costiera.",
        "argomento": "Precauzioni da adottare in caso di navigazione con tempo cattivo",
        "id": 415,
        "domanda": "Quale affermazione è corretta?"
    },
    {
        "ris_3": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "F",
        "domanda": "Quale titolo abilita il comandante di un'imbarcazione da diporto ad utilizzare un apparato ricetrasmittente VHF/FM?",
        "risposta_1": "il certificato illimitato di radiotelefonista per navi.",
        "immagine": "",
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "progressivo": "1.3.9-1",
        "id": 416,
        "ris_2": "V",
        "risposta_3": "nessun titolo.",
        "risposta_2": "il certificato limitato di radiotelefonista per naviglio minore."
    },
    {
        "risposta_3": "consente di utilizzare il VHF a bordo di una nave da diporto.",
        "progressivo": "1.3.9-2",
        "risposta_1": "consente di utilizzare il VHF a bordo di un natante da diporto.",
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "id": 417,
        "domanda": "Cosa è l'indicativo di chiamata?",
        "immagine": "",
        "ris_2": "F",
        "ris_1": "V",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_3": "F",
        "risposta_2": "consente di utilizzare il VHF a bordo di un'imbarcazione da diporto."
    },
    {
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "progressivo": "1.3.9-3",
        "immagine": "",
        "ris_2": "V",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_3": "F",
        "risposta_3": "è necessario il collaudo di un tecnico abilitato appartenente al Ministero dello Sviluppo Economico.",
        "risposta_2": "è richiesto solo di controllare che l’apparato sia omologato.",
        "domanda": "Per installare a bordo un VHF di tipo fisso:",
        "id": 418,
        "ris_1": "F",
        "risposta_1": "dopo l'installazione è necessaria un'ispezione dell'Autorità Marittima del luogo ove è stata eseguita l'installazione."
    },
    {
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "id": 419,
        "ris_1": "F",
        "progressivo": "1.3.9-4",
        "risposta_1": "identificare un natante da diporto non iscritto che sta navigando al di fuori delle acque nazionali.",
        "immagine": "",
        "risposta_2": "utilizzare l'apparato VHF a bordo di imbarcazioni e navi da diporto.",
        "risposta_3": "utilizzare l'apparato VHF a bordo di un natante da diporto.",
        "domanda": "Il nominativo internazionale consente di:",
        "ris_3": "F",
        "ris_2": "V",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE"
    },
    {
        "risposta_1": "il giardinetto come la parte curva dello scafo vicino alla prora.",
        "ris_3": "V",
        "id": 42,
        "immagine": "",
        "risposta_3": "l'apertura nel ponte di coperta per il passaggio all'interno di persone o cose.",
        "ris_1": "F",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_2": "il trincarino come la protezione esterna dello scafo.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "domanda": "Cosa è il boccaporto?",
        "ris_2": "F",
        "progressivo": "1.1.1-42"
    },
    {
        "immagine": "",
        "ris_3": "V",
        "risposta_1": "ogni 10 anni.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "domanda": "Ogni quanto tempo gli apparati ricetrasmittenti installati a bordo sono sottoposti ad ispezioni ordinarie?",
        "ris_1": "F",
        "id": 420,
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "risposta_2": "ogni 5 anni.",
        "ris_2": "F",
        "risposta_3": "sono esonerati dalle ispezioni ordinarie.",
        "progressivo": "1.3.9-5"
    },
    {
        "progressivo": "1.3.9-6",
        "immagine": "",
        "risposta_3": "se si presume la presenza di una nave, di un aeroplano, della costa.",
        "ris_1": "F",
        "ris_3": "V",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "solo se sono ben visibili le luci di una nave, di un aeroplano, della costa.",
        "id": 421,
        "domanda": "In caso di richiesta di soccorso, in che occasione si utilizzano i \"razzi a paracadute a luce rossa\"?",
        "ris_2": "F",
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "risposta_1": "sempre."
    },
    {
        "id": 422,
        "ris_2": "F",
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "ris_3": "F",
        "risposta_3": "sempre.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "progressivo": "1.3.9-7",
        "risposta_2": "se si presume la presenza di una nave, di un aeroplano o della costa.",
        "immagine": "",
        "risposta_1": "se sono ben visibili le luci di una nave, di un aeroplano, della costa.",
        "ris_1": "V",
        "domanda": "In caso di richiesta di soccorso, in che occasione si utilizzano i \"fuochi a mano a luce rossa\"?"
    },
    {
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "risposta_2": "il canale 68.",
        "ris_2": "F",
        "ris_1": "F",
        "progressivo": "1.3.9-8",
        "id": 423,
        "domanda": "Quale canale radio VHF/FM è utilizzato per le chiamate di soccorso?",
        "risposta_3": "il canale 16.",
        "risposta_1": "il canale 18.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "immagine": "",
        "ris_3": "V"
    },
    {
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "progressivo": "1.3.9-9",
        "ris_1": "F",
        "risposta_1": "di 50 metri.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "di 100 metri.",
        "ris_2": "F",
        "domanda": "Di norma, i razzi a paracadute a luce rossa, quando sparati in aria raggiungono una quota minima:",
        "immagine": "",
        "ris_3": "V",
        "risposta_3": "di 300 metri.",
        "id": 424
    },
    {
        "ris_2": "F",
        "domanda": "La frequenza del Canale 16 è la:",
        "risposta_3": "156.8 Mhz.",
        "ris_3": "V",
        "immagine": "",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "id": 425,
        "risposta_2": "099.7 MHz.",
        "progressivo": "1.3.9-10",
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "ris_1": "F",
        "risposta_1": "102.5 MHz."
    },
    {
        "ris_3": "V",
        "progressivo": "1.3.9-11",
        "ris_1": "F",
        "immagine": "",
        "ris_2": "F",
        "risposta_2": "si dirige verso il porto più vicino in cerca di aiuto.",
        "domanda": "Chi riceve una richiesta di soccorso:",
        "id": 426,
        "risposta_3": "rilancia la chiamata di soccorso ed eventualmente si adopera per prestare soccorso all'unità in pericolo.",
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_1": "si occupa di coordinare i soccorsi."
    },
    {
        "immagine": "",
        "risposta_2": "SECURITE' ripetuta tre volte.",
        "ris_3": "V",
        "ris_1": "F",
        "risposta_3": "MAYDAY ripetuta tre volte.",
        "ris_2": "F",
        "progressivo": "1.3.9-12",
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "risposta_1": "PAN PAN ripetuta tre volte.",
        "id": 427,
        "domanda": "Da quale parola è preceduta la chiamata di soccorso?",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE"
    },
    {
        "progressivo": "1.3.9-13",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_3": "F",
        "ris_1": "F",
        "ris_2": "V",
        "id": 428,
        "risposta_1": "la parola MAYDAY ripetuta tre volte.",
        "risposta_2": "le parole PAN PAN ripetute tre volte.",
        "risposta_3": "la parola SECURITE' ripetuta tre volte.",
        "domanda": "Da cosa è preceduta la chiamata di urgenza?",
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "immagine": ""
    },
    {
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "progressivo": "1.3.9-14",
        "ris_3": "F",
        "id": 429,
        "immagine": "",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_2": "V",
        "risposta_1": "il messaggio di soccorso da trasmettere deve essere preceduto dalla parola SECURITE' e seguito dalla parola MAYDAY.",
        "risposta_3": "il messaggio di soccorso da trasmettere deve essere seguito dalla parola PAN ripetuta tre volte.",
        "ris_1": "F",
        "domanda": "La procedura per trasmettere correttamente un messaggio di soccorso:",
        "risposta_2": "il messaggio di soccorso da trasmettere deve essere preceduto dalla parola MAYDAY ripetuta tre volte."
    },
    {
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_1": "V",
        "ris_2": "F",
        "domanda": "Qual è la funzione degli zinchi?",
        "ris_3": "F",
        "risposta_3": "impedire che l'acqua filtri all'interno dello scafo.",
        "immagine": "",
        "id": 43,
        "progressivo": "1.1.1-43",
        "risposta_2": "aumentare la zavorra.",
        "risposta_1": "evitare le corrosioni galvaniche.",
        "argomento": "Nomenclatura delle parti principali dello scafo"
    },
    {
        "risposta_1": "dalla parola SECURITE' ripetuta tre volte.",
        "risposta_3": "dalle parole PAN PAN ripetuta tre volte.",
        "ris_2": "F",
        "domanda": "Da quale parola è preceduta la chiamata di sicurezza?",
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "immagine": "",
        "ris_3": "F",
        "progressivo": "1.3.9-15",
        "ris_1": "V",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "dalla parola MAYDAY ripetuta tre volte.",
        "id": 430
    },
    {
        "ris_1": "F",
        "domanda": "Il Mayday va ripetuto durante la chiamata:",
        "risposta_1": "1 volta.",
        "immagine": "",
        "ris_3": "F",
        "risposta_2": "3 volte.",
        "risposta_3": "5 volte.",
        "id": 431,
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "progressivo": "1.3.9-16",
        "ris_2": "V",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE"
    },
    {
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "F",
        "risposta_2": "ogni ora,",
        "ris_2": "F",
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "risposta_3": "nei primi 3 minuti successivi all'inizio dell'ora intera e della mezz'ora.",
        "immagine": "",
        "domanda": "L'obbligo del silenzio radio sul canale 16 si ha:",
        "risposta_1": "non esiste alcun obbligo.",
        "progressivo": "1.3.9-17",
        "id": 432,
        "ris_3": "V"
    },
    {
        "ris_2": "F",
        "id": 433,
        "ris_3": "V",
        "risposta_1": "tranquillamente, sempre se non c'è situazione di emergenza.",
        "risposta_3": "solo per la prima chiamata; per proseguire la comunicazione bisogna poi spostarsi su un altro canale.",
        "immagine": "",
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "risposta_2": "solo in caso di comunicazioni riguardanti la sicurezza.",
        "progressivo": "1.3.9-18",
        "domanda": "Il canale 16 sulla banda di frequenza VHF è utilizzabile:",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "F"
    },
    {
        "id": 434,
        "ris_3": "V",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "progressivo": "1.3.9-19",
        "ris_1": "F",
        "risposta_3": "si comunicano nell'ordine: nominativo internazionale, coordinate della posizione e tipo di pericolo in corso.",
        "risposta_1": "lo si lancia a intervalli di tre minuti.",
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "domanda": "Nel caso si renda necessario lanciare un MAYDAY via radio:",
        "immagine": "",
        "ris_2": "F",
        "risposta_2": "lo si lancia sulla frequenza di lavoro della stazione radio più vicina"
    },
    {
        "domanda": "Come si impone il silenzio radio col VHF ?",
        "risposta_1": "pronunciando la parola SILENCE MAYDAY.",
        "progressivo": "1.3.9-20",
        "ris_3": "F",
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "ris_2": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "V",
        "immagine": "",
        "id": 435,
        "risposta_3": "pronunciando la parola PAN PAN.",
        "risposta_2": "pronunciando la parola SECURITÈ."
    },
    {
        "risposta_3": "il canale 14.",
        "ris_1": "F",
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "ris_3": "F",
        "id": 436,
        "progressivo": "1.3.9-21",
        "risposta_2": "i canali 6, 8, 72 o 77.",
        "domanda": "Per le comunicazioni tra barca e barca si possono usare i canali",
        "risposta_1": "i canali 16 e 68.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_2": "V",
        "immagine": ""
    },
    {
        "ris_2": "V",
        "risposta_3": "si deve selezionare sempre la potenza di 1 watt.",
        "id": 437,
        "ris_3": "F",
        "immagine": "",
        "risposta_2": "quando si è a distanza ravvicinata è opportuno utilizzare la potenza ridotta di 1 watt.",
        "domanda": "Per le comunicazioni tramite VHF con apparato fisso:",
        "risposta_1": "è sempre opportuno usare la potenza massima di emissione di 25 watt, perché il segnale sia sempre chiaro.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "ris_1": "F",
        "progressivo": "1.3.9-22"
    },
    {
        "risposta_1": "tutti gli apparati ricetrasmittenti di bordo delle unità da diporto sono sottoposti a collaudo e a ispezioni ordinarie.",
        "ris_2": "F",
        "risposta_3": "il comandante dell'unità da diporto è responsabile del corretto utilizzo degli impianti e degli apparati ricetrasmittenti di bordo.",
        "risposta_2": "il VHF può essere utilizzato solo dal comandante dell'unità da diporto.",
        "immagine": "",
        "progressivo": "1.3.9-23",
        "domanda": "In tema di VHF, quale tra le seguenti affermazioni è corretta:",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "F",
        "id": 438,
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "ris_3": "V"
    },
    {
        "immagine": "",
        "risposta_3": "le antenne di due stazioni comunicanti sono ubicate ad un'altezza di circa un metro al di sotto della linea dell'orizzonte.",
        "ris_3": "F",
        "progressivo": "1.3.9-24",
        "risposta_2": "le antenne di due stazioni comunicanti sono ubicate al di sopra della linea dell'orizzonte.",
        "ris_2": "V",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "domanda": "Le comunicazioni radio effettuate mediante apparati VHF possono essere effettuate qualora:",
        "id": 439,
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "risposta_1": "le antenne di due stazioni comunicanti sono ubicate ad un'altezza di circa due metri al di sotto della linea dell'orizzonte.",
        "ris_1": "F"
    },
    {
        "domanda": "Il flying bridge, detto anche fly, è:",
        "ris_2": "F",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_3": "un tipo di vela.",
        "risposta_2": "il ponte principale, dove si trova la timoneria principale.",
        "ris_3": "F",
        "risposta_1": "il ponte superiore di una unità a motore, dove è collocata la seconda timoneria.",
        "ris_1": "V",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "progressivo": "1.1.1-44",
        "immagine": "",
        "id": 44
    },
    {
        "immagine": "",
        "risposta_1": "inferiore a 5 miglia nautiche.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "ris_1": "F",
        "domanda": "Qual è di massima la portata dell'apparato VHF per le comunicazioni fra unità navali?",
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "ris_2": "V",
        "risposta_2": "fra 10 e 20 miglia nautiche",
        "progressivo": "1.3.9-25",
        "ris_3": "F",
        "risposta_3": "circa 30 miglia nautiche.",
        "id": 440
    },
    {
        "progressivo": "1.3.9-26",
        "immagine": "",
        "risposta_2": "inferiore alle 20 miglia nautiche.",
        "domanda": "Qual è la portata massima dell'apparato VHF per le comunicazioni tra un'unità navale e le stazioni radio costiere?",
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "risposta_1": "circa 40 miglia nautiche.",
        "ris_2": "F",
        "ris_3": "F",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "id": 441,
        "risposta_3": "circa 150 miglia nautiche.",
        "ris_1": "V"
    },
    {
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "risposta_2": "rafforzare l'eco del radar installato a bordo.",
        "risposta_3": "garantire il collegamento sul canale di lavoro restando in ricezione anche sul canale 16.",
        "progressivo": "1.3.9-27",
        "risposta_1": "attenuare il rumore di fondo durante le comunicazioni.",
        "domanda": "A quale funzione assolve lo squelch di un ricevitore radio?",
        "immagine": "",
        "ris_3": "F",
        "ris_1": "V",
        "ris_2": "F",
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "id": 442
    },
    {
        "risposta_1": "intensificare l'eco di ritorno al radar, permettendo di attenuare i disturbi di origine meteorologica.",
        "domanda": "A quale funzione assolve il riflettore radar, installato a bordo delle piccole unità da diporto?",
        "ris_2": "V",
        "progressivo": "1.3.9-28",
        "risposta_2": "intensificare l'eco di ritorno al radar, permettendo di identificare anche una piccola unità navale su cui è installato, sia a grande distanza che tra i vari echi del mare.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "ris_1": "F",
        "immagine": "",
        "id": 443,
        "ris_3": "F",
        "risposta_3": "intensificare l'eco di ritorno al radar, permettendo di amplificare i campi magnetici di bordo."
    },
    {
        "ris_2": "F",
        "risposta_2": "tra i minuti 03-06 e 33-36 delle ore pari.",
        "capitolo": "SICUREZZA DELLA NAVIGAZIONE",
        "argomento": "Corretto uso degli apparati radio di bordo e chiamate di soccorso",
        "risposta_1": "tra i minuti 00-03 e 30-33 di ogni ora.",
        "ris_3": "F",
        "progressivo": "1.3.9-29",
        "ris_1": "V",
        "id": 444,
        "immagine": "",
        "risposta_3": "tra i minuti 00-03 e 30-33 delle ore pari.",
        "domanda": "Per motivi di sicurezza, quali sono gli intervalli temporali per i quali è vietato effettuare comunicazioni radio?"
    },
    {
        "risposta_1": "il pagamento di una somma da 414 euro a 2.066 euro.",
        "id": 445,
        "immagine": "",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_3": "non è prevista una sanzione pecuniaria.",
        "ris_3": "F",
        "argomento": "Precauzioni all'ingresso  e  all'uscita dei  porti",
        "domanda": "Qual è la sanzione amministrativa prevista per coloro che superano i limiti di velocità previsti per la navigazione negli specchi d'acqua portuali?",
        "risposta_2": "il pagamento di una somma da 2775 a 11.017 euro.",
        "ris_2": "F",
        "ris_1": "V",
        "progressivo": "1.4.1-1"
    },
    {
        "ris_1": "F",
        "risposta_2": "no, non esiste.",
        "ris_2": "F",
        "domanda": "Esiste un limite di velocità per un'unità da diporto in transito all'interno di un porto?",
        "immagine": "",
        "ris_3": "V",
        "risposta_1": "sì, è la velocità minima prima di entrare in planata.",
        "capitolo": "MANOVRA E CONDOTTA",
        "progressivo": "1.4.1-2",
        "argomento": "Precauzioni all'ingresso  e  all'uscita dei  porti",
        "risposta_3": "sì, è stabilito dall'Autorità marittima di giurisdizione, generalmente 3 nodi.",
        "id": 446
    },
    {
        "risposta_2": "in tutti, eccetto Genova.",
        "risposta_1": "in nessun porto.",
        "ris_3": "V",
        "id": 447,
        "capitolo": "MANOVRA E CONDOTTA",
        "immagine": "",
        "domanda": "In quali porti bisogna tenere la dritta sia entrando sia uscendo?",
        "progressivo": "1.4.1-3",
        "ris_2": "F",
        "ris_1": "F",
        "risposta_3": "nei porti come disciplinato con ordinanza dell'Autorità marittima.",
        "argomento": "Precauzioni all'ingresso  e  all'uscita dei  porti"
    },
    {
        "ris_3": "F",
        "immagine": "",
        "risposta_3": "in nessun porto.",
        "id": 448,
        "ris_2": "F",
        "progressivo": "1.4.1-4",
        "risposta_1": "in tutti i porti in cui così stabilisca il regolamento dell'Autorità marittima.",
        "ris_1": "V",
        "risposta_2": "in tutti i porti.",
        "capitolo": "MANOVRA E CONDOTTA",
        "domanda": "In quali porti bisogna dare la precedenza alle unità che escono su quelle che entrano?",
        "argomento": "Precauzioni all'ingresso  e  all'uscita dei  porti"
    },
    {
        "progressivo": "1.4.1-5",
        "ris_3": "F",
        "risposta_3": "il concessionario del servizio di rimorchio.",
        "risposta_2": "non dobbiamo avvisare nessuno.",
        "risposta_1": "l'Autorità marittima.",
        "argomento": "Precauzioni all'ingresso  e  all'uscita dei  porti",
        "domanda": "Entrando in un porto commerciale, privo di attrezzature da diporto, di norma dobbiamo avvisare:",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_2": "F",
        "id": 449,
        "immagine": "",
        "ris_1": "V"
    },
    {
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_3": "la larghezza massima dello scafo.",
        "domanda": "Cos'è il baglio massimo:",
        "immagine": "",
        "progressivo": "1.1.1-45",
        "risposta_2": "il peso totale che la nave può trasportare.",
        "ris_3": "V",
        "risposta_1": "la distanza verticale tra la linea di galleggiamento e la parte superiore della chiglia.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "ris_2": "F",
        "ris_1": "F",
        "id": 45
    },
    {
        "risposta_3": "se con scarsa visibilità, segnaliamo la nostra presenza con 2 suoni brevi.",
        "immagine": "",
        "ris_2": "F",
        "argomento": "Precauzioni all'ingresso  e  all'uscita dei  porti",
        "ris_1": "V",
        "risposta_2": "di notte, i fanali in testata ai moli emettono luce fissa verde per via libera.",
        "ris_3": "F",
        "id": 450,
        "risposta_1": "diamo precedenza alle manovre delle navi di grande dimensioni.",
        "domanda": "Salvo le ordinanze locali, di norma, in prossimità dell'ingresso di un porto:",
        "progressivo": "1.4.1-6",
        "capitolo": "MANOVRA E CONDOTTA"
    },
    {
        "progressivo": "1.4.1-7",
        "argomento": "Precauzioni all'ingresso  e  all'uscita dei  porti",
        "domanda": "Accingendosi all'ingresso in un porto italiano, in navigazione notturna, e rilevando i fanali dell'imboccatura in modo che il verde sia sulla propria sinistra e il rosso sia sulla propria dritta:",
        "ris_1": "V",
        "immagine": "",
        "ris_2": "F",
        "ris_3": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_1": "si deve cambiare rotta perché si sta andando contro il molo foraneo.",
        "risposta_3": "è possibile procedere su questa rotta d'ingresso perché non si rilevano unità in uscita.",
        "risposta_2": "ci si deve tenere sulla dritta in modo da non ostacolare l'eventuale uscita di altre unità.",
        "id": 451
    },
    {
        "progressivo": "1.4.1-8",
        "risposta_3": "due torrette o colonnine: verde a sinistra e rossa a dritta.",
        "ris_2": "V",
        "ris_3": "F",
        "id": 452,
        "risposta_1": "due torrette o colonnine: rossa a sinistra e gialla a dritta.",
        "risposta_2": "due torrette o colonnine: rossa a sinistra verde a dritta.",
        "immagine": "",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_1": "F",
        "domanda": "Di giorno come si presenta l’ingresso dell'imboccatura di un porto?",
        "argomento": "Precauzioni all'ingresso  e  all'uscita dei  porti"
    },
    {
        "risposta_1": "l'unità A, perché proviene da dritta.",
        "domanda": "Salvo le ordinanze locali, di norma quale delle due imbarcazioni in figura a lato ha il diritto di precedenza?",
        "immagine": 20,
        "risposta_3": "l'unità B, perché sta uscendo dal porto.",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_3": "V",
        "ris_1": "F",
        "risposta_2": "l'unità A, perché è in fase di avvicinamento all'imboccatura del porto.",
        "id": 453,
        "progressivo": "1.4.1-9",
        "ris_2": "F",
        "argomento": "Precauzioni all'ingresso  e  all'uscita dei  porti"
    },
    {
        "risposta_2": "a 1000 metri.",
        "risposta_3": "a 500 metri.",
        "domanda": "Salvo le ordinanze locali, a che distanza dall’ingresso del porto è buona norma ridurre la velocità di un'imbarcazione da diporto?",
        "ris_2": "F",
        "ris_1": "F",
        "ris_3": "V",
        "risposta_1": "dipende dalle dimensioni della nostra unità da diporto.",
        "capitolo": "MANOVRA E CONDOTTA",
        "immagine": "",
        "id": 454,
        "progressivo": "1.4.1-10",
        "argomento": "Precauzioni all'ingresso  e  all'uscita dei  porti"
    },
    {
        "ris_2": "V",
        "capitolo": "MANOVRA E CONDOTTA",
        "progressivo": "1.4.1-11",
        "risposta_1": "4 nodi per le unità a motore e 2 nodi per le unità a vela.",
        "risposta_2": "3 nodi.",
        "argomento": "Precauzioni all'ingresso  e  all'uscita dei  porti",
        "ris_3": "F",
        "ris_1": "F",
        "immagine": "",
        "id": 455,
        "risposta_3": "in base al tempo, alla visibilità ed alle dimensioni dell'unità, ad una velocità compresa tra 4 nodi e 10 nodi.",
        "domanda": "Salvo le ordinanze locali, a quale velocità è buona norma entrare in porto?"
    },
    {
        "argomento": "Precauzioni all'ingresso  e  all'uscita dei  porti",
        "progressivo": "1.4.1-12",
        "ris_3": "V",
        "immagine": "",
        "id": 456,
        "ris_2": "F",
        "domanda": "Salvo ordinanze locali, di norma è possibile entrare in un porto navigando a vela?",
        "ris_1": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_2": "sì, è sempre possibile.",
        "risposta_1": "sì, ma riducendo la velatura in modo da sviluppare un'andatura ridotta.",
        "risposta_3": "no, non è possibile."
    },
    {
        "ris_3": "F",
        "ris_1": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_3": "2 fanali: verde e rosso o verde e bianco sormontati da una luce gialla a lampi.",
        "ris_2": "V",
        "argomento": "Precauzioni all'ingresso  e  all'uscita dei  porti",
        "risposta_2": "2 fanali: verde sulla dritta e rosso sulla sinistra.",
        "domanda": "Come viene segnalato di notte l’ingresso di un porto?",
        "risposta_1": "2 fanali rossi a 800 m dall’ingresso e 2 fanali verdi in prossimità dell’ingresso.",
        "id": 457,
        "immagine": "",
        "progressivo": "1.4.1-13"
    },
    {
        "risposta_2": "verso il fanale rosso, in qualsiasi condizioni di moto effettivo.",
        "domanda": "Salvo le ordinanze locali, volendo entrare in porto, verso quale fanale si deve condurre la propria unità?",
        "immagine": "",
        "ris_1": "V",
        "risposta_3": "verso il fanale verde o rosso, l’importante è ridurre la velocità e dare la precedenza alle unità in uscita.",
        "progressivo": "1.4.1-14",
        "ris_2": "F",
        "argomento": "Precauzioni all'ingresso  e  all'uscita dei  porti",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_3": "F",
        "risposta_1": "verso il fanale verde.",
        "id": 458
    },
    {
        "risposta_3": "emettendo 2 suoni lunghi e 2 brevi.",
        "progressivo": "1.4.1-15",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_3": "F",
        "ris_2": "V",
        "argomento": "Precauzioni all'ingresso  e  all'uscita dei  porti",
        "risposta_1": "emettendo 5 suoni brevi (segnale di pericolo).",
        "immagine": "",
        "ris_1": "F",
        "id": 459,
        "risposta_2": "emettendo 1 suono prolungato e ascoltando l’eventuale risposta.",
        "domanda": "In uscita dal porto, nel dubbio di non essere visto da altre imbarcazioni, come ci si  comporta?"
    },
    {
        "progressivo": "1.1.1-46",
        "risposta_3": "un elemento essenziale per il rinforzo trasversale dell'imbarcazione.",
        "capitolo": "TEORIA DELLO SCAFO",
        "id": 46,
        "immagine": "",
        "domanda": "Il pagliolo di un'imbarcazione da diporto è:",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "ris_2": "V",
        "ris_3": "F",
        "risposta_2": "un piano amovibile e calpestabile sotto coperta.",
        "ris_1": "F",
        "risposta_1": "una trave longitudinale che sostiene i bagli."
    },
    {
        "immagine": "",
        "risposta_2": "ha diritto al risarcimento solo se il danno patito sia conseguenza di un urto per contatto fisico diretto di nave contro nave.",
        "domanda": "Conseguentemente all'ormeggio da me compiuto e del conseguente moto ondoso generato, l'unità ormeggiata al mio fianco patisce dei danni per aver urtato contro la banchina.\nPertanto, il danneggiato:",
        "ris_3": "F",
        "risposta_1": "ha diritto al risarcimento del danno stante l'irregolare condotta della navigazione e la conseguente responsabilità per urto tra navi, anche se dovuto al solo moto ondoso.",
        "progressivo": "1.4.1-16",
        "risposta_3": "ha torto poiché la sua unità non avrebbe urtato in banchina se egli si fosse prodigato per assicurare adeguatamente i suoi ormeggi nel mentre che mi accingevo ad ormeggiare la mia unità.",
        "capitolo": "MANOVRA E CONDOTTA",
        "argomento": "Precauzioni all'ingresso  e  all'uscita dei  porti",
        "ris_1": "V",
        "ris_2": "F",
        "id": 460
    },
    {
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_3": "F",
        "progressivo": "1.4.1-17",
        "ris_1": "F",
        "domanda": "Fatte salve le ordinanze locali, come deve comportarsi l’unità che transita nei 500 metri antistanti l’ingresso del porto?",
        "risposta_3": "se è a vela ha diritto di precedenza.",
        "ris_2": "V",
        "risposta_2": "deve dare la precedenze alle unità in entrata e in uscita dal porto.",
        "risposta_1": "valgono le normali regole di precedenza.",
        "id": 461,
        "argomento": "Precauzioni all'ingresso  e  all'uscita dei  porti",
        "immagine": ""
    },
    {
        "immagine": "",
        "risposta_2": "dal Prefetto.",
        "risposta_3": "dal Ministero delle Infrastrutture.",
        "id": 462,
        "ris_1": "V",
        "ris_2": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "domanda": "Per il demanio marittimo non in regime di concessione destinato alla navigazione e al trasporto marittimo, la riserva per gli accosti alle  unità da diporto in transito è disciplinata:",
        "ris_3": "F",
        "progressivo": "1.4.1-18",
        "risposta_1": "con ordinanza del Capo del circondario marittimo competente.",
        "argomento": "Precauzioni all'ingresso  e  all'uscita dei  porti"
    },
    {
        "immagine": "",
        "capitolo": "MANOVRA E CONDOTTA",
        "argomento": "Precauzioni all'ingresso  e  all'uscita dei  porti",
        "risposta_2": "è consentita la pesca nell'ambito dei bacini portuali.",
        "id": 463,
        "domanda": "Quale affermazione è corretta?",
        "ris_2": "F",
        "risposta_1": "un'unità che naviga lungo un canale  deve, quando è possibile e non comporta pericolo, mantenersi vicino al limite di destra rispetto alla propria rotta.",
        "risposta_3": "all'interno dei campi boe è consentito l'ancoraggio.",
        "progressivo": "1.4.1-19",
        "ris_1": "V",
        "ris_3": "F"
    },
    {
        "risposta_3": "per la durata di 72 ore.",
        "ris_2": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_3": "V",
        "id": 464,
        "domanda": "I concessionari delle strutture dedicate alla nautica da diporto devono riservare ormeggi in transito:",
        "ris_1": "F",
        "argomento": "Precauzioni all'ingresso  e  all'uscita dei  porti",
        "risposta_2": "per la durata di 48 ore.",
        "immagine": "",
        "risposta_1": "per la durata di 24 ore.",
        "progressivo": "1.4.1-20"
    },
    {
        "capitolo": "MANOVRA E CONDOTTA",
        "immagine": "",
        "domanda": "Gli ormeggi riservati alle persone con disabilità:",
        "ris_2": "F",
        "risposta_2": "non possono mai essere occupati da altra unità.",
        "ris_3": "F",
        "ris_1": "V",
        "risposta_1": "quando non impegnati a tale fine, possono essere occupati da altra unità, con l'obbligo di essere liberati in caso di richiesta di portatore di handicap comunicata al concessionario almeno 24 ore prima.",
        "progressivo": "1.4.1-21",
        "argomento": "Precauzioni all'ingresso  e  all'uscita dei  porti",
        "risposta_3": "possono essere occupati solo da natanti per trascorrere la notte.",
        "id": 465
    },
    {
        "risposta_1": "navigare a una velocità non superiore a 10 nodi nella fascia di mare compresa tra il limite della balneazione e i\n1.000 metri dalla costa.",
        "immagine": "",
        "ris_2": "F",
        "ris_3": "F",
        "domanda": "Quale accorgimento deve essere adottato da un'unità navale da diporto a motore in navigazione in prossimità della costa durante la stagione balneare?",
        "ris_1": "V",
        "risposta_3": "navigare a una velocità non superiore a 10 nodi nella fascia di mare compresa tra i 50 e i 250 metri dalla costa.",
        "argomento": "Navigazione in prossimità della costa",
        "id": 466,
        "progressivo": "1.4.2-1",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_2": "navigare a una velocità superiore a 10 nodi nella fascia di mare compresa tra i 250 e i 500 metri dalla costa."
    },
    {
        "immagine": "",
        "risposta_3": "a una distanza di almeno 100 metri.",
        "risposta_2": "a una distanza di almeno 20 metri.",
        "progressivo": "1.4.2-2",
        "id": 467,
        "ris_3": "V",
        "argomento": "Navigazione in prossimità della costa",
        "domanda": "A quale distanza minima devono mantenersi le unità navali dalle boe di segnalazione di subacquei, avvistate durante la navigazione?",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_1": "F",
        "ris_2": "F",
        "risposta_1": "a una distanza di almeno 50 metri."
    },
    {
        "id": 468,
        "ris_3": "F",
        "argomento": "Navigazione in prossimità della costa",
        "ris_2": "V",
        "ris_1": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_3": "Codice Internazionale dei Segnali e i Portolani.",
        "risposta_1": "SOLAS e COLREG.",
        "immagine": "",
        "risposta_2": "ordinanze dei locali Capi di Circondario Marittimo ai sensi dell'art. 81 del Codice della Navigazione.",
        "domanda": "Quali norme disciplinano i limiti di navigazione dalla costa e le prescrizioni relative all'atterraggio delle unità navali da diporto in spiaggia?",
        "progressivo": "1.4.2-3"
    },
    {
        "argomento": "Navigazione in prossimità della costa",
        "domanda": "Qual è, di massima, la distanza minima dalle spiagge oltre la quale è possibile circolare, sostare o ancorarsi da parte di un'unità navale?",
        "immagine": "",
        "risposta_2": "di massima 1.000 metri.",
        "risposta_3": "di massima 200 metri.",
        "ris_3": "V",
        "risposta_1": "di massima 500 metri.",
        "id": 469,
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_1": "F",
        "ris_2": "F",
        "progressivo": "1.4.2-4"
    },
    {
        "ris_2": "F",
        "domanda": "La distanza verticale posta tra la coperta e la linea di galleggiamento è:",
        "id": 47,
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "ris_3": "F",
        "risposta_3": "l'opera viva.",
        "progressivo": "1.1.1-47",
        "ris_1": "V",
        "risposta_2": "l'immersione.",
        "risposta_1": "il bordo libero.",
        "capitolo": "TEORIA DELLO SCAFO",
        "immagine": ""
    },
    {
        "risposta_3": "mediante il posizionamento di gavitelli di colore giallo posti ad una distanza di 100 metri l'uno dall'altro parallelamente alla linea di costa.",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_3": "F",
        "ris_2": "V",
        "argomento": "Navigazione in prossimità della costa",
        "immagine": "",
        "risposta_2": "mediante il posizionamento di gavitelli di colore rosso posti ad una distanza di 50 metri l'uno dall'altro parallelamente alla linea di costa.",
        "risposta_1": "mediante il posizionamento di gavitelli di colore bianco e rosso posti ad una distanza di 100 metri l'uno dall'altro parallelamente alla linea di costa.",
        "domanda": "Come sono segnalati i confini degli specchi acquei prospicienti i 200 metri dalla linea di battigia durante la stagione balneare?",
        "progressivo": "1.4.2-5",
        "id": 470,
        "ris_1": "F"
    },
    {
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_3": "con gavitelli di colore bianco e rosso posti perpendicolarmente alla costa sino a una distanza di 500 metri.",
        "argomento": "Navigazione in prossimità della costa",
        "ris_2": "V",
        "ris_3": "F",
        "ris_1": "F",
        "immagine": "",
        "progressivo": "1.4.2-6",
        "risposta_1": "con gavitelli di colore verde a dritta e rosso a sinistra posti perpendicolarmente alla costa sino a una distanza di 250 metri.",
        "risposta_2": "con gavitelli di colore giallo o arancione posti perpendicolarmente alla costa sino a una distanza di 250 metri.",
        "id": 471,
        "domanda": "Come sono segnalati i corridoi di lancio che consentono l'atterraggio e la partenza delle unità sulla spiaggia, durante la stagione balneare?"
    },
    {
        "progressivo": "1.4.2-7",
        "risposta_1": "mediante bandiere bianche posizionate sui gavitelli esterni.",
        "immagine": "",
        "ris_1": "V",
        "risposta_3": "mediante bandiere rosse con banda obliqua bianca posizionate sui gavitelli esterni.",
        "capitolo": "MANOVRA E CONDOTTA",
        "id": 472,
        "ris_3": "F",
        "domanda": "Come sono segnalati i gavitelli esterni di delimitazione dei corridoi di lancio che consentono l'atterraggio e la partenza delle unità sulla spiaggia, durante la stagione balneare?",
        "argomento": "Navigazione in prossimità della costa",
        "risposta_2": "mediante bandiere rosse posizionate sui gavitelli esterni.",
        "ris_2": "F"
    },
    {
        "ris_1": "F",
        "progressivo": "1.4.2-8",
        "ris_3": "F",
        "domanda": "Oltre ai segnalamenti marittimi previsti dalle norme in vigore, quali segnalamenti marittimi contraddistinguono un'unità navale impiegata in attività subacquee durante le ore diurne?",
        "risposta_3": "una bandiera di colore bianco con diagonale rossa.",
        "risposta_1": "un pallone bianco sul quale è posta una bandiera di colore arancione.",
        "risposta_2": "un pallone rosso sul quale è posta una bandiera di colore rosso con diagonale bianca.",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_2": "V",
        "immagine": "",
        "id": 473,
        "argomento": "Navigazione in prossimità della costa"
    },
    {
        "risposta_3": "oltre ai segnalamenti marittimi previsti,  una luce lampeggiante  gialla visibile a 360° a una distanza non inferiore ai 300 metri.",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_1": "oltre ai segnalamenti marittimi previsti,  una luce lampeggiante rossa visibile a 360° a una distanza non inferiore ai 300 metri.",
        "ris_2": "F",
        "immagine": "",
        "progressivo": "1.4.2-9",
        "id": 474,
        "ris_3": "V",
        "ris_1": "F",
        "domanda": "Quale segnalamenti marittimi contraddistinguono un'unità impiegata in attività subacquee durante le ore notturne?",
        "risposta_2": "oltre ai segnalamenti marittimi previsti,  una luce lampeggiante  bianca visibile a 360° a una distanza non inferiore ai 300 metri.",
        "argomento": "Navigazione in prossimità della costa"
    },
    {
        "id": 475,
        "progressivo": "1.4.2-10",
        "ris_2": "F",
        "risposta_2": "il pagamento di una somma da 2.066 euro a 4.000 euro.",
        "domanda": "Quale sanzione amministrativa è prevista per coloro che utilizzano un'unità da diporto superando i limiti di velocità previsti?",
        "risposta_3": "il pagamento di una somma da 414 euro a 818 euro.",
        "ris_1": "V",
        "argomento": "Navigazione in prossimità della costa",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_3": "F",
        "risposta_1": "il pagamento di una somma da 414 euro a 2.066 euro.",
        "immagine": ""
    },
    {
        "ris_3": "F",
        "immagine": "",
        "risposta_1": "mi avvicino a lento moto con l'ausilio dei remi e comunque assumendo una rotta in direzione perpendicolare al profilo di costa.",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_2": "uso il tender propulso con il motore fuori bordo e mi muovo a lento moto.",
        "progressivo": "1.4.2-11",
        "id": 476,
        "ris_2": "F",
        "risposta_3": "uso la moto d'acqua di bordo a lento moto.",
        "domanda": "Durante la stagione balneare, quale percorso devo seguire per raggiungere la riva (posto l'assoluta necessità per motivi di emergenza)?",
        "ris_1": "V",
        "argomento": "Navigazione in prossimità della costa"
    },
    {
        "immagine": "",
        "domanda": "Un'unità in transito in prossimità di un subacqueo:",
        "progressivo": "1.4.2-12",
        "argomento": "Navigazione in prossimità della costa",
        "ris_1": "V",
        "risposta_2": "modera la velocità e prosegue la navigazione mantenendosi ad una distanza di ad almeno 50 metri di distanza dal segnale.",
        "id": 477,
        "ris_3": "F",
        "risposta_3": "accelera per allontanarsi dal segnale sino alla distanza minima di sicurezza prescritta dalla legge che è di 500 metri.",
        "risposta_1": "modera la velocità e prosegue la navigazione mantenendosi ad una distanza di almeno 100 metri di distanza dal segnale.",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_2": "F"
    },
    {
        "ris_3": "V",
        "immagine": "",
        "ris_1": "F",
        "id": 478,
        "ris_2": "F",
        "risposta_3": "modificare il proprio percorso di rotta per mantenersi a debita distanza dai limiti del campo di gara.",
        "argomento": "Navigazione in prossimità della costa",
        "risposta_1": "attraversare il campo di regata non appena le unità in gara si siano spostate in altro settore della zona di regata.",
        "domanda": "Se durante la navigazione si rileva che sulla propria rotta è in corso di svolgimento una manifestazione sportiva in un campo di regata; fermo restando le prescrizioni impartite con l'ordinanza di polizia marittima, per proseguire la  navigazione sono adottate le seguenti precauzioni:",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_2": "comunicare sul canale 16 VHF l'intenzione di attraversare il campo di gara e attendere istruzioni.",
        "progressivo": "1.4.2-13"
    },
    {
        "risposta_1": "ha imbarcato il pilota del porto.",
        "risposta_3": "è ferma.",
        "domanda": "La bandiera A (Alfa) in figura a fianco, prescritta dal Codice Internazionale dei Segnali, è esposta da un'unità per indicare che:",
        "progressivo": "1.4.2-14",
        "immagine": 21,
        "id": 479,
        "argomento": "Navigazione in prossimità della costa",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_1": "F",
        "ris_2": "V",
        "risposta_2": "ha un palombaro in immersione.",
        "ris_3": "F"
    },
    {
        "progressivo": "1.1.1-48",
        "risposta_1": "la sezione trasversale centrale che, normalmente, ha maggior larghezza",
        "ris_3": "F",
        "ris_2": "F",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "id": 48,
        "risposta_3": "la sezione iniziale dello scafo",
        "domanda": "Cosa è la sezione maestra dello scafo?",
        "capitolo": "TEORIA DELLO SCAFO",
        "immagine": "",
        "ris_1": "V",
        "risposta_2": "la zona in cui si comanda."
    },
    {
        "ris_3": "V",
        "argomento": "Navigazione in prossimità della costa",
        "progressivo": "1.4.2-15",
        "risposta_1": "un pericolo isolato.",
        "ris_1": "F",
        "risposta_3": "un subacqueo in immersione nel raggio di 50 metri dal segnale.",
        "capitolo": "MANOVRA E CONDOTTA",
        "domanda": "Una bandierina rossa con banda diagonale bianca posta su un galleggiante indica la presenza di:",
        "immagine": "",
        "risposta_2": "attrezzi di pesca presenti in mare nel raggio di 100 metri dal segnale.",
        "id": 480,
        "ris_2": "F"
    },
    {
        "argomento": "Navigazione in prossimità della costa",
        "id": 481,
        "risposta_1": "non inferiore a 100 metri.",
        "immagine": "",
        "risposta_2": "non inferiore a 200 metri.",
        "ris_1": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "domanda": "Per segnalare la propria posizione, il subacqueo in immersione notturna deve mostrare una boa munita di segnale luminoso che emetta lampi gialli visibili, a giro d'orizzonte, a una distanza:",
        "risposta_3": "non inferiore a 300 metri.",
        "ris_2": "F",
        "ris_3": "V",
        "progressivo": "1.4.2-16"
    },
    {
        "ris_2": "V",
        "argomento": "Navigazione in prossimità della costa",
        "id": 482,
        "risposta_2": "è permesso il lancio e l'atterraggio di natanti da diporto propulsi a motore.",
        "ris_1": "F",
        "progressivo": "1.4.2-17",
        "immagine": "",
        "risposta_1": "è possibile lanciarsi in tuffi durante la balneazione.",
        "ris_3": "F",
        "domanda": "I corridoi di lancio sono zone di mare dove:",
        "risposta_3": "è permesso il lancio ma non anche l'atterraggio di natanti propulsi a remi.",
        "capitolo": "MANOVRA E CONDOTTA"
    },
    {
        "risposta_2": "Sì, ma esclusivamente con un'unità iscritta.",
        "id": 483,
        "risposta_3": "No, assolutamente.",
        "progressivo": "1.4.2-18",
        "ris_2": "F",
        "ris_3": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_1": "Sì, entro certi limiti di cattura.",
        "argomento": "Navigazione in prossimità della costa",
        "ris_1": "V",
        "domanda": "Si può esercitare l'attività di pesca sportiva con un'unità da diporto?",
        "immagine": ""
    },
    {
        "argomento": "Navigazione in prossimità della costa",
        "progressivo": "1.4.2-19",
        "immagine": "",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_3": "F",
        "risposta_1": "100 metri.",
        "id": 484,
        "domanda": "La distanza massima intercorrente tra il pescatore subacqueo e la sua boa di segnalazione è pari a:",
        "ris_2": "V",
        "ris_1": "F",
        "risposta_3": "150 metri.",
        "risposta_2": "50 metri."
    },
    {
        "argomento": "Navigazione in prossimità della costa",
        "immagine": "",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_3": "F",
        "progressivo": "1.4.2-20",
        "risposta_2": "entro 1 miglio dalla costa.",
        "id": 485,
        "risposta_3": "entro 2 chilometri dalla costa.",
        "ris_2": "V",
        "domanda": "La moto d'acqua può navigare:",
        "ris_1": "F",
        "risposta_1": "entro mille metri dalla costa."
    },
    {
        "ris_3": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "domanda": "La tavola a vela può navigare:",
        "immagine": "",
        "ris_2": "F",
        "id": 486,
        "risposta_3": "entro mille metri dalla costa.",
        "progressivo": "1.4.2-21",
        "argomento": "Navigazione in prossimità della costa",
        "ris_1": "V",
        "risposta_2": "entro 2 chilometri dalla costa.",
        "risposta_1": "entro 1 miglio dalla costa."
    },
    {
        "ris_1": "F",
        "id": 487,
        "risposta_1": "entro 6 miglia dalla costa.",
        "ris_2": "F",
        "argomento": "Navigazione in prossimità della costa",
        "ris_3": "V",
        "progressivo": "1.4.2-22",
        "immagine": "",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_3": "entro 1 miglio dalla costa o dall'unità madre, ovunque si trovi.",
        "risposta_2": "entro 1 miglio dalla costa.",
        "domanda": "I battelli al servizio (tender) dell'unità-madre da diporto possono navigare:"
    },
    {
        "domanda": "Un natante con superficie velica non superiore a 4 metri quadrati può navigare:",
        "argomento": "Navigazione in prossimità della costa",
        "progressivo": "1.4.2-23",
        "risposta_1": "entro 1 miglio dalla costa.",
        "ris_2": "F",
        "ris_1": "V",
        "ris_3": "F",
        "id": 488,
        "capitolo": "MANOVRA E CONDOTTA",
        "immagine": "",
        "risposta_3": "entro mille metri dalla costa",
        "risposta_2": "entro 2 chilometri dalla costa."
    },
    {
        "id": 489,
        "risposta_1": "entro 2 chilometri dalla costa.",
        "risposta_2": "entro 1 miglio dalla costa.",
        "progressivo": "1.4.2-24",
        "immagine": "",
        "ris_1": "F",
        "ris_3": "F",
        "domanda": "I natanti comunemente denominati pattini, jole, pedalò, mosconi, ecc., possono navigare:",
        "risposta_3": "entro 500 metri dalla costa.",
        "ris_2": "V",
        "capitolo": "MANOVRA E CONDOTTA",
        "argomento": "Navigazione in prossimità della costa"
    },
    {
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "risposta_1": "la sovrastruttura elevata sulla coperta, che non si estende per tutta la larghezza dell'unità.",
        "id": 49,
        "immagine": "",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_3": "la sovrastruttura elevata sulla coperta, che si estende per tutta la larghezza dell'unità.",
        "progressivo": "1.1.1-49",
        "domanda": "In un'imbarcazione da diporto la tuga è:",
        "ris_3": "F",
        "ris_2": "F",
        "ris_1": "V",
        "risposta_2": "il pozzetto a poppa ovvero la parte ribassata rispetto al piano di coperta."
    },
    {
        "immagine": "",
        "ris_1": "F",
        "argomento": "Navigazione in prossimità della costa",
        "id": 490,
        "domanda": "La navigazione a motore può essere interdetta?",
        "ris_2": "F",
        "ris_3": "V",
        "risposta_3": "si, per esempio nella fascia di mare prioritariamente riservata alla balneazione.",
        "capitolo": "MANOVRA E CONDOTTA",
        "progressivo": "1.4.2-25",
        "risposta_1": "mai.",
        "risposta_2": "no, non può essere soggetta ad alcuna limitazione."
    },
    {
        "ris_2": "F",
        "risposta_1": "oltre 500 metri dalle spiagge frequentate dai bagnanti.",
        "risposta_3": "non esistono limiti di distanza.",
        "capitolo": "MANOVRA E CONDOTTA",
        "progressivo": "1.4.2-26",
        "ris_1": "V",
        "domanda": "La pesca subacquea sportiva è consentita:",
        "ris_3": "F",
        "argomento": "Navigazione in prossimità della costa",
        "risposta_2": "oltre 200 metri dalle spiagge frequentate dai bagnanti.",
        "id": 491,
        "immagine": ""
    },
    {
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_1": "V",
        "risposta_1": "no.",
        "immagine": "",
        "ris_3": "F",
        "domanda": "Si può effettuare la pesca subacquea sportiva con fucile nelle ore notturne?",
        "id": 492,
        "progressivo": "1.4.2-27",
        "argomento": "Navigazione in prossimità della costa",
        "risposta_2": "sì, se il pescatore è segnalato da una sorgente di luce.",
        "ris_2": "F",
        "risposta_3": "sì, purchè in prossimità di un'unità di appoggio."
    },
    {
        "id": 493,
        "ris_1": "F",
        "progressivo": "1.4.2-28",
        "argomento": "Navigazione in prossimità della costa",
        "capitolo": "MANOVRA E CONDOTTA",
        "domanda": "E' possibile l'uso della rete a circuizione per l'esercizio della pesca sportiva con unità da diporto?",
        "ris_2": "F",
        "immagine": "",
        "risposta_2": "sì, per la pesca sportiva in ore diurne.",
        "risposta_1": "sì, per la pesca sportiva in ore notturne.",
        "ris_3": "V",
        "risposta_3": "no, non è possibile."
    },
    {
        "immagine": "",
        "ris_1": "V",
        "progressivo": "1.4.2-29",
        "risposta_1": "no, non è possibile.",
        "risposta_3": "sì, è possibile.",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_3": "F",
        "id": 494,
        "ris_2": "F",
        "risposta_2": "sì, a seguito di rilascio del previsto permesso di pesca.",
        "domanda": "E' possibile praticare la pesca professionale a bordo delle unità da diporto?",
        "argomento": "Navigazione in prossimità della costa"
    },
    {
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_1": "F",
        "id": 495,
        "progressivo": "1.4.2-30",
        "domanda": "L'esercizio della pesca subacquea sportiva è vietato a distanza inferiore a:",
        "immagine": "",
        "argomento": "Navigazione in prossimità della costa",
        "risposta_2": "100 metri dagli impianti fissi di pesca.",
        "risposta_1": "200 metri dalle spiagge frequentate da bagnanti.",
        "risposta_3": "500 metri dalle navi all'ancora in rada.",
        "ris_3": "F",
        "ris_2": "V"
    },
    {
        "ris_2": "V",
        "argomento": "Navigazione in prossimità della costa",
        "id": 496,
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_3": "si, ma solo per la raccolta di coralli, molluschi e crostacei.",
        "risposta_2": "no, mai.",
        "domanda": "La pesca subacquea sportiva può essere esercitata con l'uso di apparecchi ausiliari di respirazione?",
        "immagine": "",
        "ris_3": "F",
        "progressivo": "1.4.2-31",
        "ris_1": "F",
        "risposta_1": "si, in ogni caso."
    },
    {
        "risposta_2": "è vietato a meno di 500 metri da unità in attività di pesca professionale.",
        "ris_2": "V",
        "argomento": "Navigazione in prossimità della costa",
        "ris_3": "F",
        "id": 497,
        "domanda": "L'esercizio dell'attività di pesca sportiva (non subacquea) con l'unità da diporto:",
        "ris_1": "F",
        "immagine": "",
        "risposta_3": "non ammette l'uso di fonti luminose notturne, senza alcuna eccezione.",
        "risposta_1": "è soggetto a limiti di età.",
        "capitolo": "MANOVRA E CONDOTTA",
        "progressivo": "1.4.2-32"
    },
    {
        "argomento": "Ancoraggio",
        "domanda": "Da cosa dipende la resistenza alla trazione di un'ancora?",
        "risposta_2": "dal suo peso e, in parte, dalla forma.",
        "ris_1": "F",
        "risposta_3": "dalla presenza di spigoli vivi.",
        "progressivo": "1.4.3-1",
        "ris_3": "F",
        "ris_2": "V",
        "id": 498,
        "risposta_1": "dalla conformazione del diamante.",
        "capitolo": "MANOVRA E CONDOTTA",
        "immagine": ""
    },
    {
        "ris_1": "F",
        "risposta_2": "la distanza tra il verricello dell'ancora e il livello medio del mare, nonché l'altezza minima delle onde.",
        "capitolo": "MANOVRA E CONDOTTA",
        "domanda": "Quali tra questi fattori dovranno essere tenuti in considerazione prima di dare fondo all'ancora?",
        "risposta_1": "il rapporto tra la lunghezza dell'unità navale e le lunghezze di catena da filare in relazione all'altezza minima e massima delle onde.",
        "immagine": "",
        "progressivo": "1.4.3-2",
        "ris_3": "V",
        "id": 499,
        "ris_2": "F",
        "risposta_3": "la presenza di possibili divieti nonché la situazione meteomarina locale.",
        "argomento": "Ancoraggio"
    },
    {
        "domanda": "Cos'è il ponte di coperta?",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "ris_2": "F",
        "immagine": "",
        "capitolo": "TEORIA DELLO SCAFO",
        "id": 5,
        "risposta_2": "la superficie orizzontale atta a ricoprire la parte più alta dell'unità.",
        "ris_1": "V",
        "risposta_1": "il ponte che si estende longitudinalmente e trasversalmente, in modo continuo, racchiudendo interamente lo scafo.",
        "ris_3": "F",
        "progressivo": "1.1.1-5",
        "risposta_3": "la struttura che ricopre le cabine."
    },
    {
        "domanda": "I candelieri sono:",
        "ris_2": "F",
        "progressivo": "1.1.1-50",
        "risposta_2": "gli appigli orizzontali di sicurezza.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "risposta_3": "elementi verticali delle battagliole.",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_1": "fanali bianchi da accendere in caso di emergenza.",
        "ris_3": "V",
        "ris_1": "F",
        "immagine": "",
        "id": 50
    },
    {
        "progressivo": "1.4.3-3",
        "risposta_2": "da 1 a 2 volte il fondale.",
        "domanda": "Quante lunghezze di cima-catena è opportuno filare durante la manovra di ancoraggio?",
        "risposta_3": "una quantità di catena prossima alla profondità del fondale..",
        "ris_2": "F",
        "id": 500,
        "ris_1": "V",
        "ris_3": "F",
        "immagine": "",
        "argomento": "Ancoraggio",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_1": "da 3 a 5 volte il fondale."
    },
    {
        "risposta_3": "con una quantità di catena tale da farla risultare a picco.",
        "argomento": "Ancoraggio",
        "risposta_1": "in presenza di condimeteo particolarmente avverse.",
        "domanda": "Quando viene utilizzato di massima l'ormeggio su di un ancora o a ruota?",
        "immagine": "",
        "progressivo": "1.4.3-4",
        "ris_3": "F",
        "id": 501,
        "risposta_2": "negli specchi d'acqua caratterizzati da un adeguato spazio libero intorno.",
        "ris_2": "V",
        "ris_1": "F",
        "capitolo": "MANOVRA E CONDOTTA"
    },
    {
        "risposta_2": "utilizzando due ancore le cui catene creino tra loro un angolo di 180°, affondate nella direzione della corrente.",
        "id": 502,
        "ris_3": "F",
        "immagine": "",
        "progressivo": "1.4.3-5",
        "risposta_3": "utilizzando una sola ancora la cui catena abbia una lunghezza pari a due volte il fondale.",
        "capitolo": "MANOVRA E CONDOTTA",
        "argomento": "Ancoraggio",
        "risposta_1": "utilizzando due ancore le cui catene siano disposte parallelamente tra loro e affondate in direzione perpendicolare alla corrente.",
        "ris_2": "V",
        "domanda": "Quale tipologia di ancoraggio è opportuno adottare, di massima, nei fiumi?",
        "ris_1": "F"
    },
    {
        "domanda": "L'ancora a ombrello, con le marre richiudibile, è generalmente è utilizzata:",
        "risposta_1": "da piccole unità, tra cui i battelli gonfiabili.",
        "risposta_2": "da unità di grandi dimensioni.",
        "argomento": "Ancoraggio",
        "risposta_3": "da unità  a vela.",
        "id": 503,
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_1": "V",
        "ris_3": "F",
        "progressivo": "1.4.3-6",
        "immagine": "",
        "ris_2": "F"
    },
    {
        "immagine": "",
        "risposta_2": "si tratta di un'ancora di piccole dimensioni a quattro marre mobili, utilizzata per manovre di affiancamento ad altre unità.",
        "capitolo": "MANOVRA E CONDOTTA",
        "domanda": "Quali sono le principali caratteristiche del grappino?",
        "risposta_3": "si tratta di un'ancora di piccole dimensioni a quattro marre fisse, utilizzata solo per ancoraggi di piccole unità.",
        "argomento": "Ancoraggio",
        "ris_3": "V",
        "ris_2": "F",
        "progressivo": "1.4.3-7",
        "ris_1": "F",
        "risposta_1": "si tratta di un'ancora di piccole dimensioni a otto marre fisse con artigli, utilizzata per ancoraggi di unità navali di grandi dimensioni.",
        "id": 504
    },
    {
        "risposta_3": "è un'ancora costituita da un monoblocco, dotata di una sola marra a forma di ala e priva di altre parti articolate.",
        "immagine": "",
        "ris_3": "V",
        "ris_2": "F",
        "domanda": "Quali sono le principali caratteristiche dell'ancora Bruce?",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_1": "è un'ancora costruita in lega di carbonio dotata di due marre divergenti e semi- mobili.",
        "risposta_2": "è un'ancora costruita in un'unica marra mobile a forma di martello.",
        "id": 505,
        "ris_1": "F",
        "progressivo": "1.4.3-8",
        "argomento": "Ancoraggio"
    },
    {
        "id": 506,
        "ris_3": "F",
        "risposta_2": "ellittica.",
        "domanda": "Le catene dell'ancora utilizzate a bordo delle imbarcazioni sono costituite da maglie aventi forma:",
        "risposta_1": "circolare.",
        "ris_2": "V",
        "ris_1": "F",
        "immagine": "",
        "capitolo": "MANOVRA E CONDOTTA",
        "progressivo": "1.4.3-9",
        "argomento": "Ancoraggio",
        "risposta_3": "iperbolica."
    },
    {
        "ris_3": "F",
        "risposta_2": "la ruota sagomata con impronta della catena, posta alla base del verricello, che evita lo slittamento delle maglie di catena durante le operazioni di manovra dell'ancora.",
        "immagine": "",
        "ris_2": "V",
        "domanda": "Cosa si intende per \"barbotin\"?",
        "risposta_1": "l'argano utilizzato per agevolare l'issaggio dei pesi a bordo dell'unità navale.",
        "id": 507,
        "argomento": "Ancoraggio",
        "risposta_3": "un verricello utilizzato per tenere le ancore in tensione durante la manovra di ancoraggio.",
        "capitolo": "MANOVRA E CONDOTTA",
        "progressivo": "1.4.3-10",
        "ris_1": "F"
    },
    {
        "ris_1": "V",
        "ris_3": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_2": "velocizzare la manovra di ancoraggio dell'unità rispetto all'ancoraggio con una sola ancora.",
        "progressivo": "1.4.3-11",
        "ris_2": "F",
        "id": 508,
        "risposta_1": "ridurre il campo di giro dell'unità che così assume una configurazione ellittica a differenza dell'ancoraggio a ruota.",
        "risposta_3": "garantire l'ancoraggio di un'unità  anche su fondali particolarmente rocciosi.",
        "domanda": "Quale vantaggio offre l'ancoraggio mediante due ancore afforcate?",
        "immagine": "",
        "argomento": "Ancoraggio"
    },
    {
        "id": 509,
        "risposta_2": "si dirige sul posto di ancoraggio a velocità di crociera e con la prora sottovento. Si fila l'ancora completamente una volta che l'unità è ferma.",
        "domanda": "Quali sono le fasi principali che caratterizzano una manovra di ancoraggio?",
        "immagine": "",
        "ris_3": "V",
        "ris_2": "F",
        "progressivo": "1.4.3-12",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_1": "F",
        "argomento": "Ancoraggio",
        "risposta_1": "si dirige sul posto di ancoraggio a velocità moderata e con la poppa al vento. Si fila la catena dell'ancora dando un leggero colpo a marcia avanti.",
        "risposta_3": "si dirige sul posto di ancoraggio con il solo abbrivio, mantenendo la prua al vento o alla corrente. Si dà fondo all'ancora,  dando contemporaneamente un leggero colpo a marcia indietro."
    },
    {
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "progressivo": "1.1.1-51",
        "ris_3": "V",
        "risposta_1": "sentina.",
        "ris_1": "F",
        "immagine": "",
        "id": 51,
        "ris_2": "F",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_3": "pagliolato.",
        "risposta_2": "coperta.",
        "domanda": "Il piano di calpestìo più basso di un’imbarcazione da diporto si chiama:"
    },
    {
        "id": 510,
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_3": "se si è costretti ad ancorare su un fondo sabbioso o argilloso ove l'ancora può arare.",
        "progressivo": "1.4.3-13",
        "domanda": "Quando è opportuno utilizzare la grippia e il grippiale prima di dare fondo all'ancora?",
        "risposta_2": "se si è costretti ad ancorare su un fondo fangoso, ove l'ancora può penetrare molto.",
        "argomento": "Ancoraggio",
        "immagine": "",
        "ris_1": "V",
        "ris_2": "F",
        "ris_3": "F",
        "risposta_1": "se si è costretti ad ancorare su un fondale roccioso o cosparso di relitti, ove l'ancora può restare incastrata."
    },
    {
        "risposta_2": "da una cima piuttosto sottile, di cui un'estremità è vincolata al diamante dell'ancora mentre l'altra è attestata ad un gavitello.",
        "risposta_3": "da una catena formata da maglie circolari, di cui un'estremità è vincolata al maniglione dell'ancora e l'altra a un golfare posto a proravia dell'unità navale.",
        "domanda": "Com'è costituita una \"grippia\"?",
        "immagine": "",
        "ris_2": "V",
        "risposta_1": "da una catena formata da maglie ellittiche, di cui un'estremità è vincolata al maniglione  dell'ancora e l'altra a un parabordo.",
        "ris_1": "F",
        "argomento": "Ancoraggio",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_3": "F",
        "id": 511,
        "progressivo": "1.4.3-14"
    },
    {
        "ris_3": "F",
        "progressivo": "1.4.3-15",
        "risposta_2": "è opportuno effettuare dei rilevamenti  successivi, mediante di punti cospicui della costa, oppure dei punti nave successivi.",
        "ris_2": "V",
        "risposta_1": "è opportuno effettuare delle ispezioni subacquee a intervalli di tempo regolare.",
        "argomento": "Ancoraggio",
        "ris_1": "F",
        "risposta_3": "scandagliare il fondale a prora e a poppa rispettivamente all'alba e al tramonto.",
        "immagine": "",
        "capitolo": "MANOVRA E CONDOTTA",
        "domanda": "Quali accorgimenti devono essere adottati per controllare la corretta tenuta dell'ancora?",
        "id": 512
    },
    {
        "ris_1": "F",
        "immagine": "",
        "risposta_3": "marre.",
        "domanda": "I bracci delle ancore sono denominati:",
        "risposta_2": "bracci.",
        "argomento": "Ancoraggio",
        "risposta_1": "uncini.",
        "ris_3": "V",
        "id": 513,
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_2": "F",
        "progressivo": "1.4.3-16"
    },
    {
        "immagine": "",
        "argomento": "Ancoraggio",
        "risposta_1": "ha fatto presa sul fondo.",
        "risposta_3": "si presenta rovesciata sul fondale.",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_2": "F",
        "risposta_2": "si è incattivita.",
        "progressivo": "1.4.3-17",
        "domanda": "Quando un'ancora fa testa, significa che:",
        "ris_1": "V",
        "ris_3": "F",
        "id": 514
    },
    {
        "domanda": "Riguardo alla tenuta di un ancoraggio, si può dire che:",
        "immagine": "",
        "risposta_3": "l'ancora deve rimanere orizzontale sul fondo, anche se la barca fa forza sul calumo.",
        "risposta_1": "il calumo è bene che sia lungo sempre 10 metri.",
        "argomento": "Ancoraggio",
        "ris_2": "F",
        "id": 515,
        "ris_3": "V",
        "progressivo": "1.4.3-18",
        "ris_1": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_2": "è sempre meglio scegliere un fondale roccioso."
    },
    {
        "domanda": "La parte inferiore, al centro delle marre, di un'ancora è denominata:",
        "ris_2": "F",
        "risposta_3": "diamante.",
        "ris_1": "F",
        "immagine": "",
        "risposta_2": "scalmo.",
        "argomento": "Ancoraggio",
        "risposta_1": "unghia.",
        "capitolo": "MANOVRA E CONDOTTA",
        "progressivo": "1.4.3-19",
        "id": 516,
        "ris_3": "V"
    },
    {
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_2": "F",
        "risposta_1": "che abbiamo finito di calare un’ancora.",
        "immagine": "",
        "domanda": "Il termine calumo indica:",
        "risposta_2": "la profondità della zona ove si vuole dar fondo all’ancora.",
        "id": 517,
        "ris_3": "V",
        "risposta_3": "la parte di catena che viene a trovarsi fuori bordo.",
        "ris_1": "F",
        "progressivo": "1.4.3-20",
        "argomento": "Ancoraggio"
    },
    {
        "ris_3": "V",
        "argomento": "Ancoraggio",
        "ris_2": "F",
        "risposta_3": "tra 15 e 20 Kg.",
        "domanda": "Secondo una buona regola marinara, qual è il peso ideale di un'ancora da utilizzare per un'unità di 10 metri?",
        "id": 518,
        "risposta_2": "tra 10 e 14 Kg.",
        "capitolo": "MANOVRA E CONDOTTA",
        "immagine": "",
        "ris_1": "F",
        "progressivo": "1.4.3-21",
        "risposta_1": "tra 21 e 25 Kg."
    },
    {
        "ris_1": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_1": "non riesce a spedare le ancore.",
        "ris_3": "V",
        "risposta_2": "ha dato fondo a 2 ancore con calumi aperti a 180 gradi circa.",
        "ris_2": "F",
        "id": 519,
        "domanda": "Un'unità afforcata è quell'unità che:",
        "immagine": "",
        "risposta_3": "ha dato fondo a 2 ancore con calumi aperti a 45 gradi circa.",
        "argomento": "Ancoraggio",
        "progressivo": "1.4.3-22"
    },
    {
        "risposta_2": "catamarano.",
        "immagine": "",
        "risposta_1": "piatta.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "domanda": "La carena viene detta dislocante se è del tipo:",
        "progressivo": "1.1.1-52",
        "ris_1": "F",
        "ris_2": "F",
        "capitolo": "TEORIA DELLO SCAFO",
        "id": 52,
        "ris_3": "V",
        "risposta_3": "tonda."
    },
    {
        "risposta_1": "dare un calumo adatto alle condizioni meteo.",
        "ris_2": "F",
        "argomento": "Ancoraggio",
        "risposta_3": "dar fondo anche da poppa ad un'ancora supplementare.",
        "ris_3": "V",
        "id": 520,
        "ris_1": "F",
        "immagine": "",
        "progressivo": "1.4.3-23",
        "domanda": "Nell'ancoraggio alla ruota cosa non è da fare?",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_2": "prendere dei rilevamenti di un paio di punti della costa."
    },
    {
        "id": 521,
        "immagine": "",
        "ris_1": "V",
        "ris_2": "F",
        "argomento": "Ancoraggio",
        "domanda": "Se un'ancora non tiene, si dice che:",
        "progressivo": "1.4.3-24",
        "risposta_3": "sta agguantando.",
        "risposta_1": "sta arando.",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_3": "F",
        "risposta_2": "fa testa."
    },
    {
        "risposta_3": "fuso.",
        "risposta_1": "marra.",
        "immagine": "",
        "ris_1": "V",
        "ris_3": "F",
        "progressivo": "1.4.3-25",
        "ris_2": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "id": 522,
        "domanda": "Com'è denominata la parte dell'ancora che fa presa sul fondo?",
        "argomento": "Ancoraggio",
        "risposta_2": "diamante."
    },
    {
        "capitolo": "MANOVRA E CONDOTTA",
        "progressivo": "1.4.3-26",
        "id": 523,
        "ris_3": "F",
        "ris_1": "F",
        "domanda": "La \"grippia\" è una cima che si lega:",
        "risposta_2": "al diamante dell'ancora per facilitarne il recupero.",
        "argomento": "Ancoraggio",
        "risposta_1": "al fuso per regolare l'ancoraggio.",
        "ris_2": "V",
        "risposta_3": "all'anello dell'ancora per evitare che l'ancora ari.",
        "immagine": ""
    },
    {
        "immagine": "",
        "risposta_1": "almeno 38 metri.",
        "risposta_2": "almeno 40 metri.",
        "risposta_3": "almeno 48 metri.",
        "ris_2": "F",
        "domanda": "Per stare alla fonda con mare calmo su un fondale di 16 metri, quanta cima bisogna filare?",
        "ris_1": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "argomento": "Ancoraggio",
        "id": 524,
        "progressivo": "1.4.3-27",
        "ris_3": "V"
    },
    {
        "risposta_1": "un gancio per le vele.",
        "domanda": "Il \"ferro\" a grappino è:",
        "capitolo": "MANOVRA E CONDOTTA",
        "argomento": "Ancoraggio",
        "progressivo": "1.4.3-28",
        "risposta_3": "un tipo di ancora di emergenza.",
        "ris_1": "F",
        "ris_3": "F",
        "immagine": "",
        "id": 525,
        "ris_2": "V",
        "risposta_2": "un ancorotto per piccole imbarcazioni."
    },
    {
        "id": 526,
        "progressivo": "1.4.3-29",
        "risposta_1": "almeno 21 metri.",
        "domanda": "Per stare alla fonda con mare calmo su un fondale di 9 metri, quanta cima bisogna filare?",
        "immagine": "",
        "ris_1": "F",
        "risposta_2": "almeno 15 metri.",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_3": "V",
        "ris_2": "F",
        "risposta_3": "almeno 27 metri.",
        "argomento": "Ancoraggio"
    },
    {
        "risposta_2": "almeno 15 metri.",
        "argomento": "Ancoraggio",
        "id": 527,
        "ris_1": "F",
        "ris_3": "F",
        "risposta_3": "almeno 11 metri.",
        "ris_2": "V",
        "risposta_1": "almeno 12 metri.",
        "domanda": "Per stare alla fonda con mare calmo su un fondale di 5 metri, quanta cima bisogna filare?",
        "immagine": "",
        "progressivo": "1.4.3-30",
        "capitolo": "MANOVRA E CONDOTTA"
    },
    {
        "ris_1": "V",
        "capitolo": "MANOVRA E CONDOTTA",
        "progressivo": "1.4.3-31",
        "risposta_3": "non si riesce a salpare.",
        "argomento": "Ancoraggio",
        "ris_2": "F",
        "domanda": "Quando un'ancora fa testa, significa che:",
        "risposta_2": "si è staccata dalla catena.",
        "risposta_1": "ha fatto presa sul fondo.",
        "ris_3": "F",
        "immagine": "",
        "id": 528
    },
    {
        "immagine": "",
        "ris_1": "F",
        "risposta_3": "il calumo viene aumentato da 3 a 5 volte il fondale in funzioni delle condizioni meteomarine.",
        "ris_3": "V",
        "capitolo": "MANOVRA E CONDOTTA",
        "domanda": "Riguardo alla tenuta di un ancoraggio, si può dire che:",
        "argomento": "Ancoraggio",
        "id": 529,
        "ris_2": "F",
        "risposta_1": "il calumo è bene che sia lungo al massimo due volte il fondale.",
        "risposta_2": "se il fondo è in pendenza, l'ancora deve essere tirata verso il fondale più profondo.",
        "progressivo": "1.4.3-32"
    },
    {
        "progressivo": "1.1.1-53",
        "id": 53,
        "risposta_1": "è la linea che divide lo scafo in opera viva e opera morta.",
        "risposta_3": "indica il limite superiore del bordo libero.",
        "ris_3": "F",
        "domanda": "La linea di galleggiamento:",
        "ris_1": "V",
        "immagine": "",
        "risposta_2": "è la parte terminale superiore della fiancata.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_2": "F"
    },
    {
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_1": "patta.",
        "domanda": "La parte inferiore, al centro delle marre, di un'ancora è denominata:",
        "ris_3": "V",
        "id": 530,
        "risposta_2": "ceppo.",
        "ris_2": "F",
        "progressivo": "1.4.3-33",
        "risposta_3": "diamante.",
        "immagine": "",
        "argomento": "Ancoraggio",
        "ris_1": "F"
    },
    {
        "ris_3": "V",
        "risposta_2": "la profondità della zona ove si vuole dar fondo all’ancora.",
        "ris_2": "F",
        "risposta_1": "che abbiamo finito di calare un’ancora.",
        "risposta_3": "la lunghezza di cima e/o catena filati per dar fondo all’ancora.",
        "capitolo": "MANOVRA E CONDOTTA",
        "domanda": "Il termine calumo indica:",
        "id": 531,
        "progressivo": "1.4.3-34",
        "argomento": "Ancoraggio",
        "immagine": "",
        "ris_1": "F"
    },
    {
        "ris_1": "F",
        "argomento": "Ancoraggio",
        "domanda": "Ancorare alla ruota significa:",
        "risposta_1": "il giro di 360 gradi intorno all’ancora per rendere efficace il grippiale.",
        "risposta_2": "il giro di 360 gradi effettuato intorno all’ancora prima di aver dato fondo.",
        "capitolo": "MANOVRA E CONDOTTA",
        "immagine": "",
        "risposta_3": "la libertà di rotazione di 360 gradi dell’imbarcazione alla fonda.",
        "progressivo": "1.4.3-35",
        "ris_2": "F",
        "id": 532,
        "ris_3": "V"
    },
    {
        "domanda": "L’ancoraggio utilizzando una sola ancora filata di prora è denominato:",
        "ris_2": "V",
        "ris_1": "F",
        "ris_3": "F",
        "id": 533,
        "immagine": "",
        "argomento": "Ancoraggio",
        "risposta_2": "alla ruota.",
        "risposta_1": "incattivito.",
        "capitolo": "MANOVRA E CONDOTTA",
        "progressivo": "1.4.3-36",
        "risposta_3": "appennellato."
    },
    {
        "id": 534,
        "argomento": "Ancoraggio",
        "progressivo": "1.4.3-37",
        "risposta_1": "ha fatto presa sul fondo.",
        "risposta_2": "non tiene la presa sul fondo.",
        "ris_1": "F",
        "domanda": "Di un'ancora si dice che speda se:",
        "ris_3": "F",
        "immagine": "",
        "ris_2": "V",
        "risposta_3": "è sospesa sotto la prua.",
        "capitolo": "MANOVRA E CONDOTTA"
    },
    {
        "risposta_3": "si dà fondo a 2 ancore con calumi aperti a 45 gradi circa.",
        "progressivo": "1.4.3-38",
        "risposta_1": "per una migliore tenuta in condizioni critiche, al diamante dell'ancora principale viene fissata una seconda ancora, detta \"pennello\", con una catena di 4-6 metri.",
        "ris_3": "F",
        "risposta_2": "si dà fondo a 2 ancore con calumi aperti a 180 gradi circa.",
        "id": 535,
        "ris_2": "F",
        "ris_1": "V",
        "capitolo": "MANOVRA E CONDOTTA",
        "immagine": "",
        "domanda": "Le cosiddette ancore appennellate si hanno quando:",
        "argomento": "Ancoraggio"
    },
    {
        "risposta_1": "lunghezza del cavo-catena deve essere sempre superiore a 6 volte il fondale.",
        "progressivo": "1.4.3-39",
        "risposta_2": "l'ancora Danforth è ottima su fondali sabbiosi-fangosi.",
        "risposta_3": "l'ancora Danforth è consigliata sui fondali rocciosi.",
        "immagine": "",
        "domanda": "Con riferimento all'utilizzo dell'ancora in funzione del fondale, è possibile affermare che:",
        "ris_2": "V",
        "ris_1": "F",
        "ris_3": "F",
        "argomento": "Ancoraggio",
        "capitolo": "MANOVRA E CONDOTTA",
        "id": 536
    },
    {
        "domanda": "Con riferimento ai vari tipi di ancore, è possibile affermare che:",
        "ris_2": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "immagine": "",
        "id": 537,
        "risposta_3": "la CQR e la Delta sono tipi di ancora per tutti i fondali.",
        "risposta_2": "la Danforth è adatta allo scoglio.",
        "progressivo": "1.4.3-40",
        "ris_3": "V",
        "ris_1": "F",
        "risposta_1": "la Bruce è la tipica ancora delle navi, passante lo scafo attraverso l’occhio di cubia.",
        "argomento": "Ancoraggio"
    },
    {
        "risposta_1": "sconsigliato perché si è esposti al moto ondoso.",
        "ris_2": "F",
        "argomento": "Ancoraggio",
        "risposta_3": "consigliato solo tra barche a motore.",
        "ris_3": "F",
        "ris_1": "V",
        "domanda": "L'ancoraggio in una baia di più unità a murata, è:",
        "progressivo": "1.4.3-41",
        "immagine": "",
        "id": 538,
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_2": "consigliato con mare molto mosso."
    },
    {
        "risposta_3": "dopo aver filato una quantità di cavo-cima pari alla profondità del fondale, si indietreggia velocemente.",
        "domanda": "La procedura comunemente utilizzata per un coretto ancoraggio è:",
        "argomento": "Ancoraggio",
        "capitolo": "MANOVRA E CONDOTTA",
        "id": 539,
        "progressivo": "1.4.3-42",
        "ris_3": "F",
        "ris_2": "V",
        "ris_1": "F",
        "risposta_2": "dopo aver filato un'adeguata quantità di calumo, si inizia a indietreggiare leggermente, continuando a filare il cavo- cima, al fine di stendere il calumo opportuno e far fare testa all'ancora.",
        "risposta_1": "dopo aver filato una quantità di cavo-cima pari almeno 3 volte il fondale, si spegne il motore.",
        "immagine": ""
    },
    {
        "risposta_1": "non plana e navigando sposta l’acqua a destra e a sinistra.",
        "ris_3": "V",
        "id": 54,
        "domanda": "Un catamarano:",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_2": "F",
        "ris_1": "F",
        "risposta_2": "non plana e si alza sul filo dell’acqua.",
        "immagine": "",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "progressivo": "1.1.1-54",
        "risposta_3": "presenta due scafi."
    },
    {
        "ris_2": "V",
        "id": 540,
        "risposta_3": "dopo aver filato una quantità adeguata di calumo, si dispone l'unità con prua al vento.",
        "risposta_2": "dopo aver disposto la prua dell'unità al vento, esaurito l'abbrivio, si cala l'ancora.",
        "ris_1": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_1": "dopo aver ingranato la retromarcia, si inizia a calare l'ancora.",
        "ris_3": "F",
        "domanda": "La procedura comunemente utilizzata per un coretto ancoraggio è:",
        "argomento": "Ancoraggio",
        "progressivo": "1.4.3-43",
        "immagine": ""
    },
    {
        "ris_1": "F",
        "argomento": "Ancoraggio",
        "ris_2": "F",
        "domanda": "Nella situazione illustrata di unità alla fonda, dove è più opportuno calare l'ancora?",
        "ris_3": "V",
        "risposta_2": "nel punto B.",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_1": "nel punto A.",
        "immagine": 22,
        "risposta_3": "nel punto C.",
        "progressivo": "1.4.3-44",
        "id": 541
    },
    {
        "argomento": "Ancoraggio",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_3": "F",
        "progressivo": "1.4.3-45",
        "ris_1": "F",
        "id": 542,
        "risposta_3": "quella della barca C, perché è la più lontana dalle altre unità.",
        "domanda": "Qual è la posizione corretta per l'ancoraggio nella rada affollata riportata in figura?",
        "immagine": 23,
        "risposta_1": "quella della barca A, perché più esterna alle altre unità.",
        "risposta_2": "quella della barca B, perché ha spazio per la ruota.",
        "ris_2": "V"
    },
    {
        "progressivo": "1.4.3-46",
        "ris_1": "F",
        "argomento": "Ancoraggio",
        "risposta_1": "iniziare ad ingranare la retromarcia prima di calare l'ancora.",
        "risposta_3": "filare velocemente una quantità di catena opportuna, allentando il barbotin del verricello salpancora.",
        "id": 543,
        "domanda": "In caso di ancoraggio con vento forte, è consigliabile:",
        "ris_3": "V",
        "risposta_2": "calare con prudenza e lentamente l'ancora.",
        "capitolo": "MANOVRA E CONDOTTA",
        "immagine": "",
        "ris_2": "F"
    },
    {
        "ris_1": "V",
        "argomento": "Ancoraggio",
        "risposta_1": "dare fondo all'ancora leggermente sopravento rispetto al posto barca che si vuole occupare.",
        "id": 544,
        "domanda": "In caso di vento forte, dovendo dare fondo all'ancora per poi ormeggiarsi di poppa alla banchina, è opportuno:",
        "risposta_3": "dare fondo in asse rispetto al posto barca che si vuole occupare.",
        "ris_2": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "immagine": "",
        "risposta_2": "dare fondo all'ancora leggermente sottovento rispetto al posto barca che si vuole occupare.",
        "progressivo": "1.4.3-47",
        "ris_3": "F"
    },
    {
        "id": 545,
        "progressivo": "1.4.3-48",
        "domanda": "L'ancoraggio in baia di più unità a murata è:",
        "ris_3": "F",
        "argomento": "Ancoraggio",
        "risposta_1": "sconsigliato perché si è esposti al moto ondoso.",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_1": "V",
        "immagine": "",
        "risposta_3": "consigliato solo tra barche a motore.",
        "risposta_2": "consigliato solo tra barche a vela.",
        "ris_2": "F"
    },
    {
        "ris_3": "V",
        "argomento": "Ancoraggio",
        "immagine": "",
        "risposta_1": "la Bruce è adatta alla Posidonia.",
        "ris_2": "F",
        "domanda": "In tema di ancore, quale affermazione è corretta?",
        "risposta_2": "la CQR è particolarmente adatta allo scoglio.",
        "progressivo": "1.4.3-49",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_1": "F",
        "risposta_3": "le ancore a tenuta dinamica, ad esempio Mantus e Ultra, sono adatte a tutti i fondali.",
        "id": 546
    },
    {
        "argomento": "Ancoraggio",
        "ris_2": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "id": 547,
        "progressivo": "1.4.3-50",
        "risposta_3": "3 volte il fondale.",
        "ris_3": "V",
        "immagine": "",
        "domanda": "In ancoraggio, quale è il calumo, cioè la lunghezza di cavo o catena, minimo da dare rispetto alla profondità del fondale?",
        "risposta_2": "6 volte il fondale.",
        "risposta_1": "1 volte il fondale.",
        "ris_1": "F"
    },
    {
        "risposta_1": "si accende il motore e con la marcia non ingranata e si attiva il salpancore.",
        "ris_3": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "domanda": "Per salpare l'ancora:",
        "ris_1": "F",
        "progressivo": "1.4.3-51",
        "id": 548,
        "risposta_2": "si dà un leggero colpo di marcia avanti per togliere tensione alla catena.",
        "argomento": "Ancoraggio",
        "risposta_3": "si avanza a motore fino al punto in cui è stata calata l'ancora e poi si inizio a recuperare catena.",
        "immagine": "",
        "ris_2": "V"
    },
    {
        "domanda": "Le caratteristiche dell'ancora Rocna sono:",
        "argomento": "Ancoraggio",
        "risposta_1": "un'unica marra fissa, a forma di lama concava, dotata di un roll-bar che assicura che non si posi ribaltata sul fondale.",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_1": "V",
        "ris_3": "F",
        "immagine": "",
        "id": 549,
        "ris_2": "F",
        "progressivo": "1.4.3-52",
        "risposta_3": "un'unica marra snodata con una forma ad aratro e lama convessa.",
        "risposta_2": "quattro marre mobili per essere utilizzata nelle manovre di affiancamento ad altre unità navali."
    },
    {
        "domanda": "Il timone è compensato quando:",
        "ris_3": "F",
        "risposta_2": "una parte della pala è a proravia dell'asse.",
        "progressivo": "1.1.1-55",
        "risposta_3": "corregge eventuali errori del timoniere.",
        "risposta_1": "è composto da un particolare tipo di legno.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "ris_2": "V",
        "immagine": "",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_1": "F",
        "id": 55
    },
    {
        "immagine": 24,
        "ris_2": "F",
        "risposta_3": "la presenza di una corrente sostenuta, di cui devo tener conto in manovra di ancoraggio.",
        "progressivo": "1.4.3-53",
        "risposta_1": "la probabile rotazione a Nord delle unità alla fonda.",
        "domanda": "Mi accingo ad ancorare e osservo la situazione rappresentata in figura, ne deduco:",
        "argomento": "Ancoraggio",
        "id": 550,
        "ris_3": "V",
        "risposta_2": "l'assenza di corrente.",
        "ris_1": "F",
        "capitolo": "MANOVRA E CONDOTTA"
    },
    {
        "capitolo": "MANOVRA E CONDOTTA",
        "progressivo": "1.4.4-1",
        "risposta_1": "la rotazione a Est delle unità alla fonda, qualora il vento cessasse.",
        "risposta_2": "la rotazione verso Sud delle unità alla fonda, qualora il vento cessasse.",
        "ris_1": "F",
        "risposta_3": "la rotazione verso Ovest delle unità alla fonda, qualora il vento cessasse.",
        "argomento": "Ormeggio e disormeggio",
        "immagine": 24,
        "id": 551,
        "ris_3": "V",
        "domanda": "Mi accingo ad ancorare e osservo la situazione rappresentata in figura, ne deduco.",
        "ris_2": "F"
    },
    {
        "risposta_1": "da un blocco di cemento posato sul fondo marino, a cui viene fissato il maniglione dell'ancora dell'unità.",
        "risposta_3": "dalla parte inutilizzata della catena a bordo dell'unità navale a cui è attestato un maniglione dell'ancora.",
        "immagine": "",
        "domanda": "Com'è composto un \"corpo morto\"?",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_2": "un blocco di cemento, posato sul fondo marino, a cui è fissato un anello ove viene agganciata una catena che presenta all'altra estremità un gavitello galleggiante in superficie.",
        "ris_3": "F",
        "id": 552,
        "ris_2": "V",
        "progressivo": "1.4.4-2",
        "ris_1": "F",
        "argomento": "Ormeggio e disormeggio"
    },
    {
        "progressivo": "1.4.4-3",
        "ris_1": "F",
        "ris_2": "V",
        "domanda": "La manovra di avvicinamento a una boa d'ormeggio si effettua:",
        "id": 553,
        "risposta_2": "facendo procedere l'unità navale a lento moto verso la boa, mantenendo la prora al vento o alla corrente.",
        "argomento": "Ormeggio e disormeggio",
        "risposta_1": "facendo procedere l'unità navale a lento moto verso la boa, mantenendo la poppa al vento o alla corrente.",
        "risposta_3": "facendo procedere l'unità navale alla velocità di crociera verso la boa, mantenendo la poppa al vento o alla corrente.",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_3": "F",
        "immagine": ""
    },
    {
        "ris_1": "V",
        "risposta_1": "risulta assicurata alla banchina mediante i cavi d'ormeggio.",
        "progressivo": "1.4.4-4",
        "risposta_3": "è ancorata mediante due ancore, a distanza di sicurezza dalla banchina.",
        "risposta_2": "ha filato un'ancora galleggiante.",
        "argomento": "Ormeggio e disormeggio",
        "ris_3": "F",
        "id": 554,
        "capitolo": "MANOVRA E CONDOTTA",
        "immagine": "",
        "ris_2": "F",
        "domanda": "Un'unità si dice attraccata, quando:"
    },
    {
        "risposta_2": "garantire un'adeguata immersione media dell'unità navale.",
        "ris_1": "V",
        "ris_3": "F",
        "argomento": "Ormeggio e disormeggio",
        "progressivo": "1.4.4-5",
        "risposta_1": "immobilizzare l'unità dai movimenti in senso longitudinale.",
        "id": 555,
        "domanda": "Quale funzione svolgono principalmente i cavi di ormeggio denominati \"spring\"?",
        "risposta_3": "garantire un'adeguata altezza di bordo libero.",
        "ris_2": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "immagine": ""
    },
    {
        "risposta_2": "non far scostare l'unità navale dalla banchina o da altra imbarcazione affiancata.",
        "risposta_1": "garantire un'adeguata altezza di bordo libero.",
        "ris_2": "V",
        "id": 556,
        "argomento": "Ormeggio e disormeggio",
        "domanda": "Quale funzione svolgono principalmente i cavi di ormeggio denominati \"traversini\"?",
        "immagine": "",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_3": "mantenere costante l'assetto dell'unità navale.",
        "progressivo": "1.4.4-6",
        "ris_1": "F",
        "ris_3": "F"
    },
    {
        "capitolo": "MANOVRA E CONDOTTA",
        "domanda": "Nell'ormeggio con la poppa in banchina, si dispongono le cime di poppa in maniera incrociata:",
        "ris_3": "F",
        "risposta_2": "per mantenere ferma la prua dell'unità.",
        "progressivo": "1.4.4-7",
        "argomento": "Ormeggio e disormeggio",
        "immagine": "",
        "ris_2": "F",
        "id": 557,
        "risposta_3": "per mantenere costante l'assetto dell'unità.",
        "ris_1": "V",
        "risposta_1": "in presenza di risacca,  al fine di evitare che la poppa possa muoversi lateralmente."
    },
    {
        "ris_2": "F",
        "risposta_2": "costituita da una coppia di cavi utilizzati per il rimorchio.",
        "progressivo": "1.4.4-8",
        "risposta_1": "fatta ruotare attorno alla bitta in banchina per fissare successivamente i due capi alla bitta di bordo.",
        "domanda": "Il \"doppino\" è una cima di ormeggio:",
        "immagine": "",
        "id": 558,
        "ris_1": "V",
        "risposta_3": "sono le cime di poppa disposte incrociate.",
        "ris_3": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "argomento": "Ormeggio e disormeggio"
    },
    {
        "ris_2": "F",
        "ris_1": "V",
        "id": 559,
        "risposta_2": "quando le due boe sono disposte trasversalmente all'unità.",
        "progressivo": "1.4.4-9",
        "risposta_3": "quando le due boe sono disposte entrambe a proravia dell'unità.",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_1": "che le due boe siano ubicate una a proravia e l'altra a poppavia dell'unità navale.",
        "immagine": "",
        "domanda": "Quale condizione è necessaria affinchè un'unità possa decidere di ormeggiarsi in sicurezza a due boe?",
        "ris_3": "F",
        "argomento": "Ormeggio e disormeggio"
    },
    {
        "capitolo": "TEORIA DELLO SCAFO",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "id": 56,
        "ris_3": "F",
        "risposta_1": "asse.",
        "risposta_2": "pala.",
        "domanda": "Come si chiama la superficie del timone su cui agisce la pressione dell'acqua?",
        "risposta_3": "ruota.",
        "progressivo": "1.1.1-56",
        "immagine": "",
        "ris_2": "V",
        "ris_1": "F"
    },
    {
        "risposta_3": "spring.",
        "argomento": "Ormeggio e disormeggio",
        "risposta_1": "calumo.",
        "risposta_2": "mascone.",
        "ris_2": "F",
        "id": 560,
        "ris_1": "F",
        "domanda": "In figura a fianco è indicata un'unità all'ormeggio \"all'inglese\", come sono denominati i cavi di ormeggio indicati dalle frecce?",
        "ris_3": "V",
        "capitolo": "MANOVRA E CONDOTTA",
        "progressivo": "1.4.4-10",
        "immagine": 25
    },
    {
        "risposta_1": "spring di poppa e spring di prua.",
        "argomento": "Ormeggio e disormeggio",
        "risposta_3": "doppini.",
        "domanda": "Con riferimento al disegno a fianco, con quale tipologia di cavi l'unità è ormeggiata?",
        "progressivo": "1.4.4-11",
        "risposta_2": "traversino di poppa e traversino di prua.",
        "ris_1": "F",
        "id": 561,
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_2": "V",
        "immagine": 26,
        "ris_3": "F"
    },
    {
        "ris_3": "F",
        "argomento": "Ormeggio e disormeggio",
        "ris_2": "V",
        "risposta_3": "no, perché ha bisogno della \"grippia\".",
        "immagine": 26,
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_2": "no, perché senza uno \"spring\" di prua e uno \"spring\" di poppa può muoversi lungo l'asse longitudinale.",
        "ris_1": "F",
        "progressivo": "1.4.4-12",
        "risposta_1": "si, perché i due traversini la tengono accostata alla banchina.",
        "domanda": "L'unità raffigurata può ritenersi correttamente ormeggiata?",
        "id": 562
    },
    {
        "argomento": "Ormeggio e disormeggio",
        "risposta_1": "nome alternativo delle cime di ormeggio.",
        "progressivo": "1.4.4-13",
        "id": 563,
        "ris_1": "F",
        "immagine": "",
        "risposta_2": "un cavo opzionale che può essere messo per sicurezza.",
        "ris_3": "V",
        "domanda": "Lo \"spring\" è:",
        "risposta_3": "il cavo d'ormeggio che da prua o dalla poppa dell'imbarcazione corre sulla banchina verso il centro barca, essenziale nell'ormeggio \"all'inglese\".",
        "ris_2": "F",
        "capitolo": "MANOVRA E CONDOTTA"
    },
    {
        "ris_2": "V",
        "progressivo": "1.4.4-14",
        "risposta_3": "è indifferente.",
        "argomento": "Ormeggio e disormeggio",
        "risposta_1": "quella sulla bitta B.",
        "ris_1": "F",
        "id": 564,
        "capitolo": "MANOVRA E CONDOTTA",
        "immagine": 27,
        "risposta_2": "quella sulla bitta A.",
        "domanda": "Nella situazione rappresentata dalla figura a fianco, quale cima di poppa va assicurata per prima?",
        "ris_3": "F"
    },
    {
        "ris_1": "V",
        "ris_2": "F",
        "immagine": 28,
        "ris_3": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "domanda": "Nella situazione rappresentata dalla figura a fianco, quale cima di poppa va assicurata per prima?",
        "progressivo": "1.4.4-15",
        "argomento": "Ormeggio e disormeggio",
        "risposta_2": "quella sulla bitta A.",
        "risposta_1": "quella sulla bitta B.",
        "id": 565,
        "risposta_3": "è indifferente."
    },
    {
        "ris_2": "F",
        "immagine": 29,
        "argomento": "Ormeggio e disormeggio",
        "risposta_2": "quella sulla bitta A.",
        "ris_1": "V",
        "progressivo": "1.4.4-16",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_3": "è indifferente.",
        "risposta_1": "quella sulla bitta B.",
        "id": 566,
        "domanda": "Nella situazione rappresentata dalla figura a fianco, quale cima di poppa va liberata per prima volendo lasciare la banchina?",
        "ris_3": "F"
    },
    {
        "ris_2": "V",
        "id": 567,
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_2": "quella sulla bitta A.",
        "risposta_1": "quella sulla bitta B.",
        "ris_1": "F",
        "domanda": "Nella situazione rappresentata dalla figura a fianco, quale cima di poppa va liberata per prima volendo lasciare la banchina?",
        "immagine": 30,
        "progressivo": "1.4.4-17",
        "ris_3": "F",
        "argomento": "Ormeggio e disormeggio",
        "risposta_3": "è indifferente."
    },
    {
        "risposta_1": "impiegato solo per sagole galleggianti utilizzate per il salvataggio.",
        "id": 568,
        "domanda": "Il cavo di polipropilene è:",
        "immagine": "",
        "progressivo": "1.4.4-18",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_3": "F",
        "argomento": "Ormeggio e disormeggio",
        "risposta_2": "adatto alle cime di ormeggio.",
        "ris_2": "F",
        "risposta_3": "adatto per le manovre delle unità a vela.",
        "ris_1": "V"
    },
    {
        "risposta_2": "solo per sagole galleggianti utilizzate per il salvataggio.",
        "domanda": "Il cavo di poliestere è utilizzato:",
        "id": 569,
        "ris_2": "F",
        "ris_3": "F",
        "immagine": "",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_1": "per le cime di ormeggio.",
        "progressivo": "1.4.4-19",
        "ris_1": "V",
        "argomento": "Ormeggio e disormeggio",
        "risposta_3": "non è utilizzato."
    },
    {
        "id": 57,
        "progressivo": "1.1.1-57",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "ris_2": "F",
        "ris_1": "V",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_3": "la pala è dotata di asse in alluminio.",
        "risposta_1": "una parte della pala è a proravia dell'asse.",
        "domanda": "Il timone è compensato se:",
        "ris_3": "F",
        "risposta_2": "tutta la pala  è a poppavia dell'asse.",
        "immagine": ""
    },
    {
        "risposta_3": "di grande tenuta, adatto per cavi di ormeggio.",
        "immagine": "",
        "capitolo": "MANOVRA E CONDOTTA",
        "domanda": "La gassa d'amante è un nodo:",
        "ris_1": "F",
        "ris_3": "V",
        "id": 570,
        "ris_2": "F",
        "risposta_1": "utilizzato per unire due cavi aventi diverso diametro.",
        "progressivo": "1.4.4-20",
        "argomento": "Ormeggio e disormeggio",
        "risposta_2": "che si usa per accorciare una cima di ormeggio."
    },
    {
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_2": "si usa per accorciare una cima.",
        "argomento": "Ormeggio e disormeggio",
        "immagine": "",
        "id": 571,
        "progressivo": "1.4.4-21",
        "ris_1": "V",
        "ris_3": "F",
        "risposta_3": "adatto per assicurare l'ancora.",
        "domanda": "Il nodo parlato:",
        "risposta_1": "è utile per fissare i parabordi a pulpiti e draglie.",
        "ris_2": "F"
    },
    {
        "risposta_3": "un sinonimo per indicare la grippia.",
        "argomento": "Ormeggio e disormeggio",
        "ris_1": "V",
        "progressivo": "1.4.4-22",
        "domanda": "Cos'è la trappa (o drappa)?",
        "capitolo": "MANOVRA E CONDOTTA",
        "immagine": "",
        "ris_3": "F",
        "risposta_1": "la cima che nei marina unisce la catenaria alla banchina e funge da ormeggio verso il largo.",
        "id": 572,
        "ris_2": "F",
        "risposta_2": "la cima che nei marina unisce la catenaria alla banchina e funge da ormeggio verso poppa."
    },
    {
        "risposta_3": "non va mai azionato durante la manovra d'ormeggio.",
        "argomento": "Ormeggio e disormeggio",
        "domanda": "Come avvalersi del \"bow truster\" intendendo ormeggiarsi in banchina sul proprio lato dritto?",
        "progressivo": "1.4.4-23",
        "id": 573,
        "ris_2": "V",
        "ris_1": "F",
        "risposta_1": "in accosto a sinistra, in modo da favorire la traslazione dell'unità parallelamente alla banchina.",
        "risposta_2": "in accosto a dritta, in modo da favorire la traslazione dell'unità parallelamente alla banchina.",
        "immagine": "",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_3": "F"
    },
    {
        "ris_2": "F",
        "ris_1": "V",
        "id": 574,
        "ris_3": "F",
        "risposta_2": "anemometro.",
        "risposta_1": "solcometro.",
        "argomento": "Ormeggio e disormeggio",
        "domanda": "Qual è lo strumento utilizzato per misurare la velocità delle unità?",
        "risposta_3": "ecoscandaglio.",
        "immagine": "",
        "capitolo": "MANOVRA E CONDOTTA",
        "progressivo": "1.4.4-24"
    },
    {
        "id": 575,
        "risposta_2": "la manovra n. 2.",
        "capitolo": "MANOVRA E CONDOTTA",
        "immagine": 31,
        "domanda": "Dovendo accostare di poppa, con un’unità munita di una sola elica destrorsa, quale manovra conviene:",
        "argomento": "Ormeggio e disormeggio",
        "progressivo": "1.4.4-25",
        "ris_3": "F",
        "ris_2": "V",
        "risposta_3": "è indifferente.",
        "ris_1": "F",
        "risposta_1": "la manovra n. 1."
    },
    {
        "ris_3": "F",
        "immagine": 31,
        "risposta_2": "la manovra n. 2.",
        "id": 576,
        "capitolo": "MANOVRA E CONDOTTA",
        "domanda": "Dovendo accostare di poppa con un'unità munità di una sola elica sinistrorsa, quale manovra conviene rispetto a quelle proposte nelle figure:",
        "progressivo": "1.4.4-26",
        "ris_2": "F",
        "ris_1": "V",
        "risposta_1": "la manovra n. 1.",
        "argomento": "Ormeggio e disormeggio",
        "risposta_3": "è indifferente."
    },
    {
        "immagine": 32,
        "ris_2": "F",
        "argomento": "Ormeggio e disormeggio",
        "progressivo": "1.4.4-27",
        "risposta_1": "la posizione 1.",
        "capitolo": "MANOVRA E CONDOTTA",
        "domanda": "Dovendo raggiungere la banchina con la poppa a bordo di una unità dotata di una sola elica sinistrorsa, è più conveniente assumere quale posizione di partenza:",
        "risposta_3": "la posizione 3.",
        "ris_3": "V",
        "id": 577,
        "ris_1": "F",
        "risposta_2": "la posizione 2."
    },
    {
        "id": 578,
        "ris_1": "F",
        "risposta_1": "la posizione 1.",
        "ris_2": "V",
        "risposta_3": "la posizione 3.",
        "argomento": "Ormeggio e disormeggio",
        "risposta_2": "la posizione 2.",
        "domanda": "Dovendo raggiungere la banchina con la poppa a bordo di una unità dotata di una sola elica destrorsa, è più conveniente assumere quale posizione di partenza:",
        "capitolo": "MANOVRA E CONDOTTA",
        "immagine": 32,
        "ris_3": "F",
        "progressivo": "1.4.4-28"
    },
    {
        "risposta_2": "con elica sinistrorsa, si retrocede perpendicolarmente alla banchina presentando il giardinetto di dritta alla banchina.",
        "argomento": "Ormeggio e disormeggio",
        "domanda": "Per effettuare un corretto accosto di poppa alla banchina:",
        "risposta_3": "con elica destrorsa, si retrocede perpendicolarmente alla banchina correggendo con il timone a sinistra.",
        "ris_1": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "id": 579,
        "risposta_1": "si retrocede perpendicolarmente alla banchina presentando il mascone e correggendo solo col timone.",
        "immagine": "",
        "ris_2": "V",
        "ris_3": "F",
        "progressivo": "1.4.4-29"
    },
    {
        "capitolo": "TEORIA DELLO SCAFO",
        "domanda": "La freccia verso l'unità rappresentata in figura a fianco indica il:",
        "ris_1": "F",
        "ris_3": "F",
        "risposta_1": "mascone.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "risposta_2": "traverso.",
        "immagine": 7,
        "risposta_3": "giardinetto.",
        "id": 58,
        "progressivo": "1.1.1-58",
        "ris_2": "V"
    },
    {
        "domanda": "L'operazione di avvicinamento a una banchina o a un galleggiante è conosciuta sotto il nome di:",
        "ris_2": "V",
        "immagine": "",
        "argomento": "Ormeggio e disormeggio",
        "progressivo": "1.4.4-30",
        "risposta_2": "attracco.",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_3": "bordeggio.",
        "risposta_1": "tonneggio.",
        "ris_3": "F",
        "id": 580,
        "ris_1": "F"
    },
    {
        "progressivo": "1.4.4-31",
        "immagine": "",
        "ris_1": "F",
        "ris_2": "F",
        "risposta_1": "se aziono il motore in marcia indietro avvicino la prua alla banchina.",
        "domanda": "Per accostarsi di fianco (all'inglese) a una banchina con un'unità dotata di un un'elica destrorsa:",
        "id": 581,
        "ris_3": "V",
        "risposta_2": "l'unità si muove parallelamente alla banchina.",
        "capitolo": "MANOVRA E CONDOTTA",
        "argomento": "Ormeggio e disormeggio",
        "risposta_3": "con la banchina a sinistra, si dà marcia indietro con il mascone di sinistra alla banchina, avvicinando la poppa e arrestando l'abbrivio."
    },
    {
        "argomento": "Ormeggio e disormeggio",
        "ris_3": "F",
        "ris_2": "V",
        "id": 582,
        "risposta_3": "con elica destrorsa, in marcia indietro mettiamo il timone a dritta.",
        "risposta_2": "con elica sinistrorsa, in marcia avanti mettiamo il timone a dritta, accostando decisamente a dritta.",
        "immagine": "",
        "capitolo": "MANOVRA E CONDOTTA",
        "progressivo": "1.4.4-32",
        "domanda": "Volendo ruotare sul posto da fermo, sfruttando contemporaneamente gli effetti evolutivi di elica e timone:",
        "risposta_1": "in marcia avanti, non agisce la corrente respinta sul timone.",
        "ris_1": "F"
    },
    {
        "domanda": "Per assicurare l'unità ad un gavitello, ci si lega:",
        "immagine": "",
        "risposta_1": "alla cima sotto il gavitello.",
        "id": 583,
        "risposta_2": "all'anello sulla sommità del gavitello.",
        "risposta_3": "come mi conviene indifferentemente, in quanto sono valide entrambe le soluzioni di cui alle risposte suddette.",
        "capitolo": "MANOVRA E CONDOTTA",
        "argomento": "Ormeggio e disormeggio",
        "ris_2": "F",
        "progressivo": "1.4.4-33",
        "ris_3": "F",
        "ris_1": "V"
    },
    {
        "capitolo": "MANOVRA E CONDOTTA",
        "domanda": "Come si misura la lunghezza di un rimorchio?",
        "risposta_3": "dalla prora del rimorchiatore alla poppa dell'ultima unità rimorchiata.",
        "progressivo": "1.4.4-34",
        "argomento": "Ormeggio e disormeggio",
        "ris_1": "F",
        "risposta_2": "dalla poppa del rimorchiatore alla poppa dell'ultima unità rimorchiata.",
        "immagine": "",
        "ris_3": "F",
        "id": 584,
        "risposta_1": "dalla prora del rimorchiatore alla prora dell'ultima unità rimorchiata.",
        "ris_2": "V"
    },
    {
        "risposta_2": "procedere con cautela, se necessario inserendo e disinserendo la marcia per garantire una velocità contenuta",
        "ris_3": "F",
        "risposta_3": "non ci sono limiti di velocità",
        "capitolo": "MANOVRA E CONDOTTA",
        "immagine": "",
        "ris_2": "V",
        "risposta_1": "tenere il motore con la marcia inserita al minimo dei giri",
        "ris_1": "F",
        "domanda": "In avvicinamento in una rada, si deve:",
        "progressivo": "1.4.4-35",
        "argomento": "Ormeggio e disormeggio",
        "id": 585
    },
    {
        "domanda": "Nella situazione di vento illustrata, qual è la manovra di approccio alla banchina più corretto?",
        "ris_1": "V",
        "progressivo": "1.4.4-36",
        "risposta_2": "immagine 2",
        "argomento": "Ormeggio e disormeggio",
        "ris_2": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_1": "immagine 1",
        "immagine": 33,
        "risposta_3": "immagine 3",
        "id": 586,
        "ris_3": "F"
    },
    {
        "ris_2": "F",
        "domanda": "Navigando all'interno di una rada, si deve tenere conto:",
        "progressivo": "1.4.4-37",
        "risposta_3": "che le manovre effettuate in velocità rendono più evidenti le proprie intenzioni.",
        "argomento": "Ormeggio e disormeggio",
        "ris_3": "F",
        "risposta_2": "che è opportuno transitare sempre in prossimità della poppa delle unità all'ancora.",
        "id": 587,
        "immagine": "",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_1": "V",
        "risposta_1": "che l'onda formata dalla propria imbarcazione può creare una situazione di disturbo o pericolo per le altre unità all'ancora."
    },
    {
        "ris_2": "F",
        "progressivo": "1.4.4-38",
        "argomento": "Ormeggio e disormeggio",
        "risposta_3": "regolando con il \"TRIM\" il piede verso l’alto si determina un abbassamento della prora rispetto alla superficie del mare.",
        "id": 588,
        "ris_1": "V",
        "domanda": "Relativamente ad un motore fuoribordo, bisogna sapere che:",
        "risposta_2": "i cilindri sono raffreddati ad aria.",
        "ris_3": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "immagine": "",
        "risposta_1": "regolando con il \"TRIM\" il piede verso l’alto si determina un'elevazione della prora rispetto alla superficie del mare."
    },
    {
        "ris_2": "V",
        "ris_1": "F",
        "domanda": "Quando il TRIM è tutto basso si dice che l'assetto è:",
        "risposta_3": "neutrale.",
        "id": 589,
        "immagine": "",
        "ris_3": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "argomento": "Ormeggio e disormeggio",
        "progressivo": "1.4.4-39",
        "risposta_1": "tutto in positivo e serve  ad alzare la prua con mare formato in poppa.",
        "risposta_2": "tutto in negativo e serve per dare maggiore spinta iniziale per raggiungere l'assetto di planata."
    },
    {
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_3": "F",
        "domanda": "Le frecce in figura indicano:",
        "immagine": 8,
        "ris_2": "V",
        "ris_1": "F",
        "risposta_1": "il mascone di sinistra.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "risposta_2": "la murata sinistra.",
        "progressivo": "1.1.1-59",
        "risposta_3": "il giardinetto di sinistra.",
        "id": 59
    },
    {
        "ris_2": "F",
        "domanda": "Ci troviamo in una condizione di rotta di collisione; di norma, l'unità con diritto di precedenza deve:",
        "risposta_1": "accelerare con la propria unità in modo da scapolare quanto prima possibile l'altra unità che ha dato la precedenza.",
        "id": 590,
        "immagine": "",
        "ris_3": "V",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_3": "mantenere rotta e velocità costante, accertandosi che l'altra unità dia la precedenza.",
        "argomento": "Ormeggio e disormeggio",
        "ris_1": "F",
        "progressivo": "1.4.4-40",
        "risposta_2": "ridurre la velocità."
    },
    {
        "ris_2": "F",
        "progressivo": "1.4.4-41",
        "risposta_1": "sopravvento al gavitello.",
        "immagine": "",
        "ris_3": "V",
        "argomento": "Ormeggio e disormeggio",
        "capitolo": "MANOVRA E CONDOTTA",
        "domanda": "Come bisogna presentarsi nella manovra per la presa di gavitello?",
        "ris_1": "F",
        "id": 591,
        "risposta_3": "sottovento al gavitello.",
        "risposta_2": "con vento al traverso e gavitello di prora."
    },
    {
        "risposta_2": "non serve utilizzarla in caso di cattivo tempo.",
        "risposta_3": "serve a recuperare a bordo l'ancora incattivita.",
        "id": 592,
        "progressivo": "1.4.4-42",
        "ris_3": "F",
        "risposta_1": "serve a limitare l'intraversamento dell'unità.",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_2": "F",
        "immagine": "",
        "argomento": "Ormeggio e disormeggio",
        "ris_1": "V",
        "domanda": "L’ancora galleggiante:"
    },
    {
        "progressivo": "1.4.4-43",
        "id": 593,
        "risposta_2": "è adatta all'utilizzo in prossimità della costa sottovento.",
        "ris_1": "F",
        "domanda": "L’ancora galleggiante:",
        "ris_2": "V",
        "capitolo": "MANOVRA E CONDOTTA",
        "immagine": "",
        "risposta_3": "non è adatta con profondità del mare troppo elevate.",
        "risposta_1": "è utilizzata in assenza di deriva e scarroccio.",
        "ris_3": "F",
        "argomento": "Ormeggio e disormeggio"
    },
    {
        "ris_3": "F",
        "risposta_3": "profondità troppo elevate e in presenza di deriva.",
        "ris_2": "V",
        "domanda": "L’utilizzo dell’ancora galleggiante è vantaggioso in caso di:",
        "progressivo": "1.4.4-44",
        "risposta_2": "profondità troppo elevate e in presenza di scarroccio.",
        "argomento": "Ormeggio e disormeggio",
        "risposta_1": "profondità troppo elevate e in assenza di deriva e scarroccio.",
        "id": 594,
        "immagine": "",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_1": "F"
    },
    {
        "ris_3": "V",
        "ris_2": "F",
        "immagine": "",
        "id": 595,
        "risposta_3": "orizzontale dell'unità stessa.",
        "domanda": "Generalmente, la funzione del \"TRIM\" in un motore fuoribordo determina l'innalzamento della prua dell'unità per un angolo compreso tra la superficie del mare e il piano:",
        "progressivo": "1.4.4-45",
        "ris_1": "F",
        "risposta_2": "trasversale dell'unita stessa.",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_1": "laterale dell'unità stessa.",
        "argomento": "Ormeggio e disormeggio"
    },
    {
        "ris_2": "F",
        "immagine": "",
        "ris_3": "F",
        "argomento": "Ormeggio e disormeggio",
        "risposta_2": "misura il solco lasciato dalla carena della nave nel suo moto in avanti.",
        "domanda": "In generale il solcometro (comunemente detto anche log):",
        "capitolo": "MANOVRA E CONDOTTA",
        "risposta_1": "fornisce il dato di velocità e di cammino percorso in un dato tempo.",
        "id": 596,
        "progressivo": "1.4.4-46",
        "ris_1": "V",
        "risposta_3": "è un contachilometri percorsi che li trasforma in miglia."
    },
    {
        "progressivo": "1.4.4-47",
        "risposta_3": "elettro-scandaglio.",
        "domanda": "Lo scandaglio elettronico è denominato:",
        "argomento": "Ormeggio e disormeggio",
        "risposta_1": "solcometro.",
        "immagine": "",
        "ris_1": "F",
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_3": "F",
        "risposta_2": "ecoscandaglio.",
        "ris_2": "V",
        "id": 597
    },
    {
        "argomento": "Ormeggio e disormeggio",
        "risposta_1": "in caso di presenza di vento.",
        "progressivo": "1.4.4-48",
        "capitolo": "MANOVRA E CONDOTTA",
        "immagine": "",
        "risposta_3": "non è vero, è sempre attendibile perché tiene conto di deriva e scarroccio.",
        "ris_1": "F",
        "domanda": "Quando il dato della velocità fornito dal solcometro (comunemente detto anche log) non è attendibile:",
        "ris_3": "F",
        "id": 598,
        "risposta_2": "in caso di presenza di corrente.",
        "ris_2": "V"
    },
    {
        "risposta_3": "effettiva, cioè quella rispetto al fondo del mare.",
        "risposta_1": "propria o propulsiva, cioè quella prodotta dalle eliche.",
        "ris_2": "F",
        "id": 599,
        "capitolo": "MANOVRA E CONDOTTA",
        "ris_3": "F",
        "immagine": "",
        "risposta_2": "rispetto alla superficie dell'acqua.",
        "progressivo": "1.4.4-49",
        "ris_1": "V",
        "argomento": "Ormeggio e disormeggio",
        "domanda": "Il solcometro (comunemente detto anche log) misura la velocità:"
    },
    {
        "risposta_3": "la porzione anteriore posta all'estremità dell'unità.",
        "risposta_2": "la parte estrema posteriore dello scafo.",
        "progressivo": "1.1.1-6",
        "ris_2": "F",
        "ris_3": "V",
        "domanda": "Cosa si intende per prua dell'unità?",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_1": "F",
        "id": 6,
        "immagine": "",
        "risposta_1": "la parte priva di  spigoli  dello scafo.",
        "argomento": "Nomenclatura delle parti principali dello scafo"
    },
    {
        "immagine": 9,
        "capitolo": "TEORIA DELLO SCAFO",
        "id": 60,
        "domanda": "Le frecce in figura indicano:",
        "risposta_2": "il mascone di dritta.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "risposta_1": "il baglio massimo.",
        "risposta_3": "la murata di dritta.",
        "ris_3": "V",
        "ris_2": "F",
        "progressivo": "1.1.1-60",
        "ris_1": "F"
    },
    {
        "ris_1": "F",
        "risposta_3": "testa d'albero Rosso, a dritta Bianco, a sinistra Verde, coronamento Rosso.",
        "argomento": "Fanali e segnali diurni",
        "immagine": "",
        "id": 600,
        "risposta_1": "testa d'albero Bianco, a dritta Verde, a sinistra Rosso, coronamento Giallo.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "progressivo": "1.5.1-1",
        "ris_2": "V",
        "domanda": "Un'unità a motore, di lunghezza inferiore a 50 metri, che stia effettuando una normale ordinaria navigazione notturna, deve mostrare (individuare la\ncombinazione corretta):",
        "ris_3": "F",
        "risposta_2": "testa d'albero Bianco, a dritta Verde, a sinistra Rosso, coronamento Bianco."
    },
    {
        "risposta_2": "un cono.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Un peschereccio intento alla pesca a strascico, di giorno mostra:",
        "progressivo": "1.5.1-2",
        "argomento": "Fanali e segnali diurni",
        "ris_2": "F",
        "immagine": "",
        "ris_1": "F",
        "risposta_1": "un bicono con le basi unite.",
        "risposta_3": "un bicono con i vertici uniti.",
        "id": 601,
        "ris_3": "V"
    },
    {
        "ris_1": "V",
        "risposta_1": "i fanali prescritti per la nave a propulsione meccanica.",
        "progressivo": "1.5.1-3",
        "argomento": "Fanali e segnali diurni",
        "domanda": "Una nave a cuscino d'aria in navigazione dislocante; di notte mostra:",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "un fanale giallo lampeggiante visibile a 360 gradi.",
        "ris_2": "F",
        "ris_3": "F",
        "risposta_2": "un fanale giallo a luce fissa visibile a 360 gradi.",
        "id": 602,
        "immagine": ""
    },
    {
        "progressivo": "1.5.1-4",
        "risposta_3": "225 gradi.",
        "risposta_2": "135 gradi.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_3": "F",
        "risposta_1": "112.5 gradi.",
        "argomento": "Fanali e segnali diurni",
        "ris_1": "F",
        "domanda": "Il settore visibile del fanale di coronamento è ampio:",
        "ris_2": "V",
        "immagine": "",
        "id": 603
    },
    {
        "ris_1": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "id": 604,
        "progressivo": "1.5.1-5",
        "domanda": "Qual è l'ampiezza dell'arco di orizzonte in cui è visibile la luce ininterrotta di un fanale laterale di un'unità navale in navigazione?",
        "ris_3": "F",
        "argomento": "Fanali e segnali diurni",
        "risposta_3": "135°.",
        "risposta_2": "112°,5.",
        "ris_2": "V",
        "immagine": "",
        "risposta_1": "22°,5."
    },
    {
        "ris_1": "V",
        "risposta_2": "fanale in testa d'albero con luce di colore rosso.",
        "domanda": "Un'imbarcazione da diporto, in navigazione notturna entro 3 miglia dalla costa, cosa può utilizzare per segnalare la sua posizione in sostituzione dei fanali regolamentari?",
        "immagine": "",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "progressivo": "1.5.1-6",
        "ris_3": "F",
        "risposta_1": "una torcia di sicurezza a luce bianca.",
        "argomento": "Fanali e segnali diurni",
        "id": 605,
        "risposta_3": "fuochi a mano a luce rossa, da usare all'occorrenza.",
        "ris_2": "F"
    },
    {
        "risposta_3": "ampio 225 gradi verso poppa, centrato sull'asse longitudinale.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": "",
        "domanda": "Il fascio luminoso del fanale di coronamento in navigazione notturna è:",
        "progressivo": "1.5.1-7",
        "id": 606,
        "ris_2": "V",
        "risposta_1": "ampio 135 gradi verso prora, centrato sull'asse longitudinale.",
        "risposta_2": "ampio 135 gradi verso poppa, centrato sull'asse longitudinale.",
        "ris_3": "F",
        "argomento": "Fanali e segnali diurni",
        "ris_1": "F"
    },
    {
        "immagine": "",
        "progressivo": "1.5.1-8",
        "risposta_2": "112,5 gradi misurati a partire dall'asse longitudinale dell'unità verso poppavia a dritta e a sinistra.",
        "risposta_1": "112,5 gradi misurati a partire dall'asse longitudinale dell'unità verso proravia a dritta e a sinistra.",
        "ris_1": "F",
        "id": 607,
        "ris_3": "F",
        "ris_2": "V",
        "risposta_3": "122,5 gradi misurati a partire dall'asse longitudinale dell'unità verso poppavia a dritta e a sinistra.",
        "argomento": "Fanali e segnali diurni",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "I fanali laterali hanno un settore di visibilità ampio:"
    },
    {
        "immagine": 34,
        "argomento": "Fanali e segnali diurni",
        "risposta_1": "un'unità che procede contemporaneamente a vela e a motore.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_2": "un'unità da diporto intenta alla pesca sportiva.",
        "progressivo": "1.5.1-9",
        "ris_1": "V",
        "ris_3": "F",
        "domanda": "Siamo in navigazione diurna a bordo della nostra imbarcazione quando rileviamo un'unità che presenta un cono disposto come in figura a fianco: cosa indica questo segnale?",
        "ris_2": "F",
        "risposta_3": "un'unità che non governa, con abbrivio.",
        "id": 608
    },
    {
        "risposta_1": "un pallone nero.",
        "ris_2": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": "",
        "ris_1": "V",
        "id": 609,
        "progressivo": "1.5.1-10",
        "argomento": "Fanali e segnali diurni",
        "risposta_2": "un cilindro a prora.",
        "ris_3": "F",
        "domanda": "Una nave all'ancora di giorno deve mostrare:",
        "risposta_3": "un cono a prora con il vertice in alto."
    },
    {
        "risposta_3": "l'insieme degli elementi a protezione del camminamento per il passaggio tra poppa e prora.",
        "id": 61,
        "immagine": "",
        "progressivo": "1.1.1-61",
        "ris_1": "F",
        "ris_2": "V",
        "domanda": "I tubolari sono:",
        "risposta_1": "i tubi di scarico del pozzetto, collegati agli ombrinali.",
        "risposta_2": "le parti esterne di un battello pneumatico, che ne garantiscono una parte della riserva di galleggiamento.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_3": "F"
    },
    {
        "risposta_1": "verde.",
        "argomento": "Fanali e segnali diurni",
        "progressivo": "1.5.1-11",
        "ris_3": "V",
        "id": 610,
        "domanda": "La luce di un fanale di testa d'albero è di colore:",
        "ris_2": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "bianco.",
        "immagine": "",
        "ris_1": "F",
        "risposta_2": "rosso."
    },
    {
        "risposta_2": "225 gradi orientato verso prora, centrato sull'asse longitudinale.",
        "argomento": "Fanali e segnali diurni",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Il secondo fanale in testa d'albero ha un settore di visibilità di:",
        "immagine": "",
        "progressivo": "1.5.1-12",
        "ris_3": "F",
        "risposta_1": "225 gradi orientato verso poppa, centrato sull'asse longitudinale.",
        "risposta_3": "135 gradi orientato verso prora, centrato sull'asse longitudinale.",
        "ris_2": "V",
        "id": 611,
        "ris_1": "F"
    },
    {
        "id": 612,
        "domanda": "L'unità intenta alla pesca, quando è in navigazione, deve lasciar libera la rotta:",
        "argomento": "Fanali e segnali diurni",
        "risposta_1": "all'imbarcazione da diporto.",
        "ris_1": "F",
        "ris_2": "F",
        "ris_3": "V",
        "risposta_2": "all'unità propulsa a vela.",
        "risposta_3": "a una nave che non governa.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": "",
        "progressivo": "1.5.1-13"
    },
    {
        "risposta_2": "quando a bordo c'è necessità di rientrare in porto.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "id": 613,
        "risposta_1": "in alcuni casi.",
        "ris_1": "F",
        "ris_2": "F",
        "argomento": "Fanali e segnali diurni",
        "ris_3": "V",
        "immagine": "",
        "progressivo": "1.5.1-14",
        "risposta_3": "mai.",
        "domanda": "In navigazione notturna, le unità da diporto hanno precedenza rispetto a navi che mostrano luci speciali previste dal Regolamento per prevenire gli abbordi in Mare - Colreg?"
    },
    {
        "risposta_1": "le unità da diporto che navigano con velocità inferiore a 10 nodi.",
        "domanda": "Quali unità da diporto al posto dei fanali regolamentari di navigazione possono utilizzare di notte una torcia bianca?",
        "progressivo": "1.5.1-15",
        "id": 614,
        "risposta_3": "i natanti da diporto a vela di lunghezza inferiore a 7 metri.",
        "ris_3": "V",
        "risposta_2": "i natanti da diporto a motore di lunghezza fuori tutto inferiore a 7.5 metri.",
        "ris_1": "F",
        "immagine": "",
        "ris_2": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "argomento": "Fanali e segnali diurni"
    },
    {
        "ris_2": "F",
        "id": 615,
        "ris_1": "V",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Un'unità a propulsione meccanica in navigazione deve lasciare libera la rotta ad una unità che non governa?",
        "risposta_1": "sì, sempre.",
        "risposta_2": "solo se l'unità che non governa si trova a dritta.",
        "risposta_3": "no, in nessun caso.",
        "ris_3": "F",
        "argomento": "Fanali e segnali diurni",
        "progressivo": "1.5.1-16",
        "immagine": ""
    },
    {
        "ris_3": "F",
        "risposta_2": "bianco.",
        "argomento": "Fanali e segnali diurni",
        "ris_2": "V",
        "risposta_3": "rosso.",
        "id": 616,
        "risposta_1": "giallo.",
        "immagine": "",
        "ris_1": "F",
        "domanda": "La luce del fanale di coronamento di un'unità a rimorchio è di colore:",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "progressivo": "1.5.1-17"
    },
    {
        "ris_2": "F",
        "argomento": "Fanali e segnali diurni",
        "domanda": "I fanali mostrati da una nave a motore di lunghezza uguale o superiore a 50 metri con rimorchio di lunghezza sino a 200 metri:",
        "progressivo": "1.5.1-18",
        "immagine": "",
        "risposta_1": "sono riportati nel Colreg.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "prevedono una luce lampeggiante gialla.",
        "id": 617,
        "risposta_2": "sono raddoppiati rispetto a quelli ordinari.",
        "ris_1": "V",
        "ris_3": "F"
    },
    {
        "domanda": "Quali fanali mostra una nave di lunghezza uguale o superiore ai 50 m. che sia incagliata?",
        "ris_1": "V",
        "immagine": "",
        "argomento": "Fanali e segnali diurni",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "id": 618,
        "risposta_1": "i fanali aggiuntivi stabilitI dal Regolamento per Prevenire gli Abbordi in Mare - Colreg.",
        "risposta_2": "una palla nera.",
        "risposta_3": "una luce lampeggiante gialla.",
        "progressivo": "1.5.1-19",
        "ris_2": "F",
        "ris_3": "F"
    },
    {
        "ris_3": "V",
        "immagine": "",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "progressivo": "1.5.1-20",
        "risposta_3": "i fanali aggiuntivi sulla nave che rimorchia, come stabilito dal Regolamento per Prevenire gli Abbordi in Mare - Colreg.",
        "risposta_1": "una luce lampeggiante gialla",
        "ris_1": "F",
        "ris_2": "F",
        "domanda": "Quali fanali mostra la nave a motore di lunghezza uguale o superiore a 50 metri con rimorchio di lunghezza superiore a 200 metri?",
        "argomento": "Fanali e segnali diurni",
        "id": 619,
        "risposta_2": "i soli fanali di entrambi le unità."
    },
    {
        "risposta_2": "il musone di prua dove è alloggiata l'ancora.",
        "ris_3": "F",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "risposta_3": "è un nome di fantasia.",
        "progressivo": "1.1.1-62",
        "immagine": "",
        "ris_1": "V",
        "id": 62,
        "domanda": "Per RIB (o RHIB) si intende:",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_1": "i battelli pneumatici con chiglia rigida.",
        "ris_2": "F"
    },
    {
        "argomento": "Fanali e segnali diurni",
        "ris_1": "V",
        "risposta_2": "suoni emessi a distanza regolare.",
        "ris_3": "F",
        "domanda": "I segnali diurni della nave con manovrabilità limitata, intenta a dragare o in operazioni subacquee sono:",
        "id": 620,
        "ris_2": "F",
        "progressivo": "1.5.1-21",
        "risposta_3": "gli stessi della nave da pesca a strascico.",
        "risposta_1": "definiti dal Regolamento per Prevenire gli Abbordi in Mare - Colreg.",
        "immagine": "",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO"
    },
    {
        "progressivo": "1.5.1-22",
        "risposta_1": "non sussiste alcun pericolo.",
        "ris_1": "F",
        "ris_3": "F",
        "immagine": "",
        "domanda": "Il rilevamento non cambia e la distanza diminuisce; significa che:",
        "risposta_3": "siamo in rotta raggiungente.",
        "argomento": "Fanali e segnali diurni",
        "id": 621,
        "risposta_2": "c'è rischio di collisione con l'altra unità.",
        "ris_2": "V",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO"
    },
    {
        "id": 622,
        "progressivo": "1.5.1-23",
        "domanda": "I fanali di navigazione devono essere accesi:",
        "risposta_1": "al tramonto ed in condizioni di scarsa visibilità.",
        "ris_3": "F",
        "risposta_2": "solo di notte.",
        "ris_1": "V",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_2": "F",
        "risposta_3": "sempre.",
        "argomento": "Fanali e segnali diurni",
        "immagine": ""
    },
    {
        "risposta_2": "accesi, i fanali di via e di coronamento.",
        "immagine": "",
        "ris_3": "F",
        "id": 623,
        "progressivo": "1.5.1-24",
        "domanda": "Una nave rimorchiata, quando in navigazione notturna, mostra:",
        "argomento": "Fanali e segnali diurni",
        "risposta_1": "acceso, solo il fanale di coronamento.",
        "ris_2": "V",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_1": "F",
        "risposta_3": "accesi, i 2 fanali rossi."
    },
    {
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_1": "un cono nero con la punta rivolta verso il basso.",
        "immagine": "",
        "ris_2": "F",
        "risposta_3": "i fanali e i segnali stabiliti dal Regolamento per Prevenire gli Abbordi in Mare - Colreg.",
        "argomento": "Fanali e segnali diurni",
        "risposta_2": "un fanale lampeggiante rosso.",
        "id": 624,
        "ris_1": "F",
        "ris_3": "V",
        "progressivo": "1.5.1-25",
        "domanda": "Quali segnali mostra una nave di lunghezza uguale o superiore a 50 metri, condizionata dalla propria immersione?"
    },
    {
        "progressivo": "1.5.1-26",
        "id": 625,
        "ris_3": "F",
        "immagine": "",
        "risposta_3": "per l'unità di lunghezza uguale o superiore a 12 metri, qualunque sia l'abilitazione alla navigazione.",
        "argomento": "Fanali e segnali diurni",
        "ris_1": "F",
        "risposta_1": "solo a navi e imbarcazioni da diporto, sono esclusi i natanti da diporto.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_2": "V",
        "domanda": "I fanali regolamentari di navigazione sono prescritti:",
        "risposta_2": "in ogni caso, se l'unità viene impiegata in navigazione in ore notturne, conformemente al Regolamento per\nPrevenire gli abbordi in Mare - Colreg."
    },
    {
        "ris_1": "F",
        "domanda": "I fanali di navigazione sono prescritti per le unità da diporto?",
        "ris_3": "V",
        "risposta_3": "si, per le unità da diporto in navigazione oltre 1 miglio dalla costa.",
        "risposta_2": "sì, per tutte le unità da diporto in navigazione entro 300 metri dalla costa",
        "argomento": "Fanali e segnali diurni",
        "risposta_1": "sì, per le unità da diporto in navigazione esclusivamente in caso di nebbia.",
        "ris_2": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "progressivo": "1.5.1-27",
        "id": 626,
        "immagine": ""
    },
    {
        "risposta_1": "2 miglia.",
        "risposta_3": "2,5 miglia.",
        "ris_1": "V",
        "id": 627,
        "ris_2": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": "",
        "argomento": "Fanali e segnali diurni",
        "domanda": "La portata dei fanali laterali di un’unità di lunghezza uguale o superiore a 12 metri ma inferiore a 50 metri è di:",
        "progressivo": "1.5.1-28",
        "ris_3": "F",
        "risposta_2": "1,5 miglia."
    },
    {
        "ris_3": "F",
        "id": 628,
        "domanda": "Una nave a propulsione meccanica lunga 280 metri, quanti fanali di testa d'albero espone?",
        "ris_2": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "argomento": "Fanali e segnali diurni",
        "risposta_3": "3, uno ogni 100 metri di lunghezza ovvero frazione di essa superiore a 50 metri nell'ultimo tratto di  lunghezza se minore di 100 metri.",
        "ris_1": "V",
        "immagine": "",
        "progressivo": "1.5.1-29",
        "risposta_1": 2,
        "risposta_2": 1
    },
    {
        "id": 629,
        "ris_2": "V",
        "progressivo": "1.5.1-30",
        "domanda": "L'ampiezza angolare complessiva data dalla somma dei settori di visibilità dei \"fanali laterali\" è di:",
        "risposta_2": "225 gradi verso prora, centrata sull'asse longitudinale.",
        "risposta_3": "135 gradi verso poppa, centrata sull'asse longitudinale.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_1": "225 gradi verso poppa, centrata sull'asse longitudinale.",
        "ris_1": "F",
        "immagine": "",
        "ris_3": "F",
        "argomento": "Fanali e segnali diurni"
    },
    {
        "risposta_3": "la ferramenta che si trova a prua estrema, generalmente composta da un unico blocco, che comprende il passacatena dell´ancora.",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_2": "l'attacco del timone sullo specchio di poppa.",
        "immagine": "",
        "domanda": "Si chiama musone:",
        "ris_3": "V",
        "risposta_1": "è un nome di fantasia.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "ris_2": "F",
        "progressivo": "1.1.1-63",
        "id": 63,
        "ris_1": "F"
    },
    {
        "ris_2": "F",
        "argomento": "Fanali e segnali diurni",
        "risposta_1": "si trovi all'ancora.",
        "immagine": "",
        "id": 630,
        "domanda": "La nave può utilizzare i fanali di servizio per illuminare i ponti quando:",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_3": "F",
        "ris_1": "V",
        "risposta_2": "si trovi in navigazione in bassi fondali e vincolata dal proprio pescaggio.",
        "risposta_3": "si trovi in navigazione in acque ristrette.",
        "progressivo": "1.5.1-31"
    },
    {
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": "",
        "ris_3": "F",
        "argomento": "Fanali e segnali diurni",
        "progressivo": "1.5.1-32",
        "risposta_1": "50 metri.",
        "risposta_2": "150 metri.",
        "ris_2": "V",
        "domanda": "Un cono aggiuntivo con il vertice verso l'alto è mostrato nella direzione dell'attrezzo dal peschereccio non a strascico che è in attività con un attrezzo esterno che si estenda più di:",
        "risposta_3": "100 metri.",
        "ris_1": "F",
        "id": 631
    },
    {
        "risposta_2": "da pesca, di lunghezza inferiore a 50 metri, che è intenta alla pesca non a strascico che dirige a dritta dell'osservatore.",
        "risposta_1": "da pesca di lunghezza uguale o superiore a 50 metri, che è intenta alla pesca a strascico, che dirige a sinistra dell'osservatore.",
        "domanda": "I fanali rappresentati in figura a fianco indicano una nave:",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_2": "F",
        "ris_1": "V",
        "id": 632,
        "progressivo": "1.5.1-33",
        "ris_3": "F",
        "risposta_3": "a motore che sta mostrando il fianco di dritta e che sta dirigendo a sinistra dell'osservatore.",
        "immagine": 35,
        "argomento": "Fanali e segnali diurni"
    },
    {
        "ris_2": "F",
        "domanda": "In aggiunta ai fanali prescritti per la nave a propulsione meccanica in navigazione, una nave a cuscino d'aria deve mostrare:",
        "progressivo": "1.5.1-34",
        "ris_3": "F",
        "argomento": "Fanali e segnali diurni",
        "risposta_1": "1 fanale giallo, lampeggiante, visibile per tutto l'orizzonte, se l'unità opera in assetto non dislocante.",
        "risposta_2": "1 fanale giallo, lampeggiante, visibile per tutto l'orizzonte, se l'unità opera in assetto dislocante.",
        "risposta_3": "indifferentemente dall'assetto, 1 fanale giallo, lampeggiante, visibile esattamente come il fanale di testa d'albero.",
        "ris_1": "V",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "id": 633,
        "immagine": ""
    },
    {
        "risposta_1": "i fari che illuminano il ponte.",
        "domanda": "In navigazione notturna si accendono a bordo:",
        "risposta_2": "le mede regolamentari.",
        "argomento": "Fanali e segnali diurni",
        "ris_3": "V",
        "ris_2": "F",
        "risposta_3": "i fanali regolamentari.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "progressivo": "1.5.1-35",
        "id": 634,
        "ris_1": "F",
        "immagine": ""
    },
    {
        "ris_3": "F",
        "risposta_1": "verde a dritta e rosso a sinistra.",
        "argomento": "Fanali e segnali diurni",
        "progressivo": "1.5.1-36",
        "id": 635,
        "risposta_2": "due fanali di colore bianco.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "due fanali di colore rosso.",
        "ris_2": "F",
        "domanda": "Sulle fiancate di un’unità in navigazione sono accesi i seguenti fanali:",
        "ris_1": "V",
        "immagine": ""
    },
    {
        "risposta_2": "accendere solo una luce bianca visibile per tutto l'orizzonte.",
        "domanda": "In navigazione notturna, un’unità da diporto a motore di lunghezza fuori tutto di 45 metri deve obbligatoriamente mostrare:",
        "progressivo": "1.5.1-37",
        "risposta_1": "sia il fanale bianco di testa d’albero, sia i fanali di via laterali e sia il fanale di coronamento.",
        "ris_1": "V",
        "ris_2": "F",
        "ris_3": "F",
        "id": 636,
        "argomento": "Fanali e segnali diurni",
        "risposta_3": "accendere solo il fanale di testa d’albero ed i fanali di via laterali.",
        "immagine": "",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO"
    },
    {
        "immagine": "",
        "progressivo": "1.5.1-38",
        "ris_2": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "id": 637,
        "risposta_2": "un fanale rosso più alto rispetto a quello di testa d’albero e a poppavia del primo, visibile per 360 gradi.",
        "risposta_3": "un fanale bianco più alto rispetto a quello di testa d’albero e a poppavia, visibile per 225 gradi.",
        "domanda": "Un’unità a motore di lunghezza uguale o superiore a 50 metri, cosa deve accendere in più rispetto ad una di lunghezza inferiore a 50 metri?",
        "ris_3": "V",
        "ris_1": "F",
        "argomento": "Fanali e segnali diurni",
        "risposta_1": "un fanale di coronamento giallo."
    },
    {
        "ris_1": "V",
        "ris_3": "F",
        "progressivo": "1.5.1-39",
        "immagine": "",
        "risposta_1": "fanali di via laterali e fanale di coronamento.",
        "risposta_2": "fanali di via laterali, 2 fanali ripetitori verdi e fanale di coronamento.",
        "risposta_3": "fanali di via laterale, fanale di testa d’albero, fanali ripetitori e fanale di coronamento.",
        "id": 638,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_2": "F",
        "domanda": "Un’unità in navigazione notturna a vela ha l’obbligo di accendere:",
        "argomento": "Fanali e segnali diurni"
    },
    {
        "id": 639,
        "ris_1": "F",
        "ris_2": "V",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Il settore di visibilità dei fanali ripetitori (o facoltativi) rosso e verde che mostrano sull'albero alcune unità a vela è di:",
        "progressivo": "1.5.1-40",
        "argomento": "Fanali e segnali diurni",
        "risposta_3": "è pari a 225 gradi.",
        "risposta_2": "è pari a 360 gradi.",
        "ris_3": "F",
        "immagine": "",
        "risposta_1": "è pari a 112,5 gradi."
    },
    {
        "progressivo": "1.1.1-64",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "risposta_1": "la parte filettata che attraversa lo spessore della carena e si connette alla presa a mare nelle tubazioni che trasportano l'acqua alle o dalle varie utenze.",
        "risposta_3": "il foro attraverso cui passa la catena dell'ancora quando viene dato fondo.",
        "immagine": "",
        "capitolo": "TEORIA DELLO SCAFO",
        "id": 64,
        "domanda": "Il passascafo è:",
        "ris_3": "F",
        "risposta_2": "il foro da cui defluisce l'acqua dal pozzetto.",
        "ris_2": "F",
        "ris_1": "V"
    },
    {
        "ris_3": "V",
        "risposta_2": "intenta alla pesca a strascico, i cui attrezzi si estendono fuoribordo per più di 150 metri, che dirige verso l'osservatore.",
        "domanda": "Il fanale rappresentato in figura a fianco indica un'unità:",
        "ris_2": "F",
        "argomento": "Fanali e segnali diurni",
        "risposta_1": "intenta alla pesca non a strascico, i cui attrezzi si estendono fuoribordo per meno di 150 metri, che dirige verso l'osservatore.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_1": "F",
        "risposta_3": "intenta alla pesca non a strascico, i cui attrezzi si estendono fuoribordo per più di 150 metri, che dirige verso l'osservatore.",
        "id": 640,
        "immagine": 36,
        "progressivo": "1.5.1-41"
    },
    {
        "id": 641,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": 37,
        "risposta_2": "di lunghezza inferiore a 50 metri, intenta alla pesca non a strascico, che dirige verso l'osservatore.",
        "argomento": "Fanali e segnali diurni",
        "ris_2": "F",
        "domanda": "Il fanale rappresentato in figura a fianco indica un'unità:",
        "ris_3": "F",
        "risposta_1": "di lunghezza inferiore a 50 metri, intenta alla pesca a strascico, che dirige verso l'osservatore.",
        "risposta_3": "a vela con i fanali facoltativi d'albero che dirige verso l'osservatore.",
        "ris_1": "V",
        "progressivo": "1.5.1-42"
    },
    {
        "immagine": 38,
        "ris_3": "F",
        "ris_2": "V",
        "domanda": "I fanali rappresentati in figura a fianco indicano un'unità:",
        "argomento": "Fanali e segnali diurni",
        "risposta_2": "a motore di lunghezza inferiore a 50 metri che mostra la dritta.",
        "risposta_1": "a motore di lunghezza uguale o superiore a 50 metri che mostra la dritta.",
        "progressivo": "1.5.1-43",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_1": "F",
        "id": 642,
        "risposta_3": "a vela che mostra la dritta."
    },
    {
        "ris_1": "F",
        "id": 643,
        "progressivo": "1.5.1-44",
        "ris_2": "F",
        "domanda": "I fanali rappresentati in figura a fianco indicano un'unità:",
        "risposta_2": "a vela, che mostra la prora.",
        "risposta_1": "a motore, di lunghezza inferiore a 50 metri, che mostra la prora.",
        "ris_3": "V",
        "argomento": "Fanali e segnali diurni",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": 39,
        "risposta_3": "a motore, di lunghezza inferiore a 20 metri, che mostra la prora."
    },
    {
        "risposta_1": "a propulsione meccanica, con fanali facoltativi.",
        "ris_3": "V",
        "immagine": 40,
        "argomento": "Fanali e segnali diurni",
        "risposta_2": "condizionata dalla propria immersione.",
        "risposta_3": "a vela con fanali facoltativi, che mostra la poppa.",
        "ris_2": "F",
        "ris_1": "F",
        "id": 644,
        "progressivo": "1.5.1-45",
        "domanda": "I fanali rappresentati in figura a fianco indicano una imbarcazione:",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO"
    },
    {
        "ris_3": "V",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_1": "una palla nera.",
        "risposta_2": "un fanale lampeggiante rosso.",
        "immagine": "",
        "domanda": "Una nave pilota mostra:",
        "risposta_3": "i fanali e i segnali stabiliti dal Regolamento per Prevenire gli Abbordi in Mare - Colreg.",
        "id": 645,
        "progressivo": "1.5.1-46",
        "ris_2": "F",
        "argomento": "Fanali e segnali diurni",
        "ris_1": "F"
    },
    {
        "ris_1": "F",
        "ris_3": "V",
        "ris_2": "F",
        "domanda": "Il fanale rappresentato in figura a fianco indica una:",
        "immagine": 41,
        "risposta_2": "nave pilota, senza abbrivio.",
        "risposta_1": "nave da pesca non a strascico, senza abbrivio in attesa sul punto.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "nave a vela che mostra la sinistra.",
        "argomento": "Fanali e segnali diurni",
        "id": 646,
        "progressivo": "1.5.1-47"
    },
    {
        "risposta_2": "nave da pesca non a strascico, senza abbrivio.",
        "id": 647,
        "progressivo": "1.5.1-48",
        "ris_3": "F",
        "domanda": "I fanali rappresentati in figura a fianco indicano una:",
        "ris_2": "V",
        "argomento": "Fanali e segnali diurni",
        "risposta_1": "nave da pesca a strascico, senza abbrivio.",
        "ris_1": "F",
        "immagine": 42,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "nave pilota, senza abbrivio."
    },
    {
        "ris_1": "V",
        "immagine": 43,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_2": "F",
        "domanda": "I fanali rappresentati in figura a fianco indicano una:",
        "argomento": "Fanali e segnali diurni",
        "ris_3": "F",
        "risposta_3": "nave da pesca non a strascico, con abbrivio, avente un attrezzo esterno che si estende orizzontalmente fuoribordo per una distanza superiore a 150 metri.",
        "risposta_1": "nave da pesca non a strascico, con abbrivio, vista sul suo lato dritto.",
        "id": 648,
        "progressivo": "1.5.1-49",
        "risposta_2": "unità navale pilota in navigazione che mostra il suo lato dritto."
    },
    {
        "ris_3": "V",
        "ris_2": "F",
        "ris_1": "F",
        "argomento": "Fanali e segnali diurni",
        "risposta_3": "un peschereccio, intento alla pesca non a strascico, con le reti fuoribordo per più di 150 metri e che dirige a dritta dell'osservatore.",
        "risposta_1": "un peschereccio, intento alla pesca a strascico, con le reti fuoribordo per meno di 150 metri e che dirige a dritta dell'osservatore.",
        "id": 649,
        "immagine": 44,
        "domanda": "I fanali rappresentati in figura a fianco indicano:",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "progressivo": "1.5.1-50",
        "risposta_2": "un peschereccio, intento alla pesca non a strascico, con le reti fuoribordo per meno di 150 metri e che dirige a dritta dell'osservatore."
    },
    {
        "risposta_2": "il punto di raccolta di eventuali perdite di fluidi del motore.",
        "risposta_3": "l'apertura per far defluire l'acqua presente in coperta.",
        "ris_2": "F",
        "immagine": "",
        "progressivo": "1.1.1-65",
        "risposta_1": "la parte esterna di un'imbarcazione dove solitamente sono posizionate le manovre e il timone, destinata ad accogliere gli ospiti in sicurezza.",
        "ris_3": "F",
        "domanda": "Il pozzetto è:",
        "ris_1": "V",
        "capitolo": "TEORIA DELLO SCAFO",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "id": 65
    },
    {
        "ris_3": "V",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": 45,
        "ris_2": "F",
        "risposta_2": "un'unità da pesca a strascico senza abbrivio.",
        "ris_1": "F",
        "risposta_3": "un'unità a vela che mostra la dritta.",
        "argomento": "Fanali e segnali diurni",
        "domanda": "Il fanale rappresentato in figura a fianco indica:",
        "progressivo": "1.5.1-51",
        "risposta_1": "un'unità a motore che mostra la dritta.",
        "id": 650
    },
    {
        "risposta_3": "a vela, con fanali ripetitori, che mostra la sinistra.",
        "id": 651,
        "immagine": 46,
        "argomento": "Fanali e segnali diurni",
        "domanda": "I fanali rappresentati in figura a fianco indicano una nave:",
        "ris_3": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_1": "rimorchiata che mostra la sinistra.",
        "ris_2": "V",
        "ris_1": "F",
        "risposta_2": "da pesca a strascico, che mostra la sinistra.",
        "progressivo": "1.5.1-52"
    },
    {
        "argomento": "Fanali e segnali diurni",
        "risposta_1": "una nave condizionata dalla propria immersione.",
        "ris_3": "V",
        "domanda": "Il segnale diurno rappresentato in figura a fianco indica:",
        "risposta_2": "una nave con manovrabilità limitata, con un lato ostruito.",
        "risposta_3": "una nave da pesca che opera con attrezzi non a strascico estesi fuori bordo per più di 150 metri.",
        "progressivo": "1.5.1-53",
        "ris_2": "F",
        "ris_1": "F",
        "immagine": 47,
        "id": 652,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO"
    },
    {
        "argomento": "Fanali e segnali diurni",
        "risposta_2": "una unità  alla fonda.",
        "ris_2": "V",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "id": 653,
        "progressivo": "1.5.1-54",
        "risposta_1": "una unità incagliata.",
        "ris_3": "F",
        "risposta_3": "una unità che non governa.",
        "immagine": 48,
        "ris_1": "F",
        "domanda": "Il segnale diurno rappresentato in figura a fianco indica:"
    },
    {
        "risposta_1": "una unità incagliata.",
        "ris_2": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "un'unità a vela che naviga anche a motore.",
        "ris_1": "F",
        "progressivo": "1.5.1-55",
        "domanda": "Il segnale diurno rappresentato in figura a fianco indica:",
        "argomento": "Fanali e segnali diurni",
        "risposta_2": "una unità alla fonda.",
        "immagine": 49,
        "ris_3": "V",
        "id": 654
    },
    {
        "ris_2": "V",
        "risposta_2": "dal tramonto al sorgere del sole e dal sorgere del sole al tramonto in caso di visibilità ridotta, nonché in tutte le altre circostanze in cui lo si ritiene necessario.",
        "argomento": "Fanali e segnali diurni",
        "progressivo": "1.5.1-56",
        "ris_1": "F",
        "ris_3": "F",
        "immagine": "",
        "risposta_3": "dalle ore 20:30 alle ore 06:30.",
        "id": 655,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Quando è previsto che i fanali di navigazione devono essere mantenuti accesi?",
        "risposta_1": "dalle ore 20:00 alle ore 06:00."
    },
    {
        "ris_1": "V",
        "progressivo": "1.5.1-57",
        "immagine": 50,
        "id": 656,
        "argomento": "Fanali e segnali diurni",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "un'unità alla fonda di lunghezza superiore a 50 metri.",
        "risposta_2": "un'unità  a propulsione meccanica di lunghezza inferiore a 50 metri  in navigazione.",
        "risposta_1": "è una unità alla fonda di lunghezza inferiore a 50 metri.",
        "ris_2": "F",
        "ris_3": "F",
        "domanda": "Quella mostrata in figura:"
    },
    {
        "risposta_2": "un'unita navale da pesca intenta allo strascico vista di prua.",
        "immagine": 51,
        "progressivo": "1.5.1-58",
        "risposta_1": "nave con pilota a bordo, vista di prora.",
        "id": 657,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_3": "V",
        "ris_1": "F",
        "argomento": "Fanali e segnali diurni",
        "domanda": "Nella figura, di quale unità si tratta?",
        "ris_2": "F",
        "risposta_3": "un'unità a vela in navigazione, di lunghezza inferiore a 20 metri, vista di prua."
    },
    {
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Nella figura, di quale unità si tratta?",
        "argomento": "Fanali e segnali diurni",
        "id": 658,
        "risposta_1": "un'unità a vela in navigazione, di lunghezza pari o superiore a 20 metri, vista di prua.",
        "ris_3": "F",
        "risposta_2": "un'unità a motore in navigazione, di lunghezza pari o superiore a 50 metri, vista di prua.",
        "ris_1": "V",
        "immagine": 52,
        "progressivo": "1.5.1-59",
        "risposta_3": "un'unità a motore in navigazione, di lunghezza inferiore a 50 metri, vista di prua.",
        "ris_2": "F"
    },
    {
        "id": 659,
        "domanda": "Un'unità  di lunghezza inferiore a 50 metri, che pesca a strascico, con abbrivio, quali segnali diurni deve mostrare?",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "due palloni uniti in linea verticale l'uno sull'altro.",
        "ris_1": "V",
        "ris_3": "F",
        "argomento": "Fanali e segnali diurni",
        "progressivo": "1.5.1-60",
        "risposta_1": "due coni con i vertici uniti in linea verticale l'uno sull'altro.",
        "ris_2": "F",
        "risposta_2": "due coni con i vertici opposti in linea verticale l'uno sull'altro.",
        "immagine": ""
    },
    {
        "domanda": "Il pulpito è:",
        "id": 66,
        "risposta_2": "la protezione dalle cadute posta a estrema prua e estrema poppa, solitamente in tubo di acciaio, cui è ancorata la battagliola.",
        "ris_1": "F",
        "risposta_1": "l'attacco della ruota del timone.",
        "risposta_3": "la ferramenta che si trova a prua estrema, generalmente composta da un unico blocco, che comprende il passacatena dell´ancora.",
        "immagine": "",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_3": "F",
        "progressivo": "1.1.1-66",
        "ris_2": "V",
        "argomento": "Nomenclatura delle parti principali dello scafo"
    },
    {
        "risposta_3": "il fanale di testa d'albero e il fanale di poppa. Questi possono essere combinati tra loro in unico fanale.",
        "domanda": "Quali fanali deve mostrare un'unità a vela di lunghezza superiore a 20 metri in navigazione?",
        "ris_1": "F",
        "ris_2": "V",
        "risposta_1": "un unico fanale combinato che assolva le funzioni di fanale di coronamento e di rimorchio.",
        "risposta_2": "i fanali laterali e il fanale di poppa.",
        "id": 660,
        "progressivo": "1.5.1-61",
        "ris_3": "F",
        "immagine": "",
        "argomento": "Fanali e segnali diurni",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO"
    },
    {
        "progressivo": "1.5.1-62",
        "ris_1": "F",
        "ris_3": "F",
        "id": 661,
        "ris_2": "V",
        "risposta_3": "nel Codice della nautica da diporto.",
        "immagine": "",
        "risposta_2": "nel Colreg.",
        "risposta_1": "negli Avvisi ai Naviganti.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "L'elenco completo dei fanali mostrati dalle navi è indicato:",
        "argomento": "Fanali e segnali diurni"
    },
    {
        "ris_2": "F",
        "risposta_2": "da pesca, di lunghezza inferiore a 50 metri, che è intenta alla pesca non a strascico che dirige a dritta dell'osservatore.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "argomento": "Fanali e segnali diurni",
        "progressivo": "1.5.1-63",
        "id": 662,
        "risposta_3": "a motore, di lunghezza uguale o superiore a 50 metri, che sta dirigendo a sinistra dell'osservatore.",
        "immagine": 53,
        "ris_3": "V",
        "ris_1": "F",
        "risposta_1": "da pesca di lunghezza uguale o superiore a 50 metri, che è intenta alla pesca a strascico, che dirige a sinistra dell'osservatore.",
        "domanda": "I fanali rappresentati in figura a fianco indicano una nave:"
    },
    {
        "risposta_1": "ciascuna di esse accosta a dritta.",
        "id": 663,
        "domanda": "Due unità a propulsione meccanica che stanno navigando, come da figura a fianco, in situazione di rotte opposte con rischio di abbordaggio, in che modo si devono comportare?",
        "risposta_3": "accostano dallo stesso lato per compiere un giro intero e ritornare sulla propria rotta.",
        "immagine": 54,
        "ris_2": "F",
        "ris_3": "F",
        "risposta_2": "l’unità di sinistra accosta a dritta, cedendo il passo all’altra nave.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "progressivo": "1.5.2-1",
        "ris_1": "V",
        "argomento": "Prevenire gli abbordi in mare"
    },
    {
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_3": "V",
        "id": 664,
        "risposta_2": "le Ordinanze emanate dalla Autorità Marittima.",
        "immagine": "",
        "ris_2": "F",
        "domanda": "Qual è la norma che disciplina la materia degli abbordi in mare?",
        "ris_1": "F",
        "risposta_3": "il Regolamento Internazionale del 1972 per prevenire gli abbordi in mare, noto come COLREG '72.",
        "progressivo": "1.5.2-2",
        "risposta_1": "il Codice della Navigazione e il suo Regolamento di esecuzione.",
        "argomento": "Prevenire gli abbordi in mare"
    },
    {
        "ris_3": "F",
        "risposta_2": "ogni condizione in cui la visibilità risulta inferiore a 12 miglia nautiche.",
        "ris_2": "F",
        "domanda": "Ai sensi della COLREG '72, cosa indica l'espressione \"visibilità ridotta \"?",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "id": 665,
        "risposta_1": "ogni condizione in cui la visibilità è ridotta da nebbia, bruma, caduta di neve, violenti acquazzoni, tempeste di sabbia o qualsiasi altro evento simile.",
        "progressivo": "1.5.2-3",
        "ris_1": "V",
        "argomento": "Prevenire gli abbordi in mare",
        "immagine": "",
        "risposta_3": "ogni condizione in cui è necessario utilizzare i sistemi di ausilio alla navigazione (es. radar ed ecoscandaglio)."
    },
    {
        "ris_1": "V",
        "risposta_2": "solo quando le due unità seguono rotte opposte.",
        "id": 666,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "progressivo": "1.5.2-3",
        "ris_2": "F",
        "domanda": "Ogni manovra intrapresa allo scopo di evitare una collisione, se le circostanze del caso lo permettono, deve essere eseguita:",
        "immagine": "",
        "argomento": "Prevenire gli abbordi in mare",
        "risposta_1": "con decisione e ampio margine di tempo e con il dovuto rispetto all'osservanza delle buone regole dell'arte marinara.",
        "risposta_3": "solo quando le due unità sono raggiungenti.",
        "ris_3": "F"
    },
    {
        "risposta_3": "essere abbastanza ampio da risultare evidente all'altra unità navale che la osserva visualmente o con il radar.",
        "immagine": "",
        "ris_2": "F",
        "risposta_1": "essere eseguito in più e successive brevi variazioni per non creare timore all'altra unità navale che la osserva visualmente o con il radar.",
        "argomento": "Prevenire gli abbordi in mare",
        "ris_1": "F",
        "risposta_2": "essere effettuato con successive variazioni non superiori a 5° di rotta o di 1 nodo di velocità.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_3": "V",
        "progressivo": "1.5.2-4",
        "domanda": "Ogni cambiamento di rotta e/o di velocità atto ad evitare una collisione, se le circostanze del caso lo consentono, deve:",
        "id": 667
    },
    {
        "ris_2": "F",
        "ris_1": "V",
        "risposta_2": "l'unità  di lunghezza inferiore a 20 metri o l'unità a vela deve effettuare ogni cambiamento di rotta e/o di velocità con successive variazioni non superiori a 5° di rotta o di 1 nodo di velocità.",
        "risposta_3": "l'unità  di lunghezza superiore a 24 metri o l'unità a vela deve effettuare ogni cambiamento di rotta e/o di velocità con successive variazioni non superiori a 5° di rotta o di 1 nodo di velocità.",
        "progressivo": "1.5.2-5",
        "risposta_1": "l'unità di lunghezza inferiore a 20 metri o l'unità a vela non deve intralciare il passaggio di una nave a propulsione meccanica che segue lo schema di separazione del traffico.",
        "argomento": "Prevenire gli abbordi in mare",
        "ris_3": "F",
        "domanda": "Per quanto concerne la navigazione delle unità navali  all'interno di uno schema di separazione del traffico, come nello stretto di Messina, quale tra queste affermazioni è corretta?",
        "id": 668,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": ""
    },
    {
        "ris_2": "F",
        "domanda": "Quando due unità  a vela si avvicinano una all'altra prendendo  il vento da lati diversi, così da correre il rischio di una collisione:",
        "risposta_2": "l'unità che ha il vento sulla sua dritta deve lasciare libera la rotta all'altra.",
        "ris_3": "V",
        "argomento": "Prevenire gli abbordi in mare",
        "ris_1": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": "",
        "risposta_3": "l'unità  che ha il vento sulla sua sinistra deve lasciare libera la rotta all'altra.",
        "risposta_1": "l'unità  che ha la maggiore superficie velica deve lasciare libera la rotta all'altra.",
        "progressivo": "1.5.2-6",
        "id": 669
    },
    {
        "immagine": "",
        "risposta_3": "è un termine di fantasia.",
        "domanda": "Si dicono prese a mare:",
        "ris_1": "V",
        "risposta_2": "le elichette che fuoriescono dallo scafo con cui il log determina la velocità dello scafo.",
        "risposta_1": "le valvole, poste in connessione con i passascafo, che consentono di chiudere l'ingresso dell'acqua all'interno della barca.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "ris_3": "F",
        "ris_2": "F",
        "id": 67,
        "capitolo": "TEORIA DELLO SCAFO",
        "progressivo": "1.1.1-67"
    },
    {
        "id": 670,
        "ris_2": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_3": "F",
        "progressivo": "1.5.2-7",
        "immagine": "",
        "domanda": "Quando due unità navali a vela si avvicinano una all'altra prendendo il vento dallo stesso lato, così da correre il rischio di una collisione:",
        "argomento": "Prevenire gli abbordi in mare",
        "ris_1": "V",
        "risposta_1": "l'unità che è sopravento deve lasciare libera la rotta a quella che è sottovento.",
        "risposta_2": "l'unità  che è sottovento deve lasciare libera la rotta a quella che è sopravento.",
        "risposta_3": "l'unità che ha la maggiore superficie velica deve lasciare libera la rotta al'altra."
    },
    {
        "progressivo": "1.5.2-8",
        "ris_1": "V",
        "risposta_2": "un'unità che ha difficoltà di manovra deve lasciare libera la rotta ad un'unità a vela.",
        "ris_3": "F",
        "argomento": "Prevenire gli abbordi in mare",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": "",
        "ris_2": "F",
        "risposta_3": "un'unità navale che ha difficoltà di manovra deve lasciare libera la rotta ad un'unità navale impegnata in operazioni di pesca.",
        "risposta_1": "un'unità che ha difficoltà di manovra deve lasciare libera la rotta ad un'unità navale che non governa.",
        "id": 671,
        "domanda": "Quale affermazione è corretta?"
    },
    {
        "domanda": "Per quanto concerne la situazione di rotte incrociate, implicanti un pericolo di collisione, tra due unità a propulsione meccanica:",
        "argomento": "Prevenire gli abbordi in mare",
        "ris_3": "F",
        "risposta_3": "l'unità che vede l'altra sulla propria dritta deve incrementare la propria velocità e garantire un adeguato servizio di vedetta.",
        "risposta_1": "l'unità  che vede l'altra sulla propria sinistra deve lasciarle libera la rotta e, quando le circostanze lo permettono, deve evitare di passarle di poppa.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "progressivo": "1.5.2-9",
        "id": 672,
        "risposta_2": "l'unità  che vede l'altra sulla propria dritta deve lasciarle libera la rotta e, quando le circostanze lo permettono, deve evitare di passarle di prora.",
        "ris_1": "F",
        "immagine": "",
        "ris_2": "V"
    },
    {
        "domanda": "In caso di visibilità limitata:",
        "ris_3": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_2": "ogni unità  deve procedere ad una velocità di crociera non inferiore ai 10 nodi e con il servizio di vedetta attivato.",
        "risposta_1": "ogni unità  deve procedere a una velocità di sicurezza relativa alle circostanze del momento e alle condizioni di visibilità.",
        "immagine": "",
        "progressivo": "1.5.2-10",
        "argomento": "Prevenire gli abbordi in mare",
        "id": 673,
        "ris_2": "F",
        "ris_1": "V",
        "risposta_3": "ogni unità deve procedere a una velocità di crociera non inferiore agli 8 nodi e con il servizio di vedetta attivato."
    },
    {
        "risposta_1": "dal tramonto al sorgere del sole le unità  non devono mostrare nessun altra luce che possa essere confusa con i fanali prescritti dal COLREG.",
        "ris_3": "F",
        "ris_2": "F",
        "immagine": "",
        "argomento": "Prevenire gli abbordi in mare",
        "progressivo": "1.5.2-11",
        "risposta_3": "dalle ore 08:00 alle ore 19:00 le unità non devono mostrare nessun altra luce che possa essere confusa con i fanali prescritti dal COLREG.",
        "risposta_2": "dalle ore 08:00 alle ore 17:00 le unità non devono mostrare nessun altra luce che possa essere confusa con i fanali prescritti dal COLREG.",
        "ris_1": "V",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "id": 674,
        "domanda": "Per quanto concerne l'ambito di applicazione delle regole relative ai fanali:"
    },
    {
        "domanda": "Per quanto concerne l'ambito di applicazione delle regole relative ai fanali:",
        "risposta_1": "in caso navigazione in prossimità di bassi fondali i fanali prescritti dal COLREG devono obbligatoriamente essere esposti anche dal sorgere del sole al tramonto.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": "",
        "ris_1": "F",
        "argomento": "Prevenire gli abbordi in mare",
        "id": 675,
        "risposta_3": "in caso di visibilità ridotta e in tutte le altre circostanze, se lo si ritiene necessario, i fanali prescritti dal COLREG devono essere esposti anche dal sorgere del sole al tramonto.",
        "risposta_2": "in caso navigazione in prossimità di schemi di separazione del traffico, come nello Stretto di Messina, i fanali prescritti dal COLREG devono obbligatoriamente essere esposti anche dal sorgere del sole al tramonto.",
        "progressivo": "1.5.2-12",
        "ris_3": "V",
        "ris_2": "F"
    },
    {
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_1": "V",
        "ris_2": "F",
        "ris_3": "F",
        "domanda": "Di quale apparecchiatura per i segnali sonori  deve essere munita un'unità di lunghezza inferiore a 12 metri?",
        "risposta_1": "Non sussiste un obbligo, sotto i 12 metri l'unità deve essere fornita di un mezzo in grado  di produrre un efficace segnale sonoro.",
        "risposta_3": "una campana.",
        "argomento": "Prevenire gli abbordi in mare",
        "risposta_2": "un fischio.",
        "progressivo": "1.5.2-13",
        "id": 676,
        "immagine": ""
    },
    {
        "risposta_2": "può compiere tre accostate a dritta e tre a sinistra.",
        "immagine": "",
        "ris_2": "F",
        "risposta_3": "può compiere un'intera curva di evoluzione, accostando esclusivamente a dritta, per ritornare in prossimità del punto di partenza.",
        "ris_3": "F",
        "id": 677,
        "domanda": "Per quanto concerne i segnali di pericolo che un'unità deve usare o mostrare quando ha necessità di soccorso o è in pericolo, quale tra queste affermazioni è corretta?",
        "ris_1": "V",
        "argomento": "Prevenire gli abbordi in mare",
        "risposta_1": "può utilizzare un suono continuo emesso da qualsiasi apparecchiatura per segnali da nebbia.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "progressivo": "1.5.2-14"
    },
    {
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Quale delle due unità  a vela è tenuta a lasciare libera la rotta all'altra?",
        "ris_1": "F",
        "risposta_1": "l'unità B, che prende il vento sulla dritta, deve lasciare libera la rotta all'unità A.",
        "risposta_3": "l'unità A, che ha il vento sulla sinistra, deve lasciare libera la rotta all'unità B.",
        "immagine": 55,
        "id": 678,
        "risposta_2": "entrambe mantengono la rotta inalterata.",
        "ris_3": "V",
        "argomento": "Prevenire gli abbordi in mare",
        "ris_2": "F",
        "progressivo": "1.5.2-15"
    },
    {
        "ris_3": "F",
        "domanda": "Prendendo il vento dallo stesso lato, quale delle due unità  a vela è tenuta ha lasciare libera rotta all'altra?",
        "risposta_1": "l'unità A, che è sopravvento, deve lasciare libera la rotta all'unità B, che è sottovento.",
        "ris_2": "F",
        "ris_1": "V",
        "risposta_2": "l'unità B, che è sopravvento, deve lasciare libera la rotta all'unità A, che è sottovento.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "argomento": "Prevenire gli abbordi in mare",
        "progressivo": "1.5.2-16",
        "risposta_3": "entrambe mantengono la rotta inalterata.",
        "immagine": 56,
        "id": 679
    },
    {
        "risposta_1": "la carena a \"V\" profonda affronta meglio il moto ondoso molto formato.",
        "id": 68,
        "risposta_3": "la carena piatta è la più adatta per affrontare il moto ondoso formato.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "risposta_2": "la carena dislocante è la più adatta alla planata.",
        "domanda": "In base alle prestazioni nautiche dei principali tipi di scafo, si può affermare che:",
        "capitolo": "TEORIA DELLO SCAFO",
        "progressivo": "1.1.1-68",
        "immagine": "",
        "ris_3": "F",
        "ris_2": "F",
        "ris_1": "V"
    },
    {
        "risposta_3": "si trova nel raggio del fanale di coronamento di quella che la precede.",
        "risposta_1": "mostra un fanale rosso visibile per tutto l'orizzonte.",
        "domanda": "La \"nave raggiungente\" si riconosce di notte perchè:",
        "id": 680,
        "risposta_2": "mostra sempre un fanale giallo di coronamento.",
        "ris_2": "F",
        "ris_3": "V",
        "immagine": "",
        "ris_1": "F",
        "progressivo": "1.5.2-17",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "argomento": "Prevenire gli abbordi in mare"
    },
    {
        "risposta_2": "il rilevamento diminuisce.",
        "immagine": "",
        "risposta_1": "il rilevamento aumenta.",
        "ris_1": "F",
        "progressivo": "1.5.2-18",
        "ris_2": "F",
        "risposta_3": "il rilevamento rimane costante e la distanza diminuisce.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Si è in presenza di rotta di collisione, in caso di rotte convergenti, quando:",
        "argomento": "Prevenire gli abbordi in mare",
        "id": 681,
        "ris_3": "V"
    },
    {
        "ris_2": "F",
        "progressivo": "1.5.2-19",
        "risposta_2": "deve segnalare l'intenzione di voler sorpassare e la nave raggiunta è obbligata a cedere la rotta.",
        "domanda": "Una unità, raggiungente un'altra unità:",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "id": 682,
        "ris_3": "F",
        "argomento": "Prevenire gli abbordi in mare",
        "immagine": "",
        "ris_1": "V",
        "risposta_1": "deve lasciare libera la rotta alla nave raggiunta.",
        "risposta_3": "deve emettere 4 suoni brevi."
    },
    {
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_3": "F",
        "risposta_2": "1 fischio prolungato ad intervalli non superiori a 2 minuti.",
        "ris_2": "V",
        "risposta_1": "2 fischi brevi ad intervalli non superiori a 2 minuti.",
        "progressivo": "1.5.2-20",
        "immagine": "",
        "id": 683,
        "argomento": "Prevenire gli abbordi in mare",
        "ris_1": "F",
        "risposta_3": "2 fischi prolungati ad intervalli non superiori a 2 minuti.",
        "domanda": "In presenza di nebbia, che segnali sonori deve emettere una nave a motore in navigazione con abbrivio?"
    },
    {
        "domanda": "Una nave a propulsione meccanica in navigazione segnala, ad altre unità in vista, un'accostata a dritta con:",
        "progressivo": "1.5.2-21",
        "ris_3": "F",
        "id": 684,
        "risposta_1": "1 suono breve emesso con un fischio.",
        "argomento": "Prevenire gli abbordi in mare",
        "risposta_3": "2 suoni brevi emessi con un fischio.",
        "ris_1": "V",
        "risposta_2": "1 suono breve ed 1 suono prolungato emessi con un fischio.",
        "immagine": "",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_2": "F"
    },
    {
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_3": "F",
        "argomento": "Prevenire gli abbordi in mare",
        "ris_2": "F",
        "risposta_2": "non superarla.",
        "progressivo": "1.5.2-22",
        "risposta_1": "superarla sulla sinistra.",
        "risposta_3": "superarla sulla dritta.",
        "ris_1": "V",
        "immagine": "",
        "domanda": "Una unità raggiungente che emette 2 segnali sonori prolungati e 2 brevi, sta segnalando all'unità raggiunta che ha l'intenzione di:",
        "id": 685
    },
    {
        "risposta_3": "con 1 suono breve ed 1 prolungato emessi con il fischio.",
        "ris_2": "F",
        "domanda": "Una nave a propulsione meccanica che si trovi in navigazione, come segnala un'accostata a sinistra?",
        "risposta_2": "con 2 suoni prolungati emessi con il fischio.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "argomento": "Prevenire gli abbordi in mare",
        "ris_3": "F",
        "id": 686,
        "ris_1": "V",
        "immagine": "",
        "risposta_1": "con 2 suoni brevi emessi con il fischio.",
        "progressivo": "1.5.2-23"
    },
    {
        "immagine": "",
        "risposta_1": "all'imbarcazione da diporto.",
        "ris_1": "F",
        "domanda": "L'unità intenta alla pesca, quando è in navigazione, deve lasciar libera la rotta:",
        "risposta_2": "all'unità propulsa a vela.",
        "id": 687,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_3": "V",
        "argomento": "Prevenire gli abbordi in mare",
        "progressivo": "1.5.2-24",
        "ris_2": "F",
        "risposta_3": "a una nave con manovrabilità limitata."
    },
    {
        "domanda": "In figura a fianco sono rappresentate due unità da diporto propulse a motore: quale delle due ha il dovere di manovrare?",
        "immagine": 57,
        "risposta_1": "l'unità A accosta a dritta e passa a poppa della B.",
        "argomento": "Prevenire gli abbordi in mare",
        "ris_3": "F",
        "ris_2": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_1": "V",
        "progressivo": "1.5.2-25",
        "id": 688,
        "risposta_3": "accostano a dritta entrambe.",
        "risposta_2": "l'unita B accosta a dritta e passa a poppa della A."
    },
    {
        "id": 689,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_1": "nave con manovrabilità limitata.",
        "domanda": "Una draga intenta a dragare è considerabile come una:",
        "progressivo": "1.5.2-26",
        "argomento": "Prevenire gli abbordi in mare",
        "ris_1": "V",
        "immagine": "",
        "risposta_2": "nave condizionata dalla sua immersione.",
        "risposta_3": "nave che non governa.",
        "ris_3": "F",
        "ris_2": "F"
    },
    {
        "progressivo": "1.1.1-69",
        "risposta_1": "longitudinale.",
        "domanda": "Il rollìo è l'oscillazione dell'unità intorno al suo asse:",
        "ris_1": "V",
        "capitolo": "TEORIA DELLO SCAFO",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "risposta_3": "trasversale.",
        "ris_2": "F",
        "id": 69,
        "ris_3": "F",
        "risposta_2": "verticale.",
        "immagine": ""
    },
    {
        "immagine": "",
        "risposta_2": "c'è rischio di collisione con l'altra unità.",
        "progressivo": "1.5.2-27",
        "risposta_1": "ci si sta allontanando dall'altra unità.",
        "ris_1": "F",
        "ris_3": "F",
        "id": 690,
        "ris_2": "V",
        "risposta_3": "si naviga su rotte parallele rispetto all'altra unità.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Il rilevamento non cambia e la distanza diminuisce; significa che:",
        "argomento": "Prevenire gli abbordi in mare"
    },
    {
        "argomento": "Prevenire gli abbordi in mare",
        "immagine": "",
        "ris_1": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Sono al comando di una barca a motore e lascio la precedenza ad altra barca a motore che emette due fischi brevi; quindi mi aspetto che:",
        "id": 691,
        "risposta_2": "tale barca esegua un accosto a sinistra.",
        "ris_3": "F",
        "progressivo": "1.5.2-28",
        "risposta_1": "tale barca si allontani a distanza di manovra.",
        "risposta_3": "tale barca mi sorpassi sulla dritta.",
        "ris_2": "V"
    },
    {
        "immagine": "",
        "argomento": "Prevenire gli abbordi in mare",
        "risposta_1": "nell'ordine: nave che non governa, nave con manovrabilità limitata, nave intenta a pescare, nave a vela.",
        "id": 692,
        "risposta_2": "nell'ordine: nave con manovrabilità limitata, nave intenta a dragare, nave intenta a pescare, nave a vela.",
        "ris_1": "V",
        "ris_3": "",
        "risposta_3": "nell'ordine: nave con manovrabilità limitata, nave intenta a posare cavi sottomarini, nave intenta a pescare, nave a vela.",
        "ris_2": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Salvo disposizioni contrarie espressamente previste nel Regolamento per Prevenire gli Abbordi in Mare (Colreg '72), in generale un'unità a motore verso chi ha l'obbligo di dare la precedenza? (individuare la risposta con l'ordine di  precedenza corretto):",
        "progressivo": "1.5.2-29"
    },
    {
        "risposta_1": "si rileva l’altra nave in rotta opposta a poppavia del traverso.",
        "immagine": "",
        "ris_2": "F",
        "risposta_3": "in caso di rotte opposte, il rilevamento polare non cambia e la distanza diminuisce.",
        "id": 693,
        "ris_1": "F",
        "progressivo": "1.5.2-30",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "argomento": "Prevenire gli abbordi in mare",
        "ris_3": "V",
        "domanda": "Constatiamo che esiste il rischio di collisione con un’altra unità se:",
        "risposta_2": "diminuisce la distanza tra le due navi con rotte opposte e il rilevamento polare scade."
    },
    {
        "immagine": 58,
        "risposta_2": "l'unità B.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_3": "F",
        "ris_2": "V",
        "risposta_3": "l'unità A, se proviene da dritta.",
        "id": 694,
        "risposta_1": "l'unità A perché ha le mura a dritta e si trova a dritta dell'unità B.",
        "domanda": "Quale, tra due unità a vela A e B  in figura, le cui rotte si incrociano, ha il diritto di precedenza considerando che l'unità A espone un cono nero con il vertice rivolto verso il basso?",
        "argomento": "Prevenire gli abbordi in mare",
        "ris_1": "F",
        "progressivo": "1.5.2-31"
    },
    {
        "progressivo": "1.5.2-32",
        "risposta_2": "l'unità B, perchè ha il vento sulla sinistra.",
        "risposta_3": "l'unità B, perché è mure a dritta.",
        "argomento": "Prevenire gli abbordi in mare",
        "ris_1": "V",
        "immagine": 59,
        "risposta_1": "l'unità A.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "id": 695,
        "ris_2": "F",
        "ris_3": "F",
        "domanda": "Quale, tra due unità a vela A e B  in figura, le cui rotte si incrociano, ha il diritto di precedenza?"
    },
    {
        "immagine": "",
        "ris_3": "V",
        "ris_2": "F",
        "domanda": "I fanali di navigazione sono prescritti per le unità da diporto?",
        "risposta_1": "sì, per le unità da diporto in navigazione oltre 6 miglia dalla costa.",
        "risposta_2": "sì, per tutte le unità da diporto (natanti, imbarcazione e navi) indipendentemente dal tipo di navigazione effettuata.",
        "progressivo": "1.5.2-33",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_1": "F",
        "risposta_3": "si, per le unità da diporto in navigazione oltre 1 miglio dalla costa.",
        "argomento": "Prevenire gli abbordi in mare",
        "id": 696
    },
    {
        "risposta_3": "3 miglia.",
        "immagine": "",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_3": "F",
        "domanda": "La portata dei fanali laterali di un’unità di lunghezza uguale o superiore a 12 metri ma inferiore a 50 metri è di:",
        "ris_1": "V",
        "risposta_2": "1 miglio.",
        "progressivo": "1.5.2-34",
        "id": 697,
        "risposta_1": "2 miglia.",
        "argomento": "Prevenire gli abbordi in mare",
        "ris_2": "F"
    },
    {
        "ris_2": "F",
        "domanda": "Una unità di lunghezza superiore a 20 metri, alla fonda con nebbia, per segnalare la sua presenza emette:",
        "progressivo": "1.5.2-35",
        "risposta_2": "2 suoni prolungati e 1 suono breve.",
        "id": 698,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "rapidi suoni di campana per cinque secondi ad intervalli non superiori a un\nminuto.",
        "ris_3": "V",
        "argomento": "Prevenire gli abbordi in mare",
        "risposta_1": "2 suoni prolungati e 2 suoni brevi.",
        "immagine": "",
        "ris_1": "F"
    },
    {
        "immagine": "",
        "progressivo": "1.5.2-36",
        "domanda": "La campana da nebbia è obbligatoria per le unità che siano di lunghezza uguale o superiore a:",
        "risposta_1": "7 metri.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "10 metri.",
        "argomento": "Prevenire gli abbordi in mare",
        "id": 699,
        "ris_1": "F",
        "ris_2": "V",
        "risposta_2": "12 metri.",
        "ris_3": "F"
    },
    {
        "progressivo": "1.1.1-7",
        "ris_1": "F",
        "ris_3": "F",
        "domanda": "Cosa si intende per poppa di un'unità?",
        "risposta_1": "la parte più arrotondata dello scafo.",
        "risposta_3": "la porzione estrema anteriore dello scafo.",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_2": "V",
        "id": 7,
        "risposta_2": "la porzione posteriore posta all'estremità dello scafo.",
        "immagine": "",
        "argomento": "Nomenclatura delle parti principali dello scafo"
    },
    {
        "progressivo": "1.1.1-70",
        "risposta_2": "plana e si alza sul filo dell'acqua.",
        "immagine": "",
        "ris_1": "F",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "risposta_1": "se in un dato momento non plana.",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_3": "è tonda oppure a V profondo.",
        "id": 70,
        "ris_3": "V",
        "domanda": "La carena viene detta dislocante se:",
        "ris_2": "F"
    },
    {
        "risposta_1": "4 suoni brevi ogni 5 minuti.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "id": 700,
        "ris_3": "F",
        "domanda": "Una nave in navigazione che intende sorpassare sulla sinistra un'altra unità emette i seguenti segnali sonori:",
        "ris_2": "V",
        "immagine": "",
        "risposta_3": "2 suoni brevi.",
        "risposta_2": "2 suoni prolungati e 2 suoni brevi.",
        "progressivo": "1.5.2-37",
        "argomento": "Prevenire gli abbordi in mare",
        "ris_1": "F"
    },
    {
        "immagine": "",
        "risposta_3": "un’andatura a lento moto.",
        "ris_2": "V",
        "domanda": "L’abbrivo o abbrivio è:",
        "progressivo": "1.5.2-38",
        "ris_3": "F",
        "argomento": "Prevenire gli abbordi in mare",
        "risposta_2": "il moto che rimane quando si disinnesca l’invertitore del motore o si sventano le vele.",
        "risposta_1": "è un'andatura esclusivamente a vela; i motori vengono spenti.",
        "id": 701,
        "ris_1": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO"
    },
    {
        "ris_1": "F",
        "risposta_1": "entrambe accostano a dritta per poi riprendere la propria navigazione una volta cessato il pericolo.",
        "id": 702,
        "immagine": "",
        "progressivo": "1.5.2-39",
        "argomento": "Prevenire gli abbordi in mare",
        "ris_2": "V",
        "ris_3": "F",
        "domanda": "Due unità a motore che navighino con rotte di collisione (non opposte):",
        "risposta_2": "l’unità che proviene da sinistra accosta sulla propria dritta e quindi passa di poppa all’altra unità.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "l’unità che proviene da dritta accosta sulla propria sinistra e quindi passa di\npoppa all’altra unità."
    },
    {
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "In caso di nebbia, un'unità:",
        "progressivo": "1.5.2-40",
        "immagine": "",
        "ris_1": "F",
        "ris_2": "V",
        "risposta_1": "a motore, in navigazione o con abbrivio, deve emettere, ad intervalli non superiori a 2 minuti, 1 suono prolungato e 2 brevi con un intervallo tra di loro di circa 2 secondi.",
        "id": 703,
        "ris_3": "F",
        "risposta_3": "che non governa, deve emettere ogni minuto 1 suono prolungato o rapidi suoni di campana per cinque secondi.",
        "argomento": "Prevenire gli abbordi in mare",
        "risposta_2": "a motore, ferma e senza abbrivio, emette, ad intervalli non superiori a 2 minuti, 2 suoni prolungati con un intervallo tra di loro di circa 2 secondi."
    },
    {
        "ris_2": "F",
        "risposta_2": "in funzione del tipo di unità di diporto, rileviamo: verde a dritta o a sinistra e rosso a dritta o a sinistra.",
        "risposta_1": "verde a dritta e rosso a sinistra.",
        "ris_1": "V",
        "progressivo": "1.5.1-64",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": "",
        "ris_3": "F",
        "id": 704,
        "argomento": "Fanali e segnali diurni",
        "domanda": "Sulle fiancate di un’unità in navigazione sono accesi i seguenti fanali:",
        "risposta_3": "verde a sinistra e rosso a dritta."
    },
    {
        "id": 705,
        "argomento": "Fanali e segnali diurni",
        "immagine": "",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "In navigazione notturna, un’unità da diporto a motore di lunghezza fuori tutto di 45 metri deve obbligatoriamente mostrare:",
        "ris_2": "F",
        "risposta_3": "accendere solo il fanale di testa d’albero ed i fanali di via laterali.",
        "risposta_1": "sia il fanale bianco di testa d’albero, sia i fanali di via laterali e sia il fanale di coronamento.",
        "progressivo": "1.5.1-65",
        "risposta_2": "accendere solo i fanali di via laterali ed il fanale di coronamento.",
        "ris_3": "F",
        "ris_1": "V"
    },
    {
        "risposta_3": "un fanale bianco più alto rispetto a quello di testa d’albero e a poppavia, visibile per 225 gradi.",
        "id": 706,
        "progressivo": "1.5.1-66",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_1": "F",
        "risposta_1": "una seconda serie di fanali laterali disposti più a proravia dei primi.",
        "immagine": "",
        "argomento": "Fanali e segnali diurni",
        "domanda": "Un’unità a motore di lunghezza uguale o superiore a 50 metri, cosa deve accendere in più rispetto ad una di lunghezza inferiore a 50 metri?",
        "ris_3": "V",
        "ris_2": "F",
        "risposta_2": "un fanale bianco più alto rispetto a quello di testa d’albero e a poppavia del primo, visibile per 360 gradi."
    },
    {
        "progressivo": "1.5.1-67",
        "immagine": "",
        "risposta_1": "fanali di via laterali e fanale di coronamento.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "argomento": "Fanali e segnali diurni",
        "risposta_3": "fanali di via laterale, fanale di testa d’albero, fanali ripetitori e fanale di coronamento.",
        "ris_1": "V",
        "ris_2": "F",
        "risposta_2": "fanali di via laterali, 2 fanali ripetitori verde sopra e rosso sotto visibili per 360 gradi e fanale di coronamento.",
        "ris_3": "F",
        "id": 707,
        "domanda": "Un’unità in navigazione notturna a vela ha l’obbligo di accendere:"
    },
    {
        "ris_3": "F",
        "ris_1": "F",
        "domanda": "Il pericolo di collisione tra due unità può sussistere se:",
        "risposta_3": "si mostrano la stessa fiancata.",
        "progressivo": "1.5.2-41",
        "ris_2": "V",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": "",
        "risposta_2": "si mostrano fiancate opposte.",
        "id": 708,
        "risposta_1": "navigano a velocità differenti.",
        "argomento": "Prevenire gli abbordi in mare"
    },
    {
        "argomento": "Prevenire gli abbordi in mare",
        "ris_1": "V",
        "id": 709,
        "risposta_3": "le due unità navigano a velocità uguali.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_2": "le due unità si mostrano gli stessi fanali (di notte).",
        "immagine": "",
        "ris_2": "F",
        "domanda": "Sussiste pericolo di collisione tra due unità se:",
        "risposta_1": "tramite dei rilevamenti successivi si possa stabilire la simultaneità di transito per lo stesso punto.",
        "progressivo": "1.5.2-42",
        "ris_3": "F"
    },
    {
        "ris_2": "F",
        "immagine": "",
        "domanda": "La carena viene detta dislocante se è del tipo:",
        "risposta_2": "plana e si alza sul filo dell’acq",
        "id": 71,
        "ris_1": "V",
        "capitolo": "TEORIA DELLO SCAFO",
        "progressivo": "1.1.1-71",
        "risposta_3": "presenta due scafi.",
        "risposta_1": "non plana e navigando sposta l’acqua a destra e a sinistra.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "ris_3": "F"
    },
    {
        "argomento": "Prevenire gli abbordi in mare",
        "ris_1": "F",
        "risposta_3": "attuo la manovra per dare la precedenza.",
        "immagine": "",
        "id": 710,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_3": "V",
        "progressivo": "1.5.2-43",
        "risposta_1": "aspetto che manovri l'altra unità.",
        "domanda": "Sono al comando dell’unità non avente diritto di precedenza ed è certo il pericolo di collisione: che fare?",
        "ris_2": "F",
        "risposta_2": "accelero per cercare di precedere l’altra unità."
    },
    {
        "domanda": "Fra tre unità da diporto a motore, ha diritto di precedenza quella:",
        "ris_3": "F",
        "ris_2": "V",
        "argomento": "Prevenire gli abbordi in mare",
        "risposta_3": "più lenta.",
        "immagine": "",
        "ris_1": "F",
        "risposta_2": "che viene da dritta.",
        "risposta_1": "che si trova tra le altre due.",
        "id": 711,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "progressivo": "1.5.2-44"
    },
    {
        "risposta_1": "mostra il settore di coronamento alla nave raggiungente.",
        "domanda": "Una unità viene definita “nave raggiunta” quando:",
        "ris_3": "F",
        "progressivo": "1.5.2-45",
        "risposta_2": "espone di notte il fanale rosso laterale.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "è più lenta di un’altra.",
        "argomento": "Prevenire gli abbordi in mare",
        "ris_1": "V",
        "id": 712,
        "ris_2": "F",
        "immagine": ""
    },
    {
        "ris_1": "F",
        "risposta_3": "nessuna; devono manovrare entrambe sulla propria dritta.",
        "ris_3": "V",
        "ris_2": "F",
        "risposta_2": "nessuna; devono manovrare entrambe sulla propria sinistra.",
        "argomento": "Prevenire gli abbordi in mare",
        "progressivo": "1.5.2-46",
        "id": 713,
        "risposta_1": "l'unità più grande perché si presenta con maggior difficoltà di manovra rispetto all'unità più piccola.",
        "domanda": "Se due unità a motore da diporto mostrano, entrambe l'una all'altra, i fanali laterali e quello di testa d’albero, ha la precedenza:",
        "immagine": "",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO"
    },
    {
        "risposta_3": "fare due rilevamenti polari in tempi successivi dell’altra unità. Il pericolo di collisione è effettivo e reale se l’angolo rimane costante e diminuisce la distanza tra le due unità.",
        "ris_1": "F",
        "risposta_2": "capire se una delle due è più veloce.",
        "progressivo": "1.5.2-47",
        "risposta_1": "fare un rilevamento polare dell’altra unità.",
        "immagine": "",
        "domanda": "Per stabilire che due unità arrivano contemporaneamente nel “punto di collisione”, è sufficiente:",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_2": "F",
        "argomento": "Prevenire gli abbordi in mare",
        "id": 714,
        "ris_3": "V"
    },
    {
        "id": 715,
        "domanda": "Il segnale sonoro \"2 suoni prolungati seguito da 1 breve\", indica:",
        "ris_3": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_2": "V",
        "risposta_2": "che intendo sorpassare a dritta.",
        "progressivo": "1.5.2-48",
        "ris_1": "F",
        "immagine": "",
        "risposta_1": "che una nave è in difficoltà di manovra nella nebbia.",
        "argomento": "Prevenire gli abbordi in mare",
        "risposta_3": "dubbio o pericolo."
    },
    {
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "ho intenzione di accostare a dritta.",
        "argomento": "Prevenire gli abbordi in mare",
        "id": 716,
        "ris_1": "F",
        "ris_2": "F",
        "risposta_2": "macchine indietro.",
        "risposta_1": "ho intenzione di accostare a sinistra.",
        "ris_3": "V",
        "progressivo": "1.5.2-49",
        "domanda": "Il segnale sonoro \"1 suono breve\", indica:",
        "immagine": ""
    },
    {
        "id": 717,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": "",
        "ris_3": "V",
        "ris_2": "F",
        "domanda": "L’intenzione di voler sorpassare è segnalata con:",
        "risposta_3": "2 suoni prolungati (ai quali aggiungerò 1 breve per sorpassare a dritta e 2 brevi per sorpassare a sinistra).",
        "ris_1": "F",
        "progressivo": "1.5.2-50",
        "risposta_1": "1 suono prolungato, 1 breve,\n1 prolungato, 1 breve (ai quali aggiungerò 1 breve per sorpassare a dritta e 2 brevi per sorpassare a sinistra).",
        "risposta_2": "3 suoni brevi (ai quali aggiungerò 1 breve per sorpassare a dritta e 2 brevi per sorpassare a sinistra).",
        "argomento": "Prevenire gli abbordi in mare"
    },
    {
        "domanda": "Relativamente alle apparecchiature per i segnali sonori:",
        "risposta_2": "un'unità di lunghezza inferiore a 12 metri non deve essere dotata di alcun mezzo in grado di produrre un efficace segnale sonoro.",
        "ris_3": "F",
        "risposta_3": "entrambe le risposte suddette sono errate.",
        "id": 718,
        "progressivo": "1.5.2-51",
        "argomento": "Prevenire gli abbordi in mare",
        "risposta_1": "un'unità di lunghezza inferiore a 12 metri deve comunque essere dotata di qualsiasi mezzo in grado di produrre un efficace segnale sonoro.",
        "ris_1": "V",
        "ris_2": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": ""
    },
    {
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_3": "F",
        "argomento": "Prevenire gli abbordi in mare",
        "ris_2": "F",
        "domanda": "In caso di scarsa visibilità, qual è il segnale sonoro che le unità da diporto a vela devono emettere?",
        "risposta_2": "1 suono prolungato e 3 brevi ad intervalli non superiori a due minuti.",
        "immagine": "",
        "ris_1": "V",
        "id": 719,
        "progressivo": "1.5.2-52",
        "risposta_1": "1 suono prolungato e 2 brevi ad intervalli non superiori a due minuti.",
        "risposta_3": "2 suoni prolungati e 1 breve ad intervalli non superiori a due minuti."
    },
    {
        "risposta_3": "il sistema di comunicazione radio.",
        "immagine": "",
        "risposta_1": "una tipologia di carena.",
        "ris_3": "F",
        "id": 72,
        "ris_2": "V",
        "risposta_2": "il pistone idraulico che va ad agire sulla posizione del motore fuoribordo modificando l'angolo tra lo specchio di poppa e il gambo del motore stesso.",
        "progressivo": "1.1.1-72",
        "ris_1": "F",
        "domanda": "Per trim si intende:",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "capitolo": "TEORIA DELLO SCAFO"
    },
    {
        "id": 720,
        "risposta_1": "le unità a vela.",
        "argomento": "Prevenire gli abbordi in mare",
        "domanda": "In caso di scarsa visibilità, chi deve emettere 1 suono prolungato ogni due minuti secondo quanto prescritto dal Regolamento per Prevenire gli Abbordi in Mare?",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "le unità a motore in navigazione con abbrivio.",
        "progressivo": "1.5.2-53",
        "immagine": "",
        "risposta_2": "le unità alla fonda.",
        "ris_3": "V",
        "ris_2": "F",
        "ris_1": "F"
    },
    {
        "ris_3": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_1": "F",
        "immagine": "",
        "argomento": "Prevenire gli abbordi in mare",
        "domanda": "In navigazione notturna vedo un fanale bianco in direzione della mia prora:",
        "progressivo": "1.5.2-54",
        "id": 721,
        "risposta_1": "mi allontano perché si tratta sicuramente di una nave incagliata.",
        "risposta_2": "sto raggiungendo un'altra unità, dovrò quindi darle la precedenza.",
        "ris_2": "V",
        "risposta_3": "vengo da dritta, quindi ho diritto di precedenza per cui procederò con rotta e velocità costanti."
    },
    {
        "id": 722,
        "risposta_3": "si effettuano dei rilevamenti polari dell'unità B in tempi successivi per valutare la necessità di effettuare la manovra per dare la precedenza.",
        "argomento": "Prevenire gli abbordi in mare",
        "risposta_2": "si attende che manovri l'unità di minori dimensioni in quanto più manovriera rispetto a quella di maggiori dimensioni.",
        "ris_2": "F",
        "domanda": "Sino a quando non sia chiaro a chi spetti il diritto di precedenza, in caso di rotte che s'incrocino tali che dall'unità A sia visibile il fanale laterale rosso dell'unità B di minori dimensioni:",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_1": "si aumenta la velocità in modo da evitare sicuramente il pericolo di collisione.",
        "ris_1": "F",
        "ris_3": "V",
        "immagine": "",
        "progressivo": "1.5.2-55"
    },
    {
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Il Regolamento per Prevenire gli Abbordi in Mare prevede che:",
        "ris_1": "F",
        "immagine": "",
        "progressivo": "1.5.2-56",
        "id": 723,
        "ris_3": "F",
        "risposta_1": "per dare la precedenza è necessario accostare a sempre a dritta.",
        "argomento": "Prevenire gli abbordi in mare",
        "risposta_3": "in rotta di collisione l'unità più lenta ha diritto di precedenza.",
        "ris_2": "V",
        "risposta_2": "in situazioni dubbie il pericolo si considera esistente."
    },
    {
        "progressivo": "1.5.2-57",
        "ris_3": "F",
        "risposta_3": "la manovra per dare la precedenza sia fatta lentamente in modo da non cogliere di sorpresa l'unità avente diritto di precedenza.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "id": 724,
        "immagine": "",
        "argomento": "Prevenire gli abbordi in mare",
        "risposta_2": "la manovra per dare la precedenza sia fatta entro 1 miglio dal punti di probabile collisione.",
        "risposta_1": "la manovra per dare la precedenza sia decisa, tempestiva ed evidente.",
        "ris_1": "V",
        "domanda": "Il Regolamento per Prevenire gli Abbordi in Mare prevede che:",
        "ris_2": "F"
    },
    {
        "risposta_3": "si trova nel raggio del fanale di coronamento di quella che la precede.",
        "immagine": "",
        "id": 725,
        "risposta_2": "raggiunge un'altra nave su una rotta di collisione.",
        "argomento": "Prevenire gli abbordi in mare",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "La \"nave raggiungente\" si riconosce di notte perchè:",
        "ris_3": "V",
        "risposta_1": "si trova nel raggio del fanale di via di quella che la precede.",
        "ris_2": "F",
        "progressivo": "1.5.2-58",
        "ris_1": "F"
    },
    {
        "domanda": "Prendendo il vento dallo stesso lato, quale delle due unità  a vela è tenuta ha lasciare libera rotta all'altra?",
        "immagine": 60,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_3": "F",
        "progressivo": "1.5.2-59",
        "id": 726,
        "ris_2": "F",
        "risposta_1": "l'unità A, che è sopravvento, deve lasciare libera la rotta all'unità B, che è sottovento.",
        "risposta_2": "l'unità B, che è sottovento.",
        "argomento": "Prevenire gli abbordi in mare",
        "risposta_3": "nessuna delle due.",
        "ris_1": "V"
    },
    {
        "risposta_3": "l'altezza dell'unità da diporto.",
        "ris_1": "V",
        "ris_3": "F",
        "domanda": "Quali sono gli elementi che influenzano la portata luminosa dei fari?",
        "risposta_1": "l'intensità luminosa della luce, la visibilità meteorologica e la sensibilità dell'occhio dell'osservatore.",
        "risposta_2": "il colore della luce e l'altezza della costruzione.",
        "immagine": "",
        "ris_2": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "progressivo": "1.5.3-1",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "id": 727
    },
    {
        "risposta_2": "dipende dall'altezza del faro.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "ris_3": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_1": "V",
        "progressivo": "1.5.3-2",
        "ris_2": "F",
        "immagine": "",
        "risposta_1": "corrisponde alla portata luminosa di una luce riferita ad una atmosfera omogenea in cui la visibilità meteorologica è pari a 10 miglia nautiche.",
        "id": 728,
        "domanda": "La portata nominale di un faro:",
        "risposta_3": "il colore della luce e l'altezza della costruzione."
    },
    {
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "progressivo": "1.5.3-3",
        "domanda": "Quali sono gli elementi che influenzano la portata geografica di un faro?",
        "ris_3": "F",
        "risposta_3": "il colore della luce e la potenza della lampada.",
        "ris_1": "F",
        "id": 729,
        "risposta_2": "la curvatura della terra, l'altezza della luce e l'elevazione dell'occhio dell'osservatore.",
        "immagine": "",
        "risposta_1": "l'intensità luminosa, la visibilità meteorologica e la sensibilità dell'occhio dell'osservatore.",
        "ris_2": "V"
    },
    {
        "risposta_2": "le alette delle imbarcazioni a vela.",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_1": "V",
        "ris_2": "F",
        "id": 73,
        "progressivo": "1.1.1-73",
        "domanda": "I flaps sono:",
        "immagine": "",
        "risposta_1": "appendici immerse, montate sullo specchio di poppa, per influire sull'assetto della carena.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "risposta_3": "non esistono, è un nome di fantasia.",
        "ris_3": "F"
    },
    {
        "ris_3": "F",
        "risposta_3": "dal cavo elettrico che porta la corrente nell'impianto.",
        "domanda": "Da cosa è rappresentata la \"fase\" di un segnalamento marittimo?",
        "immagine": "",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "progressivo": "1.5.3-4",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "ris_1": "F",
        "ris_2": "V",
        "id": 730,
        "risposta_2": "da ogni successivo elemento che compone un ciclo di una luce ritmica (lampo, eclissi).",
        "risposta_1": "dalla frequenza della corrente alternata di alimentazione."
    },
    {
        "progressivo": "1.5.3-5",
        "id": 731,
        "risposta_2": "un segnalamento luminoso galleggiante vincolato al fondo marino.",
        "ris_2": "V",
        "ris_3": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": "",
        "domanda": "Cosa si intende per \"boa luminosa\"?",
        "risposta_1": "un segnalamento sottomarino.",
        "risposta_3": "un segnalamento luminoso alla deriva.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "ris_1": "F"
    },
    {
        "risposta_1": "i limiti delle acque balneabili.",
        "risposta_3": "i corridoi di lancio delle moto d'acqua.",
        "progressivo": "1.5.3-6",
        "id": 732,
        "immagine": "",
        "ris_3": "F",
        "ris_1": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "ris_2": "V",
        "domanda": "Cosa segnalano, di massima, le boe luminose?",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_2": "i limiti dei canali navigabili, pericoli afferenti la sicurezza della navigazione e specchi acquei di particolare interesse."
    },
    {
        "risposta_2": "pericolo, 3 scogli affioranti.",
        "ris_1": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": "",
        "ris_3": "V",
        "domanda": "La descrizione \"Sc.(3)\" identifica un segnalamento luminoso:",
        "progressivo": "1.5.3-7",
        "risposta_3": "scintillante, a gruppi di 3.",
        "id": 733,
        "risposta_1": "a luce alternata trifase.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "ris_2": "F"
    },
    {
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_1": "F",
        "ris_3": "V",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_2": "a lampi brevi di colore rosso.",
        "id": 734,
        "ris_2": "F",
        "progressivo": "1.5.3-8",
        "risposta_3": "a luce alternata, che mostra alternativamente i colori bianco e rosso.",
        "risposta_1": "che indica di fermarsi immediatamente.",
        "domanda": "La descrizione \"Alt. b.r.\" identifica un segnalamento luminoso:",
        "immagine": ""
    },
    {
        "ris_2": "F",
        "risposta_3": "a luce alternata di due colori diversi.",
        "ris_3": "F",
        "ris_1": "V",
        "risposta_1": "intermittente, a gruppi di eclissi di 2.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "La descrizione \"Int.(2)\" identifica un segnalamento luminoso:",
        "id": 735,
        "risposta_2": "in acque internazionali.",
        "progressivo": "1.5.3-9",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "immagine": ""
    },
    {
        "risposta_3": "un dispositivo, che può essere sistemato anche sui segnalamenti, consentendo di riflettere in maniera passiva gli impulsi emessi dai radar.",
        "risposta_1": "una luce bianca che identifica una stazione radar costiera.",
        "ris_1": "F",
        "risposta_2": "un dispositivo radar situato esclusivamente in mare.",
        "immagine": "",
        "progressivo": "1.5.3-10",
        "domanda": "Come si può definire un \"riflettore radar \"?",
        "ris_2": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "id": 736,
        "ris_3": "V"
    },
    {
        "domanda": "Cosa indica la seguente sigla alfanumerica posta in prossimità del faro di Capo Negro dell'Isola di Zannone Fl(3) 10s 37m 12M?",
        "risposta_3": "che emette una luce fissa di 3 secondi, la cui struttura ha un'altezza rispetto al livello medio del mare di 10 metri, ed è visibile ad una portata nominale di 12 miglia nautiche.",
        "ris_2": "V",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_1": "che emette una luce flebile di intenistà 3, la cui struttura ha un'altezza rispetto al livello medio del mare di 10 metri, ed è visibile ad una portata nominale di 37,12 miglia nautiche.",
        "immagine": "",
        "progressivo": "1.5.3-11",
        "ris_1": "F",
        "id": 737,
        "ris_3": "F",
        "risposta_2": "che emette una luce lampeggiante a gruppi di 3 lampi in 10 secondi, la luce ha un'altezza rispetto al livello medio del mare di 37 metri, ed è visibile ad una portata nominale di 12 miglia nautiche."
    },
    {
        "domanda": "Con riferimento al sistema di segnalamento AISM-IALA, in quali contesti marittimi è utilizzato il segnale di fianco rappresentato?",
        "ris_2": "V",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "ris_1": "F",
        "risposta_3": "è un il segnale cardinale che indicala zona posta a sud di un pericolo isolato.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_2": "è un \"segnale speciale\" utilizzato per indicare particolari configurazioni o situazioni della costa non riportate sulla carta nautica.",
        "progressivo": "1.5.3-12",
        "ris_3": "F",
        "immagine": 61,
        "id": 738,
        "risposta_1": "è un segnale che indica la zona di mare in\nsicurezza di un canale navigabile."
    },
    {
        "id": 739,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_1": "è un riflettore radar utilizzato in caso di nebbia.",
        "risposta_2": "è un radio segnalamento marittimo costituto da una \"boa con apparato radar- risponditore\".",
        "ris_2": "F",
        "ris_1": "F",
        "progressivo": "1.5.3-13",
        "risposta_3": "è un segnale da nebbia costituito da una \"boa con campana azionata dalle onde \".",
        "immagine": 62,
        "domanda": "Cosa indica su una carta nautica il simbolo di fianco rappresentato?",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "ris_3": "V"
    },
    {
        "immagine": "",
        "capitolo": "TEORIA DELLO SCAFO",
        "id": 74,
        "progressivo": "1.1.1-74",
        "ris_2": "F",
        "domanda": "I flaps:",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "risposta_1": "sono montati sullo specchio di poppa, sono sempre due, uno sull’estremità destra e l’altro sull’estremità sinistra.",
        "ris_3": "F",
        "risposta_2": "sono montati uno sullo specchio di poppa e l'altro subito al di sotto della prua.",
        "ris_1": "V",
        "risposta_3": "sono sempre due, montati subito al di sotto della prua."
    },
    {
        "risposta_2": "che la boa luminosa, facente parte dei segnali cardinali del sistema AISM-IALA, emette luci scintillanti a gruppi di 9 ogni 15 secondi, ed è visibile a una portata nominale di 5 miglia nautiche.",
        "risposta_1": "che la boa luminosa, facente parte dei segnali cardinali del sistema AISM-IALA, emette 15 lampi ogni 9 secondi, ed è visibile a una portata nominale di 5 miglia nautiche.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "che la boa luminosa, facente parte dei segnali cardinali del sistema AISM-IALA, emette 15 lampi ogni 5 minuti, ed è visibile a una portata geografica di 9 miglia nautiche.",
        "domanda": "Con riferimento alle caratteristiche dei segnalamenti marittimi riportate in una carta nautica, cosa indica la sigla alfanumerica posta lateralmente alla boa luminosa di fianco rappresentata?",
        "progressivo": "1.5.3-14",
        "ris_3": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "id": 740,
        "ris_2": "V",
        "ris_1": "F",
        "immagine": 63
    },
    {
        "domanda": "Cosa indica il segnale marittimo rappresentato in figura?",
        "risposta_2": "un segnale cardinale del sistema di segnalamento marittimo AISM-IALA.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_2": "F",
        "ris_3": "V",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_1": "un segnale di acque sicure del sistema di segnalamento marittimo AISM-IALA.",
        "progressivo": "1.5.3-15",
        "ris_1": "F",
        "immagine": 64,
        "risposta_3": "un segnale di pericolo isolato del sistema di segnalamento marittimo AISM-IALA.",
        "id": 741
    },
    {
        "ris_3": "F",
        "progressivo": "1.5.3-16",
        "risposta_3": "colore rosso con banda(e) orizzontale nera.",
        "ris_1": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "id": 742,
        "risposta_2": "colore nero con banda(e) orizzontale rossa.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_2": "V",
        "domanda": "Di che colore è il corpo del segnale marittimo riportato sulla carta nautica e rappresentato in figura?",
        "risposta_1": "colore bianco con banda(e) orizzontale rossa.",
        "immagine": 65
    },
    {
        "ris_2": "V",
        "risposta_1": "un radiofaro circolare marittimo o aeromarittimo.",
        "domanda": "Cosa indica il segnale marittimo rappresentato in figura?",
        "ris_1": "F",
        "id": 743,
        "ris_3": "F",
        "risposta_3": "un trasmettitore con sistema automatico d'identificazione.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "progressivo": "1.5.3-17",
        "risposta_2": "un risponditore radar con corno che emette un segnale sonoro da nebbia.",
        "immagine": 66
    },
    {
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_1": "una boa sferica luminosa.",
        "progressivo": "1.5.3-18",
        "immagine": 67,
        "ris_1": "V",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_2": "F",
        "risposta_2": "una boa conica luminosa.",
        "id": 744,
        "domanda": "Cosa indica il segnale marittimo rappresentato in figura?",
        "ris_3": "F",
        "risposta_3": "una boa cilindrica luminosa."
    },
    {
        "ris_2": "F",
        "ris_3": "V",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Di che colore è il corpo del segnale marittimo rappresentato in figura?",
        "progressivo": "1.5.3-19",
        "risposta_2": "rosso.",
        "ris_1": "F",
        "id": 745,
        "risposta_3": "giallo.",
        "immagine": 67,
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_1": "bianco."
    },
    {
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_2": "5 lampi gialli ogni 20 secondi con portata nominale di 3 miglia nautiche.",
        "ris_1": "F",
        "id": 746,
        "progressivo": "1.5.3-20",
        "ris_2": "V",
        "domanda": "Cosa indica la caratteristica del segnale marittimo rappresentato in figura?",
        "risposta_1": "20 lampi gialli ogni 5 secondi con visibilità di 3 miglia nautiche.",
        "risposta_3": "1 lampo giallo ogni 5 secondi con portata geografica di 3 miglia nautiche.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "immagine": 67,
        "ris_3": "F"
    },
    {
        "domanda": "Cosa indica il segnale marittimo rappresentato in figura?",
        "risposta_1": "boa luminosa a fuso.",
        "progressivo": "1.5.3-21",
        "risposta_3": "boa luminosa cilindrica.",
        "immagine": 68,
        "ris_2": "F",
        "id": 747,
        "ris_1": "V",
        "risposta_2": "boa luminosa ad asta.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_3": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA"
    },
    {
        "risposta_3": "giallo.",
        "id": 748,
        "domanda": "Di che colore è il corpo del segnale marittimo rappresentato in figura?",
        "risposta_1": "bianco.",
        "ris_3": "V",
        "ris_1": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "progressivo": "1.5.3-22",
        "ris_2": "F",
        "immagine": 68,
        "risposta_2": "nero.",
        "argomento": "I principali fanali luminosi e il sistema IALA"
    },
    {
        "risposta_3": "5 lampi gialli ogni 3 secondi con portata geografica di 20 miglia nautiche.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_1": "5 lampi gialli ogni 20 secondi con portata nominale di 3 miglia nautiche.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": 68,
        "domanda": "Cosa indica la caratteristica del segnale marittimo rappresentato in figura?",
        "ris_1": "V",
        "progressivo": "1.5.3-23",
        "risposta_2": "1 lampo giallo ogni 20 secondi con visibilità di 3 miglia nautiche.",
        "ris_3": "F",
        "ris_2": "F",
        "id": 749
    },
    {
        "risposta_3": "realizzato attraverso il monitoraggio di un display, normalmente posto sulla plancia, analogico o digitale, che indica la loro altezza.",
        "capitolo": "TEORIA DELLO SCAFO",
        "progressivo": "1.1.1-75",
        "risposta_1": "manuale, effettuato tramite dei leveraggi disposti nella zona poppiera dell'unità.",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "domanda": "Il controllo dei flaps è solitamente:",
        "ris_3": "V",
        "immagine": "",
        "risposta_2": "regolato dal cantiere all'atto della prima messa in acqua.",
        "id": 75,
        "ris_1": "F",
        "ris_2": "F"
    },
    {
        "ris_1": "V",
        "ris_2": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "id": 750,
        "progressivo": "1.5.3-24",
        "risposta_3": "una boa luminosa a fuso avente come miraglio un Racon.",
        "ris_3": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "domanda": "Cosa indica il segnale marittimo rappresentato in figura?",
        "risposta_2": "una boa luminosa ad asta avente come miraglio un Racon.",
        "immagine": 69,
        "risposta_1": "una boa luminosa a fuso avente come miraglio un riflettore radar."
    },
    {
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "ris_1": "F",
        "ris_3": "V",
        "progressivo": "1.5.3-25",
        "domanda": "Con riferimento allo stralcio di carta nautica Q11, di che colore è la luce del segnale marittimo rappresentato in figura?",
        "id": 751,
        "immagine": 69,
        "risposta_3": "bianco.",
        "ris_2": "F",
        "risposta_2": "giallo.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_1": "rosso."
    },
    {
        "id": 752,
        "risposta_3": "lampo ogni 7 secondi con portata geografica di 3 miglia nautiche.",
        "risposta_2": "un lampo ogni 3 secondi con portata nominale di 7 miglia nautiche.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "ris_1": "F",
        "ris_3": "F",
        "domanda": "Cosa indica la caratteristica del segnale marittimo rappresentato in figura?",
        "ris_2": "V",
        "progressivo": "1.5.3-26",
        "risposta_1": "3 lampi ogni 3 secondi con visibilità di 7 miglia nautiche.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": 69
    },
    {
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_1": "V",
        "id": 753,
        "risposta_1": "da quale lato della nave (dritta o sinistra) deve essere lasciato il segnale secondo il senso convenzionale del segnalamento.",
        "risposta_3": "un certo tipo di canalizzazione del traffico.",
        "ris_2": "F",
        "progressivo": "1.5.3-27",
        "domanda": "Un \"segnale laterale\" è quel segnale marittimo che indica:",
        "risposta_2": "il lato su cui devono procedere le navi che entrano o escono dal porto.",
        "ris_3": "F",
        "immagine": "",
        "argomento": "I principali fanali luminosi e il sistema IALA"
    },
    {
        "immagine": "",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "id": 754,
        "progressivo": "1.5.3-28",
        "domanda": "La portata geografica è la:",
        "risposta_2": "massima distanza alla quale è visibile la luce.",
        "ris_1": "F",
        "risposta_1": "portata luminosa in un'atmosfera omogenea con \"visibilità meteorologica\" di 10 miglia.",
        "ris_2": "F",
        "risposta_3": "distanza alla quale la luce di un faro può essere vista in relazione alla curvatura della Terra e all'altezza dell'osservatore.",
        "ris_3": "V"
    },
    {
        "immagine": "",
        "ris_1": "F",
        "progressivo": "1.5.3-29",
        "domanda": "I Fanali sono:",
        "ris_3": "F",
        "risposta_1": "sorgenti luminose capaci di essere rilevate sempre anche dai radar.",
        "risposta_2": "sorgenti luminose che segnalano entrate dei porti, boe, pericoli, canali navigabili, piattaforme, ecc.",
        "ris_2": "V",
        "id": 755,
        "risposta_3": "impianti di illuminazione fissa dei porti o piattaforme petrolifere.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "argomento": "I principali fanali luminosi e il sistema IALA"
    },
    {
        "immagine": "",
        "id": 756,
        "ris_1": "V",
        "risposta_2": "distanza d'avvistamento della sorgente luminosa in funzione della curvatura terrestre.",
        "ris_3": "F",
        "risposta_1": "massima distanza alla quale è visibile la luce di un segnalamento in funzione della sua intensità luminosa e della visibilità meteorologica.",
        "risposta_3": "portata luminosa in un'atmosfera omogenea con \"visibilità meteorologica\" di 10 miglia.",
        "progressivo": "1.5.3-30",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "domanda": "La portata luminosa è la:",
        "ris_2": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO"
    },
    {
        "ris_1": "F",
        "risposta_1": "distanza d'avvistamento della sorgente luminosa in funzione della curvatura terrestre.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "id": 757,
        "domanda": "La portata nominale è la:",
        "risposta_3": "massima distanza alla quale è visibile la luce.",
        "risposta_2": "portata luminosa in un'atmosfera omogenea con \"visibilità meteorologica\" di 10 miglia.",
        "ris_2": "V",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "immagine": "",
        "progressivo": "1.5.3-31",
        "ris_3": "F"
    },
    {
        "ris_1": "V",
        "risposta_2": "il Sistema B (rosso a dx).",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "id": 758,
        "ris_2": "F",
        "ris_3": "F",
        "progressivo": "1.5.3-32",
        "domanda": "In base al sistema di segnalamento marittimo IALA, quale dei due Sistemi prescritti è adottato nel Mar Mediterraneo?",
        "risposta_1": "il Sistema A (rosso a sx).",
        "immagine": "",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "il Sistema C (bianco a dx e a sx)."
    },
    {
        "ris_3": "V",
        "progressivo": "1.5.3-33",
        "domanda": "Il segnale AISM - IALA regione A, in figura, è un segnale:",
        "id": 759,
        "risposta_1": "cardinale che indica di passare a Ovest dello stesso perché il pericolo è ad Est.",
        "ris_2": "F",
        "risposta_2": "cardinale che indica di passare a Est dello stesso perché il pericolo è ad Ovest.",
        "immagine": 70,
        "ris_1": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_3": "cardinale che indica di passare a Nord dello stesso perché il pericolo è a Sud."
    },
    {
        "ris_1": "F",
        "immagine": "",
        "ris_2": "F",
        "progressivo": "1.1.2-1",
        "risposta_3": "pale abbattibili.",
        "risposta_1": "pale orientabili.",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "domanda": "Tra un'elica a passo fisso, una a pale abbattibili ed una a pale orientabili, l'elica che  ha il minor rendimento a marcia indietro è quella a:",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_2": "pale fisse.",
        "id": 76,
        "ris_3": "V"
    },
    {
        "risposta_3": "3 luci bianche fisse verticali, periodo 10 secondi.",
        "ris_3": "F",
        "immagine": "",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_2": "3 lampi bianchi, periodo 10 secondi.",
        "id": 760,
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "progressivo": "1.5.3-34",
        "ris_1": "F",
        "risposta_1": "scintillante, gruppi di 3 lampi bianchi, periodo 10 secondi.",
        "domanda": "L'abbreviazione in inglese \"Fl (3) W 10s\", che si trova nell'elenco dei Fari e segnali da nebbia edito in Italiano dall'I.I.M.M., significa:",
        "ris_2": "V"
    },
    {
        "ris_1": "F",
        "immagine": "",
        "progressivo": "1.5.3-35",
        "ris_2": "V",
        "risposta_1": "faro di secondo tipo internazionale; periodo: 10 secondi; portata geografica: 26 miglia; portata luminosa: 20 miglia.",
        "risposta_3": "2 intermittenze; colore\nindeterminato; periodo: 10 secondi; elevazione luce sul l.m.m.: 26 metri; portata nominale: 20 miglia.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_2": "2 intermittenze; luce bianca; periodo: 10 secondi; elevazione luce sul l.m.m.: 26 metri; portata nominale: 20 miglia.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "id": 761,
        "domanda": "Cosa significa \"Int (2) 10s 26m 20M\"?",
        "ris_3": "F"
    },
    {
        "domanda": "Il segnale cardinale indica:",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_1": "il lato N, E, S o W su cui transitare rispetto lo stesso segnale per evitare il pericolo.",
        "ris_1": "V",
        "immagine": "",
        "id": 762,
        "ris_2": "F",
        "risposta_2": "la rotta per allontanarsi dal pericolo indicata dal segnale.",
        "progressivo": "1.5.3-36",
        "risposta_3": "il lato dritto o sinistro su cui transitare rispetto lo stesso segnale per evitare il pericolo.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_3": "F"
    },
    {
        "id": 763,
        "ris_3": "F",
        "risposta_2": "occultato bianco per 5 secondi (in ogni periodo).",
        "ris_2": "F",
        "immagine": "",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "progressivo": "1.5.3-37",
        "ris_1": "V",
        "domanda": "L'abbreviazione in inglese \"Oc (3) W 5s\", che si trova nell'elenco dei Fari e segnali da nebbia edito in Italiano dall'I.I.M.M., significa:",
        "risposta_1": "intermittente bianco, periodo 5 secondi.",
        "risposta_3": "1 lampo bianco, periodo 5 secondi.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO"
    },
    {
        "ris_1": "V",
        "progressivo": "1.5.3-38",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_2": "dura esattamente quanto la metà dell'eclisse.",
        "risposta_1": "ha la stessa durata dell'intervallo.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_3": "F",
        "domanda": "In un faro \"ISO\", la luce:",
        "risposta_3": "ha la durata doppia dell'intervallo.",
        "ris_2": "F",
        "immagine": "",
        "id": 764
    },
    {
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "ris_1": "F",
        "ris_3": "F",
        "risposta_3": "elevazione della luce sul\nl.m.m. e dalla elevazione dell'occhio dell'osservatore.",
        "domanda": "La portata luminosa di una faro è influenzata dalla:",
        "risposta_1": "elevazione dell'occhio dell'osservatore e dalla trasparenza dell'atmosfera al memento considerato.",
        "id": 765,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "progressivo": "1.5.3-39",
        "risposta_2": "trasparenza dell'atmosfera al momento considerato e dalla potenza della lampada espressa in candele.",
        "ris_2": "V",
        "immagine": ""
    },
    {
        "immagine": 71,
        "ris_1": "F",
        "risposta_2": "speciale.",
        "risposta_3": "di pericolo isolato.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_2": "F",
        "progressivo": "1.5.3-40",
        "id": 766,
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_1": "di acque sicure.",
        "domanda": "Il segnale AISM - IALA regione A, in figura, è un segnale:",
        "ris_3": "V"
    },
    {
        "domanda": "Accanto al simbolo di un faro sulla carta nautica sono riportate le seguenti indicazioni: \"Lam (2) 8s 30m 11M\". Cosa esprime questo faro?",
        "risposta_3": "2 lampi bianchi; periodo: 8 secondi; elevazione luce sul l.m.m.: 30 metri; portata nominale: 11 miglia.",
        "ris_3": "V",
        "risposta_1": "2 lampi di colore indeterminato; periodo: 8 secondi; elevazione luce sul l.m.m.: 30 metri; portata nominale: 11 miglia.",
        "progressivo": "1.5.3-41",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_2": "faro di seconda categoria; 8 lampi nel periodo; portata geografica: 30 miglia; portata luminosa: 11 miglia.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "ris_1": "F",
        "ris_2": "F",
        "immagine": "",
        "id": 767
    },
    {
        "ris_3": "F",
        "id": 768,
        "progressivo": "1.5.3-42",
        "domanda": "Il \"segnale speciale\" ha la funzione di indicare:",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_1": "l'assistenza alle attività di pesca.",
        "risposta_3": "l'assistenza alla navigazione.",
        "ris_2": "V",
        "risposta_2": "una zona speciale per attività particolari (p.e. presenza di cavi o condutture sottomarine, zone riservate al diporto nautico, presenza di stazioni per raccolta di dati oceanografici etc.).",
        "ris_1": "F",
        "immagine": ""
    },
    {
        "id": 769,
        "ris_2": "F",
        "ris_3": "F",
        "domanda": "Nel sistema laterale AISM - IALA della Regione A, i segnalamenti da tenere sul lato sinistro entrando in un porto sono quelli di colore:",
        "ris_1": "V",
        "immagine": "",
        "risposta_1": "rosso, forma cilindrica e miraglio cilindrico.",
        "risposta_3": "verde, forma cilindrica e miraglio cilindrico.",
        "risposta_2": "rosso, forma conica e miraglio conico.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "progressivo": "1.5.3-43"
    },
    {
        "ris_3": "F",
        "domanda": "In retromarcia con un'unica elica destrorsa:",
        "ris_2": "F",
        "progressivo": "1.1.2-2",
        "id": 77,
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "immagine": "",
        "ris_1": "V",
        "risposta_1": "la poppa ruota più facilmente a sinistra.",
        "risposta_2": "la poppa ruota più facilmente a dritta.",
        "risposta_3": "è indifferente.",
        "capitolo": "TEORIA DELLO SCAFO"
    },
    {
        "ris_1": "V",
        "ris_2": "F",
        "domanda": "Per ogni segnale marittimo, gli elementi di codificazione diurna che ne danno il significato sono:",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "solo la forma della boa.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_1": "la forma ed il colore della boa oppure la forma ed colore del miraglio.",
        "progressivo": "1.5.3-44",
        "risposta_2": "solo la forma del miraglio.",
        "id": 770,
        "immagine": "",
        "ris_3": "F"
    },
    {
        "risposta_2": "lampeggiante verde periodo 5 secondi.",
        "ris_3": "V",
        "id": 771,
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "progressivo": "1.5.3-45",
        "domanda": "L'abbreviazione in inglese \"Fl G 5s\", che si trova nell'elenco dei Fari e segnali da nebbia edito in Italiano dall'I.I.M.M., significa:",
        "immagine": "",
        "ris_2": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_1": "1 lampo giallo, periodo 5 secondi.",
        "risposta_3": "1 lampo verde, periodo 5 secondi.",
        "ris_1": "F"
    },
    {
        "id": 772,
        "risposta_3": "sì, nei segnali di pericolo isolato.",
        "risposta_2": "sì, nei segnali di acque sicure.",
        "ris_1": "V",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_2": "F",
        "domanda": "Nel sistema AISM - IALA vi sono differenze tra le regioni A e B per quanto riguarda i segnali speciali, di acque sicure e di pericolo isolato?",
        "risposta_1": "no, non vi sono differenze; differenze vi sono solo nel sistema laterale.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "progressivo": "1.5.3-46",
        "immagine": "",
        "ris_3": "F"
    },
    {
        "domanda": "Nella caratteristica del faro, il \"periodo\" è l'intervallo di tempo:",
        "ris_2": "F",
        "progressivo": "1.5.3-47",
        "ris_3": "V",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_3": "durante il quale si ripete ciclicamente la sequenza di lampi ed eclissi della caratteristica del faro.",
        "risposta_2": "tra due lampi successivi.",
        "ris_1": "F",
        "id": 773,
        "risposta_1": "tra due eclissi successive.",
        "immagine": "",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO"
    },
    {
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Accanto al simbolo di un faro sulla carta nautica sono riportate le seguenti indicazioni: \"Lam (2) 12s 27m 17M\". Cosa esprime questo faro?",
        "ris_1": "F",
        "risposta_2": "luce a lampi, 2 lampi in 12 secondi di periodo, costruzione alta 27 metri, 17 miglia di portata geografica.",
        "ris_3": "V",
        "risposta_1": "luce a lampi, periodo 12 secondi di cui 2 secondi di luce, luce alta 17 metri sul l.m.m., portata nominale 27 miglia.",
        "id": 774,
        "risposta_3": "luce a lampi, 2 lampi in 12 secondi di periodo, luce alta 27 metri sul l.m.m., 17 miglia di portata nominale.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "ris_2": "F",
        "progressivo": "1.5.3-48",
        "immagine": ""
    },
    {
        "progressivo": "1.5.3-49",
        "risposta_1": "laterali, cardinali, di pericolo isolato, di acque sicure, speciali.",
        "id": 775,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_2": "F",
        "risposta_2": "laterali, cardinali N, cardinali S, di pericolo isolato, speciali.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "immagine": "",
        "domanda": "Quali tipi di segnali marittimi prevede il sistema AISM - IALA?",
        "ris_1": "V",
        "risposta_3": "cardinali, laterali A, laterali B, di acque sicure, speciali.",
        "ris_3": "F"
    },
    {
        "ris_1": "F",
        "ris_3": "F",
        "id": 776,
        "progressivo": "1.5.3-50",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "rosso.",
        "ris_2": "V",
        "immagine": "",
        "risposta_1": "giallo con una banda nera.",
        "risposta_2": "nero con una o più bande orizzontali rosse.",
        "domanda": "Una meda che indica pericolo isolato è di colore:"
    },
    {
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_3": "alla bussola e i colori sono il nero ed il giallo.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_1": "al \"senso convenzionale del segnalamento\".",
        "ris_3": "V",
        "immagine": "",
        "ris_2": "F",
        "ris_1": "F",
        "risposta_2": "alle direzioni cardinali ed i colori sono il rosso o il verde.",
        "domanda": "L'impiego dei segnali cardinali è associato:",
        "progressivo": "1.5.3-51",
        "id": 777
    },
    {
        "risposta_3": "cardinale che indica di passare a Sud dello stesso perché il pericolo è a Nord.",
        "immagine": 72,
        "risposta_2": "cardinale che indica di passare a Ovest dello stesso perché il pericolo è ad Est.",
        "progressivo": "1.5.3-52",
        "ris_1": "V",
        "ris_2": "F",
        "id": 778,
        "domanda": "Il segnale AISM - IALA regione A, in figura, è un segnale:",
        "ris_3": "F",
        "risposta_1": "cardinale che indica di passare a Est dello stesso perché il pericolo è ad Ovest.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "argomento": "I principali fanali luminosi e il sistema IALA"
    },
    {
        "ris_3": "V",
        "ris_1": "F",
        "immagine": "",
        "risposta_2": "è unico a forma di sfera ed è di colore giallo.",
        "progressivo": "1.5.3-53",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_3": "è unico a forma di \"X\" ed è di colore giallo.",
        "ris_2": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "id": 779,
        "domanda": "L'eventuale miraglio del segnale speciale:",
        "risposta_1": "è unico a forma di cono ed è di colore giallo."
    },
    {
        "ris_1": "F",
        "progressivo": "1.1.2-3",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "risposta_1": "non ha importanza, perché può essere indifferentemente sinistrorsa o destrorsa in quanto, in entrambi i casi, l'unità evoluisce sempre allo stesso moto ed alle medesime condizioni.",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_2": "V",
        "risposta_2": "è sinistrorsa.",
        "risposta_3": "tende a spostare la prua lateralmente verso sinistra.",
        "domanda": "Unità da diporto a motore con a dritta elica destrorsa; si può ragionevolmente ritenere che quella di sinistra:",
        "id": 78,
        "immagine": "",
        "ris_3": "F"
    },
    {
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "domanda": "Il segnale AISM - IALA regione A, in figura, è un segnale:",
        "progressivo": "1.5.3-54",
        "id": 780,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "cardinale che indica di passare a Ovest dello stesso perché il pericolo è ad Est.",
        "ris_2": "F",
        "ris_3": "V",
        "ris_1": "F",
        "risposta_1": "cardinale che indica di passare a Sud dello stesso perché il pericolo è a Nord.",
        "immagine": 73,
        "risposta_2": "cardinale che indica di passare a Est dello stesso perché il pericolo è ad Ovest."
    },
    {
        "id": 781,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Il segnale AISM - IALA regione A, in figura, è un segnale:",
        "ris_1": "F",
        "ris_2": "V",
        "ris_3": "",
        "risposta_1": "cardinale che indica di passare a Est dello stesso perché il pericolo è ad Ovest.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "immagine": 74,
        "risposta_3": "cardinale che indica di passare a Ovest dello stesso perché il pericolo è ad Est.",
        "risposta_2": "cardinale che indica di passare a Sud dello stesso perché il pericolo è a Nord.",
        "progressivo": "1.5.3-55"
    },
    {
        "domanda": "La \"caratteristica della luce\" del faro è:",
        "risposta_3": "l'insieme di \"tipo\", \"colore\" della luce e \"periodo\" che ne consentono l'identificazione.",
        "risposta_2": "la descrizione della costruzione che alloggia il segnalamento.",
        "ris_3": "V",
        "id": 782,
        "immagine": "",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "ris_1": "F",
        "ris_2": "F",
        "progressivo": "1.5.3-56",
        "risposta_1": "il colore della struttura del faro."
    },
    {
        "id": 783,
        "domanda": "Tra gli aspetti più distintivi di fari e fanali, normalmente:",
        "risposta_2": "i fanali producono luce di grande portata.",
        "risposta_3": "i fari permettono il riconoscimento costiero, i fanali segnalano opere portuali, pericoli vari e punti costieri di secondario interesse.",
        "risposta_1": "i fari segnalano le testate dei moli.",
        "ris_1": "F",
        "ris_3": "V",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "ris_2": "F",
        "progressivo": "1.5.3-57",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": ""
    },
    {
        "id": 784,
        "ris_1": "F",
        "risposta_1": "le mede sono aste piazzate sui promontori come punti cospicui.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_3": "V",
        "risposta_3": "i gavitelli sono piccoli galleggianti romboidali, per segnalazioni temporanee.",
        "domanda": "Riguardo ai segnalamenti diurni:",
        "ris_2": "F",
        "risposta_2": "i dromi sono galleggianti parallelepipedi, spesso muniti di miraglio.",
        "progressivo": "1.5.3-58",
        "immagine": ""
    },
    {
        "ris_2": "F",
        "ris_3": "V",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_2": "cardinale.",
        "id": 785,
        "ris_1": "F",
        "risposta_3": "di acque sicure.",
        "risposta_1": "di pericolo isolato.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "immagine": "",
        "domanda": "A proposito di segnalamento marittimo AISM - IALA, una luce bianca, isofase, intermittente o a lampi lunghi o riproducente la lettera A (Alfa) dell'alfabeto Morse, è un segnale:",
        "progressivo": "1.5.3-59"
    },
    {
        "id": 786,
        "risposta_1": "è possibile emettere luci di colore differenziato per dati settori di visibilità.",
        "immagine": "",
        "risposta_2": "la luce verde viene impiegata dalla nave per segnalare il diritto di precedenza nei canali navigabili.",
        "ris_1": "V",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "domanda": "Con riferimento alla luce emessa da un segnalamento:",
        "ris_3": "F",
        "risposta_3": "la luce verde viene indicata nell'abbreviazione italiana con G.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_2": "F",
        "progressivo": "1.5.3-60"
    },
    {
        "ris_2": "V",
        "risposta_3": "per il colore della luce.",
        "immagine": "",
        "progressivo": "1.5.3-61",
        "domanda": "Faro e fanale differiscono:",
        "risposta_1": "per il diametro in pollici della sorgente luminosa.",
        "risposta_2": "per la portata nominale.",
        "ris_3": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_1": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "id": 787
    },
    {
        "immagine": "",
        "ris_3": "F",
        "progressivo": "1.5.3-62",
        "ris_1": "F",
        "risposta_3": "una boa.",
        "id": 788,
        "risposta_2": "una costruzione o un palo fisso sul fondo del mare che emerge.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_1": "un tipo di faro.",
        "ris_2": "V",
        "domanda": "La meda è:"
    },
    {
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_2": "V",
        "risposta_1": "se la nominale è superiore alla geografica, si avvisteranno le \"spazzate\" di luce sopra l’orizzonte.",
        "risposta_3": "la portata geografica dipende dall’altezza della sorgente e dall’intensità della luce.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "id": 789,
        "ris_3": "F",
        "immagine": "",
        "risposta_2": "la portata nominale è quella luminosa, in un’atmosfera con visibilità meteorologica di 10 miglia.",
        "progressivo": "1.5.3-63",
        "ris_1": "F",
        "domanda": "Con riferimento alla portata di un segnalamento:"
    },
    {
        "risposta_3": "è indifferente.",
        "risposta_2": "la poppa ruota più facilmente a dritta.",
        "immagine": "",
        "capitolo": "TEORIA DELLO SCAFO",
        "domanda": "In retromarcia con un'unica elica sinistrorsa:",
        "risposta_1": "la poppa ruota più facilmente a sinistra.",
        "ris_2": "V",
        "ris_1": "F",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "id": 79,
        "ris_3": "F",
        "progressivo": "1.1.2-4"
    },
    {
        "risposta_2": "segnale di pericolo isolato.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_2": "V",
        "id": 790,
        "ris_1": "F",
        "risposta_3": "segnale di acque sicure.",
        "domanda": "A proposito di segnalamento marittimo AISM - IALA, una luce bianca, a lampi (durata della luce inferiore a quella dell'eclisse), è un:",
        "progressivo": "1.5.3-64",
        "ris_3": "F",
        "risposta_1": "segnale speciale.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "immagine": ""
    },
    {
        "domanda": "L'eventuale miraglio del segnale di acque sicure è costituito da:",
        "ris_3": "V",
        "immagine": "",
        "risposta_1": "due sfere nere sovrapposte.",
        "ris_1": "F",
        "risposta_2": "due sfere rosse sovrapposte.",
        "risposta_3": "una sfera rossa.",
        "progressivo": "1.5.3-65",
        "id": 791,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_2": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA"
    },
    {
        "risposta_3": "due coni sovrapposti uniti per le rispettive basi.",
        "ris_1": "V",
        "risposta_1": "due coni sovrapposti con i rispettivi vertici rivolti verso l'alto.",
        "progressivo": "1.5.3-66",
        "id": 792,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_2": "due coni sovrapposti con i rispettivi vertici rivolti verso il basso.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "domanda": "Il miraglio del segnale cardinale Nord è costituito da:",
        "immagine": "",
        "ris_2": "F",
        "ris_3": "F"
    },
    {
        "ris_1": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "il lato ove si trova un pericolo e, di conseguenza, dove navigare in sicurezza.",
        "risposta_2": "le zone interdette alla navigazione.",
        "progressivo": "1.5.3-67",
        "ris_2": "F",
        "domanda": "I segnali cardinali indicano:",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_1": "il settore dal quale provengono, in genere, le avverse condimeteo in quella zona.",
        "id": 793,
        "immagine": "",
        "ris_3": "V"
    },
    {
        "risposta_1": "faro isofase con altezza di 18 m sul livello del mare.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Cosa significa la seguente indicazione \"F.r. 18M\" per un faro?",
        "ris_3": "F",
        "risposta_2": "faro a luce fissa di colore rossa con portata nominale di 18 miglia.",
        "ris_1": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_3": "faro lampeggiante con lampeggio ad intervalli regolari di altezza e portata nominale pari a 18 miglia.",
        "progressivo": "1.5.3-68",
        "id": 794,
        "immagine": "",
        "ris_2": "V"
    },
    {
        "ris_2": "F",
        "immagine": "",
        "ris_3": "V",
        "progressivo": "1.5.3-69",
        "risposta_2": "due coni sovrapposti con i rispettivi vertici rivolti verso il basso.",
        "ris_1": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "domanda": "Il miraglio del segnale cardinale Est è costituito da:",
        "risposta_1": "due coni sovrapposti con i rispettivi vertici rivolti verso l'alto.",
        "risposta_3": "due coni sovrapposti uniti per le rispettive basi.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "id": 795
    },
    {
        "ris_1": "F",
        "ris_2": "F",
        "ris_3": "V",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Il miraglio del segnale cardinale Sud è costituito da:",
        "risposta_1": "due coni sovrapposti uniti per i rispettivi vertici.",
        "risposta_2": "due coni sovrapposti uniti per le rispettive basi.",
        "risposta_3": "due coni sovrapposti con i rispettivi vertici rivolti verso il basso.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "progressivo": "1.5.3-70",
        "id": 796,
        "immagine": ""
    },
    {
        "ris_1": "V",
        "risposta_2": "di passare a sud del segnale (il pericolo è a nord).",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_2": "F",
        "domanda": "Una boa con miraglio formato da due coni neri uniti per il vertice segnala:",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "immagine": "",
        "risposta_3": "di passare a est del segnale (il pericolo è a ovest).",
        "id": 797,
        "risposta_1": "di passare a ovest del segnale (il pericolo è a est).",
        "ris_3": "F",
        "progressivo": "1.5.3-71"
    },
    {
        "risposta_1": "di passare a est del segnale (il pericolo è a ovest).",
        "id": 798,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Una boa con miraglio formato da due coni neri uniti per la base segnala:",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "ris_1": "V",
        "immagine": "",
        "risposta_2": "di passare a ovest del segnale (il pericolo è a est).",
        "ris_3": "F",
        "ris_2": "F",
        "progressivo": "1.5.3-72",
        "risposta_3": "di passare a sud del segnale (il pericolo è a nord)."
    },
    {
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_2": "F",
        "id": 799,
        "progressivo": "1.5.3-73",
        "domanda": "Il miraglio del segnale cardinale Ovest è costituito da:",
        "immagine": "",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_3": "due coni sovrapposti uniti per i rispettivi vertici.",
        "ris_3": "V",
        "ris_1": "F",
        "risposta_2": "due coni sovrapposti con i rispettivi vertici rivolti verso il basso.",
        "risposta_1": "due coni sovrapposti uniti per le rispettive basi."
    },
    {
        "id": 8,
        "ris_3": "F",
        "risposta_1": "la porzione esterna e superiore della poppa.",
        "ris_2": "F",
        "immagine": "",
        "risposta_3": "la superficie verticale interna",
        "progressivo": "1.1.1-8",
        "ris_1": "V",
        "domanda": "Cosa si intende per specchio di poppa di un'unità?",
        "capitolo": "TEORIA DELLO SCAFO",
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "risposta_2": "la paratia interna che separa gli organi del timone dal resto delle cabine."
    },
    {
        "risposta_2": "entrambe sinistrorse.",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "domanda": "Su un'unità munita di due motori, le eliche generalmente sono:",
        "ris_2": "F",
        "risposta_3": "entrambe destrorse.",
        "id": 80,
        "capitolo": "TEORIA DELLO SCAFO",
        "immagine": "",
        "ris_3": "F",
        "ris_1": "V",
        "risposta_1": "sinistrorsa a sinistra e destrorsa a dritta.",
        "progressivo": "1.1.2-5"
    },
    {
        "risposta_1": "boa a fuso oppure asta di colore nero con una o più fasce larghe rosse orizzontali.",
        "domanda": "Il segnale AISM - IALA regione A, di pericolo isolato è indicato con:",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "ris_3": "F",
        "ris_1": "V",
        "immagine": "",
        "progressivo": "1.5.3-74",
        "id": 800,
        "ris_2": "F",
        "risposta_3": "boa gialla con o senza miraglio giallo a \"X\".",
        "risposta_2": "boa bianca e rossa con miraglio a triangolo rosso.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO"
    },
    {
        "id": 801,
        "domanda": "L’abbreviazione “Alt”, presente sulle carte nautiche italiane e riferita alle luci, indica:",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": "",
        "ris_3": "F",
        "risposta_2": "altezza della luce sul livello medio del mare.",
        "ris_2": "F",
        "ris_1": "V",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_3": "altezza del segnale.",
        "risposta_1": "luce alternata.",
        "progressivo": "1.5.3-75"
    },
    {
        "id": 802,
        "ris_1": "V",
        "domanda": "Riguardo ai tipi di luce di un faro, possiamo dire che:",
        "progressivo": "1.5.3-76",
        "ris_3": "F",
        "immagine": "",
        "ris_2": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_1": "la luce alternata di un faro è una luce ritmica che mostra alternativamente colori diversi.",
        "risposta_2": "la luce alternata di un faro è una luce ritmica che mostra sempre una luce bianca alternata ad una eclisse.",
        "risposta_3": "la luce fissa di un faro è una luce continua di aspetto e intensità costanti ma di colore variabile.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO"
    },
    {
        "risposta_1": "dalla sua \"caratteristica\".",
        "id": 803,
        "risposta_2": "esclusivamente dal colore della sua luce e dal periodo.",
        "progressivo": "1.5.3-77",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": "",
        "ris_3": "F",
        "domanda": "Un faro di notte è individuato:",
        "risposta_3": "esclusivamente dal suo colore e numero di lampeggi.",
        "ris_2": "F",
        "ris_1": "V",
        "argomento": "I principali fanali luminosi e il sistema IALA"
    },
    {
        "immagine": 75,
        "ris_3": "F",
        "ris_2": "F",
        "progressivo": "1.5.3-78",
        "risposta_2": "la sua luce si vede da nord a sud, cioè da 000° a 180°",
        "id": 804,
        "domanda": "Qual è il settore di visibilità di un faro come in figura a fianco?",
        "risposta_1": "la sua luce si vede da est verso ovest, cioè da 090° a 270°",
        "ris_1": "V",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_3": "è un faro spento poiché guasto."
    },
    {
        "id": 805,
        "ris_1": "F",
        "risposta_3": "la portata nominale.",
        "risposta_1": "sempre la portata geografica.",
        "ris_3": "V",
        "immagine": "",
        "progressivo": "1.5.3-79",
        "ris_2": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "domanda": "Qual è la \"portata\" del faro indicata sulla carta nautica, edita dall'I.I.M.M., rappresentante i mari italiani?",
        "risposta_2": "sempre la portata luminosa.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO"
    },
    {
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "progressivo": "1.5.3-80",
        "ris_1": "F",
        "id": 806,
        "risposta_3": "il \"periodo\" ha una durata di 4 secondi.",
        "ris_3": "V",
        "ris_2": "F",
        "risposta_2": "vi sono due fasi di luce di cui la prima dura 1 secondo e la seconda dura 2 secondi.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Di un faro si legge << 0.5 (in grassetto) - 1 - 0.5 (in grassetto) - 2 >> nell'Elenco Fari e segnali da nebbia:",
        "risposta_1": "vi sono due fasi di eclissi ciascuna della durata di 0,5 secondi.",
        "immagine": ""
    },
    {
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_2": "F",
        "id": 807,
        "domanda": "Di un faro si legge << 1,5 (in grassetto) - 2 - 1.5 (in grassetto) - 2 >> nell'Elenco Fari e segnali da nebbia:",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "progressivo": "1.5.3-81",
        "risposta_1": "vi sono due fasi di eclissi della durata complessiva di 3 secondi.",
        "risposta_3": "il \"periodo\" ha una durata di 7 secondi.",
        "immagine": "",
        "risposta_2": "vi sono due fasi di luce, ciascuna della durata di 2 secondi.",
        "ris_1": "F",
        "ris_3": "V"
    },
    {
        "ris_1": "V",
        "immagine": "",
        "id": 808,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_1": "pericolo a est: passare a ovest.",
        "ris_3": "F",
        "ris_2": "F",
        "domanda": "Il segnale cardinale che di notte emette nove scintillii, indica:",
        "risposta_3": "pericolo a ovest: passare a est.",
        "risposta_2": "pericolo a nord: passare a sud.",
        "progressivo": "1.5.3-82",
        "argomento": "I principali fanali luminosi e il sistema IALA"
    },
    {
        "risposta_1": "pericolo a nord: passare a sud.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_2": "pericolo a ovest: passare a est.",
        "ris_2": "V",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_3": "pericolo a est: passare a ovest.",
        "domanda": "Il segnale cardinale che di notte emette tre scintillii, indica:",
        "id": 809,
        "ris_1": "F",
        "immagine": "",
        "progressivo": "1.5.3-83",
        "ris_3": "F"
    },
    {
        "ris_1": "V",
        "ris_2": "F",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "domanda": "In navigazione a motore, dov'è ubicato, rispetto al centro nave, l'asse di rotazione intorno al quale l'unità accosta?",
        "risposta_1": "verso prua.",
        "id": 81,
        "immagine": "",
        "risposta_3": "al centro.",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_2": "verso poppa.",
        "progressivo": "1.1.2-6",
        "ris_3": "F"
    },
    {
        "ris_1": "F",
        "risposta_3": "pericolo a nord: passare a sud.",
        "immagine": "",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_2": "F",
        "risposta_1": "pericolo a ovest: passare a est.",
        "risposta_2": "pericolo a est: passare a ovest.",
        "id": 810,
        "progressivo": "1.5.3-84",
        "ris_3": "V",
        "domanda": "Il segnale cardinale che di notte emette sei scintillii, indica:",
        "argomento": "I principali fanali luminosi e il sistema IALA"
    },
    {
        "risposta_3": "è consentita la navigazione, ma bisogna prestare attenzione ad un determinato pericolo.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "domanda": "Sulla carta nautica, vicino al simbolo del faro, si legge la scritta \"settore rosso\". Significa che in quel settore:",
        "immagine": "",
        "ris_1": "F",
        "id": 811,
        "risposta_1": "è interdetta la navigazione.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "progressivo": "1.5.3-85",
        "ris_2": "F",
        "ris_3": "V",
        "risposta_2": "si può navigare solo in caso di emergenza."
    },
    {
        "progressivo": "1.5.3-86",
        "risposta_1": "l'intensità luminosa della luce, la visibilità meteorologica e la sensibilità dell'occhio dell'osservatore.",
        "ris_2": "F",
        "id": 812,
        "risposta_3": "l'assetto dell'unità, il riverbero del mare e lo stato nuvoloso.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": "",
        "domanda": "Quali sono gli elementi che influenzano la portata luminosa dei fari?",
        "ris_3": "F",
        "ris_1": "V",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_2": "la curvatura della terra, l'altezza della luce e l'elevazione dell'occhio dell'osservatore."
    },
    {
        "ris_3": "F",
        "immagine": "",
        "id": 813,
        "risposta_1": "corrisponde alla portata luminosa di una luce riferita ad una atmosfera omogenea in cui la visibilità meteorologica è pari a 10 miglia nautiche.",
        "progressivo": "1.5.3-87",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_2": "dipende dall'altezza del faro.",
        "ris_1": "V",
        "risposta_3": "dipende dall'altezza dell'occhio dell'osservatore.",
        "ris_2": "F",
        "domanda": "La portata nominale di un faro:",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO"
    },
    {
        "ris_3": "F",
        "risposta_3": "l'assetto dell'unità, il riverbero del mare e lo stato nuvoloso.",
        "progressivo": "1.5.3-88",
        "ris_2": "V",
        "risposta_1": "l'intensità luminosa, la visibilità meteorologica e la sensibilità dell'occhio dell'osservatore.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Quali sono gli elementi che influenzano la portata geografica di un faro?",
        "id": 814,
        "immagine": "",
        "risposta_2": "la curvatura della terra, l'altezza della luce e l'elevazione dell'occhio dell'osservatore.",
        "ris_1": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA"
    },
    {
        "progressivo": "1.5.3-89",
        "risposta_3": "dall'intervallo di tempo che intercorre tra due cicli successivi (lampo, eclisse).",
        "risposta_1": "dall'intervallo di tempo che intercorre tra un ciclo di luce ritmica e uno a luce fissa.",
        "ris_1": "F",
        "risposta_2": "da ogni successivo elemento che compone un ciclo di una luce ritmica (lampo, eclissi).",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "id": 815,
        "ris_2": "V",
        "immagine": "",
        "ris_3": "F",
        "domanda": "Da cosa è rappresentata la \"fase\" di un segnalamento marittimo?"
    },
    {
        "risposta_3": "un segnalamento luminoso galleggiante a rimorchio di un battello pneumatico.",
        "ris_2": "V",
        "immagine": "",
        "progressivo": "1.5.3-90",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_3": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "id": 816,
        "domanda": "Cosa si intende per \"boa luminosa\"?",
        "ris_1": "F",
        "risposta_1": "un segnalamento luminoso galleggiante fluttuante sulla superficie del mare.",
        "risposta_2": "un segnalamento luminoso galleggiante vincolato al fondo marino."
    },
    {
        "risposta_2": "i limiti dei canali navigabili, pericoli afferenti la sicurezza della navigazione e specchi acquei di particolare interesse.",
        "risposta_3": "i limiti delle rade, delle aree di ancoraggio e delle zone riservate alla pesca sportiva.",
        "domanda": "Cosa segnalano, di massima, le boe luminose?",
        "progressivo": "1.5.3-91",
        "id": 817,
        "risposta_1": "i limiti delle acque territoriali.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_1": "F",
        "ris_2": "V",
        "ris_3": "F",
        "immagine": "",
        "argomento": "I principali fanali luminosi e il sistema IALA"
    },
    {
        "immagine": "",
        "risposta_2": "intermittente, a gruppi di 3.",
        "risposta_1": "a lampi, a gruppi di 3.",
        "ris_1": "F",
        "risposta_3": "scintillante, a gruppi di 3.",
        "ris_3": "V",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "id": 818,
        "progressivo": "1.5.3-92",
        "ris_2": "F",
        "domanda": "La descrizione \"Sc.(3)\" identifica un segnalamento luminoso:"
    },
    {
        "ris_1": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "immagine": "",
        "risposta_3": "a luce alternata, che mostra alternativamente i colori bianco e rosso.",
        "id": 819,
        "ris_3": "V",
        "domanda": "La descrizione \"Alt. b.r.\" identifica un segnalamento luminoso:",
        "progressivo": "1.5.3-93",
        "risposta_2": "a luce alternata,  che mostra i colori bianco e rosso in cui la durata della luce bianca è doppia rispetto a quella della luce rossa.",
        "ris_2": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_1": "con luce che mostra contemporaneamente i colori bianco e rosso."
    },
    {
        "risposta_1": "girano in senso orario in marcia indietro.",
        "ris_2": "V",
        "immagine": "",
        "capitolo": "TEORIA DELLO SCAFO",
        "progressivo": "1.1.2-7",
        "risposta_3": "girano in senso antiorario in marcia avanti.",
        "domanda": "Un'elica è destrorsa se, guardando la poppa dall'esterno, le pale:",
        "id": 82,
        "ris_1": "F",
        "risposta_2": "girano in senso orario in marcia avanti.",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "ris_3": "F"
    },
    {
        "immagine": "",
        "domanda": "La descrizione \"Int.(2)\" identifica un segnalamento luminoso:",
        "risposta_2": "scintillante rapida, a gruppi di 2.",
        "ris_2": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "progressivo": "1.5.3-94",
        "risposta_1": "intermittente, a gruppi di eclissi di 2.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "a luce alternata, a gruppi di 2.",
        "ris_1": "V",
        "id": 820,
        "ris_3": "F"
    },
    {
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "id": 821,
        "risposta_2": "un dispositivo radar attivo sistemato sulla terra ferma, che emette un apposito codice di identificazione.",
        "risposta_3": "un dispositivo, che può essere sistemato anche sui segnalamenti, consentendo di riflettere in maniera passiva gli impulsi emessi dai radar.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_1": "F",
        "domanda": "Come si può definire un \"riflettore radar \"?",
        "progressivo": "1.5.3-95",
        "ris_2": "F",
        "ris_3": "V",
        "immagine": "",
        "risposta_1": "un dispositivo radar attivo, sistemato sulla terra ferma, che fornisce esclusivamente il valore del rilevamento sotto l'azione degli impulsi radar ricevuti."
    },
    {
        "immagine": "",
        "risposta_1": "che emette una luce lampeggiante a gruppi di 10 lampi ogni 3 secondi, la cui struttura ha un'altezza rispetto al livello medio del mare di 12 metri, ed è visibile ad una portata nominale di 37 miglia nautiche.",
        "ris_3": "F",
        "risposta_3": "che emette una luce fissa di 3 secondi, la cui struttura ha un'altezza rispetto al livello medio del mare di 10 metri, ed è visibile ad una portata nominale di 12 miglia nautiche.",
        "progressivo": "1.5.3-96",
        "risposta_2": "che emette una luce lampeggiante a gruppi di 3 lampi ogni 10 secondi, la cui struttura ha un'altezza rispetto al livello medio del mare di 37 metri, ed è visibile ad una portata nominale di 12 miglia nautiche.",
        "domanda": "Cosa indica la seguente sigla alfanumerica posta in prossimità del faro di Capo Negro dell'Isola di Zannone Fl(3) 10s 37m 12M?",
        "ris_2": "V",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "id": 822,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_1": "F"
    },
    {
        "ris_3": "F",
        "domanda": "Un'unità navale che si trovi a navigare in prossimità del segnalamento marittimo di fianco indicato, quale precauzione dovrebbe adottare?",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_1": "F",
        "immagine": 76,
        "risposta_2": "passare ad ovest della boa, essendo questa un segnale cardinale del sistema AISM- IALA.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_3": "passare a est della boa, essendo questa un segnale cardinale del sistema AISM- IALA.",
        "progressivo": "1.5.3-97",
        "id": 823,
        "risposta_1": "passare indifferentemente a est o a ovest della boa, essendo questa un segnale di allineamento per l'accesso a uno schema di separazione del traffico.",
        "ris_2": "V"
    },
    {
        "risposta_1": "che la struttura della boa luminosa, facente parte dei segnali cardinali del sistema AISM-IALA, ha una colorazione grigio-nero-grigio.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Cosa indica la sigla alfabetica posta sotto alla boa luminosa di fianco rappresentata?",
        "ris_3": "V",
        "ris_1": "F",
        "risposta_2": "che la luce della boa luminosa, facente parte dei segnali cardinali del sistema AISM-IALA, ha una colorazione gialla intermittente.",
        "id": 824,
        "risposta_3": "che la struttura della boa luminosa, facente parte dei segnali cardinali del sistema AISM-IALA, ha una colorazione gialla con banda nera.",
        "immagine": 76,
        "progressivo": "1.5.3-98",
        "ris_2": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA"
    },
    {
        "risposta_1": "un segnale di acque sicure del sistema di segnalamento marittimo AISM-IALA.",
        "ris_1": "V",
        "risposta_2": "un segnale cardinale del sistema di segnalamento marittimo AISM-IALA.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "ris_3": "F",
        "id": 825,
        "risposta_3": "un segnale di pericolo isolato del sistema di segnalamento marittimo AISM-IALA.",
        "immagine": 77,
        "progressivo": "1.5.3-99",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Cosa indica il segnale marittimo rappresentato in figura?",
        "ris_2": "F"
    },
    {
        "ris_2": "F",
        "id": 826,
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "domanda": "Cosa indica il segnale marittimo rappresentato in figura, posto in prossimità (S) del porto di Piombino?",
        "ris_1": "V",
        "ris_3": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "boa luminosa ad asta indicante un segnale cardinale del sistema di segnalamento marittimo AISM-IALA.",
        "risposta_2": "boa luminosa ad asta indicante un segnale di acque sicure del sistema di segnalamento marittimo AISM-IALA.",
        "immagine": 78,
        "progressivo": "1.5.3-100",
        "risposta_1": "boa luminosa ad asta indicante un segnale speciale del sistema di segnalamento marittimo AISM-IALA."
    },
    {
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "progressivo": "1.5.3-101",
        "risposta_2": "bianco.",
        "domanda": "Di che colore è il corpo del segnale marittimo rappresentato in figura, posto in prossimità (S) del porto di Piombino?",
        "id": 827,
        "ris_1": "F",
        "risposta_1": "nero.",
        "ris_3": "V",
        "immagine": 78,
        "risposta_3": "giallo.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "ris_2": "F"
    },
    {
        "ris_3": "F",
        "risposta_3": "un lampo giallo ogni 4 secondi con portata geografica di 3 miglia nautiche.",
        "id": 828,
        "risposta_2": "3 lampi gialli ogni 3 secondi con visibilità di 4 miglia nautiche.",
        "risposta_1": "un lampo giallo ogni 3 secondi con portata nominale di 4 miglia nautiche.",
        "progressivo": "1.5.3-102",
        "ris_1": "V",
        "immagine": 78,
        "ris_2": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "domanda": "Cosa indica la caratteristica del segnale marittimo rappresentato in figura, posto a Sud del porto di Piombino?",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO"
    },
    {
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "id": 829,
        "risposta_1": "che la boa luminosa, facente parte dei segnali cardinali del sistema AISM-IALA, emette 10 lampi ogni 3 secondi, ed è visibile a una portata nominale di 5 miglia nautiche.",
        "immagine": 79,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_2": "che la boa luminosa, facente parte dei segnali cardinali del sistema AISM-IALA, emette luci scintillanti a gruppi di 3 ogni 10 secondi, ed è visibile a una portata nominale di 5 miglia nautiche.",
        "ris_2": "V",
        "progressivo": "1.5.3-103",
        "ris_3": "F",
        "domanda": "Con riferimento alle caratteristiche dei segnalamenti marittimi riportate in una carta nautica, cosa indica la sigla alfanumerica posta lateralmente alla boa luminosa di fianco rappresentata?",
        "risposta_3": "che la boa luminosa, facente parte dei segnali cardinali del sistema AISM-IALA, emette 10 lampi ogni 5 minuti , ed è visibile ad una portata geografica di 3 miglia nautiche.",
        "ris_1": "F"
    },
    {
        "risposta_3": "il flusso d'acqua spinto contro la pala del timone o la carena.",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_2": "F",
        "ris_3": "V",
        "progressivo": "1.1.2-8",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "id": 83,
        "risposta_2": "il diametro del mozzo dell'elica.",
        "immagine": "",
        "ris_1": "F",
        "risposta_1": "la rapidità dell'invertitore di inserire una marcia.",
        "domanda": "Cosa, oltre la spinta esercitata dalle singole pale, influisce sull'effetto evolutivo dell'elica?"
    },
    {
        "immagine": 79,
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "progressivo": "1.5.3-104",
        "id": 830,
        "ris_1": "F",
        "risposta_3": "che l'area navigabile è posta a sud del segnale stesso.",
        "ris_2": "V",
        "risposta_2": "che l'area navigabile è posta a levante del segnale stesso.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Cosa indica il miraglio del segnale marittimo rappresentato in figura, posto in prossimità (NE) delle Isole dei Poveri?",
        "ris_3": "F",
        "risposta_1": "che l'area navigabile è posta a ponente del segnale stesso."
    },
    {
        "ris_2": "V",
        "progressivo": "1.5.3-105",
        "domanda": "Cosa indica la sigla alfabetica posta sotto alla boa luminosa di fianco rappresentata?",
        "ris_3": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "id": 831,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_3": "che la luce della boa luminosa, facente parte dei segnali cardinali del sistema AISM-IALA, ha una colorazione gialla intermittente.",
        "ris_1": "F",
        "immagine": 80,
        "risposta_2": "che la struttura della boa luminosa, facente parte dei segnali cardinali del sistema AISM-IALA, ha una colorazione nera con banda gialla.",
        "risposta_1": "che la struttura della boa luminosa, facente parte dei segnali cardinali del sistema AISM-IALA, ha una colorazione nero-grigio-nero."
    },
    {
        "ris_3": "F",
        "immagine": 80,
        "risposta_2": "colore nero sopra il giallo.",
        "ris_1": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_3": "colore bianco sopra il giallo.",
        "ris_2": "V",
        "domanda": "Di che colore è il corpo del segnale marittimo rappresentato in figura, posto in prossimità (N) di P.ta della Volpe?",
        "risposta_1": "colore giallo sopra il nero.",
        "progressivo": "1.5.3-106",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "id": 832
    },
    {
        "ris_3": "V",
        "risposta_2": "scintillante a gruppi di 4 lampi.",
        "risposta_1": "lampeggiante a gruppi di 4 lampi.",
        "risposta_3": "scintillante continua e una portata nominale di 4 miglia nautiche.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_1": "F",
        "ris_2": "F",
        "id": 833,
        "progressivo": "1.5.3-107",
        "domanda": "Cosa indica la caratteristica del segnale marittimo rappresentato in figura?",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "immagine": 80
    },
    {
        "risposta_2": "boa non luminosa ad asta indicante un segnale cardinale Sud del sistema di segnalamento marittimo AISM-IALA.",
        "risposta_3": "boa luminosa a fuso indicante un pericolo isolato.",
        "immagine": 81,
        "ris_1": "F",
        "ris_3": "F",
        "risposta_1": "boa luminosa a fuso indicante un segnale di pericolo isolato del sistema di segnalamento marittimo AISM-IALA.",
        "ris_2": "V",
        "id": 834,
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "progressivo": "1.5.3-108",
        "domanda": "Cosa indica il segnale marittimo rappresentato in figura, posto in prossimità (SW) dell'isola Mortorio?",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO"
    },
    {
        "progressivo": "1.5.3-109",
        "risposta_1": "che l'area navigabile è posta a Nord del segnale stesso.",
        "id": 835,
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "domanda": "Cosa indica il miraglio del segnale marittimo rappresentato in figura, posto in prossimità (SW) dell'Isola Mortorio?",
        "immagine": 81,
        "risposta_2": "che l'area navigabile è posta a Ovest del segnale stesso.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_2": "F",
        "ris_3": "V",
        "risposta_3": "che l'area navigabile è posta a Sud del segnale stesso.",
        "ris_1": "F"
    },
    {
        "risposta_2": "il segnale di dritta (zona A) entrando nei porti o nei canali.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "ris_1": "V",
        "immagine": 82,
        "progressivo": "1.5.3-110",
        "ris_2": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Cosa indica il segnale marittimo rappresentato in figura?",
        "id": 836,
        "risposta_1": "il segnale di sinistra (zona A) entrando nei porti o nei canali.",
        "ris_3": "F",
        "risposta_3": "il segnale di pericolo isolato."
    },
    {
        "domanda": "Cosa indica il segnale marittimo rappresentato in figura?",
        "ris_1": "F",
        "risposta_2": "il segnale di dritta (zona A) entrando nei porti o nei canali.",
        "ris_2": "V",
        "progressivo": "1.5.3-111",
        "risposta_1": "il segnale di sinistra (zona A) entrando nei porti o nei canali.",
        "risposta_3": "il segnale di acque libere.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "immagine": 83,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "id": 837,
        "ris_3": "F"
    },
    {
        "ris_3": "F",
        "risposta_3": "segnale di chiamata e rimando; l'unità deve dirigersi verso la sponda e fare marcia indietro.",
        "id": 838,
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "progressivo": "1.5.3-112",
        "ris_2": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_2": "segnale di chiamata e rimando; dobbiamo proseguire lungo la sponda dove si trova il segnale sino ad avviso successivo.",
        "domanda": "Navigazione fluviale; il segnale rappresentato in figura a fianco indica:",
        "ris_1": "V",
        "immagine": 84,
        "risposta_1": "segnale di chiamata e rimando; dirigersi verso la sponda dove si trova il segnale e abbandonarla immediatamente dopo averla raggiunta."
    },
    {
        "ris_1": "F",
        "immagine": "",
        "risposta_2": "quella che naviga avendo la corrente a favore.",
        "ris_2": "V",
        "ris_3": "F",
        "id": 839,
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "progressivo": "1.5.3-113",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_1": "quella più grande perché con maggiori difficoltà di manovra.",
        "domanda": "Navigazione fluviale; tra due imbarcazioni in navigazione con rotte opposte chi ha la precedenza sull'altra?",
        "risposta_3": "quella che naviga controcorrente."
    },
    {
        "ris_3": "V",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "id": 84,
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_1": "avere un timone più robusto.",
        "immagine": "",
        "ris_2": "F",
        "ris_1": "F",
        "risposta_3": "ridurre la resistenza della pala alla rotazione e quindi la durezza della barra/ruota.",
        "domanda": "Il timone compensato serve a:",
        "risposta_2": "allontanare dall'asse di rotazione il punto di applicazione della risultante della pressione esercitata dall'acqua sulle pale.",
        "progressivo": "1.1.2-9"
    },
    {
        "progressivo": "1.5.3-114",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "domanda": "Navigazione fluviale; si deve attraversare un ponte avente più arcate; sotto quale arcata si passa?",
        "id": 840,
        "risposta_2": "quella centrale.",
        "risposta_1": "quella più a dritta.",
        "ris_3": "V",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "immagine": "",
        "ris_1": "F",
        "risposta_3": "quella segnalata da un rombo giallo.",
        "ris_2": "F"
    },
    {
        "risposta_1": "si passa a sinistra del segnale.",
        "ris_2": "F",
        "ris_1": "V",
        "domanda": "Navigazione fluviale; procediamo controcorrente quando incrociamo una boa bianca:",
        "risposta_3": "si passa a dritta del segnale.",
        "ris_3": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "id": 841,
        "immagine": "",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "progressivo": "1.5.3-115",
        "risposta_2": "la si evita passando a dritta o a sinistra indifferentemente."
    },
    {
        "id": 842,
        "risposta_2": "si rallenta soltanto.",
        "ris_2": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "ris_3": "V",
        "domanda": "Navigazione fluviale; rileviamo l’approssimarsi di una curva a gomito:",
        "risposta_1": "si accendono i fanali regolamentari e si rallenta.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "progressivo": "1.5.3-116",
        "immagine": "",
        "ris_1": "F",
        "risposta_3": "si emette 1 suono prolungato e si rimane sull'ascolto della risposta da parte di eventuale altra unità."
    },
    {
        "ris_3": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "ris_1": "F",
        "risposta_3": "indica che dobbiamo invertire il senso di marcia.",
        "progressivo": "1.5.3-117",
        "immagine": 85,
        "domanda": "Navigazione fluviale; il segnale rappresentato in figura a fianco:",
        "risposta_1": "se presente sulla sponda destra, indica che dobbiamo abbandonare la sponda dove si trova il segnale.",
        "ris_2": "V",
        "risposta_2": "se presente sulla sponda destra, indica che dobbiamo dirigere verso la sponda dove si trova il segnale.",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "id": 843
    },
    {
        "domanda": "Navigazione fluviale; il segnale rappresentato in figura a fianco:",
        "risposta_1": "se presente sulla sponda sinistra, indica che dobbiamo dirigere verso la sponda dove si trova il segnale.",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "risposta_2": "se presente sulla sponda sinistra, indica che dobbiamo abbandonare la sponda dove si trova il segnale.",
        "ris_1": "V",
        "id": 844,
        "ris_2": "F",
        "immagine": 86,
        "ris_3": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "risposta_3": "indica che dobbiamo invertire il senso di marcia.",
        "progressivo": "1.5.3-118"
    },
    {
        "risposta_3": "segnale di prosecuzione; devo proseguire lungo la sponda dove si trova il segnale sino ad avviso successivo.",
        "domanda": "Navigazione fluviale; il segnale rappresentato in figura a fianco indica:",
        "progressivo": "1.5.3-119",
        "risposta_1": "segnale di prosecuzione nella sponda opposta; devo ridurre la velocità.",
        "ris_2": "F",
        "argomento": "I principali fanali luminosi e il sistema IALA",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "id": 845,
        "immagine": 87,
        "ris_1": "F",
        "risposta_2": "segnale di prosecuzione; devo aumentare la velocità della mia unità perché c'è una forte corrente.",
        "ris_3": "V"
    },
    {
        "ris_1": "V",
        "progressivo": "1.5.3-120",
        "id": 846,
        "risposta_1": "un faro anabbagliante orientabile.",
        "immagine": "",
        "ris_2": "F",
        "risposta_2": "lo specchietto retrovisore convesso.",
        "risposta_3": "la cassetta di pronto soccorso.",
        "ris_3": "F",
        "capitolo": "COLREG E SEGNALAMENTO MARITTIMO",
        "domanda": "Navigazione fluviale: quale tra le seguenti attrezzature deve essere imbarcata tra le dotazioni di bordo dell'unità?",
        "argomento": "I principali fanali luminosi e il sistema IALA"
    },
    {
        "ris_1": "F",
        "risposta_1": "la scala Douglas.",
        "id": 847,
        "progressivo": "1.6.1-1",
        "domanda": "Attraverso quale scala viene misurata la forza del vento?",
        "ris_2": "V",
        "immagine": "",
        "risposta_3": "la scala di Coriolis.",
        "risposta_2": "la scala Beaufort.",
        "ris_3": "F",
        "capitolo": "METEOROLOGIA",
        "argomento": "Elementi di meteorologia e strumenti"
    },
    {
        "capitolo": "METEOROLOGIA",
        "ris_1": "F",
        "progressivo": "1.6.1-2",
        "risposta_1": "la brezza diurna e quella notturna hanno la medesima intensità.",
        "id": 848,
        "argomento": "Elementi di meteorologia e strumenti",
        "ris_3": "V",
        "immagine": "",
        "ris_2": "F",
        "risposta_2": "la brezza di notte è più intensa di quella diurna.",
        "domanda": "Quale tra queste affermazioni è corretta?",
        "risposta_3": "la brezza che spira durante le ore diurne è più intensa rispetto a quella notturna."
    },
    {
        "risposta_3": "la differenza che intercorre tra il valore di temperatura rilevato all'alba e quello rilevato al tramonto.",
        "domanda": "Cosa si intende per escursione diurna di temperatura?",
        "id": 849,
        "immagine": "",
        "risposta_1": "la differenza che intercorre tra il valore di temperatura misurato alle ore 00:00 locali e quello misurato alle ore 12:00 locali.",
        "progressivo": "1.6.1-3",
        "ris_3": "F",
        "ris_2": "V",
        "risposta_2": "la differenza che intercorre tra il valore massimo di temperatura e quello minimo nel corso della giornata.",
        "ris_1": "F",
        "capitolo": "METEOROLOGIA",
        "argomento": "Elementi di meteorologia e strumenti"
    },
    {
        "risposta_3": "che presenta una parte della pala a proravia dell'asse.",
        "ris_3": "V",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_1": "di rispetto.",
        "ris_1": "F",
        "risposta_2": "i cui effetti evolutivi sono compensati da quelli dell'elica.",
        "id": 85,
        "progressivo": "1.1.2-10",
        "domanda": "Un timone compensato è quel timone:",
        "ris_2": "F",
        "immagine": "",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità."
    },
    {
        "ris_1": "F",
        "risposta_2": "genera l'effetto serra, mentre sulla superficie terrestre si forma l'effetto albedo.",
        "argomento": "Elementi di meteorologia e strumenti",
        "ris_3": "V",
        "immagine": "",
        "id": 850,
        "progressivo": "1.6.1-4",
        "capitolo": "METEOROLOGIA",
        "domanda": "Quali fenomeni sono generati dal vapore acqueo?",
        "risposta_1": "genera neve e grandine.",
        "risposta_3": "a seguito della sua condensazione, nell'aria si generano nubi e nebbie mentre sulla superficie terrestre si formano rugiada e brina.",
        "ris_2": "F"
    },
    {
        "argomento": "Elementi di meteorologia e strumenti",
        "risposta_3": "Millimetri Torricelli (mmT).",
        "ris_3": "F",
        "ris_1": "V",
        "id": 851,
        "immagine": "",
        "risposta_2": "Newton (N).",
        "capitolo": "METEOROLOGIA",
        "risposta_1": "Hectopascal (hPa).",
        "domanda": "Qual è l'unità di misura internazionale per indicare il valore della pressione atmosferica?",
        "ris_2": "F",
        "progressivo": "1.6.1-5"
    },
    {
        "immagine": "",
        "ris_1": "V",
        "risposta_3": "linee di uguale differenza di pressione.",
        "ris_2": "F",
        "id": 852,
        "risposta_2": "Linee di uguale temperatura.",
        "capitolo": "METEOROLOGIA",
        "domanda": "Cosa si intende per isobare?",
        "progressivo": "1.6.1-6",
        "argomento": "Elementi di meteorologia e strumenti",
        "risposta_1": "Linee di uguale pressione.",
        "ris_3": "F"
    },
    {
        "risposta_2": "qualsiasi forma di condensazione del vapore acqueo negli strati atmosferici superiori.",
        "ris_3": "F",
        "ris_1": "V",
        "risposta_1": "qualsiasi forma di condensazione del vapore acqueo negli strati atmosferici a immediato contatto con il suolo o gli specchi acquei.",
        "risposta_3": "è un sinonimo di foschia.",
        "immagine": "",
        "argomento": "Elementi di meteorologia e strumenti",
        "progressivo": "1.6.1-7",
        "ris_2": "F",
        "id": 853,
        "domanda": "Cosa si intende per nebbia?",
        "capitolo": "METEOROLOGIA"
    },
    {
        "ris_1": "F",
        "ris_2": "F",
        "capitolo": "METEOROLOGIA",
        "risposta_3": "lo spostamento pressocchè orizzontale di una massa d'aria i cui caratteri distintivi sono la direzione e la velocità.",
        "domanda": "Come si definisce il vento?",
        "risposta_1": "il movimento obliquo dell'aria i cui caratteri distintivi sono l'accelerazione e la turbolenza.",
        "progressivo": "1.6.1-8",
        "risposta_2": "il movimento verticale dell'aria i cui caratteri distintivi sono la frequenza e la provenienza.",
        "ris_3": "V",
        "id": 854,
        "immagine": "",
        "argomento": "Elementi di meteorologia e strumenti"
    },
    {
        "risposta_3": "oceanografici, torrenziali e convergenti.",
        "ris_3": "F",
        "ris_2": "F",
        "progressivo": "1.6.1-9",
        "risposta_2": "orizzontali, laterali e sussultori.",
        "domanda": "Quali sono i principali movimenti a cui è soggetto il mare?",
        "ris_1": "V",
        "risposta_1": "correnti, onde e maree.",
        "id": 855,
        "capitolo": "METEOROLOGIA",
        "argomento": "Elementi di meteorologia e strumenti",
        "immagine": ""
    },
    {
        "id": 856,
        "domanda": "Come si definiscono le maree?",
        "ris_2": "F",
        "capitolo": "METEOROLOGIA",
        "risposta_3": "l'oscillazione del livello del mare generata dalla forza di attrazione gravitazionale esercitata principalmente dal sole e dalla luna.",
        "risposta_1": "movimenti oscillanti delle masse d'acqua generati dalla rotazione terrestre.",
        "risposta_2": "movimento orizzontale del mare generato dal magnetismo terrestre.",
        "ris_3": "V",
        "immagine": "",
        "progressivo": "1.6.1-10",
        "argomento": "Elementi di meteorologia e strumenti",
        "ris_1": "F"
    },
    {
        "risposta_2": "fronte tropicale.",
        "risposta_1": "fronte occluso.",
        "id": 857,
        "argomento": "Elementi di meteorologia e strumenti",
        "risposta_3": "fronte equatoriale.",
        "domanda": "Come si definisce il fenomeno atmosferico generato dalla sovrapposizione di un fronte freddo e di un fronte caldo?",
        "ris_2": "F",
        "capitolo": "METEOROLOGIA",
        "ris_3": "F",
        "immagine": "",
        "progressivo": "1.6.1-11",
        "ris_1": "V"
    },
    {
        "ris_3": "F",
        "id": 858,
        "risposta_1": "la conoscenza della tendenza della pressione atmosferica nel tempo.",
        "immagine": "",
        "argomento": "Elementi di meteorologia e strumenti",
        "ris_2": "F",
        "progressivo": "1.6.1-12",
        "capitolo": "METEOROLOGIA",
        "risposta_2": "la conoscenza della variazione di temperatura atmosferica nel tempo.",
        "domanda": "Quale elemento risulta fondamentale al fine di prevedere l'evoluzione delle condizioni meteorologiche durante la navigazione?",
        "ris_1": "V",
        "risposta_3": "la conoscenza della variazione dell'umidità atmosferica nel tempo."
    },
    {
        "immagine": "",
        "id": 859,
        "progressivo": "1.6.1-13",
        "argomento": "Elementi di meteorologia e strumenti",
        "capitolo": "METEOROLOGIA",
        "ris_2": "F",
        "risposta_2": "colore grigio o bluastro, forma imponente.",
        "ris_1": "F",
        "risposta_3": "colore bianco, struttura fibrosa, isolate.",
        "risposta_1": "colore roseo, struttura lattiginosa, sono raggruppate a banchi.",
        "domanda": "Quali sono le principali caratteristiche delle nubi denominate \"cirri\"?",
        "ris_3": "V"
    },
    {
        "risposta_1": "passo.",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "capitolo": "TEORIA DELLO SCAFO",
        "immagine": "",
        "progressivo": "1.1.2-11",
        "ris_2": "F",
        "domanda": "Com'è definita la differenza tra la distanza teorica e quella effettiva percorsa da un'elica in un giro completo?",
        "risposta_3": "regresso.",
        "id": 86,
        "ris_1": "F",
        "ris_3": "V",
        "risposta_2": "diametro."
    },
    {
        "ris_2": "V",
        "argomento": "Elementi di meteorologia e strumenti",
        "risposta_1": "neve, nebbia e foschia.",
        "progressivo": "1.6.1-14",
        "immagine": "",
        "risposta_3": "tuoni, trombe d'aria e arcobaleno.",
        "id": 860,
        "capitolo": "METEOROLOGIA",
        "ris_3": "F",
        "risposta_2": "rovesci, temporali o grandine.",
        "ris_1": "F",
        "domanda": "Quali sono i principali effetti meteorologici generati dalle nubi tipo \"cumulonembo\"?"
    },
    {
        "immagine": "",
        "capitolo": "METEOROLOGIA",
        "ris_2": "F",
        "ris_3": "F",
        "argomento": "Elementi di meteorologia e strumenti",
        "risposta_2": "l'intersezione tra una massa d'aria e la superficie terrestre.",
        "risposta_1": "la superficie di contatto, e pertanto di discontinuità, tra due masse d'aria aventi caratteristiche di temperatura, pressione e umidità differenti.",
        "domanda": "Un fronte meteorologico è:",
        "progressivo": "1.6.1-15",
        "risposta_3": "la massa di nubi di altezza media tra 2000 e 6000 m.",
        "ris_1": "V",
        "id": 861
    },
    {
        "progressivo": "1.6.1-16",
        "risposta_1": "quando una massa d'aria calda (quindi anche più umida) si avvicina a una relativamente più fredda (e meno umida), scorrendovi sopra.",
        "ris_1": "V",
        "capitolo": "METEOROLOGIA",
        "ris_3": "F",
        "domanda": "Quando si ha un fronte caldo?",
        "id": 862,
        "ris_2": "F",
        "risposta_2": "quando una massa d'aria fredda (quindi meno umida ma più densa) si avvicina e si incunea sotto a una massa relativamente più calda (più umida e più leggera), facendola salire.",
        "immagine": "",
        "risposta_3": "quando una massa d'aria si interseca con la superficie terrestre.",
        "argomento": "Elementi di meteorologia e strumenti"
    },
    {
        "risposta_3": "quando una massa d'aria si interseca con la superficie terrestre.",
        "risposta_2": "quando una massa d'aria più calda (quindi anche più umida) si avvicina a una relativamente più fredda (e meno umida), scorrendovi sopra.",
        "argomento": "Elementi di meteorologia e strumenti",
        "ris_2": "F",
        "capitolo": "METEOROLOGIA",
        "ris_3": "F",
        "ris_1": "V",
        "domanda": "Quando un fronte si definisce freddo?",
        "id": 863,
        "progressivo": "1.6.1-17",
        "immagine": "",
        "risposta_1": "quando una massa d'aria fredda (quindi meno umida ma più densa) si avvicina e si incunea sotto a una massa relativamente più calda (più umida e più leggera), facendola salire e determinando un raffreddamento della regione in cui transita."
    },
    {
        "ris_2": "F",
        "domanda": "Come può definirsi un fronte stazionario?",
        "risposta_2": "la sovrapposizione di un fronte caldo e un fronte freddo.",
        "progressivo": "1.6.1-18",
        "ris_3": "F",
        "immagine": "",
        "ris_1": "V",
        "argomento": "Elementi di meteorologia e strumenti",
        "risposta_1": "un fronte che non presenta alcun movimento, ossia nessuna delle masse d'aria interessate invade sensibilmente la zona occupata dall'altra.",
        "id": 864,
        "capitolo": "METEOROLOGIA",
        "risposta_3": "un fronte che presenta un movimento verticale, tale da generare nubi denominate cirri."
    },
    {
        "domanda": "Nel nostro emisfero:",
        "capitolo": "METEOROLOGIA",
        "risposta_1": "il vento al suolo spira in senso orario intorno a una bassa pressione (o ciclone).",
        "immagine": "",
        "argomento": "Elementi di meteorologia e strumenti",
        "ris_3": "F",
        "ris_1": "F",
        "id": 865,
        "risposta_3": "il vento al suolo spira in senso antiorario intorno a un'alta pressione (a un anticiclone).",
        "risposta_2": "il vento al suolo spira in senso antiorario intorno a una bassa pressione (o ciclone).",
        "ris_2": "V",
        "progressivo": "1.6.1-19"
    },
    {
        "immagine": "",
        "ris_2": "V",
        "ris_3": "F",
        "argomento": "Elementi di meteorologia e strumenti",
        "progressivo": "1.6.1-20",
        "risposta_1": "la differenza di temperatura esistente tra due masse d'aria confinanti tra loro.",
        "risposta_2": "la differenza di pressione esistente tra due masse d'aria confinanti tra loro.",
        "id": 866,
        "capitolo": "METEOROLOGIA",
        "risposta_3": "la differenza di umidità esistente tra due masse d'aria confinanti tra loro.",
        "domanda": "Cosa si intende per gradiente barico orizzontale?",
        "ris_1": "F"
    },
    {
        "immagine": "",
        "risposta_3": "venti periodici che spirano ad una velocità compresa tra i 30 e i 40 nodi e la cui intensità risulta maggiore nei mesi caldi e umidi.",
        "id": 867,
        "risposta_2": "venti permanenti che spirano ad una velocità compresa tra i 13 e i 18 nodi e la cui intensità risulta maggiore nei mesi freddi.",
        "domanda": "Gli alisei sono:",
        "capitolo": "METEOROLOGIA",
        "progressivo": "1.6.1-21",
        "ris_3": "F",
        "risposta_1": "venti periodici che spirano ad una velocità compresa tra i 40 e i 50 nodi e la cui intensità risulta maggiore nei mesi caldi e umidi.",
        "ris_1": "F",
        "argomento": "Elementi di meteorologia e strumenti",
        "ris_2": "V"
    },
    {
        "risposta_2": "il valore dell'altezza media delle più alte onde di marea rispetto allo zero idrografico (in inglese chart datum).",
        "ris_3": "V",
        "domanda": "Cosa si intende per altezza della marea?",
        "risposta_3": "il valore dell'altezza dell'alta marea o della bassa marea rispetto allo zero idrografico (in inglese chart datum ).",
        "risposta_1": "il valore dell'altezza delle onde di marea rispetto allo zero idrografico (in inglese chart datum).",
        "progressivo": "1.6.1-22",
        "id": 868,
        "ris_1": "F",
        "immagine": "",
        "argomento": "Elementi di meteorologia e strumenti",
        "capitolo": "METEOROLOGIA",
        "ris_2": "F"
    },
    {
        "domanda": "Qual è l'Ente nazionale preposto a produrre le informazioni destinate all'assistenza meteorologica delle unità navali in navigazione nel Mar Mediterraneo?",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "ris_3": "F",
        "risposta_3": "la Centrale Operativa della Guardia Costiera.",
        "capitolo": "METEOROLOGIA",
        "risposta_1": "il Centro Nazionale di Meteorologia e Climatologia Aeronautica.",
        "ris_1": "V",
        "risposta_2": "il Centro Nazionale delle Stazioni radio costiere.",
        "immagine": "",
        "ris_2": "F",
        "id": 869,
        "progressivo": "1.6.2-1"
    },
    {
        "id": 87,
        "risposta_3": "la poppa verso sinistra, quindi la prora verso dritta.",
        "immagine": "",
        "progressivo": "1.1.2-12",
        "domanda": "L'effetto evolutivo di un'elica destrorsa in rotazione all'indietro (retromarcia) fa ruotare:",
        "ris_1": "F",
        "ris_2": "F",
        "risposta_1": "sia la poppa sia la prora verso dritta.",
        "risposta_2": "la poppa verso dritta, quindi la prora verso sinistra.",
        "capitolo": "TEORIA DELLO SCAFO",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "ris_3": "V"
    },
    {
        "progressivo": "1.6.2-2",
        "risposta_3": "utili informazioni afferenti fenomeni meteorologici pericolosi per la navigazione marittima.",
        "risposta_1": "utili informazioni  afferenti fenomeni di improvvisi innalzamenti di maree sigiziali.",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "ris_1": "F",
        "risposta_2": "utili informazioni afferenti fenomeni di improvvisi cambi di direzione delle correnti marine.",
        "immagine": "",
        "capitolo": "METEOROLOGIA",
        "ris_3": "V",
        "domanda": "Cosa sono gli \"avvisi\" meteorologici sono messaggi che contengono:",
        "id": 870,
        "ris_2": "F"
    },
    {
        "progressivo": "1.6.2-3",
        "immagine": "",
        "ris_2": "V",
        "risposta_2": "la pressione rimane costante o sale lentamente ed il sole si presenta di colore rosso nelle ore serali in condizioni di cielo chiaro.",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "risposta_3": "repentino aumento della temperatura e basso tenore di umidità nell'atmosfera.",
        "capitolo": "METEOROLOGIA",
        "domanda": "Nell'ambito delle previsioni locali, quali segni premonitori possono indicare il perdurare del bel tempo?",
        "ris_3": "F",
        "ris_1": "F",
        "risposta_1": "repentino aumento della pressione atmosferica e presenza di cumulonembi all'orizzonte.",
        "id": 871
    },
    {
        "ris_1": "V",
        "immagine": "",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "ris_2": "F",
        "risposta_3": "rotazione del vento da Sud verso Est e riduzione del livello del mare indipendentemente dalla marea.",
        "id": 872,
        "capitolo": "METEOROLOGIA",
        "risposta_1": "addensamento dei cirri in cirrostrati, repentina riduzione della pressione e presenza di vento sostenuto già dalle prime ore del mattino.",
        "risposta_2": "movimento delle nubi da Sud verso Nord e incremento repentino dell'umidità al tramonto.",
        "progressivo": "1.6.2-4",
        "domanda": "Nell'ambito delle previsioni locali, quali segni premonitori possono indicare il possibile peggioramento del tempo?",
        "ris_3": "F"
    },
    {
        "ris_2": "V",
        "risposta_3": "repentino annuvolamento del cielo e movimento delle nubi a differenti altezze e in diverse direzioni.",
        "risposta_2": "innalzamento delle basi delle nubi, rotazione in senso orario del vento da Est verso Sud e poi Ovest e rapido innalzamento della pressione.",
        "risposta_1": "abbassamento delle nubi e brusco calo della pressione atmosferica.",
        "ris_3": "F",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "id": 873,
        "immagine": "",
        "domanda": "Nell'ambito delle previsioni locali, quali segni premonitori possono indicare il possibile miglioramento del tempo?",
        "capitolo": "METEOROLOGIA",
        "ris_1": "F",
        "progressivo": "1.6.2-5"
    },
    {
        "risposta_1": "i venti da Sud calano di intensità, il barometro sale costantemente e il livello del mare tende a salire.",
        "progressivo": "1.6.2-6",
        "risposta_2": "il barometro sale costantemente, con brusco calo della temperatura.",
        "immagine": "",
        "ris_3": "V",
        "ris_2": "F",
        "capitolo": "METEOROLOGIA",
        "ris_1": "F",
        "risposta_3": "le nubi si addensano, i cirri assumono di una colorazione rossastra con calo repentino della pressione e rinforzo dei venti provenienti da Sud.",
        "domanda": "Nell'ambito delle previsioni locali, quali segni premonitori possono indicare il possibile verificarsi di precipitazioni piovose?",
        "id": 874,
        "argomento": "Bollettini meteorologici e  previsioni locali"
    },
    {
        "ris_1": "V",
        "risposta_3": "diminuzione della temperatura di rugiada e presenza di mare lungo.",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "progressivo": "1.6.2-7",
        "domanda": "Quali sono tra questi gli elementi che preannunciano l'approssimarsi di tempo cattivo durante la navigazione?",
        "id": 875,
        "immagine": "",
        "ris_3": "F",
        "ris_2": "F",
        "capitolo": "METEOROLOGIA",
        "risposta_1": "brusca caduta della pressione atmosferica e presenza di nuvole ad alto sviluppo verticale.",
        "risposta_2": "presenza di mare lungo e nuvole stratificate."
    },
    {
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "ris_2": "V",
        "risposta_3": "oscuramento del cielo durante le ore serali, presenza di aria secca e vento regolare.",
        "ris_1": "F",
        "progressivo": "1.6.2-8",
        "risposta_2": "movimento di un flusso di aria calda e umida da una regione oceanica più calda a una più fredda, presenza di acqua molto più fredda rispetto all'aria sovrastante e presenza di vento debole.",
        "domanda": "Nell'ambito delle previsioni locali, quali segni premonitori possono indicare la possibile formazione di nebbia?",
        "id": 876,
        "ris_3": "F",
        "risposta_1": "movimento di un flusso di aria fredda e secca da una regione oceanica più calda ad una più fredda, presenza di acqua molto più calda rispetto all'aria sovrastante e presenza di vento teso.",
        "immagine": "",
        "capitolo": "METEOROLOGIA"
    },
    {
        "progressivo": "1.6.2-9",
        "id": 877,
        "ris_3": "F",
        "risposta_1": "carte al suolo e carte in quota.",
        "risposta_2": "carte generali e carte particolari.",
        "risposta_3": "carte gnomoniche e carte equatoriali.",
        "immagine": "",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "domanda": "Come possono suddividersi le carte meteorologiche?",
        "ris_1": "V",
        "ris_2": "F",
        "capitolo": "METEOROLOGIA"
    },
    {
        "risposta_2": "fronte freddo.",
        "domanda": "Che tipo di fronte è rappresentato in figura?",
        "ris_3": "F",
        "progressivo": "1.6.2-10",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "ris_1": "F",
        "risposta_3": "fronte occluso.",
        "immagine": 88,
        "id": 878,
        "capitolo": "METEOROLOGIA",
        "ris_2": "V",
        "risposta_1": "fronte caldo."
    },
    {
        "risposta_3": "fronte caldo.",
        "capitolo": "METEOROLOGIA",
        "id": 879,
        "domanda": "Che tipo di fronte è rappresentato in figura?",
        "progressivo": "1.6.2-11",
        "ris_1": "F",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "ris_2": "F",
        "ris_3": "V",
        "immagine": 89,
        "risposta_2": "fronte occluso.",
        "risposta_1": "fronte freddo."
    },
    {
        "domanda": "Qual è l'angolo di rotazione del timone (intorno al suo asse) per ottenere il massimo effetto di governo?",
        "ris_1": "F",
        "ris_3": "V",
        "risposta_2": "tra 50 gradi e 90 gradi.",
        "id": 88,
        "ris_2": "F",
        "immagine": "",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "progressivo": "1.1.2-13",
        "risposta_1": "tra 90 gradi e 115 gradi.",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_3": "tra 30 gradi e 40 gradi."
    },
    {
        "ris_2": "V",
        "capitolo": "METEOROLOGIA",
        "risposta_3": "fronte caldo.",
        "risposta_1": "fronte freddo.",
        "risposta_2": "fronte stazionario.",
        "ris_3": "F",
        "progressivo": "1.6.2-12",
        "domanda": "Che tipo di fronte è rappresentato in figura?",
        "ris_1": "F",
        "id": 880,
        "immagine": 90,
        "argomento": "Bollettini meteorologici e  previsioni locali"
    },
    {
        "progressivo": "1.6.2-13",
        "id": 881,
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "immagine": 91,
        "capitolo": "METEOROLOGIA",
        "ris_1": "V",
        "risposta_3": "fronte caldo.",
        "ris_2": "F",
        "risposta_1": "fronte occluso",
        "ris_3": "F",
        "domanda": "Che tipo di fronte è rappresentato in figura?",
        "risposta_2": "fronte stazionario."
    },
    {
        "risposta_3": "Tramontana, Grecale e Levante.",
        "argomento": "Venti",
        "domanda": "Quali sono i venti del I quadrante?",
        "risposta_1": "Mezzogiorno, Libeccio e Ponente.",
        "immagine": "",
        "progressivo": "1.6.3-1",
        "ris_3": "V",
        "ris_2": "F",
        "risposta_2": "Ponente, Maestrale e Tramontana.",
        "ris_1": "F",
        "capitolo": "METEOROLOGIA",
        "id": 882
    },
    {
        "risposta_1": "Levante, Scirocco e Mezzogiorno.",
        "risposta_3": "Ponente, Maestrale e Tramontana.",
        "ris_3": "F",
        "risposta_2": "Mezzogiorno, Libeccio e Ponente.",
        "progressivo": "1.6.3-2",
        "ris_2": "F",
        "domanda": "Quali sono i venti del II quadrante?",
        "argomento": "Venti",
        "immagine": "",
        "capitolo": "METEOROLOGIA",
        "ris_1": "V",
        "id": 883
    },
    {
        "progressivo": "1.6.3-3",
        "ris_1": "F",
        "id": 884,
        "risposta_2": "Ponente, Maestrale e Tramontana.",
        "capitolo": "METEOROLOGIA",
        "argomento": "Venti",
        "risposta_3": "Mezzogiorno, Libeccio e Ponente.",
        "ris_3": "V",
        "domanda": "Quali sono i venti del III quadrante?",
        "risposta_1": "Tramontana, Grecale e Levante.",
        "immagine": "",
        "ris_2": "F"
    },
    {
        "domanda": "Quali sono i venti del IV quadrante?",
        "ris_3": "F",
        "ris_1": "V",
        "risposta_3": "Levante, Scirocco e Mezzogiorno.",
        "risposta_1": "Ponente, Maestrale e Tramontana.",
        "argomento": "Venti",
        "ris_2": "F",
        "progressivo": "1.6.3-4",
        "id": 885,
        "capitolo": "METEOROLOGIA",
        "risposta_2": "Tramontana, Grecale e Levante.",
        "immagine": ""
    },
    {
        "risposta_2": "l'orizzonte visibile, con il nome e la direzione di provenienza dei venti tipici del Mediterraneo.",
        "ris_3": "F",
        "progressivo": "1.6.3-5",
        "ris_1": "F",
        "immagine": "",
        "id": 886,
        "risposta_1": "nome, forza e direzione dei venti.",
        "risposta_3": "la destinazione dei venti principali.",
        "argomento": "Venti",
        "ris_2": "V",
        "capitolo": "METEOROLOGIA",
        "domanda": "La rosa dei venti rappresenta:"
    },
    {
        "progressivo": "1.6.2-14",
        "id": 887,
        "domanda": "Gli \"Avvisi di Burrasca\" sono diffusi via radio:",
        "ris_3": "F",
        "risposta_2": "preceduti dal segnale di sicurezza \"MAYDAY\".",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "ris_2": "F",
        "capitolo": "METEOROLOGIA",
        "ris_1": "V",
        "immagine": "",
        "risposta_3": "preceduti dal segnale di sicurezza \"PANPAN\".",
        "risposta_1": "preceduti dal segnale di sicurezza \"SECURITÈ\"."
    },
    {
        "ris_3": "V",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "capitolo": "METEOROLOGIA",
        "domanda": "Gli \"Avvisi di Tempesta\" o \"di Burrasca\":",
        "immagine": "",
        "risposta_1": "coprono un'area estesa quanto il mar Mediterraneo.",
        "id": 888,
        "ris_1": "F",
        "progressivo": "1.6.2-15",
        "risposta_3": "segnalano tempesta o burrasca in corso o imminente.",
        "risposta_2": "segnalano che una tempesta o burrasca si svilupperà non prima di 12 ore.",
        "ris_2": "F"
    },
    {
        "ris_3": "V",
        "risposta_3": "Ovest.",
        "id": 889,
        "domanda": "Il Ponente spira dalla direzione cardinale:",
        "immagine": "",
        "risposta_1": "Est.",
        "argomento": "Venti",
        "progressivo": "1.6.3-6",
        "capitolo": "METEOROLOGIA",
        "ris_1": "F",
        "risposta_2": "Nord.",
        "ris_2": "F"
    },
    {
        "ris_1": "V",
        "id": 89,
        "risposta_1": "accosta a dritta.",
        "domanda": "In marcia avanti, portando la ruota del timone a sinistra, come si comporta la poppa dell'unità?",
        "risposta_2": "accosta a sinistra.",
        "ris_3": "F",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "ris_2": "F",
        "progressivo": "1.1.2-14",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_3": "orza.",
        "immagine": ""
    },
    {
        "id": 890,
        "ris_2": "F",
        "capitolo": "METEOROLOGIA",
        "ris_3": "F",
        "risposta_3": "La velocità dell'imbarcazione.",
        "domanda": "L'anemometro misura:",
        "progressivo": "1.6.1-23",
        "risposta_1": "La velocità del vento.",
        "immagine": "",
        "argomento": "Elementi di meteorologia e strumenti",
        "ris_1": "V",
        "risposta_2": "La velocità della corrente."
    },
    {
        "risposta_2": "Sud-Ovest.",
        "risposta_3": "Nord-Ovest.",
        "argomento": "Venti",
        "id": 891,
        "ris_1": "V",
        "immagine": "",
        "ris_2": "F",
        "risposta_1": "Sud-Est.",
        "capitolo": "METEOROLOGIA",
        "progressivo": "1.6.3-7",
        "domanda": "Da quale direzione proviene il vento di Scirocco?",
        "ris_3": "F"
    },
    {
        "capitolo": "METEOROLOGIA",
        "id": 892,
        "risposta_2": "avvisi urgenti ai naviganti (Avurnav).",
        "ris_2": "F",
        "immagine": "",
        "ris_3": "V",
        "risposta_1": "sorgere e tramonto del sole, per valutare la formazione delle nebbie.",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "risposta_3": "avvisi (es. di temporali o di burrasca), in corso o previsti.",
        "progressivo": "1.6.2-16",
        "domanda": "Quali tra le seguenti informazioni sono riportate nel Meteomar:",
        "ris_1": "F"
    },
    {
        "ris_2": "F",
        "id": 893,
        "risposta_3": "Sud-Ovest.",
        "risposta_2": "Sud-Est.",
        "risposta_1": "Nord-Est.",
        "immagine": "",
        "argomento": "Venti",
        "ris_1": "V",
        "ris_3": "F",
        "progressivo": "1.6.3-8",
        "domanda": "Da quale direzione proviene il vento di Grecale?",
        "capitolo": "METEOROLOGIA"
    },
    {
        "progressivo": "1.6.3-9",
        "id": 894,
        "ris_3": "V",
        "risposta_2": "Levante.",
        "ris_1": "F",
        "risposta_3": "Scirocco.",
        "ris_2": "F",
        "capitolo": "METEOROLOGIA",
        "immagine": "",
        "risposta_1": "Grecale.",
        "argomento": "Venti",
        "domanda": "Quale vento spira da 135 gradi?"
    },
    {
        "risposta_3": "Grecale.",
        "immagine": "",
        "risposta_1": "Levante",
        "ris_1": "F",
        "argomento": "Venti",
        "domanda": "Da Nord - Est spira il:",
        "progressivo": "1.6.3-10",
        "capitolo": "METEOROLOGIA",
        "ris_3": "V",
        "ris_2": "F",
        "risposta_2": "Ponente.",
        "id": 895
    },
    {
        "ris_3": "V",
        "progressivo": "1.6.2-17",
        "immagine": "",
        "domanda": "Come viene diffuso il bollettino Meteomar?",
        "risposta_2": "sul canale VHF 16, alle ore sinottiche principali (UTC).",
        "risposta_3": "sul canale 68, di continuo.",
        "ris_1": "F",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "capitolo": "METEOROLOGIA",
        "risposta_1": "sul canale VHF 78, di continuo.",
        "id": 896,
        "ris_2": "F"
    },
    {
        "immagine": "",
        "id": 897,
        "domanda": "La Brezza soffia dal mare perchè:",
        "risposta_2": "sia la terraferma che il mare raggiungono la stessa temperatura ed il vento spira dal mare.",
        "progressivo": "1.6.1-24",
        "risposta_3": "la terraferma si raffredda più in fretta del mare.",
        "ris_2": "F",
        "risposta_1": "la terraferma si scalda più in fretta del mare.",
        "ris_1": "V",
        "argomento": "Elementi di meteorologia e strumenti",
        "capitolo": "METEOROLOGIA",
        "ris_3": "F"
    },
    {
        "capitolo": "METEOROLOGIA",
        "id": 898,
        "immagine": "",
        "ris_1": "F",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "domanda": "Il Meteomar emesso alle ore 12:00 UTC di oggi:",
        "risposta_3": "è valido sino alle ore 00:00 UTC di domani.",
        "ris_2": "F",
        "risposta_2": "è valido sino alle ore 12:00 UTC di domani.",
        "risposta_1": "è valido sino alle ore 18:00 UTC di oggi.",
        "progressivo": "1.6.2-18",
        "ris_3": "V"
    },
    {
        "risposta_2": "differenti valori di temperatura e pressione dell'aria.",
        "ris_3": "F",
        "domanda": "Il vento è originato da?",
        "ris_2": "V",
        "capitolo": "METEOROLOGIA",
        "risposta_3": "gradiente termico verticale e umidità dell'aria.",
        "immagine": "",
        "progressivo": "1.6.1-25",
        "id": 899,
        "argomento": "Elementi di meteorologia e strumenti",
        "ris_1": "F",
        "risposta_1": "instabilità e umidità dell'aria."
    },
    {
        "argomento": "Nomenclatura delle parti principali dello scafo",
        "progressivo": "1.1.1-9",
        "immagine": "",
        "id": 9,
        "risposta_1": "l'ambiente di bordo dove sono sistemati i motori principali e la gran parte dei sistemi ausiliari.",
        "ris_1": "V",
        "ris_3": "F",
        "risposta_3": "l'ambiente dovo sono collocati esclusivamente i sistemi ausiliari.",
        "domanda": "Cosa si intende per locale macchine o locale apparato motore di un'unità?",
        "risposta_2": "il locale di bordo, generalmente situato a poppa, individuato come garage.",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_2": "F"
    },
    {
        "progressivo": "1.1.2-15",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "risposta_2": "antiorario nella marcia indietro.",
        "risposta_1": "antiorario nella marcia avanti.",
        "id": 90,
        "ris_1": "V",
        "domanda": "Un'elica si definisce sinistrorsa quando, guardando la poppa dall'esterno, le pale girano in senso:",
        "risposta_3": "orario nella marcia avanti.",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_3": "F",
        "immagine": "",
        "ris_2": "F"
    },
    {
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "ris_3": "V",
        "capitolo": "METEOROLOGIA",
        "ris_1": "F",
        "risposta_3": "la tendenza dello stato del mare nelle 12 ore successive al periodo di validità della “Previsione”.",
        "ris_2": "F",
        "risposta_1": "una possibile burrasca.",
        "domanda": "La sezione \"Tendenza\" nel Meteomar indica:",
        "progressivo": "1.6.2-19",
        "immagine": "",
        "risposta_2": "la direzione di provenienza e la forza del vento per le prossime 96 ore.",
        "id": 900
    },
    {
        "risposta_2": "nei caldi pomeriggi estivi.",
        "id": 901,
        "ris_1": "V",
        "capitolo": "METEOROLOGIA",
        "progressivo": "1.6.1-26",
        "ris_2": "F",
        "argomento": "Elementi di meteorologia e strumenti",
        "risposta_3": "nelle calde serate estive.",
        "immagine": "",
        "domanda": "Le brezze hanno origine:",
        "ris_3": "F",
        "risposta_1": "se ci sono differenze di riscaldamento tra mare e terraferma."
    },
    {
        "immagine": "",
        "progressivo": "1.6.1-27",
        "risposta_1": "e il mare raggiungono la stessa temperatura.",
        "ris_1": "F",
        "id": 902,
        "capitolo": "METEOROLOGIA",
        "ris_3": "F",
        "risposta_2": "si raffredda più in fretta del mare.",
        "domanda": "La Brezza spira da terra di notte perché la terraferma:",
        "ris_2": "V",
        "argomento": "Elementi di meteorologia e strumenti",
        "risposta_3": "si scalda più in fretta del mare."
    },
    {
        "id": 903,
        "risposta_3": "dalle Capitanerie di porto.",
        "capitolo": "METEOROLOGIA",
        "progressivo": "1.6.2-20",
        "ris_2": "V",
        "ris_3": "F",
        "ris_1": "F",
        "risposta_1": "dal Ministero dello Sviluppo Economico (ex Comunicazioni).",
        "risposta_2": "dalle Stazioni Radio costiere.",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "immagine": "",
        "domanda": "Il Meteomar è trasmesso:"
    },
    {
        "risposta_3": "Tramontana.",
        "ris_1": "V",
        "risposta_2": "Ponente.",
        "ris_2": "F",
        "ris_3": "F",
        "domanda": "Da Est spira:",
        "capitolo": "METEOROLOGIA",
        "immagine": "",
        "id": 904,
        "risposta_1": "Levante.",
        "argomento": "Venti",
        "progressivo": "1.6.3-11"
    },
    {
        "risposta_3": "forniscono informazioni su venti forza 12.",
        "immagine": "",
        "risposta_1": "sono diffusi via VHF con precedenza assoluta su tutti gli altri messaggi di natura meteorologica.",
        "risposta_2": "sono diffusi via VHF in coda con gli altri messaggi di natura meteorologica.",
        "ris_1": "V",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "ris_3": "F",
        "capitolo": "METEOROLOGIA",
        "domanda": "Gli Avvisi di burrasca (Gale Warnings):",
        "progressivo": "1.6.2-21",
        "ris_2": "F",
        "id": 905
    },
    {
        "progressivo": "1.6.1-28",
        "immagine": "",
        "ris_2": "V",
        "risposta_2": "di giorno soffia dal mare verso la terraferma.",
        "domanda": "La brezza:",
        "ris_1": "F",
        "id": 906,
        "capitolo": "METEOROLOGIA",
        "argomento": "Elementi di meteorologia e strumenti",
        "risposta_1": "di notte risente della condizione in base alla quale il mare si raffredda più in fretta della terraferma.",
        "risposta_3": "è un indicatore di condizioni generali di cattivo tempo.",
        "ris_3": "F"
    },
    {
        "ris_3": "F",
        "ris_2": "V",
        "progressivo": "1.6.1-29",
        "domanda": "La brezza di terra spira:",
        "argomento": "Elementi di meteorologia e strumenti",
        "capitolo": "METEOROLOGIA",
        "immagine": "",
        "risposta_3": "di giorno.",
        "ris_1": "F",
        "id": 907,
        "risposta_2": "di notte.",
        "risposta_1": "ininterrottamente per 24 ore al giorno."
    },
    {
        "risposta_3": "incomprensibile.",
        "risposta_2": "alta.",
        "ris_3": "F",
        "progressivo": "1.6.1-30",
        "ris_2": "F",
        "ris_1": "V",
        "id": 908,
        "risposta_1": "ripida.",
        "domanda": "Con corrente e vento, l'un l'altro contro in direzione opposta, l'onda è:",
        "capitolo": "METEOROLOGIA",
        "argomento": "Elementi di meteorologia e strumenti",
        "immagine": ""
    },
    {
        "ris_2": "F",
        "risposta_2": "dal raffreddamento del mare.",
        "domanda": "La brezza di terra è innescata:",
        "immagine": "",
        "argomento": "Elementi di meteorologia e strumenti",
        "progressivo": "1.6.1-31",
        "risposta_3": "dal riscaldamento della terraferma da parte del sole.",
        "risposta_1": "dal rapido raffreddamento della terraferma rispetto al mare.",
        "id": 909,
        "ris_3": "F",
        "capitolo": "METEOROLOGIA",
        "ris_1": "V"
    },
    {
        "risposta_2": "diametro teorico.",
        "ris_2": "F",
        "ris_3": "F",
        "capitolo": "TEORIA DELLO SCAFO",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "risposta_1": "passo teorico.",
        "immagine": "",
        "ris_1": "V",
        "risposta_3": "regresso teorico.",
        "progressivo": "1.1.2-16",
        "domanda": "La distanza teorica che un'elica percorrerebbe in un giro completo se l'acqua fosse solida è denominata:",
        "id": 91
    },
    {
        "immagine": "",
        "progressivo": "1.6.1-32",
        "risposta_3": "di giorno è dovuta alla pressione più alta sulla terraferma che sul mare.",
        "id": 910,
        "capitolo": "METEOROLOGIA",
        "ris_1": "F",
        "ris_2": "V",
        "ris_3": "F",
        "risposta_2": "di notte spira dalla terraferma verso il mare.",
        "argomento": "Elementi di meteorologia e strumenti",
        "risposta_1": "è più consistente nelle giornate di pioggia.",
        "domanda": "La brezza:"
    },
    {
        "domanda": "La sezione \"Tendenza” circa il vento indicato nel Meteomar:",
        "progressivo": "1.6.2-22",
        "risposta_2": "fornisce la tendenza del vento nelle 24 ore successive al periodo di validità del Meteomar medesimo.",
        "ris_3": "F",
        "risposta_1": "fornisce la tendenza del vento nelle 12 ore successive al periodo di validità del Meteomar medesimo.",
        "id": 911,
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "risposta_3": "fornisce previsioni relative alle 48 ore successive al periodo di validità del Meteomar medesimo.",
        "ris_1": "V",
        "capitolo": "METEOROLOGIA",
        "ris_2": "F",
        "immagine": ""
    },
    {
        "immagine": "",
        "ris_1": "F",
        "ris_2": "F",
        "capitolo": "METEOROLOGIA",
        "id": 912,
        "progressivo": "1.6.3-12",
        "argomento": "Venti",
        "risposta_2": "della tramontana è tra il II° e il III° quadrante.",
        "ris_3": "V",
        "risposta_1": "del Grecale è da E.",
        "risposta_3": "del maestrale è da NW.",
        "domanda": "La direzione di provenienza:"
    },
    {
        "id": 913,
        "risposta_1": "Nord-Ovest.",
        "immagine": "",
        "progressivo": "1.6.3-13",
        "argomento": "Venti",
        "ris_2": "V",
        "risposta_3": "Sud-Est",
        "ris_3": "F",
        "risposta_2": "Sud-Ovest.",
        "domanda": "Da quale direzione proviene il vento di Libeccio?",
        "capitolo": "METEOROLOGIA",
        "ris_1": "F"
    },
    {
        "risposta_2": "è un indicatore di condizioni generali di cattivo tempo.",
        "risposta_3": "di notte è ragionevolmente dovuta al più rapido raffreddamento della terraferma rispetto al mare.",
        "risposta_1": "di notte soffia dal mare verso la terra.",
        "progressivo": "1.6.1-33",
        "domanda": "La brezza:",
        "capitolo": "METEOROLOGIA",
        "ris_3": "V",
        "ris_1": "F",
        "argomento": "Elementi di meteorologia e strumenti",
        "ris_2": "F",
        "id": 914,
        "immagine": ""
    },
    {
        "argomento": "Venti",
        "ris_1": "F",
        "id": 915,
        "risposta_3": "Levante.",
        "immagine": "",
        "risposta_2": "Ponente.",
        "risposta_1": "Scirocco.",
        "domanda": "Il vento che viene da 270 gradi si chiama:",
        "capitolo": "METEOROLOGIA",
        "ris_2": "V",
        "progressivo": "1.6.3-14",
        "ris_3": "F"
    },
    {
        "id": 916,
        "immagine": "",
        "ris_1": "F",
        "risposta_3": "generalmente, se il tempo peggiora la pressione aumenta.",
        "ris_3": "F",
        "progressivo": "1.6.1-34",
        "ris_2": "V",
        "risposta_1": "con il barometro misuro il valore della tendenza barografica istantanea.",
        "domanda": "Quale affermazione è corretta tra le seguenti:",
        "risposta_2": "la pressione dell'aria è misurata con il barometro.",
        "argomento": "Elementi di meteorologia e strumenti",
        "capitolo": "METEOROLOGIA"
    },
    {
        "progressivo": "1.6.3-15",
        "ris_3": "F",
        "ris_2": "F",
        "risposta_3": "non sono lo stesso vento.",
        "ris_1": "V",
        "id": 917,
        "capitolo": "METEOROLOGIA",
        "argomento": "Venti",
        "risposta_2": "non sono venti.",
        "risposta_1": "sono lo stesso vento.",
        "immagine": "",
        "domanda": "Ostro e Mezzogiorno:"
    },
    {
        "argomento": "Elementi di meteorologia e strumenti",
        "risposta_2": "il barometro misura la pressione dell’aria.",
        "domanda": "Quale affermazione tra le seguenti è corretta:",
        "risposta_3": "in genere se il tempo peggiora l'umidità diminuisce.",
        "progressivo": "1.6.1-35",
        "immagine": "",
        "ris_3": "F",
        "capitolo": "METEOROLOGIA",
        "risposta_1": "il barometro misura la temperatura dell’aria.",
        "ris_2": "V",
        "ris_1": "F",
        "id": 918
    },
    {
        "domanda": "Individuare la corretta direzione di provenienza:",
        "ris_2": "F",
        "ris_3": "F",
        "risposta_1": "la Tramontana spira da N.",
        "ris_1": "V",
        "risposta_3": "l'Ostro spira da NW.",
        "argomento": "Venti",
        "capitolo": "METEOROLOGIA",
        "immagine": "",
        "risposta_2": "il Libeccio spira da 135 gradi.",
        "progressivo": "1.6.3-16",
        "id": 919
    },
    {
        "domanda": "L'elica con passo lungo e diametro piccolo, rispetto a una, che al contrario, ha passo piccolo e diametro più grande:",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_2": "F",
        "risposta_3": "è indifferente.",
        "ris_1": "V",
        "immagine": "",
        "risposta_1": "produce maggiore velocità.",
        "risposta_2": "produce maggior spinta.",
        "id": 92,
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "progressivo": "1.1.2-17",
        "ris_3": "F"
    },
    {
        "ris_2": "V",
        "ris_3": "F",
        "id": 920,
        "domanda": "Da quale direzione proviene il vento di Libeccio?",
        "risposta_2": "Sud-Ovest.",
        "ris_1": "F",
        "capitolo": "METEOROLOGIA",
        "risposta_1": "Nord-Ovest.",
        "progressivo": "1.6.3-17",
        "immagine": "",
        "risposta_3": "Sud-Est.",
        "argomento": "Venti"
    },
    {
        "ris_3": "F",
        "argomento": "Venti",
        "immagine": "",
        "capitolo": "METEOROLOGIA",
        "domanda": "Individuare l'affermazione corretta:",
        "progressivo": "1.6.3-17",
        "id": 921,
        "risposta_3": "il Levante spira da 135 gradi.",
        "ris_1": "V",
        "risposta_2": "lo Scirocco spira da 225 gradi.",
        "ris_2": "F",
        "risposta_1": "l'Ostro spira da 180 gradi."
    },
    {
        "ris_1": "F",
        "capitolo": "METEOROLOGIA",
        "domanda": "Riguardo al bollettino meteomar:",
        "risposta_2": "orari e canali di servizio sono riportati sulla pubblicazione Radioservizi per la navigazione edita dall'I.I.M.M.",
        "risposta_3": "orari e canali di servizio sono riportati sulla pubblicazione Radioservizi per la navigazione edita dall'A.M.",
        "risposta_1": "la trasmissione avviene tramite le Capitanerie di porto.",
        "id": 922,
        "progressivo": "1.6.2-23",
        "immagine": "",
        "ris_3": "F",
        "ris_2": "V",
        "argomento": "Bollettini meteorologici e  previsioni locali"
    },
    {
        "risposta_1": "barografo.",
        "id": 923,
        "domanda": "L’umidità relativa si misura con:",
        "argomento": "Elementi di meteorologia e strumenti",
        "capitolo": "METEOROLOGIA",
        "ris_1": "F",
        "progressivo": "1.6.1-36",
        "risposta_2": "barometro.",
        "ris_3": "V",
        "risposta_3": "igrometro.",
        "immagine": "",
        "ris_2": "F"
    },
    {
        "risposta_3": "temperatura tra due zone.",
        "risposta_2": "pressione atmosferica tra due zone.",
        "argomento": "Elementi di meteorologia e strumenti",
        "ris_2": "F",
        "progressivo": "1.6.1-37",
        "ris_1": "F",
        "domanda": "La formazione delle brezze è innescata dalla diversa:",
        "ris_3": "V",
        "immagine": "",
        "capitolo": "METEOROLOGIA",
        "risposta_1": "umidità tra due zone.",
        "id": 924
    },
    {
        "ris_3": "F",
        "risposta_1": "più leggera di quella fredda.",
        "risposta_2": "più pesante di quella fredda.",
        "risposta_3": "data dai venti che soffiano da E e NE.",
        "ris_1": "V",
        "immagine": "",
        "progressivo": "1.6.1-38",
        "argomento": "Elementi di meteorologia e strumenti",
        "domanda": "L’aria, se calda, è:",
        "id": 925,
        "ris_2": "F",
        "capitolo": "METEOROLOGIA"
    },
    {
        "domanda": "La pressione atmosferica viene considerata:",
        "immagine": "",
        "risposta_2": "normale, se uguale a 1023,2 hPa; alta se superiore e bassa se inferiore al suddetto valore.",
        "ris_2": "F",
        "capitolo": "METEOROLOGIA",
        "risposta_1": "normale, se uguale a 1003,2 hPa; alta se superiore e bassa se inferiore al suddetto valore.",
        "id": 926,
        "progressivo": "1.6.1-38",
        "ris_1": "F",
        "risposta_3": "normale, se uguale a 1013,2 hPa; alta se superiore e bassa se inferiore al suddetto valore.",
        "ris_3": "V",
        "argomento": "Elementi di meteorologia e strumenti"
    },
    {
        "immagine": "",
        "risposta_2": "se la pressione diminuisce il tempo volgerà al bello.",
        "ris_1": "F",
        "argomento": "Elementi di meteorologia e strumenti",
        "risposta_1": "lo stato del mare è misurato con la scala di Dorrestein.",
        "ris_3": "V",
        "progressivo": "1.6.1-39",
        "capitolo": "METEOROLOGIA",
        "id": 927,
        "domanda": "Quale affermazione tra le seguenti è corretta:",
        "ris_2": "F",
        "risposta_3": "generalmente, l'orizzonte chiaro, con calma di vento, preannuncia bel tempo."
    },
    {
        "ris_2": "V",
        "capitolo": "METEOROLOGIA",
        "id": 928,
        "immagine": "",
        "risposta_2": "vento da 0 a 12, mare da 0 a\n9.",
        "risposta_3": "vento da 1 a 10, mare da 1 a\n9.",
        "risposta_1": "vento da 0 a 14, mare da 0 a\n10.",
        "domanda": "Quali sono i valori minimi e massimi delle scale del vento e del mare?",
        "ris_3": "F",
        "ris_1": "F",
        "progressivo": "1.6.1-40",
        "argomento": "Elementi di meteorologia e strumenti"
    },
    {
        "id": 929,
        "risposta_2": "il vento di libeccio è un vento cardinale.",
        "progressivo": "1.6.3-18",
        "risposta_1": "il vento di levante proviene da oriente.",
        "capitolo": "METEOROLOGIA",
        "ris_1": "V",
        "ris_3": "F",
        "risposta_3": "il vento di ponente spira verso ovest.",
        "immagine": "",
        "ris_2": "F",
        "domanda": "Quale affermazione è corretta tra le seguenti:",
        "argomento": "Venti"
    },
    {
        "ris_3": "F",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "risposta_3": "esclusivamente lo sbandamento.",
        "id": 93,
        "immagine": "",
        "capitolo": "TEORIA DELLO SCAFO",
        "progressivo": "1.1.2-18",
        "risposta_1": "spostamento laterale sul lato dell'accostata, lieve appoppamento.",
        "risposta_2": "riduzione di velocità, spostamento laterale sul lato opposto a quello della pala, leggero appruamento.",
        "domanda": "In generale, oltre all'accostata, l'impiego del timone con pala produce i seguenti effetti:",
        "ris_2": "V",
        "ris_1": "F"
    },
    {
        "argomento": "Venti",
        "ris_3": "V",
        "immagine": "",
        "capitolo": "METEOROLOGIA",
        "ris_1": "F",
        "risposta_1": "il levante spira tra N e NNW.",
        "progressivo": "1.6.3-19",
        "id": 930,
        "ris_2": "F",
        "risposta_3": "lo scirocco spira da SE.",
        "domanda": "Quale affermazione è corretta tra le seguenti:",
        "risposta_2": "il libeccio spira tra S e SSE."
    },
    {
        "risposta_3": "verso la quale si dirigono.",
        "domanda": "I venti che spirano dai 4 punti intercardinali (NE, SE, SW, NW) prendono il nome dalla regione:",
        "id": 931,
        "progressivo": "1.6.3-20",
        "ris_1": "V",
        "ris_2": "F",
        "capitolo": "METEOROLOGIA",
        "risposta_1": "di provenienza.",
        "ris_3": "F",
        "risposta_2": "dove si manifestano più frequentemente (es.libeccio in Libia).",
        "immagine": "",
        "argomento": "Venti"
    },
    {
        "ris_1": "V",
        "id": 932,
        "argomento": "Elementi di meteorologia e strumenti",
        "immagine": "",
        "progressivo": "1.6.1-41",
        "risposta_2": "diminuisce dietro, alle spalle del fronte, e dopo di nuovo aumenta repentinamente.",
        "ris_3": "F",
        "risposta_3": "diminuisce.",
        "domanda": "Al passaggio di un fronte freddo, la pressione:",
        "ris_2": "F",
        "risposta_1": "sale bruscamente.",
        "capitolo": "METEOROLOGIA"
    },
    {
        "domanda": "Una \"Burrasca\":",
        "risposta_1": "corrisponde a un termine descrittivo della Forza del vento.",
        "capitolo": "METEOROLOGIA",
        "ris_3": "F",
        "ris_1": "V",
        "risposta_3": "corrisponde a un gergo marinaro usato per esprimere un tempo perturbato in zone lontane.",
        "progressivo": "1.6.2-24",
        "id": 933,
        "immagine": "",
        "ris_2": "F",
        "risposta_2": "corrisponde a uno stato del mare abbastanza agitato.",
        "argomento": "Bollettini meteorologici e  previsioni locali"
    },
    {
        "ris_3": "F",
        "id": 934,
        "domanda": "I \"Cirri\" sono:",
        "ris_1": "V",
        "risposta_1": "le nubi più alte che di norma indicano bel tempo se la pressione è stazionaria o in salita.",
        "capitolo": "METEOROLOGIA",
        "immagine": "",
        "risposta_3": "le nubi da cui è possibile prevedere l'arrivo brusco di un fronte freddo e le piogge entro 6 ore.",
        "progressivo": "1.6.2-25",
        "ris_2": "F",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "risposta_2": "le nubi di altezza media tra 2000 e 6000 m."
    },
    {
        "immagine": "",
        "risposta_3": "della stagione.",
        "capitolo": "METEOROLOGIA",
        "domanda": "La violenza di un temporale è in funzione:",
        "ris_3": "F",
        "id": 935,
        "risposta_1": "dello sviluppo verticale della nube.",
        "ris_2": "F",
        "progressivo": "1.6.2-26",
        "ris_1": "V",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "risposta_2": "dell'escursione termica."
    },
    {
        "risposta_1": "è quella linea di separazione sussistente tra due correnti di stessa intensità ma con verso opposto.",
        "ris_3": "V",
        "immagine": "",
        "ris_1": "F",
        "id": 936,
        "progressivo": "1.6.2-27",
        "domanda": "Il fronte:",
        "capitolo": "METEOROLOGIA",
        "risposta_2": "è la linea che separa due strati di cumuli-nembi e nembo-strati.",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "ris_2": "F",
        "risposta_3": "è una linea che esprime la superficie di contatto o di discontinuità che separa due masse d'aria."
    },
    {
        "ris_1": "F",
        "risposta_1": "nubi grigie stratiformi.",
        "domanda": "I \"Cumuli\" sono:",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "progressivo": "1.6.2-28",
        "capitolo": "METEOROLOGIA",
        "ris_2": "F",
        "immagine": "",
        "risposta_3": "nubi a sviluppo verticale.",
        "risposta_2": "le nubi più alte di aspetto chiaro e filamentoso.",
        "ris_3": "V",
        "id": 937
    },
    {
        "progressivo": "1.6.2-29",
        "risposta_2": "una condizione del mare caratterizzata da onde corte e ripide.",
        "ris_2": "F",
        "domanda": "Il \"fetch minimo\" è:",
        "risposta_1": "il tratto di mare, privo di ostacoli, sul quale soffia un vento per un certo periodo, oltre il quale tratto di mare le onde raggiungeranno la massima altezza per quel dato vento.",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "ris_1": "V",
        "capitolo": "METEOROLOGIA",
        "id": 938,
        "risposta_3": "un vento caldo e secco discendente da una catena montuosa.",
        "immagine": "",
        "ris_3": "F"
    },
    {
        "ris_3": "F",
        "domanda": "In genere la pressione aumenta se:",
        "progressivo": "1.6.2-30",
        "capitolo": "METEOROLOGIA",
        "ris_1": "V",
        "risposta_2": "spirano venti freddi dal 2° e 3° quadrante.",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "ris_2": "F",
        "risposta_1": "spirano venti freddi dal 4° e 1° quadrante.",
        "id": 939,
        "risposta_3": "nessuna delle due affermazioni suddette è corretta.",
        "immagine": ""
    },
    {
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_1": "è riferita alla velocità dell'unità.",
        "progressivo": "1.1.2-19",
        "ris_1": "F",
        "ris_2": "V",
        "domanda": "La curva di evoluzione:",
        "risposta_2": "è la traiettoria descritta dall'unità che accosta verso dritta ovvero sinistra.",
        "ris_3": "F",
        "immagine": "",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "id": 94,
        "risposta_3": "descrive la rotazione del timone"
    },
    {
        "ris_2": "V",
        "risposta_1": "gli altocumuli",
        "immagine": "",
        "ris_1": "F",
        "risposta_2": "i cumulonembi.",
        "domanda": "Sono nuvole temporalesche:",
        "progressivo": "1.6.2-31",
        "id": 940,
        "capitolo": "METEOROLOGIA",
        "risposta_3": "i nembostrati.",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "ris_3": "F"
    },
    {
        "risposta_3": "la pressione aumenta bruscamente, il vento rinforza con raffiche.",
        "ris_1": "F",
        "capitolo": "METEOROLOGIA",
        "ris_3": "V",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "ris_2": "F",
        "id": 941,
        "domanda": "Generalmente, al passaggio di un fronte freddo:",
        "risposta_1": "la pressione diminuisce in modo irregolare, inoltre la temperatura e l'umidità sono in aumento.",
        "risposta_2": "la visibilità peggiora, vi sono nubi di tipo altostratiforme e nebbie.",
        "immagine": "",
        "progressivo": "1.6.2-32"
    },
    {
        "id": 942,
        "risposta_1": "scarse.",
        "risposta_2": "di forte intensità e a intermittenza.",
        "ris_3": "F",
        "progressivo": "1.6.2-33",
        "risposta_3": "poco intense.",
        "domanda": "Generalmente, con aria instabile si hanno precipitazioni:",
        "capitolo": "METEOROLOGIA",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "ris_1": "F",
        "immagine": "",
        "ris_2": "V"
    },
    {
        "ris_3": "F",
        "argomento": "Elementi di meteorologia e strumenti",
        "risposta_2": "lo spostamento verticale delle acque marine generato dalla marea.",
        "risposta_3": "lo spostamento obliquo delle acque marine generato dalla marea.",
        "progressivo": "1.6.1.97",
        "ris_1": "V",
        "capitolo": "METEOROLOGIA",
        "risposta_1": "lo spostamento orizzontale delle acque marine generato dalla marea, non collegato al moto ondoso",
        "immagine": "",
        "domanda": "Come si definisce una corrente di marea?",
        "id": 943,
        "ris_2": "F"
    },
    {
        "argomento": "Elementi di meteorologia e strumenti",
        "immagine": "",
        "id": 944,
        "ris_3": "F",
        "progressivo": "1.6.1-42",
        "ris_2": "F",
        "capitolo": "METEOROLOGIA",
        "risposta_2": "un movimento di masse d'acqua generato dal moto ondoso o dalla marea.",
        "domanda": "Una corrente marina è:",
        "risposta_1": "un movimento di masse d'acqua non derivante dal moto ondoso o dalla marea.",
        "risposta_3": "un movimento di masse d'acqua generato dall'azione combinata delle maree e del moto ondoso.",
        "ris_1": "V"
    },
    {
        "capitolo": "METEOROLOGIA",
        "risposta_1": "buona, a volte ottima.",
        "risposta_2": "scarsa.",
        "ris_2": "F",
        "id": 945,
        "risposta_3": "nessuna delle due affermazioni suddette è corretta.",
        "domanda": "In genere, con aria instabile la visibilità è:",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "ris_3": "F",
        "immagine": "",
        "progressivo": "1.6.2-34",
        "ris_1": "V"
    },
    {
        "id": 946,
        "domanda": "Riguardo alla corrente marina, è possibile affermare che:",
        "ris_2": "F",
        "capitolo": "METEOROLOGIA",
        "risposta_2": "l'intero ciclo copre un periodo di alcune ore.",
        "argomento": "Elementi di meteorologia e strumenti",
        "ris_3": "V",
        "progressivo": "1.6.1-43",
        "risposta_1": "si verifica in acque relativamente basse e negli stretti, e relative adiacenze, colleganti due bacini.",
        "ris_1": "F",
        "immagine": "",
        "risposta_3": "si verifica in acque profonde e in mari aperti e che risente del moto di rotazione terrestre."
    },
    {
        "immagine": "",
        "risposta_3": "la massa d'acqua interessata ha una sua densità e temperatura diversa dalla massa d'acqua circostante.",
        "ris_1": "V",
        "domanda": "La corrente di marea:",
        "ris_3": "F",
        "argomento": "Elementi di meteorologia e strumenti",
        "capitolo": "METEOROLOGIA",
        "progressivo": "1.6.1-44",
        "risposta_2": "è un fenomeno stagionale.",
        "ris_2": "F",
        "risposta_1": "si verifica in acque relativamente basse e negli stretti, e relative adiacenze, colleganti due bacini.",
        "id": 947
    },
    {
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "ris_3": "F",
        "risposta_1": "una persistente situazione di stallo e di maltempo.",
        "ris_1": "V",
        "domanda": "Un fronte stazionario indica:",
        "ris_2": "F",
        "progressivo": "1.6.2-35",
        "capitolo": "METEOROLOGIA",
        "id": 948,
        "risposta_3": "un fronte che si muove poco.",
        "risposta_2": "un fronte attivo di temporali.",
        "immagine": ""
    },
    {
        "ris_1": "V",
        "ris_2": "F",
        "risposta_3": "è a raffiche quando direzione e velocità medie variano notevolmente e improvvisamente.",
        "capitolo": "METEOROLOGIA",
        "risposta_2": "la direzione media muta continuamente mentre la velocità media rimane costante.",
        "immagine": "",
        "risposta_1": "la direzione media e la velocità media si mantengono costanti per un certo periodo di tempo.",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "domanda": "Il vento è teso quando:",
        "ris_3": "F",
        "id": 949,
        "progressivo": "1.6.2-36"
    },
    {
        "progressivo": "1.1.2-20",
        "risposta_2": "se in moto indietro, con barra a dritta la poppa accosta a sinistra.",
        "ris_1": "F",
        "domanda": "Riguardo al funzionamento del timone a barra, abbiamo che:",
        "risposta_3": "se in moto indietro, con barra a dritta la poppa accosta a dritta.",
        "id": 95,
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_3": "F",
        "risposta_1": "se in moto avanti, con barra a dritta la prora accosta a dritta.",
        "ris_2": "V",
        "immagine": ""
    },
    {
        "domanda": "I vento è a raffiche quando:",
        "risposta_1": "la direzione media rimane costante mentre la velocità media presenta improvvisi picchi con valori di almeno 10 nodi oltre la media e di durata inferiore al minuto.",
        "ris_2": "F",
        "capitolo": "METEOROLOGIA",
        "risposta_2": "quando la direzione media muta continuamente mentre la velocità media rimane costante per un certo periodo di tempo.",
        "risposta_3": "quando direzione e velocità medie non variano, come accade nel corso di temporali.",
        "ris_3": "F",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "immagine": "",
        "ris_1": "V",
        "progressivo": "1.6.2-37",
        "id": 950
    },
    {
        "domanda": "Il Foehn (o Fohn) indica:",
        "risposta_2": "un vento che, per effetto di un ostacolo orografico, è costretto a salire di quota lungo il versante sopravento.",
        "id": 951,
        "ris_1": "V",
        "ris_2": "F",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "risposta_3": "un vento anabatico.",
        "ris_3": "F",
        "risposta_1": "un vento che discende forzatamente di quota lungo il versante sottovento di un ostacolo orografico.",
        "progressivo": "1.6.2-38",
        "immagine": "",
        "capitolo": "METEOROLOGIA"
    },
    {
        "risposta_1": "le maree alle quadrature.",
        "ris_3": "V",
        "progressivo": "1.6.2-39",
        "immagine": "",
        "capitolo": "METEOROLOGIA",
        "risposta_3": "il vento.",
        "risposta_2": "la sabbia quando è vicina alle rocce.",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "domanda": "Cosa provoca il moto ondoso?",
        "ris_1": "F",
        "id": 952,
        "ris_2": "F"
    },
    {
        "immagine": "",
        "id": 953,
        "risposta_3": "verticale tra la cresta di un'onda e l'incavo dell'onda successiva.",
        "ris_2": "V",
        "ris_3": "F",
        "domanda": "La lunghezza di un'onda è data dalla distanza:",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "risposta_1": "orizzontale tra due incavi successivi.",
        "progressivo": "1.6.2-40",
        "capitolo": "METEOROLOGIA",
        "risposta_2": "orizzontale tra due creste successive.",
        "ris_1": "F"
    },
    {
        "risposta_3": "tra il frangente e l'incavo.",
        "ris_2": "F",
        "ris_1": "V",
        "progressivo": "1.6.2-41",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "ris_3": "F",
        "immagine": "",
        "id": 954,
        "capitolo": "METEOROLOGIA",
        "risposta_1": "tra la cresta e l'incavo.",
        "domanda": "L'altezza di un'onda è data dalla distanza verticale:",
        "risposta_2": "tra la cresta e il frangente."
    },
    {
        "domanda": "Generalmente un'onda frange quando:",
        "risposta_2": "il rapporto tra altezza e lunghezza (ripidità) dell'onda è maggiore di 1/25.",
        "ris_1": "F",
        "capitolo": "METEOROLOGIA",
        "risposta_3": "il rapporto tra altezza e lunghezza (ripidità) dell'onda è maggiore di 1/7.",
        "id": 955,
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "ris_3": "V",
        "progressivo": "1.6.2-42",
        "immagine": "",
        "risposta_1": "la profondità del fondale è maggiore del doppio dell'altezza dell'onda.",
        "ris_2": "F"
    },
    {
        "risposta_3": "la profondità del fondale è minore del doppio dell'altezza dell'onda.",
        "progressivo": "1.6.2-43",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "immagine": "",
        "ris_1": "F",
        "ris_2": "F",
        "domanda": "Generalmente un'onda frange quando:",
        "risposta_2": "la profondità del fondale è maggiore del doppio dell'altezza dell'onda.",
        "ris_3": "V",
        "risposta_1": "il rapporto tra altezza e lunghezza (ripidità) dell'onda è maggiore di 1/8.",
        "id": 956,
        "capitolo": "METEOROLOGIA"
    },
    {
        "id": 957,
        "progressivo": "1.6.2-44",
        "domanda": "Cosa si intende per \"mare vivo\"?",
        "immagine": "",
        "capitolo": "METEOROLOGIA",
        "ris_2": "V",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "risposta_2": "è generato da un vento che agisce sul posto dove si trova l'osservatore.",
        "ris_1": "F",
        "risposta_1": "quando l'onda proviene per propagazione da una zona lontana, rispetto all'osservatore, dove agisce un vento che lo sta generando.",
        "risposta_3": "è una zona ricca di pesce.",
        "ris_3": "F"
    },
    {
        "id": 958,
        "domanda": "Quando si ha il così detto \"mare lungo\" circa le onde?",
        "risposta_3": "quando si ha moto ondoso proveniente per propagazione da una zona lontana, rispetto all'osservatore, in cui è presente un \"mare vivo\".",
        "immagine": "",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "ris_3": "V",
        "risposta_1": "quando c'è vento ma non c'è onda.",
        "ris_1": "F",
        "ris_2": "F",
        "progressivo": "1.6.2-45",
        "risposta_2": "quando si ha moto ondoso generato da un vento che agisce sul posto dove si trova l'osservatore.",
        "capitolo": "METEOROLOGIA"
    },
    {
        "risposta_3": "proviene per propagazione da una zona lontana, rispetto all'osservatore, in cui è presente un mare vivo.",
        "progressivo": "1.6.2-46",
        "risposta_1": "persiste sul posto dove si trova l'osservatore pur in assenza dell'azione diretta del vento che lo aveva generato.",
        "risposta_2": "è generato da un vento che agisce sul posto dove si trova l'osservatore.",
        "ris_1": "V",
        "domanda": "L'osservatore valuta che sta navigando con un \"mare vecchio\" (o \"morto\") se il sistema di onde:",
        "id": 959,
        "capitolo": "METEOROLOGIA",
        "immagine": "",
        "ris_3": "F",
        "ris_2": "F",
        "argomento": "Bollettini meteorologici e  previsioni locali"
    },
    {
        "progressivo": "1.1.2-21",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "ris_2": "V",
        "risposta_3": "con elica destrorsa, si retrocede perpendicolarmente alla banchina correggendo con il timone a sinistra.",
        "risposta_1": "si retrocede perpendicolarmente alla banchina presentando il mascone e correggendo solo col timone.",
        "id": 96,
        "ris_3": "F",
        "ris_1": "F",
        "capitolo": "TEORIA DELLO SCAFO",
        "immagine": "",
        "domanda": "Per effettuare un corretto ormeggio di poppa alla banchina:",
        "risposta_2": "con elica sinistrorsa, si retrocede perpendicolarmente alla banchina presentando il giardinetto di dritta alla banchina."
    },
    {
        "risposta_1": "1053,2 hPa.",
        "risposta_2": "1003,2 mm.",
        "capitolo": "METEOROLOGIA",
        "domanda": "Quant’è il valore di pressione media sul livello del mare?",
        "risposta_3": "1013,2 hPa.",
        "immagine": "",
        "progressivo": "1.6.2-47",
        "ris_1": "F",
        "ris_3": "V",
        "id": 960,
        "ris_2": "F",
        "argomento": "Bollettini meteorologici e  previsioni locali"
    },
    {
        "risposta_2": "si ha pioggia intermittente.",
        "domanda": "In genere, nella zona che precede un fronte caldo:",
        "risposta_3": "la pressione cade rapidamente.",
        "ris_3": "V",
        "ris_1": "F",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "progressivo": "1.6.2-48",
        "id": 961,
        "immagine": "",
        "ris_2": "F",
        "capitolo": "METEOROLOGIA",
        "risposta_1": "la pressione aumenta rapidamente."
    },
    {
        "ris_3": "F",
        "risposta_3": "il passaggio di un fronte occluso.",
        "risposta_1": "il passaggio di un fronte freddo.",
        "id": 962,
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "ris_1": "V",
        "capitolo": "METEOROLOGIA",
        "progressivo": "1.6.2-49",
        "ris_2": "F",
        "immagine": "",
        "risposta_2": "il passaggio di un fronte caldo.",
        "domanda": "Se la pressione sale bruscamente, cosa possiamo attenderci:"
    },
    {
        "ris_1": "F",
        "domanda": "Quali sono gli effetti di un fronte caldo?",
        "ris_2": "V",
        "ris_3": "F",
        "risposta_3": "nebbia da irraggiamento.",
        "risposta_2": "l'aria calda, raffreddandosi, causa piogge leggere al passaggio del fronte.",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "id": 963,
        "risposta_1": "il rapido sollevamento dell’aria calda genera nubi di tipo cumulonembo generando fenomeni meteorologici anche violenti come rovesci, temporali e vento forte.",
        "progressivo": "1.6.2-50",
        "capitolo": "METEOROLOGIA",
        "immagine": ""
    },
    {
        "ris_2": "F",
        "risposta_2": "l'aria calda, raffreddandosi, causa piogge leggere al passaggio del fronte.",
        "risposta_1": "il rapido sollevamento dell’aria calda genera nubi di tipo cumulonembo, generando fenomeni meteorologici anche violenti come rovesci, temporali e vento forte.",
        "ris_3": "F",
        "immagine": "",
        "id": 964,
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "progressivo": "1.6.2-51",
        "capitolo": "METEOROLOGIA",
        "ris_1": "V",
        "risposta_3": "nebbia da irraggiamento.",
        "domanda": "Quali sono gli effetti di un fronte freddo?"
    },
    {
        "capitolo": "METEOROLOGIA",
        "risposta_3": "entrambe riducono la visibilità, ma si ha la foschia quando la visibilità  viene ridotta a valori inferiori a 1 km.",
        "risposta_2": "entrambe riducono la visibilità, ma si ha la nebbia quando la visibilità  viene ridotta a valori inferiori a 1 km.",
        "domanda": "Quale differenza c'è fra nebbia e foschia?",
        "immagine": "",
        "progressivo": "1.6.2-52",
        "id": 965,
        "risposta_1": "nessuna.",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "ris_3": "F",
        "ris_1": "F",
        "ris_2": "V"
    },
    {
        "immagine": "",
        "argomento": "Bollettini meteorologici e  previsioni locali",
        "ris_2": "F",
        "risposta_3": "più forti quanto minore è la distanza tra una isobara e l'altra, perché maggiore è la differenza di pressione.",
        "id": 966,
        "capitolo": "METEOROLOGIA",
        "risposta_2": "più deboli quanto minore è la distanza tra una isobara e l'altra, perché minore è la differenza di pressione.",
        "ris_3": "V",
        "domanda": "Circa il gradiente barico, i venti saranno:",
        "risposta_1": "sempre costanti fra un'isobara e l'altra.",
        "ris_1": "F",
        "progressivo": "1.6.2-53"
    },
    {
        "domanda": "Il grado di longitudine è la misura della distanza:",
        "risposta_1": "angolare tra l'equatore ed il parallelo passante per il punto.",
        "argomento": "Coordinate geografiche",
        "id": 967,
        "ris_3": "F",
        "risposta_2": "angolare tra due meridiani ed è pari a 60 minuti d'arco.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "progressivo": "1.7.1-1",
        "immagine": "",
        "risposta_3": "equivalente ad un miglio marino.",
        "ris_2": "V",
        "ris_1": "F"
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "ris_3": "V",
        "risposta_1": "angolare tra un meridiano ed il successivo corrispondente ad 1' di arco.",
        "ris_2": "F",
        "risposta_3": "angolare tra l'equatore ed il parallelo, oppure tra due paralleli.",
        "risposta_2": "equivalente ad un miglio marino.",
        "ris_1": "F",
        "domanda": "Il grado di latitudine è la misura della distanza:",
        "progressivo": "1.7.1-2",
        "argomento": "Coordinate geografiche",
        "id": 968
    },
    {
        "risposta_3": "la distanza angolare compresa fra i paralleli passanti per i due punti.",
        "ris_1": "V",
        "immagine": "",
        "risposta_1": "la latitudine del punto.",
        "progressivo": "1.7.1-3",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "l'affermazione è errata.",
        "id": 969,
        "argomento": "Coordinate geografiche",
        "domanda": "L'arco di meridiano compreso fra l'equatore e il parallelo passante per il punto esprime:",
        "ris_3": "F"
    },
    {
        "id": 97,
        "ris_1": "F",
        "ris_2": "F",
        "immagine": "",
        "risposta_2": "l'unità si muove parallelamente alla banchina.",
        "risposta_3": "con la banchina a sinistra, si dà marcia indietro con il mascone di sinistra alla banchina, avvicinando la poppa e arrestando l'abbrivio.",
        "progressivo": "1.1.2-22",
        "ris_3": "V",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_1": "se aziono il motore in marcia indietro avvicino la prua alla banchina",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "domanda": "Effettuo un ormeggio di fianco (all'inglese) con un'elica destrorsa:"
    },
    {
        "domanda": "La latitudine è misurata:",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_3": "la latitudine si misura da 0° a 90° verso Nord o verso Sud.",
        "argomento": "Coordinate geografiche",
        "ris_3": "V",
        "ris_2": "F",
        "immagine": "",
        "ris_1": "F",
        "id": 970,
        "risposta_1": "da 0° a 90° verso Est o verso Ovest.",
        "risposta_2": "da 0° a 180° verso Nord o verso Sud.",
        "progressivo": "1.7.1-4"
    },
    {
        "risposta_2": "da 0° a 180° verso Est o verso Ovest.",
        "ris_2": "V",
        "risposta_1": "da 0° a 90° verso Est o verso Ovest.",
        "domanda": "La longitudine si misura:",
        "progressivo": "1.7.1-5",
        "risposta_3": "da 0° a 180° verso Nord o verso Sud.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "F",
        "argomento": "Coordinate geografiche",
        "immagine": "",
        "ris_1": "F",
        "id": 971
    },
    {
        "risposta_1": "un circolo minore della superficie terrestre, parallelo al piano dell'equatore.",
        "risposta_3": "ogni semicircolo massimo che passa per i due poli geografici Nord e Sud.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "F",
        "risposta_2": "il circolo massimo ottenuto attraverso l'intersezione della sfera terrestre con un piano perpendicolare all'asse polare e passante per il centro della terra.",
        "argomento": "Coordinate geografiche",
        "ris_2": "F",
        "immagine": "",
        "domanda": "Il meridiano è:",
        "progressivo": "1.7.1-6",
        "ris_3": "V",
        "id": 972
    },
    {
        "immagine": "",
        "id": 973,
        "ris_3": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "F",
        "domanda": "Le coordinate geografiche sono date da:",
        "progressivo": "1.7.1-7",
        "risposta_3": "Nord, Sud, Est, Ovest.",
        "ris_2": "V",
        "risposta_1": "equatore e meridiano di Greenwich.",
        "risposta_2": "latitudine e longitudine.",
        "argomento": "Coordinate geografiche"
    },
    {
        "ris_3": "V",
        "immagine": "",
        "domanda": "I cerchi fondamentali del sistema di coordinate sono:",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_3": "l'equatore ed il meridiano di Greenwich.",
        "ris_2": "F",
        "risposta_2": "l'ortodromia e la lossodromia.",
        "progressivo": "1.7.1-8",
        "id": 974,
        "risposta_1": "il meridiano di Greenwich ed il meridiano di Monte Mario.",
        "argomento": "Coordinate geografiche",
        "ris_1": "F"
    },
    {
        "ris_1": "F",
        "argomento": "Coordinate geografiche",
        "risposta_1": "alla lunghezza dell'arco di un circolo di parallelo che corrisponde ad a 1' (un primo) misurato sulla scala della longitudine.",
        "ris_2": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "alla lunghezza dell'arco di circolo massimo che corrisponde a 1' (un primo) di latitudine.",
        "domanda": "Considerando la terra perfettamente sferica, il miglio nautico corrisponde:",
        "ris_3": "F",
        "progressivo": "1.7.1-9",
        "risposta_3": "a 1896 metri.",
        "immagine": "",
        "id": 975
    },
    {
        "domanda": "Il meridiano di Greenwich è:",
        "immagine": "",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "F",
        "risposta_2": "il semicerchio massimo fondamentale al quale si rapportano le latitudini dei luoghi; divide la terra in due emisferi: Nord e Sud.",
        "risposta_1": "il semicerchio massimo fondamentale al quale si rapportano le longitudini dei luoghi.",
        "progressivo": "1.7.1-10",
        "id": 976,
        "ris_1": "V",
        "risposta_3": "il circolo massimo fondamentale, al quale si rapportano le latitudini dei luoghi; divide la terra nei due emisferi Nord e Sud.",
        "argomento": "Coordinate geografiche",
        "ris_2": "F"
    },
    {
        "risposta_1": "è l'unità di misura angolare, pari alla 360^ parte di un angolo giro; si divide in 60' (minuti d'arco) ed ogni primo in 60\" (secondi d'arco).",
        "risposta_2": "è l'unità di misura angolare, pari alla 60^ parte di un angolo giro; si divide in 100' (minuti d'arco) ed ogni primo in 60\" (secondi d'arco).",
        "id": 977,
        "argomento": "Coordinate geografiche",
        "progressivo": "1.7.1-11",
        "ris_2": "F",
        "ris_1": "V",
        "risposta_3": "è l'unità di misura angolare, pari alla 100^ parte di un angolo giro; si divide in 100' (minuti d'arco) ed ogni primo in 100\" (secondi d'arco).",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "Cos'è il \"grado\"?",
        "immagine": "",
        "ris_3": "F"
    },
    {
        "risposta_2": "la longitudine del punto.",
        "ris_3": "F",
        "risposta_1": "l'arco di meridiano inferiore a 90 gradi compreso fra i due punti.",
        "ris_2": "V",
        "progressivo": "1.7.1-12",
        "domanda": "L'arco di equatore compreso tra il meridiano fondamentale ed il meridiano passante per il punto esprime:",
        "immagine": "",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_3": "la distanza angolare compresa fra i meridiani passanti per i due punti.",
        "ris_1": "F",
        "argomento": "Coordinate geografiche",
        "id": 978
    },
    {
        "immagine": "",
        "ris_2": "V",
        "id": 979,
        "progressivo": "1.7.1-13",
        "argomento": "Coordinate geografiche",
        "ris_3": "F",
        "domanda": "I Circoli Massimi sono:",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_1": "F",
        "risposta_1": "gli Antimeridiani.",
        "risposta_2": "l'Equatore ed i Meridiani con i rispettivi Antimeridiani.",
        "risposta_3": "l'insieme dei Paralleli e i Meridiani."
    },
    {
        "risposta_1": "abbriviata a marcia indietro.",
        "id": 98,
        "domanda": "L'effetto evolutivo dell'elica è maggiore con un'unità:",
        "risposta_2": "abbriviata a marcia avanti.",
        "immagine": "",
        "risposta_3": "senza abbrivio e con marcia inserita.",
        "ris_2": "F",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "ris_3": "V",
        "capitolo": "TEORIA DELLO SCAFO",
        "ris_1": "F",
        "progressivo": "1.1.2-23"
    },
    {
        "ris_2": "F",
        "argomento": "Coordinate geografiche",
        "id": 980,
        "ris_3": "F",
        "progressivo": "1.7.1-14",
        "ris_1": "V",
        "risposta_1": "circoli minori che si dipartono parallelamente dall'equatore verso i poli.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "circoli minori che uniscono i poli.",
        "immagine": "",
        "risposta_3": "semicircoli che uniscono i poli.",
        "domanda": "I paralleli sono gli infiniti:"
    },
    {
        "ris_3": "F",
        "risposta_3": "il primo è un cerchio semimassimo, il secondo è un semicerchio minore.",
        "progressivo": "1.7.1-15",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 981,
        "domanda": "Posto che le linee di riferimento del sistema di coordinate geografiche sono l'equatore ed il meridiano di Greenwich, tali linee rispettivamente sono:",
        "immagine": "",
        "ris_1": "V",
        "risposta_1": "il primo è un circolo massimo, il secondo è un semicircolo massimo.",
        "argomento": "Coordinate geografiche",
        "risposta_2": "il primo è un cerchio minore, il secondo è un semicerchio minore.",
        "ris_2": "F"
    },
    {
        "ris_1": "V",
        "argomento": "Coordinate geografiche",
        "risposta_1": "effettivamente sono infiniti.",
        "ris_3": "F",
        "risposta_2": "effettivamente sono 90 verso nord e 90 verso sud.",
        "domanda": "Per ogni grado di longitudine, si considerano 180 meridiani tra 0 e 180 gradi Est, 180 meridiani tra 0 e 180 gradi Ovest, ma:",
        "immagine": "",
        "risposta_3": "effettivamente sono molti meno.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 982,
        "progressivo": "1.7.1-16",
        "ris_2": "F"
    },
    {
        "risposta_3": "da 0 a 90 gradi verso N e da 0 a 90 gradi verso S.",
        "ris_1": "V",
        "ris_3": "F",
        "argomento": "Coordinate geografiche",
        "ris_2": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "immagine": "",
        "domanda": "La longitudine si misura:",
        "risposta_1": "da 0 a 180 gradi verso E e da 0 a 180 gradi verso W.",
        "progressivo": "1.7.1-17",
        "id": 983,
        "risposta_2": "da 0 a 360 gradi verso S o N."
    },
    {
        "argomento": "Coordinate geografiche",
        "progressivo": "1.7.1-18",
        "ris_3": "V",
        "ris_2": "F",
        "domanda": "I paralleli sono:",
        "id": 984,
        "risposta_1": "circoli minori paralleli all'asse di rotazione terrestre.",
        "ris_1": "F",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_3": "circoli minori perpendicolari all'asse terrestre e paralleli all'equatore.",
        "immagine": "",
        "risposta_2": "circoli massimi paralleli all'equatore."
    },
    {
        "risposta_1": "il semicircolo massimo fondamentale, al quale si rapportano le longitudini dei luoghi. Divide la terra in due emisferi Est ed Ovest.",
        "progressivo": "1.7.1-19",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 985,
        "ris_3": "F",
        "ris_1": "F",
        "immagine": "",
        "risposta_3": "meridiano fondamentale.",
        "argomento": "Coordinate geografiche",
        "domanda": "L'equatore è:",
        "ris_2": "V",
        "risposta_2": "il circolo massimo fondamentale, al quale si rapportano le latitudini dei luoghi. Divide la terra nei due emisferi Nord (Boreale) e Sud (Australe)."
    },
    {
        "immagine": "",
        "ris_1": "F",
        "domanda": "Quanto misura un miglio nautico?",
        "risposta_2": "1852 metri.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Coordinate geografiche",
        "risposta_1": "1609,34 metri.",
        "ris_2": "V",
        "risposta_3": "1810 metri.",
        "progressivo": "1.7.1-20",
        "ris_3": "F",
        "id": 986
    },
    {
        "id": 987,
        "ris_3": "F",
        "domanda": "Come si esprimono le coordinate geografiche?",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "F",
        "risposta_1": "in latitudine e longitudine.",
        "argomento": "Coordinate geografiche",
        "immagine": "",
        "risposta_3": "in rilevamento bussola e rilevamento magnetico.",
        "progressivo": "1.7.1-21",
        "ris_1": "V",
        "risposta_2": "in cerchio capace e rilevamento bussola."
    },
    {
        "ris_2": "V",
        "domanda": "Usualmente, la lettera greca “λ ” (lambda) è utilizzata:",
        "risposta_3": "assolutamente non è utilizzata.",
        "progressivo": "1.7.1-22",
        "argomento": "Coordinate geografiche",
        "ris_1": "F",
        "id": 988,
        "immagine": "",
        "risposta_2": "per indicare la longitudine.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "F",
        "risposta_1": "per indicare la latitudine."
    },
    {
        "risposta_1": "la latitudine.",
        "risposta_2": "la longitudine.",
        "ris_2": "V",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "argomento": "Coordinate geografiche",
        "risposta_3": "nessuna delle due.",
        "id": 989,
        "immagine": "",
        "ris_3": "F",
        "domanda": "Navigando con Rv = 180 gradi, rimarrà invariata:",
        "progressivo": "1.7.1-23",
        "ris_1": "F"
    },
    {
        "ris_3": "F",
        "capitolo": "TEORIA DELLO SCAFO",
        "risposta_2": "oltrepassa il limite dei giri e non si ha più la spinta propulsiva.",
        "domanda": "Si ha cavitazione nel momento in cui l'elica:",
        "ris_1": "F",
        "risposta_1": "non raggiunge il regime minimo dei giri.",
        "id": 99,
        "immagine": "",
        "risposta_3": "s'impiglia in un cavo.",
        "argomento": "Effetti  evolutivi  dell'elica  e  del timone.  Elementi  di stabilità dell'unità.",
        "progressivo": "1.1.2-24",
        "ris_2": "V"
    },
    {
        "risposta_1": "poli geografici, equatore e meridiano di Greenwich.",
        "risposta_2": "miglio nautico, lossodromia ed ortodromia.",
        "ris_3": "F",
        "immagine": "",
        "argomento": "Coordinate geografiche",
        "ris_2": "F",
        "ris_1": "V",
        "domanda": "Sono elementi del sistema di riferimento sulla sfera terrestre:",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 990,
        "risposta_3": "rosa dei venti.",
        "progressivo": "1.7.1-24"
    },
    {
        "ris_2": "F",
        "ris_1": "V",
        "risposta_2": "per indicare la longitudine.",
        "risposta_3": "assolutamente non è utilizzata.",
        "risposta_1": "per indicare la latitudine.",
        "argomento": "Coordinate geografiche",
        "ris_3": "F",
        "immagine": "",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "id": 991,
        "domanda": "Usualmente, la lettera greca “ϕ ”  (fi) è utilizzata:",
        "progressivo": "1.7.1-25"
    },
    {
        "risposta_2": "semicircoli che uniscono i poli.",
        "immagine": "",
        "argomento": "Coordinate geografiche",
        "risposta_1": "circoli massimi dell'emisfero nord.",
        "progressivo": "1.7.1-26",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "F",
        "risposta_3": "circoli minori che si dipartono parallelamente dall'equatore ai poli.",
        "domanda": "I meridiani sono gli infiniti:",
        "ris_2": "V",
        "ris_1": "F",
        "id": 992
    },
    {
        "risposta_2": "in alto.",
        "argomento": "Coordinate geografiche",
        "progressivo": "1.7.1-27",
        "ris_3": "V",
        "ris_2": "F",
        "risposta_3": "in alto e in basso.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_1": "in basso.",
        "domanda": "Dove è indicato, sulla carta nautica, il valore della longitudine?",
        "id": 993,
        "ris_1": "F",
        "immagine": ""
    },
    {
        "risposta_3": "costituisce unico riferimento per la misura della longitudine.",
        "ris_1": "F",
        "risposta_2": "costituisce il riferimento per la misura della latitudine",
        "risposta_1": "è l'unico parallelo a non essere un cerchio massimo.",
        "progressivo": "1.7.1-28",
        "argomento": "Coordinate geografiche",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "L'equatore:",
        "immagine": "",
        "id": 994,
        "ris_3": "F",
        "ris_2": "V"
    },
    {
        "domanda": "Il circolo massimo che divide la terra nei due emisferi, noti sotto il nome di \"Australe\" e \"Boreale\", è denominato:",
        "ris_2": "V",
        "risposta_3": "meridiano di Greenwich.",
        "ris_3": "F",
        "argomento": "Coordinate geografiche",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "risposta_2": "equatore.",
        "risposta_1": "orizzonte.",
        "immagine": "",
        "ris_1": "F",
        "id": 995,
        "progressivo": "1.7.1-29"
    },
    {
        "argomento": "Coordinate geografiche",
        "risposta_3": "no, occorre necessariamente anche la seconda coordinata geografica della latitudine.",
        "risposta_2": "solo se abbiamo la sua distanza dal faro.",
        "ris_2": "F",
        "ris_1": "F",
        "domanda": "Con la sola coordinata geografica della longitudine è possibile identificare in maniera univoca un punto geografico della terra?",
        "progressivo": "1.7.1-30",
        "immagine": "",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_3": "V",
        "risposta_1": "certamente.",
        "id": 996
    },
    {
        "domanda": "Uno dei seguenti valori è un dato sicuramente errato; quale?",
        "id": 997,
        "immagine": "",
        "risposta_3": "95 gradi di longitudine ovest.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "ris_2": "F",
        "risposta_1": "95 gradi di latitudine nord.",
        "ris_1": "V",
        "argomento": "Coordinate geografiche",
        "ris_3": "F",
        "progressivo": "1.7.1-31",
        "risposta_2": "95 gradi di longitudine est."
    },
    {
        "risposta_1": "tutti hanno la stessa latitudine.",
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "progressivo": "1.7.1-32",
        "id": 998,
        "ris_2": "F",
        "risposta_2": "tutti sono equidistanti fra loro.",
        "immagine": "",
        "argomento": "Coordinate geografiche",
        "ris_1": "V",
        "domanda": "La caratteristica dei punti lungo un arco di parallelo è che:",
        "risposta_3": "tutti hanno la stessa longitudine.",
        "ris_3": "F"
    },
    {
        "capitolo": "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA",
        "domanda": "La caratteristica dei punti lungo un arco di meridiano è che:",
        "argomento": "Coordinate geografiche",
        "immagine": "",
        "ris_1": "F",
        "id": 999,
        "ris_3": "F",
        "risposta_3": "tutti hanno la stessa latitudine.",
        "progressivo": "1.7.1-33",
        "risposta_1": "tutti sono equidistanti fra loro.",
        "risposta_2": "tutti hanno la stessa longitudine.",
        "ris_2": "V"
    }
]


export const DATA_QUIZ_VELA_BASE: any[] = [
    {
        "id": 1,
        "domanda": "Lo scafo di un'unità navale a vela è la parte immersa della deriva.",
        "progressivo": "2.1.1-1",
        "risposta": "F",
        "categoria": "TEORIA DELLA VELA"
    },
    {
        "categoria": "TEORIA DELLA VELA",
        "risposta": "V",
        "domanda": "Quando l'unità a vela si muove controvento, il vento apparente corrisponde alla somma tra il vento reale e quello di velocità dell'unità navale.",
        "id": 10,
        "progressivo": "2.1.1-10"
    },
    {
        "id": 100,
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "risposta": "V",
        "progressivo": "2.2.1-1",
        "domanda": "Negli armamenti frazionati, le sartie volanti servono a sostenere l'albero, controbilanciando lo sforzo trasmesso dalle vele allo strallo."
    },
    {
        "id": 101,
        "risposta": "V",
        "domanda": "Negli armamenti frazionati con crocette acquartierate verso poppa e paterazzo, le sartie volanti possono dare supporto all'albero ma non sono strutturali.",
        "progressivo": "2.2.1-2",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA"
    },
    {
        "id": 102,
        "risposta": "V",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "progressivo": "2.2.1-3",
        "domanda": "Si intende per unità attrezzata con armo frazionato quella in cui lo strallo non è \"incappellato\" in testa d'albero."
    },
    {
        "id": 103,
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "risposta": "F",
        "progressivo": "2.2.1-4",
        "domanda": "Si intende per unità attrezzata con armo frazionato quella il cui scafo è suddiviso in almeno tre compartimenti."
    },
    {
        "id": 104,
        "risposta": "F",
        "progressivo": "2.2.1-5",
        "domanda": "Le crocette garantiscono  un'adeguato punto di forza e ritenuta delle scotte sul piano di coperta.",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA"
    },
    {
        "id": 105,
        "risposta": "V",
        "domanda": "Le crocette servono a tensionare le sartie che sorreggono l'albero lateralmente.",
        "progressivo": "2.2.1-6",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA"
    },
    {
        "domanda": "La regolazione delle sartie si attua attraverso l'utilizzo del carrello della scotta.",
        "id": 106,
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "risposta": "F",
        "progressivo": "2.2.1-7"
    },
    {
        "domanda": "L'avvolgifiocco è una particolare galloccia dove viene avvolta la scotta sottovento del fiocco.",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "progressivo": "2.2.1-8",
        "id": 107,
        "risposta": "F"
    },
    {
        "risposta": "F",
        "progressivo": "2.2.1-9",
        "domanda": "La  balumina è il lato più corto della randa, che si introduce all'interno della canaletta del boma.",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "id": 108
    },
    {
        "domanda": "La ralinga della randa è il cavo cucito nel lato di inferitura per essere introdotto all'interno della canaletta dell'albero.",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "progressivo": "2.2.1-10",
        "risposta": "V",
        "id": 109
    },
    {
        "id": 11,
        "categoria": "TEORIA DELLA VELA",
        "risposta": "F",
        "domanda": "Quando l'unità a vela si muove controvento, il vento apparente è pari al vento di velocità dell'unità navale stessa.",
        "progressivo": "2.1.1-11"
    },
    {
        "domanda": "Per \"base\" della randa si intende il lato libero della vela dove sono ricavate le tasche per poter introdurre le stecche.",
        "id": 110,
        "progressivo": "2.2.1-11",
        "risposta": "F",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA"
    },
    {
        "id": 111,
        "domanda": "Per \"angolo di scotta\" della randa si intende quello compreso tra la base e la balumina, dove è agganciato il tesabase.",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "risposta": "V",
        "progressivo": "2.2.1-12"
    },
    {
        "id": 112,
        "risposta": "F",
        "progressivo": "2.2.1-13",
        "domanda": "Per \"angolo di penna\" della randa si intende l'angolo compreso tra la base e la ralinga ove è agganciata la trozza del boma.",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA"
    },
    {
        "id": 113,
        "progressivo": "2.2.1-14",
        "domanda": "Per \"angolo di mura\" della randa si intende l'angolo compreso tra la balumina e la ralinga, posto all'estremità superiore della vela, ove è agganciata la drizza.",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "risposta": "F"
    },
    {
        "risposta": "F",
        "progressivo": "2.2.1-15",
        "domanda": "Il fiocco consente all'unità a vela di navigare esclusivamente con angoli compresi tra i 40° e i 70° rispetto alla direzione da cui spira il vento.",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "id": 114
    },
    {
        "domanda": "La randa rappresenta la vela principale di un'unità a vela, ubicata a poppavia dell'albero, e di forma triangolare.",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "id": 115,
        "progressivo": "2.2.1-16",
        "risposta": "V"
    },
    {
        "id": 116,
        "risposta": "F",
        "domanda": "Il genoa o genova rappresenta una particolare tipologia di vela prodiera avente una superficie ridotta utilizzata in caso di condizioni meteo marine avverse.",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "progressivo": "2.2.1-17"
    },
    {
        "domanda": "Il genoa o genova è la vela prodiera avente una superficie che non si sovrappone a quella della randa.",
        "progressivo": "2.2.1-18",
        "risposta": "F",
        "id": 117,
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA"
    },
    {
        "domanda": "Il genoa (o genova) oltrepassa l'albero verso poppa fino a una lunghezza generalmente pari al 50% della distanza fra l'albero e il punto di mura .",
        "id": 118,
        "risposta": "V",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "progressivo": "2.2.1-19"
    },
    {
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "progressivo": "2.2.1-20",
        "domanda": "Il fiocco è la vela prodiera avente una superficie che non si sovrappone a quella della randa.",
        "risposta": "V",
        "id": 119
    },
    {
        "categoria": "TEORIA DELLA VELA",
        "risposta": "V",
        "domanda": "Durante la navigazione di una unità a vela, il vento apparente è sempre orientato più a proravia rispetto al vento reale.",
        "progressivo": "2.1.1-12",
        "id": 12
    },
    {
        "domanda": "Lo spinnaker rappresenta la vela principale, utilizzata in particolar modo nelle andature di bolina al fine di dare maggiore potenza e superficie velica.",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "progressivo": "2.2.1-21",
        "risposta": "F",
        "id": 120
    },
    {
        "id": 121,
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "domanda": "Il gennaker è una vela asimmetrica adatta alle andature comprese tra il traverso e il lasco (60°- 120° dal vento).",
        "progressivo": "2.2.1-22",
        "risposta": "V"
    },
    {
        "progressivo": "2.2.1-23",
        "id": 122,
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "domanda": "Il code 0 è una vela asimmetrica adatta alle andature con poco vento comprese tra la bolina larga e il traverso.",
        "risposta": "V"
    },
    {
        "progressivo": "2.2.1-24",
        "id": 123,
        "risposta": "F",
        "domanda": "Il code 0 è una vela inferita.",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA"
    },
    {
        "progressivo": "2.2.1-25",
        "domanda": "Lo sloop è l'armo caratterizzato dalla presenza di un solo albero e la possibilità di issare una sola vela di prua alla volta.",
        "risposta": "V",
        "id": 124,
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA"
    },
    {
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "domanda": "Il cutter è l'armo caratterizzato dalla presenza di un solo albero, armato con due fiocchi contemporaneamente.",
        "id": 125,
        "risposta": "V",
        "progressivo": "2.2.1-26"
    },
    {
        "domanda": "Il ketch è quell'armo caratterizzato dalla presenza dell'albero di mezzana a proravia dell'asse del timone.",
        "progressivo": "2.2.1-27",
        "risposta": "V",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "id": 126
    },
    {
        "domanda": "Tra le manovre fisse vi sono  drizze e scotte.",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "id": 127,
        "progressivo": "2.2.1-28",
        "risposta": "F"
    },
    {
        "id": 128,
        "progressivo": "2.2.1-29",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "domanda": "Tra le manovre correnti vi sono stralli e sartie.",
        "risposta": "F"
    },
    {
        "id": 129,
        "risposta": "V",
        "progressivo": "2.2.1-30",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "domanda": "Il paranco di scotta assolve la funzione di demoltiplicare lo sforzo."
    },
    {
        "id": 13,
        "categoria": "TEORIA DELLA VELA",
        "risposta": "F",
        "progressivo": "2.1.1-13",
        "domanda": "Durante la navigazione di una unità a vela, il vento apparente, è sempre orientato ortogonalmente rispetto al vento reale."
    },
    {
        "progressivo": "2.2.1-31",
        "domanda": "Quello rappresentato in figura è un paranco con un rapporto 6:1",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "id": 130,
        "risposta": "F",
        "immagine":102

    },
    {
        "id": 131,
        "risposta": "V",
        "domanda": "Quello rappresentato in figura è un paranco doppio con il rapporto più favorevole, il cosiddetto \"fino\" di 8:1.",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "progressivo": "2.2.1-32",
        "immagine":103
    },
    {
        "domanda": "Il cunningham assolve la funzione  di porre in tensione la parte prodiera bassa della randa, mediante un paranco verticale.",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "progressivo": "2.2.1-33",
        "risposta": "V",
        "id": 132
    },
    {
        "risposta": "F",
        "id": 133,
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "progressivo": "2.2.1-34",
        "domanda": "Il tangone è il pennone sul quale è fissata la base della randa."
    },
    {
        "progressivo": "2.2.1-35",
        "risposta": "F",
        "id": 134,
        "domanda": "Il winch è un particolare meccanismo costituito da due bozzelli utilizzato per moltiplicare lo sforzo di trazione esercitato sulle cime.",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA"
    },
    {
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "risposta": "V",
        "id": 135,
        "progressivo": "2.2.1-36",
        "domanda": "Le scotte devono essere avvolte intorno al tamburo  del winch sempre in senso orario, ponendo particolare attenzione per evitare la sovrapposizione dei \"colli\"."
    },
    {
        "id": 136,
        "domanda": "La ferramenta di bordo è costituita dall'insieme di elementi come strozzascotte, winch, arridatori e galloccie.",
        "risposta": "V",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "progressivo": "2.2.1-37"
    },
    {
        "progressivo": "2.2.1-38",
        "domanda": "Il polipropilene è utilizzato solo per sagole galleggianti utilizzate per il salvataggio.",
        "id": 137,
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "risposta": "V"
    },
    {
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "id": 138,
        "progressivo": "2.2.1-39",
        "risposta": "F",
        "domanda": "I grilli assumono la funzione di ridurre o sforzo di trazione sui cavi."
    },
    {
        "progressivo": "2.2.1-40",
        "id": 139,
        "risposta": "F",
        "domanda": "Il carrello di randa (o trasto) è il congegno sul quale vengono date volta e bloccate le scotte della randa.",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA"
    },
    {
        "domanda": "Durante la navigazione a vela, il vento apparente ha un'intensità tanto maggiore quanto più l'unità navale procede verso la direzione da cui proviene il vento.",
        "progressivo": "2.1.1-14",
        "id": 14,
        "categoria": "TEORIA DELLA VELA",
        "risposta": "V"
    },
    {
        "id": 140,
        "risposta": "F",
        "progressivo": "2.2.1-41",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "domanda": "La galloccia è il dispositivo con cui si fissano le draglie."
    },
    {
        "id": 141,
        "progressivo": "2.2.1-42",
        "domanda": "La landa è il cavallotto o la piastra collocata in coperta utilizzata per fissare le sartie e gli stralli.",
        "risposta": "V",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA"
    },
    {
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "progressivo": "2.2.1-43",
        "domanda": "Il golfare è il carrello del boma dove si innesta la randa.",
        "id": 142,
        "risposta": "F"
    },
    {
        "risposta": "F",
        "id": 143,
        "domanda": "La varea del tangone è l'anello di attacco del mantiglio.",
        "progressivo": "2.2.1-44",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA"
    },
    {
        "progressivo": "2.2.1-45",
        "domanda": "La trozza è lo snodo che unisce il boma all'albero.",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "id": 144,
        "risposta": "V"
    },
    {
        "domanda": "La resistenza alla trazione rappresenta una qualità importante nelle fibre del tessuto di una vela, determinandone la stabilità trasversale.",
        "id": 145,
        "progressivo": "2.2.1-46",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "risposta": "V"
    },
    {
        "id": 146,
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "domanda": "Il dacron non è un materiale correntemente diffuso per la realizzazione di vele da crociera.",
        "risposta": "F",
        "progressivo": "2.2.1-47"
    },
    {
        "risposta": "V",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "id": 147,
        "domanda": "L'esposizione molto prolungata delle vele ai raggi solari ne determina il decadimento delle sue caratteristiche meccaniche di resistenza.",
        "progressivo": "2.2.1-48"
    },
    {
        "domanda": "Il set di vele standard di un catamarano è formato da  randa, fiocco e gennaker.",
        "progressivo": "2.2.1-49",
        "risposta": "V",
        "id": 148,
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA"
    },
    {
        "progressivo": "2.2.1-50",
        "risposta": "V",
        "id": 149,
        "domanda": "Il set di vele base di uno scafo armato a sloop è formato da randa e genoa (o genova).",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA"
    },
    {
        "id": 15,
        "risposta": "F",
        "domanda": "Durante la navigazione di una navale a vela, il vento apparente ha un'intensità tanto maggiore quanto più l'unità navale si discosta dalla direzione da cui proviene il vento.",
        "progressivo": "2.1.1-15",
        "categoria": "TEORIA DELLA VELA"
    },
    {
        "progressivo": "2.2.1-51",
        "id": 150,
        "domanda": "I garrocci sono gli specifici moschettoni che consentono di fissare il lato prodiero del genoa e del fiocco  allo strallo di prua.",
        "risposta": "V",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA"
    },
    {
        "id": 151,
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "progressivo": "2.2.1-52",
        "domanda": "La funzione del paterazzo è di regolare il vang.",
        "risposta": "F"
    },
    {
        "progressivo": "2.2.1-53",
        "domanda": "Cazzando il paterazzo si determina un rilevante smagrimento della parte centrale della randa.",
        "id": 152,
        "risposta": "V",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA"
    },
    {
        "progressivo": "2.2.1-54",
        "risposta": "F",
        "domanda": "La gassa d'amante è un nodo che tende a sciogliersi facilmente.",
        "id": 153,
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA"
    },
    {
        "id": 154,
        "domanda": "La gassa d'amante si usa per accorciare una cima.",
        "progressivo": "2.2.1-55",
        "risposta": "F",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA"
    },
    {
        "id": 155,
        "progressivo": "2.2.1-56",
        "domanda": "E' opportuno utilizzare il nodo piano per unire due cavi aventi diverso diametro.",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "risposta": "F"
    },
    {
        "id": 156,
        "progressivo": "2.2.1-57",
        "domanda": "La funzione di un nodo savoia è impedire che l'estremità di un cavo si sfili da un passacavo.",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "risposta": "V"
    },
    {
        "risposta": "V",
        "progressivo": "2.2.1-58",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "id": 157,
        "domanda": "Il nodo parlato è utile per fissare i parabordi alle draglie."
    },
    {
        "progressivo": "2.2.1-59",
        "risposta": "V",
        "domanda": "Il nodo margherita si usa per accorciare una cima.",
        "id": 158,
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA"
    },
    {
        "domanda": "Per lazy jack si intende una particolare drizza utilizzata per issare le vele in condizioni di emergenza.",
        "progressivo": "2.2.1-60",
        "id": 159,
        "risposta": "F",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA"
    },
    {
        "risposta": "F",
        "progressivo": "2.1.1-16",
        "id": 16,
        "domanda": "Per andatura di \"bolina\" si intende quando una unità a vela procede con una direzione di rotta che forma un angolo di circa 135° rispetto alla direzione del vento reale.",
        "categoria": "TEORIA DELLA VELA"
    },
    {
        "domanda": "Per lazy jack si intende il sistema di sagole che aiuta a raccogliere la randa in fase di ammainata.",
        "id": 160,
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "progressivo": "2.2.1-61",
        "risposta": "V"
    },
    {
        "progressivo": "2.2.1-62",
        "risposta": "V",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "id": 161,
        "domanda": "Il feeder è il dispositivo utilizzato al fine di facilitare l'introduzione dell'inferitura del fiocco o del genoa all'interno della canaletta dello strallo cavo."
    },
    {
        "domanda": "Il tesabase è il dispositivo finalizzato a mantenere tesata la base del fiocco.",
        "id": 162,
        "risposta": "F",
        "progressivo": "2.2.1-63",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA"
    },
    {
        "risposta": "F",
        "domanda": "Le manovre necessarie all'uso dello spinnaker sono scotta, spring, vang, borosa e  meolo del tangone.",
        "id": 163,
        "progressivo": "2.2.1-64",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA"
    },
    {
        "domanda": "Per braccio si intende il cavo utilizzato per manovrare e, quindi, regolare la mura dello spinnaker.",
        "risposta": "V",
        "id": 164,
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "progressivo": "2.2.1-65"
    },
    {
        "domanda": "Il matafione è un fiocco di rispetto utilizzato in condizioni meteo marine avverse.",
        "progressivo": "2.2.1-66",
        "id": 165,
        "risposta": "F",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA"
    },
    {
        "risposta": "V",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "domanda": "Per impiombatura si intende l'intreccio dei trefoli delle estremità di cavi tessili o in acciaio, al fine di unirli tra di loro o per realizzare un anello fisso a cui agganciare le ferramenta o le manovre.",
        "id": 166,
        "progressivo": "2.2.1-67"
    },
    {
        "progressivo": "2.2.1-68",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "risposta": "F",
        "domanda": "Per borosa si intende la parte terminale superiore dello strallo cavo che lo collega all'albero.",
        "id": 167
    },
    {
        "progressivo": "2.2.1-69",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "domanda": "Le sartie, sono i cavi generalmente in acciaio (ma anche in fibre tessili particolarmente tenaci), che sostengono l'albero.",
        "id": 168,
        "risposta": "V"
    },
    {
        "domanda": "Il tornichetto è un congegno utilizzato per unire due cime di diverso materiale.",
        "risposta": "F",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "id": 169,
        "progressivo": "2.2.1-70"
    },
    {
        "risposta": "F",
        "progressivo": "2.1.1-17",
        "id": 17,
        "domanda": "Per andatura al \"traverso\" si intende quando una unità a vela procede con una direzione di rotta che forma un angolo di 45° rispetto alla direzione del vento reale.",
        "categoria": "TEORIA DELLA VELA"
    },
    {
        "domanda": "Il vang è un sistema di ritenuta del boma di tipo regolabile che assolve a due funzioni principali: regola la flessione longitudinale dell'albero e influenza la superficie portante della vela.",
        "risposta": "V",
        "id": 170,
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "progressivo": "2.2.1-71"
    },
    {
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "id": 171,
        "domanda": "All'interno dell'albero si possono far passare le manovre fisse come sartie e stralli.",
        "progressivo": "2.2.1-72",
        "risposta": "F"
    },
    {
        "id": 172,
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "domanda": "L'avvolgiranda è un'attrezzatura che permette di riporre la randa in un gavone una volta terminata la navigazione.",
        "progressivo": "2.2.1-73",
        "risposta": "F"
    },
    {
        "id": 173,
        "domanda": "La regolazione dell'albero viene effettuata con l'unità all'ormeggio agendo su ogni singola manovra corrente in stretta aderenza a quanto indicato dal costruttore.",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "risposta": "F",
        "progressivo": "2.2.1-74"
    },
    {
        "id": 174,
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "risposta": "V",
        "progressivo": "2.2.1-75",
        "domanda": "Le manovre correnti sono quelle che servono a manovrare le vele, come le scotte, drizze, wang, tesa base ecc."
    },
    {
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "progressivo": "2.2.1-76",
        "domanda": "Un winch self-tailing è un verricello elettronico comandato dalla timoneria per il quale non è necessario l'uso della maniglia.",
        "risposta": "F",
        "id": 175
    },
    {
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "id": 176,
        "domanda": "Stralli e sartie sono manovre fisse.",
        "risposta": "V",
        "progressivo": "2.2.1-77"
    },
    {
        "domanda": "Girando la maniglia in senso orario il winch sostiene una migliore trazione e potenza.",
        "risposta": "V",
        "id": 177,
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "progressivo": "2.2.1-78"
    },
    {
        "domanda": "Il motivo per cui il grillo della penna di randa è del tipo con perno di blocco è per consentire di sganciare la vela evitando che lo stesso cada in mare.",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "progressivo": "2.2.1-79",
        "id": 178,
        "risposta": "V"
    },
    {
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "progressivo": "2.2.1-80",
        "risposta": "V",
        "domanda": "La calza è una sorta di tubo di tela con il quale si raccoglie lo spinnaker o il gennaker prima di ammainarlo.",
        "id": 179
    },
    {
        "risposta": "F",
        "categoria": "TEORIA DELLA VELA",
        "progressivo": "2.1.1-18",
        "id": 18,
        "domanda": "Per andatura di lasco si intende quando una unità a vela procede con una direzione di rotta che forma un angolo di circa 45° rispetto alla direzione del vento reale."
    },
    {
        "id": 180,
        "progressivo": "2.2.1-81",
        "risposta": "F",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "domanda": "Con il fiocco autovirante è necessario cazzare la scotta in virata."
    },
    {
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "risposta": "V",
        "domanda": "Con il fiocco autovirante la scotta è generalmente rinviata a una puleggia sull'albero.",
        "progressivo": "2.2.1-82",
        "id": 181
    },
    {
        "domanda": "Con il genoa avvolgibile ridotto oltre il 30% della superficie si ha una sensibile riduzione di efficienza del profilo.",
        "risposta": "V",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "progressivo": "2.2.1-83",
        "id": 182
    },
    {
        "domanda": "L'avvolgifiocco e il moderno sistema che consente di ridurre la vela di prua senza ammainarla.",
        "risposta": "V",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "progressivo": "2.2.1-84",
        "id": 183
    },
    {
        "domanda": "Lo stopper è la manovra con cui si fissa il boma in posizione di riposo.",
        "risposta": "F",
        "id": 184,
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "progressivo": "2.2.1-85"
    },
    {
        "risposta": "V",
        "progressivo": "2.2.1-86",
        "categoria": "ATTREZZATURA DELLE UNITA' A VELA",
        "id": 185,
        "domanda": "Lo stopper è il sistema di bloccaggio che consente di strozzare una drizza."
    },
    {
        "id": 186,
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "risposta": "V",
        "domanda": "Per \"sventare\" si intende la manovra tesa a condurre l'unità navale con la prua al vento o a mollare le scotte, in modo che le vele non siano portanti",
        "progressivo": "2.3.1-1"
    },
    {
        "progressivo": "2.3.1-2",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "id": 187,
        "domanda": "Per \"sventare\" si intende la manovra tesa a condurre l'unità navale con la poppa al vento.",
        "risposta": "F"
    },
    {
        "domanda": "Con timone a barra per poggiare è necessario porre la barra del timone sopravento ossia dalla parte opposta rispetto alla randa.",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "progressivo": "2.3.1-3",
        "id": 188,
        "risposta": "V"
    },
    {
        "risposta": "F",
        "domanda": "Con timone a barra per poggiare è necessario porre la barra del timone sottovento ossia dallo stesso lato della randa.",
        "progressivo": "2.3.1-4",
        "id": 189,
        "categoria": "MANOVRE DELLE UNITA' A VELA"
    },
    {
        "domanda": "Per andatura di poppa si intende quando una unità a vela procede con una direzione di rotta che forma un angolo di 90° rispetto alla direzione del vento reale.",
        "categoria": "TEORIA DELLA VELA",
        "risposta": "F",
        "progressivo": "2.1.1-19",
        "id": 19
    },
    {
        "domanda": "Quando due unità navali a vela navigano di bolina con rotte convergenti, quella con le mure a sinistra poggierà per lasciare la rotta libera a quella con le mure a dritta, passandole di poppa.",
        "risposta": "V",
        "id": 190,
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "progressivo": "2.3.1-5"
    },
    {
        "risposta": "F",
        "domanda": "Quando due unità navali a vela navigano di bolina con rotte convergenti, quella più lenta lascierà la rotta libera a quella più veloce, passandole di poppa.",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "id": 191,
        "progressivo": "2.3.1-6"
    },
    {
        "domanda": "Quando due unità navali a vela navigano di bolina entrambe con le stesse mure, quella sopravento orzerà per lasciare la rotta libera a quella sottovento.",
        "risposta": "V",
        "id": 192,
        "progressivo": "2.3.1-7",
        "categoria": "MANOVRE DELLE UNITA' A VELA"
    },
    {
        "progressivo": "2.3.1-8",
        "id": 193,
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "risposta": "V",
        "domanda": "Dopo aver tesato la drizza della randa, la base della stessa può essere cazzata, poco o molto, a seconda che si vogliano assumere rispettivamente andature larghe o di bolina."
    },
    {
        "progressivo": "2.3.1-9",
        "id": 194,
        "domanda": "L'abbattuta è la manovra mediante la quale l'unità a vela cambia mure attraversando con la poppa la direzione da cui proviene il vento.",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "risposta": "V"
    },
    {
        "risposta": "F",
        "domanda": "La virata è  la manovra per evitare un ostacolo.",
        "id": 195,
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "progressivo": "2.3.1-10"
    },
    {
        "domanda": "La virata è la manovra usata per raggiungere una meta navigando con il vento in fil di ruota.",
        "id": 196,
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "progressivo": "2.3.1-11",
        "risposta": "F"
    },
    {
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "domanda": "L'abbattuta si esegue quando la barca è alla massima velocità e naviga con andatura al traverso o di bolina.",
        "id": 197,
        "progressivo": "2.3.1-12",
        "risposta": "F"
    },
    {
        "domanda": "Per armare la randa: si collega la borosa all'angolo di mura, si tesa la base e si chiude lo stopper della scotta.",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "id": 198,
        "risposta": "F",
        "progressivo": "2.3.1-13"
    },
    {
        "risposta": "F",
        "id": 199,
        "domanda": "Il punto di mura è posizionato sulla varea del boma.",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "progressivo": "2.3.1-14"
    },
    {
        "progressivo": "2.1.1-2",
        "domanda": "Lo scafo di un'unità a vela è la struttura galleggiante e portante della stessa.",
        "categoria": "TEORIA DELLA VELA",
        "id": 2,
        "risposta": "V"
    },
    {
        "domanda": "Procedendo di bolina, la velocita avvertita dell'unità a vela sembra elevata perché l'intensità del vento percepita risulta superiore rispetto a quella reale.",
        "risposta": "V",
        "progressivo": "2.1.1-20",
        "id": 20,
        "categoria": "TEORIA DELLA VELA"
    },
    {
        "id": 200,
        "domanda": "Si arma la randa cazzando il meolo, inserendo la tavoletta all'interno dell'apposita tasca posta sulla parte più alta dell'albero.",
        "progressivo": "2.3.1-15",
        "risposta": "F",
        "categoria": "MANOVRE DELLE UNITA' A VELA"
    },
    {
        "id": 201,
        "risposta": "F",
        "progressivo": "2.3.1-16",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "domanda": "Dopo aver lascato la drizza della randa, la base della stessa può essere lascata, poco o molto, a seconda che si vogliano assumere andature in bolina o bolina larga."
    },
    {
        "risposta": "V",
        "id": 202,
        "progressivo": "2.3.1-17",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "domanda": "Genoa (o genova) e fiocco si armano allo stesso modo perché  hanno, in generale, lo stesso punto di mura nonché risultano inferiti al medesimo strallo."
    },
    {
        "risposta": "F",
        "id": 203,
        "progressivo": "2.3.1-18",
        "domanda": "La prima operazione necessaria per issare il fiocco o il genoa (o genova) munito di garocci è fissare l'occhiello di bugna nell'apposito attacco ubicato alla base dello strallo.",
        "categoria": "MANOVRE DELLE UNITA' A VELA"
    },
    {
        "id": 204,
        "progressivo": "2.3.1-19",
        "risposta": "F",
        "domanda": "I garrocci di cui è munito il fiocco vanno incocciati allo strallo partendo dal punto di penna e proseguendo verso il punto di scotta.",
        "categoria": "MANOVRE DELLE UNITA' A VELA"
    },
    {
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "progressivo": "2.3.1-20",
        "domanda": "Il dispositivo solitamente utilizzato per agganciare la drizza alla penna è un moschettone impiombato alla drizza stessa.",
        "risposta": "V",
        "id": 205
    },
    {
        "progressivo": "2.3.1-21",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "domanda": "Il nodo utilizzato solitamente per fissare le due scotte alla bugna del fiocco, una per lato, è il parlato doppio.",
        "risposta": "F",
        "id": 206
    },
    {
        "progressivo": "2.3.1-22",
        "risposta": "V",
        "domanda": "La barca viene condotta con la prua al vento al fine di consentire che il fiocco non si gonfi mentre viene issato.",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "id": 207
    },
    {
        "domanda": "Lo strallo cavo offre il vantaggio di abbassare il centro velico del fiocco.",
        "progressivo": "2.3.1-23",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "id": 208,
        "risposta": "F"
    },
    {
        "risposta": "V",
        "id": 209,
        "progressivo": "2.3.1-24",
        "domanda": "La doppia canaletta di uno strallo cavo serve per facilitare la sostituzione di una vela di prua.",
        "categoria": "MANOVRE DELLE UNITA' A VELA"
    },
    {
        "domanda": "Procedendo di bolina, la velocita avvertita dell'unità a vela sembra elevata perché l'intensità del vento percepita risulta inferiore rispetto a quella reale.",
        "progressivo": "2.1.1-21",
        "risposta": "F",
        "categoria": "TEORIA DELLA VELA",
        "id": 21
    },
    {
        "domanda": "Issare la tormentina è la manovra che può essere adottata al fine di ridurre la velocità risalendo il vento",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "progressivo": "2.3.1-25",
        "id": 210,
        "risposta": "F"
    },
    {
        "risposta": "F",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "domanda": "La manovra che può essere adottata al fine di ridurre la velocità nelle andature portanti è far fileggiare la randa.",
        "id": 211,
        "progressivo": "2.3.1-26"
    },
    {
        "id": 212,
        "domanda": "La manovra denominata \"mettersi in panna\" serve per aumentare la velocità.",
        "progressivo": "2.3.1-27",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "risposta": "F"
    },
    {
        "id": 213,
        "risposta": "V",
        "domanda": "La manovra denominata \"mettersi in panna\" consiste nel porre a collo la vela di prua lasciando la randa bordata per la bolina larga nonché ponendo il timone all'orza.",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "progressivo": "2.3.1-28"
    },
    {
        "risposta": "F",
        "progressivo": "2.3.1-29",
        "id": 214,
        "domanda": "La manovra denominata \"mettersi alla cappa\" consiste in una particolare tecnica che consente di navigare a velocità ridotta utilizzando l'ancora galleggiante filata di poppa.",
        "categoria": "MANOVRE DELLE UNITA' A VELA"
    },
    {
        "domanda": "Per \"mano o presa di terzaroli\" si intende la manovra per abbassare il tangone e smagrire lo spinnaker, passando dall'andatura in fil di ruota al traverso.",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "progressivo": "2.3.1-30",
        "risposta": "F",
        "id": 215
    },
    {
        "risposta": "F",
        "id": 216,
        "domanda": "La \"presa di terzaroli\" consiste nell'ammainare completamente la randa ed issare al suo posto la randa di rispetto denominata matafione.",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "progressivo": "2.3.1-31"
    },
    {
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "domanda": "Per \"mettere a segno\" le vele si intende l'avvolgerle correttamente dopo l'utilizzo in navigazione per il loro successivo pronto impiego.",
        "risposta": "F",
        "progressivo": "2.3.1-32",
        "id": 217
    },
    {
        "risposta": "F",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "progressivo": "2.3.1-33",
        "id": 218,
        "domanda": "Il vantaggio della planata è l'aumento del dislocamento dell'unità."
    },
    {
        "id": 219,
        "progressivo": "2.3.1-34",
        "domanda": "Quando la barca si dispone con la prua al vento le vele smagriscono disponendosi trasversalmente all'asse longitudinale dell'unità e orientandosi nella direzione di provenienza del vento apparente.",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "risposta": "F"
    },
    {
        "categoria": "TEORIA DELLA VELA",
        "domanda": "Procedendo di poppa, la velocita avvertita dell'unità a vela sembra minore perché l'intensità del vento percepita risulta inferiore rispetto a quella reale.",
        "risposta": "V",
        "progressivo": "2.1.1-22",
        "id": 22
    },
    {
        "domanda": "La manovra denominata \"strallare\" consiste nel ruotare il tangone verso la parte prodiera dell'unità navale conducendone l'estremità libera in prossimità dello strallo.",
        "progressivo": "2.3.1-35",
        "risposta": "V",
        "id": 220,
        "categoria": "MANOVRE DELLE UNITA' A VELA"
    },
    {
        "id": 221,
        "progressivo": "2.3.1-36",
        "risposta": "F",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "domanda": "La manovra denominata \"quadrare\" consiste nel ruotare il tangone verso la parte prodiera dell'unità navale conducendone l'estremità libera in prossimità dello strallo."
    },
    {
        "domanda": "Per poggiare si intende variare la prua dell'unità, allontanando la prua della stessa rispetto alla direzione di provenienza del vento.",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "id": 222,
        "progressivo": "2.3.1-37",
        "risposta": "V"
    },
    {
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "risposta": "F",
        "id": 223,
        "progressivo": "2.3.1-38",
        "domanda": "Per orzare si intende variare la rotta dell'unità navale assumendo un nuovo valore di rotta opposto a quello della direzione di provenienza del vento."
    },
    {
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "risposta": "F",
        "progressivo": "2.3.1-39",
        "domanda": "Per sventare si intende la manovra tesa a condurre l'unità navale con la poppa al vento.",
        "id": 224
    },
    {
        "id": 225,
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "risposta": "V",
        "domanda": "La virata e l'abbattuta sono le manovre fondamentali per cambiare mure.",
        "progressivo": "2.3.1-40"
    },
    {
        "domanda": "La virata è la  manovra mediante la quale l'unità  a vela si appresta ad ammainare lo spinnaker.",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "risposta": "F",
        "id": 226,
        "progressivo": "2.3.1-41"
    },
    {
        "risposta": "F",
        "id": 227,
        "progressivo": "2.3.1-42",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "domanda": "Per poggiare è necessario porre la barra al centro"
    },
    {
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "domanda": "Quando due unità a vela navigano di bolina con rotte convergenti, quella più lenta lascerà la rotta libera a quella più veloce, passandole di poppa.",
        "progressivo": "2.3.1-43",
        "id": 228,
        "risposta": "F"
    },
    {
        "domanda": "Se due unità a vela navigano entrambe con stesse mura, ha la precedenza quella che si trova sottovento.",
        "id": 229,
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "risposta": "V",
        "progressivo": "2.3.1-44"
    },
    {
        "id": 23,
        "domanda": "Procedendo di poppa, la velocita avvertita dell'unità navale a vela sembra minore perché l'intensità del vento percepita risulta superiore rispetto a quella reale.",
        "categoria": "TEORIA DELLA VELA",
        "risposta": "F",
        "progressivo": "2.1.1-23"
    },
    {
        "progressivo": "2.3.1-45",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "domanda": "Se due unità a vela navigano di bolina con rotte convergenti, quella con mure a sinistra ha la precedenza.",
        "risposta": "F",
        "id": 230
    },
    {
        "risposta": "F",
        "domanda": "Se due unità navigano a vela con mure diverse (una a sinistra e l'altra a dritta), ha la precedenza chi prende il vento a sinistra.",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "id": 231,
        "progressivo": "2.3.1-46"
    },
    {
        "progressivo": "2.3.1-47",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "risposta": "V",
        "id": 232,
        "domanda": "Se due unità navigano a vela  con mure diverse (una a sinistra e l'altra a dritta), ha la precedenza chi prende il vento a dritta."
    },
    {
        "id": 233,
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "domanda": "Se due unità navigano a vela su rotte opposte, quella che ha il vento sulla sinistra deve lasciare libera la rotta all'altra.",
        "risposta": "V",
        "progressivo": "2.3.1-48"
    },
    {
        "id": 234,
        "progressivo": "2.3.1-49",
        "risposta": "V",
        "domanda": "Se un'unità con il vento sulla sinistra vede un'altra unità a vela sopravento e non può stabilire con sicurezza se questa abbia il vento  sulla  sinistra o sulla dritta, deve  manovrare in modo da lasciare libera la rotta .",
        "categoria": "MANOVRE DELLE UNITA' A VELA"
    },
    {
        "risposta": "V",
        "progressivo": "2.3.1-50",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "domanda": "Navigando di bolina stretta, si può ridurre temporaneamente la velocità stingendo il vento oltre l'angolo di bordeggio.",
        "id": 235
    },
    {
        "domanda": "Poggiando da bolina stretta a bolina larga la barca accelera.",
        "id": 236,
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "progressivo": "2.3.1-51",
        "risposta": "V"
    },
    {
        "id": 237,
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "risposta": "V",
        "progressivo": "2.3.1-52",
        "domanda": "Per ridurre lo sbandamento, si smagriscono le vele, cazzando il cunnincham e il tesabase della randa, la drizza del genoa e si arretra il punto di scotta del genoa (o genova)."
    },
    {
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "risposta": "F",
        "progressivo": "2.3.1-53",
        "id": 238,
        "domanda": "Per aumentare la potenza con vento debole si smagriscono le vele, cazzando il cunnincham e il tesabase della randa, la drizza del genoa e si arretra il punto di scotta del genoa (o genova)."
    },
    {
        "risposta": "F",
        "domanda": "La \"messa a segno\" delle vele si ottiene quando le vele sono completamente poste a riva.",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "id": 239,
        "progressivo": "2.3.1-54"
    },
    {
        "categoria": "TEORIA DELLA VELA",
        "risposta": "V",
        "progressivo": "2.1.1-24",
        "id": 24,
        "domanda": "Per \"settore di bordeggio\", o \"angolo morto\", si intende quel settore controvento entro il quale una unità a vela non può indirizzare la sua prua per mancanza di portanza delle vele."
    },
    {
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "risposta": "V",
        "progressivo": "2.3.1-55",
        "id": 240,
        "domanda": "Per ridurre lo sbandamento sotto raffica si muove il carrello (trasto) della randa sottovento o, in assenza del carrello, si lasca la scotta."
    },
    {
        "risposta": "F",
        "domanda": "Per assecondare una rapida poggiata per evitare un ostacolo devo lascare solo il fiocco.",
        "progressivo": "2.3.1-56",
        "id": 241,
        "categoria": "MANOVRE DELLE UNITA' A VELA"
    },
    {
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "domanda": "La ritenuta del boma è quella manovra che si può utilizzare per evitare la strambata nelle andature di granlasco e giardinetto.",
        "progressivo": "2.3.1-57",
        "id": 242,
        "risposta": "V"
    },
    {
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "domanda": "In caso di aumento del vento, riducendo la randa si diminuisce la tendenza orziera dell'unità.",
        "risposta": "V",
        "progressivo": "2.3.1-58",
        "id": 243
    },
    {
        "risposta": "V",
        "domanda": "Quando si comincia a pensare se sia il caso di ridurre la vela a causa dell'eccessivo sbandamento è probabilmente il momento di farlo.",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "id": 244,
        "progressivo": "2.3.1-59"
    },
    {
        "risposta": "V",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "domanda": "E' certamente opportuno ridurre la vela se la barca ha stabilmente la falchetta in acqua.",
        "progressivo": "2.3.1-60",
        "id": 245
    },
    {
        "progressivo": "2.3.1-61",
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "risposta": "V",
        "id": 246,
        "domanda": "La strambata è il rischio più grande che si corre navigando al gran lasco o in poppa (giardinetto)."
    },
    {
        "id": 247,
        "progressivo": "2.3.1-62",
        "risposta": "F",
        "domanda": "Strambata e abbattuta  sono la stessa cosa.",
        "categoria": "MANOVRE DELLE UNITA' A VELA"
    },
    {
        "id": 248,
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "progressivo": "2.3.1-63",
        "domanda": "La strambata è l'abbattuta involontaria e incontrollata.",
        "risposta": "V"
    },
    {
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "progressivo": "2.3.1-64",
        "id": 249,
        "risposta": "F",
        "domanda": "Salvo le ordinanze locali, di norma è possibile entrare in un porto navigando a vela."
    },
    {
        "id": 25,
        "risposta": "F",
        "progressivo": "2.1.1-25",
        "domanda": "Per \"settore di bordeggio\", o \"angolo morto\", si intende quel settore entro il quale una unità a vela può navigare al fine di aumentare la velocità per raggiungere una determinata destinazione.",
        "categoria": "TEORIA DELLA VELA"
    },
    {
        "progressivo": "2.3.1-65",
        "risposta": "V",
        "id": 250,
        "categoria": "MANOVRE DELLE UNITA' A VELA",
        "domanda": "Lascare la randa agevola la poggiata."
    },
    {
        "progressivo": "2.1.1-26",
        "risposta": "V",
        "id": 26,
        "categoria": "TEORIA DELLA VELA",
        "domanda": "Un temporaneo aumento dell'intensità del vento reale (raffica) comporta una favorevole variazione della direzione del vento apparente per assumere un migliore angolo di bolina."
    },
    {
        "domanda": "Una temporanea attenuazione dell'intensità del vento reale comporta una favorevole variazione della direzione del vento apparente per assumere un migliore angolo di bolina.",
        "progressivo": "2.1.1-27",
        "categoria": "TEORIA DELLA VELA",
        "id": 27,
        "risposta": "F"
    },
    {
        "categoria": "TEORIA DELLA VELA",
        "id": 28,
        "progressivo": "2.1.1-28",
        "risposta": "V",
        "domanda": "L'angolo di incidenza risulta essere l'angolo formato tra la direzione del vento apparente e quella verso cui è orientata la vela."
    },
    {
        "progressivo": "2.1.1-29",
        "categoria": "TEORIA DELLA VELA",
        "id": 29,
        "risposta": "F",
        "domanda": "Il centro velico risulta essere il punto di applicazione della forza del vento apparente sulle vele e sull'opera morta."
    },
    {
        "domanda": "La presenza del bulbo zavorrato in un'unità navale a vela ha la funzione di fornire alla stessa una maggiore stabilità per contrastare le azioni esterne (vento).",
        "id": 3,
        "categoria": "TEORIA DELLA VELA",
        "risposta": "V",
        "progressivo": "2.1.1-3"
    },
    {
        "progressivo": "2.1.1-30",
        "risposta": "F",
        "id": 30,
        "categoria": "TEORIA DELLA VELA",
        "domanda": "Il centro di deriva risulta essere il centro geometrico della superficie di deriva posto sotto la chiglia dell'unità a vela."
    },
    {
        "categoria": "TEORIA DELLA VELA",
        "risposta": "V",
        "domanda": "Il centro velico e il centro di deriva si influenzano tra loro generando effetti che sono individuabili e prevedibili per ogni unità a vela.",
        "id": 31,
        "progressivo": "2.1.1-31"
    },
    {
        "risposta": "V",
        "categoria": "TEORIA DELLA VELA",
        "domanda": "In condizioni di timone al centro, quando il centro velico è allineato con il centro di deriva, l'unità a vela si definisce \"neutra\" (né poggiera né orziera).",
        "id": 32,
        "progressivo": "2.1.1-32"
    },
    {
        "risposta": "F",
        "id": 33,
        "progressivo": "2.1.1-33",
        "domanda": "In condizioni di timone al centro, quando il centro velico è allineato con il centro di deriva, l'unità a vela si avvicina alla direzione del vento (orziera).",
        "categoria": "TEORIA DELLA VELA"
    },
    {
        "categoria": "TEORIA DELLA VELA",
        "progressivo": "2.1.1-34",
        "risposta": "V",
        "id": 34,
        "domanda": "La posizione del centro velico nelle unità a vela dipende dalla superficie e dalla forma delle vele, dalla reciproca influenza tra le vele bordate e dalla messa a punto dell'attrezzatura."
    },
    {
        "categoria": "TEORIA DELLA VELA",
        "progressivo": "2.1.1-35",
        "risposta": "V",
        "id": 35,
        "domanda": "Per \"planata\" si intende lo stato in cui viene a trovarsi l'unità a vela navigando in condizioni di equilibrio dinamico sulla cresta dell'onda generato dal suo medesimo avanzamento."
    },
    {
        "id": 36,
        "domanda": "La \"messa a segno\" delle vele è generata dal vento apparente durante la navigazione.",
        "risposta": "V",
        "categoria": "TEORIA DELLA VELA",
        "progressivo": "2.1.1-36"
    },
    {
        "domanda": "La pressione esercitata dal vento sulle vele dipende dall'angolo di incidenza.",
        "progressivo": "2.1.1-37",
        "categoria": "TEORIA DELLA VELA",
        "risposta": "V",
        "id": 37
    },
    {
        "risposta": "F",
        "progressivo": "2.1.1-38",
        "id": 38,
        "domanda": "La pressione esercitata dal vento sulle vele dipende esclusivamente dal valore della prora assunta dall'unità.",
        "categoria": "TEORIA DELLA VELA"
    },
    {
        "id": 39,
        "progressivo": "2.1.1-39",
        "domanda": "La forza di scarroccio risulta perpendicolare all'asse longitudinale dell'unità a vela.",
        "categoria": "TEORIA DELLA VELA",
        "risposta": "V"
    },
    {
        "progressivo": "2.1.1-4",
        "categoria": "TEORIA DELLA VELA",
        "domanda": "La presenza del bulbo zavorrato in un'unità navale a vela ha la funzione di offrire alla stessa una maggior penetrazione alla prua, tale da farle raggiungere velocità più elevate.",
        "risposta": "F",
        "id": 4
    },
    {
        "domanda": "La forza di propulsione risulta perpendicolare all'asse longitudinale dell'unità a vela.",
        "id": 40,
        "categoria": "TEORIA DELLA VELA",
        "risposta": "F",
        "progressivo": "2.1.1-40"
    },
    {
        "id": 41,
        "risposta": "V",
        "categoria": "TEORIA DELLA VELA",
        "progressivo": "2.1.1-41",
        "domanda": "l'albero di un'unità a vela  inclinato verso poppa rende la stessa tendenzialmente orziera."
    },
    {
        "risposta": "V",
        "domanda": "L'albero di un'unità a vela  inclinato verso prua rende la stessa tendenzialmente poggiera.",
        "progressivo": "2.1.1-42",
        "id": 42,
        "categoria": "TEORIA DELLA VELA"
    },
    {
        "domanda": "La funzione delle stecche poste sulla randa è quella di conservare inalterata la forma della vela in qualsiasi condizione meteomarina.",
        "id": 43,
        "categoria": "TEORIA DELLA VELA",
        "risposta": "V",
        "progressivo": "2.1.1-43"
    },
    {
        "categoria": "TEORIA DELLA VELA",
        "id": 44,
        "domanda": "La funzione delle stecche poste sulla randa è quella di garantire l'ottimale indicazione della direzione del vento sulla vela?",
        "progressivo": "2.1.1-44",
        "risposta": "F"
    },
    {
        "progressivo": "2.1.1-45",
        "id": 45,
        "categoria": "TEORIA DELLA VELA",
        "risposta": "V",
        "domanda": "Con riguardo alla teoria della vela, il multiscafo ha una maggiore stabilità."
    },
    {
        "progressivo": "2.1.1-46",
        "categoria": "TEORIA DELLA VELA",
        "risposta": "V",
        "id": 46,
        "domanda": "Il bulbo zavorrato di un'unità a vela fornisce maggiore stabilità per contrastare l'azione esterna del vento."
    },
    {
        "id": 47,
        "progressivo": "2.1.1-47",
        "categoria": "TEORIA DELLA VELA",
        "domanda": "La stabilità di un'imbarcazione a vela è assicurata dal bulbo zavorrato.",
        "risposta": "V"
    },
    {
        "domanda": "La vela tende, per sua natura, ad assumere una posizione neutra di 45° rispetto al flusso del vento e a ricevere una spinta in tale direzione.",
        "categoria": "TEORIA DELLA VELA",
        "progressivo": "2.1.1-48",
        "risposta": "F",
        "id": 48
    },
    {
        "domanda": "Per andatura si intende la direzione verso cui procede un'unità  a vela rispetto alla direzione di provenienza del vento.",
        "categoria": "TEORIA DELLA VELA",
        "progressivo": "2.1.1-49",
        "id": 49,
        "risposta": "V"
    },
    {
        "domanda": "La vela si orienta in relazione al flusso del vento.",
        "categoria": "TEORIA DELLA VELA",
        "risposta": "V",
        "progressivo": "2.1.1-5",
        "id": 5
    },
    {
        "risposta": "V",
        "id": 50,
        "domanda": "Si chiama \"bolina\" l'andatura in cui l'unità a vela procede con una direzione di rotta che forma un angolo di circa 45° rispetto alla direzione del vento reale.",
        "progressivo": "2.1.1-50",
        "categoria": "TEORIA DELLA VELA"
    },
    {
        "categoria": "TEORIA DELLA VELA",
        "progressivo": "2.1.1-51",
        "domanda": "Si chiama \"lasco\" l'andatura in cui l'unità  a vela procede con una direzione di rotta che forma un angolo di circa 135° rispetto alla direzione del vento reale.",
        "risposta": "V",
        "id": 51
    },
    {
        "progressivo": "2.1.1-52",
        "id": 52,
        "risposta": "V",
        "categoria": "TEORIA DELLA VELA",
        "domanda": "Si chiama \"traverso\" l'andatura in cui l'unità  a vela procede con una direzione di rotta che forma un angolo di 90° rispetto alla direzione del vento reale."
    },
    {
        "risposta": "F",
        "id": 53,
        "domanda": "Si chiama \"lasco\" l'andatura in cui l'unità  a vela procede con una direzione di rotta che forma un angolo di circa 90° rispetto alla direzione del vento reale.",
        "categoria": "TEORIA DELLA VELA",
        "progressivo": "2.1.1-53"
    },
    {
        "id": 54,
        "domanda": "Si chiama \"poppa\" o \"fil di ruota\" l'andatura in cui l'unità a vela procede con una direzione di rotta che forma un angolo di 180° rispetto alla direzione del vento reale.",
        "progressivo": "2.1.1-54",
        "categoria": "TEORIA DELLA VELA",
        "risposta": "V"
    },
    {
        "progressivo": "2.1.1-55",
        "domanda": "Procedendo di bolina, a bordo il vento percepito risulta d'intensità superiore a quella reale.",
        "risposta": "V",
        "categoria": "TEORIA DELLA VELA",
        "id": 55
    },
    {
        "domanda": "Procedendo di poppa la velocita avvertita dell'unità a vela sembra elevata perché l'intensità del vento percepita risulta superiore rispetto a quella reale.",
        "id": 56,
        "progressivo": "2.1.1-56",
        "categoria": "TEORIA DELLA VELA",
        "risposta": "F"
    },
    {
        "categoria": "TEORIA DELLA VELA",
        "id": 57,
        "domanda": "Il settore di bordeggio è la zona dello scafo di un'unità navale a vela che concorre a fornire la spinta velica e quindi ad incrementare la velocità della stessa in funzione della direzione del vento.",
        "progressivo": "2.1.1-57",
        "risposta": "F"
    },
    {
        "risposta": "F",
        "id": 58,
        "domanda": "Si intende per lato sottovento, la superficie sopravvento della vela che è sottoposta a una depressione.",
        "progressivo": "2.1.1-58",
        "categoria": "TEORIA DELLA VELA"
    },
    {
        "domanda": "Il centro velico è il punto di applicazione della risultante delle forze dovute all'azione del vento sulle vele.",
        "risposta": "V",
        "id": 59,
        "categoria": "TEORIA DELLA VELA",
        "progressivo": "2.1.1-59"
    },
    {
        "domanda": "Per andatura si intende la direzione verso cui la stessa procede rispetto alla direzione di provenienza del vento.",
        "progressivo": "2.1.1-6",
        "categoria": "TEORIA DELLA VELA",
        "id": 6,
        "risposta": "V"
    },
    {
        "risposta": "F",
        "domanda": "E' il centro velico il punto di applicazione delle forza idrodinamica sullo scafo dovuta allo scarroccio.",
        "id": 60,
        "progressivo": "2.1.1-60",
        "categoria": "TEORIA DELLA VELA"
    },
    {
        "domanda": "Il centro di deriva è il centro dello scafo a vela di piccole dimensioni.",
        "id": 61,
        "risposta": "F",
        "categoria": "TEORIA DELLA VELA",
        "progressivo": "2.1.1-61"
    },
    {
        "categoria": "TEORIA DELLA VELA",
        "id": 62,
        "risposta": "V",
        "progressivo": "2.1.1-62",
        "domanda": "Il centro di deriva è il punto di applicazione della resistenza laterale che si oppone alle forze idrodinamiche esercitate sull'opera viva."
    },
    {
        "progressivo": "2.1.1-63",
        "id": 63,
        "categoria": "TEORIA DELLA VELA",
        "risposta": "F",
        "domanda": "Il centro velico e il centro di deriva servono per determinare il punto nave a vela."
    },
    {
        "progressivo": "2.1.1-64",
        "domanda": "In condizioni di timone neutro (al centro), quando il centro velico è collocato a proravia del centro di deriva, l'unità navale a vela si allontanata dalla direzione del vento (è poggiera).",
        "id": 64,
        "risposta": "V",
        "categoria": "TEORIA DELLA VELA"
    },
    {
        "domanda": "In condizioni di timone neutro (al centro), quando il centro velico è allineato con il centro di deriva, l'unità navale a vela si definisce equilibrata (né poggiera né orziera).",
        "risposta": "V",
        "id": 65,
        "progressivo": "2.1.1-65",
        "categoria": "TEORIA DELLA VELA"
    },
    {
        "id": 66,
        "progressivo": "2.1.1-66",
        "risposta": "V",
        "domanda": "La posizione del centro velico per le unità navali a vela dipende dalla forma della vela, dalla reciproca influenza tra le vele bordate e dalla messa a punto dell'attrezzatura.",
        "categoria": "TEORIA DELLA VELA"
    },
    {
        "categoria": "TEORIA DELLA VELA",
        "risposta": "F",
        "id": 67,
        "domanda": "La posizione del centro di deriva delle unità a vela dipende dalla forma delle vele.",
        "progressivo": "2.1.1-67"
    },
    {
        "risposta": "F",
        "domanda": "Si intende per sopravento, il lato dell'unità ubicato al di sotto del punto di applicazione del vento apparente.",
        "progressivo": "2.1.1-68",
        "categoria": "TEORIA DELLA VELA",
        "id": 68
    },
    {
        "domanda": "Si intende per sottovento, il lato dell'unità opposto rispetto a quello su cui batte il vento.",
        "risposta": "V",
        "id": 69,
        "categoria": "TEORIA DELLA VELA",
        "progressivo": "2.1.1-69"
    },
    {
        "categoria": "TEORIA DELLA VELA",
        "id": 7,
        "domanda": "Per andatura si intende la velocità riaggiunta dall'unità navale a vela rispetto alla direzione di provenienza del vento",
        "progressivo": "2.1.1-7",
        "risposta": "F"
    },
    {
        "id": 70,
        "domanda": "La dizione di \"mure a dritta/mure a sinistra\" indica la parte prodiera dello scafo sulla quale si infrangono le onde.",
        "progressivo": "2.1.1-70",
        "risposta": "F",
        "categoria": "TEORIA DELLA VELA"
    },
    {
        "progressivo": "2.1.1-71",
        "risposta": "F",
        "categoria": "TEORIA DELLA VELA",
        "domanda": "Per grasso della vela si intende la parte della vela più prossima alla tensione della drizza.",
        "id": 71
    },
    {
        "progressivo": "2.1.1-72",
        "domanda": "Il piano velico è l'organizzazione delle vele di un'unità come da progetto ed è essenzialmente caratterizzato dal numero di alberi e dal tipo di vele a disposizione.",
        "id": 72,
        "categoria": "TEORIA DELLA VELA",
        "risposta": "V"
    },
    {
        "risposta": "F",
        "domanda": "Per portanza si intende il peso complessivo di tutte le attrezzature veliche imbarcate su un'unità a vela.",
        "progressivo": "2.1.1-73",
        "id": 73,
        "categoria": "TEORIA DELLA VELA"
    },
    {
        "progressivo": "2.1.1-74",
        "categoria": "TEORIA DELLA VELA",
        "domanda": "Lo svergolamento della vela dipende dalla velocità del vento reale che aumenta in funzione dell'altezza da cui spira rispetto alla superficie del mare.",
        "risposta": "V",
        "id": 74
    },
    {
        "domanda": "Per \"straorza\" si intende l'improvviso cambio di prua verso la direzione di provenienza del vento,  causato da una raffica  o da un'onda particolarmente intensa.",
        "id": 75,
        "categoria": "TEORIA DELLA VELA",
        "risposta": "V",
        "progressivo": "2.1.1-75"
    },
    {
        "categoria": "TEORIA DELLA VELA",
        "progressivo": "2.1.1-76",
        "risposta": "V",
        "domanda": "Per \"strapoggia\" si intende l'improvviso allontanarsi della prua dalla direzione del vento tale anche da causare una strambata.",
        "id": 76
    },
    {
        "id": 77,
        "domanda": "Per \"raffica\" si intende una particolare tipologia di venti, di rilevante intensità, che spirano prevalentemente da levante o da ponente.",
        "risposta": "F",
        "categoria": "TEORIA DELLA VELA",
        "progressivo": "2.1.1-77"
    },
    {
        "id": 78,
        "risposta": "V",
        "domanda": "Per \"scarroccio\" si intende la traslazione laterale dell'unità a vela per effetto del vento esercitato sullo scafo durante la navigazione.",
        "progressivo": "2.1.1-78",
        "categoria": "TEORIA DELLA VELA"
    },
    {
        "progressivo": "2.1.1-79",
        "domanda": "Un'unità a vela scuffia quando si ribalta, immergendo l'albero anche fino a 180° rispetto alla posizione iniziale.",
        "id": 79,
        "categoria": "TEORIA DELLA VELA",
        "risposta": "V"
    },
    {
        "domanda": "Quando l'unità a vela si muove a favore di vento, il vento apparente equivale alla differenza tra il vento reale e quello di velocità dell'unità navale stessa.",
        "risposta": "V",
        "progressivo": "2.1.1-8",
        "id": 8,
        "categoria": "TEORIA DELLA VELA"
    },
    {
        "categoria": "TEORIA DELLA VELA",
        "id": 80,
        "risposta": "V",
        "progressivo": "2.1.1-80",
        "domanda": "Si intende per \"smagrire\" la vela il variare della superficie portante della stessa riducendone la sua concavità."
    },
    {
        "progressivo": "2.1.1-81",
        "risposta": "F",
        "domanda": "Si dice \"stringere il vento\" il condurre l'unità a vela lascando quanto più possibile le vele, allontanando la prua dalla direzione dalla quale proviene il vento.",
        "categoria": "TEORIA DELLA VELA",
        "id": 81
    },
    {
        "domanda": "Si dice \"poggiare\" il condurre l'unità a vela lascando quanto più possibile le vele, allontanando la prua dalla direzione dalla quale proviene il vento.",
        "categoria": "TEORIA DELLA VELA",
        "progressivo": "2.1.1-82",
        "id": 82,
        "risposta": "V"
    },
    {
        "risposta": "V",
        "id": 83,
        "progressivo": "2.1.1-83",
        "domanda": "La forza di propulsione risulta parallela all'asse longitudinale dell'unità navale a vela.",
        "categoria": "TEORIA DELLA VELA"
    },
    {
        "id": 84,
        "progressivo": "2.1.1-84",
        "domanda": "La forza di scarroccio e la forza di propulsione hanno origine dalla forza risultante generata dal vento sulla superificie velica.",
        "risposta": "V",
        "categoria": "TEORIA DELLA VELA"
    },
    {
        "progressivo": "2.1.1-85",
        "categoria": "TEORIA DELLA VELA",
        "id": 85,
        "domanda": "Si intende per \"corda\" della vela la linea idealmente tracciata per unire le due estremità del profilo della vela.",
        "risposta": "V"
    },
    {
        "progressivo": "2.1.1-86",
        "domanda": "La concavità della vela assolve alla funzione di diminuire la resistenza all'avanzamento dell'unità.",
        "categoria": "TEORIA DELLA VELA",
        "risposta": "F",
        "id": 86
    },
    {
        "categoria": "TEORIA DELLA VELA",
        "risposta": "V",
        "progressivo": "2.1.1-87",
        "id": 87,
        "domanda": "Lo spostamento del peso dell'equipaggio a bordo durante la navigazione a vela può servire a contrastare l'azione sbandante generata dal vento sulla superficie velica."
    },
    {
        "risposta": "V",
        "domanda": "Per contrastare la tendenza poggera dell'unità a vela è utile spostare i pesi verso prua.",
        "id": 88,
        "categoria": "TEORIA DELLA VELA",
        "progressivo": "2.1.1-88"
    },
    {
        "categoria": "TEORIA DELLA VELA",
        "risposta": "F",
        "progressivo": "2.1.1-89",
        "id": 89,
        "domanda": "Per contrastare la tendenza orziera dell'unità a vela è utile spostare i pesi verso l'albero."
    },
    {
        "domanda": "Quando l'unità a vela si muove a favore di vento, il vento apparente,  corrisponde alla somma tra il vento reale e quello di velocità dell'unità navale",
        "id": 9,
        "progressivo": "2.1.1-9",
        "categoria": "TEORIA DELLA VELA",
        "risposta": "F"
    },
    {
        "risposta": "V",
        "categoria": "TEORIA DELLA VELA",
        "domanda": "E' meglio evitare un'impostazione troppo poggera di un'unità perché tale impostazione limita notevolmente l'azione del timone.",
        "id": 90,
        "progressivo": "2.1.1-90"
    },
    {
        "id": 91,
        "risposta": "F",
        "progressivo": "2.1.1-91",
        "categoria": "TEORIA DELLA VELA",
        "domanda": "Un'unità in navigazione a vela con andatura di bolina, se eccessivamente sbandata sottovento, subisce un aumento della velocità e una miglior performance delle vele bordate."
    },
    {
        "domanda": "E' meglio preferire un'impostazione leggermente orziera di un'unità a vela perché tale impostazione favorisce le prestazioni e la sicurezza a bordo.",
        "categoria": "TEORIA DELLA VELA",
        "risposta": "V",
        "id": 92,
        "progressivo": "2.1.1-92"
    },
    {
        "risposta": "V",
        "progressivo": "2.1.1-93",
        "categoria": "TEORIA DELLA VELA",
        "id": 93,
        "domanda": "L'inclinazione verso la prua dell'albero di un'unità a vela rende la stessa tendenzialmente poggera."
    },
    {
        "categoria": "TEORIA DELLA VELA",
        "risposta": "F",
        "progressivo": "2.1.1-94",
        "id": 94,
        "domanda": "L'inclinazione verso la poppa dell'albero di un'unità a vela rende la stessa tendenzialmente poggera."
    },
    {
        "risposta": "V",
        "id": 95,
        "progressivo": "2.1.1-95",
        "domanda": "Lascare la drizza e la base della randa aumenta la concavità della vela (grasso) e le fa assumere una configurazione adatta all'andatura in fil di ruota.",
        "categoria": "TEORIA DELLA VELA"
    },
    {
        "risposta": "V",
        "progressivo": "2.1.1-96",
        "categoria": "TEORIA DELLA VELA",
        "id": 96,
        "domanda": "L'angolo di incidenza è quello formato tra la direzione del vento apparente e quella verso cui è orientata la vela, in pratica l'angolo con cui il profilo fende l'aria."
    },
    {
        "risposta": "V",
        "categoria": "TEORIA DELLA VELA",
        "id": 97,
        "progressivo": "2.1.1-97",
        "domanda": "La spinta della randa è principalmente orziera, quella del genoa o del fiocco tendenzialmente poggiera."
    },
    {
        "progressivo": "2.1.1-98",
        "id": 98,
        "domanda": "Al crescere del vento si cazzano cunningham (o la drizza randa), il tesabase, la drizza genova.",
        "risposta": "V",
        "categoria": "TEORIA DELLA VELA"
    },
    {
        "progressivo": "2.1.1-99",
        "id": 99,
        "categoria": "TEORIA DELLA VELA",
        "risposta": "F",
        "domanda": "Al crescere del vento è utile spostare verso prua il carrello del genoa."
    }
]


export const DATA_CARTEGGI_ENTRO_12_BASEE: any[] = [
	{
		"responses": [
			"5.2÷5.8 M",
			"09:57÷10:03",
			"12.3÷13.7 lt.",
			"lat.42°(48’.2÷48'.8) Nlong.010°(08’.1÷08'.7) E",
			"lat.42°(49’.4÷50'.0) Nlong.010°(15'.4÷16'.0) E"
		],
		"questions": [
			"Quesito 1: distanza",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"id": "4.1.1 - 1",
		"n": 1,
		"settore": "NORD OVEST ORIZZONTALE",
		"text": "Partenza alle ore 09:00 da Capo di S. Andrea (Nord Isola d’Elba) diretti a Capo d'Enfola con velocità 5.5 nodi, considerando che il consumo orario del motore è pari a 10 l/h, determinare:"
	},
	{
		"n": 10,
		"id": "4.1.1 - 10",
		"text": "Partenza da Scoglietto (Nord Elba) alle ore 09:00 con una rotta per raggiungere Capo d’Enfola, dove dobbiamo arrivare alle ore 09:30, sapendo che il consumo medio del nostro motore è di 10 l/h, determinare:",
		"settore": "NORD OVEST ORIZZONTALE",
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"responses": [
			"2.7÷3.3 M",
			"5.4÷6.6 n",
			"6.5 lt.",
			"lat.42°(49’.4÷50'.0) Nlong.010°(19’.5÷20'.1) E",
			"lat.42°(49’.4÷50'.0) Nlong.010°(15’.4÷16'.0) E"
		]
	},
	{
		"responses": [
			"3.3÷3.9 M",
			"6.6÷7.8 n",
			"13 lt.",
			"lat.42°(43’.7÷44'.3) Nlong.010°(18’.9÷19'.5) E",
			"lat. 42°(43’.3÷43'.9) Nlong.010°(14'.0÷14'.6) E"
		],
		"id": "4.1.1 - 11",
		"settore": "NORD OVEST ORIZZONTALE",
		"n": 11,
		"text": "Partenza da Isola Corbella (Sud Isola d’Elba) alle ore 10:00 diretti a Capo di Poro che si vuole raggiungere in mezz’ora, sapendo che il motore ha un consumo orario di 20 l/h, determinare:",
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		]
	},
	{
		"questions": [
			"Quesito 1: distanza",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"settore": "NORD OVEST ORIZZONTALE",
		"responses": [
			"5.4÷6.0 M",
			"10:28÷10:32",
			"12.3÷13.7 lt.",
			"lat.42°(43’.6÷44'.2) Nlong.010°(21'.7÷22'.3) E",
			"lat. 42°(43’.3÷43'.9) Nlong.010°(14'.0÷14'.6) E"
		],
		"text": "Partenza da Punta Morcone (Sud Isola d’Elba) alle ore 10:00\ndiretti a Capo di Poro.\nTenendo conto che la nostra velocità è pari a 11.4 nodi, sapendo che il consumo medio del nostro motore è di 20 l/h, determinare:",
		"id": "4.1.1 - 12",
		"n": 12
	},
	{
		"responses": [
			"8.7÷9.3 M",
			"10:29÷10:31",
			"12.6÷13.4 lt.",
			"lat.42°(43’.2÷43'.8) Nlong.010°(09’.2÷09'.8) E",
			"lat.42°(42’.5÷43'.1) Nlong.010°(21’.4÷22'.0) E"
		],
		"questions": [
			"Quesito 1: distanza",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"settore": "NORD OVEST ORIZZONTALE",
		"id": "4.1.1 - 13",
		"n": 13,
		"text": "Partenza alle ore 10:00 da Punta di Fetovaia (Sud Elba) con una rotta per Isola Corbelli.\nTenendo conto che la nostra velocità è pari a 18 nodi, sapendo che il consumo medio del nostro motore è di 20 l/h, determinare:"
	},
	{
		"questions": [
			"Quesito 1: distanza",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"id": "4.1.1 - 14",
		"settore": "NORD OVEST ORIZZONTALE",
		"text": "Partenza da Capo di Fonza (Sud Elba) alle ore 10:00 con una rotta per Isola Corbelli.\nTenendo conto che la nostra velocità è pari a 7 nodi, sapendo che il consumo medio del nostro motore è di 20 l/h, determinare:",
		"responses": [
			"3.2÷3.8 M",
			"10:27÷10:33",
			"11.9÷14.1 lt.",
			"lat.42°(43’.8÷44'.4) Nlong.010°(16’.9÷17'.5) E",
			"lat.42°(42’.5÷43'.1) Nlong.010°(21’.4÷22'.0) E"
		],
		"n": 14
	},
	{
		"responses": [
			"3.2÷3.8 M",
			"3.2÷3.8 n",
			"26 lt.",
			"lat. 42°(43’.3÷43'.9) Nlong.010°(14'.0÷14'.6) E",
			"lat.42°(43’.2÷43'.8) Nlong.010°(09’.2÷09'.8) E"
		],
		"text": "Partenza da Capo di Poro (Sud Elba) alle ore 11:00 con una rotta per raggiungere Punta di Fetovaia dopo 1 ora di navigazione, considerando che il consumo orario del nostro motore è di 20 l/h, determinare:",
		"settore": "NORD OVEST ORIZZONTALE",
		"id": "4.1.1 - 15",
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"n": 15
	},
	{
		"text": "Partenza alle ore 10:00 da Punta di Fetovaia (Sud Elba) con una\nrotta per Isola Corbelli da raggiungere in mezz’ora di navigazione, considerando che il consumo orario del nostro motore è di 20 l/h, determinare:",
		"n": 16,
		"responses": [
			"8.7÷9.3 M",
			"17.4÷18.6 n",
			"13 lt.",
			"lat.42°(43’.2÷43'.8) Nlong.010°(09’.2÷09'.8) E",
			"lat.42°(42’.5÷43'.1) Nlong.010°(21’.4÷22'.0) E"
		],
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"id": "4.1.1 - 16",
		"settore": "NORD OVEST ORIZZONTALE"
	},
	{
		"id": "4.1.1 - 17",
		"text": "Partenza alle ore 10:00 da Punta del Marchese (Isola Pianosa)\ndiretti a Punta le Tombe (Isola d’Elba).\nTenendo conto che la nostra velocità è di 7.2 nodi, sapendo che il consumo orario del nostro motore è pari a 15 l/h, determinare:",
		"n": 17,
		"settore": "NORD OVEST VERTICALE",
		"questions": [
			"Quesito 1: distanza",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"responses": [
			"6.9÷7.5 M",
			"10:57÷11:03",
			"18.7÷20.3 lt.",
			"lat.42°(36’.8÷37'.4) Nlong.010°(04’.5÷05'.1) E",
			"lat. 42°(43’.6÷44'.2) Nlong.010°(07’.9÷08'.5) E"
		]
	},
	{
		"responses": [
			"8.5÷9.1 M",
			"8.5÷9.1 n",
			"13 lt.",
			"lat.42°(45’.6÷46'.2) Nlong.010°(05’.9÷06'.5) E",
			"lat.42°(36’.8÷37'.4) Nlong.010°(04’.5÷05'.1) E"
		],
		"n": 18,
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"id": "4.1.1 - 18",
		"text": "Partenza da Punta Nera (Isola d’Elba) alle ore 09:00 diretti a Punta del Marchese (Isola Pianosa) da raggiungere alle ore 10:00, considerando che il consumo orario del motore è di 10 l/h, determinare:",
		"settore": "NORD OVEST VERTICALE"
	},
	{
		"id": "4.1.1 - 19",
		"settore": "NORD OVEST VERTICALE",
		"responses": [
			"7.8÷8.4 M",
			"10:58÷11:02",
			"18.7÷20.2 lt.",
			"lat.42°(36’.8÷37'.4) Nlong.010°(04’.5÷05'.1) E",
			"lat.42°(44’.8÷45'.4) Nlong.010°(06’.6÷07'.2) E"
		],
		"text": "Partenza da Punta del Marchese (Isola Pianosa) alle ore 10:00 diretti a Punta della Testa (Isola d'Elba) con velocità 8.1 nodi, considerando che il consumo orario del nostro motore è di 15 l/h, determinare:",
		"n": 19,
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza",
			"Quesito 5: coordinate punto di arrivo"
		]
	},
	{
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"id": "4.1.1 - 2",
		"settore": "NORD OVEST ORIZZONTALE",
		"text": "Partenza da Capo di Poro (Sud Isola d’Elba) alle ore 11:00 diretti a\nPunta Morcone (Elba).\nLa nostra velocità è di 5.7 nodi ed il consumo del motore è pari a 10 l/h, determinare:",
		"responses": [
			"5.4÷6.0 M",
			"11:57÷12:03",
			"12.3÷13.7 lt.",
			"lat. 42°(43’.3÷43'.9) Nlong.010°(14'.0÷14'.6) E",
			"lat.42°(43’.6÷44'.2) Nlong.010°(21.’7÷22'.3) E"
		],
		"n": 2
	},
	{
		"n": 20,
		"text": "Partenza da Punta di Fetovaia (Isola d’Elba) alle ore 08:00 con una rotta per Punta del Grottone (Isola Pianosa) navigando con una velocità pari a 4.8 nodi, sapendo che il motore ha un consumo orario di 20 l/h, determinare:",
		"responses": [
			"6.9÷7.5 M",
			"09:26÷09:34",
			"37.4÷40.6 lt.",
			"lat.42°(43’.2÷43'.8) Nlong.010°(09’.2÷09'.8) E",
			"lat.42°(36’.5÷37'.1) Nlong.010°(05’.1÷05'.7) E"
		],
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"id": "4.1.1 - 20",
		"settore": "NORD OVEST VERTICALE"
	},
	{
		"questions": [
			"Quesito 1: distanza",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"responses": [
			"6.9÷7.5 M",
			"10:29÷10:31",
			"6.2÷6.8 lt.",
			"lat.42°(36’.5÷37'.1) Nlong.010°(05’.1÷05'.7) E",
			"lat.42°(43’.2÷43'.8) Nlong.010°(09’.2÷09'.8) E"
		],
		"text": "Si parte alle ore 10:00 da Punta del Grottone (Isola di Pianosa) diretti a Punta di Fetovaia con velocità 14.4 nodi, sapendo che il nostro motore ha un consumo medio pari a 10 l/h, determinare:",
		"n": 21,
		"id": "4.1.1 - 21",
		"settore": "NORD OVEST VERTICALE"
	},
	{
		"n": 22,
		"settore": "NORD OVEST VERTICALE",
		"responses": [
			"1.7÷2.3 M.",
			"3.4÷4.6 n",
			"6.5 lt.",
			"lat.42°(48’.2÷48'.8) Nlong.010°(08’.1÷08'.7) E",
			"lat.42°(48’.4÷49'.0) Nlong.010°(10’.9÷11'.5) E"
		],
		"id": "4.1.1 - 22",
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza",
			"Quesito 5: coordinate punto di arrivo"
		],
		"text": "Partenza da Capo S. Andrea (Nord Isola d'Elba) alle ore 12:00 con una rotta per raggiungere Punta del Nasuto alle ore 12:30 e sapendo che il consumo medio del nostro motore è di 10 l/h, determinare:"
	},
	{
		"text": "Partenza da Punta Nera (Ovest Elba) alle ore 08:00 con una rotta per raggiungere Punta del Marchese (Isola Pianosa).\nTenendo conto che la nostra velocità è di 8.8 nodi, sapendo che il consumo medio del nostro motore è di 20 l/h, determinare:",
		"responses": [
			"8.5÷9.1 M",
			"08:58÷09:02",
			"25.1÷26.9 lt.",
			"lat.42°(45’.6÷46'.2) Nlong.010°(05'.9÷06'.5) E",
			"lat.42°(36’.8÷37'.4) Nlong.010°(04’.5÷05'.1) E"
		],
		"id": "4.1.1 - 23",
		"n": 23,
		"questions": [
			"Quesito 1: distanza",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"settore": "NORD OVEST VERTICALE"
	},
	{
		"responses": [
			"6.9÷7.5 M.",
			"6.9÷7.5 n",
			"13 lt.",
			"lat.42°(36'.7÷37'.3) Nlong.010°(04’.9÷05'.5) E",
			"lat.42°(43’.2÷43'.8) Nlong.010°(09’.2÷09'.8) E"
		],
		"settore": "NORD OVEST VERTICALE",
		"id": "4.1.1 - 24",
		"n": 24,
		"text": "Partenza da Scoglio Forano (Isola Pianosa) alle ore 07:00 diretti a Punta di Fetovaia (Isola d'Elba), dove si deve arrivare alle ore 08:00, sapendo che il consumo medio del nostro motore è di 10 l/h,  determinare:",
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		]
	},
	{
		"settore": "NORD OVEST VERTICALE",
		"questions": [
			"Quesito 1: distanza",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza",
			"Quesito 5: coordinate punto di arrivo"
		],
		"responses": [
			"8.0÷8.6 M",
			"10:58÷11:02",
			"12.5÷13.5 lt.",
			"lat.42°(44’.8÷45'.4) Nlong.010°(06’.6÷07'.2) E",
			"lat.42°(36’.5÷37'.1) Nlong.010°(05’.1÷05'.7) E"
		],
		"text": "Partenza da Punta della Testa (Ovest Elba) alle ore 10:00 diretti a Punta del Grottone (Isola Pianosa).\nTenendo conto che la nostra velocità è di 8.3 nodi e che il consumo medio del nostro motore è di 10 l/h, determinare:",
		"id": "4.1.1 - 25",
		"n": 25
	},
	{
		"n": 26,
		"settore": "NORD OVEST VERTICALE",
		"id": "4.1.1 - 26",
		"text": "Partenza da Punta del Marchese (Isola Pianosa) alle ore 10:00 con una rotta per Punta le Tombe (Isola d'Elba) che si vuole raggiungere dopo 2 ore di navigazione e sapendo che il consumo medio del nostro motore è di 15 l/h, determinare:",
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"responses": [
			"6.9÷7.5 M.",
			"3.4÷3.7 n",
			"39 lt.",
			"lat.42°(36’.8÷37'.4) Nlong.010°(04’.5÷05'.1) E",
			"lat. 42°(43’.6÷44'.2) Nlong.010°(07’.9÷08'.5) E"
		]
	},
	{
		"responses": [
			"6.9÷7.5 M.",
			"4.6÷5.0 n",
			"39 lt.",
			"lat. 42°(43’.6÷44'.2) Nlong.010°(07’.9÷08'.5) E",
			"lat.42°(36'.7÷37'.3) Nlong.010°(04’.9÷05'.5) E"
		],
		"id": "4.1.1 - 27",
		"settore": "NORD OVEST VERTICALE",
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza",
			"Quesito 5: coordinate punto di arrivo"
		],
		"n": 27,
		"text": "Partenza da Punta le Tombe (Isola d’Elba) alle ore 08:00 con una rotta per Scoglio Forano (Isola Pianosa) che si vuole raggiungere alle ore 09:30, sapendo che il motore ha un consumo orario di 20 l/h, determinare:"
	},
	{
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"n": 28,
		"id": "4.1.1 - 28",
		"text": "Partenza da Punta del Nasuto (Nord Isola d'Elba) alle ore 09:00 diretti a Capo S. Andrea che si vuole raggiungere alle ore 09:30, sapendo che il motore ha un consumo orario di\n10 l/h, determinare:",
		"settore": "NORD OVEST VERTICALE",
		"responses": [
			"1.7÷2.3 M.",
			"3.4÷4.6 n",
			"6.5 lt.",
			"lat.42°(48’.4÷49'.0) Nlong.010°(10’.9÷11'.5) E",
			"lat.42°(48’.2÷48'.8) Nlong.010°(08’.1÷08'.7) E"
		]
	},
	{
		"text": "Partenza alle ore 10:00 da Punta del Marchese (Isola Pianosa) con una rotta per raggiungere Punta Nera (Isola d'Elba).\nTenendo conto che la nostra velocità è pari a 4.4 nodi, sapendo che il consumo medio del nostro motore è di 10 l/h, determinare:",
		"n": 29,
		"questions": [
			"Quesito 1: distanza",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"id": "4.1.1 - 29",
		"settore": "NORD OVEST VERTICALE",
		"responses": [
			"8.5÷9.1 M",
			"11:56÷12:04",
			"25.1÷26.9 lt.",
			"lat.42°(36’.8÷37'.4) Nlong.010°(04’.5÷05'.1) E",
			"lat.42°(45’.6÷46'.2) Nlong.010°(05’.9÷06'.5) E"
		]
	},
	{
		"responses": [
			"5.2÷5.8 M",
			"16:57÷17:03",
			"12.3÷13.7 lt.",
			"lat.42°(42’.5÷43'.1) Nlong.010°(21’.4÷22'.0) E",
			"lat. 42°(43’.3÷43'.9) Nlong.010°(14'.0÷14'.6) E"
		],
		"id": "4.1.1 - 3",
		"questions": [
			"Quesito 1: distanza",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"settore": "NORD OVEST ORIZZONTALE",
		"text": "Partenza da Isola Corbelli (Sud Isola d’Elba) alle ore 16:00 con una\nrotta per Capo di Poro.\nTenendo conto che la nostra velocità è pari a 5.5 nodi, sapendo che il consumo medio del nostro motore è di 10 l/h, determinare:",
		"n": 3
	},
	{
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza",
			"Quesito 5: coordinate punto di arrivo"
		],
		"id": "4.1.1 - 30",
		"text": "Partenza alle ore 10:00 da Punta del Grottone (Isola Pianosa) con una rotta per Punta di Fetovaia (Elba) da raggiungere alle ore 12:00 e sapendo che il consumo medio del nostro motore è di 15 l/h, determinare:",
		"settore": "NORD OVEST VERTICALE",
		"responses": [
			"6.9÷7.5 M.",
			"3.4÷3.7 n",
			"39 lt.",
			"lat.42°(36’.5÷37'.1) Nlong.010°(05’.1÷05'.7) E",
			"lat.42°(43’.2÷43'.8) Nlong.010°(09’.2÷09'.8) E"
		],
		"n": 30
	},
	{
		"id": "4.1.1 - 31",
		"n": 31,
		"text": "Partenza, alle ore 10:00 da Scoglio Forano (Isola Pianosa) con una rotta per Punta di Fetovaia da raggiungere alle ore 10:30 e sapendo che il consumo medio del nostro motore è di 10 l/h, determinare:",
		"responses": [
			"6.9÷7.5 M.",
			"13.8÷15.0 n",
			"6.5 lt.",
			"lat.42°(36'.7÷37'.3) Nlong.010°(04’.9÷05'.5) E",
			"lat.42°(43’.2÷43'.8) Nlong.010°(09’.2÷09'.8) E"
		],
		"settore": "NORD OVEST VERTICALE",
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		]
	},
	{
		"n": 32,
		"text": "Partenza da Punta le Tombe (Isola d'Elba) alle ore 09:00 diretti a Punta del Marchese (Isola Pianosa).\nTenendo conto che la nostra velocità è pari a 3.6 nodi, sapendo che il consumo medio del nostro motore è di 10 l/h,  determinare:",
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"id": "4.1.1 - 32",
		"responses": [
			"6.9÷7.5 M",
			"10:55÷11:05",
			"24.9÷27.1 lt.",
			"lat.42°(43’.6÷44'.2) Nlong.010°(07’.9÷08'.5) E",
			"lat.42°(36’.8÷37'.4) Nlong.010°(04’.5÷05'.1) E"
		],
		"settore": "NORD OVEST VERTICALE"
	},
	{
		"questions": [
			"Quesito 1: distanza",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"responses": [
			"9.4÷10.0 M",
			"4.7÷5.0 n",
			"26 lt.",
			"lat. 42°(21’.3÷21'.9) Nlong.010°(55’.0÷55'.6) E",
			"lat.42°(26’.5÷27'.1) Nlong.011°(06’.0÷06'.6) E"
		],
		"text": "Partenza alle ore 10:00 da Giglio Porto diretti a Punta Lividonia dove dobbiamo arrivare alle ore 12:00, considerando che il consumo orario del motore è pari a 10 l/h, determinare:",
		"id": "4.1.1 - 33",
		"n": 33,
		"settore": "SUD EST"
	},
	{
		"settore": "SUD EST",
		"n": 34,
		"text": "Partenza da Punta Lividonia (Promontorio dell’Argentario) alle ore 10:30 diretti a Talamone (Faro). La nostra Velocità è di 6.4 nodi ed il consumo del motore pari a 10 l/h, determinare:",
		"id": "4.1.1 - 34",
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"responses": [
			"6.1÷6.7 M",
			"11:27÷11:33",
			"12.4÷13.6 lt.",
			"lat.42°(26’.5÷27'.1) Nlong.011°(06’.0÷06'.6) E",
			"lat. 42°(32’.8÷33'.4) Nlong.011°(07’.7÷08'.3) E"
		]
	},
	{
		"n": 35,
		"id": "4.1.1 - 35",
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza",
			"Quesito 5: coordinate punto di arrivo"
		],
		"responses": [
			"10.4÷11.0 M",
			"10.4÷11.0 n",
			"13 lt.",
			"lat.42°(32’.8÷33'.4) Nlong.011°(07’.7÷08'.3) E",
			"lat.42°(33’.4÷34'.0) Nlong.010°(53’.4÷54'.0) E"
		],
		"text": "Partenza da Talamone (faro) alle ore 08:00 diretti a  Formica Piccola (Formiche di Grosseto) dove si deve arrivare alle ore 09:00, considerando che il consumo orario del motore è pari a 10 l/h, determinare:",
		"settore": "SUD EST"
	},
	{
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza",
			"Quesito 5: coordinate punto di arrivo"
		],
		"id": "4.1.1 - 36",
		"n": 36,
		"text": "Partenza da Giglio Porto alle ore 08:00 diretti a Capo d’Uomo (Argentario) con una velocità di 4 nodi, sapendo che il motore ha un consumo orario di 10 l/h, determinare:",
		"settore": "SUD EST",
		"responses": [
			"7.7÷8.3 M",
			"09:55÷10:05",
			"25.0÷27.0 lt.",
			"lat. 42°(21’.3÷21'.9) Nlong.010°(55’.0÷55'.6) E",
			"lat.42°(23.2’÷23'.8) Nlong.011°(05’.5÷06'.1) E"
		]
	},
	{
		"responses": [
			"6.5÷7.1 M",
			"4.3÷4.7 n",
			"19.5 lt.",
			"lat.42°(32’.8÷33'.4) Nlong.011°(07'.7÷08'.3) E",
			"lat.42°(26’.0÷26'.6) Nlong.011°(07'.7÷08'.3) E"
		],
		"n": 37,
		"id": "4.1.1 - 37",
		"settore": "SUD EST",
		"text": "Partenza da Talamone (faro) alle ore 09:00 diretti a Punta Nera (Nord promontorio Argentario) che si vuole raggiungere alle ore 10:30, sapendo che il consumo orario del motore è di 10 l/h, determinare:",
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		]
	},
	{
		"id": "4.1.1 - 38",
		"responses": [
			"9.7÷10.3 M",
			"12:25÷12.35",
			"31.5÷33.5 lt.",
			"lat.42°(21’.3÷21'.9) Nlong.011°(08’.9÷09'.5) E",
			"lat.42°(20'.0÷20'.6) Nlong.010°(55’.6÷56'.2) E"
		],
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"settore": "SUD EST",
		"text": "Partenza da Punta di Torre Ciana (Argentario) alle ore 10:00 diretti a Punta Torricella (Isola del Giglio), con velocità di 4 nodi, sapendo che il consumo del motore è di 10 l/h, determinare:",
		"n": 38
	},
	{
		"id": "4.1.1 - 39",
		"questions": [
			"Quesito 1: distanza",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"responses": [
			"6.5÷7.1 M",
			"10:55÷11.05",
			"24.8÷27.1 lt.",
			"lat.42°(32’.8÷33'.4) Nlong.011°(07’.7÷08'.3) E",
			"lat.42°(26’.0÷26'.6) Nlong.011°(07'.7÷08'.3) E"
		],
		"n": 39,
		"settore": "SUD EST",
		"text": "Partenza da Talamone (faro) alle ore 09:00 con una rotta per Punta Nera (Nord Argentario).\nTenendo conto che la nostra velocità è pari a 3.4 nodi, sapendo che il consumo medio del motore è di 10 l/h, determinare:"
	},
	{
		"settore": "NORD OVEST ORIZZONTALE",
		"responses": [
			"8.7÷9.3 M",
			"09:27÷09:33",
			"18.85÷20.15 lt.",
			"lat.42°(43’.2÷43'.8) Nlong.010°(09’.2÷09'.8) E",
			"lat.42°(42’.5÷43'.1) Nlong.010°(21’.4÷22'.0) E"
		],
		"id": "4.1.1 - 4",
		"n": 4,
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"text": "Partenza da Punta di Fetovaia (Isola d’Elba) alle ore 08:00 diretti a Isola Corbelli con velocità di 6 nodi, sapendo che il consumo orario del motore è di 10 l/h, determinare:"
	},
	{
		"n": 40,
		"id": "4.1.1 - 40",
		"responses": [
			"9.3÷9.9 M",
			"11:56÷12:04",
			"25.2÷26.8 lt.",
			"lat. 42°(21’.3÷21'.9) Nlong.010°(55’.0÷55'.6) E",
			"lat.42°(26’.5÷27'.1) Nlong.011°(06’.0÷06'.6) E"
		],
		"text": "Partenza da Giglio Porto alle ore 10:00 diretti a Punta Lividonia con velocità 4.8 nodi, considerando che il consumo orario del motore è di 10 l/h, determinare:",
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza",
			"Quesito 5: coordinate punto di arrivo"
		],
		"settore": "SUD EST"
	},
	{
		"questions": [
			"Quesito 1: distanza",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"settore": "SUD EST",
		"text": "Partenza da Talamone (faro) alle ore 09:00 diretti a Punta Nera (Nord Argentario) che si vuole raggiungere alle ore 11:00, sapendo che il consumo medio del motore è di 10 l/h, determinare:",
		"id": "4.1.1 - 41",
		"n": 41,
		"responses": [
			"6.5÷7.1 M",
			"3.1÷3.7 n",
			"26 lt.",
			"lat.42°(32’.8÷33'.4) Nlong.011°(07'.7÷08'.3) E",
			"lat.42°(26’.0÷26'.6) Nlong.011°(07'.7÷08'.3) E"
		]
	},
	{
		"id": "4.1.1 - 42",
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"text": "Partenza da Punta di Torre Ciana (Argentario) alle ore 10:00 con una rotta per Punta Torricella (Isola del Giglio), tenendo conto che la velocità della nostra imbarcazione è di 4 nodi e la quantità di carburante necessaria alla navigazione sapendo che il consumo del nostro motore è di 20 l/h, determinare:",
		"settore": "SUD EST",
		"responses": [
			"9.7÷10.3 M",
			"12:25÷12.35",
			"63.0÷67.0 lt.",
			"lat.42°(21’.3÷21'.9) Nlong.011°(08’.9÷09'.5) E",
			"lat.42°(20'.0÷20'.6) Nlong.010°(55’.6÷56'.2) E"
		],
		"n": 42
	},
	{
		"settore": "SUD EST",
		"questions": [
			"Quesito 1: distanza",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"text": "Partenza da Giglio Porto alle ore 14:00 diretti a Punta Cala Grande, dove bisogna arrivare alle ore 16:00 e sapendo che il consumo medio del nostro motore è di 10 l/h, determinare:",
		"responses": [
			"8.2÷8.8 M",
			"4.1÷4.4 n",
			"26 lt.",
			"lat. 42°(21’.3÷21'.9) Nlong.010°(55’.0÷55'.6) E",
			"lat.42°(25’.5÷26'.1) Nlong.011°(04’.9÷05'.5) E"
		],
		"id": "4.1.1 - 43",
		"n": 43
	},
	{
		"settore": "SUD EST",
		"n": 44,
		"questions": [
			"Quesito 1: distanza",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"responses": [
			"6.2÷6.8 M",
			"09:57÷10:03",
			"12.4÷13.6 lt.",
			"lat. 42°(26’.5÷27'.1) Nlong.011°(06'.0÷06'.6) E",
			"lat.42°(32’.8÷33'.4) Nlong.011°(07'.7÷08'.3) E"
		],
		"text": "Partenza alle ore 09:00 da Punta Lividonia (Nord Argentario) diretti a Talamone (faro).\nTenendo conto che la nostra velocità è di 6.5 nodi, sapendo che il consumo medio del nostro motore è di 10 l/h, determinare:",
		"id": "4.1.1 - 44"
	},
	{
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza",
			"Quesito 5: coordinate punto di arrivo"
		],
		"id": "4.1.1 - 45",
		"n": 45,
		"text": "Partenza da Punta del Fenaio (Nord Isola del Giglio) alle ore 18:00 diretti verso l’Isola di Formica Piccola, che bisogna raggiungere  alle ore 19:00, sapendo che il consumo medio del nostro motore è di 10 l/h, determinare:",
		"responses": [
			"10.2÷10.8 M",
			"10.2÷10.8 n",
			"13 lt.",
			"lat.42°(23’.0÷23'.6) Nlong.010°(52’.5÷53'.1) E",
			"lat.42°(33’.4÷34'.0) Nlong.010°(53’.4÷54'.0) E"
		],
		"settore": "SUD EST"
	},
	{
		"n": 46,
		"settore": "SUD EST",
		"responses": [
			"10.4÷11.0 M",
			"08:58÷09:02",
			"12.6÷13.4 lt.",
			"lat.42°(32’.8÷33'.4) Nlong.011°(07’.7÷08'.3) E",
			"lat.42°(33’.4÷34'.0) Nlong.010°(53’.4÷54'.0) E"
		],
		"id": "4.1.1 - 46",
		"text": "Partenza da Talamone (faro) alle ore 08:00 con una rotta per raggiungere Formica Piccola.\nTenendo conto che la nostra velocità è pari a 10.7 nodi, sapendo che il consumo medio del nostro motore è di 10 l/h, determinare:",
		"questions": [
			"Quesito 1: distanza",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		]
	},
	{
		"n": 47,
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza",
			"Quesito 5: coordinate punto di arrivo"
		],
		"settore": "SUD EST",
		"text": "Partenza da Giglio Porto alle ore 08:00 con una rotta per\nraggiungere Capo d’Uomo (Argentario) da raggiungere alle ore 10:00, sapendo che il consumo medio del nostro motore è di 10 l/h, determinare:",
		"responses": [
			"7.7÷8.3 M",
			"3.8÷4.2 n",
			"26 lt.",
			"lat. 42°(21’.3÷21'.9) Nlong.010°(55’.0÷55'.6) E",
			"lat.42°(23’.2÷23'.8) Nlong.011°(05’.5÷06'.1) E"
		],
		"id": "4.1.1 - 47"
	},
	{
		"id": "4.1.1 - 48",
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"n": 48,
		"settore": "SUD EST",
		"text": "Partenza da Punta di Torre Ciana (Argentario) alle ore 10:00 con una rotta per Punta Torricella (Isola del Giglio) che si vuole raggiungere alle ore 12:30, sapendo che il consumo medio del nostro motore è di 20 l/h, determinare:",
		"responses": [
			"9.7÷10.3 M",
			"3.8÷4.2 n",
			"65 lt.",
			"lat.42°(21’.3÷21'.9) Nlong.011°(08’.9÷09'.5) E",
			"lat.42°(20'.0÷20'.6) Nlong.010°(55’.6÷56'.2) E"
		]
	},
	{
		"responses": [
			"9.9÷10.5 M",
			"4.9÷5.2 n",
			"39 lt.",
			"lat. 42°(26’.5÷27'.1) Nlong.011°(06'.0÷06'.6) E",
			"lat.42°(23’.0÷23'.6) Nlong.010°(53’.0÷53'.6) E"
		],
		"text": "Partenza da Punta Lividonia (Promontorio Argentario) alle ore 10:00 diretti a Punta del Morto (Isola del Giglio) che si vuole raggiungere dopo 2 ore di navigazione,considerando che il consumo orario del nostro motore è di 15 l/h, determinare:",
		"id": "4.1.1 - 49",
		"settore": "SUD EST",
		"n": 49,
		"questions": [
			"Quesito 1: distanza",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		]
	},
	{
		"id": "4.1.1 - 5",
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza",
			"Quesito 5: coordinate punto di arrivo"
		],
		"responses": [
			"5.4÷6.0 M",
			"5.4÷6.0 n",
			"13 lt.",
			"lat. 42°(44’.2÷44'.8) Nlong.010°(14’.0÷14'.6) E",
			"lat.42°(42’.5÷43'.1) Nlong.010°(21’.4÷22'.0) E"
		],
		"n": 5,
		"settore": "NORD OVEST ORIZZONTALE",
		"text": "Partenza da Marina di Campo (Sud Isola d’Elba) alle ore 08:00 con una rotta per Isola Corbelli dove bisogna arrivare alle ore 09:00 sapendo che il consumo orario del motore è di 10 l/h, determinare:"
	},
	{
		"n": 50,
		"settore": "SUD EST",
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"responses": [
			"9.9÷10.5 M",
			"11:56÷12.03",
			"37.8÷40.1 lt.",
			"lat. 42°(26’.5÷27'.1) Nlong.011°(06'.0÷06'.6) E",
			"lat.42°(23’.0÷23'.6) Nlong.010°(53’.0÷53'.6) E"
		],
		"text": "Partenza da Punta Lividonia (Promontorio Argentario) alle ore 10:00 diretti a Punta del Morto (Isola del Giglio) con velocità 5.1 nodi, considerando che il consumo orario del nostro motore è di 15 l/h, determinare:",
		"id": "4.1.1 - 50"
	},
	{
		"responses": [
			"2.9÷3.5 M",
			"5.8÷7.0 n",
			"6.5 lt.",
			"lat. 42° (48’.2÷48’.8) Nlong.010°(11’.5÷12'.1) E",
			"lat.42°(49'.4÷50'.0) Nlong.010°(15’.4÷16'.0) E"
		],
		"id": "4.1.1 - 6",
		"text": "Partenza alle ore 10:00 da Marciana Marina (fanale) diretti a Capo d'Enfola dove si vuole arrivare alle ore 10:30, sapendo che il motore ha un consumo orario di 10 l/h, determinare:",
		"n": 6,
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"settore": "NORD OVEST ORIZZONTALE"
	},
	{
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"n": 7,
		"responses": [
			"3.3÷3.9 M",
			"6.6÷7.8 n",
			"13 lt.",
			"lat.42°(43’.8÷44'.4) Nlong.010°(16’.9÷17'.5) E",
			"lat.42°(42’.5÷43'.1) Nlong.010°(21’.4÷22'.0) E"
		],
		"settore": "NORD OVEST ORIZZONTALE",
		"id": "4.1.1 - 7",
		"text": "Partenza da Capo di Fonza (Sud Elba) alle ore 10:00 con una rotta per raggiungere Isola Corbelli alle ore 10:30, considerando che il consumo orario del motore è di 20 l/h, determinare:"
	},
	{
		"questions": [
			"Quesito 1: distanza",
			"Quesito 2: ora di arrivo",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza ",
			"Quesito 5: coordinate punto di arrivo"
		],
		"settore": "NORD OVEST ORIZZONTALE",
		"text": "Partenza da Marina di Campo (Sud Isola d’Elba) alle ore 08:00 con\nuna rotta per Isola Corbelli.\nTenendo conto che la nostra velocità è pari a 5.7 nodi,  sapendo che il consumo medio del nostro motore è di 10 l/h, determinare:",
		"responses": [
			"5.4÷6.0 M",
			"08:57÷09:03",
			"12.3÷13.7 lt.",
			"lat. 42°(44’.2÷44'.8) Nlong.010°(14’.0÷14'.6) E",
			"lat.42°(42’.5÷43'.1) Nlong.010°(21’.4÷22'.0) E"
		],
		"n": 8,
		"id": "4.1.1 - 8"
	},
	{
		"questions": [
			"Quesito 1: distanza ",
			"Quesito 2: velocità",
			"Quesito 3: carburante da imbarcare ",
			"Quesito 4: coordinate punto di partenza",
			"Quesito 5: coordinate punto di arrivo"
		],
		"text": "Partenza da Capo di Poro (Sud Isola d’Elba) alle ore 11:00 con una rotta per raggiungere Punta di Fetovaia dopo 1 ora di navigazione, considerando che il consumo orario del motore è di 20 l/h, determinare:",
		"settore": "NORD OVEST ORIZZONTALE",
		"id": "4.1.1 - 9",
		"responses": [
			"3.2÷3.8 M",
			"3.2÷3.8 n",
			"26 lt.",
			"lat. 42°(43’.3÷43'.9) Nlong.010°(14'.0÷14'.6) E",
			"lat.42°(43’.2÷43'.8) Nlong.010°(09’.2÷09'.8) E"
		],
		"n": 9
	}
]


export const DATA_CARTEGGI_OLTRE_12_BASEE: any[] =[
	{
		"id": "5.1.1 - 1",
		"risposta": "Vp = 9  ÷ 11 kn",
		"argomento": "correnti",
		"carta": "5d",
		"settore": "A",
		"domanda": "L’imbarcazione “Daphne” si trova sul punto A di coordinate geografiche GPS: Lat. 42° 51’ N e Long. 010° 16’,9 E.Da questa posizione dirige sul punto B situato sul rilevamento vero Rilv = 127° del Monumento di Capo della Vita, distanza dal monumento 2,9 miglia nautiche.Posto che in zona è presente una corrente con direzione della corrente Dc = 180° e velocità della corrente Vc = 2 kn, indicare la velocità propria Vp da impostare per raggiungere il punto B in 30 minuti."
	},
	{
		"id": "5.1.1 - 2",
		"risposta": "Dc = 221° ÷ 227°",
		"settore": "A",
		"argomento": "correnti",
		"domanda": "L’imbarcazione “Fru Fru” è in navigazione a largo dell’Isola d’Elba e, alle ore 08h30m, determina la propria posizione osservando il Faro di P.ta Polveraia (Lam.L.(3)15s52m16M) per rilevamento vero Rilv = 112° ad una distanza di 1,8 miglia nautiche (punto A).Dal punto A l’unità procede con prora vera Pv = 350° e con una velocitàpropulsiva Vp = 8,5 kn.Alle ore 09h18m, al fine di verificare gli elementi del moto presenti in zona, l’unità determina la propria posizione individuata dalle seguenti coordinate geografiche GPS: Lat. 42° 53’,0 N - Long. 010° 00’,0 E (punto B).Posto che sono, pertanto, variati gli elementi perturbatori del moto, determinare la direzione della corrente Dc.",
		"carta": "5d"
	},
	{
		"id": "5.1.1 - 3",
		"settore": "A",
		"carta": "5d",
		"domanda": "L’imbarcazione “Europa” naviga da 1h15m a Nord dell’isola d’Elba e ilsolcometro a elica segna una velocità propulsiva Vp = 5 kn. Ci troviamosull’allineamento Scoglietto – Faro di Portoferraio a una distanza di 3 miglia nautiche da Scoglietto e decidiamo di dirigere per il porticciolo turistico di Salivoli. Dato che il punto nave stimato si trova a 2,5 miglia nautiche a Sud Ovest del punto nave calcolato, imputiamo questo spostamento alla presenza di una corrente. Calcolare il tempo necessario per raggiungere il porticciolo di Salivoli.",
		"risposta": "Tempo di navigazione 1h14m  ÷ 1h20m",
		"argomento": "correnti"
	},
	{
		"carta": "5d",
		"settore": "A",
		"domanda": "Alle ore 08h30m l’imbarcazione “Italia” sta navigando con prora vera Pv = 260° e velocità propulsiva Vp = 6,5 kn e si trova sul punto A di coordinate geografiche GPS:Lat. 42° 41’ N  - Long. 010° 28’,4 E.Alle ore 09h40m si trova sul punto B dal quale si osserva Punta Morcone per rilevamento vero Rilv = 035° e il Faro di Capo di Poro per rilevamento vero Rilv= 310°. Posto che in zona sono presenti elementi perturbatori del moto, determinare la direzione della corrente Dc.",
		"id": "5.1.1 - 4",
		"risposta": "Dc = 027° ÷ 033°",
		"argomento": "correnti"
	},
	{
		"domanda": "Alle ore 08h00m l’imbarcazione “Nausicaa” si  trova sul punto A di coordinategeografiche GPS:Lat. 42° 50’ N  - Long. 010° 00’ EDa tale posizione, l’unità si dirige a Punta Falcone, dove si deve arrivare alleore 11h30m.Alle ore 09h30m, al fine di verificare la presenza di elementi perturbatori delmoto, l’imbarcazione stabilisce la sua posizione (punto B), attraverso l’osservazione dei seguenti rilevamenti:Rilv = 130° del traliccio Monte Poppe (Ovest Portoferraio); Rilv = 203° del Faro di Punta Polveraia.Determinare la direzione corrente Dc.",
		"id": "5.1.1 - 5",
		"settore": "A",
		"argomento": "correnti",
		"carta": "5d",
		"risposta": "Dc = 288° ÷ 294°"
	},
	{
		"settore": "A",
		"carta": "5d",
		"id": "5.1.1 - 6",
		"argomento": "correnti",
		"risposta": "Ora di arrivo 11h05m  ÷ 11h11m",
		"domanda": "Alle ore 10h00m l’unità “Acrux” si trova nel punto A di coordinate geograficheGPS:Lat. 42° 39’ N - Long. 010° 12’,7 EDal punto A si intende raggiungere Punta di Fetovaia con una velocità propria Vp = 4 kn.Considerato che in zona è presente una corrente di direzione Dc = 270° evelocità Vc = 2 nodi, determinare l’ora di arrivo."
	},
	{
		"carta": "5d",
		"id": "5.1.2 - 1",
		"settore": "A",
		"risposta": "Carburante 29 ÷ 31 litri",
		"argomento": "carburante",
		"domanda": "Partenza da Isola Cerboli (Est Isola d’Elba) alle ore 08 24m con velocitàpropulsiva Vp = 6 kn e rotta vera Rv = 180°.Successivamente si rileva Capo d’Ortano al traverso a una distanza di 4,9 miglianautiche.Dal punto nave A determinato si vuole proseguire per raggiungere il punto B dicoordinate geografiche GPS: Lat. 42°40’ N  Long. 010° 30’ E.Determinare la quantità di carburante necessaria, inclusa la riserva, dalla partenza e fino a destinazione sapendo che il motore ha un consumo di 12 l/h."
	},
	{
		"id": "5.1.2 - 2",
		"risposta": "Carburante 19 ÷ 21 litri",
		"carta": "5d",
		"settore": "A",
		"argomento": "carburante",
		"domanda": "Siamo in navigazione a Nord dell’Isola d’Elba con prora bussola Pb = 69°(variazione magnetica V = +1°) e una velocità propria Vp = 6 kn.Alle ore 11h30m rileviamo il faro di Scoglietto per rilevamento polare ρ = +045° e sempre lo stesso alle ore 11h50m per rilevamento polare ρ = +090°.Da tale punto nave costiero A si vuole raggiungere Punta Falcone.Sapendo che il consumo orario del nostro motore è di 10 l/h, determinare la quantità di carburante necessaria, inclusa la riserva, per il tratto di navigazione a partire dall’ultimo punto nave A delle 11h50m."
	},
	{
		"domanda": "Partenza dal punto A di coordinate geografiche GPS: Lat. 42°44’,2 N e Long. 010° 21’,2 E con prora vera Pv = 247° e velocità propulsiva Vp = 6 kn.Alle ore 12h00m ci troviamo nel punto dove rileviamo con rilevamento polareρ = +045°  il Faro di Capo di Poro e 15 minuti dopo rileviamo lo stesso faro al traverso ρ = +090° (punto B).Da tale punto nave B,  si dirige verso il punto C di coordinate geografiche GPS:Lat. 42° 40’ N e Long. 010° 00’ E.Determinare la quantità di carburante necessaria per tutta la navigazione, inclusa la riserva, considerando un consumo medio di 4 l/h.",
		"id": "5.1.2 - 3",
		"carta": "5d",
		"risposta": "Carburante 13 ÷ 15 litri",
		"settore": "A",
		"argomento": "carburante"
	},
	{
		"settore": "A",
		"carta": "5d",
		"domanda": "Partenza da Punta del Nasuto (Nord Ovest dell’Isola d’Elba) con una prora bussola Pb = 060° (variazione magnetica V = 4° W) e una velocità propria Vp = 6 kn.Si vuole determinare il punto nave A eseguendo con fanale Scoglietto due rilevamenti polari a dritta:1) ρ =+045° eseguito alle ore 10h00m2) ρ =+090° eseguito alle ore 10h28m.Determinare la quantità di carburante, compresa la riserva, necessaria dalla partenza per arrivare al porticciolo turistico di Salivoli (simbolo) sapendo che il nostro motore ha un consumo pari a 10l/h.",
		"argomento": "carburante",
		"risposta": "Carburante 33 ÷ 35 litri",
		"id": "5.1.2 - 4"
	},
	{
		"risposta": "Carburante 30 ÷ 32 litri",
		"domanda": "Partenza da Punta di Fetovaia (Sud Ovest isola d’Elba) con una prora vera Pv =105° e una velocità propulsiva Vp = 6 kn.Si vuole verificare l’assenza di fattori esterni perturbatori al fine di determinare il nostro punto nave,  eseguendo quindi due rilevamenti polari a sinistra con isola Corbelli:ρ =– 045° eseguito alle ore 10h00m ρ = – 090° eseguito alle ore 10h16mDal punto nave A così calcolato si vuole raggiungere Punta dei Ripalti. Determinare la quantità di carburante, compresa la riserva, per giungere a Punta dei Ripalti, sapendo di avere un motore che consuma 12 l/h.",
		"argomento": "carburante",
		"carta": "5d",
		"id": "5.1.2 - 5",
		"settore": "A"
	},
	{
		"settore": "A",
		"argomento": "navigazione costiera",
		"carta": "5d",
		"domanda": "In navigazione ad Ovest dell’Isola d’Elba con prora bussola Pb = 350°(declinazione d = 1°E, deviazione δ = 0°), con una velocità propulsiva Vp = 9 kn,rileviamo il faro di Punta Polveraia: alle ore 12h00m Rilb = 075°;alle ore 12h20m Rilb = 125°.Determinare il punto nave delle ore 12h20m.",
		"id": "5.1.3 - 1",
		"risposta": "Lat. 42°49’,7 N ÷ 42°50’,3 N    Long.010°02’,0 E ÷ 010° 02’,6E"
	},
	{
		"settore": "A",
		"argomento": "navigazione costiera",
		"id": "5.1.3 - 2",
		"carta": "5d",
		"risposta": "Lat. 42°52’,2 N ÷ 42°52’,8 N   Long010°20’,3 E ÷ 010° 20’,9E",
		"domanda": "Stiamo navigando a Nord dell’Isola d’Elba con una prora bussola Pb = 086° (declinazione d =2°W, deviazione δ = – 2°) con velocità propulsiva Vp = 5 kn. Alle ore 17h00m rileviamo il faro di Scoglietto per rilevamento bussola Rilb = 164° e dopo 18 minuti di navigazione per il rilevamento bussola Rilb = 194°. Determinare le coordinate del punto nave delle ore 17h18m."
	},
	{
		"argomento": "navigazione costiera",
		"domanda": "Stiamo navigando a Sud dell’isola d’Elba con prora bussola Pb = 104°(variazione magnetica V = 4°W) e velocità propulsiva Vp = 6 kn.Si osserva un faro sulla costa che emette un lampo di luce ogni 5 secondi e lo si rileva per i seguenti rilevamenti bussola:alle ore 01h50m  per Rilb = 046° alle ore 02h20m  per Rilb = 009° alle ore 03h05m  per Rilb = 321°Determinare le coordinate del punto nave alle ore 03h05m.",
		"carta": "5d",
		"id": "5.1.3 - 3",
		"risposta": "Lat.42°38’,7 N ÷ 42°39’,3 N    Long.010°19’,8 ÷ 010°20’,4 E",
		"settore": "A"
	},
	{
		"settore": "A",
		"id": "5.1.3 - 4",
		"domanda": "Stiamo navigando a Ovest dell’Isola d’Elba con prora vera Pv = 355° (variazione magnetica Vm = – 5°) e velocità propulsiva Vp = 6 kn.Alle ore 10h00m si rileva Punta Nera (Ovest Isola d’Elba) per rilevamento bussola Rilb = 045°, alle ore 10h30m si rileva la stessa punta per rilevamento bussola Rilb = 100°.Determinare le coordinate del punto nave alle ore . 10h30m.",
		"risposta": "Lat 42°45’,9 N ÷ 42°46’,5 N   Long010°02’,4 E ÷ 010°03’,0E",
		"carta": "5d",
		"argomento": "navigazione costiera"
	},
	{
		"domanda": "Partenza dal punto A di coordinate geografiche GPS Lat. 42°40’ N e Long. 010°00’ E con rotta vera Rv = 350° (variazione magnetica V = 2°W) e una velocità propulsiva Vp = 12,4 kn.Alle ore 13h45m  si esegue il rilevamento bussola Rilb = 052° del faro di PuntaPolveraia (Ovest Isola d’Elba);alle ore 14h00m si esegue il rilevamento bussola Rilb = 083° dello stesso faro. Determinare le coordinate del punto nave delle ore 14h00m.",
		"id": "5.1.3 - 5",
		"carta": "5d",
		"argomento": "navigazione costiera",
		"risposta": "Lat. 42°46’,6 N ÷ 42°47’,2 N    Long.009°59’,3 E ÷ 010°00’,0E",
		"settore": "A"
	},
	{
		"settore": "A",
		"id": "5.1.3 - 6",
		"argomento": "navigazione costiera",
		"carta": "5d",
		"risposta": "Lat. 42° 52’,5 N ÷ 42°53’,1 N   - Long.010° 05’,7 E ÷ 010°06’,3 E",
		"domanda": "Alle ore 09h 15m l’imbarcazione “Vega” si trova nella posizione individuata dalle seguenti coordinate geografiche GPS: Lat. 42° 45’,0 N - Long. 010° 01’,7 E (punto A) e sta navigando con una velocità effettiva Ve = 6 kn.Alla medesima ora riceve una richiesta di assistenza dall’imbarcazione “Serenity” per avaria.Tale imbarcazione, che sta navigando con rotta vera Rv = 240° con velocità effettiva Ve = 4 kn, comunica la propria posizione, individuata dalle seguenti coordinate geografiche Lat. 42° 55’ N - Long. 010° 12’ E (punto B).L’imbarcazione “Vega” decide , quindi, di raggiungere l’unità in difficoltà perprestare assistenza senza variare la velocità.Determinare le coordinate geografiche del punto D di intercettazione."
	},
	{
		"id": "5.1.4 - 1",
		"carta": "5d",
		"settore": "A",
		"domanda": "Partenza alle ore 10h00m da Capo Sant’Andrea con una rotta per raggiungerealle ore 12h30m Capo della Vita.Nella zona è presente un vento di Maestrale che crea uno scarroccio di 10°.Determinare l’Ora del traverso con fanale Scoglietto.",
		"argomento": "scarroccio",
		"risposta": "Ora traverso 11h33m÷11h39m"
	},
	{
		"argomento": "scarroccio",
		"settore": "A",
		"risposta": "Ora traverso 10h27m ÷ 10h33m",
		"id": "5.1.4 - 2",
		"carta": "5d",
		"domanda": "Dal punto nave A delle seguenti coordinate geografiche GPS: Lat. 42° 53’,4 N e Long. 010° 06’,6 E  delle ore 07h20m, si vuole seguire una rotta vera Rv = 090° in presenza di un vento di Grecale che provoca uno scarroccio Sc +10° e una velocità effettiva Ve = 6 kn.Determinare l’ora in cui rileviamo Torre Isola Cerboli al nostro traverso."
	},
	{
		"settore": "A",
		"argomento": "scarroccio",
		"carta": "5d",
		"risposta": "Lat. 42° 39’,7 N  ÷42°41’,3 N   Long. 010°25',2 E  ÷  010°25’,8 E",
		"domanda": "Dal punto nave A delle seguenti coordinate geografiche GPS: Lat 42° 41',5 N Long 010° 11',3 E si procede con prora vera Pv = 085°, considerando che in zona insiste un vento di Tramontana che crea uno scarroccio di 8 °.Determinare le coordinate del punto B nel quale si rileva Punta dei Ripalti(zona Sud Est Isola d’Elba) al traverso.",
		"id": "5.1.4 - 3"
	},
	{
		"id": "5.1.4 - 4",
		"domanda": "Siamo a Sud dell’Isola d’Elba e ci troviamo sul punto nave A delle seguenti coordinate geografiche GPS: Lat. 42° 39’,6 N  Long. 010° 17’,4 E. Navighiamo con prora vera Pv = 290° e siamo in presenza di un vento di Libeccio che provoca uno scarroccio di 5°.Determinare le coordinate del punto  B posto al traverso di Punta le Tombe.",
		"settore": "A",
		"risposta": "Lat. 42° 42’,6 N ÷42°43’,2 N    Long. 010°07’,5 E  ÷ 010°08’,1 E",
		"argomento": "scarroccio",
		"carta": "5d"
	},
	{
		"carta": "5d",
		"domanda": "Partenza alle ore 10h00m da Isola Cerboli con una prora vera Pv = 190° e una velocità effettiva Ve = 6 kn.Nella zona è presente un vento di Maestrale che crea uno scarroccio di 10°.Determinare l’ora del traverso con Punta dei Ripalti.",
		"settore": "A",
		"risposta": "Ora traverso 11h37m  ÷ 11h43m",
		"argomento": "scarroccio",
		"id": "5.1.4 - 5"
	},
	{
		"settore": "B",
		"id": "5.2.1 - 1",
		"risposta": "Lat. 42°39’,1 N ÷ 42°39’,7 N Long.010°44’,2 E ÷ 010°44’,8 E",
		"domanda": "L’imbarcazione “Ester” alle ore 09h00m parte da Scoglio dello Sparviero con prora bussola Pb =163° (variazione magnetica – 3°) e una velocità propria Vp = 6 kn.Considerato che in zona è presente una corrente di direzione Dc = Nord Ovest e velocità Vc = 2 kn, determinare le coordinate del punto nave B alle ore 11h 00m.",
		"argomento": "correnti",
		"carta": "5d"
	},
	{
		"carta": "5d",
		"risposta": "Ora arrivo 11h19m  ÷ 11h24m",
		"argomento": "correnti",
		"settore": "B",
		"id": "5.2.1 - 2",
		"domanda": "L’unità “Kochab” alle ore 10:00 si trova sul punto A di coordinate geograficheGPS:Lat. 42°40’ N – Long 010°40’ Ediretta verso il porto turistico di Castiglione della Pescaia (fanali) con una velocità propria Vp = 6 kn.Considerato che in zona è presente una corrente di direzione Dc = 059° evelocità Vc =2 kn, determinare l’ora di arrivo."
	},
	{
		"argomento": "correnti",
		"carta": "5d",
		"domanda": "Il M/Y “Nubian” si trova sul punto A di coordinate geografiche GPS:Lat. 42°44’,9 N - Long. 010°40’Ediretta verso la torretta Carbonifera di Follonica, si pianifica la navigazione per arrivare a destinazione in 2 ore.Considerando che in zona è presente una corrente di direzione Dc = 220° e velocità Vc = 1,5 kn, indicare la prora bussola Pb da tenere. (declinazione 2°E, deviazione. 3°W)",
		"settore": "B",
		"risposta": "Pb = 008° ÷ 014°",
		"id": "5.2.1 - 3"
	},
	{
		"settore": "B",
		"argomento": "correnti",
		"domanda": "L’imbarcazione “Cassiopea” alle ore 11h00 si trova nel punto A situato 4 miglianautiche a Sud Ovest del serbatoio di Marina di Grosseto.Considerato che in zona è presente una corrente di direzione Dc = Est e velocità Vc = 2 kn; abbiamo pianificato di seguire una rotta vera Rv = 302° e di impostare una velocità propulsiva Vp = 8 kn.Calcolare l’ora in cui rileveremo al traverso lo Scoglio dello Sparviero.",
		"risposta": "Ora del traverso 12h50m ÷ 12h57m",
		"id": "5.2.1 - 4",
		"carta": "5d"
	},
	{
		"id": "5.2.1 - 5",
		"argomento": "correnti",
		"carta": "5d",
		"risposta": "Dc = 359° ÷ 005°",
		"settore": "B",
		"domanda": "L’imbarcazione “Venus” si trova alle ore 10h00 nel punto A di coordinategeografiche GPS:Lat. 42°50’ N - Long 010°38’,5 Eprocede con una prora vera Pv = 141° e una velocità propulsiva Vp = 6 kn.A seguito di un controllo della posizione, alle ore 11h30m si determina il punto nave B eseguendo simultaneamente due rilevamenti veri:fanali di Castiglione della Pescaia Rlv = 080° Punta Ala Rlv = 330°.Determinare il valore della direzione della corrente Dc."
	},
	{
		"domanda": "Partenza da Castiglione della Pescaia con una prora bussola Pb = 271° (variazione magnetica V = 1° W) e una velocità propulsiva Vp = 6 nodi.Si vuole calcolare il punto nave A eseguendo due rilevamenti polari a dritta con Scoglio dello Sparviero:ρ = +045° eseguito alle ore 09h00m ρ =+090° eseguito alle ore 09h22mGiunti al traverso di Scoglio dello Sparviero decidiamo di dirigere verso il puntoB di coordinate geografiche GPS Lat. 42°50’N e Long. 010°37’E.Determinare la quantità di carburante necessario alla navigazione, compresa la riserva,  sapendo che il nostro motore consuma 10 l/h.",
		"settore": "B",
		"carta": "5d",
		"argomento": "carburante",
		"id": "5.2.2 - 1",
		"risposta": "Carburante 28 ÷ 30 litri"
	},
	{
		"carta": "5d",
		"domanda": "Siamo in uscita dal porto di Castiglione della Pescaia e navighiamo con rotta vera Rv = 270° e una velocità propulsiva Vp = 4,4 kn.Alle ore 13h00m rileviamo con rilevamento polare a dritta ρ = +045° la Torre Scoglio dello Sparviero e alle ore 13h30m rileviamo lo stesso punto al traverso. Da tale punto nave, dirigiamo per il punto di coordinate geografiche GPS: Lat. 42°40’N e Long. 010°50’E.Determinare la quantità di carburante necessaria, dalla partenza alla destinazione, considerando un consumo medio di 3,5 l/h e una riserva del 30%.",
		"id": "5.2.2 - 2",
		"risposta": "Carburante 14,5 ÷ 16,5 litri",
		"settore": "B",
		"argomento": "carburante"
	},
	{
		"settore": "B",
		"domanda": "Partenza dal fanale torretta Carbonifera (Golfo di Follonica) con una rotta vera Rv = 180° e una velocità propulsiva Vp = 6 kn.Durante la navigazione si vuole controllare il punto nave A eseguendo con faro di Punta Ala due rilevamenti polari a sinistra:ρ = -045° eseguito alle ore 08h00m ρ =-090° eseguito alle ore 08h22mGiunti al traverso, si decide di raggiungere il punto B di coordinate geograficheGPS: Lat. 42°40’N e Long. 010°40’E.Determinare il carburante necessario alla navigazione comprensivo della riserva sapendo che il motore ha un consumo orario di 15l/h.",
		"id": "5.2.2 - 3",
		"risposta": "Carburante 54 ÷ 57 litri",
		"argomento": "carburante",
		"carta": "5d"
	},
	{
		"id": "5.2.2 - 4",
		"risposta": "Carburante 35 ÷ 37 litri",
		"carta": "5d",
		"argomento": "carburante",
		"settore": "B",
		"domanda": "Partenza dal punto A di coordinate geografiche GPS: Lat.42°40’N e  Long 010°55’E per raggiungere il punto B di coordinate geografiche GPS: Lat.42°50’ N  Long.010°37’E con una velocità propulsiva Vp = 6 kn.Si vuole verificare l’assenza di fattori esterni eseguendo due rilevamenti polaria dritta di Scoglio dello Sparviero:ρ = +045° eseguito alle ore 10h00m ρ = +090° eseguito alle ore 10h08mDeterminare la quantità di carburante necessaria, inclusa la riserva, persvolgere l’intera navigazione sapendo che il motore ha un consumo orario di10 l/h."
	},
	{
		"domanda": "Partenza dal punto A di coordinate geografiche GPS: Lat.42°38’,8 N Long.010°58’,5 E con una prora bussola Pb = 305° (variazione magnetica V = 3° W).Si determina il punto nave B eseguendo con Scoglio dello Sparviero due rilevamenti polari a dritta:ρ = +045° eseguito alle ore 11h00m ρ = +090° eseguito alle ore 11h17mDeterminare la quantità di carburante necessaria, compresa la riserva, per giungere al traverso di Scoglio dello Sparviero tenendo conto di un consumo del motore pari a 5 l/h.",
		"argomento": "carburante",
		"risposta": "Carburante 17 ÷ 19 litri",
		"id": "5.2.2 - 5",
		"settore": "B",
		"carta": "5d"
	},
	{
		"carta": "5d",
		"settore": "B",
		"argomento": "navigazione costiera",
		"id": "5.2.3 - 1",
		"domanda": "Stiamo navigando a Sud di Castiglione della Pescaia con una prora bussola Pb = 281° (declinazione d = 3°E, deviazione δ= -4°) e velocità propulsiva Vp = 12 kn. Vogliamo verificare il punto nave costiero eseguendo alle ore 11h00m il rilevamento bussola Rilb = 046° dei fanali di Castiglione della Pescaia e dopo 30 minuti un rilevamento bussola Rilb = 351° dello Scoglio Sparviero.Determinare le coordinate del punto nave delle ore 11h30m.",
		"risposta": "Lat. 42°45’,4 N ÷ 42°46’,0 N    Long.010°43’,0 E ÷ 010°43’,6E"
	},
	{
		"argomento": "navigazione costiera",
		"risposta": "Lat. 42°46’,4 N ÷ 42°47’,0 N   Long.010°37’,9 E ÷ 010° 38’,5E",
		"settore": "B",
		"domanda": "Stiamo navigando a Sud di Punta Ala con partenza dal punto A di coordinate geografiche GPS: Lat. 42°40’ N e Long. 010°50’ E con una prora vera Pv = 315° e velocità propulsiva Vp = 6 kn (declinazione d= 2°W, deviazione δ = -2°).Si rileva il faro di Punta Ala alle ore 10h00m per rilevamento bussola Rilb = 034° e dopo mezz’ora di navigazione sempre lo stesso faro per rilevamento bussola Rilb = 074°.Determinare le coordinate del punto nave delle ore 10h30m.",
		"id": "5.2.3 - 2",
		"carta": "5d"
	},
	{
		"settore": "B",
		"risposta": "Lat. 42°46’,5 N ÷ 42°47’,1N   Long.010°41’,9 E ÷ 010°42’,5E",
		"carta": "5d",
		"id": "5.2.3 - 3",
		"argomento": "navigazione costiera",
		"domanda": "Partenza da Castiglione della Pescaia con una prora bussola Pb = 271° (variazione magnetica V = 1° W) e velocità propulsiva Vp = 6 kn.Alle ore 15h00m si esegue un rilevamento bussola Rilb = 351° con Passo Peroni e alle ore 15h34m  con Scoglio Sparviero un rilevamento bussola Rilb = 021°. Determinare le coordinate del punto nave delle ore 15h34m."
	},
	{
		"settore": "B",
		"domanda": "Il giorno 5 Gennaio 2021 siamo in navigazione nel golfo di Follonica  con prorabussola Pb = 197° (deviazione δ = 1° E) e velocità propulsiva Vp = 9 kn.Il valore della declinazione magnetica anno 2016:d = 1° 30' E variazione annua 6’ E.Alle ore 09h00m si rileva Punta Martina per rilevamento bussola Rilb = 097°; alle ore 09h10m  si rileva il faro di Punta Ala per rilevamento bussola Rilb = 147°.Determinare le coordinate del punto nave delle ore  09h10m.",
		"argomento": "navigazione costiera",
		"risposta": "Lat. 42°50’,2 N ÷ 42°50’,8 N   Long.010°41’,9 E ÷ 010°42’,5E",
		"carta": "5d",
		"id": "5.2.3 - 4"
	},
	{
		"domanda": "Partenza da Scoglio dello Sparviero con una rotta vera Rv = 130° e una velocità propria Vp = 6 kn.Si vuole determinare il punto nave A eseguendo alle ore 10h00m con i fanali di Castiglione della Pescaia un rilevamento vero Rilv = 041° e alle ore 10h20m con Serbatoio Marina di Grosseto un rilevamento vero Rilv =  080°.Determinare le coordinate del punto nave delle ore 10h20m.",
		"risposta": "Lat. 42°41’,8 N ÷ 42°42’,4  N    Long.010°51’,8 E ÷ 010°52’,4E",
		"carta": "5d",
		"settore": "B",
		"id": "5.2.3 - 5",
		"argomento": "navigazione costiera"
	},
	{
		"settore": "B",
		"id": "5.2.4 - 1",
		"argomento": "scarroccio",
		"risposta": "Lat. 42°48’,4 N  ÷ 42°48’,8 N    Long.010°39’,2 E ÷  010°39’,6E",
		"domanda": "Stiamo navigando nel golfo di Follonica con una rotta vera Rv = 025°. Consapevoli che in zona è presente un vento di Maestrale che crea uno scarroccio di 5°, alle ore 10h00m ci troviamo nel punto A da dove rileviamoPunta Francese con rilevamento polare ρ = 035° e contemporaneamente il faro di Punta Ala con rilevamento polare ρ = 077°.Determinare il valore del punto nave costiero B delle ore 10h00m.",
		"carta": "5d"
	},
	{
		"settore": "B",
		"argomento": "scarroccio",
		"risposta": "Lat. 42°39’,7 N ÷ 42°40,1’ N     Long.010°51’,6 E ÷ 010°52,0’E",
		"carta": "5d",
		"id": "5.2.4 - 2",
		"domanda": "Partenza da Scoglio dello Sparviero con una rotta vera Rv = 140°. considerando che in zona insiste un vento di Libeccio che provoca uno scarroccio di 10°, determinare le coordinate geografiche del punto A che si trova al traverso del Serbatoio di Marina di Grosseto."
	},
	{
		"argomento": "scarroccio",
		"settore": "B",
		"id": "5.2.4 - 3",
		"domanda": "Partenza dal punto nave A delle seguenti coordinate geografiche GPS: Lat.42°40’ N e Long.010°50’ E con una rotta vera Rv = 320°. considerando che in zona è presente un vento di Grecale che crea unoscarroccio di 10°, determinare le coordinate del punto B al traverso dei fanali di Castiglione della Pescaia.",
		"risposta": "Lat. 42°42’,8 N  ÷ 42°43’,2 N    Long.010°46’,3 E ÷ 010°46’,7E",
		"carta": "5d"
	},
	{
		"argomento": "scarroccio",
		"settore": "B",
		"risposta": "Lat. 42°43’,2 N ÷ 42°43’,6 N     Long.010°42’,6 E ÷ 010°43’,0E",
		"carta": "5d",
		"domanda": "Alle ore 10h00m ci troviamo sul punto A che si trova sul rilevamento vero Rilv = 320° del fanale rosso del porto di Castiglione della Pescaia alla distanza di 1 miglio nautico. Stiamo navigando con prora vera Pv =  253° e velocità propulsiva Vp = 5 kn.In zona è presente un vento di Libeccio che provoca uno scarroccio di 7° e una variazione di velocità di - 1 nodo.Determinare la posizione del punto nave B alle ore 12h00m tenendo contodell’effetto del vento.",
		"id": "5.2.4 - 4"
	},
	{
		"settore": "B",
		"risposta": "Pv = 302°÷ 304°",
		"carta": "5d",
		"domanda": "Siamo in navigazione nella zona di Castiglione della Pescaia e alle ore 12h10m ci troviamo nel punto A di coordinate geografiche GPS Lat. 42° 41’,7 N e Long. 010° 51’,5 E.Da tale posizione si decide di raggiungere il punto B posto 1 miglio nautico a Sud della Torre di Scoglio dello Sparviero, navigando con velocità propulsiva Vp= 7 nodi. Sapendo che in zona insiste un vento di Ponente che provoca uno scarroccio di 5° e una variazione di velocità di - 1 nodo, determinare il valore della prora vera da mantenere.",
		"id": "5.2.4 - 5",
		"argomento": "scarroccio"
	},
	{
		"domanda": "Il M/Y “Nubian”, in navigazione a sud dell’isola di Pianosa, alle ore 12h42m si trova sul punto A dal quale osserva il Fumaiolo di Punta Brigantina per rilevamento vero Rilv = 022° e la Torre di Cala della Ruta per rilevamento vero Rilv = 323°.Da questa posizione il M/Y procede per rotta vera Rv = 202° e velocitàpropulsiva Vp = 16 kn. Alle ore 13h12m l’apparato GPS fornisce le seguenti coordinate geografiche del punto B: Lat. 42° 24’,1 N - Long. 010° 00’,0 E. Posto che in zona sono presenti elementi perturbatori del moto, determinare la velocità della corrente Vc.",
		"argomento": "correnti",
		"carta": "5d",
		"settore": "C",
		"id": "5.3.1 - 1",
		"risposta": "Vc = 2,9 ÷ 3,5 kn"
	},
	{
		"settore": "C",
		"id": "5.3.1 - 10",
		"domanda": "L’imbarcazione “Sanvito”, alle ore 13h00m, si trova sul punto nave A da cui osserva il Faro dell’Isola di Pianosa (Lam.(2)10s42m18M) per rilevamento vero Rilv = 246° distanza 3,2 miglia nautiche, da tale posizione si dirige con velocità propulsiva Vp = 4,8 kn verso il punto B avente coordinate geografiche GPS: Lat. 42° 23’,3 N - Long. 010° 05’,9 E.Considerato che in zona è presente una corrente di direzione Dc = 280° e velocità Vc = 1,5 kn, determinare il valore della prora vera Pv.",
		"risposta": "Pv = 144° ÷ 150°",
		"carta": "5d",
		"argomento": "correnti"
	},
	{
		"settore": "C",
		"id": "5.3.1 - 2",
		"carta": "5d",
		"domanda": "Nel 2008, l’imbarcazione “Cassiopea”, naviga con rotta vera Rv = 307°. Alle ore 16h10m, osserva il Faro dello Scoglio Africa (Lam.5s19m12M) per rilevamento vero Rilv = SW ad una distanza di 3 miglia nautiche (punto A).Dal punto A decide di accostare a dritta per dirigere in direzione del Fumaiolo di Punta Brigantina (Isola Pianosa), impostando una velocità propria Vp = 6 kn. A seguito di un controllo della posizione, alle ore 17h00m si determina il punto nave B situato sul rilevamento vero Rilv = 339° del Fumaiolo di P.ta Brigantina ad una distanza di 4,5 miglia.Posto, quindi, che dal punto A sono cambiati gli elementi perturbatori del moto, determinare la direzione della corrente Dc.",
		"risposta": "Dc = 039° ÷ 045°",
		"argomento": "correnti"
	},
	{
		"carta": "5d",
		"id": "5.3.1 - 3",
		"argomento": "correnti",
		"domanda": "L’imbarcazione “Sa Manna” alle ore 23h40m si trova in navigazione sul punto A (rilevamento vero Rilv = 310° del Faro Scoglio Africa (Lam.5s19m12M) - distanza 3,3 miglia nautiche) e procede con           prora vera Pv = 356° e con velocità propulsiva Vp = 7 kn.Alle ore 01h22m, al fine di verificare gli elementi del moto, l’imbarcazionedetermina al GPS la sua posizione geografica (punto B): Lat. 42° 32’,9 N - Long. 010° 06’,0 EPosto che sono, pertanto, variati gli elementi perturbatori del moto, determinare la direzione della corrente Dc.",
		"settore": "C",
		"risposta": "Dc = 353° ÷ 359°"
	},
	{
		"domanda": "Nel 2008 l’imbarcazione “Merak” è in navigazione nei pressi dell’Isola diMontecristo.Alle ore 00h20m l’unità determina la propria posizione osservando il Faro di Scoglio Africa (Lam.5s19m12M) per rilevamento vero Rilv = 238° ad una distanza di 3 miglia nautiche (punto A).Dal punto A, l’imbarcazione procede con prora vera Pv = 035° e con unavelocità propulsiva Vp = 3,5 kn.Alle ore 02h30m, al fine di verificare gli elementi del moto presenti in zona,l’unità determina la posizione individuata dalle seguenti coordinate geografiche GPS: Lat. 42à31’,5 N – Long. 010°15’,3 E (punto B)Posto che sono, pertanto, variati gli elementi perturbatori del moto, determinare la velocità della corrente Vc.",
		"risposta": "Vc = 1 ÷ 1,6 kn",
		"carta": "5d",
		"id": "5.3.1 - 4",
		"argomento": "correnti",
		"settore": "C"
	},
	{
		"risposta": "Vp = 10,3 ÷ 10,9 kn",
		"carta": "5d",
		"settore": "C",
		"id": "5.3.1 - 5",
		"domanda": "L’imbarcazione “Sanvito” si trova sul punto A di coordinate geografiche GPS: Lat. 42° 30’ N - Long. 010° 18’,5 E.Si naviga per raggiungere in 30 minuti il punto B di coordinate Lat. 42° 34’,2 N e Long. 010° 25’ E.Considerando che in zona è presente una corrente con direzione corrente Dc Nord e velocità Vc 4 kn, calcolare la velocità propulsiva Vp necessaria per detta navigazione.",
		"argomento": "correnti"
	},
	{
		"carta": "5d",
		"id": "5.3.1 - 6",
		"settore": "C",
		"domanda": "L’imbarcazione “Nuraghe” si trova sul punto A di coordinate geografiche GPS: Lat. 42°33’,4 N - Long. 010° 12’,8 E.Si naviga con rotta vera Rv = 253° e velocità effettiva Ve = 5 kn. Considerando che in zona insiste una corrente con direzione corrente Dc = 190° e velocità Vc = 1 kn, calcolare la velocità propulsiva Vp.",
		"risposta": "Vp = 4,3 ÷ 4,9 kn",
		"argomento": "correnti"
	},
	{
		"id": "5.3.1 - 7",
		"carta": "5d",
		"settore": "C",
		"argomento": "correnti",
		"domanda": "Il M/Y “Tempest” si trova alle ore 10h00m sul punto A di coordinategeografiche GPS:Lat. 42°25’ N - Long. 010° 14’,6 E.Siamo in navigazione con prora vera Pv = 055° e  veolocità propulsiva Vp = 6 kn.Considerando che in zona è presente una corrente direzione corrente Dc = Nord e velocità Vc = 1.5 kn, determinare la rotta vera Rv.",
		"risposta": "Rv = 043° ÷ 049°"
	},
	{
		"id": "5.3.1 - 8",
		"argomento": "correnti",
		"domanda": "L’unità “Mars” alle ore 09h00m si trova sul punto A di coordinate geograficheGPS:Lat.42°30’ N e Long.010°20’E.Si naviga con prova vera Pv = 263° e una velocità propulsiva Vp = 6 kn.Alle ore 10h40m si determina il punto nave B eseguendo simultaneamente due rilevamenti veri:faro di Pianosa Rlv = 345° faro Scoglio Africa Rlv =210°.Determinare il valore della velocità corrente Vc.",
		"risposta": "Vc = 0,9 ÷1,5 kn",
		"settore": "C",
		"carta": "5d"
	},
	{
		"carta": "5d",
		"id": "5.3.1 - 9",
		"settore": "C",
		"risposta": "Ve = 9,5 ÷ 10,1 kn",
		"domanda": "L’imbarcazione “Sa Manna” parte da Scoglio Africa e naviga con prora vera Pv= 014° e velocità propulsiva Vp = 11 kn.Considerando che in zona è presente una corrente direzione corrente Dc = 140° e velocità Vc = 2 kn, determinare il valore della velocità effettiva Ve.",
		"argomento": "correnti"
	},
	{
		"carta": "5d",
		"settore": "C",
		"domanda": "Alle ore 05h30m, l’imbarcazione “Nausicaa” si trova sul punto A situato a 0,9miglia a E del Faro dello Scoglio Africa (Lam.5s19m12M). Da tale posizionel’unità si dirige, impostando una velocità propulsiva Vp = 5,5 kn verso il punto nave B, da cui si osserva il “punto trigonometrico” di Monte della Fortezza (Isola di Montecristo) per rilevamento vero Rilv = 180° e distanza di 2,8 miglia nautiche. Posto che in zona non sono presenti elementi perturbatori del moto e che il propulsore dell’unità “Nausicaa” ha un consumo orario di 38 l/h, determinare la quantità di carburante necessaria per la traversata, comprensiva di riserva.",
		"argomento": "carburante",
		"id": "5.3.2 - 1",
		"risposta": "Carburante 90 ÷ 92 litri"
	},
	{
		"settore": "C",
		"risposta": "Carburante 58 ÷ 60 litri",
		"domanda": "Partenza dal punto A di coordinate geografiche GPS: Lat.42°30’ N  Long.010°30’E con una prora vera Pv = 253° e una velocità propulsiva Vp = 6 kn.Durante la navigazione si effettuano due rilevamenti polari a sinistra di Scoglio Africa:ρ = -045° eseguito alle ore 10h00m ρ = -090° eseguito alle ore 10h27mDa tale punto nave B si decide di raggiungere il punto C di coordinategeografiche GPS: Lat. 42°30’N e Long. 010°00’E.Determinare il carburante necessario alla navigazione, comprensivo della riserva, sapendo che il motore consuma 10 l/h.",
		"carta": "5d",
		"argomento": "carburante",
		"id": "5.3.2 - 2"
	},
	{
		"settore": "C",
		"domanda": "Partenza dal punto A di coordinate geografiche GPS: Lat.42°30’ N e Long.010°30’ E con una prora vera Pv = 275°.Al fine di determinare la velocità di navigazione effettiva, si rileva Punta Brigantina con due rilevamenti polari a dritta:ρ = +045° eseguito alle ore 10h00m ρ = +090° eseguito alle ore 10h25mDa tale punto nave B delle 10:25, si decide di raggiungere Scoglio Africa (faro). Determinare il carburante necessario, inclusa la riserva, per giungere a destinazione sapendo che il motore consuma 10 l/h.",
		"risposta": "Carburante 60 ÷ 62 litri",
		"carta": "5d",
		"argomento": "carburante",
		"id": "5.3.2 - 3"
	},
	{
		"argomento": "carburante",
		"domanda": "Partenza dal punto A di coordinate geografiche GPS: Lat.42°20’ N e Long.010°09’,2 E con una prora vera Pv  = 360°.Al fine di determinare la velocità di navigazione effettiva, si determina il punto nave B eseguendo con faro Isola Pianosa due rilevamenti polari a sinistra:ρ = -045° eseguito alle ore 22h00m ρ = -090° eseguito alle ore 22h15mDa tale punto B, si vuole raggiungere il punto C di coordinate geografiche GPS:Lat. 42°30’N e Long. 010°20’E.Determinare il carburante necessario, compresa la riserva, per l’interatraversata sapendo che il motore ha un consumo di 6 l/h.",
		"id": "5.3.2 - 4",
		"settore": "C",
		"risposta": "Carburante 18 ÷ 20 litri",
		"carta": "5d"
	},
	{
		"settore": "C",
		"carta": "5d",
		"argomento": "carburante",
		"domanda": "Partenza dal punto A di coordinate geografiche GPS: Lat.42°30’ N e Long.010°00’ E con una prora vera Pv = 062°.Al fine di determinare la velocità di navigazione effettiva, si determina il punto nave B eseguendo con faro di isola Pianosa due rilevamenti polari a sinistra:ρ = -045° eseguito alle ore 09h00m ρ = -090° eseguito alle ore 09h25mDa tale punto nave B delle ore 9:25 si decide di proseguire la navigazione diretti verso il punto C di coordinate geografiche GPS: Lat. 42°30’N e Long. 010°20’E.Determinare il carburante necessario per l’intera traversata, compresa lariserva, sapendo che il motore ha un consumo di 6 l/h.",
		"risposta": "Carburante 19,5 ÷ 21,5 litri",
		"id": "5.3.2 - 5"
	},
	{
		"carta": "5d",
		"risposta": "Carburante 54 ÷ 56 litri",
		"settore": "C",
		"argomento": "carburante",
		"domanda": "Partenza dal punto A situato a 2,5 miglia nautiche a Sud Ovest di Punta del Libeccio (Ovest isola Pianosa) diretti verso il punto B di coordinate geografiche GPS: Lat.42°20’ N e Long.010°30’ E.Al fine di determinare la velocità di navigazione effettiva, si determina il punto nave eseguendo con Punta del Diavolo (Nord isola Montecristo) due rilevamenti polari a dritta:ρ = +045° eseguito alle ore 09h00m ρ =+090° eseguito alle ore 09h38mDeterminare il carburante necessario per l’intera traversata, compresa lariserva, sapendo che il motore ha un consumo di 10 l/h.",
		"id": "5.3.2 - 6"
	},
	{
		"argomento": "carburante",
		"carta": "5d",
		"domanda": "Nel 2007, alle ore 09h30m, l’imbarcazione “Venus” parte dal punto A dicoordinate geografiche GPS: Lat. 42° 34’,4 N - Long. 009° 58’,5 E e procede con velocità propulsiva Vp = 6 kn verso il punto B determinato dai seguenti luoghi di posizione:distanza di 3,5 miglia sul rilevamento vero Rilv = 270° del Faro dello Scoglio Africa (Lam.5s19m12M).Considerando che in zona non sono presenti elementi perturbatori del moto e che il propulsore dell’unità ha un consumo orario di 15 l/h, determinare, compresa la riserva, il carburante necessario.",
		"id": "5.3.2 - 7",
		"risposta": "Carburante 48 ÷ 50 litri",
		"settore": "C"
	},
	{
		"domanda": "Stiamo navigando a Sud dell’Isola di Pianosa con prora bussola Pb = 265° (declinazione d = 3°W, deviazione δ = 0°) e velocità propria Vp = 5 kn.Vogliamo determinare il punto nave costiero A eseguendo alle ore 10h00m un rilevamento bussola di Punta Brigantina Rilb = 315° e dopo 36 minuti di navigazione di Torre Cala della Ruta un rilevamento bussola Rilb = 350°.Determinare le coordinate del punto nave delle ore 10h36m.",
		"carta": "5d",
		"settore": "C",
		"argomento": "navigazione costiera",
		"id": "5.3.3 - 1",
		"risposta": "Lat. 42°31’,4 N ÷ 42°32’,0 N   Long.010°03’,8 E ÷ 010°04’,4E"
	},
	{
		"risposta": "Pb = 176° ÷ 182°",
		"settore": "C",
		"domanda": "Nel dicembre 2008, alle ore 10h18m, l’imbarcazione “Mizar” si trova sul punto A di coordinate geografiche GPS: Lat. 42° 34’,5 N Long. 010° 08’,5 E.Non essendo presenti in zona elementi perturbatori del moto, si intende procedere per passare a 2,6 miglia nautiche al traverso a dritta del Faro dello Scoglio Africa (Lam.5s19m12M) ad una velocità propulsiva Vp = 6 kn (punto C). Il valore di declinazione nel 1994 è: 0° 20’ E  (variazione annua 7’ E).Determinare il valore della prora bussola Pb.  PER LA RISOLUZIONE DEL QUESITO E' NECESSARIO UTILIZZARE LA TABELLA DELLE DEVIAZIONI ALLEGATA",
		"argomento": "navigazione costiera",
		"carta": "5d",
		"id": "5.3.3 - 2"
	},
	{
		"settore": "C",
		"id": "5.3.3 - 3",
		"risposta": "Pb = 133° ÷ 139°",
		"carta": "5d",
		"domanda": "Nel novembre 2009, alle ore 16h00m, l’unità “Mars” si trova sul punto A di coordinate geografiche GPS: Lat. 42° 33’,0 N e Long. 009° 56’,0 E.Non essendo presenti in zona elementi perturbatori del moto, si intende passare a 3,1 miglia nautiche al traverso a dritta del Faro Scoglio Africa (Lam.5s19m12M) ad una velocità propulsiva Vp = 9 kn.Il valore di declinazione nel 1994 è: 0° 20’ E (variazione annua 7’ E). Determinare il valore della prora bussola Pb. PER LA RISOLUZIONE DEL QUESITO E' NECESSARIO UTILIZZARE LA TABELLA DELLE DEVIAZIONI ALLEGATA",
		"argomento": "navigazione costiera"
	},
	{
		"domanda": "Partenza dal punto A situato a 6 miglia nautiche da Scoglio Africa sul rilevamento vero Rilv = 171°. Da tale posizione dirigiamo sul punto  B di coordinate geografiche GPS: Lat. 42° 37’,6 N e Long. 010° 10’ E con velocità propulsiva Vp = 12 kn.Alle ore 10h00m  rileviamo il faro dell’Isola di Pianosa con un rilevamento polare a sinistra ρ = -045°, dopo 15 minuti di navigazione rileviamo lo stesso faro per un rilevamento polare a sinistra  ρ = -125°.Determinare il punto nave delle ore 10h15m .",
		"carta": "5d",
		"id": "5.3.3 - 4",
		"argomento": "navigazione costiera",
		"risposta": "Lat. 42°35’,1 N ÷ 42°35’,7 N    Long.010°08’,4 E ÷ 010°09’,0E",
		"settore": "C"
	},
	{
		"carta": "5d",
		"domanda": "Stiamo navigando a Sud dell’isola di Pianosa con rotta vera Rv = 090° e velocitàpropulsiva Vp = 6 kn.Si vuole determinare il punto nave A eseguendo alle ore 12h00m con Torre Cala della Ruta un rilevamento vero Rilv = 030° e alle ore 12h50m  un rilevamento vero Rilv = 330° con faro di Isola Pianosa.Determinare le coordinate del punto nave delle ore 12h50m.",
		"risposta": "Lat. 42°31,7’ N÷42°32’,3 N    Long.010°07’,9 E ÷ 010°08',5E",
		"settore": "C",
		"id": "5.3.3 - 5",
		"argomento": "navigazione costiera"
	},
	{
		"settore": "C",
		"carta": "5d",
		"risposta": "Lat. 42° 33’,0 N÷42°33',6 N   Long. 010°07’,3 E ÷ 010°07’,9 E",
		"domanda": "Stiamo navigando a Sud Sud Est dell’Isola di Pianosa con prora bussola Pb = 065° (declinazione d= 1° E, deviazione δ = +4°) e una velocità propulsiva Vp = 6 kn.Alle ore 10h00m  rileviamo con grafometro semicircolare la Torre di Cala della Ruta con rilevamento polare sin. ρ = -118° e alle ore 10h14m  l’Isola La Scola con rilevamento polare sin. ρ = - 098°.Determinare le coordinate del punto nave delle ore 10h14m .",
		"id": "5.3.3 - 6",
		"argomento": "navigazione costiera"
	},
	{
		"id": "5.3.3 - 7",
		"domanda": "Il giorno 8 Gennaio 2021 siamo in navigazione ad Est dell'Isola Pianosa conprora bussola Pb = 042° (deviazione δ = 1° E) e velocitè propulsiva Vp = 12 kn.Il valore della declinazione magnetica anno 2018:d = 2 °45' W variazione annua 5’ W.Alle ore 11h45m  si rileva Punta Brigantina per rilevamento bussola Rilb = 277°; alle ore 11h50m  si rileva Punta del Grottone per rilevamento bussola Rilb = 307°.Determinare le coordinate del Punto nave delle ore 11h50m.",
		"argomento": "navigazione costiera",
		"carta": "5d",
		"settore": "C",
		"risposta": "Lat. 42°34',4 N ÷42°35’,0 N      Long.010° 09',4 E ÷ 010°10’,0E"
	},
	{
		"risposta": "Lat. 42°23’,6 N ÷ 42°24’,0N   Long.010°17’,8 E ÷ 010°18’,2E",
		"argomento": "scarroccio",
		"carta": "5d",
		"settore": "C",
		"domanda": "Alle ore 09h00m in navigazione con una rotta vera Rv = 150°, si rileva il faro dell’Isola di Pianosa con rilevamento polare ρ = +138°, distanza 3,6 miglia nautiche.Sapendo che in zona spira un vento di Ponente che crea uno scarroccio di 8°, indicare le coordinate del punto nave B dove fermarsi a causa del divieto di pesca imposto dalla zona 2 dell’Isola di Montecristo.",
		"id": "5.3.4 - 1"
	},
	{
		"settore": "C",
		"carta": "5d",
		"argomento": "scarroccio",
		"risposta": "Lat. 42° 31’,9 N ÷42°32’,3 N   Long. 010°06’,7 E ÷ 010°07’,1 E",
		"domanda": "Stiamo navigando a Sud Sud Est dell’Isola di Pianosa con rotta vera Rv = 072°.Alle ore 10h00m rileviamo a sinistra, la Torre di Cala della Ruta conrilevamento polare ρ = -119° e contemporaneamente l’Isola La Scola con rilevamento polare ρ = - 077°.Determinare le coordinate del punto nave B delle ore 10h00m considerando che in zona è presente un vento di tramontana che crea uno scarroccio di 2°.",
		"id": "5.3.4 - 2"
	},
	{
		"risposta": "Lat. 42°35’,5 N ÷ 42°35’,9N Long.010°09’,0 E ÷ 010°09’,4 E",
		"settore": "C",
		"id": "5.3.4 - 3",
		"domanda": "Ci troviamo sul punto A in cui rileviamo il faro dello Scoglio d’Africa con rilevamento vero Rlv = 090° distanza 2,8 miglia nautiche; dal punto A dirigiamo con rotta vera Rv = 025° sul punto B in cui rileviamo a sinistra il faro dell’Isola di Pianosa con rilevamento polare ρ = -137°.Sapendo che in zona è presente un vento di Levante che provoca uno scarroccio di 8°, determinare le coordinate del punto nave B.",
		"carta": "5d",
		"argomento": "scarroccio"
	},
	{
		"carta": "5d",
		"settore": "C",
		"id": "5.3.4 - 4",
		"domanda": "Ci troviamo nel punto A situato a 1,5 miglia nautiche a Nord-Ovest del Faro di Scoglio Africa e vogliamo seguire una rotta vera Rv = 352° dirigendo versol’Isola d’Elba. In zona è presente un vento di Maestrale che provoca unoscarroccio di 8°.Determinare le coordinate geografiche del punto B al traverso del faro di isola Pianosa.",
		"argomento": "scarroccio",
		"risposta": "Lat. 42° 33’,7 N ÷42°34’,1N   Long. 010°00’,0 E ÷ 010°00’,4 E"
	},
	{
		"settore": "C",
		"domanda": "Alle ore 8h30m dal punto nave A situato a 2 miglia nautiche a Sud Ovest del Faro Scoglio Africa, considerando che in zona insiste un vento di Scirocco che provoca uno scarroccio di - 6° e una velocità effettiva Ve = 18 kn,  si intende procedere con rotta vera Rv = 020°.Determinare l’ora in cui ci troviamo nel punto B, dove rileviamo PuntaBrigantina (zona Sud isola Pianosa) al traverso.",
		"argomento": "scarroccio",
		"risposta": "Ora del rilevamento: 09h13m ÷ 09h19m",
		"carta": "5d",
		"id": "5.3.4 - 5"
	},
	{
		"settore": "D",
		"domanda": "Alle ore 09h30m l’imbarcazione “Nuraghe”, navigando a S del Promontorio dell’Argentario, determina la propria posizione sul punto A, osservando simultaneamente la Torre di Punta Avoltore (Promontorio Argentario) per rilevamento vero Rilv = 030° e la Torre di Punta di Torre Ciana (Promontorio Argentario) per rilevamento vero Rilv = 321°.Deve raggiungere il punto B situato a 3 miglia nautiche a N dal Faro di Punta del Fenaio (Lam.(3)15s39m16M) (Isola del Giglio). L’ora stimata di arrivo (ETA) è fissata per le ore 11h00m.Considerato che in zona agisce una corrente di direzione Dc = 158° e velocità Vc = 2 kn, determinare la prora vera Pv.",
		"risposta": "Pv = 277° ÷ 303°",
		"id": "5.4.1 - 1",
		"carta": "5d",
		"argomento": "correnti"
	},
	{
		"settore": "D",
		"domanda": "Sono le ore 10h00 e l’unità “Vega” si trova sul punto A in cui osserva simultaneamente il faro di Talamone per rilevamento vero Rlv = 053° e il faro di Punta Lividonia per rilevamento vero Rlv = 150°.Dal punto A, l’imbarcazione procede con prora vera Pv = 230° e con velocitàpropulsiva Vp = 10 nodi.Dopo 45 minuti di navigazione, al fine di verificare gli elementi del motopresenti nella zona, l’unità determina la posizione (punto B) osservando sulla sua sinistra il faro di Punta del Fenaio con rilevamento polare ρ = 030° a una distanza di 3,5 miglia nautiche.Il punto B non coincide con il punto stimato a quell’ora, imputiamo lospostamento alla corrente. Determinare la velocità corrente Vc.",
		"id": "5.4.1 - 10",
		"argomento": "correnti",
		"carta": "5d",
		"risposta": "Vc = 1,3 ÷ 1,9 kn"
	},
	{
		"carta": "5d",
		"settore": "D",
		"id": "5.4.1 - 11",
		"domanda": "L’unità “Rigel” alle ore 10h00 si trova sul punto A di coordinate geograficheGPS:Lat. 42° 30’,6 N e Long. 011° 06’,4 E.Si sta navigando seguendo una rotta vera Rv = 300° e una velocità propulsiva Vp = 7,2 kn; sappiamo che nella zona insite una corrente con direzione corrente Dc = 180° e velocità corrente Vc = 2 kn.Determinare la prora vera Pv.",
		"risposta": "Pv = 310° ÷ 316°",
		"argomento": "correnti"
	},
	{
		"settore": "D",
		"risposta": "Pv = 136° ÷ 142°",
		"argomento": "correnti",
		"id": "5.4.1 - 12",
		"domanda": "L’imbarcazione “Regina” alle ore 08h30m si trova sul punto A di coordinategeografiche GPS:Lat. 42° 24’,.3 N - Long. 010° 55’,1 EStiamo navigando con velocità propulsiva Vp =7 kn verso il punto B dicoordinate Lat. 42° 21’,6 N - Long. 010° 59’,4 E. e nella zona è presente una corrente con direzione corrente Dc Nord e velocità Vc = 1,5 kn, determinare la prora vera Pv.",
		"carta": "5d"
	},
	{
		"domanda": "Alle ore 11h00m l’unità “Fru Fru”si trova nel punto nave A determinato con l’allineamento tra la la Torre di Cala di Forno e il faro di Formica Grande e il rilevamento vero di Torre Poggio Raso Rilv = 098°.Dal punto A si vuole raggiungere il punto B di coordinate geografiche Lat.42°37’,8 N e Long. 010°50’ E alle ore 12h30m.Considerando che in zona è presente una corrente con direzione corrente Dc = 145° e velocità Vc = 1.5 kn, determinare la prora vera Pv.",
		"settore": "D",
		"risposta": "Pv = 287° ÷ 292°",
		"id": "5.4.1 - 13",
		"argomento": "correnti",
		"carta": "5d"
	},
	{
		"carta": "5d",
		"id": "5.4.1 - 14",
		"settore": "D",
		"domanda": "Alle ore 10h00m l’imbarcazione “Italia” si trova nel punto A, e osserva il Fanale rosso del Porto di Piombino (Lam.r.5s8M) per rilevamento vero Rilv = 243°, ad una distanza dallo stesso di 0,4 miglia nautiche. Da questo puntol’imbarcazione dirige verso la Torre dello Scoglio dello Sparviero, in modo da giungere sul punto B di coordinate geografiche: Lat. 42° 49’,0 N e Long. 010° 41’,4 E, alle ore 10h40m.Considerato che in zona è presente una corrente di direzione Dc = 192° e velocità Vc = 3 kn, determinare la prora vera Pv.",
		"argomento": "correnti",
		"risposta": "Pv = 127° ÷ 133°"
	},
	{
		"domanda": "Alle ore 03h20m l’imbarcazione “Pollux” parte dal punto A, situato sul rilevamento vero Rilv = N della Torre dello Scoglio dello Sparviero (P.ta Ala) ad una distanza di 3 miglia nautiche, e procede seguendo una prora vera Pv = 161° con una velocità propulsiva Vp = 10 kn.Alle ore 04h26m, al fine diverificare gli elementi del moto presenti in zona, l’unità determina la posizione osservando il Faro di Formica Grande (Lam.6s23m11M) per Rilv = 124° ad una distanza minima dalla costa di 2,4 miglia nautiche.Posto che sono, pertanto, variati gli elementi perturbatori del moto, determinare la direzione della corrente Dc.",
		"settore": "D",
		"argomento": "correnti",
		"risposta": "Dc = 046°÷ 052°",
		"carta": "5d",
		"id": "5.4.1 - 15"
	},
	{
		"carta": "5d",
		"id": "5.4.1 - 16",
		"settore": "D",
		"argomento": "correnti",
		"risposta": "Vc = 2,2 ÷ 2,8 kn",
		"domanda": "Alle ore 18h00m l’unità “Kochab” sta navigando con prora vera Pv = 076° e velocità propulsiva Vp = 7,1 kn e si trova sul punto A di coordinate geografiche GPS:Lat. 42° 21’,0 N - Long. 009° 57’,0 E.Alle ore 18h45m il GPS fornisce le seguenti coordinate geografiche (punto B):Lat. 42°24’,0 N – Long. 010°05’,1 E.Posto che in zona sono presenti elementi perturbatori del moto, determinare la velocità della corrente Vc."
	},
	{
		"risposta": "ora di arrivo 19h 47m ÷ 19h 53m",
		"argomento": "correnti",
		"id": "5.4.1 - 17",
		"domanda": "L’imbarcazione “Deneb” si trova in navigazione ad Ovest dell’Isola del Giglio. Alle ore 18h00m l’imbarcazione determina la propria posizione individuata dalle seguenti coordinate geografiche GPS:Lat. 42° 22’,3 N - Long. 010° 41’,2 E (punto A).Da tale posizione l’unità assume prora vera Pv = 063° ed una velocitàpropulsiva Vp = 5 kn.Considerando che in zona è presente una corrente di direzione Dc = S evelocità Vc = 1,5 kn, l’imbarcazione “Deneb” naviga fino ad osservare il Faro di P.ta del Fenaio (Lam.(3)15s39m16M) per rilevamento vero Rilv = 150° (punto B), determinare l’ora di arrivo sul punto B.",
		"settore": "D",
		"carta": "5d"
	},
	{
		"settore": "D",
		"domanda": "L’imbarcazione “Bellatrix” è in navigazione a Nord dell’Isola di Montecristo e, alle ore 22h00m, determina la propria posizione osservando il “puntotrigonometrico” di Monte della Fortezza per rilevamento vero Rilv = 160°,distanza di 3,9 miglia nautiche (punto A).Da tale posizione l’unità assume proravera Pv = 038° ed una velocità propulsiva Vp = 5 kn.Considerando che in zona è presente una corrente di direzione Dc = 095° e velocità Vc = 2 kn, determinare la velocità effettiva Ve.",
		"id": "5.4.1 - 18",
		"risposta": "Ve = 6 ÷ 6,6 kn",
		"argomento": "correnti",
		"carta": "5d"
	},
	{
		"argomento": "correnti",
		"id": "5.4.1 - 2",
		"risposta": "ora di arrivo 11h06m ÷ 11h12m",
		"domanda": "L’imbarcazione “Jupiter”, alle ore 09h45m, si trova sul punto A di coordinate geografiche GPS: Lat. 42° 23’,2 N - Long. 010° 56’,8 E.Da tale punto A procede con velocità propulsiva Vp = 7 kn verso il punto B situato sul rilevamento  vero Rilv = 305° del Faro Talamone (Lam.(2)10s30m15M) ad una distanza di 0,5 miglia nautiche.Considerato che in zona è presente una corrente di direzione Dc = 075° evelocità Vc = 3 kn, determinare l’ora di arrivo sul punto B.",
		"carta": "5d",
		"settore": "D"
	},
	{
		"carta": "5d",
		"id": "5.4.1 - 3",
		"settore": "D",
		"risposta": "Vc = 0,7 kn ÷ 1,3 kn",
		"argomento": "correnti",
		"domanda": "L’imbarcazione “Aldebaran” è in navigazione nei pressi del Promontoriodell’Argentario e, alle ore 11h30m, determina la propria posizione osservando il Faro di P.ta Lividonia (Lam.5s47m16M) per Rilv = S ad una distanza di 2,2 miglia nautiche (punto A).Dal punto A, l’imbarcazione procede con prora vera Pv = 292° e con unavelocità propulsiva Vp = 6 kn.Alle ore 13h00m, al fine di verificare gli elementi del moto presenti in zona,l’unità determina la posizione individuata dalle seguenti coordinate geografiche GPS: Lat. 42° 32’,4 N - Long. 010° 57’,0 E (punto B).Posto che sono, pertanto, variati gli elementi perturbatori del moto, determinare la velocità corrente Vc."
	},
	{
		"id": "5.4.1 - 4",
		"domanda": "Nell’anno 2000, l’imbarcazione “Rigel” alle ore 19h40m si trova sul punto A individuato dall’intersezione del rilevamento vero Rilv = 119° del Faro di P.ta del Fenaio (Lam.(3)15s39m16M) (Isola del Giglio) e la batimetrica dei 200 metri.Dal punto A, l’imbarcazione procede con prora bussola Pb = 069° (deviazione letta nella tabella di borso δ = - 4°) e con velocità propulsiva Vp = 6 kn verso il punto B, situato a 0,5 miglia nautiche a S del Faro di Talamone (Lam.(2)10s30m15M).Il valore di declinazione nel 1994 è: 0° 30’ E (variazione annua 7’ E)Alle ore 21h00m, al fine di verificare gli elementi del moto presenti in zona,l’unità determina la posizione (punto C) osservando simultaneamente il Faro di P.ta del Fenaio (Lam.(3)15s39m16M) per rilevamento bussola Rilb = 195° e il Faro di Punta Lividonia (Lam.5s47m16M) per rilevamento bussola Rilb = 107°. Determinare la rotta vera Rv.",
		"carta": "5d",
		"risposta": "Rv = 055° ÷ 061°",
		"settore": "D",
		"argomento": "correnti"
	},
	{
		"settore": "D",
		"carta": "5d",
		"argomento": "correnti",
		"risposta": "Dc = 124° ÷ 130°",
		"domanda": "L’imbarcazione “Regina” è in navigazione nei pressi del Promontorio dell’Argentario.Alle ore 12h18m l’imbarcazione determina la propria posizione osservando simultaneamente il faro di Talamone (Lam.(2)10s30m15M) per rilevamento vero Rilv = 047° ed il Faro di P.ta Lividonia (Lam.5s47m16M) per rilevamento vero Rilv = 152° (punto A).Dal punto A l’imbarcazione procede con prora vera Pv = 243° e con unavelocità propulsiva Vp = 5 kn.Alle ore 13h30m, al fine di verificare gli elementi del moto presenti in zona,l’unità determina la posizione individuata dalle seguenti coordinate geografiche GPS: Lat. 42° 26’,2 N - Long. 010° 58’,8 E (punto B).Posto che sono, pertanto, variati gli elementi perturbatori del moto, determinare la direzione della corrente Dc.",
		"id": "5.4.1 - 5"
	},
	{
		"settore": "D",
		"argomento": "correnti",
		"id": "5.4.1 - 6",
		"carta": "5d",
		"risposta": "ora di arrivo 14h44m ÷ 14h50m",
		"domanda": "Alle ore 13h00m l’imbarcazione “Acrux” si trova sul punto di coordinate geografiche GPS: Lat. 42° 32’,4 N - Long. 010° 57’,0 E (punto A).Da tale posizione l’imbarcazione intende dirigere, con una velocità propulsiva Vp = 6 kn, sul punto B che si trova rilevando il Faro della Formica Grande (Lam.6s23m11M) per Rilv = E ad una di 4,6 miglia nautiche.Considerando che in zona è presente una corrente di direzione Dc = 048° evelocità Vc = 2,2 kn, determinare l’ora di arrivo al punto B."
	},
	{
		"id": "5.4.1 - 7",
		"settore": "D",
		"argomento": "correnti",
		"risposta": "ora di arrivo 13h20m ÷ 13h26m",
		"domanda": "L’imbarcazione “Vega” è in navigazione a Nord dell’Isola del Giglio.Alle ore 12h00m l’unità determina la propria posizione osservando il  Faro di Formica Grande (Lam.6s23m11M) per rilevamento vero Rilv = 124° ad una distanza di 2,4  miglia nautiche (punto A).Da tale posizione l’imbarcazione intende dirigere, con una velocità propulsiva Vp = 10 kn, sul punto  B che si trova rilevando il Faro di Punta del Fenaio (Lam.(3)15s39m16M) per rilevamento vero Rilv = 153° ad una distanza di 1 miglio nautico.Considerando che in zona è presente una corrente di direzione Dc = 048° evelocità Vc = 2,2 kn, determinare l’ora di arrivo al punto B.",
		"carta": "5d"
	},
	{
		"settore": "D",
		"id": "5.4.1 - 8",
		"domanda": "Nel 2009, alle ore 16h45m, l’imbarcazione “Schedir” naviga con una prora vera Pv = 068° alla velocità propulsiva Vp = 8 kn e determina la propria posizione osservando il Faro di Formica Grande (Lam.6s23m11M) per rilevamento vero Rilv = 333° ad una distanza di 2,1 miglia nautiche (punto A).Da tale posizione l’imbarcazione intende dirigere verso il punto B di coordinate geografiche: Lat. 42° 35’,4 N – Long. 011°03’,4 E.Alle ore 17h39m, l’imbarcazione osserva il Faro di P.ta Lividonia (Lam.5s47m16M) per rilevamento  vero Rilv = 159° ad una distanza minima dalla costa di 6 miglia nautiche.Considerando che in zona è presente una corrente di direzione Dc = 180° e velocità Vc = 3,2 kn, determinare la rotta vera Rv.",
		"risposta": "Rv = 089° ÷ 095°",
		"argomento": "correnti",
		"carta": "5d"
	},
	{
		"carta": "5d",
		"settore": "D",
		"argomento": "correnti",
		"risposta": "Vp = 3,9 ÷ 4,5 kn",
		"domanda": "L’imbarcazione “Essex” sta navigando da due ore diretta al porto di Talamone. Alle ore 12h00 determiniamo il punto nave con il GPS e ci accorgiamo di essere fuori rotta:Punto nave A GPS Lat. 42° 25’,5 N - Long. 010° 51’,1 E;Punto nave B stimato Lat. 42° 26’,6 N - Long. 010° 47’,3 E.Imputando lo spostamento alla corrente, calcolare la velocità propulsiva Vp necessaria per arrivare alle ore 15h00 al porto turistico di Talamone (simbolo).",
		"id": "5.4.1 - 9"
	},
	{
		"domanda": "Nel 2006, alle ore 16h45m, l’unità “Acrux” si trova sul punto A, distanza 1 miglio sul rilevamento vero  Rilv = Nord della Torre di Capo d’Uomo (Talamone).Dal punto A si fa rotta verso Giglio Porto (ingresso tra i due Fanali), impostando una velocità  propulsiva Vp = 20 kn.Posto che il propulsore dell’unità ha un consumo orario di 65 l/h e che in zona non sono presenti elementi perturbatori del moto, determinare il carburante necessario, comprensivo del 30% di riserva, dal punto nave A sino al punto nave al traverso del faro di Punta Lividonia.",
		"settore": "D",
		"carta": "5d",
		"id": "5.4.2 - 1",
		"risposta": "Carburante 19,5 ÷ 21,5 litri",
		"argomento": "carburante"
	},
	{
		"carta": "5d",
		"argomento": "carburante",
		"domanda": "Siamo in uscita dal fanale di Porto Santo Stefano (simbolo porto turistico) e navighiamo con rotta vera Rv = 320° dirigendo verso le Formiche di Grosseto. Alle ore 14h00m rileviamo il Faro di Formica Grande con rilevamento polare a sinistra  ρ = - 45°. Mantenendo la stessa rotta, alle ore 14h30m rileviamo lo stesso faro al traverso. Da questo punto si inverte la rotta e si rientra a Porto Santo Stefano (simbolo porto turistico).Determinare la quantità di carburante necessaria per tutta la navigazione effettuata, inclusa la riserva,  considerando un consumo medio di 4 l/h.",
		"risposta": "Carburante 21,5 ÷ 23,5 litri",
		"id": "5.4.2 - 2",
		"settore": "D"
	},
	{
		"argomento": "carburante",
		"carta": "5d",
		"risposta": "Carburante 49 ÷ 51 litri",
		"domanda": "Partenza da Punta Lividonia con rotta vera Rv = 267°.Al fine di determinare la velocità di navigazione effettiva, si determina il punto nave A eseguendo con faro Punta del Fenaio due rilevamenti polari:ρ = +045° - 045° eseguito alle ore 10h00m ρ = +090° - 090° eseguito alle ore 10h30mDa tale punto A si vuole raggiungere il porticciolo turistico di Talamone (simbolo).Determinare il valore del carburante necessario per tutta la navigazione effettuata comprensivo della riserva sapendo che il motore ha un consumo orario di 10 l/h.",
		"id": "5.4.2 - 3",
		"settore": "D"
	},
	{
		"argomento": "carburante",
		"id": "5.4.2 - 4",
		"risposta": "Carburante 56 ÷ 58 litri",
		"domanda": "Si parte da Giglio Porto (fanali) con una prora vera Pv = 042°.Al fine di determinare la velocità di navigazione effettiva, si determina il nostro punto nave A eseguendo con faro Punta Lividonia alle ore 10.00 un primorilevamento polare a dritta ρ = +045° e dopo 30 minuti di navigazione sempre con lo stesso punto cospicuo un secondo rilevamento polare a dritta ρ = +090°. Da tale punto A si decide di cambiare rotta per arrivare a Formica Piccola.Determinare la quantità di carburante necessaria a tutta la navigazione, comprensiva della riserva, sapendo che il motore ha un consumo pari a 12 l/h.",
		"settore": "D",
		"carta": "5d"
	},
	{
		"domanda": "Si parte dal punto A di coordinate geografiche GPS: Lat. 42° 25’,7 N e Long. 011° 03’,7 E con una prora bussola Pb = 267°  (variazione magnetica V = 0°) e una velocità propulsiva Vp = 4,2 kn.Si eseguono con faro Punta del Fenaio (Nord isola del Giglio ) due rilevamenti polari a sinistra:ρ =  -045° eseguito alle ore 16h30m ρ =  -090° eseguito alle ore 17h00mDa tale punto nave B si decide di raggiungere Formica Grande. Determinare la quantità di carburante necessaria per tutta la navigazione, compresa la riserva, sapendo che il motore ha un consumo orario di 10 l/h.",
		"settore": "D",
		"risposta": "Carburante 52,5 ÷ 54,5 litri",
		"id": "5.4.2 - 5",
		"argomento": "carburante",
		"carta": "5d"
	},
	{
		"argomento": "carburante",
		"settore": "D",
		"id": "5.4.2 - 6",
		"carta": "5d",
		"risposta": "Carburante 70,5 ÷ 72,5 litri",
		"domanda": "Si parte dal punto A di coordinate geografiche GPS: Lat. 42°20’ N e Long. 010° 40’ E con una prora bussola Pb = 061° e una velocità propulsiva Vp = 3,6 kn.  Si determina il punto nave B eseguendo con faro Punta del Fenaio (Nord isola del Giglio) due rilevamenti polari a dritta:ρ = +045° eseguito alle ore 16h30m ρ = +090° eseguito alle ore 17h00mDa tale punto B si decide di raggiungere Formica Grande.Determinare la quantità di carburante necessaria per tutta la navigazione, comprensiva della riserva, sapendo che il motore ha un consumo pari a 10 l/h. Variazione magnetica 0°"
	},
	{
		"carta": "5d",
		"risposta": "Lat. 42°28’,4 N ÷ 42°29’,0 N      Long.011°03’,4 E ÷ 011°04’,0E",
		"id": "5.4.3 - 1",
		"argomento": "navigazione costiera",
		"settore": "D",
		"domanda": "Partenza da Punta del Fenaio diretti a Talamone (faro) con una velocitàpropulsiva Vp = 6 kn (declinazione d = 1°W, deviazione δ  +5°).Alle ore 10.00 rileviamo il faro di Punta Lividonia per un primo Rlb 096°, dopo 20 minuti di navigazione lo rileviamo con un secondo Rlb 131°.Determinare le coordinate del punto nave costiero delle ore 10:20."
	},
	{
		"argomento": "navigazione costiera",
		"id": "5.4.3 - 2",
		"settore": "D",
		"domanda": "Stiamo navigando ad Est delle Formiche di Grosseto con una prora vera  Pv = 349° (variazione magnetica V = 2°E) con velocità propulsiva Vp = 6 kn.Alle ore 10h00m rileviamo il faro di Formica Grande con rilevamento bussola Rilb = 278° e alle 10h20m  rileviamo lo stesso faro con rilevamento bussola Rilb= 243°.Determinare le coordinate del punto nave delle ore 10h20m.",
		"carta": "5d",
		"risposta": "Lat. 42°35’,7 N  ÷ 42°36’,3 N   Long.010°56’,8 E ÷ 010°57’,4E"
	},
	{
		"id": "5.4.3 - 3",
		"domanda": "Il giorno 5 Gennaio 2021 siamo in navigazione al largo del Promontorio Argentario con prora bussola Pb = 337° (deviazione 1° E) e Vp = 12 nodi. Il valore della declinazione magnetica anno 2018:d = 1 °45' W variazione annua 5’ W.Alle ore 10h00m  si rileva Capo d'Uomo (Argentario) per rilevamento bussola Rilb = 036°alle ore 10h20m si rileva lo Scoglio Argentarola per rilevamento bussola Rilb = 081°.Determinare le coordinate del punto nave delle ore 10h20m.",
		"argomento": "navigazione costiera",
		"carta": "5d",
		"settore": "D",
		"risposta": "Lat. 42° 24',3 N ÷42°24’,9N     Long.011°00’,7 E ÷011°01’,3E"
	},
	{
		"risposta": "Lat. 42°26’,1 N ÷ 42°26’,7 N   Long.010°54’,2 E ÷ 010°54’,8E",
		"settore": "D",
		"id": "5.4.3 - 4",
		"domanda": "Partenza da faro Talamone con una rotta vera Pv = 235° e una velocità propulsia Vp = 6 kn.Si vuole determinare il punto nave costiero A eseguendo alle ore 20h00m un rilevamento di Punta Lividonia rilevamento vero Rilv = 120° e alle ore 20h50m un rilevamento vero Rlv = 200° di Punta del Fenaio.Determinare le coordinate del punto nave delle ore 20h50m.",
		"argomento": "navigazione costiera",
		"carta": "5d"
	},
	{
		"domanda": "Siamo in navigazione a Ovest di Formica Grande con una prora bussola Pb = 230° (variazione magnetica V = 5° W) e una velocità propulsiva Vp 6 = kn.Si vuole determinare il punto nave costiero A eseguendo alle ore 16h00m con il faro di Formica Grande un rilevamento bussola Rilb = 105° e alle ore 16h23m sempre con lo stesso faro un rilevamento bussola Rilb =  070°.Determinare le coordinate del punto nave delle ore 16h23m.",
		"settore": "D",
		"risposta": "Lat.42° 32’,9 N ÷ 42°33’,5 N   Long.010°48’,5 E ÷ 010°49’,1E",
		"carta": "5d",
		"argomento": "navigazione costiera",
		"id": "5.4.3 - 5"
	},
	{
		"argomento": "navigazione costiera",
		"carta": "5d",
		"domanda": "Alle ore 14h50m  imbarcazione “Rigel”, avente un pescaggio di m. 2, in navigazione a W dell’Isola del Giglio, determina la propria posizioneosservando il Faro di Punta del Fenaio (Lam.(3)15s39m16M) per rilevamento vero Rilv = 095° ad una distanza di 2,5 miglia nautiche (punto A).Da tale punto procede seguendo una rotta vera Rv = 340° con una velocità effettiva Ve = 9 kn.Alle ore 15h50m riceve una richiesta di assistenza dall’imbarcazione “Dubhe”per avaria.Tale imbarcazione comunica la propria posizione individuata dalle seguenti coordinate geografiche GPS:Lat. 42° 32’,5 N - Long. 010° 57’,5 E (punto B).L’imbarcazione “Dubhe” sta navigando seguendo una rotta vera Rv = 052° aduna velocità effettiva Ve = 1,6 kn.L’imbarcazione “Rigel” decide , quindi, di raggiungere l’unità in difficoltà perprestare assistenza senza variare la velocità.Determinare le coordinate geografiche del punto D di intercettazione.",
		"risposta": "Lat. 42° 33’,5 N ÷42°34’,1 N - Long. 010°59’,4 E ÷ 011° 00’ E",
		"settore": "D",
		"id": "5.4.3 - 6"
	},
	{
		"risposta": "Lat. 42° 28’,8 N÷42°29’,4 N - Long. 011°04’,9 E ÷ 011°05’,5 E",
		"argomento": "navigazione costiera",
		"settore": "D",
		"carta": "5d",
		"domanda": "Nel 2009 l’imbarcazione “Daphne” è in navigazione a E dell’Isola del Giglio,seguendo una rotta vera Rv = 041° con una velocità effettiva Ve = 4 kn.Alle ore 09h30m si trova sul punto A di coordinate geografiche GPS:Lat. 42°23’,0 N e Long. 010° 58’,0 E.Alla stessa ora l’imbarcazione “Sophia” chiede assistenza per avaria delpropulsore.Tale unità naviga seguendo una rotta vera Rv = 221° con una velocità effettiva Ve = 1,5 kn e la sua posizione GPS (punto B) risulta essere: Lat. 42° 31’,5 N e Long. 011° 08’,0 E.Considerando che in zona non sono presenti elementi perturbatori del moto determinare le coordinate geografiche del punto C di intercettazione.",
		"id": "5.4.3 - 7"
	},
	{
		"id": "5.4.3 - 8",
		"domanda": "L’imbarcazione “Mistral” in uscita dal porto dell’Isola del Giglio, si trova alle ore 12h20m tra i fanali delle ostruzioni (punto A) e procede con una velocità propulsiva Vp1 = 5 kn verso il Faro di Talamone (Lam.(2)10s30m15M).Alle ore 14h20m riceve una richiesta di assistenza dall’imbarcazione “Ghibli”per avaria.Tale imbarcazione, che sta navigando seguendo una rotta vera Rv = 105° ad una velocità effettiva Ve = 2,5 kn, comunica la propria posizione individuata dalle seguenti coordinate geografiche GPS:Lat. 42° 36’,0 N - Long. 010° 53’,0 E (punto B).Alle ore 14h20m , quindi, l’imbarcazione “Mistral” osserva il Faro di P.ta Lividonia (Lam.5s47m16M) per rilevamento vero Rilv = 146° ad una distanza dalla costa di 3,1miglia nautiche (punto A’).L’imbarcazione “Mistral” decide, quindi, di raggiungere l’unità in difficoltà per prestare assistenza all’imbarcazione “Ghibli” aumentando la velocità propria a 7 kn (Vp2).Determinare le coordinate geografiche del punto D di intercettazione.",
		"risposta": "Lat. 42° 35’,0 N ÷ 42°35’,6 N - Long. 010°56’,4 E ÷ 010° 57’,0 E",
		"carta": "5d",
		"argomento": "navigazione costiera",
		"settore": "D"
	},
	{
		"carta": "5d",
		"argomento": "scarroccio",
		"settore": "D",
		"risposta": "Lat. 42°24’,1 N ÷ 42°24’,7 N   Long.010°54’,5 E  ÷ 010°55’,1E",
		"id": "5.4.4 - 1",
		"domanda": "Stiamo navigando con rotta vera Rv = 313° e alle ore 09h00m ci troviamo sul punto A, sul rilevamento polare sinistro ρ = -090°e una distanza di 1,8 miglia nautiche dal faro di Punta del Fenaio (Isola del Giglio).Considerando che in zona soffia un vento di Tramontana che crea uno scarroccio di 8°, indicare le coordinate geografiche del punto nave A."
	},
	{
		"carta": "5d",
		"risposta": "Lat. 42°33’,0  N ÷ 42°33’,6 N    Long.010°54’,7 E  ÷ 010°55’,3E",
		"argomento": "scarroccio",
		"domanda": "Stiamo navigando a sud delle Formiche di Grosseto con rotta vera Rv = 045°. Alle ore 09h00m rileviamo al traverso sinistro ad una distanza di 2 miglia il faro di Formica Grande.Considerando che nella zona soffia un vento di Tramontana che crea uno scarroccio di 5°, indicare le coordinate del punto nave.",
		"settore": "D",
		"id": "5.4.4 - 2"
	},
	{
		"carta": "5d",
		"argomento": "scarroccio",
		"risposta": "Vp = 6,5 ÷7,5 kn",
		"settore": "D",
		"domanda": "Ci troviamo alle ore 15h30m sul punto A di coordinate geografiche GPS Lat 42°21’,1 N e Long. 011° 13’,5 E e vogliamo raggiungere il porto dell’isola del Giglio (fanali di ingresso in porto).In zona insiste un vento di Libeccio che provoca uno scarroccio a dritta di 7° euna variazione di velocità di – 1 kn.Determinare la velocità propria Vp da impostare per raggiungere il porto turistico alle ore 17h45m.",
		"id": "5.4.4 - 3"
	},
	{
		"argomento": "scarroccio",
		"settore": "D",
		"id": "5.4.4 - 4",
		"risposta": "Lat. 42° 26',9 N ÷42°27’,5 N   Long. 010°59',9 E ÷ 011°00’,5 E",
		"carta": "5d",
		"domanda": "Dal punto nave A situato 1,5 miglia nautiche sul rilevamento Ovest Sud Ovest del faro di Punta del Fenaio (zona nord isola del Giglio), in presenza di un vento di Maestrale che provoca uno scarroccio di +8°  si intende impostare rotta vera Rv = 060°.Determinare le coordinate del punto B nel quale si rileva Punta Lividonia (zonaNord promontorio Argentario) per rilevamento polare a dritta ρ = +45°."
	},
	{
		"id": "5.4.4 - 5",
		"carta": "5d",
		"settore": "D",
		"risposta": "Lat. 42° 32',3 N ÷42°32’,9 N   Long. 011°07',8 E ÷ 011°08’,4 E",
		"domanda": "Dal punto nave A situato a una distanza di 1,5 miglia nautiche sul rilevamento vero Nord Ovest del Faro Formica Grande,  si intende procedere con rotta vera Rv = 095°, tenendo conto che siamo in presenza di un vento di Grecale che crea uno scarroccio di 5°.Determinare le coordinate del punto nave B in cui si rileva al traverso il Faro di Talamone.",
		"argomento": "scarroccio"
	},
	{
		"id": "5.5.1 - 1",
		"risposta": "Pv = 300° ÷ 306°",
		"argomento": "correnti",
		"carta": "4d2",
		"settore": "A",
		"domanda": "Alle ore 20h20m, l’imbarcazione “Alphard” determina la propria posizione attraverso l’osservazione simultanea dei rilevamenti veri del Faro Cap de Feno (Lam.(4)15s20m21M) per Rilv = 335° e del Faro Cap. Pertusato (Lam.(2)10s100m25M) per Rilv = 032° (punto A).L’unità naviga seguendo una rotta vera Rv = 290° e una velocità propulsiva Vp =7 kn.Tenuto conto che in zona vi è una corrente dalle seguenti caratteristiche Dc = 250° e Vc = 2,5 kn, determinare la prora vera Pv."
	},
	{
		"risposta": "Pv = 31°÷ 37°",
		"carta": "4d2",
		"domanda": "Alle ore 06h45m l’imbarcazione “Sonia” si trova sul punto A individuato dall’intersezione del rilevamento vero Rilv = 067° del faro Ex sem. di Capo Testa e la batimetrica dei 50 metri.Dal punto A, l’imbarcazione procede con prora vera Pv = 044° e con velocitàpropulsiva Vp = 7 kn.Alle ore 07h20m sul G.P.S. leggiamo le coordinate del punto nave B: Lat. 41°15’,7 N e Long. 009° 04’,7 E, imputiamo lo spostamento alla corrente. Poiché le condizioni meteo sono in peggioramento e non essendo mutati gli elementi perturbatori del moto, dal punto B si prosegue la navigazione dirigendo verso il porto turistico (simbolo) di Punta la Madonnetta, dove si vuole arrivare alle 08h20m.Determinare la prora vera Pv.",
		"id": "5.5.1 - 2",
		"argomento": "correnti",
		"settore": "A"
	},
	{
		"carta": "4d2",
		"argomento": "correnti",
		"risposta": "Dc = 298° ÷ 304°",
		"settore": "A",
		"domanda": "Il M/Y “Formalhaut” è in navigazione a SW della Corsica e segue una prora veraPv = 175° ad una velocità propulsiva Vp = 6,6 kn.Alle ore 08h20m, determina la propria posizione osservando il Faro di Cap de Feno (Lam.(4)15s20m21M) per rilevamento vero Rilv = 090° ad una distanza di 2 miglia nautiche dallo stesso (punto A).Alle ore 09h50m, al fine di verificare gli elementi perturbatori del moto presenti in zona, determina nuovamente la sua posizione attraverso l’osservazione simultanea dei seguenti rilevamenti veri (punto B):Rilv = 052° Faro di Cap Pertusato (Lam.(2)10s100m25M); Rilv = 098° Faro di Capo Testa (Lam.(3)12s67m17M).Posto che sono, pertanto, variati gli elementi perturbatori del moto, determinare la direzione della corrente Dc.",
		"id": "5.5.1 - 3"
	},
	{
		"carta": "4d2",
		"domanda": "In navigazione ad ovest di Cap de Feno con Pb 286° e velocità propulsiva Vp = 6 kn (declinazione 3°E, deviazione 1°E), alle ore 02h30m rileviamo il fanale del segnale IALA cardinale sud per Rilb = 346°.Dopo 20 minuti lo rileviamo nuovamente per Rilb = 56°.Determinare le coordinate geografiche del punto nave delle ore 02h50m.",
		"argomento": "navigazione costiera",
		"risposta": "Lat. 41°25’,6 N ÷ 41°26’,2 N    Long.008°51’,3 E ÷ 008°51’,9E",
		"settore": "A",
		"id": "5.5.3 - 1"
	},
	{
		"argomento": "navigazione costiera",
		"risposta": "Lat. 41° 19’,1 N ÷ 41°19’,7 N  - Long. 009°10’,6 E ÷ 009° 11’,2 E",
		"carta": "4d2",
		"domanda": "L’imbarcazione “Mizar”, è in navigazione a W di Cap de Feno (SW della Corsica), seguendo una rotta vera Rv = 080° con una velocità propulsiva Vp = 6 kn e, alle ore 10h40m, determina la propria posizione individuata dalle seguenti coordinate geografiche GPS:Lat. 41° 22’,2 N - Long. 008° 57’,5 E (punto A).Sempre alla medesima ora 10h40m la stessa unità riceve una richiesta di assistenza dall’imbarcazione “Esperia” per avaria. Tale imbarcazione comunica la propria posizione individuata dalle seguenti coordinate geografiche GPS: Lat. 41° 17’,3 N - Long. 009° 06’,0 E (punto B).L’imbarcazione “Esperia” sta navigando seguendo una rotta vera Rv = 060° aduna velocità effettiva Ve = 3,3 kn.L’imbarcazione “Mizar” decide, quindi, di raggiungere l’unità in difficoltà perprestare assistenza aumentando la velocità a 8 kn.Determinare le coordinate geografiche del punto D di intercettazione.",
		"id": "5.5.3 - 2",
		"settore": "A"
	},
	{
		"carta": "4d2",
		"settore": "A",
		"argomento": "scarroccio",
		"domanda": "Dal punto nave A di coordinate geografiche GPS Lat. 41° 15’,3 N e Long. 009° 11’,5 E, si procede per raggiungere il porto di Bonifacio (Simbolo) navigando con velocità propulsiva Vp = 7 kn.Sapendo che in zona insiste un vento di Ponente che provoca uno scarroccio di 10°, determinare la Prua vera per giungere a destinazione.",
		"id": "5.5.4 - 1",
		"risposta": "Pv = 333° ÷ 337°"
	},
	{
		"argomento": "correnti",
		"domanda": "Il M/Y “Atria” è in navigazione a NE della Sardegna e, alle ore 15h00m,determina la propria posizione (punto A) attraverso l’osservazione simultaneadei seguenti rilevamenti veri:Rilv = 247° il Radiofaro Circolare di Capo Ferro (Lam.(3)15s52m24M); Rilv = 300° il Faro di Isole Monaci (Lam.5s26m10M).Da questo punto si procede con una velocità propulsiva Vp = 12 kn verso il punto B di coordinate geografiche GPS: Lat. 41° 21’,5 N - Long. 009° 26’,0 E. Tenuto conto che in zona vi è una corrente dalle seguenti caratteristiche Dc = 045° e Vc = 2,5 kn, determinare la prora vera Pv.",
		"settore": "B",
		"risposta": "Pv = 310° ÷316°",
		"id": "5.6.1 - 1",
		"carta": "4d2"
	},
	{
		"carta": "4d2",
		"id": "5.6.1 - 2",
		"argomento": "correnti",
		"settore": "B",
		"domanda": "Il M/Y “Savona” alle ore 21h00m si trova sul punto A di coordinate geografiche GPS: Lat. 41°20’,0 N – Long.009°30’,0 E e procede con prora vera Pv = 137° con velocità propulsiva Vp = 10 kn a Levante delle “Bocche di Bonifacio”.Alle ore 22h00m , al fine di verificare gli elementi perturbatori del motopresenti in zona, determina la propria posizione  rilevando il faro “I Monaci”con rilevamento vero Rilv = 280° e distanza di 4 miglia nautiche.Posto che in zona esiste una corrente, determinare la direzione corrente Dc.",
		"risposta": "Dc = 255° ÷261°"
	},
	{
		"risposta": "Ora traverso: 12h31m÷ 12h37m",
		"argomento": "navigazione costiera",
		"id": "5.6.3 - 1",
		"carta": "4d2",
		"domanda": "Nel 2009 l’imbarcazione “Peacock” naviga a SE della Corsica con prora vera Pv = 180° e velocità propulsiva Vp = 4 kn, in assenza di elementi perturbatori del moto.Alle ore 11h50m determina la propria posizione (punto A) attraversol’osservazione simultanea dei seguenti rilevamenti bussola:Rilb = 243° il segnale cardinale E dell’Ecueil de Perduto (sc(3)10s16m11M);Rilb = 195° il faro di Razzoli (Lam.5s77m13M); Rilb = 286° la Torre di Santa Manza.Il valore di declinazione è: 0° 05’ E 1993 (7’ E)Determinare l’ora in cui si rileva al traverso di dritta il Faro dell’Isola di Lavezzi (Int.(2)6s27m15M) punto B.  PER LA RISOLUZIONE DEL QUESITO E' NECESSARIO UTILIZZARE LA TABELLA DELLE DEVIAZIONI ALLEGATA",
		"settore": "B"
	},
	{
		"settore": "B",
		"id": "5.6.3 - 2",
		"risposta": "Lat. 41°15’,5 N ÷ 41°16’,1 N   Long.009°32’,8 E ÷ 009°33’,4E",
		"domanda": "Ci troviamo in navigazione ad est dell’Arcipelago della Maddalena con prorabussola Pb = 300° (declinazione 2°E, deviazione 2°W) e velocità propulsiva Vp=6 kn; rileviamo il Faro sulle Isolette Monaci per Rilb = 235°. Successivamente, dopo 15 minuti di navigazione, rileviamo nuovamente lo stesso per Rilb = 210°.  Determinare il punto nave al momento dell’ultimo rilevamento.",
		"carta": "4d2",
		"argomento": "navigazione costiera"
	},
	{
		"settore": "B",
		"risposta": "Lat. 41° 21’,1 N ÷ 41°21’,7 N - Long. 009°21’,7 E ÷ 009° 22’,3 E",
		"carta": "4d2",
		"argomento": "navigazione costiera",
		"id": "5.6.3 - 3",
		"domanda": "L’imbarcazione “Altair” è in navigazione a SE dell’Isola di Caprera, seguendo una rotta vera Rv = N con una velocità propulsiva Vp = 8 kn e alle ore 20h20m determina la propria posizione (punto A) attraverso l’osservazione simultanea dei seguenti rilevamenti veri:  Rilv = 135° del Faro di Capo Ferro (Lam.(3)15s52m24M);Rilv = 246° della Meda galleggiante Secca di Tre Monti (Lam.(2)8s5M).Alle ore 21h05m, l’unità “Altair” riceve una richiesta di assistenza dall’imbarcazione “Deneb” che, a causa di un’avaria al propulsore, procede con rotta vera Rv = 225° e velocità effettiva Ve = 2 kn.L’unità “Deneb” comunica la propria posizione delle ore 21h05m individuata dalle seguenti coordinate geografiche GPS: Lat. 41° 22’,5 N - Long. 009° 23’,5 E (punto B). L’imbarcazione “Altair” decide, quindi, di raggiungere l’unità in difficoltà per prestare assistenza aumentando la velocità propulsiva a 9,9 kn.Considerando che in zona non sono presenti elementi perturbatori del moto, determinare le coordinate geografiche del punto D di intercettazione."
	},
	{
		"carta": "4d2",
		"settore": "B",
		"domanda": "Alle ore 10h 50m ci troviamo sul punto A che si trova sul rilevamento vero Rilv= 190° del Faro Isolette Monaci alla distanza di 1 miglio nautico. Stiamo navigando con prora vera Pv = 050° e velocità propulsiva Vp = 7 kn.In zona è presente un vento di Ostro che provoca uno scarroccio di 8° e una variazione di velocità di + 1 nodo.Determinare la posizione del punto nave B alle ore 12h30m tenendo contodell’effetto del vento.",
		"risposta": "Lat. 41° 23’,5 N ÷41°24’,1 N   Long. 009°42’,6 E ÷ 009°43’,2 E",
		"argomento": "scarroccio",
		"id": "5.6.4 - 1"
	},
	{
		"domanda": "Nel 2008 l’imbarcazione “Khamsin” è in navigazione ad NW della Sardegna e alle ore 12h00m determina la propria posizione osservando la parte di costa più sporgente di P.ta Li Canneddi (Lat. 41° 01’,7 N - Long. 008° 52’,8 E) per rilevamento vero Rilv = 115° ad una distanza di 1 miglio nautico (punto A). Dal punto A, non essendo presenti in zona elementi perturbatori del moto,l’imbarcazione procede con prora vera Pv = 045° e con una velocità propulsivaVp = 6,5 kn.Alle ore 13h00m, al fine di verificare gli elementi perturbatori del moto presenti in zona, l’unità determina la propria posizione individuata dalle seguenti coordinate geografiche GPS: Lat. 41° 07’,4 N - Long. 008° 55’,1 E (punto B).Posto che sono, pertanto, variati gli elementi perturbatori del moto, determinare la velocità della corrente Vc.",
		"id": "5.7.1 - 1",
		"argomento": "correnti",
		"risposta": "Vc = 1,7 ÷ 2,3 kn",
		"settore": "C",
		"carta": "4d2"
	},
	{
		"carta": "4d2",
		"argomento": "correnti",
		"settore": "C",
		"id": "5.7.1 - 2",
		"risposta": "Ve = 7,6÷ 8,2 kn",
		"domanda": "L’imbarcazione “Utopia” è in navigazione con prora vera Pv = 035° e velocitàpropulsiva Vp = 6 kn.Alle ore 20h00m determina la propria posizione individuata dalle seguenticoordinate geografiche GPS: Lat. 40°54’,9 N e Long 008°42’,1 E. Sappiamo che la zona di mare è interessata da una corrente di direzione corrente Dc = 059° e velocità corrente Vc = 2 kn.Determinare la velocità effettiva Ve."
	},
	{
		"id": "5.7.3 - 1",
		"carta": "4d2",
		"settore": "C",
		"argomento": "navigazione costiera",
		"domanda": "Alle ore 12h00m salpiamo dalla rada a nord di C.po di M. Russo e assumiamo prora bussola Pb = 281° e velocità propulsiva Vp = 18 kn (declinazione 3°W, deviazione 2°E), rilevando il P.to Trigonometrico su M. Russo per Rilb = 241°. Alle ore 12h10m  rileviamo il Camp.le su P.ta di li Francesi per Rilb = 211°.Determinare il punto nave delle ore 12h10m.",
		"risposta": "Lat. 41°09’,8 N ÷ 41°10’,4 N   Long.009°04’,3 E ÷ 009°04’,9E"
	},
	{
		"settore": "C",
		"risposta": "Lat. 41°12’,3 N ÷ 41°12’,9 N  Long.008°58’,2 E ÷ 008°58’,8E",
		"id": "5.7.3 - 2",
		"domanda": "L’imbarcazione “Enif” è in navigazione ad W della Sardegna e, alle ore 10h 00m,determina la propria posizione individuata dalle seguenti coordinategeografiche GPS: Lat. 41° 08’,5 N - Long. 009° 01’,5 E (punto A).Alla stessa ora, l’unità “Enif”, riceve una richiesta di assistenza dall’imbarcazione “Denebola” che, a causa di un’avaria al propulsore, procede con rotta vera Rv = 280° e velocità propulsiva Vp = 2 kn.L’unità “Denebola” comunica la propria posizione individuata dalle seguenti coordinate geografiche GPS: Lat. 41° 12’,0 N - Long. 009° 02’,5 E (punto B). L’imbarcazione “Enif” decide, quindi, di raggiungere l’unità  “Denebola” per prestarle  assistenza impostando la velocità propulsiva Vp = 3 kn.Considerando che in zona non sono presenti elementi perturbatori del moto, determinare le coordinate geografiche del punto D di intercettazione.",
		"carta": "4d2",
		"argomento": "navigazione costiera"
	},
	{
		"domanda": "Alle ore 11h15m dal punto nave A di coordinate geografiche GPS: Lat. 40° 56’,7 N e Long. 008° 42’,5 E si procede con rotta vera Rv = 045° e velocità effettiva Ve= 5,5 kn.Considerando che nella zona soffia un vento di Maestrale che crea uno scarroccio di 6°, determinare l’orario in cui si rileva alla nostra dritta P.ta li Canneddi, per rilevamento polare ρ = 90°.",
		"carta": "4d2",
		"argomento": "scarroccio",
		"risposta": "Ora traverso 12h 47m÷ 12h 54m",
		"settore": "C",
		"id": "5.7.4 - 1"
	},
	{
		"id": "5.8.1 - 1",
		"carta": "4d2",
		"settore": "D",
		"risposta": "Vp = 7,7 ÷ 8,3 kn",
		"argomento": "correnti",
		"domanda": "L’imbarcazione “Markab” si trova in navigazione a SE di Capo Figari (Lat. 40° 59’,7 N - Long. 009° 39’,9 E) e, alle ore 14h20m, determina la propria posizione attraverso l’osservazione simultanea dei rilevamenti veri dell’ex Semaforo di Capo Figari per Rilv = 288° e del Faro di P.ta Timone (Lam.L.(2)10s72m15M) (I. Tavolara) per Rilv = 216° (punto A).Da tale posizione dirige per giungere alle ore 16h00m sul punto B, situato alla distanza di 2 miglia nautiche sul rilevamento vero Rilv = 235° del Fanale delle Isole Nibani (Lam.4s21m2M), segnalamento luminoso marittimo posto a SE di Capo Ferro.Tenuto conto che in zona vi è una corrente dalle seguenti caratteristiche Dc = 246° e Vc = 2,4 kn, determinare la velocità propulsiva Vp."
	},
	{
		"risposta": "Rv = 357° ÷ 003°",
		"argomento": "correnti",
		"settore": "D",
		"domanda": "Nel 2008 siete in navigazione a bordo dell’imbarcazione “Canopo” ad Edell’Isola Tavolara, alla velocità propulsiva Vp di 7 kn e con prora bussola Pb =348°.Alle ore 05h30m determinate la vostra posizione attraverso l’osservazione simultanea dei rilevamenti bussola del Faro di P.ta Timone (Lam.L.(2)10s72m15M) per Rilb = 242° e del Faro dell’Isola di Figarolo (Lam.5s71m11M) per Rilb = 283° (punto A).In zona vi è una corrente dalle seguenti caratteristiche Dc = 100° e Vc =1,5 kn. Il valore di declinazione riportato sulla carta nautica è: 0° 10’ E 1993 (7’ E) Considerando che gli elementi perturbatori del moto restano invariati, determinare la rotta vera Rv.  PER LA RISOLUZIONE DEL QUESITO E' NECESSARIO UTILIZZARE LA TABELLA DELLE DEVIAZIONI ALLEGATA",
		"carta": "4d2",
		"id": "5.8.1 - 2"
	},
	{
		"id": "5.8.1 - 3",
		"settore": "D",
		"carta": "4d2",
		"domanda": "L’imbarcazione “Nunki” alle ore 19h00m, in navigazione ad E di Capo Ferro, segue una navigazione stimata con prora vera Pv = 160° ad una velocità propulsiva Vp = 7 kn.Trascorse 01h20m stima di trovarsi nella posizione individuata dalle seguenti coordinate geografiche:Lat. 41° 08’,5 N - Long. 009° 37’,6 E (punto A).Al fine di verificare l’eventuale presenza di elementi perturbatori del moto, al medesimo orario (20h20m) determina la propria posizione osservando simultaneamente il Radiofaro Circolare di Capo Ferro (Lam.(3)15s52m24M) per rilevamento vero Rilv = 281° e il Faro P.ta Timone (Lam.L.(2)10s72m15M) (Isola Tavolara) per rilevamento vero Rilv = 173° (punto B).Pertanto, deducendo la presenza di una corrente in zona, durante la navigazione seguita in questo intervallo temporale, determinare la direzione della corrente Dc.",
		"risposta": "Dc = 101° ÷ 107°",
		"argomento": "correnti"
	},
	{
		"settore": "D",
		"argomento": "correnti",
		"id": "5.8.1 - 4",
		"domanda": "L’imbarcazione “Zuben el Genubi” è in navigazione a E della Sardegnasettentrionale, alle ore 08h00m, determina la propria posizione situata sulrilevamento vero Rilv = 250° dell’ex semaforo di Capo Figari ad una distanza di4,3 miglia nautiche (punto A).Dal punto A delle ore 08h00m l’unità assume una rotta per lasciare a sinistra il fanale delle Isole Nibani (Lam.4s21m2M), a una distanza di 1 miglio nautico dallo stesso (punto B) e una velocità propulsiva Vp = 6 kn.Considerato che in zona è presente una corrente di direzione Dc = 180° e di velocità Vc = 2 kn, determinare la prora vera Pv.",
		"risposta": "Pv = 323° ÷ 329°",
		"carta": "4d2"
	},
	{
		"argomento": "correnti",
		"id": "5.8.1 - 5",
		"domanda": "Il M/Y “Aldebaran” si trova in  navigazione con prora vera Pv = 000° e velocitàpropulsiva Vp = 7 kn.Dopo un’ora di navigazione determina la propria posizione osservando la costaeffettuando un punto nave A con due rilevamenti simultanei: faro di “Punta timone –tavolara” con Rilp = 118° a sinistra; faro “ Isola di Figarolo”con Rilp = 77° a sinistra.Avendo calcolato il punto stimato B, allo stesso orario, di coordinategeografiche: Lat. 40°57,5’ N – Long. 009°46,1’ E, considerato che esistonoelementi perturbatori del moto, determinare la velocità della corrente Vc.",
		"carta": "4d2",
		"risposta": "Vc = 0,9 ÷ 1,5 kn",
		"settore": "D"
	},
	{
		"settore": "D",
		"carta": "4d2",
		"domanda": "Nel 2008 l’imbarcazione “Aliseo” si trova in navigazione a NE di Capo Figari (Golfo di Congianus), seguendo una rotta vera Rv = 350° ad una velocità propulsiva Vp = 7,5 kn e, alle ore 07h00m, determina la propria posizione attraverso l’osservazione simultanea dei rilevamenti veri del Faro Scoglio Mortoriotto (Lam.(2)6s10m2M) per Rilv = 317° e dell’ex Semaforo di Capo Figari per Rilv = 196° (punto A).Tenuto conto che in zona vi è una corrente dalle seguenti caratteristiche Dc = 115° e Vc =2,5 kn, determinare la prora vera Pv.",
		"argomento": "correnti",
		"risposta": "Pv = 331° ÷ 337°",
		"id": "5.8.1 - 6"
	},
	{
		"risposta": "Lat. 40°57’,2N ÷ 40°57’,8 N    Long.009°43’,7 E ÷ 009°44’,3E",
		"argomento": "navigazione costiera",
		"id": "5.8.3 - 1",
		"settore": "D",
		"domanda": "Avvicinandoci al Golfo di Olbia da Sud-Est con prora bussola Pb = 317° (declinazione 4°E, deviazione 6°W) e velocità propulsiva Vp = 14 kn, alle ore 06h30m rileviamo il Faro di P.ta Timone per Rilb = 272°. Successivamente, alle ore 06h42m rileviamo il Faro della Bocca di Olbia per Rilb = 255°, dove accosteremo per dirigere verso la rada di Olbia.Determinare le coordinate geografiche del punto di accostata.",
		"carta": "4d2"
	},
	{
		"domanda": "L’imbarcazione “Alpheratz” è in navigazione ad E dell’Isola Mortorio e segue una Rotta vera Rv = 348° con una velocità propulsiva Vp = 6,6 kn e, alle ore 07h 00m , determina la propria posizione individuata dalle seguenti coordinate geografiche GPS: Lat. 41° 05’,2 N - Long. 009° 48’,0 E (punto A).Alla medesima ora, l’unità “Alpheratz” riceve una richiesta di assistenza dall’imbarcazione “Schedar” che, a causa di un’avaria al propulsore, procede con rotta vera Rv = 100° e velocità effettiva Ve = 1,5 kn.L’unità “Schedar” comunica la propria posizione situata a 8 miglia nautiche a levantedel Faro delle Isole Monaci (Lam.5s26m10M) (punto B).L’imbarcazione “Alpheratz” decide, quindi, di raggiungere l’unità in difficoltà perprestare assistenza mantenendo invariata la propria velocità.Considerando che in zona non sono presenti elementi perturbatori del moto, determinare le coordinate geografiche del punto D di intercettazione.",
		"carta": "4d2",
		"risposta": "Lat. 41°12’,3 N ÷ 41°12’,9 N   Long.009°43’,5 E ÷ 009°44’,1E",
		"settore": "D",
		"argomento": "navigazione costiera",
		"id": "5.8.3 - 2"
	},
	{
		"argomento": "navigazione costiera",
		"carta": "4d2",
		"risposta": "Lat. 41°12’,2 N ÷ 41°12’,8 N   Long.009°41’,3 E ÷ 009°41’,9E",
		"domanda": "L’imbarcazione “Hamal”, in navigazione ad E del Golfo del Pevero con Rotta vera Rv = 330° e con una velocità propulsiva Vp = 5 kn, alle ore 07h 51m determina la propria posizione individuata dalle seguenti coordinate geografiche GPS: Lat. 41° 07’,6 N - Long. 009° 39’,1 E (punto A).Alla medesima ora, l’unità “Hamal”, riceve una richiesta di assistenza dall’imbarcazione “Spica” che, a causa di un’avaria al propulsore, procede con rotta vera Rv = 170° e velocità effettiva Ve = 3,2 kn.L’unità “Spica” comunica la propria posizione situata sul rilevamento vero Rilv = 249° del Faro Isole Monaci (Lam.5s26m10M) posto ad una distanza di 8 miglia nautiche dal Faro stesso (punto B).L’imbarcazione “Hamal” decide, quindi, di raggiungere l’unità in difficoltà per prestare assistenza all’unità “Spica” mantenendo invariata la propria velocità. Considerando che in zona non sono presenti elementi perturbatori del moto, determinare le coordinate geografiche del punto D di intercettazione.",
		"id": "5.8.3 - 3",
		"settore": "D"
	},
	{
		"domanda": "L’imbarcazione “Starfive” è in navigazione nel Golfo di Olbia; alle ore 10h 25m stima la sua posizione (punto A) rilevando il Faro di P.ta Timone (I. Tavolara) per Rlv = 127° ad una distanza di 1,8 miglia nautiche.Da detta posizione si vuole seguire Rv = 345° e nella zona è presente un vento di Grecale che crea uno scarroccio di 10°con una velocita effettiva Ve di 6,5 kn. Determinare l’ora in cui si rileva il Faro dello Scoglio Mortoriotto perRilevamento polare a sinistra ρ =  - 90.",
		"risposta": "Ora traverso 11h 45m÷ 11h 51m",
		"settore": "D",
		"carta": "4d2",
		"id": "5.8.4 - 1",
		"argomento": "scarroccio"
	}
]
