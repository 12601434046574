import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ActivatedRoute, Router } from '@angular/router';
import { DbService } from 'src/services/db.service';

@Component({
  selector: 'app-payment-manager',
  templateUrl: './payment-manager.page.html',
  styleUrls: ['./payment-manager.page.scss'],
})
export class PaymentManagerPage implements OnInit {
  id
  constructor(private route: ActivatedRoute, private fns: AngularFireFunctions, private db: DbService,private router: Router) {
    alert(this.route.snapshot.params['id'])
    this.id = this.route.snapshot.params['id']
    this.loadData()
  }
  loadData() {
    const callable = this.fns.httpsCallable('retrieveStripeSession');

    const obs = callable({ id: this.id });
    console.log(obs);
    let u = obs.subscribe(async res => {


      u.unsubscribe()
      console.log(res)
      if(res.metadata.type == 'register'){
        if (res.payment_status == 'paid') {
          let x = this.db.getProfile().valueChanges().subscribe((usr: any) => {
            x.unsubscribe()
            console.log(usr)
            usr[0].nUsers = +usr[0].nUsers + +res.metadata.qta
            this.db.upsertUser(usr[0])
            this.router.navigate(['home-admin'])
          })
        } else {
          alert('Errore nel pagamento')
          this.router.navigate(['home-admin'])
        }
      }else{
        if (res.payment_status == 'paid') {
          let x = this.db.getProfile().valueChanges().subscribe((usr: any) => {
            x.unsubscribe()
            console.log(usr)
            usr[0].isPaid = true
            this.db.upsertUser(usr[0])
            this.router.navigate(['home'])
          })
        } else {
          alert('Errore nel pagamento')
          this.router.navigate(['home'])
        }

      }

    })
  }
  ngOnInit() {
  }

}
