import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActionSheetController, AlertController, ModalController, NavParams } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { DbService, QUIZ_COLLECTION } from 'src/services/db.service';

import * as moment from 'moment';
import { AuthService } from 'src/services/auth.service';

import { IonContent } from '@ionic/angular';
import { CLOCK_REFRESH_TIME } from '../app.config';

const N_QUESTIONS_PER_QUIZ = 5;

@Component({
  selector: 'app-vela',
  templateUrl: './vela.page.html',
  styleUrls: ['./vela.page.scss'],
})
export class VelaPage implements OnInit, OnDestroy {
  sliderOne: any;
  viewType: string;
  counter = 0;
  quizData = [];
  @ViewChild('slideWithNav', { static: false }) slideWithNav: any;

  @ViewChild(IonContent) content: IonContent;
  startDate = new Date();

  quiz_vela;
  quiz = {
    id: undefined,
    questions: [],
    i: 0,
    type: 'quiz_vela',
    cap: undefined,
    duration: undefined,
    finished: false,
    nCorrect: undefined,
    dta: undefined,
    user: this.auth.getUserId() ? this.auth.getUserId() : '',

    passed: undefined,
    perc: undefined,

    shared: false
  };

  indeterminateState: boolean = true;
  checkParent: boolean = true;

  ricerca = '';
  timeReady = false

  sharable = true
  modalClassShare = false

  classiList = []
  classeActive = []

  idClassActive = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private db: DbService,
    public auth: AuthService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private actionSheetCtrl: ActionSheetController,
  ) {

    //Item object for Nature

    this.sliderOne =
    {
      isBeginningSlide: true,
      isEndSlide: false,
      slidesItems: []
    };
  }
  // slideOptions = {
  //   slidesPerView: 3,
  //   direction: 'vertical',
  //   pagination: {
  //     el: '.swiper-pagination',
  //     type: 'progressbar',
  //   }
  // }

  // slideOptions = {
  //   slidesPerView: 1,
  //   // direction: 'vertical',
  //   coverflowEffect: {
  //   },
  //   pagination: {
  //     el: '.swiper-pagination',
  //     type: 'progressbar',
  //   },
  // }

  //Coverflow
  slideOptions = {
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination',
      type: 'progressbar',
    },
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    on: {
      beforeInit() {
        const swiper = this;

        swiper.classNames.push(`${swiper.params.containerModifierClass}coverflow`);
        swiper.classNames.push(`${swiper.params.containerModifierClass}3d`);

        swiper.params.watchSlidesProgress = true;
        swiper.originalParams.watchSlidesProgress = true;
      },
      setTranslate() {
        const swiper = this;
        const {
          width: swiperWidth, height: swiperHeight, slides, $wrapperEl, slidesSizesGrid, $
        } = swiper;
        const params = swiper.params.coverflowEffect;
        const isHorizontal = swiper.isHorizontal();
        const transform$$1 = swiper.translate;
        const center = isHorizontal ? -transform$$1 + (swiperWidth / 2) : -transform$$1 + (swiperHeight / 2);
        const rotate = isHorizontal ? params.rotate : -params.rotate;
        const translate = params.depth;
        // Each slide offset from center
        for (let i = 0, length = slides.length; i < length; i += 1) {
          const $slideEl = slides.eq(i);
          const slideSize = slidesSizesGrid[i];
          const slideOffset = $slideEl[0].swiperSlideOffset;
          const offsetMultiplier = ((center - slideOffset - (slideSize / 2)) / slideSize) * params.modifier;

          let rotateY = isHorizontal ? rotate * offsetMultiplier : 0;
          let rotateX = isHorizontal ? 0 : rotate * offsetMultiplier;
          // var rotateZ = 0
          let translateZ = -translate * Math.abs(offsetMultiplier);

          let translateY = isHorizontal ? 0 : params.stretch * (offsetMultiplier);
          let translateX = isHorizontal ? params.stretch * (offsetMultiplier) : 0;

          // Fix for ultra small values
          if (Math.abs(translateX) < 0.001) { translateX = 0; }
          if (Math.abs(translateY) < 0.001) { translateY = 0; }
          if (Math.abs(translateZ) < 0.001) { translateZ = 0; }
          if (Math.abs(rotateY) < 0.001) { rotateY = 0; }
          if (Math.abs(rotateX) < 0.001) { rotateX = 0; }

          const slideTransform = ``;

          $slideEl.transform(slideTransform);
          $slideEl[0].style.zIndex = -Math.abs(Math.round(offsetMultiplier)) + 1;
          if (params.slideShadows) {
            // Set shadows
            let $shadowBeforeEl = isHorizontal ? $slideEl.find('.swiper-slide-shadow-left') : $slideEl.find('.swiper-slide-shadow-top');
            let $shadowAfterEl = isHorizontal ? $slideEl.find('.swiper-slide-shadow-right') : $slideEl.find('.swiper-slide-shadow-bottom');
            if ($shadowBeforeEl.length === 0) {
              $shadowBeforeEl = swiper.$(`<div class="swiper-slide-shadow-${isHorizontal ? 'left' : 'top'}"></div>`);
              $slideEl.append($shadowBeforeEl);
            }
            if ($shadowAfterEl.length === 0) {
              $shadowAfterEl = swiper.$(`<div class="swiper-slide-shadow-${isHorizontal ? 'right' : 'bottom'}"></div>`);
              $slideEl.append($shadowAfterEl);
            }
            if ($shadowBeforeEl.length) { $shadowBeforeEl[0].style.opacity = offsetMultiplier > 0 ? offsetMultiplier : 0; }
            if ($shadowAfterEl.length) { $shadowAfterEl[0].style.opacity = (-offsetMultiplier) > 0 ? -offsetMultiplier : 0; }
          }
        }

        // Set correct perspective for IE10
        if (swiper.support.pointerEvents || swiper.support.prefixedPointerEvents) {
          const ws = $wrapperEl[0].style;
          ws.perspectiveOrigin = `${center}px 50%`;
        }
      },
      setTransition(duration) {
        const swiper = this;
        swiper.slides
          .transition(duration)
          .find('.swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left')
          .transition(duration);
      }
    }
  };

  spinner = false
  async share() {

    const quiz_base = { ... this.quiz }


    let buttons = []

    let s = this.db.listClassi().snapshotChanges().subscribe(async refClassi => {


      for (const refClasse of refClassi) {

        let classe: any = refClasse.payload.doc.data()
        console.log(classe)

        this.classiList.push(classe);
        console.log(this.classiList);

        // ADD CLASSE
        if (!classe.hidden) buttons.push({
          text: classe.nome,
          //role: 'cancel',
          data: {
            action: classe.id,
          },
        })


      }

      buttons = buttons.sort((a, b) => { return a.text.toUpperCase() > b.text.toUpperCase() ? 1 : -1 })


      // ADD CANCEL
      buttons.push({
        text: 'Annulla',
        role: 'destructive',
        data: {
          action: 'cancel',
        },
      })

      const actionSheet = await this.actionSheetCtrl.create({
        header: 'Seleziona una classe',
        //subHeader: 'Example subheader',
        buttons: buttons
      });

      console.log(actionSheet)
      await actionSheet.present();

      const result = await actionSheet.onDidDismiss();

      if (result.data.action == 'cancel') return

      this.spinner = true

      const idClasse = result.data.action

      s.unsubscribe()

      console.log('idClasse = ' + idClasse)

      this.idClassActive = idClasse;

      this.spinner = false

      var idArrayClass = 0;

      let s1 = this.db.listUsersClassi(idClasse).snapshotChanges().subscribe(async usersRef => {
        //console.log(usersRef);
        s1.unsubscribe()

        idArrayClass = this.classiList.findIndex(obj => obj.id === idClasse);
        console.log(idArrayClass);

        this.classeActive['users'] = [];

        for (const userRef of usersRef) {

          const user: any = userRef.payload.doc.data()

          user['active'] = true;
          console.log(user)

          //popolo gli users della classe selezionata
          this.classiList[idArrayClass].users.push(user);
        }

        console.log(this.classiList);

        this.classeActive = this.classiList[idArrayClass];
        console.log(this.classeActive);

      });

      /*
      let s1 = this.db.listUsersClassi(idClasse).snapshotChanges().subscribe(async usersRef => {

        console.log(usersRef)

        let msg = ""
        let n = 0

        const now = new Date()

        console.clear()


        s1.unsubscribe()

        for (const userRef of usersRef) {

          const user: any = userRef.payload.doc.data()

          console.log(user)

          const quiz = { ...quiz_base }

          quiz.user = user.email
          quiz.id = this.db.firestore.createId()
          quiz.dta = now
          quiz.duration = '00:00:00'
          quiz.shared = true

          delete quiz.nCorrect
          delete quiz.perc
          delete quiz.passed


          await this.db.firestore.collection(QUIZ_COLLECTION).doc(quiz.id).set(quiz).then(res => {

            n++
            console.log(`\n${user.nome} ${user.cognome}`)
            msg += `\n${user.nome} ${user.cognome} (${user.email})`

          }).catch(err => {
            console.error(err)
          })

        }



        this.spinner = false
        alert(`Hai condiviso il quiz con ${n} utenti: ${msg}`)



      })
      */

      this.modalClassShare = true

    })


  }

  async sendQuiz() {

    const quiz_base = { ... this.quiz }

    const now = new Date()

    let msg = ""
    let n = 0

    console.log(this.classeActive)

    for (const user of this.classeActive['users'].filter(e => e.active)) {

      console.log(user);


      const quiz = { ...quiz_base }

      quiz.user = user.email
      quiz.id = this.db.firestore.createId()
      quiz.dta = now
      quiz.duration = '00:00:00'
      quiz.shared = true

      delete quiz.nCorrect
      delete quiz.perc
      delete quiz.passed


      await this.db.firestore.collection(QUIZ_COLLECTION).doc(quiz.id).set(quiz).then(res => {

        n++
        console.log(`\n${user.nome} ${user.cognome}`)
        msg += `\n${user.nome} ${user.cognome} (${user.email})`

      }).catch(err => {
        console.error(err)
      })


    }

    this.spinner = false
    alert(`Hai condiviso il quiz con ${n} utenti: ${msg}`)


    this.classiList = []

    this.modalClassShare = false
  }

  async getCurrent() {
    this.quiz.i = this.slideWithNav?.nativeElement.swiper.activeIndex;
    if (this.quiz.i > 0) this.sharable = false
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.route.params.subscribe(async res => {


      if (res.id) {

        console.clear()

        let s = await this.db.getQuizById(res.id).valueChanges().subscribe(resx => {

          console.log(resx)
          this.quiz = resx as any
          s.unsubscribe()
          this.timeReady = true
          this.getDuration();

        })


      } else {

        this.quiz.cap = res.cap;



        if (this.quiz.cap == 'complete') {

          const qb = JSON.parse(JSON.stringify(this.db.QUIZ_VELA_BASE))

          for (let i = 0; i < N_QUESTIONS_PER_QUIZ; i++) {

            const rand = Math.floor(Math.random() * qb.length);
            const q = qb.splice(rand, 1)[0];
            this.quiz.questions.push(q);

          }
          /// FIXO OROLOGIO
          this.timeReady = true
          this.getDuration();
          ///

        } else if (this.quiz.cap == 'search') {

          this.ricerca = res.quiz;
          this.quiz_vela = this.db.searchQuiz(res.quiz)[1];

          for (const iterator of this.quiz_vela) {
            this.quiz.questions.push(iterator);
          }
          console.log(this.quiz.questions)

        } else {
          this.openModal()


        }



        console.log(this.quiz);

        this.getDuration();

      }

    });

  }


  checkAll(event) {
    setTimeout(() => {
      this.classeActive['users'].forEach(item => {
        item.active = this.checkParent;
      });
    });
  }


  checkSingle() {
    const allItems = this.classeActive['users'].length;
    let selected = 0;
    this.classeActive['users'].map(item => {
      if (item.active) selected++;
    });
    if (selected > 0 && selected < allItems) {
      this.indeterminateState = true;
      this.checkParent = false;
    } else if (selected == allItems) {
      this.checkParent = true;
      this.indeterminateState = false;
    } else {
      this.indeterminateState = false;
      this.checkParent = false;
    }
  }

  bookmarks = localStorage.getItem('bookmarks_quiz_vela') ? JSON.parse(localStorage.getItem('bookmarks_quiz_vela')) : []
  isBookmarked() {
    try {
      return this.bookmarks.find(e => e == this.quiz.questions[this.quiz.i].id)
    } catch (error) {
      return false
    }

  }
  toggleBookmark() {
    if (this.isBookmarked()) {
      let i = this.bookmarks.findIndex(e => e == this.quiz.questions[this.quiz.i].id)
      this.bookmarks.splice(i, 1)
    } else {
      this.bookmarks.push(this.quiz.questions[this.quiz.i].id)
    }
    localStorage.setItem('bookmarks_quiz_vela', JSON.stringify(this.bookmarks))
  }

  swipeNext() {

    this.slideWithNav?.nativeElement?.swiper?.slideNext(500)

    this.getCurrent();

    setTimeout(() => {
      this.content.scrollToTop();
    }, 500);

  }

  isEnd() {
    return this.slideWithNav?.nativeElement?.swiper?.isEnd
  }
  /*
    //Move to previous slide
    slidePrev(object, slideView) {
      slideView.slidePrev(500).then(() => {
        this.checkIfNavDisabled(object, slideView);
        this.getCurrent();
      });;
    }
  */
  //Method called when slide is changed by drag or navigation
  SlideDidChange(event, object, slideView) {
    console.log(event);
    //this.checkIfNavDisabled(object, slideView);
    console.log(object, slideView);
    this.getCurrent();

  }
  /*
    //Call methods to check if slide is first or last to enable disbale navigation
    checkIfNavDisabled(object, slideView) {
      this.checkisBeginning(object, slideView);
      this.checkisEnd(object, slideView);
    }
  
    checkisBeginning(object, slideView) {
      slideView.isBeginning().then((istrue) => {
        object.isBeginningSlide = istrue;
      });
    }
  
    checkisEnd(object, slideView) {
      slideView.isEnd().then((istrue) => {
        object.isEndSlide = istrue;
      });
    };
  */
  test_result() {
    this.router.navigate(['./test-result', { quiz: JSON.stringify(this.quiz) }]);
  }


  intervalId
  getDuration() {

    if (this.intervalId) clearInterval(this.intervalId)

    setInterval(
      () => {
        const end = moment();
        const start = moment(this.startDate);

        const diff = end.diff(start);

        this.quiz.duration = moment.utc(diff).format('HH:mm:ss');
      },
      CLOCK_REFRESH_TIME
    );

  }

  correct() {

    this.quiz.finished = true;

    ;

    for (const e of this.quiz.questions) {

      const b = e.selected == e.risposta;

      e.correct = b;

    }


    this.quiz.nCorrect = this.quiz.questions.filter(e => e.correct).length;

    this.quiz.perc = 100 * this.quiz.nCorrect / this.quiz.questions.length;
    this.quiz.passed = this.quiz.perc >= 100 * 4 / 5;


    this.db.saveQuiz(this.quiz)

    this.navCtrl.navigateRoot(['test-result', { quiz: this.quiz.id }]);
    // this.router.navigate(['test-result', {quiz:this.quiz.id}])
  }

  async middleCorrect() {

    if (!confirm('Voi veramente interrompere il quiz? Verranno corrette solo le risposte date finora')) return
    this.quiz.finished = true;

    //;
    console.log(this.quiz)
    let tmp = { ...this.quiz }
    tmp.questions = this.quiz.questions.slice(0, this.quiz.i)

    for (const e of tmp.questions) {

      const b = e.selected == e.risposta;

      e.correct = b;

    }


    tmp.nCorrect = tmp.questions.filter(e => e.correct).length;

    tmp.perc = 100 * tmp.nCorrect / tmp.questions.length;
    tmp.passed = tmp.perc >= 100 * 4 / 5;


    this.db.saveQuiz(tmp)

    this.navCtrl.navigateRoot(['test-result', { quiz: tmp.id }]);
    // this.router.navigate(['test-result', {quiz:this.quiz.id}])

  }


  setSelected(q, i) {

    console.log('setSelected')

    this.sharable = false

    if (!q.selected) {
      q.selected = i;
    }

    setTimeout(() => {
      this.swipeNext();
    }, 300);

  }



  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }

  cancelModal() {
    this.modalClassShare = false
  }

  getCorrectAnswer(q) {
    return q.risposta == 'V' ? 'Vero' : 'Falso'
  }

  getSelectedAnswer(q) {
    return ''
  }
  /**
          let b = confirm('Vuoi usare solo le domande a cui non hai mai risposto?')
          let qb = this.db.QUIZ_VELA_BASE.filter(e => e.categoria == this.quiz.cap)
          if (b) {
            qb = qb.filter(e => !this.db.COUNTER['quiz_vela'].find(e1 => e.id == e1.id).n)
          }

          const n = qb.length;

          for (let i = 0; i < n; i++) {

            const rand = Math.floor(Math.random() * qb.length);
            const q = qb.splice(rand, 1)[0];
            this.quiz.questions.push(q);

          }
          */
  async openModal() {
    const modal = await this.modalCtrl.create({
      component: ModalSceltaVela,
      componentProps: { quiz: this.quiz, bookmark: this.bookmarks.length },
      backdropDismiss: false
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();


    let qb = JSON.parse(JSON.stringify(this.db.QUIZ_VELA_BASE))
    qb = this.db.QUIZ_VELA_BASE.filter(e => e.categoria == this.quiz.cap)
    console.log(data, role)
    switch (data) {
      case null:
        this.router.navigate(['home'])
        break;
      case 'info':
        qb = qb.filter(e => !this.db.COUNTER['quiz_vela'].find(e1 => e.id == e1.id).n)
        break;
      case 'all': break;
      case 'err':
        qb = qb.filter(e => this.db.COUNTER['quiz_vela'].find(e1 => e.id == e1.id).lastError)
        break;
      case 'book':
        qb = qb.filter(e => this.bookmarks.find(e1 => e.id == e1))
        break;
      case 'rand10':
        let tmp = []
        for (let i = 0; i < 10; i++) {
          const rand = Math.floor(Math.random() * qb.length);
          const q = qb.splice(rand, 1)[0];
          tmp.push(q);
        }
        qb = tmp
        break;
    }


    const n = qb.length;

    for (let i = 0; i < n; i++) {

      const rand = Math.floor(Math.random() * qb.length);
      const q = qb.splice(rand, 1)[0];
      this.quiz.questions.push(q);

    }
  }
}



@Component({
  selector: 'app-modal-scelta-vela',
  template: `

  <ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()"><ion-icon name="chevron-back-outline"></ion-icon></ion-button>
    </ion-buttons>
    <h2 style="color: white;
    text-align: center;">{{quiz.cap}}</h2>

  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding" style="margin:20px">

<br>

<p style="text-align:center">Seleziona quali domande vuoi utilizzare</p>
<br>


  <ion-item (click)="confirm('all')" expand="block" color="primary" style="border-radius:10px">

    <ion-icon slot="start" name="medical-outline"></ion-icon>
    Tutte
    <ion-badge style="width:60px"  slot="end" color="light">{{totQuestion}}</ion-badge>
  </ion-item>

  <br>

  <ion-item (click)="confirm('rand10')" expand="block" color="primary" style="border-radius:10px">
    <ion-icon slot="start" name="shuffle-outline"></ion-icon>
    10 domande casuali
    <ion-badge style="width:60px"  slot="end" color="light">10</ion-badge>
  </ion-item>

  <br>

  <ion-item (click)="confirm('info')" expand="block" color="primary" style="border-radius:10px">


    <ion-icon slot="start" name="grid-outline"></ion-icon>

    Solo domande non fatte
    <ion-badge style="width:60px" slot="end" color="success">{{notDone}}</ion-badge>
  </ion-item>

  <br>

  <ion-item (click)="confirm('err')" expand="block" color="primary" style="border-radius:10px">


  <ion-icon slot="start" name="warning-outline"></ion-icon>

    Solo domande errate
    <ion-badge style="width:60px" slot="end" color="danger">{{countErr}}</ion-badge>
  </ion-item>

  <br>

  <ion-item (click)="confirm('book')" expand="block" color="primary" style="border-radius:10px">


  <ion-icon slot="start" name="star-outline"></ion-icon>

    Solo domande salvate
    <ion-badge style="width:60px" slot="end" color="warning">{{bookmarkLength}}</ion-badge>
  </ion-item>

  <br><br><br>
  <ion-button color="danger" style="display:block; margin:auto" (click)="reset()">RESETTA CONTATORI</ion-button>

</ion-content>` ,
})
export class ModalSceltaVela {
  name: string;
  quiz
  countErr = 0
  totQuestion = 0
  countInfo = '0'
  bookmarkLength = 0
  notDone = 0

  bookmarks = localStorage.getItem('bookmarks_quiz_vela') ? JSON.parse(localStorage.getItem('bookmarks_quiz_vela')) : []

  constructor(private modalCtrl: ModalController,
    private navParams: NavParams,
    private db: DbService) {

    this.loadData()

  }

  loadData() {

    this.quiz = this.navParams.get('quiz')

    try {
      this.bookmarkLength = this.db.QUIZ_VELA_BASE.filter(e => e.capitolo == this.quiz.cap).filter(e => this.bookmarks.find(e1 => e.id == e1)).length
    } catch (error) {
      alert('ModalController -> constructor -> bookmarkLength: ' + JSON.stringify(error))
    }

    try {
      this.countErr = this.getError(this.quiz.cap)
    } catch (error) {
      alert('ModalController -> constructor: -> getError' + JSON.stringify(error))
    }

    try {
      this.countInfo = this.getInfo(this.quiz.cap)
    } catch (error) {
      alert('ModalController -> constructor: -> getInfo' + JSON.stringify(error))
    }

  }

  async reset() {

    if (!confirm("Sei sicuro di voler resettare i contatori? questa operazione non è reversibile")) return

    localStorage.removeItem('bookmarks_quiz_vela')
    this.bookmarks = []

    await this.db.resetCounterVela()

    this.loadData()

  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm(value) {
    return this.modalCtrl.dismiss(value, 'confirm');
  }
  getInfo(cap = 'complete') {

    try {
      let tmp = this.db.COUNTER['quiz_vela'].filter(e => e.capitolo == cap || cap == 'complete')
      let n = tmp.length
      this.totQuestion = n
      let e = tmp.filter(e => e.lastError).length

      let x = tmp.filter(e => e.n > 0).length


      this.notDone = n - x

      return `${x}/${n}`

    } catch (error) {

      alert('getInfo: ' + JSON.stringify(error))
    }

    return ''

  }

  getError(cap = 'complete') {

    try {
      let tmp = this.db.COUNTER['quiz_vela'].filter(e => e.capitolo == cap || cap == 'complete')

      let e = tmp.filter(e => e.lastError).length

      return e

    } catch (error) {


      alert('getError: ' + JSON.stringify(error))
    }

    return 0

  }

}
