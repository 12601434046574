import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapitalizePipe } from 'src/services/pipes';
import { LockedComponent } from '../components/locked/locked.component';
import { IonicModule } from '@ionic/angular';
import { LatLonModalComponent, LatlonComponent } from '../components/latlon/latlon.component';
import { FormsModule } from '@angular/forms';




@NgModule({
  declarations: [
    CapitalizePipe,
    LockedComponent,
    LatlonComponent,
    LatLonModalComponent,

  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
  ],
  exports:[
    CapitalizePipe,
    LockedComponent,
    LatlonComponent,
    LatLonModalComponent,
    FormsModule,

  ],
  providers:[
    CapitalizePipe,
  ]
})
export class SharedModule { }


export const validateEmail = (email) => String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
